import { testRootSagaMappings } from 'bnc-utilities-js/testUtils/saga';
import rootSaga, { workersMapping } from '..';

jest.mock('../workers', () => ({
  watchBncSignInCompleted: jest.fn(),
  watchBncSignInFailure: jest.fn(),
  watchRemoveIdentity: jest.fn(),
  watchReturnToLogin: jest.fn(),
  watchLoginRequest: jest.fn(),
  watchBncVerifySuccess: jest.fn(),
  watchBncVerifyFailure: jest.fn(),
  watchBncResendSuccess: jest.fn(),
  watchBncResendFailure: jest.fn(),
  watchLoginMfaCodeValidationRequest: jest.fn(),
  watchLoginMfaCodeRequest: jest.fn(),
  watchTriggerHideResendButtons: jest.fn(),
  watchBncAuthSaveIdentity: jest.fn(),
  watchLocationChange: jest.fn(),
  watchLoginFailure: jest.fn(),
  watchLoginMfaSendCodeRequest: jest.fn(),
  watchLoginMfaResendCodeRequest: jest.fn(),
  watchBncSendSuccess: jest.fn(),
  watchBncSendFailure: jest.fn(),
  watchUserLockedPasswordTimeout: jest.fn(),
  watchBncTokenEnroll: jest.fn(),
}));

jest.mock('../taggingWorkers', () => ({
  watchTrackResendCodeSucess: jest.fn(),
}));

testRootSagaMappings(rootSaga, workersMapping);
