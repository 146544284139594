// @flow
import React from 'react';

import Sbip2LoginTemplate from '../../templates/sbip2/LoginTemplate';
import ObeLoginTemplate from '../../templates/obe/LoginTemplate';
import WhiteLabelLoginTemplate from '../../templates/whiteLabel/LoginTemplate';
import SecureKeyLoginTemplate from '../../templates/secureKey/LoginTemplate';
import NatgoLoginTemplate from '../../templates/natgo/LoginTemplate';
import BneLoginTemplate from '../../templates/bne/LoginTemplate';
import RewardsLoginTemplate from '../../templates/rewards/LoginTemplate';
import RewardsWhiteLabelLoginTemplate from '../../templates/rewardsWhiteLabel/LoginTemplate';
import BaoLoginTemplate from '../../templates/bao/LoginTemplate';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';

import './style.scss';

type Props = {
  templateName: string,
};

const Login = (props: Props) => {
  const { templateName } = props;

  const loginTemplates = {
    [TEMPLATES.SBIP2]: <Sbip2LoginTemplate />,
    [TEMPLATES.OBE]: <ObeLoginTemplate />,
    [TEMPLATES.SBIP2_WHITE_LABEL]: <WhiteLabelLoginTemplate />,
    [TEMPLATES.SECURE_KEY]: <SecureKeyLoginTemplate />,
    [TEMPLATES.NATGO]: <NatgoLoginTemplate />,
    [TEMPLATES.BNE]: <BneLoginTemplate />,
    [TEMPLATES.REWARDS_WHITE_LABEL]: <RewardsWhiteLabelLoginTemplate />,
    [TEMPLATES.REWARDS]: <RewardsLoginTemplate />,
    [TEMPLATES.BAO]: <BaoLoginTemplate />,
  };
  return getTemplateComponent(loginTemplates, templateName);
};

export default Login;
