// @flow
export const FORM_HANDLER_POPULATE_FORM_INPUTS =
  'FORM_HANDLER_POPULATE_FORM_INPUTS';
export const FORM_HANDLER_FOCUS_ERROR_INPUT_REQUEST =
  'FORM_HANDLER_FOCUS_ERROR_INPUT_REQUEST';
export const FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS =
  'FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS';
export const FORM_HANDLER_NO_FIELD_TO_FOCUS_ON =
  'FORM_HANDLER_NO_FIELD_TO_FOCUS_ON';
export const FORM_HANDLER_FORM_INIT_DONE = 'FORM_HANDLER_FORM_INIT_DONE';
export const FORM_HANDLER_RESET_FORM_REQUEST =
  'FORM_HANDLER_RESET_FORM_REQUEST';
export const FORM_HANDLER_FORM_INIT_REQUEST = 'FORM_HANDLER_FORM_INIT_REQUEST';
