import React from 'react';
import PropTypes from 'prop-types';
import { Title } from 'bnc-react-components';

import './style.scss';

const propTypes = {
  errorTitle: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  errorButton: PropTypes.node,
};

const defaultProps = {
  errorText: '',
  errorButton: null,
};

function ErrorDisplay(props) {
  const { errorTitle, errorText, errorButton } = props;
  return (
    <div className="error-display">
      <div className="error-display__content">
        <Title className="error-display__title" component="h1">
          {errorTitle}
        </Title>
        {errorText && (
          <div className="error-display__paragraph">{errorText}</div>
        )}
        {errorButton && (
          <div className="error-display__button">{errorButton}</div>
        )}
      </div>
    </div>
  );
}

ErrorDisplay.propTypes = propTypes;
ErrorDisplay.defaultProps = defaultProps;

export default ErrorDisplay;
