import includes from 'lodash/includes';

export const addClassBody = classToAdd => {
  document.body.classList.add(classToAdd);
};

export const removeClassBody = classToRemove => {
  document.body.classList.remove(classToRemove);
};

export const focusOnComponent = selector => {
  const comp = document.querySelector(selector);
  if (comp) {
    comp.focus();
  }
};

const INPUT_COMPONENTS = ['textarea', 'input'];

export const isFocusOnInputComponents = () => {
  if (document.activeElement) {
    const tagName = document.activeElement.tagName.toLowerCase();
    return includes(INPUT_COMPONENTS, tagName);
  }
  return false;
};
