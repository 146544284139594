import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Message } from '@nbc-design/message';
import { Text } from '@nbc-design/text';
import { intlShape } from 'react-intl';
import { isNotValidated } from '../helpers';
import { queueAnnouncer } from '../../../../utils/liveAnnouncer/helper';

const propTypes = {
  criteriaMessageMap: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({
    newPassword: PropTypes.string.isRequired,
    listCriteria: PropTypes.string.isRequired,
  }).isRequired,
  dirty: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  announceMessage: PropTypes.func.isRequired,
};

const PasswordCriteriaForm = ({
  criteriaMessageMap,
  errors,
  dirty,
  intl,
  announceMessage,
}) => {
  const criteriaMessageKeys = Object.keys(criteriaMessageMap);

  useEffect(() => {
    if (!isEmpty(errors.newPassword)) {
      const messages = [
        intl.formatMessage({ id: 'text.label.accessibility.criterias.error' }),
        criteriaMessageKeys
          .filter(criteria => isNotValidated(errors.listCriteria, criteria))
          .map(v => {
            return criteriaMessageMap[v].toLowerCase();
          })
          .join(', '),
      ];
      queueAnnouncer(messages, announceMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="reset-password-form__validations">
      <Text className="password-criteria__exigences" appearance="paragraph">
        {intl.formatMessage({
          id: 'text.label.criterias',
        })}
      </Text>
      {criteriaMessageKeys.map(criteria => {
        const criteriaMessage = criteriaMessageMap[criteria];
        return isNotValidated(errors.listCriteria, criteria) || !dirty ? (
          <span
            key={criteria}
            className="dsc-message dsc-message--inactive"
            data-test="inactive-message"
          >
            <Message appearance="inactive" />
            {criteriaMessage}
          </span>
        ) : (
          <span
            key={criteria}
            className="dsc-message dsc-message--success"
            data-test="success-message"
          >
            <Message className="dsc-message__icon" appearance="success" />
            {criteriaMessage}
          </span>
        );
      })}
    </div>
  );
};

PasswordCriteriaForm.propTypes = propTypes;

export default PasswordCriteriaForm;
