import getFormHandlerState from '../selectors';

describe('getFormHandlerState', () => {
  it('should return state if they are available', () => {
    const state = {
      formHandlerServiceReducer: { test: 1 },
    };
    expect(getFormHandlerState(state)).toEqual({ test: 1 });
  });

  it('should return {} if state are not available', () => {
    const state = {};
    expect(getFormHandlerState(state)).toEqual({});
  });

  it('should return module state if they are available', () => {
    const modulePath = 'front_end.sso';

    const state = {
      front_end: {
        sso: {
          formHandlerServiceReducer: { test: 1 },
        },
      },
    };
    expect(getFormHandlerState(state, modulePath)).toEqual({ test: 1 });
  });
});
