// @flow
import * as actionTypes from './actionTypes';
import { getUserLocale, getLocaleMessages } from '../../utils/i18nManager';

import type { Action } from './types';

export type I18nReducerState = {|
  +locale: string,
  +messages: { [key: string]: string },
  +localeCanBeOverriden: boolean,
|};

const defaultState: I18nReducerState = {
  locale: getUserLocale(),
  messages: getLocaleMessages(getUserLocale()),
  localeCanBeOverriden: true,
};

export const initialState = () => defaultState;

export default (
  state: I18nReducerState = initialState(),
  action: Action,
): I18nReducerState => {
  switch (action.type) {
    case actionTypes.I18N_SERVICE_CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
        messages: getLocaleMessages(action.locale),
      };
    case actionTypes.I18N_SERVICE_PROTECT_CHANGE_LOCALE:
      return {
        ...state,
        localeCanBeOverriden: false,
      };
    default:
      return state;
  }
};
