import { isRedirected, isSet, getParam, shouldRetry } from '../helper';

jest.mock('../../../configs', () => ({
  Configs: { AUTH: { SAML_EXPIRATION: 600000 } },
}));

// Mock new Date() to return a fixed date
const fixedDate = new Date('2018-01-01T16:00:00.000Z');
beforeAll(() => {
  global.Date = class extends Date {
    constructor(date) {
      super(date);
      if (typeof date === 'undefined') {
        return fixedDate;
      }
    }
  };
});

describe('isRedirected to /badRequest page', () => {
  [
    {
      params: {
        SAMLRequest: '23234234',
        RelayState: '1234',
      },
      expectedResult: false,
    },
    {
      params: {
        SAMLRequest: '',
        RelayState: '1234',
      },
      expectedResult: true,
    },
    {
      params: {
        SAMLRequest: '23234234',
        RelayState: '',
      },
      expectedResult: true,
    },
    {
      params: {
        SAMLRequest: '23234234',
      },
      expectedResult: true,
    },
    {
      params: {
        RelayState: '1234',
      },
      expectedResult: true,
    },
    {
      params: '',
      expectedResult: true,
    },
  ].forEach(({ params, expectedResult }) => {
    test(`params '${JSON.stringify(
      params,
    )}' should return '${expectedResult}'`, () => {
      expect(isRedirected(params)).toEqual(expectedResult);
    });
  });
});

describe('isSet', () => {
  [
    {
      item: {
        SAMLRequest: '23234234',
        RelayState: '1234',
      },
      path: 'SAMLRequest',
      expectedResult: true,
    },
    {
      item: {
        SAMLRequest: '',
        RelayState: '1234',
      },
      path: 'SAMLRequest',
      expectedResult: false,
    },
    {
      item: {
        SAMLRequest: 'fkekl',
        RelayState: '1234',
      },
      path: 'random',
      expectedResult: false,
    },
    {
      item: null,
      path: true,
      expectedResult: false,
    },
  ].forEach(({ item, path, expectedResult }) => {
    test(`isSet with item '${JSON.stringify(
      item,
    )}' and path '${path}' should return '${expectedResult}'`, () => {
      expect(isSet(item, path)).toEqual(expectedResult);
    });
  });
});

describe('getParam', () => {
  [
    {
      paramsList: {
        SAMLRequest: '23234234',
        RelayState: '1234',
      },
      paramName: 'SAMLRequest',
      expectedResult: '23234234',
    },
    {
      paramsList: {
        RelayState: '1234',
      },
      paramName: 'SAMLRequest',
      expectedResult: null,
    },
    {
      paramsList: {
        SAMLRequest: '23234234',
        RelayState: '1234',
      },
      paramName: 'toto',
      expectedResult: null,
    },
    {
      paramsList: {
        SAMLRequest: '',
        RelayState: '1234',
      },
      paramName: 'SAMLRequest',
      expectedResult: null,
    },
  ].forEach(({ paramsList, paramName, expectedResult }) => {
    test(`getParam with paramsList '${JSON.stringify(
      paramsList,
    )}' and paramName '${paramName}' should return '${expectedResult}'`, () => {
      expect(getParam(paramsList, paramName)).toEqual(expectedResult);
    });
  });
});

describe('shouldRetry', () => {
  [
    {
      isFirstTryObj: undefined,
      expectedResult: true,
    },
    {
      isFirstTryObj: null,
      expectedResult: true,
    },
    {
      isFirstTryObj: {},
      expectedResult: true,
    },
    {
      isFirstTryObj: 'string',
      expectedResult: true,
    },
    {
      isFirstTryObj: {
        isFirstTry: true,
        expiration: '',
      },
      expectedResult: true,
    },
    {
      isFirstTryObj: {
        isFirstTry: false,
        expiration: new Date('2018-01-01T16:00:05.000Z'),
      },
      expectedResult: false,
    },
    {
      isFirstTryObj: {
        isFirstTry: false,
        expiration: new Date('2018-01-01T16:00:00.000Z'),
      },
      expectedResult: true,
    },
    {
      isFirstTryObj: {
        isFirstTry: false,
        expiration: new Date('2018-01-01T16:00:30.000Z'),
      },
      expectedResult: true,
    },
    {
      isFirstTryObj: {
        isFirstTry: false,
        expiration: new Date('2018-01-01T16:00:31.000Z'),
      },
      expectedResult: true,
    },
    {
      isFirstTryObj: {
        isFirstTry: false,
        expiration: new Date('2018-01-01T16:00:35.000Z'),
      },
      expectedResult: true,
    },
    {
      isFirstTryObj: {
        isFirstTry: false,
        expiration: new Date('2018-01-01T15:30:00.000Z'),
      },
      expectedResult: true,
    },
  ].forEach(({ isFirstTryObj, expectedResult }) => {
    test(`shouldRetry with isFirstTryObj '${JSON.stringify(
      isFirstTryObj,
    )}' should return '${expectedResult}'`, () => {
      expect(shouldRetry(isFirstTryObj)).toEqual(expectedResult);
    });
  });
});
