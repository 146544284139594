import { fork, put, select, delay } from 'redux-saga/effects';
import get from 'lodash/get';

import isUrl from 'bnc-utilities-js/url/isUrl';
import requestsManager from 'bnc-react-services/managers/RequestsManager';
import propagateGlobalErrors from 'bnc-react-services/utils/propagateErrorsHandler';

import browserHistory from '../../nav/BrowserHistoryManager/index';

import { ROUTES } from '../../utils/constants';
import { SSO_URL_PARAMS } from '../../utils/constants/urlParams';
import {
  STORAGE_EXPIRATION_FIRST_TRY,
  STORAGE_REDIRECT_FIRST_TRY,
} from '../../utils/constants/login';
import urlUtils from '../../utils/urlUtils';
import { getParam, isRedirected, shouldRetry } from './helper';
import { buildPath } from '../../nav/Router/routerHelper';
import * as reducers from '../../globalRedux/reducers/constants';
import { getAppBaseUrl } from '../../utils/configUtils';

import redirectServiceActions from '../redirectService/actions';
import { setReturnToSbipLoginFlag } from '../loginService/actions';
import { showModal } from '../modalService/actions';

import { MODAL_TYPES } from '../../utils/constants/modal';
import { Configs } from '../../configs';
import { getBaseUrl } from '../../utils/templateUtils';
import { hasFeature } from '../../utils/featureUtils';
import { getTemplateName } from '../templateService/selectors';
import API from './api';

function* triggerSessionExpired() {
  yield delay(Configs.AUTH.SAML_EXPIRATION);

  if (hasFeature(getTemplateName(yield select()), 'SESSION_EXPIRED_PAGE')) {
    yield put(
      redirectServiceActions.redirectToPage(
        `${getBaseUrl()}${ROUTES.SESSION_EXPIRED}`,
      ),
    );
  } else {
    // If we change to another route, the back to login will do a back and forth to refresh the SAML
    yield put(setReturnToSbipLoginFlag());
    yield put(showModal(MODAL_TYPES.SESSION_EXPIRED_MODAL));
  }
}

function* watchInitParamsUnavailable() {
  try {
    const { templateName, partnerId } = (yield select())[
      reducers.TEMPLATE_SERVICE_REDUCER
    ];
    const { locale } = (yield select())[reducers.I18N_SERVICE_REDUCER];
    const pathLogin = buildPath(ROUTES.LOGIN, templateName, partnerId);
    // We only want to force params if we are on the actual login page
    if (window.location.pathname === pathLogin) {
      const windowLocation = window.location.href;
      const params = urlUtils.parseParams(windowLocation, SSO_URL_PARAMS);
      // SSO params are missing
      if (isRedirected(params)) {
        yield browserHistory.push(ROUTES.PARAMS_UNAVAILABLE);
      } else {
        let storageFirstTryObj = {};
        const SAMLRequest = getParam(params, SSO_URL_PARAMS.SAML_REQUEST);
        const validateResponse = yield requestsManager.call(
          API.validateSamlFetch,
          SAMLRequest,
        );
        if (!get(validateResponse, 'isSAMLValid', false)) {
          const appUrl = getAppBaseUrl(templateName, partnerId, locale);
          // SSO params are expired, we force a reload to get new ones
          const firstTryObj = JSON.parse(
            sessionStorage.getItem(STORAGE_REDIRECT_FIRST_TRY),
          );
          const isAllowedToRetry = shouldRetry(firstTryObj);
          if (appUrl && isUrl(appUrl) && isAllowedToRetry) {
            const expirationDate = new Date();
            expirationDate.setTime(
              expirationDate.getTime() + +STORAGE_EXPIRATION_FIRST_TRY,
            );
            storageFirstTryObj = {
              isFirstTry: false,
              expiration: expirationDate,
            };
            sessionStorage.setItem(
              STORAGE_REDIRECT_FIRST_TRY,
              JSON.stringify(storageFirstTryObj),
            );
            yield put(redirectServiceActions.redirectToPage(appUrl));
          } else {
            // No URL is defined (or is invalid) to refresh SSO params
            yield browserHistory.push(ROUTES.PARAMS_UNAVAILABLE);
          }
        } else {
          // SSO params are valid, we allow the user to get new parameters
          storageFirstTryObj = {
            isFirstTry: true,
          };
          sessionStorage.setItem(
            STORAGE_REDIRECT_FIRST_TRY,
            JSON.stringify(storageFirstTryObj),
          );
          yield fork(triggerSessionExpired);
        }
      }
    }
  } catch (e) {
    yield requestsManager.call(propagateGlobalErrors, e);
    yield browserHistory.push(ROUTES.PARAMS_UNAVAILABLE);
  }
}

export default {
  watchInitParamsUnavailable,
};
