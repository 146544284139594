// @flow
import * as actionTypes from './actionTypes';
import type {
  LoginFailureAction,
  LoginFlowSuccessAction,
  LoginMfaAction,
  LoginRequestAction,
  LoginReturnToLoginAction,
  LoginSetReturnToSbipLoginFlagAction,
  LoginSuccessAction,
  LogoutAction,
  LoginTriggerHideResendAction,
  LoginSendCodeRequestAction,
  LoginResendCodeRequestAction,
  LoginCodeValidationRequestAction,
  DisplayResendAction,
  HideResendAction,
  LoginLoadIdentitySuccessAction,
  LoginClearLoginErrorMessagesAction,
  LoginRemoveIdentityAction,
  IdentityWithDeviceTokenAction,
  RsaReturnLoginFlag,
  RememberDeviceBneAction,
} from './types';

import type { User } from '../../types/user';

export function loginRequest(
  identity: string,
  password: string,
  remember: boolean,
  requestMFA: boolean = true,
): LoginRequestAction {
  return {
    type: actionTypes.LOGIN_REQUEST,
    identity,
    password,
    remember,
    requestMFA,
  };
}

export function loginSuccess(): LoginSuccessAction {
  return {
    type: actionTypes.LOGIN_SUCCESS,
  };
}

export function loginFailure(sdkError: any): LoginFailureAction {
  return {
    type: actionTypes.LOGIN_FAILURE,
    sdkError,
  };
}

export function logout(forced: boolean = false): LogoutAction {
  return {
    type: actionTypes.LOGIN_LOGOUT,
    forced,
  };
}

export function hideResendOTCSuccessMessage() {
  return {
    type: actionTypes.LOGIN_MFA_HIDE_RESEND_SUCCESS_MESSAGE,
  };
}

export function loginMFA(
  contact: string,
  factors: Array<*>,
  selectedFactor: string,
  factorsHint,
  transactionStatus: string,
  isHardEnrolling: boolean,
): LoginMfaAction {
  return {
    type: actionTypes.LOGIN_MFA,
    contact,
    MFAfactors: factors,
    MFAfactorsHint: factorsHint,
    MFAselected: selectedFactor,
    transactionStatus,
    isHardEnrolling,
  };
}

export function loginFlowSuccess(): LoginFlowSuccessAction {
  return {
    type: actionTypes.LOGIN_FLOW_SUCCESS,
  };
}

export function loadIdentitySuccess(
  cookieValue: Array<string>,
): LoginLoadIdentitySuccessAction {
  return {
    type: actionTypes.LOGIN_LOAD_IDENTITY_SUCCESS,
    cookieValue,
  };
}

export function clearLoginErrorMessages(): LoginClearLoginErrorMessagesAction {
  return {
    type: actionTypes.LOGIN_CLEAR_ERROR_MESSAGES,
  };
}

export function removeIdentity(identity: User): LoginRemoveIdentityAction {
  return {
    type: actionTypes.LOGIN_REMOVE_IDENTITY,
    identity,
  };
}

export function returnToLogin(): LoginReturnToLoginAction {
  return {
    type: actionTypes.LOGIN_RETURN_TO_LOGIN,
  };
}

export function setReturnToSbipLoginFlag(): LoginSetReturnToSbipLoginFlagAction {
  return {
    type: actionTypes.LOGIN_SET_RETURN_SBIP_LOGIN_FLAG,
  };
}

export function triggerHideResend(): LoginTriggerHideResendAction {
  return {
    type: actionTypes.LOGIN_MFA_TRIGGER_HIDE_RESEND,
  };
}

export function loginSendCodeRequest(
  selectedFactor: *,
): LoginSendCodeRequestAction {
  return {
    type: actionTypes.LOGIN_MFA_SEND_CODE_REQUEST,
    MFAselected: selectedFactor.factorType,
  };
}

export function loginResendCodeRequest(
  selectedFactor: *,
): LoginResendCodeRequestAction {
  return {
    type: actionTypes.LOGIN_MFA_RESEND_CODE_REQUEST,
    MFAselected: selectedFactor,
  };
}

export function loginCodeValidationRequest(
  code: string,
  remember: boolean,
  rememberDevice: boolean,
): LoginCodeValidationRequestAction {
  return {
    type: actionTypes.LOGIN_MFA_CODE_VALIDATION_REQUEST,
    code,
    remember,
    rememberDevice,
  };
}

export function displayResend(): DisplayResendAction {
  return {
    type: actionTypes.LOGIN_MFA_DISPLAY_RESEND,
  };
}

export function hideResend(): HideResendAction {
  return {
    type: actionTypes.LOGIN_MFA_HIDE_RESEND,
  };
}

export function updateContact(selectedFactor: string, contact: string) {
  return {
    type: actionTypes.LOGIN_MFA_UPDATE_CONTACT,
    MFAselected: selectedFactor,
    contact,
  };
}

export function putIdentityWithDeviceToken(
  login: string,
  deviceToken: string,
): IdentityWithDeviceTokenAction {
  return {
    type: actionTypes.PUT_IDENTITY_WITH_DEVICE_TOKEN,
    login,
    deviceToken,
  };
}

export function updateLockedStatus(lockedStatus: string) {
  return {
    type: actionTypes.UPDATE_LOCKED_STATUS,
    lockedStatus,
  };
}

export function tickMfaDisplayResendCounter() {
  return {
    type: actionTypes.TICK_MFA_DISPLAY_RESEND_COUNTER,
  };
}

export function startMfaDisplayResendCounter() {
  return {
    type: actionTypes.START_MFA_DISPLAY_RESEND_COUNTER,
  };
}

export function clearOtcResendMessage() {
  return {
    type: actionTypes.CLEAR_OTC_RESEND_MESSAGE,
  };
}

export function userLockedPassword() {
  return {
    type: actionTypes.USER_LOCKED_PASSWORD,
  };
}

export function lockUserPassword() {
  return {
    type: actionTypes.USER_LOCK_PASSWORD,
  };
}

export function unlockUserPassword() {
  return {
    type: actionTypes.USER_UNLOCK_PASSWORD,
  };
}

export function updateLastLoginFailureDate(date: Date) {
  return {
    type: actionTypes.UPDATE_LAST_LOGIN_FAILURE_DATE,
    date,
  };
}

export function saveHashLogin(hashLogin) {
  return {
    type: actionTypes.SAVE_HASH_LOGIN,
    hashLogin,
  };
}

export function trackResendCode(selectedFactor) {
  return {
    type: actionTypes.TRACK_RESEND_SUCCESS,
    selectedFactor,
  };
}

export function setRsaReturnLoginFlag(flagStatus: boolean): RsaReturnLoginFlag {
  return {
    type: actionTypes.RSA_RETURN_LOGIN_FLAG,
    flagStatus,
  };
}

export function setRememberDeviceBne(
  flagStatus: boolean,
): RememberDeviceBneAction {
  return {
    type: actionTypes.REMEMBER_DEVICE_BNE,
    flagStatus,
  };
}
