import { LANG } from '../../../../utils/forgotPasswordUtils/constants';

/**
 * onBlur method specified for CurrentInput
 *
 * @param {*} fieldName form field name
 * @param {*} locale  language locale
 * @param {*} onChange  form onChange method
 * @param {*} onBlur form onBlur method
 */
const onAmountBlur = (fieldName, locale, onChange, onBlur) => event => {
  let fieldValue = event.target.value;
  if (fieldValue) {
    if (locale === LANG.FR) {
      fieldValue = fieldValue.replace(/[.,]/g, '.');
    } else {
      fieldValue = fieldValue.replace(/[,]/g, '');
    }
  }
  const roundedValue = fieldValue
    ? Number.parseFloat(fieldValue).toFixed(2)
    : fieldValue;

  onChange({
    target: {
      name: fieldName,
      value: roundedValue,
    },
  });

  onBlur(event);
};

export default onAmountBlur;
