import { LOG_LEVEL_ENUM } from '../configs';

const stripConsoleLogs = logLevel => {
  if (logLevel < LOG_LEVEL_ENUM.TRACE) {
    console.trace = () => null;
  }

  if (logLevel < LOG_LEVEL_ENUM.DEBUG) {
    console.debug = () => null;
  }

  if (logLevel < LOG_LEVEL_ENUM.INFO) {
    console.group = () => null;
    console.groupEnd = () => null;
    console.log = () => null;
    console.info = () => null;
  }

  if (logLevel < LOG_LEVEL_ENUM.WARN) {
    console.warn = () => null;
  }

  if (logLevel < LOG_LEVEL_ENUM.ERROR) {
    console.error = () => null;
  }
};

export default stripConsoleLogs;
