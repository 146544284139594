// @flow
import React from 'react';

import Right2ColumnsTemplate from '../../models/Right2ColumnsTemplate';
import Header from '../../../organisms/common/Header';
import ResetPasswordException from '../../../molecules/secureKey/ResetPasswordException';
import SecureKeyMedia from '../../../molecules/common/SecureKeyMedia';

const ResetPasswordExceptionTemplate = () => (
  <Right2ColumnsTemplate
    className="secure-key_reset-password-exception"
    header={<Header />}
    main={<ResetPasswordException />}
    assets={<SecureKeyMedia />}
  />
);

export default ResetPasswordExceptionTemplate;
