// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import actionTypes from './actionTypes';
import workers from './workers';

export const workersMapping = createWorkersMapping().takeLatest(
  actionTypes.PARAMS_UNAVAILABLE_INIT,
  workers.watchInitParamsUnavailable,
);

export default createRootSagaFromWorkersMapping(workersMapping);
