import React, { useState } from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { DateFields } from '@bnc-ui/dateFields';
import { FormGroup } from '@bnc-ui/formGroup';
import { InputGroup } from '@bnc-ui/inputGroup';

import { convertDateFieldsBirthDayToDate } from '../../../../utils/formUtils';
import { FORGOT_PASSWORD_FIELDS } from '../../../../utils/forgotPasswordUtils/constants';

const propTypes = {
  intl: intlShape.isRequired,
  formUpdate: PropTypes.func.isRequired,
  triggerFieldValidation: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    email: PropTypes.string,
    birthDate: PropTypes.instanceOf(Date),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
};

const defaultProps = {
  formValues: {
    email: '',
    birthDate: '',
  },
  errors: {},
};

const PersonalInfo = ({
  intl,
  formUpdate,
  triggerFieldValidation,
  formValues,
  errors,
}) => {
  const [isDateFilled, setIsDateFilled] = useState(false);

  const onChange = event => {
    formUpdate(event.target.name, event.target.value);
  };

  const onBlur = event => {
    triggerFieldValidation(event.target.name);
  };

  const onDatePickerFieldChange = (event, date) => {
    // isDateFilled: whether user fills all birthday field components
    setIsDateFilled(date && date.day && date.month && date.year);
    formUpdate('birthDate', convertDateFieldsBirthDayToDate(date));
  };

  const onDatePickerBlur = event => {
    if (isDateFilled) {
      // only validate birthday if all date components are filled
      triggerFieldValidation('birthDate');
    }
  };

  return (
    <div className="forgot-password-form--group">
      <FormGroup
        label={{
          text: intl.formatMessage({ id: 'text.field.identifier' }),
          htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL,
        }}
        validate={{
          hasError: get(
            errors,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL,
          ),
          errorMsg: intl.formatMessage({ id: 'text.error.invalidIdentifier' }),
        }}
      >
        <InputGroup
          inputId={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL}
          inputName={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL}
          type={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL}
          placeholder={intl.formatMessage({
            id: 'text.field.identifierPlaceholder',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={get(
            formValues,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL,
          )}
          maxLength="100"
        />
      </FormGroup>
      <FormGroup
        label={{
          text: intl.formatMessage({ id: 'text.title.birthdate' }),
          htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE,
        }}
        validate={{
          hasError: get(
            errors,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE,
          ),
          errorMsg: intl.formatMessage({ id: 'text.error.invalidBirthDate' }),
        }}
      >
        <DateFields
          id={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE}
          locale={intl.locale}
          value={get(
            formValues,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE,
          )}
          onChange={onDatePickerFieldChange}
          onBlur={onDatePickerBlur}
          ariaLabel={{
            day: intl.formatMessage({ id: 'text.title.birthdate' }),
            month: intl.formatMessage({ id: 'text.title.birthdate' }),
            year: intl.formatMessage({ id: 'text.title.birthdate' }),
          }}
        />
      </FormGroup>
    </div>
  );
};

PersonalInfo.propTypes = propTypes;
PersonalInfo.defaultProps = defaultProps;

export default PersonalInfo;
