import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import BiometryCredentialsDeletionModal from './credentialsDeletionModal';
import userAgentSelectors from '../../../services/userAgentService/selectors';
import { biometrySelector } from '../../../services/biometryService/selectors';

const mapStateToProps = state => ({
  os: userAgentSelectors.getOS(state),
  biometry: biometrySelector(state),
});

export default injectIntl(
  connect(mapStateToProps, null)(BiometryCredentialsDeletionModal),
);
