// @flow
import omit from 'lodash/omit';
import * as actionTypes from './actionTypes';

import type { Action, LoginFormState } from './types';

import { LOGIN_FORM_STEPS } from '../../utils/constants/login';

export const initialState = (): LoginFormState => ({
  // export for test and init
  formId: '',
  isValid: false,
  errors: {},
  formData: {
    identity: '',
    password: '',
    remember: false,
  },
  submitFailed: false,
  initDone: false,
  currentStep: LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP,
  isFirstRender: null,
  hasToFocus: false,
});

export default (state: LoginFormState = initialState(), action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN_FORM_UPDATE:
      return {
        ...state,
        submitFailed: false,
        formData: { ...state.formData, [action.fieldName]: action.fieldValue },
      };

    case actionTypes.LOGIN_FORM_VALIDATION_FAILURE:
      return {
        ...state,
        isValid: false,
        submitFailed: false,
        errors: { ...state.errors, [action.fieldName]: action.errors },
      };

    case actionTypes.LOGIN_FORM_VALIDATION_SUCCESS:
      return {
        ...state,
        isValid: action.isValid,
        submitFailed: false,
        errors: omit(state.errors, action.fieldName),
      };

    case actionTypes.LOGIN_FORM_SUBMIT_REQUEST:
      return {
        ...state,
        submitFailed: false,
      };

    case actionTypes.LOGIN_FORM_SUBMIT_FAILURE:
      return {
        ...state,
        errors: action.errors,
        submitFailed: true,
      };
    case actionTypes.LOGIN_FORM_INIT_REQUEST:
      return { ...state, formId: action.formId };

    case actionTypes.LOGIN_FORM_INIT_DONE:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
        initDone: true,
      };

    case actionTypes.LOGIN_FORM_RESET:
      return initialState();

    case actionTypes.LOGIN_FORM_CHANGE_STEP_REQUEST:
      return {
        ...state,
        hasToFocus: !!action.hasToFocus,
      };

    case actionTypes.LOGIN_FORM_CHANGE_STEP_SUCCESS:
      return {
        ...state,
        currentStep: action.step,
        errors: {},
        submitFailed: false,
        isFirstRender: state.isFirstRender === null,
      };
    default:
      return state;
  }
};
