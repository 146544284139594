// @flow
import React from 'react';

import Right2ColumnsTemplate from '../../models/Right2ColumnsTemplate';

import LoginFooter from '../../../organisms/sbip2/LoginFooter';
import Header from '../../../organisms/whiteLabel/Header';
import LoginSbip2 from '../../../organisms/sbip2/Login';
import ExpirationModal from '../../../organisms/common/ExpirationModal';

import WhiteLabelMedia from '../../../molecules/whiteLabel/WhiteLabelMedia';

const LoginTemplate = () => (
  <Right2ColumnsTemplate
    header={<Header logoTagName="h1" />}
    main={<LoginSbip2 />}
    footer={<LoginFooter />}
    assets={<WhiteLabelMedia />}
    modal={<ExpirationModal />}
  />
);

export default LoginTemplate;
