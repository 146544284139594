import React from 'react';
import PropTypes from 'prop-types';

import { intlShape } from 'react-intl';
import ErrorDisplay from '../../../molecules/common/ErrorDisplay';
import Redirect from '../../../molecules/common/Redirect';

const propTypes = {
  intl: intlShape.isRequired,
  redirectPageUrl: PropTypes.string.isRequired,
};

function NotFoundError(props) {
  const { redirectPageUrl, intl } = props;
  const title = intl.formatMessage({ id: 'text.error.notFound' });
  const message = intl.formatMessage({ id: 'text.message.notFound' });

  return (
    <div className="main-center">
      <ErrorDisplay
        errorTitle={title}
        errorText={message}
        errorButton={redirectPageUrl && <Redirect pageUrl={redirectPageUrl} />}
      />
    </div>
  );
}

NotFoundError.propTypes = propTypes;

export default NotFoundError;
