// @flow
import * as actionTypes from './actionTypes';

import type {
  InitUserAgentDoneAction,
  InitUserAgentRequestAction,
} from './types';
import type { OS, Browser } from '../../types/browser';

export function initUserAgentRequest(): InitUserAgentRequestAction {
  return {
    type: actionTypes.USER_AGENT_INIT_REQUEST,
  };
}

export function initUserAgentDone(
  browser: Browser,
  os: OS,
): InitUserAgentDoneAction {
  return {
    type: actionTypes.USER_AGENT_INIT_DONE,
    browser,
    os,
  };
}
