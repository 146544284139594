// @flow
import React from 'react';
import type { intlShape } from 'react-intl';

import { FormGroup } from '@bnc-ui/formGroup';
import { Input } from '@bnc-ui/input';
import { Button } from '@bnc-ui/button';

import type { MultiFactorAuthFormData } from '../../../../types/forms/multiFactorAuthForm';
import { FORGOT_PASSWORD_FIELDS } from '../../../../utils/forgotPasswordUtils/constants';
import { OTC_CODE_LENGTH } from '../../../../services/multiFactorAuthFormService/formSchema';

// Loading Styles and style Helper
import './style.scss';
import ExpirationModal from '../../../../organisms/common/ExpirationModal';

type Props = {
  intl: intlShape,
  isFetching: boolean,
  formData: MultiFactorAuthFormData,
  formUpdate: (fieldName: string, fieldValue: string) => void,
  triggerSubmit: () => void,
  triggerValidation: (fieldName: string) => void,
  errors: *,
  sdkErrors: *,
  setFormInputs?: (input: any) => void,
  onKeyPress?: (event: any, callback: Function) => void,
};

const defaultProps = {
  setFormInputs: () => {},
  onKeyPress: () => {},
};

const MultiFactorAuthRSAForm = (props: Props) => {
  const {
    intl,
    formUpdate,
    errors,
    sdkErrors,
    triggerSubmit,
    triggerValidation,
    isFetching,
    onKeyPress,
    formData,
    setFormInputs,
  } = props;

  const onChange = event => {
    const { value, name } = event.target;
    formUpdate(name, value);
  };

  const onBlur = event => {
    const fieldName = event.target.name;
    setTimeout(() => validateField(fieldName), 100);
  };

  const onSubmitCode = () => {
    validateField(FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE);
    // $FlowFixMe
    setFormInputs({ [FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE]: {} });
    triggerSubmit();
  };

  const validateField = (fieldName: string) => {
    triggerValidation(fieldName);
  };

  const handleKeyPress = event => {
    if (!isFetching) {
      // $FlowFixMe
      onKeyPress(event, onSubmitCode);
    }
  };

  const rsaError = errors?.code;
  const sdkError = sdkErrors?.code;
  const rsaLabel = intl.formatMessage({ id: 'text.field.rsaToken' });
  const submitLabel = isFetching
    ? intl.formatMessage({ id: 'multiFactorAuth.button.loading' })
    : intl.formatMessage({ id: 'text.button.validationCodeConfirm' });

  return (
    <>
      <div className="rsa-form-labels">
        <label className="dsc-label" htmlFor="code">
          <span>{rsaLabel}</span>
        </label>
      </div>
      <FormGroup
        className="mfa-validation-code"
        validate={{
          hasError: rsaError || sdkError,
        }}
        onKeyPress={handleKeyPress}
      >
        <Input
          className={rsaError || sdkError ? 'error' : ''}
          id={FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE}
          name={FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE}
          placeholder={intl.formatMessage({
            id: 'text.field.rsaTokenPlaceholder',
          })}
          type="text"
          autoComplete="off"
          onChange={onChange}
          aria-invalid={rsaError}
          onBlur={onBlur}
          value={formData.code}
          maxLength={OTC_CODE_LENGTH}
        />
      </FormGroup>
      <Button
        type="submit"
        id="submitOtcForm"
        disabled={isFetching}
        onMouseDown={event => event.preventDefault()}
        onClick={onSubmitCode}
        appearance="primary"
        theme="entreprise"
        className="validate-rsa-button--margins"
        fluid
      >
        {submitLabel}
      </Button>
      <ExpirationModal isShowSessionExpiredModal={false} />
    </>
  );
};

MultiFactorAuthRSAForm.defaultProps = defaultProps;

export default MultiFactorAuthRSAForm;
