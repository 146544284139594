import union from 'lodash/union';
import difference from 'lodash/difference';
import * as actionTypes from './actionTypes';

export const initialState = () => ({
  // export for test
  modalStack: [],
  modalClass: {},
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case actionTypes.SHOW_MODAL:
      return {
        ...state,
        modalClass: {
          ...state.modalClass,
          [action.modalType]: '',
        },
        modalStack: union([action.modalType], state.modalStack),
      };
    case actionTypes.HIDE_MODAL:
      return {
        ...state,
        modalClass: {
          ...state.modalClass,
          [action.modalType]: 'modal-closed',
        },
        modalStack: difference(state.modalStack, [action.modalType]),
      };
    default:
      return state;
  }
};
