// @flow
import React from 'react';

import Right2ColumnsTemplate from '../../models/Right2ColumnsTemplate';

import RewardsLoginFooter from '../../../organisms/rewards/LoginFooter';
import Header from '../../../organisms/rewards/Header';
import LoginRewards from '../../../organisms/rewards/Login';
import ExpirationModal from '../../../organisms/common/ExpirationModal';
import BncMediaRewards from '../../../molecules/rewards/BncMediaRewards';

import './style.scss';

const LoginTemplate = () => (
  <Right2ColumnsTemplate
    header={<Header />}
    main={<LoginRewards className="rewards" />}
    footer={<RewardsLoginFooter className="rewards" />}
    assets={<BncMediaRewards />}
    modal={<ExpirationModal />}
    className="rewards"
  />
);

export default LoginTemplate;
