// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';

import { Alert } from '@bnc-ui/alert';

import { Tagging, TrackAction } from '@nbc-studio/analytics';
import includes from 'lodash/includes';
import get from 'lodash/get';
import type { LoginFormErrors } from '../../../types/forms/loginForm';
import type { SDKError } from '../../../types';

import {
  getErrorMessage,
  clearLoginErrorMessageIfNecessary,
} from '../../../utils/bne/loginFormMessageUtils';
import {
  FRONTEND_ERROR_MESSAGES,
  GENERIC_WITH_CODE,
  GENERIC_WITHOUT_CODE,
} from '../../../utils/authErrorMessages/constants';
import { getAuthErrorMessageId } from '../../../utils/authErrorMessages';
import {
  BNE_EVENT_IDS,
  BNE_EVENT_NAMES,
  BNE_EVENT_SOURCE,
  BNE_UCS_IDS,
  BNE_UCS_STATE,
} from '../../../utils/taggingUtils/bneTaggingConstants';
import {
  getAccurateErrorDescription,
  getFilterKeys,
} from '../../../utils/taggingUtils/helper';

type Props = {
  errors: LoginFormErrors,
  hasValidationError: boolean,
  hasLoginFailure: boolean,
  sdkError: SDKError,
  clearLoginErrorMessages: () => void,
  intl: IntlShape,
  templateName: string,
  isSubmitFailed: boolean,
};

function LoginFormMessage(props: Props) {
  const {
    errors,
    hasValidationError,
    hasLoginFailure,
    sdkError,
    clearLoginErrorMessages,
    intl,
    templateName,
    isSubmitFailed,
  } = props;

  clearLoginErrorMessageIfNecessary(
    hasValidationError,
    hasLoginFailure,
    clearLoginErrorMessages,
  );

  const { message, type } = getErrorMessage(
    hasValidationError,
    hasLoginFailure,
    errors,
    sdkError,
    templateName,
    intl,
  );

  const isTechnicalError =
    hasLoginFailure &&
    !hasValidationError &&
    includes(
      [
        GENERIC_WITH_CODE,
        GENERIC_WITHOUT_CODE,
        ...getFilterKeys('code', FRONTEND_ERROR_MESSAGES),
      ],
      getAuthErrorMessageId(sdkError),
    );

  const errorCode = get(sdkError, 'code', '0000');
  const errorMsg = get(sdkError, 'message', 'Unexpected Error');

  return (
    message &&
    type && (
      <>
        <Alert visible appearance={type} description={message} />
        {(hasValidationError || hasLoginFailure) && isSubmitFailed && (
          <Tagging
            ucsId={BNE_UCS_IDS.FORM_ERROR}
            ucsState={BNE_UCS_STATE.NON_PV}
            eventName={BNE_EVENT_NAMES.FORM_ERROR}
            eventId={BNE_EVENT_IDS.FORM_ERROR}
            eventSource={BNE_EVENT_SOURCE.BNE}
          >
            <TrackAction
              formError={getAccurateErrorDescription(hasLoginFailure, errors)}
              dependencies={[errors]}
            />
          </Tagging>
        )}
        {isTechnicalError && (
          <Tagging
            ucsId={BNE_UCS_IDS.TECHNICAL_ERROR}
            ucsState={BNE_UCS_STATE.NON_PV}
            eventName={BNE_EVENT_NAMES.PAGE_ERROR}
            eventId={BNE_EVENT_IDS.PE}
            eventSource={BNE_EVENT_SOURCE.BNE}
            technicalError={`${errorMsg} (CODE:${errorCode})`}
          >
            <TrackAction />
          </Tagging>
        )}
      </>
    )
  );
}

export default LoginFormMessage;
