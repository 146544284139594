import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import BiometryForm from './BiometryForm';
import {
  biometrySelector,
  errorSelector,
} from '../../../../services/biometryService/selectors';
import type { Dispatch } from '../../../../types/store';
import {
  initBiometry,
  biometryForceLogin,
} from '../../../../services/biometryService/actions';
import { isFetching as isFetchingSelector } from '../../../../services/loginService/selectors';
import { formUpdate } from '../../../../services/loginFormService/actions';

const mapStateToProps = state => ({
  biometry: biometrySelector(state),
  isFetching: isFetchingSelector(state),
  error: errorSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initBiometry: () => dispatch(initBiometry()),
  forcePasswordLogin: () => dispatch(biometryForceLogin()),
  formUpdate: (fieldName, value) => dispatch(formUpdate(fieldName, value)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(BiometryForm),
);
