import {
  getCompatibleBrowserList,
  isBrowserSupported,
  getCurrentQueryString,
  hasUnsupportedBrowserFeature,
} from '../browserUtils';
import { Configs } from '../../configs';

describe('getCompatibleBrowserList', () => {
  const { SUPPORTED_BROWSER_VERSIONS, OS_SUPPORTED } = Configs;

  const testCases = [
    {
      browser: { name: 'Netscape' },
      os: {
        name: 'Windows',
        version: 10,
      },
      result: [
        SUPPORTED_BROWSER_VERSIONS.Chrome,
        SUPPORTED_BROWSER_VERSIONS.Firefox,
        SUPPORTED_BROWSER_VERSIONS.Edge,
      ],
    },
    {
      browser: { name: 'Lynx' },
      os: {
        name: 'Windows',
        version: 7,
      },
      result: [
        SUPPORTED_BROWSER_VERSIONS.Chrome,
        SUPPORTED_BROWSER_VERSIONS.Firefox,
      ],
    },
    {
      browser: { name: 'Opera' },
      os: OS_SUPPORTED.MAC_OS,
      result: [
        SUPPORTED_BROWSER_VERSIONS.Chrome,
        SUPPORTED_BROWSER_VERSIONS.Firefox,
        SUPPORTED_BROWSER_VERSIONS.Safari,
      ],
    },
    {
      browser: SUPPORTED_BROWSER_VERSIONS.Firefox,
      os: OS_SUPPORTED.IOS,
      result: [SUPPORTED_BROWSER_VERSIONS.Firefox],
    },
  ];
  testCases.forEach(({ browser, os, result }) => {
    it(`should return '${result}' for browser '${JSON.stringify(
      browser,
    )}' and os ${JSON.stringify(os)}`, () => {
      expect(getCompatibleBrowserList(browser, os)).toEqual(result);
    });
  });
});

describe('isBrowserSupported', () => {
  const { SUPPORTED_BROWSER_VERSIONS } = Configs;
  it(`should return false when the browser is not in the list`, () => {
    const browser = {
      name: 'Chrominum',
    };
    expect(isBrowserSupported(browser)).toBeFalsy();
  });

  it(`should return true when the browser version is equal to the supported one`, () => {
    const browser = {
      name: 'Chrome',
      version: SUPPORTED_BROWSER_VERSIONS.Chrome.version,
    };
    expect(isBrowserSupported(browser)).toBeTruthy();
  });

  it(`should return false when the browser version is outdated`, () => {
    const browser = {
      name: 'Chrome',
      version: '1',
    };
    expect(isBrowserSupported(browser)).toBeFalsy();
  });

  it(`should return true when the browser version is upper than the current one`, () => {
    const browser = {
      name: 'Chrome',
      version: '999999999',
    };
    expect(isBrowserSupported(browser)).toBeTruthy();
  });
});

test('getCurrentQueryString', () => {
  // We cannot add more relevant tests for this function because Jest doesn’t allow
  // us to modify or mock document.location.
  expect(getCurrentQueryString()).toEqual('');
});

describe('hasUnsupportedBrowserFeature', () => {
  it(`should return false when SecureKey is the template`, () => {
    expect(hasUnsupportedBrowserFeature('SECURE_KEY')).toBeFalsy();
  });

  it(`should return false when SBIP2 is the template`, () => {
    expect(hasUnsupportedBrowserFeature('SBIP2')).toBeTruthy();
  });

  it(`should return false when BNE is the template`, () => {
    expect(hasUnsupportedBrowserFeature('BNE')).toBeFalsy();
  });
});
