import { FORGOT_PASSWORD_FIELDS } from '../../../utils/forgotPasswordUtils/constants';
import generateSelectorTests from '../../../utils/selectorTestHelper';
import {
  errorsSelector,
  executedActionSelector,
  failureSelector,
  formDataSelector,
  formIdSelector,
  initDoneSelector,
  isEmailError,
  isTokenCaptchaError,
  isValidSelector,
  tokenRecaptchaSelector,
  getProductType,
} from '../selectors';

const TEST_SELECTORS = [
  {
    selector: isValidSelector,
    selectorField: 'forgotPasswordForm.isValid',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    selector: errorsSelector,
    selectorField: 'forgotPasswordForm.errors',
    selectorType: 'object',
    defaultValue: {},
  },
  {
    selector: formDataSelector,
    selectorField: 'forgotPasswordForm.formData',
    selectorType: 'object',
    defaultValue: {},
  },
  {
    selector: failureSelector,
    selectorField: 'forgotPasswordForm.failure',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    selector: formIdSelector,
    selectorField: 'forgotPasswordForm.formId',
    selectorType: 'string',
    defaultValue: '',
  },
  {
    selector: initDoneSelector,
    selectorField: 'forgotPasswordForm.initDone',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    selector: isTokenCaptchaError,
    selectorField: 'forgotPasswordForm.errors.tokenCaptcha',
    selectorType: 'string',
    defaultValue: '',
    outputMapper: x => !!x,
  },
  {
    selector: isEmailError,
    selectorField: 'forgotPasswordForm.errors.email',
    selectorType: 'string',
    defaultValue: '',
    outputMapper: x => !!x,
  },
  {
    selector: tokenRecaptchaSelector,
    selectorField: `forgotPasswordForm.formData.${FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA}`,
    selectorType: 'string',
    defaultValue: '',
  },
  {
    selector: executedActionSelector,
    selectorField: `forgotPasswordForm.executedAction`,
    selectorType: 'object',
    defaultValue: {},
  },
  {
    selector: getProductType,
    selectorField: 'forgotPasswordForm.formData.productType',
    selectorType: 'string',
    defaultValue: '',
  },
];

describe('Test Selectors', generateSelectorTests(TEST_SELECTORS));
