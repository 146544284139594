import {
  failureSelector,
  getBackendError,
  isFetching,
  rootSelector,
} from '../selectors';

describe('rootSelector', () => {
  it('should return root selector value', () => {
    [
      {
        state: {
          resetPasswordReducer: {
            something: true,
          },
        },
        result: {
          something: true,
        },
      },
      {
        state: {},
        result: {},
      },
    ].forEach(({ state, result }) =>
      expect(rootSelector(state)).toEqual(result),
    );
  });
});

describe('failureSelector', () => {
  it('should return failure value', () => {
    [
      {
        state: {
          resetPasswordReducer: {
            failure: true,
          },
        },
        result: true,
      },
      {
        state: {
          resetPasswordReducer: {
            failure: false,
          },
        },
        result: false,
      },
    ].forEach(({ state, result }) =>
      expect(failureSelector(state)).toEqual(result),
    );
  });
});

describe('isFetching selector', () => {
  it('should return isFetching value', () => {
    [
      {
        state: {
          resetPasswordReducer: {
            isFetching: true,
          },
        },
        result: true,
      },
      {
        state: {
          resetPasswordReducer: {
            isFetching: false,
          },
        },
        result: false,
      },
      {
        state: {
          resetPasswordReducer: {},
        },
        result: false,
      },
    ].forEach(({ state, result }) => expect(isFetching(state)).toEqual(result));
  });
});

describe('backendError selector', () => {
  it('should return backendError', () => {
    [
      {
        state: {
          resetPasswordReducer: {
            backendError: { error: 'test' },
          },
        },
        result: { error: 'test' },
      },
      {
        state: {
          resetPasswordReducer: {
            backendError: {},
          },
        },
        result: {},
      },
    ].forEach(({ state, result }) =>
      expect(getBackendError(state)).toEqual(result),
    );
  });
});
