// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import * as actionTypes from './actionTypes';
import * as workers from './workers';

export const workersMapping = createWorkersMapping()
  .takeEvery(
    actionTypes.FORM_HANDLER_FORM_INIT_REQUEST,
    workers.watchFormInitRequest,
  )
  .takeLatest(
    actionTypes.FORM_HANDLER_FOCUS_ERROR_INPUT_REQUEST,
    workers.watchFocusOnFirstErrorField,
  )
  .takeEvery(
    actionTypes.FORM_HANDLER_RESET_FORM_REQUEST,
    workers.watchResetFormRequest,
  );

export default createRootSagaFromWorkersMapping(workersMapping);
