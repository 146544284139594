// @flow
import React from 'react';

import SecureKeyResetPasswordExceptionSbip2Template from '../../templates/secureKey/ResetPasswordExceptionTemplate';
import RewardsWLPasswordExceptionSbip2Template from '../../templates/rewardsWhiteLabel/ResetPasswordExceptionTemplate';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';

import './style.scss';

type Props = {
  templateName: string,
};

const ResetPasswordException = (props: Props) => {
  const { templateName } = props;

  const loginTemplates = {
    [TEMPLATES.SECURE_KEY]: <SecureKeyResetPasswordExceptionSbip2Template />,
    [TEMPLATES.REWARDS_WHITE_LABEL]: (
      <RewardsWLPasswordExceptionSbip2Template />
    ),
  };
  return getTemplateComponent(loginTemplates, templateName);
};

export default ResetPasswordException;
