import testReducer from 'bnc-utilities-js/testUtils/reducer';
import modalReducer from '../reducer';

import { showModal, hideModal } from '../actions';

testReducer(modalReducer)
  .initialState()
  .expectDiff({
    modalStack: [],
    modalClass: {},
  });

testReducer(modalReducer)
  .withState({
    modalStack: ['MODAL_1'],
  })
  .on(hideModal('MODAL_1'))
  .expectDiff({
    modalClass: {
      MODAL_1: 'modal-closed',
    },
    modalStack: [],
  });

testReducer(modalReducer)
  .withState({
    modalStack: ['MODAL_1', 'MODAL_2'],
  })
  .on(hideModal('MODAL_1'))
  .expectDiff({
    modalClass: {
      MODAL_1: 'modal-closed',
    },
    modalStack: ['MODAL_2'],
  });

testReducer(modalReducer)
  .withState({
    modalStack: ['MODAL_1'],
  })
  .on(showModal('MODAL_2'))
  .expectDiff({
    modalClass: {
      MODAL_2: '',
    },
    modalStack: ['MODAL_2', 'MODAL_1'],
  });
