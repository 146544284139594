// @flow
import * as actionTypes from './actionTypes';

import type { Config } from '../../types/configs';

import type {
  RetrieveConfigsRequestAction,
  RetrieveConfigsFailureAction,
  RetrieveConfigsSuccessAction,
} from './types';

export function retrieveConfigsRequest(): RetrieveConfigsRequestAction {
  return {
    type: actionTypes.CONFIGS_SERVICE_RETRIEVE_CONFIGS_REQUEST,
  };
}

export function retrieveConfigsSuccess(
  configs: Config,
): RetrieveConfigsSuccessAction {
  return {
    type: actionTypes.CONFIGS_SERVICE_RETRIEVE_CONFIGS_SUCCESS,
    configs,
  };
}

export function retrieveConfigsFailure(err: any): RetrieveConfigsFailureAction {
  return {
    type: actionTypes.CONFIGS_SERVICE_RETRIEVE_CONFIGS_FAILURE,
    err,
  };
}
