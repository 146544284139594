import { getUserLockedCookie, removeUserLockedCookie } from '../lockUserCookie';
import * as cookie from '../cookie';

jest.mock('../cookie', () => ({
  get: jest.fn(),
  set: jest.fn(),
  remove: jest.fn(),
}));

describe('getUserLockedCookie', () => {
  it('cookie get to be called', () => {
    getUserLockedCookie();
    expect(cookie.get).toHaveBeenCalled();
  });
});

describe('removeUserLockedCookie', () => {
  it('cookie remove to be called', () => {
    removeUserLockedCookie();
    expect(cookie.remove).toHaveBeenCalled();
  });
});
