import { combineReducers } from 'redux';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import globalErrorServiceActionTypes from 'bnc-react-services/services/globalErrorService/actionTypes';
import service from '..';
import saga from '../saga';
import reducer from '../reducer';
import {
  tokenCaptchaRequest,
  tokenCaptchaRequestSuccess,
  tokenCaptchaRequestFailure,
} from '../actions';
import { tokenSelector, failureSelector } from '../selectors';
import api from '../api';
import token from '../mock';

const rootReducer = combineReducers({ [service.namespace]: reducer });

const recaptcha = '3ntc4i234ft6oyno78';

test('tokenCaptchaRequest should call API and dispatch tokenCaptchaRequestSuccess', async () => {
  const result = await expectSaga(saga)
    .withReducer(rootReducer)
    .provide([[matchers.call.fn(api.tokenCaptchaFetch, recaptcha), token]])
    .call(api.tokenCaptchaFetch, recaptcha, 'toto')
    .put(tokenCaptchaRequestSuccess(token, 'toto'))
    .dispatch(tokenCaptchaRequest(recaptcha, 'toto'))
    .run();

  expect(tokenSelector(result.storeState)).toEqual(token);
});

test('tokenCaptchaRequest should dispatch global error and tokenCaptchaRequestFailure if API throws an error', async () => {
  const error = new Error('some error');
  const result = await expectSaga(saga)
    .withReducer(rootReducer)
    .provide([
      [
        matchers.call.fn(api.tokenCaptchaFetch, recaptcha),
        Promise.reject(error),
      ],
    ])
    .call(api.tokenCaptchaFetch, recaptcha, 'toto')
    .put({
      error,
      type: globalErrorServiceActionTypes.BNC_GLOBAL_ERROR_CAUGHT,
    })
    .put(tokenCaptchaRequestFailure())
    .dispatch(tokenCaptchaRequest(recaptcha, 'toto'))
    .run();

  expect(failureSelector(result.storeState)).toEqual(true);
});
