import React, { useLayoutEffect, useState } from 'react';
import type { IntlShape } from 'react-intl';
import { Lock } from '@nbc-design/icons/lib/web/Lock';
import { Button } from '@bnc-ui/button';
import { Text } from '@bnc-ui/text';
import { Link } from '@bnc-ui/link';
import { Alert } from '@bnc-ui/alert';
import Greeting from '../../../common/Greeting';
import Biometry from '../../../../services/biometryService/biometry';

import {
  launchBiometryValidation,
  deleteBiometryCredentials,
} from '../../../../services/biometryService/webviewActions';
import BiometryInput from '../../../../organisms/common/BiometryInput';

import './style.scss';
import BiometryCredentialsDeletionModal from '../../../biometry/CredentialsDeletionModal';
import BiometryErrorModal from '../../../biometry/ErrorModal';
import type { BiometryError } from '../../../../services/biometryService/types';
import MobileBottomLinks from '../../../../organisms/bne/MobileBottomLinks';

type Props = {
  intl: IntlShape,
  biometry: Biometry,
  initBiometry: () => void,
  forcePasswordLogin: () => void,
  formUpdate: (fieldName: string, value: string | boolean) => void,
  isFetching: boolean,
  error: BiometryError,
};

const BiometryForm = (props: Props) => {
  const {
    intl,
    biometry,
    error,
    initBiometry,
    forcePasswordLogin,
    formUpdate,
    isFetching,
  } = props;

  const [
    showDeletionCredentialsModal,
    setShowDeletionCredentialsModal,
  ] = useState(false);

  const hideModalAndForcePasswordLogin = () => {
    deleteBiometryCredentials(biometry);
    setShowDeletionCredentialsModal(false);
  };

  const loginWithPassword = () => {
    formUpdate('identityBne', '');
    formUpdate('identity', '');
    forcePasswordLogin();
  };

  useLayoutEffect(() => {
    initBiometry();
  }, [initBiometry]);

  const LoginButton = () => (
    <Button
      appearance="primary"
      theme="entreprise"
      fluid
      size="large"
      disabled={isFetching}
      onClick={() => launchBiometryValidation(intl, biometry)}
      className="biometry-login__submit"
      type="button"
      id="login__submit"
      loading={{
        isLoading: isFetching,
        ariaLabel: intl.formatMessage({ id: 'loginForm.button.loading' }),
      }}
      icon={<Lock size="small" title="lock" />}
    >
      {intl.formatMessage({ id: 'text.button.login' })}
    </Button>
  );

  const UsePasswordLink = () => (
    <div className="link-native-password">
      <Text size="small" className="link-native-password__padding">
        <Link
          onClick={() => loginWithPassword()}
          id="link-native-password__link"
          underlined="true"
          tabIndex="0"
        >
          {intl.formatMessage({
            id: 'mobile.biometric.native.password.button',
          })}
        </Link>
      </Text>
    </div>
  );

  const Modals = () => (
    <>
      {biometry && (
        <>
          <BiometryCredentialsDeletionModal
            isShow={showDeletionCredentialsModal}
            onValidation={hideModalAndForcePasswordLogin}
            onCancel={() => setShowDeletionCredentialsModal(false)}
          />
          <BiometryErrorModal />
        </>
      )}
    </>
  );

  const getErrorDescription = () => {
    return intl.formatMessage({
      id: 'mobile.biometric.deleteCredentials.error',
    });
  };

  return (
    <div className="biometry-login">
      <h2 className="a11y-hide">
        {intl.formatMessage({ id: 'text.aria.greetingsContext' })}
      </h2>
      <Greeting tagName="span" className="title" />
      {error && !error.openInModal && (
        <Alert
          appearance="error"
          visible
          description={getErrorDescription()}
          className="biometry-login__error"
        />
      )}
      <div className="combo">
        {biometry && (
          <BiometryInput
            biometry={biometry}
            onDeletionClick={() => setShowDeletionCredentialsModal(true)}
          />
        )}
        <UsePasswordLink />
      </div>
      <LoginButton />
      <MobileBottomLinks />
      <Modals />
    </div>
  );
};

export default BiometryForm;
