// @flow
import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';
import Footer from '../../../organisms/common/Footer';
import Header from '../../../organisms/common/Header';
import {
  COMPONENT_TYPE,
  getComponent,
} from '../../common/ForgotPasswordSetup/forgotPasswordSetup';
import TEMPLATES from '../../../utils/constants/template';

const ForgotPasswordTemplate = (props: Props) => {
  const { currentStep } = props;
  const MainComponent = getComponent(currentStep, TEMPLATES.ORION_WHITE_LABEL);
  const FooterComponent = getComponent(
    currentStep,
    TEMPLATES.ORION_WHITE_LABEL,
    COMPONENT_TYPE.FOOTER,
  );

  return (
    <FullScreenFooterTemplate
      header={<Header hasSwitchLang={false} />}
      main={<MainComponent />}
      footer={<Footer content={<FooterComponent />} />}
    />
  );
};

export default ForgotPasswordTemplate;
