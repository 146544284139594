import { getErrorDescription } from '../helper';
import { FORM_LOGIN_FIELD_ERROR } from '../constants';

describe('Get description error to tagging lunch  ', () => {
  it('should return error message - field email ', () => {
    const errors = { identity: ['identity-error-msg'] };
    expect(getErrorDescription(false, errors)).toEqual(
      `identity:${FORM_LOGIN_FIELD_ERROR.IDENTITY}`,
    );
  });

  it('should return error message - field password ', () => {
    const errors = { password: ['password-error-msg'] };
    expect(getErrorDescription(false, errors)).toEqual(
      `password:${FORM_LOGIN_FIELD_ERROR.PASSWORD}`,
    );
  });

  it('should return error login fail message', () => {
    expect(getErrorDescription(true, {})).toEqual('email or password invalid');
  });
});
