import selectors from '../selectors';

describe('getUserAgentState', () => {
  it('should return state if they are available', () => {
    const state = {
      userAgentServiceReducer: { test: 1 },
    };
    expect(selectors.getUserAgentState(state)).toEqual({ test: 1 });
  });

  it('should return {} if state are not available', () => {
    const state = {};
    expect(selectors.getUserAgentState(state)).toEqual({});
  });

  it('should return module state if they are available', () => {
    const modulePath = 'front_end.sso';

    const state = {
      front_end: {
        sso: {
          userAgentServiceReducer: { test: 1 },
        },
      },
    };
    expect(selectors.getUserAgentState(state, modulePath)).toEqual({ test: 1 });
  });
});

describe('getBrowserName', () => {
  it('should return Something if they are available', () => {
    const state = {
      userAgentServiceReducer: { browser: { name: 'Something' } },
    };
    expect(selectors.getBrowserName(state)).toEqual('Something');
  });

  it('should return empty string if state are not available', () => {
    const state = {};
    expect(selectors.getBrowserName(state)).toEqual('');
  });
});

describe('getBrowserVersion', () => {
  it('should return version if they are available', () => {
    const state = {
      userAgentServiceReducer: { browser: { version: 'v1' } },
    };
    expect(selectors.getBrowserVersion(state)).toEqual('v1');
  });

  it('should return empty string if state are not available', () => {
    const state = {};
    expect(selectors.getBrowserVersion(state)).toEqual('');
  });
});

describe('getOS', () => {
  it('should return OS object if it available', () => {
    const os = { name: 'Android', version: '1.1.0' };
    const state = {
      userAgentServiceReducer: { os },
    };
    expect(selectors.getOS(state)).toEqual({
      name: 'Android',
      version: '1.1.0',
    });
  });

  it('should return empty string if state are not available', () => {
    const state = {};
    expect(selectors.getOS(state)).toEqual({});
  });
});
