// @flow
import React, { useEffect } from 'react';
import { Constants, Icon, LinkButton } from 'bnc-react-components';
import { Button } from '@bnc-ui/button';
import { Heading } from '@bnc-ui/heading';
import { Text } from '@bnc-ui/text';
import get from 'lodash/get';

import type { IntlShape } from 'react-intl';
import type { User } from '../../../../types/user';

import UserIdentity from '../../../common/UserIdentity';

import { LOGIN_FORM_STEPS } from '../../../../utils/constants/login';

import { WELCOME_ANNOUNCE_DELAY } from '../../../../utils/constants/forms';

import { getWelcomeMsgKey } from '../../../../utils/liveAnnouncer/helper';

type Props = {
  className?: string,
  intl: IntlShape,
  changeStepRequest: (nextStep: string, hasToFocus: boolean) => void,
  formUpdate: (fieldName: string, value: string) => void,
  identities: Array<User>,
  isDeletingIdentities?: boolean,
  isFirstRender: ?boolean,
  announceAlertMessage: (message: string) => void,
  hasToFocus: boolean,
  partnerName: string,
  isMaximumReached: boolean,
  informationalMessage: string,
};

const defaultProps = {
  className: '',
  isDeletingIdentities: false,
};

const LoginWithIdPartialContent = (props: Props) => {
  const {
    intl,
    changeStepRequest,
    formUpdate,
    identities,
    className,
    isDeletingIdentities,
    isFirstRender,
    announceAlertMessage,
    hasToFocus,
    partnerName,
    isMaximumReached,
    informationalMessage,
  } = props;

  useEffect(() => {
    if (hasToFocus) {
      // Focus first identity button when component is rendered
      const firstIdentity = document.querySelector(
        '.identities__list .identity button',
      );
      if (firstIdentity) {
        firstIdentity.focus();
      }
    }
    if (isFirstRender === null || isFirstRender) {
      setTimeout(
        () => announceAlertMessage(getWelcomeMsgKey(partnerName)),
        WELCOME_ANNOUNCE_DELAY,
      );
    }
  }, [hasToFocus, isFirstRender, partnerName, announceAlertMessage]);

  const connectWithOtherId = () => {
    changeStepRequest(LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP, true);
  };

  const onClickIdentity = itemSelected => {
    formUpdate('identity', get(itemSelected, 'login', ''));
    changeStepRequest(LOGIN_FORM_STEPS.PASSWORD_STEP, true);
  };

  const renderListIdentities = () =>
    identities.map(identity => (
      <div className="combo__entry identity" key={identity.login}>
        <Button
          onClick={() => onClickIdentity(identity)}
          aria-label={intl.formatMessage(
            { id: 'text.aria.fieldSelectIdentity' },
            {
              email: identity.login,
            },
          )}
        >
          <UserIdentity user={identity} isDeletable={isDeletingIdentities} />
        </Button>
      </div>
    ));

  const maxReachText = `${intl.formatMessage({
    id: 'text.message.maximumSaved',
  })} ${intl.formatMessage({
    id: 'text.message.deleteOthers',
  })}`;

  const renderMaximumReached = () => {
    if (isMaximumReached) {
      return (
        <div className="maximum-reached">
          <Text secondary>{maxReachText}</Text>
        </div>
      );
    }
    return null;
  };

  const getAriaLabelNewIdentity = () => {
    let ariaLabelNewIdentity = intl.formatMessage({
      id: 'text.button.newIdentity',
    });
    if (isMaximumReached) {
      const ariaMaxSaved = intl.formatMessage({
        id: 'text.message.maximumSaved',
      });
      const ariaDeleteOthers = intl.formatMessage({
        id: 'text.message.deleteOthers',
      });
      ariaLabelNewIdentity = `${ariaLabelNewIdentity}.${ariaMaxSaved}.${ariaDeleteOthers}`;
    }
    return ariaLabelNewIdentity;
  };

  return (
    <div className={className}>
      <Heading className="title greeting" component="h2">
        {intl.formatMessage({ id: 'text.title.chooseIdentity' })}
      </Heading>
      {informationalMessage && <Text>{informationalMessage}</Text>}
      <div className="combo identities__list">
        {renderListIdentities()}
        {!isDeletingIdentities && (
          <div className="combo__entry combo__entry--add">
            <Button
              onClick={connectWithOtherId}
              aria-label={getAriaLabelNewIdentity()}
            >
              <div className="addIdentity__icon">
                <Icon name={Constants.ICONS.ACCORDION.OPEN} />
              </div>
              <div className="addIdentity__content">
                {intl.formatMessage({ id: 'text.button.newIdentity' })}
              </div>
            </Button>
          </div>
        )}
      </div>
      {renderMaximumReached()}
      <LinkButton
        label={intl.formatMessage({ id: 'text.link.deleteIdentity' })}
        onClick={() =>
          changeStepRequest(LOGIN_FORM_STEPS.DELETE_IDENTITIES_STEP, true)
        }
      />
    </div>
  );
};

LoginWithIdPartialContent.defaultProps = defaultProps;

export default LoginWithIdPartialContent;
