import get from 'lodash/get';
import selectors from '../selectors';
import {
  TEMPLATE_SERVICE_REDUCER,
  I18N_SERVICE_REDUCER,
} from '../../../globalRedux/reducers/constants';
import { Configs } from '../../../configs';

describe('getRedirectPage', () => {
  it('should return appropiate state value if contexte SBIP', () => {
    const templateName = 'SBIP2';
    const locale = 'fr';
    const expectedURL = get(
      Configs,
      ['TEMPLATES', templateName, 'APP_URL', locale.toUpperCase()],
      '',
    );

    const state = {
      [TEMPLATE_SERVICE_REDUCER]: {
        templateName,
        partnerId: '',
      },
      [I18N_SERVICE_REDUCER]: { locale },
    };
    expect(selectors.getRedirectPage(state)).toEqual(expectedURL);
  });

  it('should return appropiate state value if contexte NATGO', () => {
    const templateName = 'NATGO';
    const locale = 'fr';
    const expectedURL = get(
      Configs,
      ['TEMPLATES', templateName, 'APP_URL', locale.toUpperCase()],
      '',
    );

    const state = {
      [TEMPLATE_SERVICE_REDUCER]: {
        templateName,
        partnerId: '',
      },
      [I18N_SERVICE_REDUCER]: { locale },
    };
    expect(selectors.getRedirectPage(state)).toEqual(expectedURL);
  });

  it('should return appropiate state value if contexte WHITE LABEL', () => {
    const templateName = 'SBIP2_WHITE_LABEL';
    const locale = 'fr';
    const partnerId = 'f55';
    const expectedURL = get(
      Configs,
      ['TEMPLATES', templateName, 'APP_URL', locale.toUpperCase()],
      '',
    )
      .concat('/')
      .concat(partnerId);

    const state = {
      [TEMPLATE_SERVICE_REDUCER]: {
        templateName,
        partnerId,
      },
      [I18N_SERVICE_REDUCER]: { locale },
    };
    expect(selectors.getRedirectPage(state)).toEqual(expectedURL);
  });

  it('should return appropiate state value if contexte SECURE KEY', () => {
    const templateName = 'SECURE_KEY';
    const locale = 'fr';
    const expectedURL = null;

    const state = {
      [TEMPLATE_SERVICE_REDUCER]: {
        templateName,
        partnerId: '',
      },
      [I18N_SERVICE_REDUCER]: { locale },
    };
    expect(selectors.getRedirectPage(state)).toEqual(expectedURL);
  });
});
