const frontEndConfigs =
  window.cfg || JSON.parse(process.env.REACT_APP_FRONT_END_CONFIG);

const {
  ACCESS_ENDPOINT,
  DEBUG_MODE,
  AUTH_CLIENT_ID,
  AUTH_AUTHORIZATION_ID,
  AUTH_SAML_EXPIRATION,
  AUTH_MFA_CODE_EXPIRATION,
  AUTH_MFA_CODE_EXPIRATION_SMS,
  AUTH_MFA_PAGE_CHOICE_EXPIRATION,
  RECAPTCHA_SITE_KEY,
  RECAPTCHA_GOOGLE_ENDPOINT,
  RECAPTCHA_SITE_KEY_V2_INVISIBLE,
  RECAPTCHA_EXCHANGE_TOKEN_ENDPOINT,
  RECAPTCHA_FORGOT_PWD_NBFG_ID,
  RECAPTCHA_FORGOT_PWD_TOKEN_NBFG_ID,
  SECURE_KEY,
  BAO,
  WHITE_LABEL,
  NATGO,
  REWARDS_WHITE_LABEL,
  REWARDS,
  SUPPORTED_BROWSER_VERSIONS,
  OS_SUPPORTED,
  NO_HOMOLOGATION_IE11,
  CONFIG_SERVER_ENDPOINT,
  DTM_MAPPING,
  DAM_RESOURCES_BASE_URL,
  CHATBOT = {},
  IAMX_ENDPOINT,
  DATADOG_RUM,
  MCO_BNC_MAIN_SCRIPT_URL,
  MCO_BNC_IMAGE_SAFETY_BELT_URL,
  MCO_BNC_CSS_SAFETY_BELT_URL,
} = frontEndConfigs;

const {
  SIGN_UP_PATH: SBIP2_SIGN_UP_PATH,
  EXTERNAL_URLS: SBIP2_EXTERNAL_URLS,
  ASSISTANCE,
} = frontEndConfigs.SBIP2;

const { EXTERNAL_URLS: BNE_EXTERNAL_URLS } = frontEndConfigs.BNE;

// Handeling localhost using ci-develop configs
let { TEMPLATES } = frontEndConfigs;
if (process.env.NODE_ENV === 'development') {
  // This code will not be bundled in production, we override the regex from openshift in order to match with localhost
  const isEmpty = require('lodash/isEmpty');
  if (!isEmpty(process.env.REACT_APP_LOCAL_FRONT_END_CONFIG)) {
    const localConfigs = JSON.parse(
      process.env.REACT_APP_LOCAL_FRONT_END_CONFIG || {},
    );
    TEMPLATES = { ...localConfigs.TEMPLATES };
  }
}

export const LOG_LEVEL_ENUM = {
  DISABLE: 0,
  ERROR: 1, // alias of 'exception'
  WARN: 2,
  INFO: 3, // alias of 'info'
  DEBUG: 4,
  TRACE: 5,
};

export const Configs = {
  DEBUG_MODE,
  LOG_LEVEL: DEBUG_MODE ? LOG_LEVEL_ENUM.DEBUG : LOG_LEVEL_ENUM.WARN,
  AUTH: {
    OKTA_BASE_URL: IAMX_ENDPOINT.OKTA_BASE_URL,
    AUTH_BASE_URL: IAMX_ENDPOINT.AUTH_BASE_URL,
    CLIENT_ID: AUTH_CLIENT_ID,
    AUTHORIZATION_ID: AUTH_AUTHORIZATION_ID,
    SAML_EXPIRATION: AUTH_SAML_EXPIRATION,
    MFA_CODE_EXPIRATION: AUTH_MFA_CODE_EXPIRATION,
    MFA_CODE_EXPIRATION_SMS: AUTH_MFA_CODE_EXPIRATION_SMS,
    MFA_PAGE_CHOICE_EXPIRATION: AUTH_MFA_PAGE_CHOICE_EXPIRATION,
  },
  SBIP2: {
    ENDPOINT: {
      SIGN_UP: {
        URL: SBIP2_SIGN_UP_PATH,
      },
    },
    EXTERNAL_URLS: {
      // todo move away
      CONTACT_US: {
        URL: {
          fr: SBIP2_EXTERNAL_URLS ? SBIP2_EXTERNAL_URLS.CONTACT_US.FR : '',
          en: SBIP2_EXTERNAL_URLS ? SBIP2_EXTERNAL_URLS.CONTACT_US.EN : '',
        },
      },
      DEMO: {
        URL: {
          fr: SBIP2_EXTERNAL_URLS ? SBIP2_EXTERNAL_URLS.DEMO.FR : '',
          en: SBIP2_EXTERNAL_URLS ? SBIP2_EXTERNAL_URLS.DEMO.EN : '',
        },
      },
      BANK_NOTICE: {
        URL: {
          fr: SBIP2_EXTERNAL_URLS ? SBIP2_EXTERNAL_URLS.BANK_NOTICE.FR : '',
          en: SBIP2_EXTERNAL_URLS ? SBIP2_EXTERNAL_URLS.BANK_NOTICE.EN : '',
        },
      },
      FIND_CLOSEST_BRANCH: {
        URL: {
          fr: SBIP2_EXTERNAL_URLS
            ? SBIP2_EXTERNAL_URLS.FIND_CLOSEST_BRANCH.FR
            : '',
          en: SBIP2_EXTERNAL_URLS
            ? SBIP2_EXTERNAL_URLS.FIND_CLOSEST_BRANCH.EN
            : '',
        },
      },
      LOGIN_VIDEO: {
        ID: 'login.video.link',
        URL: SBIP2_EXTERNAL_URLS ? SBIP2_EXTERNAL_URLS.LOGIN_VIDEO : '',
      },
      HELP_PAGE: {
        ID: 'helpPage.link',
        URL: {
          fr: SBIP2_EXTERNAL_URLS ? SBIP2_EXTERNAL_URLS.HELP.FR : '',
          en: SBIP2_EXTERNAL_URLS ? SBIP2_EXTERNAL_URLS.HELP.EN : '',
        },
      },
      MAKE_AN_APPOINTMENT: {
        URL: {
          fr: SBIP2_EXTERNAL_URLS
            ? SBIP2_EXTERNAL_URLS.MAKE_AN_APPOINTMENT.FR
            : '',
          en: SBIP2_EXTERNAL_URLS
            ? SBIP2_EXTERNAL_URLS.MAKE_AN_APPOINTMENT.EN
            : '',
        },
      },
    },
    ASSISTANCE,
  },
  RECAPTCHA: {
    SITE_KEY: RECAPTCHA_SITE_KEY,
    GOOGLE_ENDPOINT: RECAPTCHA_GOOGLE_ENDPOINT,
    SITE_KEY_V2_INVISIBLE: RECAPTCHA_SITE_KEY_V2_INVISIBLE,
    EXCHANGE_TOKEN_ENDPOINT: RECAPTCHA_EXCHANGE_TOKEN_ENDPOINT,
    FORGOT_PWD_NBFG_ID: RECAPTCHA_FORGOT_PWD_NBFG_ID,
    FORGOT_PWD_TOKEN_NBFG_ID: RECAPTCHA_FORGOT_PWD_TOKEN_NBFG_ID,
  },
  SECURE_KEY,
  BNE: {
    EXTERNAL_URLS: {
      // todo move away
      REGISTER_URL: {
        URL: {
          fr: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.REGISTER_URL.FR : '',
          en: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.REGISTER_URL.EN : '',
        },
      },
      LEGAL_URL: {
        URL: {
          fr: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.LEGAL_URL.FR : '',
          en: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.LEGAL_URL.EN : '',
        },
      },
      CONFIDENTIALITY_URL: {
        URL: {
          fr: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.CONFIDENTIALITY_URL.FR : '',
          en: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.CONFIDENTIALITY_URL.EN : '',
        },
      },
      COOKIES_URL: {
        URL: {
          fr: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.COOKIES_URL.FR : '',
          en: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.COOKIES_URL.EN : '',
        },
      },
      SECURITY_URL: {
        URL: {
          fr: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.SECURITY_URL.FR : '',
          en: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.SECURITY_URL.EN : '',
        },
      },
      ENTERPRISE_HOME_URL: {
        URL: {
          fr: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.ENTERPRISE_HOME_URL.FR : '',
          en: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.ENTERPRISE_HOME_URL.EN : '',
        },
      },
      FORGOT_PASSWORD_URL: {
        URL: {
          A: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.FORGOT_PASSWORD_URL?.A : '',
          B: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.FORGOT_PASSWORD_URL?.B : '',
          C: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.FORGOT_PASSWORD_URL?.C : '',
          D: BNE_EXTERNAL_URLS ? BNE_EXTERNAL_URLS.FORGOT_PASSWORD_URL?.D : '',
        },
      },
      FORGOT_PASSWORD_DEFAULT_URL: BNE_EXTERNAL_URLS
        ? BNE_EXTERNAL_URLS.FORGOT_PASSWORD_URL
        : '',
    },
  },
  WHITE_LABEL,
  NATGO,
  REWARDS_WHITE_LABEL,
  REWARDS,
  BAO,
  API: {
    ENDPOINT: {
      CONFIG_SERVER: {
        ENDPOINT: CONFIG_SERVER_ENDPOINT,
      },
      ACCESS: ACCESS_ENDPOINT,
      IAMX: {
        VERIFY_USER: `${IAMX_ENDPOINT.BASE_URL}${IAMX_ENDPOINT.API.VERIFY_USER}`,
        SEND_OTC: `${IAMX_ENDPOINT.BASE_URL}${IAMX_ENDPOINT.API.SEND_OTC}`,
        VERIFY_OTC: `${IAMX_ENDPOINT.BASE_URL}${IAMX_ENDPOINT.API.VERIFY_OTC}`,
        SET_PASSWORD: `${IAMX_ENDPOINT.BASE_URL}${IAMX_ENDPOINT.API.SET_PASSWORD}`,
        GET_POLICY_RULES_SBIP: `${IAMX_ENDPOINT.INGRESS_GATEWAY_URL}${IAMX_ENDPOINT.API.GET_POLICIES.SBIP}`,
      },
    },
  },
  TEMPLATES,
  SUPPORTED_BROWSER_VERSIONS,
  OS_SUPPORTED,
  NO_HOMOLOGATION_IE11,
  DTM_MAPPING,
  DAM_RESOURCES_BASE_URL,
  CHATBOT,
  DATADOG_RUM,
  MCO_BNC_MAIN_SCRIPT_URL,
  MCO_BNC_IMAGE_SAFETY_BELT_URL,
  MCO_BNC_CSS_SAFETY_BELT_URL,
};
