import React from 'react';

const LockLogo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="132"
    viewBox="0 0 120 132"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#E5F1F5"
        d="M13.6 51.9h72.8c5.965 0 10.8 4.835 10.8 10.8v19.766c-11.356-.067-18.802 3.889-22.339 11.866-3.537 7.978-2.095 17.567 4.324 28.768H13.6c-5.965 0-10.8-4.835-10.8-10.8V62.7c0-5.965 4.835-10.8 10.8-10.8z"
      />
      <path
        fill="#D5E9EE"
        d="M62.004 51.9H86.4c5.965 0 10.8 4.835 10.8 10.8v19.766c-11.32-.067-18.742 3.889-22.267 11.866-3.526 7.978-2.089 17.567 4.31 28.768h-17.24c7.902-27.09 7.902-50.823 0-71.2z"
      />
      <path
        stroke="#00314D"
        strokeLinecap="round"
        strokeWidth="3.2"
        d="M66.468 122.81h-52.37c-6.409 0-11.604-5.212-11.604-11.641V63.277c0-6.428 5.195-11.64 11.605-11.64h71.443c6.41 0 11.604 5.212 11.604 11.64v11"
      />
      <path
        stroke="#E21F26"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.21"
        d="M19.61 51.604V32.413c0-16.836 13.603-30.48 30.386-30.48 16.78 0 30.383 13.644 30.383 30.48v18.794"
      />
      <path
        stroke="#00314D"
        strokeLinecap="round"
        strokeWidth="3.2"
        d="M19.61 51.604V32.413c0-16.836 13.603-30.48 30.386-30.48 16.78 0 30.383 13.644 30.383 30.48v18.794"
      />
      <path
        stroke="#00314D"
        strokeLinecap="round"
        strokeWidth="3.2"
        d="M67.19 51.436l.085-19.297c0-9.651-7.751-17.503-17.28-17.503-9.528 0-17.28 7.852-17.28 17.503l-.043 19.473"
      />
      <path
        fill="#00314D"
        d="M57.995 81.777c0-4.41-3.582-7.984-8-7.984s-8 3.575-8 7.984c0 2.874 1.538 5.373 3.82 6.778v8.405a4.038 4.038 0 0 0 4.04 4.033 4.038 4.038 0 0 0 4.042-4.033v-8.252c2.435-1.37 4.098-3.944 4.098-6.931"
      />
      <path
        stroke="#00314D"
        strokeLinecap="round"
        strokeWidth="3.2"
        d="M112.953 90.891a23.62 23.62 0 0 1 5.356 15.033c0 13-10.403 23.536-23.235 23.536-12.833 0-23.235-10.537-23.235-23.536 0-13 10.402-23.539 23.235-23.539 3.26 0 6.366.682 9.183 1.91"
      />
      <path
        stroke="#00314D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.2"
        d="M84.857 107.272l10.173 11.526 17.925-27.907"
      />
    </g>
  </svg>
);

export default LockLogo;
