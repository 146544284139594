import urlUtils from '../urlUtils';

jest.mock('../../nav/BrowserHistoryManager/index.js', () => ({
  browserHistoryManager: jest.fn(),
}));

jest.mock('../../nav/Router/routerHelper', () => ({
  getActivePartnerIds: jest.fn(),
}));

describe('parseParams', () => {
  [
    {
      url: 'htttp://www.sso.com?RelayState=12345&SAMLRequest=67890',
      expectedResults: {
        SAMLRequest: '67890',
        RelayState: '12345',
      },
    },
    {
      url: 'htttp://www.sso.com/',
      expectedResults: {},
    },
    {
      url: '',
      expectedResults: {},
    },
  ].forEach(({ url, expectedResults }) => {
    test(`test parseParams with url: ${url}`, () => {
      expect(urlUtils.parseParams(url)).toEqual(expectedResults);
    });
  });
});
