import { createSelector } from 'reselect';
import get from 'lodash/get';

import { getLocale } from '../i18nService/selectors';

import getReducerState from '../../utils/stateSelectorHelper';

import { TEMPLATE_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';
import TEMPLATES from '../../utils/constants/template';

import { Configs } from '../../configs';

export const getTemplateState = (state, modulePath = '') =>
  getReducerState(state, modulePath, TEMPLATE_SERVICE_REDUCER);

export const getTemplateName = createSelector(getTemplateState, templateState =>
  get(templateState, 'templateName', ''),
);

export const getPartnerIdName = createSelector(
  getTemplateState,
  templateState => get(templateState, 'partnerId', ''),
);

export const getPartnerName = createSelector(
  [getTemplateName, getPartnerIdName, getLocale],
  (templateName, partnerId, locale) => {
    if (
      templateName === TEMPLATES.SBIP2_WHITE_LABEL &&
      partnerId !== 'partnership'
    ) {
      return get(
        Configs,
        `WHITE_LABEL.SBIP2.config.${partnerId}.NAME.${locale.toUpperCase()}`,
        null,
      );
    }
    return null;
  },
);
