import { LOGIN_FORM_SERVICE_REDUCER } from '../../../globalRedux/reducers/constants';
import generateSelectorTests from '../../../utils/selectorTestHelper';
import {
  getFormData,
  getLoginFormState,
  hasValidationErrors,
  isLoginFormDone,
} from '../selectors';

describe('getFormHandlerState', () => {
  it('should return state if they are available', () => {
    const state = {
      loginFormServiceReducer: { test: 1 },
    };
    expect(getLoginFormState(state)).toEqual({ test: 1 });
  });

  it('should return {} if state are not available', () => {
    const state = {};
    expect(getLoginFormState(state)).toEqual({});
  });

  it('should return module state if they are available', () => {
    const modulePath = 'front_end.sso';

    const state = {
      front_end: {
        sso: {
          loginFormServiceReducer: { test: 1 },
        },
      },
    };
    expect(getLoginFormState(state, modulePath)).toEqual({ test: 1 });
  });
});

describe('hasValidationErrors', () => {
  it('should return false if there are not errors', () => {
    const state = {
      loginFormServiceReducer: { errors: {} },
    };
    expect(hasValidationErrors(state)).toEqual(false);
  });

  it('should return true if there are errors', () => {
    const state = {
      loginFormServiceReducer: { errors: { identity: ['identity-error-msg'] } },
    };
    expect(hasValidationErrors(state)).toEqual(true);
  });
});

const TEST_SELECTORS = [
  {
    testName: 'isLoginFormDone',
    selector: isLoginFormDone,
    selectorField: `${LOGIN_FORM_SERVICE_REDUCER}.initDone`,
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    testName: 'getFormData',
    selector: getFormData,
    selectorField: `${LOGIN_FORM_SERVICE_REDUCER}.formData`,
    selectorType: 'object',
    defaultValue: {},
  },
];

describe('Test Selectors', generateSelectorTests(TEST_SELECTORS));
