// @flow
import type { Saga } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import requestsManager from 'bnc-react-services/managers/RequestsManager';
import getErrorMessageKey from 'bnc-utilities-js/errorUtils/getErrorMessageKey';
import browserHistory from '../../nav/BrowserHistoryManager/index';
import ROUTES from '../../utils/constants/routes';
import TEMPLATES from '../../utils/constants/template';
import * as reducers from '../../globalRedux/reducers/constants';

import type { RetrieveConfigsSuccessAction } from './types';

import { retrieveConfigsSuccess, retrieveConfigsFailure } from './actions';

import API from './api';
import isAppUnavailable from './helper';
import { forgotPasswordUnavailableCheck } from '../forgotPasswordService/actions';

export function* watchRetrieveConfigRequest(): Saga<void> {
  try {
    const { templateName } = (yield select())[
      reducers.TEMPLATE_SERVICE_REDUCER
    ];

    if (templateName !== TEMPLATES.BNE) {
      const response = yield requestsManager.call(API.getConfigs);
      yield put(retrieveConfigsSuccess(response));
    }
  } catch (e) {
    yield put(retrieveConfigsFailure(getErrorMessageKey(e)));
  }
}

export function* watchRetrieveConfigSuccess(
  action: RetrieveConfigsSuccessAction,
): Saga<void> {
  if (isAppUnavailable(action)) {
    yield browserHistory.push(ROUTES.COMPLETE_UNAVAILABILITY);
  } else {
    const pathName = window.location.pathname;
    if (
      ROUTES.FORGOT_PASSWORD === pathName ||
      ROUTES.FORGOT_PASSWORD_UNAVAILABLE === pathName
    ) {
      yield put(forgotPasswordUnavailableCheck(pathName));
    }
  }
}
