// @flow
import * as yup from 'yup';
import { FACTOR_TYPES } from 'bnc-react-services/managers/AuthManager/constants';

import type { MultiFactorAuthFormData } from '../../types/forms/multiFactorAuthForm';

import { isFieldValid, isFormValid } from '../../utils/formUtils';

export const OTC_CODE_LENGTH_CALL = 5;
export const OTC_CODE_LENGTH = 6;

export const formSchema = yup.object({
  code: yup
    .string('text.message.validationCodeError')
    .required('text.message.validationCodeError.empty')
    .matches(/^[0-9]+$/, 'text.message.validationCodeError')
    .min(OTC_CODE_LENGTH, 'text.message.validationCodeError')
    .max(OTC_CODE_LENGTH, 'text.message.validationCodeError'),
});

export const formSchemaCall = yup.object({
  code: yup
    .string('text.message.validationCodeError')
    .required('text.message.validationCodeError.empty')
    .matches(/^[0-9]+$/, 'text.message.validationCodeError')
    .min(OTC_CODE_LENGTH_CALL, 'text.message.validationCodeError')
    .max(OTC_CODE_LENGTH_CALL, 'text.message.validationCodeError'),
});

export function fieldValidation(
  formData: MultiFactorAuthFormData,
  action: any,
  selectedFactor: string,
) {
  switch (selectedFactor) {
    case FACTOR_TYPES.CALL:
      return isFieldValid(formData, action, formSchemaCall);
    default:
      return isFieldValid(formData, action, formSchema);
  }
}

export function formValidation(
  formData: MultiFactorAuthFormData,
  selectedFactor: string,
) {
  switch (selectedFactor) {
    case FACTOR_TYPES.CALL:
      return isFormValid(formData, formSchemaCall);
    default:
      return isFormValid(formData, formSchema);
  }
}
