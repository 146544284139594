// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import AccountLockedTemplate from './accountLockedTemplate';
import { getLockedStatus } from '../../../services/loginService/selectors';

const mapStateToProps = state => ({
  lockedStatus: getLockedStatus(state),
});

export default injectIntl(connect(mapStateToProps)(AccountLockedTemplate));
