// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import MultiFactorAuthRSAForm from './multiFactorAuthRSAForm';
import { Dispatch } from '../../../../types/store';
import { displayResend } from '../../../../services/loginService/actions';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  displayResend: () => dispatch(displayResend()),
});

export default injectIntl(
  connect(null, mapDispatchToProps)(MultiFactorAuthRSAForm),
);
