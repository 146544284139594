// @flow
import React from 'react';
import cx from 'classnames';
import type { Node } from 'react';
import PropTypes from 'prop-types';
import { IntlShape } from 'react-intl';

import LoginAlert from '../../../../molecules/forms/LoginAlert';
import { Configs } from '../../../../configs';
import LoginFooterLinks from '../../../../molecules/bne/footerlinks';

import './style.scss';

type Props = {
  locale: PropTypes.String.isRequired,
  intl: IntlShape,
  className?: string,
  modal?: Node,
  header: Node,
  main: Node,
  footer?: Node,
  assets: Node,
  isLoginAlertEnable?: boolean,
};

const defaultProps = {
  className: '',
  footer: null,
  modal: null,
  isLoginAlertEnable: true,
};

function Right2ColumnsTemplate(props: Props) {
  const {
    className,
    header,
    main,
    footer,
    assets,
    modal,
    isLoginAlertEnable,
    locale,
    intl,
  } = props;

  const links = [
    {
      label: intl.formatMessage({ id: 'text.link.legalUrl' }),
      href: Configs.BNE.EXTERNAL_URLS.LEGAL_URL.URL[locale],
      id: 'url_legal',
    },
    {
      label: intl.formatMessage({ id: 'text.link.privacyPolicyUrl' }),
      href: Configs.BNE.EXTERNAL_URLS.CONFIDENTIALITY_URL.URL[locale],
      id: 'url_privacy',
    },
    {
      label: intl.formatMessage({ id: 'text.link.cookiesUrl' }),
      href: Configs.BNE.EXTERNAL_URLS.COOKIES_URL.URL[locale],
      id: 'url_cookies',
    },
    {
      label: intl.formatMessage({ id: 'text.link.securityUrl' }),
      href: Configs.BNE.EXTERNAL_URLS.SECURITY_URL.URL[locale],
      id: 'url_security',
    },
  ];

  return (
    <div className={cx('template-right', className)}>
      {modal}
      {assets && (
        <div className="template-right__content template-right__content--left">
          {assets}
        </div>
      )}

      <div className="template-right__content template-right__content--right">
        {isLoginAlertEnable && <LoginAlert />}
        <div className={cx('container', className)}>
          {header && <header>{header}</header>}
          {main && <main>{main}</main>}
        </div>
        {footer && <footer>{footer}</footer>}
        <LoginFooterLinks links={links} />
      </div>
    </div>
  );
}

Right2ColumnsTemplate.defaultProps = defaultProps;

export default Right2ColumnsTemplate;
