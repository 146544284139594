import React from 'react';
import PropTypes from 'prop-types';

import DefaultAccountLockedTemplate from '../../templates/common/AccountLockedTemplate';
import BneAccountLockedTemplate from '../../templates/bne/AccountLockedTemplate';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';

const propTypes = {
  templateName: PropTypes.string,
};

const defaultProps = {
  templateName: '',
};

const AccountLocked = props => {
  const { templateName } = props;

  const AccountLockedTemplates = {
    [TEMPLATES.SBIP2]: <DefaultAccountLockedTemplate />,
    [TEMPLATES.OBE]: <DefaultAccountLockedTemplate />,
    [TEMPLATES.NATGO]: <DefaultAccountLockedTemplate />,
    [TEMPLATES.SECURE_KEY]: <DefaultAccountLockedTemplate />,
    [TEMPLATES.SBIP2_WHITE_LABEL]: <DefaultAccountLockedTemplate />,
    [TEMPLATES.BAO]: <DefaultAccountLockedTemplate />,
    [TEMPLATES.BNE]: <BneAccountLockedTemplate />,
  };
  return getTemplateComponent(AccountLockedTemplates, templateName);
};

AccountLocked.propTypes = propTypes;
AccountLocked.defaultProps = defaultProps;

export default AccountLocked;
