import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';

import HeaderEnterprise from '../../../organisms/common/HeaderEnterprise';
import MultiFactorAuthChoice from '../../../organisms/bne/MultiFactorAuthChoice';
import './style.scss';

const MultiFactorAuthChoiceTemplate = () => (
  <div className="multifactorauth-bne">
    <HeaderEnterprise hasSwitchLang={false} className="mobile-header" />
    <FullScreenFooterTemplate
      header={<HeaderEnterprise hasSwitchLang={false} />}
      className="container--entreprise bne-mfa-choice"
      main={<MultiFactorAuthChoice />}
    />
  </div>
);

export default MultiFactorAuthChoiceTemplate;
