// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { resendOTC } from '../../../services/forgotPasswordService/actions';
import { factorsHintSelector } from '../../../services/forgotPasswordService/selectors';
import { isHideResend } from '../../../services/multiFactorAuthFormService/selectors';
import type { State } from '../../../types/state';
import type { Dispatch } from '../../../types/store';
import MultiFactorAuthFooter from './multiFactorAuthFooter';

const mapStateToProps = (state: State) => ({
  isHideResend: isHideResend(state),
  factors: factorsHintSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  triggerCodeRequest: factor => dispatch(resendOTC(factor)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuthFooter),
);
