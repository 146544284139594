import React from 'react';
import PropTypes from 'prop-types';

import DefaultNBCForbidden from '../../templates/common/ForbiddenTemplate';
import WhiteLabelForbidden from '../../templates/whiteLabel/ForbiddenTemplate';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';

const propTypes = {
  templateName: PropTypes.string,
};

const defaultProps = {
  templateName: '',
};

const Forbidden = props => {
  const { templateName } = props;

  const forbiddenTemplates = {
    [TEMPLATES.SBIP2]: <DefaultNBCForbidden />,
    [TEMPLATES.OBE]: <DefaultNBCForbidden />,
    [TEMPLATES.NATGO]: <DefaultNBCForbidden />,
    [TEMPLATES.SECURE_KEY]: <DefaultNBCForbidden />,
    [TEMPLATES.SBIP2_WHITE_LABEL]: <WhiteLabelForbidden />,
    [TEMPLATES.BAO]: <DefaultNBCForbidden />,
  };
  return getTemplateComponent(forbiddenTemplates, templateName);
};

Forbidden.propTypes = propTypes;
Forbidden.defaultProps = defaultProps;

export default Forbidden;
