// @flow
import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';

import Footer from '../../../organisms/common/Footer';
import MultiFactorAuthFooterContent from '../../../organisms/common/MultiFactorAuthFooter';
import Header from '../../../organisms/rewards/Header';
import MultiFactorAuth from '../../../organisms/common/MultiFactorAuth';
import ExpirationModal from '../../../organisms/common/ExpirationModal';

const MultiFactorAuthTemplate = () => (
  <FullScreenFooterTemplate
    header={<Header hasSwitchLang={false} />}
    main={<MultiFactorAuth className="sbip2-mfa" />}
    footer={<Footer content={<MultiFactorAuthFooterContent />} />}
    modal={<ExpirationModal />}
  />
);

export default MultiFactorAuthTemplate;
