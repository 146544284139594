// @flow
import React, { useEffect } from 'react';
import type { intlShape } from 'react-intl';
import { FormattedHTMLMessage } from 'react-intl';
import cx from 'classnames';

import includes from 'lodash/includes';

import { Heading } from '@bnc-ui/heading';
import { Alert } from '@nbc-design/alert';

import FormHandler from '../../../molecules/forms/FormHandler';
import MultiFactorAuthForm from '../../../molecules/forms/MultiFactorAuthForm';
import BackLinkButton from '../../../molecules/common/BackLinkButton';
import MultiFactorAuthTaggingWrapper from './MultiFactorAuthTaggingWrapper';

import type { MultiFactorAuthFormData } from '../../../types/forms/multiFactorAuthForm';
import type { SDKError } from '../../../types';
import {
  MULTI_FACTOR_AUTH_FORM_INIT_DONE,
  MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
  MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE,
  MULTI_FACTOR_AUTH_UPDATE_FACTORS,
} from '../../../services/multiFactorAuthFormService/actionTypes';
import { formatFactorValue, getFactorIcon } from '../../../utils/factorUtils';
import { focusOnComponent } from '../../../utils/domUtils';
import { queueAnnouncer } from '../../../utils/liveAnnouncer/helper';
import { FORM_TYPES } from '../../../utils/constants/forms';
import { FORGOT_PASSWORD_FIELDS } from '../../../utils/forgotPasswordUtils/constants';
import { MFA_STEPS } from '../../../utils/taggingUtils/constants';

import './style.scss';

type Props = {
  intl: intlShape,
  className?: string,
  isFetching: boolean,
  formUpdate: (fieldName: string, fieldValue: string) => void,
  triggerSubmit: () => void,
  triggerValidation: (fieldName: string) => void,
  errors: *,
  isHideResend: boolean,
  factorTypes: Array<string>,
  formData: MultiFactorAuthFormData,
  returnToLogin: () => void,
  sdkError: SDKError,
  selectedFactor: string,
  contact: string,
  isShowResendOTCSuccessMessage?: boolean,
  getAuthErrorMessageId: ({}) => string,
  executedAction?: any,
  announceMessage?: string => void,
  isFormDone: boolean,
  isHardEnrolling?: boolean,
  isForgotPasswordFlow?: boolean,
  forgotPasswordFlowId?: string,
  partnerIdName?: string,
};

const defaultProps = {
  className: '',
  isShowResendOTCSuccessMessage: false,
  executedAction: {},
  announceMessage: () => {},
  isHardEnrolling: false,
  isForgotPasswordFlow: false,
  forgotPasswordFlowId: '',
  partnerIdName: '',
};

const MultiFactorAuth = (props: Props) => {
  const {
    className,
    intl,
    formUpdate,
    errors,
    triggerSubmit,
    triggerValidation,
    isFetching,
    isHideResend,
    factorTypes,
    formData,
    contact,
    sdkError,
    selectedFactor,
    returnToLogin,
    isShowResendOTCSuccessMessage,
    getAuthErrorMessageId,
    executedAction,
    announceMessage,
    isFormDone,
    isHardEnrolling,
    isForgotPasswordFlow,
    forgotPasswordFlowId,
    partnerIdName,
  } = props;

  useEffect(() => {
    if (executedAction.type === MULTI_FACTOR_AUTH_FORM_INIT_DONE) {
      queueAnnouncer(
        [
          intl.formatMessage(
            { id: `text.aria.validationCodeSent.${selectedFactor}` },
            {
              contact: formatFactorValue(selectedFactor, contact, intl.locale),
            },
          ),
        ],
        announceMessage,
      );
    }
  }, [executedAction, intl, selectedFactor, announceMessage, contact]);

  useEffect(() => {
    if (isShowResendOTCSuccessMessage) {
      queueAnnouncer(
        [
          intl.formatMessage({
            id: `text.title.resendSuccess.${selectedFactor}`,
          }),
        ],
        announceMessage,
      );
    }
  }, [isShowResendOTCSuccessMessage, intl, announceMessage, selectedFactor]);

  useEffect(() => {
    const { code } = sdkError;
    if (code) {
      queueAnnouncer(
        [
          intl.formatMessage(
            { id: `auth.error.${getAuthErrorMessageId(sdkError)}` },
            { code },
          ),
        ],
        announceMessage,
      );
    }
  }, [sdkError, getAuthErrorMessageId, intl, announceMessage]);

  useEffect(() => {
    const typeEval = [
      MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE,
      MULTI_FACTOR_AUTH_UPDATE_FACTORS,
      MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
    ];
    if (!!sdkError.code || includes(typeEval, executedAction.type)) {
      focusOnComponent(`#${FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE}`);
    }
  }, [sdkError.code, executedAction.type]);

  const codeSendTitle = (
    <FormattedHTMLMessage
      id={`text.message.validationCodeSent.${selectedFactor}`}
      values={{
        contact: formatFactorValue(selectedFactor, contact, intl.locale),
      }}
    />
  );

  return (
    <div
      id="multiFactorAuth-common"
      className={cx('connect-form mfa', className)}
    >
      <Heading type="h1" size={1}>
        {intl.formatMessage({ id: 'text.title.validationCode' })}
      </Heading>

      {isHardEnrolling && (
        <Alert
          className="hard-enrolling-warning"
          title={intl.formatMessage({ id: 'text.field.emailHardEnrolling' })}
          appearance="warning"
          size="small"
        />
      )}

      {isShowResendOTCSuccessMessage && (
        <Alert
          className="resend-success-message"
          title={intl.formatMessage({
            id: `text.title.resendSuccess.${selectedFactor}`,
          })}
          appearance="success"
          size="small"
        />
      )}

      {!!sdkError.code && (
        <Alert
          appearance="error"
          size="small"
          title={intl.formatMessage(
            { id: `auth.error.${getAuthErrorMessageId(sdkError)}` },
            { code: sdkError.code },
          )}
        />
      )}

      <Heading type="h2" size={1}>
        <FormattedHTMLMessage
          id="text.message.delayCode"
          values={{
            modalType: intl.formatMessage({
              id: `text.message.${selectedFactor}`,
            }),
          }}
        />
      </Heading>

      <Alert
        className="sending-notice"
        title={codeSendTitle}
        size="normal"
        icon={getFactorIcon(selectedFactor, false)}
        appearance="information"
      />

      <FormHandler formName={FORM_TYPES.MULTI_FACTOR_AUTH_FORM}>
        <MultiFactorAuthTaggingWrapper
          sdkError={sdkError}
          locale={intl.locale}
          isLoading={isFormDone}
          step={MFA_STEPS.ENTER_SECURITY_CODE}
          factorType={selectedFactor}
          isForgotPasswordFlow={isForgotPasswordFlow}
          forgotPasswordFlowId={forgotPasswordFlowId}
          partnerIdName={partnerIdName}
        >
          <MultiFactorAuthForm
            isFetching={isFetching}
            formData={formData}
            formUpdate={formUpdate}
            triggerSubmit={triggerSubmit}
            triggerValidation={triggerValidation}
            errors={errors}
            isHideResend={isHideResend}
            factorTypes={factorTypes}
          />
        </MultiFactorAuthTaggingWrapper>
      </FormHandler>

      <BackLinkButton
        label={intl.formatMessage({
          id: 'global.button.backToLogin',
        })}
        onClick={returnToLogin}
      />
    </div>
  );
};

MultiFactorAuth.defaultProps = defaultProps;

export default MultiFactorAuth;
