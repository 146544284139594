import { LOCATION_CHANGE } from 'react-router-redux';
import handlePageChange from '../../../utils/dtmUtils/handlers';

// build an array containing all dtm handlers
const dtmHandlers = [
  // add here every new dtm handler
  // ...
];

// create a custom middleware to deal with DTM call
const DTMMiddleware = store => next => action => {
  next(action);

  const state = store.getState();
  console.debug(`DTM MIDDLEWARE action type : ${action.type}`);

  // handle change of page
  if (action.type === LOCATION_CHANGE) {
    handlePageChange(action, state);
  } else {
    // loop on all dtm handler and delegate call
    for (let i = 0; i < dtmHandlers.length; i += 1) {
      const currentHandler = dtmHandlers[i];
      currentHandler(action, state);
    }
  }
};

export default DTMMiddleware;
