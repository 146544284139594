import { testRootSagaMappings } from 'bnc-utilities-js/testUtils/saga';
import rootSaga, { workersMapping } from '..';

jest.mock('../workers', () => ({
  watchContinueAnyway: jest.fn(),
  watchCannotContinue: jest.fn(),
  watchInitUnsupportedBrowser: jest.fn(),
}));

testRootSagaMappings(rootSaga, workersMapping);
