import { buildPath } from '../routerHelper';
import TEMPLATES from '../../../utils/constants/template';

test('test buildPath - SBIP2', () => {
  const path = '/forgotPassword';

  const result = buildPath(path, TEMPLATES.SBIP2, null);

  expect(result).toEqual(path);
});

test('test buildPath - NATGO', () => {
  const path = '/forgotPassword';

  const result = buildPath(path, TEMPLATES.NATGO, null);

  expect(result).toEqual('/natgo/forgotPassword');
});

test("test buildPath - NATGO - with '/'", () => {
  const path = '/forgotPassword/';

  const result = buildPath(path, TEMPLATES.NATGO, null);

  expect(result).toEqual('/natgo/forgotPassword');
});
