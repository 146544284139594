import { testRootSagaMappings } from 'bnc-utilities-js/testUtils';
import rootSaga, { workersMapping } from '..';

jest.mock('../workers', () => ({
  watchBiometryInitialization: jest.fn(),
  watchBiometryInitializationSuccess: jest.fn(),
  watchBiometryValidationResult: jest.fn(),
  watchBiometryValidationSuccess: jest.fn(),
  biometryValidationError: jest.fn(),
  watchForcePasswordLogin: jest.fn(),
  watchForCredentialsDeletionResult: jest.fn(),
  watchCredentialsSynchronization: jest.fn(),
}));

testRootSagaMappings(rootSaga, workersMapping);
