import { testSaga } from 'bnc-utilities-js/testUtils/saga';
import Biometry from '../biometry';

import {
  BIOMETRIC_COOKIE_NAME,
  BIOMETRY_FAILED,
  BIOMETRY_SUCCESS,
} from '../constants';

import {
  watchForcePasswordLogin,
  watchBiometryInitialization,
  watchBiometryInitializationSuccess,
  watchBiometryValidationResult,
  watchBiometryValidationSuccess,
  watchForCredentialsDeletionResult,
} from '../workers';

import { BIOMETRY_INITIALIZATION_SUCCESS } from '../actionTypes';
import * as actionTypes from '../actionTypes';
import * as loginFormActionTypes from '../../loginFormService/actionTypes';

window.ReactNativeWebView = {
  postMessage: jest.fn(),
};

const deviceId = 'device';
const biometricType = 'fingerprint';
const biometricEnabledUser = 'dummy.user';
const version = '2.0.0';
const biometry = new Biometry(
  deviceId,
  biometricType,
  biometricEnabledUser,
  version,
);

describe('watchBiometryInitialization', () => {
  it('should call initBiometrySuccess action', async () => {
    document.cookie = `${BIOMETRIC_COOKIE_NAME}=${encodeURIComponent(
      `deviceID=${deviceId};biometricType=${biometricType};biometricEnabledUser=${biometricEnabledUser};version=${version}`,
    )}`;
    const { actions } = await testSaga({
      saga: watchBiometryInitialization,
      state: {},
      args: [],
    }).sagaDone;

    expect(actions).toEqual([
      { type: BIOMETRY_INITIALIZATION_SUCCESS, biometry },
    ]);
  });
});

describe('watchBiometryInitializationSuccess', () => {
  it('should update login form identity', async () => {
    const { actions } = await testSaga({
      saga: watchBiometryInitializationSuccess,
      state: {},
      args: [
        {
          type: actionTypes.BIOMETRY_INITIALIZATION_SUCCESS,
          biometry,
        },
      ],
    }).sagaDone;

    expect(actions).toEqual([
      {
        type: loginFormActionTypes.LOGIN_FORM_UPDATE,
        fieldName: 'identityBne',
        fieldValue: biometry.user,
      },
      {
        type: loginFormActionTypes.LOGIN_FORM_UPDATE,
        fieldName: 'identity',
        fieldValue: biometry.user,
      },
    ]);
  });
});

describe('watchBiometryValidationResult', () => {
  it('should call biometryValidationSuccess saga if validation results is a success', async () => {
    const credentials = 'dummypassword';
    const { actions } = await testSaga({
      saga: watchBiometryValidationResult,
      state: {},
      args: [
        {
          type: actionTypes.BIOMETRY_VALIDATION_RESULT,
          payload: {
            validationResults: BIOMETRY_SUCCESS,
            retrievedCredentials: credentials,
          },
        },
      ],
    }).sagaDone;

    expect(actions).toEqual([
      {
        type: actionTypes.BIOMETRY_VALIDATION_SUCCESS,
        credentials,
      },
    ]);
  });

  it('should call biometryValidationError saga if validation results is a failure', async () => {
    const error = {
      code: 'UnknownError',
      message: 'An unknown error occurred',
    };
    const { actions } = await testSaga({
      saga: watchBiometryValidationResult,
      state: {},
      args: [
        {
          type: actionTypes.BIOMETRY_VALIDATION_RESULT,
          payload: {
            validationResults: BIOMETRY_FAILED,
            error,
          },
        },
      ],
    }).sagaDone;

    expect(actions).toEqual([
      {
        type: actionTypes.BIOMETRY_VALIDATION_FAILED,
        error,
      },
    ]);
  });
});

describe('watchBiometryValidationSuccess', () => {
  it('should fill password, passwordBne and trigger login form submit', async () => {
    const credentials = 'dummypassword';
    const { actions } = await testSaga({
      saga: watchBiometryValidationSuccess,
      state: {},
      args: [
        {
          type: actionTypes.BIOMETRY_VALIDATION_SUCCESS,
          credentials,
        },
      ],
    }).sagaDone;

    expect(actions).toEqual([
      {
        type: loginFormActionTypes.LOGIN_FORM_UPDATE,
        fieldName: 'passwordBne',
        fieldValue: credentials,
      },
      {
        type: loginFormActionTypes.LOGIN_FORM_UPDATE,
        fieldName: 'password',
        fieldValue: credentials,
      },
      {
        type: loginFormActionTypes.LOGIN_FORM_SUBMIT_REQUEST,
      },
    ]);
  });
});

describe('watchForcePasswordLogin', () => {
  it('should force login', async () => {
    const { actions } = await testSaga({
      saga: watchForcePasswordLogin,
      state: {},
      args: [],
    }).sagaDone;

    expect(actions).toEqual([
      {
        fieldName: 'identityBne',
        fieldValue: '',
        type: 'LOGIN_FORM_UPDATE',
      },
      {
        fieldName: 'identity',
        fieldValue: '',
        type: 'LOGIN_FORM_UPDATE',
      },
      {
        fieldName: 'passwordBne',
        fieldValue: '',
        type: 'LOGIN_FORM_UPDATE',
      },
      {
        fieldName: 'password',
        fieldValue: '',
        type: 'LOGIN_FORM_UPDATE',
      },
    ]);
  });
});

describe('watchForCredentialsDeletionResult', () => {
  it('should launch credentials deletion success', async () => {
    const { actions } = await testSaga({
      saga: watchForCredentialsDeletionResult,
      state: {},
      args: [
        {
          payload: {
            results: 'success',
          },
        },
      ],
    }).sagaDone;

    expect(actions).toEqual([
      {
        fieldName: 'identityBne',
        fieldValue: '',
        type: 'LOGIN_FORM_UPDATE',
      },
      {
        fieldName: 'passwordBne',
        fieldValue: '',
        type: 'LOGIN_FORM_UPDATE',
      },
      {
        fieldName: 'identity',
        fieldValue: '',
        type: 'LOGIN_FORM_UPDATE',
      },
      {
        fieldName: 'password',
        fieldValue: '',
        type: 'LOGIN_FORM_UPDATE',
      },
      {
        type: actionTypes.BIOMETRY_CREDENTIALS_DELETION_SUCCESS,
      },
    ]);
  });

  it('should launch credentials deletion error', async () => {
    const { actions } = await testSaga({
      saga: watchForCredentialsDeletionResult,
      state: {},
      args: [
        {
          payload: {
            results: 'error',
          },
        },
      ],
    }).sagaDone;

    expect(actions).toEqual([
      {
        type: actionTypes.BIOMETRY_CREDENTIALS_DELETION_FAILED,
      },
    ]);
  });
});
