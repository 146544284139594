// @flow
export const LOGIN_ROUTE_MFA = 'LOGIN_ROUTE_MFA';
export const LOGIN_ROUTE_RSA = 'LOGIN_ROUTE_RSA';
export const LOGIN_ROUTE_MFA_CHOICE = 'LOGIN_ROUTE_MFA_CHOICE';
export const LOGIN_ROUTE_PASSWORD_EXPIRED = 'LOGIN_ROUTE_PASSWORD_EXPIRED';
export const LOGIN_ROUTE_LOCKED = 'LOGIN_ROUTE_LOCKED';
export const LOGIN_ROUTE_PASSWORD_EXCEPTION = 'LOGIN_ROUTE_PASSWORD_EXCEPTION';
export const LOGIN_ROUTE_SIGN_UP = 'LOGIN_ROUTE_SIGN_UP';
export const LOGIN_ROUTE_APP_ORIGIN = 'LOGIN_ROUTE_APP_ORIGIN';
export const LOGIN_ROUTE_FORGOT_PASSWORD = 'LOGIN_ROUTE_FORGOT_PASSWORD';
export const LOGIN_ROUTE_FORGOT_PASSWORD_EXCEPTION =
  'LOGIN_ROUTE_FORGOT_PASSWORD_EXCEPTION';
export const LOGIN_ROUTE_RESET_PASSWORD = 'LOGIN_ROUTE_RESET_PASSWORD';
export const LOGIN_ROUTE_WITHOUT_SIGN_IN = 'LOGIN_ROUTE_WITHOUT_SIGN_IN';
export const FORGOT_PASSWORD_UNAVAILABLE = 'FORGOT_PASSWORD_UNAVAILABLE';
