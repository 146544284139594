import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Chatbot from './chatbot';

import { getAllConfigs } from '../../services/configsService/selectors';

const mapStateToProps = state => ({
  configs: getAllConfigs(state),
});

export default injectIntl(connect(mapStateToProps)(Chatbot));
