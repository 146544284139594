import * as formUtils from '../index';
import { BIRTHDAY_SKIP_YEARS, DATE_MIN_BIRTH } from '../../constants/forms';

describe('formatFieldsError', () => {
  const testCases = [
    {
      formErrors: {},
      isValidateForm: true,
      result: {},
    },
    {
      formErrors: { message: 'formErrors_message' },
      isValidateForm: false,
      result: ['formErrors_message'],
    },
    {
      formErrors: {
        inner: [
          { path: 'inner1', message: 'inner1_message' },
          { path: 'inner2', message: 'inner2_message' },
          { path: 'inner3', message: 'inner3_message' },
        ],
        message: 'formErrors_message',
      },
      isValidateForm: true,
      result: {
        inner1: ['inner1_message'],
        inner2: ['inner2_message'],
        inner3: ['inner3_message'],
      },
    },
    {
      formErrors: {
        inner: [
          { path: 'inner1', message: 'inner1_message' },
          { path: 'inner2', message: 'inner2_message' },
          { path: 'inner3', message: 'inner3_message' },
        ],
        message: 'formErrors_message',
      },
      isValidateForm: false,
      result: [],
    },
    {
      formErrors: {
        inner: [
          { message: 'inner1_message' },
          { path: 'inner2', message: 'inner2_message' },
          { path: 'inner3', message: 'inner3_message' },
        ],
        message: 'formErrors_message',
      },
      isValidateForm: false,
      result: ['inner1_message'],
    },
    {
      formErrors: {
        inner: [
          { message: 'inner1_message' },
          { path: 'inner2', message: 'inner2_message' },
          { path: 'inner3', message: 'inner3_message' },
        ],
        message: 'formErrors_message',
      },
      isValidateForm: true,
      result: { inner2: ['inner2_message'], inner3: ['inner3_message'] },
    },
  ];
  testCases.forEach(({ formErrors, isValidateForm, result }) => {
    it(`should return '${result}' for formErrors '${JSON.stringify(
      formErrors,
    )}' and isValidateForm '${isValidateForm}'`, () => {
      expect(formUtils.formatFieldsError(formErrors, isValidateForm)).toEqual(
        result,
      );
    });
  });
});

describe('isNewPasswordFieldValid', () => {
  const testCases = [
    {
      resetPasswordForm: { isValid: true, errors: {}, formData: {} },
      input: '',
      field: null,
      result: true,
    },
    {
      resetPasswordForm: {
        isValid: false,
        errors: { input: 'inputErrorMessage' },
        formData: {},
      },
      input: 'input',
      field: 'input',
      result: false,
    },
    {
      resetPasswordForm: {
        isValid: false,
        errors: { input: 'inputErrorMessage' },
        formData: {},
      },
      input: 'input',
      field: 'toto',
      result: true,
    },
    {
      resetPasswordForm: {
        isValid: false,
        errors: {},
        formData: { input: 'inputErrorMessage' },
      },
      input: 'input',
      field: null,
      result: true,
    },
    {
      resetPasswordForm: {
        isValid: false,
        errors: {},
        formData: {},
      },
      input: 'input',
      field: null,
      result: false,
    },
  ];
  testCases.forEach(({ resetPasswordForm, input, field, result }) => {
    it(`should return '${result}' for resetPasswordForm '${JSON.stringify(
      resetPasswordForm,
    )}' and input ${input} and field ${field}`, () => {
      expect(
        formUtils.isNewPasswordFieldValid(resetPasswordForm, input, field),
      ).toEqual(result);
    });
  });
});

describe('.yupIsValid', () => {
  const yupSchema = {
    isValid: value => true,
  };
  expect(formUtils.yupIsValid(yupSchema, 'something')).toEqual(true);
});

describe('convertDateFieldsBirthDayToDate', () => {
  it('return null if missing month, day or year', () => {
    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: '',
        month: '12',
        year: '2000',
      }),
    ).toEqual(null);

    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: '20',
        month: '',
        year: '2000',
      }),
    ).toEqual(null);

    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: '20',
        month: '10',
        year: '',
      }),
    ).toEqual(null);
  });

  it('return null if there are offset calculations', () => {
    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: '00',
        month: '12',
        year: '2000',
      }),
    ).toEqual(null);

    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: '32',
        month: '1',
        year: '2000',
      }),
    ).toEqual(null);

    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: '-5',
        month: '10',
        year: '1995',
      }),
    ).toEqual(null);
  });

  it('return null if date is out of range', () => {
    const now = new Date();

    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: now.getDay(),
        month: 1 + now.getMonth(),
        year: now.getFullYear() - BIRTHDAY_SKIP_YEARS + 1,
      }),
    ).toEqual(null);

    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: now.getDay(),
        month: 1 + now.getMonth(),
        year: now.getFullYear() - DATE_MIN_BIRTH - 1,
      }),
    ).toEqual(null);
  });

  it('return correct date value', () => {
    const now = new Date();
    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: 10,
        month: 1,
        year: now.getFullYear() - BIRTHDAY_SKIP_YEARS - 1,
      }),
    ).toEqual(new Date(now.getFullYear() - BIRTHDAY_SKIP_YEARS - 1, 0, 10));
  });

  it('return null with invalid date value', () => {
    const now = new Date();
    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: 32,
        month: 1,
        year: now.getFullYear(),
      }),
    ).toEqual(null);

    expect(
      formUtils.convertDateFieldsBirthDayToDate({
        day: 1,
        month: 13,
        year: now.getFullYear(),
      }),
    ).toEqual(null);
  });
});

describe('buildExpiryDate', () => {
  const testCases = [
    {
      result: new Date(2021, 8, 27),
      expiryDate: '09/21',
    },
    {
      result: new Date(2000, 0, 27),
      expiryDate: '1/00',
    },
    {
      result: new Date(2012, 11, 27),
      expiryDate: '12/12',
    },
    {
      result: new Date(2009, 4, 27),
      expiryDate: '5/09',
    },
  ];

  testCases.forEach(({ result, expiryDate }) => {
    it(`should return '${result}' for expiry date '${expiryDate}'`, () => {
      const builtDate = formUtils.buildExpiryDate(expiryDate);
      expect(builtDate.getFullYear()).toEqual(result.getFullYear());
      expect(builtDate.getMonth()).toEqual(result.getMonth());
      expect(builtDate.getDate()).toEqual(result.getDate());
    });
  });
});

describe('buildPhoneNumber', () => {
  const testCases = [
    {
      result: '5142334444',
      phoneNumber: '514 233 4444',
    },
    {
      result: '5142334444',
      phoneNumber: '514-233-4444',
    },
    {
      result: '5142334444',
      phoneNumber: '514 233-4444',
    },
    {
      result: '',
      phoneNumber: '',
    },
    {
      result: undefined,
      phoneNumber: undefined,
    },
  ];

  testCases.forEach(({ result, phoneNumber }) => {
    it(`should return '${result}' for phone number '${phoneNumber}'`, () => {
      const builtPhoneNumber = formUtils.buildPhoneNumber(phoneNumber);

      expect(builtPhoneNumber).toEqual(result);
    });
  });
});
