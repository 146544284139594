import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import type { State } from '../../../types/state';

import { getLocale } from '../../../services/i18nService/selectors';
import { getTemplateName } from '../../../services/templateService/selectors';

import BncBrand from './bncBrand';

function mapStateToProps(state: State) {
  return {
    locale: getLocale(state),
    templateName: getTemplateName(state),
  };
}

export default injectIntl(connect(mapStateToProps, null)(BncBrand));
