export const ASSISTANT_PHONE_TYPE = {
  INTERNATIONAL: 'INTERNATIONAL',
  TOLL_FREE: 'TOLL_FREE',
  FRAUD_NUMBER: 'FRAUD_NUMBER',
};

export const LOCKED_PHONE_TYPE = {
  LOCKED_OUT_FRAUD: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
  LOCKED_OUT_VSD: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
};
