import testReducer from 'bnc-utilities-js/testUtils/reducer';
import {
  displayResend,
  formUpdate,
  hideResend,
  initForm,
  initFormDone,
  resetMfaCode,
  submitFailure,
  triggerSubmit,
  updateFactors,
  validationFailure,
  validationSuccess,
} from '../actions';
import {
  MULTI_FACTOR_AUTH_FORM_INIT_DONE,
  MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
  MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE,
  MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE,
  MULTI_FACTOR_AUTH_FORM_VALIDATION_SUCCESS,
  MULTI_FACTOR_AUTH_UPDATE_FACTORS,
} from '../actionTypes';
import multiFactorAuthFormServiceReducer from '../reducer';

testReducer(multiFactorAuthFormServiceReducer)
  .initialState()
  .expectDiff({
    formId: '',
    isValid: false,
    errors: {},
    formData: {
      remember: false,
      code: '',
    },
    systemError: null,
    submitFailed: false,
    initDone: false,
    factorTypes: [],
    contact: '',
    hideResend: true,
    selectedFactor: '',
    executedAction: {},
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withAnyState()
  .on(formUpdate('fieldName', 'fieldValue'))
  .expectDiff({
    submitFailed: false,
    formData: {
      fieldName: 'fieldValue',
    },
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withAnyState()
  .on(initForm('FORM_ID'))
  .expectDiff({
    errors: {},
    formId: 'FORM_ID',
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withAnyState()
  .on(initFormDone({ formId: 'FORM_ID' }))
  .expectDiff({
    formId: 'FORM_ID',
    initDone: true,
    executedAction: {
      payload: {
        formId: 'FORM_ID',
      },
      type: MULTI_FACTOR_AUTH_FORM_INIT_DONE,
    },
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withAnyState()
  .on(submitFailure('code', ['error']))
  .expectDiff({
    errors: ['error'],
    submitFailed: true,
    systemError: 'code',
    executedAction: {
      code: 'code',
      errors: ['error'],
      type: MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE,
    },
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withAnyState()
  .on(validationFailure('fieldName', ['error']))
  .expectDiff({
    errors: { fieldName: ['error'] },
    isValid: false,
    submitFailed: false,
    executedAction: {
      fieldName: 'fieldName',
      errors: ['error'],
      type: MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE,
    },
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withAnyState()
  .on(validationSuccess('fieldName', true))
  .expectDiff({
    isValid: true,
    submitFailed: false,
    errors: {},
    executedAction: {
      fieldName: 'fieldName',
      isValid: true,
      type: MULTI_FACTOR_AUTH_FORM_VALIDATION_SUCCESS,
    },
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withAnyState()
  .on(triggerSubmit())
  .expectDiff({
    submitFailed: false,
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withAnyState()
  .on(displayResend())
  .expectDiff({
    hideResend: false,
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withAnyState()
  .on(hideResend())
  .expectDiff({
    hideResend: true,
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withAnyState()
  .on(updateFactors(['SMS', 'EMAIL'], 'SMS', '1 *** *** 9999'))
  .expectDiff({
    factorTypes: ['SMS', 'EMAIL'],
    selectedFactor: 'SMS',
    contact: '1 *** *** 9999',
    executedAction: {
      factorTypes: ['SMS', 'EMAIL'],
      selectedFactor: 'SMS',
      contact: '1 *** *** 9999',
      type: MULTI_FACTOR_AUTH_UPDATE_FACTORS,
    },
  });

testReducer(multiFactorAuthFormServiceReducer)
  .withState({
    multiFactorAuthForm: {
      formData: {
        code: 'someCode',
        remember: true,
      },
    },
  })
  .on(resetMfaCode())
  .expectDiff({
    errors: {},
    executedAction: {
      type: MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
    },
    formData: {
      code: '',
    },
  });
