import React from 'react';

import Right2ColumnsTemplate from '../../models/Right2ColumnsTemplate';
import Header from '../../../organisms/rewardsWhiteLabel/Header';
import ResetPasswordException from '../../../molecules/rewardsWhiteLabel/ResetPasswordException';
import BncMediaRewards from '../../../molecules/rewards/BncMediaRewards';

const ResetPasswordExceptionTemplate = () => (
  <Right2ColumnsTemplate
    className="secure-key_reset-password-exception"
    header={<Header />}
    main={<ResetPasswordException />}
    assets={<BncMediaRewards />}
  />
);

export default ResetPasswordExceptionTemplate;
