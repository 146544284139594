// @flow

import get from 'lodash/get';
import includes from 'lodash/includes';
import { browserHistory } from 'react-router';
import * as cookie from './cookie';
import I18N from './constants/i18n';

import loadI18nFiles from '../i18nLoader';

const allLocaleMessages = loadI18nFiles();

export const defineUserLocale = (): string => {
  const cookieLang = cookie.get(I18N.COOKIE_NAME);
  const queryLang = get(
    browserHistory && browserHistory.getCurrentLocation(),
    'query.lang',
  );
  const navigatorLang = (
    window.navigator.language || window.navigator.browserLanguage
  ).split('-')[0];

  if (queryLang && includes(I18N.LANG_AVAILABLE, queryLang)) {
    return queryLang;
  }
  if (cookieLang) {
    if (includes(I18N.LANG_AVAILABLE, cookieLang)) {
      return cookieLang;
    }
    return I18N.LANG_DEFAULT;
  }
  if (includes(I18N.LANG_AVAILABLE, navigatorLang)) {
    return navigatorLang;
  }
  return I18N.LANG_DEFAULT;
};

const userLocale = defineUserLocale();

export const getUserLocale = (): string => userLocale;

export const getLocaleMessages = (locale: string = userLocale) => {
  if (includes(I18N.LANG_AVAILABLE, locale)) {
    return allLocaleMessages[locale];
  }
  return allLocaleMessages[I18N.LANG_DEFAULT];
};
