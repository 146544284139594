import getReducerState from '../stateSelectorHelper';

describe('getReducerState', () => {
  it('should return reducer state if it is available', () => {
    const reducerPath = 'configsServiceReducer';
    const state = {
      configsServiceReducer: { configs: { test: 1 } },
    };
    expect(getReducerState(state, '', reducerPath)).toEqual({
      configs: { test: 1 },
    });
  });

  it('should return {} if reducer state is not available', () => {
    const reducerPath = 'configsServiceReducer';
    const state = {};
    expect(getReducerState(state, '', reducerPath)).toEqual({});
  });

  it('should return module reducer state if it is available', () => {
    const modulePath = 'front_end.sso';
    const reducerPath = 'configsServiceReducer';
    const state = {
      front_end: {
        sso: {
          configsServiceReducer: { configs: { test: 1 } },
        },
      },
    };
    expect(getReducerState(state, modulePath, reducerPath)).toEqual({
      configs: { test: 1 },
    });
  });

  it('should return {} if every params are undefined', () => {
    expect(getReducerState()).toEqual({});
  });
});
