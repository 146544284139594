// @flow
import React, { useEffect } from 'react';
import type { intlShape } from 'react-intl';
import cx from 'classnames';

import { Heading } from '@bnc-ui/heading';
import { Alert } from '@bnc-ui/alert';
import { FailureFilledXsmallColor } from '@nbc-design/icons/lib/web/FailureFilledXsmallColor';

import type { SDKError } from '../../../types';
import { queueAnnouncer } from '../../../utils/liveAnnouncer/helper';
import BackLinkButton from '../../../molecules/common/BackLinkButton';
import SendMFACode from '../../../molecules/bne/SendMFACode';

import './style.scss';
import ExpirationModal from '../../common/ExpirationModal';
import BneBroadcastChannel from '../BneBroadcastChannel';
import { BNE_STEP_NAMES } from '../../../utils/taggingUtils/bneTaggingConstants';
import PageReadyTaggingWrapper from '../../../molecules/bne/TaggingBNE/PageReadyTaggingWrapper';

type Props = {
  intl: intlShape,
  returnToLogin: () => void,
  sdkError: SDKError,
  getAuthErrorMessageId: ({}) => string,
  announceMessage?: string => void,
  triggerCodeRequest: (factor: *) => void,
  isFormDone: boolean,
};

const defaultProps = {
  announceMessage: () => {},
};

const MultiFactorAuthChoice = (props: Props) => {
  const {
    className,
    intl,
    returnToLogin,
    sdkError,
    getAuthErrorMessageId,
    announceMessage,
    triggerCodeRequest,
    isFormDone,
  } = props;

  useEffect(() => {
    if (sdkError.code) {
      queueAnnouncer(
        [
          intl.formatMessage(
            { id: `auth.error.${getAuthErrorMessageId(sdkError)}` },
            { code: sdkError.code },
          ),
        ],
        announceMessage,
      );
    }
  }, [sdkError, getAuthErrorMessageId, intl, announceMessage]);

  // Needs to be refactored once the design has been decided
  return (
    <PageReadyTaggingWrapper
      locale={intl.locale}
      isLoading={isFormDone}
      stepName={BNE_STEP_NAMES.SEND_SECURITY_CODE}
    >
      <div
        id="multiFactorAuthChoice"
        className={cx('connect-form mfa', className)}
      >
        <Heading type="h1" size={1}>
          {intl.formatMessage({ id: 'text.title.validationCode' })}
        </Heading>

        {!!sdkError.code && sdkError.code !== '3102' && (
          <Alert
            className="send-error-message"
            title={intl.formatMessage(
              { id: `auth.error.${getAuthErrorMessageId(sdkError)}` },
              { code: sdkError.code },
            )}
            appearance="error"
            size="small"
            icon={<FailureFilledXsmallColor size="small" title="Error" />}
          />
        )}

        <Heading type="h2" size={1}>
          {intl.formatMessage({ id: 'multiFactorAuthChoice.title.choice' })}
        </Heading>

        <SendMFACode triggerCodeRequest={triggerCodeRequest} />

        <BackLinkButton
          label={intl.formatMessage({
            id: 'global.button.backToLogin.bne',
          })}
          className="back-link-button--entreprise"
          onClick={returnToLogin}
        />
        <ExpirationModal />
        <BneBroadcastChannel channelName="accountLocked_sync" />
      </div>
    </PageReadyTaggingWrapper>
  );
};

MultiFactorAuthChoice.defaultProps = defaultProps;

export default MultiFactorAuthChoice;
