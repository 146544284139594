// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { announceMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';
import AccountLocked from './accountLocked';

import { returnToLogin } from '../../../services/loginService/actions';
import {
  getPartnerIdName,
  getTemplateName,
} from '../../../services/templateService/selectors';
import { getLoginState } from '../../../services/loginService/selectors';

function mapStateToProps(state) {
  const loginState = getLoginState(state);
  return {
    isFetching: loginState.isFetching,
    partnerId: getPartnerIdName(state),
    templateName: getTemplateName(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    announceMessage: msg => dispatch(announceMessage(msg, 'polite')),
    returnToLogin: () => dispatch(returnToLogin()),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AccountLocked),
);
