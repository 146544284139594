// @flow
import * as actionTypes from './actionTypes';
import type { Action as ReduxAction } from '../../types/action';

export type LoginRouteMfaAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_MFA,
>;

export type LoginRouteRsaAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_RSA,
>;

export type LoginRouteMfaChoiceAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_MFA_CHOICE,
>;

export type LoginRoutePasswordExpiredAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_PASSWORD_EXPIRED,
>;

export type LoginRouteLockedAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_LOCKED,
>;

export type LoginRoutePasswordExceptionAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_PASSWORD_EXCEPTION,
>;

export type LoginRouteSignUpAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_SIGN_UP,
>;

export type LoginRouteAppOriginAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_APP_ORIGIN,
>;

export type LoginRouteForgotPasswordAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_FORGOT_PASSWORD,
>;

export type LoginRouteForgotPasswordExceptionAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_FORGOT_PASSWORD_EXCEPTION,
>;

export type LoginRouteWithoutSignInAction = ReduxAction<
  typeof actionTypes.LOGIN_ROUTE_WITHOUT_SIGN_IN,
>;

export type ForgotPasswordUnavailableAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_UNAVAILABLE,
>;

export type Action =
  | LoginRouteLockedAction
  | LoginRouteMfaAction
  | LoginRouteRsaAction
  | LoginRouteMfaChoiceAction
  | LoginRoutePasswordExceptionAction
  | LoginRoutePasswordExpiredAction
  | LoginRouteAppOriginAction
  | LoginRouteSignUpAction
  | LoginRouteForgotPasswordAction
  | LoginRouteForgotPasswordExceptionAction
  | LoginRouteWithoutSignInAction
  | ForgotPasswordUnavailableAction;
