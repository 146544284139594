// @flow
import * as yup from 'yup';
import { isValidIdentity } from 'bnc-utilities-js/formUtils';

import type { LoginFormData } from '../../types/forms/loginForm';

import { isFieldValid, isFormValid } from '../../utils/formUtils';
import TEMPLATES from '../../utils/constants/template';

yup.addMethod(yup.string, 'validateIdentity', function testIdentity(message) {
  return this.test('testIdentity', message, value => isValidIdentity(value));
});
yup.addMethod(yup.string, 'validateIdentityEnterprise', function testIdentity(
  message,
) {
  return this.test('testIdentity', message, value => value !== '');
});
yup.addMethod(
  yup.string,
  'validateIdentityBneEnterprise',
  function testIdentity(message) {
    return this.test(
      'testIdentityEnterprise',
      message,
      value => value !== '' && !value.includes('@'),
    );
  },
);

export const loginFormSchema = yup.object({
  identity: yup
    .string()
    .default('')
    .validateIdentity('identity-error-msg'),
  password: yup
    .string()
    .default('')
    .required('password-error-msg'),
  remember: yup.bool(),
});

export const loginFormSchemaEnterprise = yup.object({
  identityBne: yup
    .string()
    .default('')
    .validateIdentityEnterprise('identity-error-msg')
    .validateIdentityBneEnterprise('identity-enterprise-error-msg'),
  passwordBne: yup
    .string()
    .default('')
    .required('password-error-msg'),
  remember: yup.bool(),
});

export function fieldValidation(
  formData: LoginFormData,
  action: any,
  templateName: string,
) {
  switch (templateName) {
    case TEMPLATES.BNE:
      return isFieldValid(formData, action, loginFormSchemaEnterprise);
    default:
      return isFieldValid(formData, action, loginFormSchema);
  }
}

export function formValidation(formData: LoginFormData, template: string) {
  switch (template) {
    case TEMPLATES.BNE:
      return isFormValid(formData, loginFormSchemaEnterprise);
    default:
      return isFormValid(formData, loginFormSchema);
  }
}
