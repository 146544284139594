// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Right2ColumnsTemplate from './Right2ColumnsTemplate';
import { getLocale } from '../../../../services/i18nService/selectors';
import { getTemplateName } from '../../../../services/templateService/selectors';

const mapStateToProps = state => ({
  locale: getLocale(state),
  templateName: getTemplateName(state),
});

export default injectIntl(connect(mapStateToProps)(Right2ColumnsTemplate));
