import { testRootSagaMappings } from 'bnc-utilities-js/testUtils/saga';
import rootSaga, { workersMapping } from '..';

jest.mock('../workers', () => ({
  watchFormInit: jest.fn(),
  watchFormSubmit: jest.fn(),
  watchChangeStepRequest: jest.fn(),
  watchFormValidation: jest.fn(),
}));

testRootSagaMappings(rootSaga, workersMapping);
