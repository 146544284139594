// @flow
import type { Action as ReduxAction } from '../../types/action';
import * as actionTypes from './actionTypes';

export type FormInitRequestAction = ReduxAction<
  typeof actionTypes.FORM_HANDLER_FORM_INIT_REQUEST,
> & {
  formId: string,
  formName: string,
  initArgs: any,
};

export type SetFormInputsStateAction = ReduxAction<
  typeof actionTypes.FORM_HANDLER_POPULATE_FORM_INPUTS,
> & {
  formName: string,
  inputs: any,
};

export type FocusOnFirstErrorFieldAction = ReduxAction<
  typeof actionTypes.FORM_HANDLER_FOCUS_ERROR_INPUT_REQUEST,
> & {
  formName: string,
  errors: any,
  scrollTop: boolean,
};

export type FocusOnFirstErrorFieldSuccessAction = ReduxAction<
  typeof actionTypes.FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
> & {};
export type NoFieldToFocusOnAction = ReduxAction<
  typeof actionTypes.FORM_HANDLER_NO_FIELD_TO_FOCUS_ON,
> & {};

export type FormInitDoneAction = ReduxAction<
  typeof actionTypes.FORM_HANDLER_FORM_INIT_DONE,
> & {
  formId: string,
};

export type ResetFormAction = ReduxAction<
  typeof actionTypes.FORM_HANDLER_RESET_FORM_REQUEST,
> & {
  formId: string,
  formName: string,
};

export type Action =
  | FormInitRequestAction
  | SetFormInputsStateAction
  | FocusOnFirstErrorFieldAction
  | FocusOnFirstErrorFieldSuccessAction
  | NoFieldToFocusOnAction
  | FormInitDoneAction
  | ResetFormAction;
