// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import {
  BNC_AUTH_SIGN_IN_COMPLETED,
  BNC_AUTH_SIGN_IN_FAILURE,
  BNC_AUTH_VERIFY_SUCCESS,
  BNC_AUTH_VERIFY_FAILURE,
  BNC_AUTH_SAVE_IDENTITY,
  BNC_AUTH_SEND_SUCCESS,
  BNC_AUTH_SEND_FAILURE,
  BNC_AUTH_RESEND_SUCCESS,
  BNC_AUTH_RESEND_FAILURE,
  BNC_AUTH_TOKEN_ENROLL,
} from 'bnc-react-services/services/AuthService/actionTypes';

import { LOCATION_CHANGE } from 'react-router-redux';

import * as actionTypes from './actionTypes';
import * as workers from './workers';
import { watchTrackResendCodeSucess } from './taggingWorkers';

import { TRACK_RESEND_SUCCESS, USER_LOCKED_PASSWORD } from './actionTypes';

export const workersMapping = createWorkersMapping()
  .takeLatest(BNC_AUTH_SIGN_IN_COMPLETED, workers.watchBncSignInCompleted)
  .takeLatest(BNC_AUTH_SIGN_IN_FAILURE, workers.watchBncSignInFailure)
  .takeLatest(BNC_AUTH_VERIFY_SUCCESS, workers.watchBncVerifySuccess)
  .takeLatest(BNC_AUTH_VERIFY_FAILURE, workers.watchBncVerifyFailure)
  .takeLatest(actionTypes.LOGIN_REMOVE_IDENTITY, workers.watchRemoveIdentity)
  .takeLatest(actionTypes.LOGIN_RETURN_TO_LOGIN, workers.watchReturnToLogin)
  .takeLatest(actionTypes.LOGIN_REQUEST, workers.watchLoginRequest)
  .takeLatest(
    actionTypes.LOGIN_MFA_TRIGGER_HIDE_RESEND,
    workers.watchTriggerHideResendButtons,
  )
  .takeLatest(
    actionTypes.LOGIN_MFA_CODE_VALIDATION_REQUEST,
    workers.watchLoginMfaCodeValidationRequest,
  )
  .takeLatest(
    actionTypes.LOGIN_MFA_SEND_CODE_REQUEST,
    workers.watchLoginMfaSendCodeRequest,
  )
  .takeLatest(
    actionTypes.LOGIN_MFA_RESEND_CODE_REQUEST,
    workers.watchLoginMfaResendCodeRequest,
  )
  .takeLatest(actionTypes.LOGIN_FAILURE, workers.watchLoginFailure)
  .takeLatest(BNC_AUTH_SAVE_IDENTITY, workers.watchBncAuthSaveIdentity)
  .takeLatest(BNC_AUTH_SEND_SUCCESS, workers.watchBncSendSuccess)
  .takeLatest(BNC_AUTH_SEND_FAILURE, workers.watchBncSendFailure)
  .takeLatest(BNC_AUTH_RESEND_SUCCESS, workers.watchBncResendSuccess)
  .takeLatest(BNC_AUTH_RESEND_FAILURE, workers.watchBncResendFailure)
  .takeLatest(LOCATION_CHANGE, workers.watchLocationChange)
  .takeLatest(USER_LOCKED_PASSWORD, workers.watchUserLockedPasswordTimeout)
  .takeLatest(BNC_AUTH_TOKEN_ENROLL, workers.watchBncTokenEnroll)
  .takeLatest(TRACK_RESEND_SUCCESS, watchTrackResendCodeSucess);

export default createRootSagaFromWorkersMapping(workersMapping);
