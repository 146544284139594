import { anonymizeUsername, getIcon } from '../utils';
import Biometry from '../biometry';

import type { OS } from '../../../types/browser';

describe('anonymizeUsername', () => {
  it('should return dum*****', () => {
    expect(anonymizeUsername('dum')).toEqual('dum*****');
  });
});

describe('getIcon', () => {
  it('should return android-fingerprint', () => {
    const biometry = new Biometry(null, 'fingerprint', null, null);
    const os: OS = {
      name: 'Android',
    };

    expect(getIcon(biometry, os)).toEqual('android-fingerprint');
  });

  it('should return android-face', () => {
    const biometry = new Biometry(null, 'facialRecognition', null, null);
    const os: OS = {
      name: 'Android',
    };

    expect(getIcon(biometry, os)).toEqual('android-face');
  });

  it('should return apple-touch-id', () => {
    const biometry = new Biometry(null, 'fingerprint', null, null);
    const os: OS = {
      name: 'iOS',
    };

    expect(getIcon(biometry, os)).toEqual('apple-touch-id');
  });

  it('should return apple-face-id', () => {
    const biometry = new Biometry(null, 'facialRecognition', null, null);
    const os: OS = {
      name: 'iOS',
    };

    expect(getIcon(biometry, os)).toEqual('apple-face-id');
  });
});
