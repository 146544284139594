import React from 'react';

import Right2ColumnsTemplate from '../../models/Right2ColumnsTemplate';

import Header from '../../../organisms/rewardsWhiteLabel/Header';
import Login from '../../../organisms/rewardsWhiteLabel/Login';
import RewardsMedia from '../../../molecules/rewards/BncMediaRewards';
import RewardsWLFooter from '../../../organisms/rewardsWhiteLabel/LoginFooter';

import './style.scss';

const LoginTemplate = () => (
  <Right2ColumnsTemplate
    header={<Header />}
    main={<Login hasForgotPwd={false} />}
    footer={<RewardsWLFooter />}
    assets={<RewardsMedia />}
    className="rewards"
  />
);

export default LoginTemplate;
