// @flow
import * as actionTypes from './actionTypes';
import type {
  LoginRouteMfaAction,
  LoginRouteRsaAction,
  LoginRouteMfaChoiceAction,
  LoginRouteLockedAction,
  LoginRoutePasswordExceptionAction,
  LoginRoutePasswordExpiredAction,
  LoginRouteSignUpAction,
  LoginRouteAppOriginAction,
  LoginRouteForgotPasswordAction,
  LoginRouteForgotPasswordExceptionAction,
  LoginRouteWithoutSignInAction,
  ForgotPasswordUnavailableAction,
} from './types';

export function loginRouteRequestMFAChoice(): LoginRouteMfaChoiceAction {
  return {
    type: actionTypes.LOGIN_ROUTE_MFA_CHOICE,
  };
}

export function loginRouteRequestMFA(): LoginRouteMfaAction {
  return {
    type: actionTypes.LOGIN_ROUTE_MFA,
  };
}

export function loginRouteRequestRSA(): LoginRouteRsaAction {
  return {
    type: actionTypes.LOGIN_ROUTE_RSA,
  };
}

export function loginRouteRequestPasswordExpired(): LoginRoutePasswordExpiredAction {
  return {
    type: actionTypes.LOGIN_ROUTE_PASSWORD_EXPIRED,
  };
}

export function loginRouteRequestLocked(): LoginRouteLockedAction {
  return {
    type: actionTypes.LOGIN_ROUTE_LOCKED,
  };
}

export function loginRouteRequestPasswordException(): LoginRoutePasswordExceptionAction {
  return {
    type: actionTypes.LOGIN_ROUTE_PASSWORD_EXCEPTION,
  };
}

export function loginRouteRequestSignUp(): LoginRouteSignUpAction {
  return {
    type: actionTypes.LOGIN_ROUTE_SIGN_UP,
  };
}

export function loginRouteRequestAppOrigin(): LoginRouteAppOriginAction {
  return {
    type: actionTypes.LOGIN_ROUTE_APP_ORIGIN,
  };
}

export function loginRouteForgotPassword(): LoginRouteForgotPasswordAction {
  return {
    type: actionTypes.LOGIN_ROUTE_FORGOT_PASSWORD,
  };
}

export function forgotPasswordUnavailable(): ForgotPasswordUnavailableAction {
  return {
    type: actionTypes.FORGOT_PASSWORD_UNAVAILABLE,
  };
}

export function loginRouteForgotPasswordException(): LoginRouteForgotPasswordExceptionAction {
  return {
    type: actionTypes.LOGIN_ROUTE_FORGOT_PASSWORD_EXCEPTION,
  };
}

export function loginRouteResetPassword() {
  return {
    type: actionTypes.LOGIN_ROUTE_RESET_PASSWORD,
  };
}

export function loginRouteWithoutSignIn(): LoginRouteWithoutSignInAction {
  return {
    type: actionTypes.LOGIN_ROUTE_WITHOUT_SIGN_IN,
  };
}
