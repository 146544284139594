import isString from 'lodash/isString';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';

import { shouldShowIE11Page } from '../helper';

import { IE11_BROWSER_NAME } from '../../../utils/browserUtils';
import { Configs } from '../../../configs';

const addDaysToDate = (date, days) => {
  return isString(date) ? addDays(parseISO(date), days) : addDays(date, days);
};

describe('shouldShowIE11Page', () => {
  const testCases = [
    {
      description: 'should return false before deadline - IE',
      browser: IE11_BROWSER_NAME,
      browserVersion: 11,
      date: addDaysToDate(Configs.NO_HOMOLOGATION_IE11.IE11_ENDED_SUPPORT, -10),
      result: false,
    },
    {
      description: 'should return true same date of deadline - IE',
      browser: IE11_BROWSER_NAME,
      browserVersion: 11,
      date: new Date(Configs.NO_HOMOLOGATION_IE11.IE11_ENDED_SUPPORT),
      result: true,
    },
    {
      description: 'should return true one day after deadline - IE',
      browser: IE11_BROWSER_NAME,
      browserVersion: 11,
      date: addDaysToDate(Configs.NO_HOMOLOGATION_IE11.IE11_ENDED_SUPPORT, 1),
      result: true,
    },
    {
      description: 'should return true same date of deadline - Other browser',
      browser: 'Somethings',
      browserVersion: 11,
      date: new Date(Configs.NO_HOMOLOGATION_IE11.IE11_ENDED_SUPPORT),
      result: false,
    },
    {
      description: 'should return false before effective date - IE',
      browser: 'Somethings',
      browserVersion: 11,
      date: addDaysToDate(Configs.NO_HOMOLOGATION_IE11.IE11_EFFECTIVE_DATE, -1),
      result: false,
    },
    {
      description:
        'should return true after effective date - IE (deadline date should be 1 month after)',
      browser: IE11_BROWSER_NAME,
      browserVersion: 11,
      date: addDaysToDate(Configs.NO_HOMOLOGATION_IE11.IE11_EFFECTIVE_DATE, 32),
      result: true,
    },
  ];

  testCases.forEach(({ description, browser, browserVersion, date, result }) =>
    it(description, () => {
      expect(shouldShowIE11Page(browser, browserVersion, date)).toEqual(result);
    }),
  );
});
