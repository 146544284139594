import * as cookie from '../../cookie';
import { getForgotPasswordUrl } from '../EnvironmentUtils';

jest.mock('../../cookie', () => ({
  get: jest.fn(),
}));

describe('getEnvironmentCookie', () => {
  it('cookie get to be called', () => {
    getForgotPasswordUrl();
    expect(cookie.get).toHaveBeenCalled();
  });
});
