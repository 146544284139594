import {
  BIOMETRIC_VALIDATION_RESULT,
  BIOMETRIC_CREDENTIALS_DELETION_RESULT,
} from './constants';

export const BIOMETRY_INITIALIZATION = 'BIOMETRY_INITIALIZATION';
export const BIOMETRY_INITIALIZATION_SUCCESS =
  'BIOMETRY_INITIALIZATION_SUCCESS';
export const BIOMETRY_VALIDATION_RESULT =
  'BIOMETRY_EVENT_BIOMETRY_VALIDATION_RESULT';
export const BIOMETRY_VALIDATION_SUCCESS = 'BIOMETRY_VALIDATION_SUCCESS';
export const BIOMETRY_VALIDATION_FAILED = 'BIOMETRY_VALIDATION_FAILED';
export const BIOMETRY_FORCE_LOGIN = 'BIOMETRY_FORCE_LOGIN';
export const BIOMETRY_CREDENTIALS_DELETION_RESULT =
  'BIOMETRY_CREDENTIALS_DELETION_RESULT';
export const BIOMETRY_CREDENTIALS_DELETION_SUCCESS =
  'BIOMETRY_CREDENTIALS_DELETION_SUCCESS';
export const BIOMETRY_CREDENTIALS_DELETION_FAILED =
  'BIOMETRY_CREDENTIALS_DELETION_FAILED';
export const BIOMETRY_CREDENTIALS_SYNCHRONIZATION_ERROR =
  'BIOMETRY_CREDENTIALS_SYNCHRONIZATION_ERROR';
export const BIOMETRY_LAUNCH_CREDENTIALS_SYNCHRONIZATION =
  'BIOMETRY_LAUNCH_CREDENTIALS_SYNCHRONIZATION';

export const eventActionTypeMapper = {
  [BIOMETRIC_VALIDATION_RESULT]: BIOMETRY_VALIDATION_RESULT,
  [BIOMETRIC_CREDENTIALS_DELETION_RESULT]: BIOMETRY_CREDENTIALS_DELETION_RESULT,
};
