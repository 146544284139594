import { MFA_FORM_SERVICE_REDUCER } from '../../../globalRedux/reducers/constants';
import generateSelectorTests from '../../../utils/selectorTestHelper';
import {
  getContact,
  getFactorTypes,
  getMFAFormData,
  getSelectedFactor,
  isHideResend,
  getMFAFormErrors,
  getMFAExecutedAction,
  isAuthFormDone,
  getMFAFormState,
} from '../selectors';

describe('getMFAFormState', () => {
  it('should return state if they are available', () => {
    const state = {
      multiFactorAuthFormServiceReducer: { test: 1 },
    };
    expect(getMFAFormState(state)).toEqual({ test: 1 });
  });

  it('should return {} if state are not available', () => {
    const state = {};
    expect(getMFAFormState(state)).toEqual({});
  });

  it('should return module state if they are available', () => {
    const modulePath = 'front_end.sso';

    const state = {
      front_end: {
        sso: {
          multiFactorAuthFormServiceReducer: { test: 1 },
        },
      },
    };
    expect(getMFAFormState(state, modulePath)).toEqual({ test: 1 });
  });

  it('should return initDone state ', () => {
    const state = { multiFactorAuthFormServiceReducer: { initDone: true } };
    expect(isAuthFormDone(state)).toEqual(true);
  });
});

const TEST_SELECTORS = [
  {
    testName: 'isHideResend',
    selector: isHideResend,
    selectorField: `${MFA_FORM_SERVICE_REDUCER}.hideResend`,
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    testName: 'getFactorTypes',
    selector: getFactorTypes,
    selectorField: `${MFA_FORM_SERVICE_REDUCER}.factorTypes`,
    selectorType: 'array.string',
    defaultValue: [],
  },
  {
    testName: 'getSelectedFactor',
    selector: getSelectedFactor,
    selectorField: `${MFA_FORM_SERVICE_REDUCER}.selectedFactor`,
    selectorType: 'string',
    defaultValue: '',
  },
  {
    testName: 'getContact',
    selector: getContact,
    selectorField: `${MFA_FORM_SERVICE_REDUCER}.contact`,
    selectorType: 'string',
    defaultValue: '',
  },
  {
    testName: 'getMFAFormData',
    selector: getMFAFormData,
    selectorField: `${MFA_FORM_SERVICE_REDUCER}.formData`,
    selectorType: 'object',
    defaultValue: {},
  },
  {
    testName: 'getMFAFormErrors',
    selector: getMFAFormErrors,
    selectorField: `${MFA_FORM_SERVICE_REDUCER}.errors`,
    selectorType: 'object',
    defaultValue: {},
  },
  {
    testName: 'getMFAExecutedAction',
    selector: getMFAExecutedAction,
    selectorField: `${MFA_FORM_SERVICE_REDUCER}.executedAction`,
    selectorType: 'object',
    defaultValue: {},
  },
  {
    testName: 'isAuthFormDone',
    selector: isAuthFormDone,
    selectorField: `${MFA_FORM_SERVICE_REDUCER}.initDone`,
    selectorType: 'boolean',
    defaultValue: false,
  },
];

describe('Test Selectors', generateSelectorTests(TEST_SELECTORS));
