import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { call, select } from 'redux-saga/effects';
import { trackAction } from '@nbc-studio/analytics';
import { sendFunctionality } from '../../utils/taggingUtils/helper';

import { rootSelector } from './selectors';
import {
  FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE,
  FORGOT_PASSWORD_FORM_INIT_DONE,
} from '../forgotPasswordFormService/actionTypes';
import {
  EVENT_IDS,
  EVENT_NAMES,
  EVENT_SOURCE,
  FUNCTIONALITIES,
  PAGES,
  UCS_IDS,
  UCS_STATE,
} from '../../utils/taggingUtils/constants';
import {
  FORGOT_PASSWORD_RESEND_OTC,
  FORGOT_PASSWORD_SEND_ANALYTICS,
  FORGOT_PASSWORD_SET_PASSWORD_SUCCESS,
} from './actionTypes';
import { getLocale } from '../i18nService/selectors';

const commonPageLoadEventProperties = {
  ucsId: 'ucs-sbip2-forgotpassword-0001',
  eventSource: 'sbip2',
  eventSourceDetail: 'forgotpassword',
  loadTime: '',
  loggedStatus: 'not-loggedin',
  lob: 'personal',
  event: 'trackPageView',
};

const commonPageForgotPasswordFormInitProperties = {
  ...commonPageLoadEventProperties,
  pageName: 'sbip2:forgotpassword:personal info',
  stepId: '1',
  stepName: 'Personal info',
};

const commonPageForgotPasswordFormError = {
  event: 'trackEvent',
  ucsId: 'ucs-sbip2-FE',
  eventName: 'formError',
  ucsState: 'non-pv',
  eventId: 'FE-sbip2',
  eventSource: 'sbip2',
};

const eventContextLookup = {
  [FORGOT_PASSWORD_FORM_INIT_DONE]: {
    ...commonPageForgotPasswordFormInitProperties,
    ucsState: 'pv',
    eventName: 'formStart',
    eventId: 'FS-forgotpassword',
  },
  [`${FORGOT_PASSWORD_RESEND_OTC}-email`]: {
    ...commonPageLoadEventProperties,
    ucsState: 'non-pv',
    eventName: 'clickCTA',
    eventId: 'CCTA-forgotpassword',
    interaction: 'resent security code:email',
  },
  [`${FORGOT_PASSWORD_RESEND_OTC}-sms`]: {
    ...commonPageLoadEventProperties,
    ucsState: 'non-pv',
    eventName: 'clickCTA',
    eventId: 'CCTA-forgotpassword',
    interaction: 'resent security code:phone',
  },
  [FORGOT_PASSWORD_SET_PASSWORD_SUCCESS]: {
    ...commonPageLoadEventProperties,
    ucsState: 'pv',
    eventName: 'FormComplete',
    eventId: 'FC-forgotpassword',
    pageName: 'sbip2:forgotpassword:confirmation',
    stepId: '5',
    stepName: 'Confirmation',
  },
  [`${FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE}-email`]: {
    ...commonPageForgotPasswordFormError,
    formError: 'email:empty',
  },
  [`${FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE}-birthDate`]: {
    ...commonPageForgotPasswordFormError,
    formError: 'birthDate:invalid',
  },
  [`${FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE}-cardNumber`]: {
    ...commonPageForgotPasswordFormError,
    formError: 'cardNumber:invalid',
  },
  [`${FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE}-expiryDate`]: {
    ...commonPageForgotPasswordFormError,
    formError: 'expiryDate:invalid',
  },
  [`${FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE}-accountNumber`]: {
    ...commonPageForgotPasswordFormError,
    formError: 'accountNumber:invalid',
  },
  [`${FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE}-originalAmount`]: {
    ...commonPageForgotPasswordFormError,
    formError: 'originalAmount:invalid',
  },
  [`${FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE}-phone`]: {
    ...commonPageForgotPasswordFormError,
    formError: 'phone:invalid',
  },
};

const getExtraKeyInfo = (type, factor, stepName, fieldName) => {
  switch (type) {
    case FORGOT_PASSWORD_SEND_ANALYTICS:
      return stepName;
    case FORGOT_PASSWORD_RESEND_OTC:
      return `${FORGOT_PASSWORD_RESEND_OTC}-${factor}`;
    case FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE:
      return `${FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE}-${fieldName}`;
    default:
      return type;
  }
};

/**
 * Look up context info for an event.
 *
 * @returns context info or empty object if nothing found.
 */
export const getContext = ({ type, factor, stepName, fieldName }, locale) => {
  const contextKey = getExtraKeyInfo(type, factor, stepName, fieldName);
  const contextData = get(eventContextLookup, contextKey, {});
  return isEmpty(contextData)
    ? {}
    : {
        formId: 'forgotpassoword',
        eventTime: new Date(),
        language: locale,
        ...contextData,
      };
};

const getLoadTime = (loginStarted, loginEnded) => {
  const loadTime = loginStarted && loginEnded ? loginEnded - loginStarted : NaN;

  return isNaN(loadTime) ? '' : loadTime;
};

export function* watchTrackResendCodeSucess(action) {
  yield call(trackAction, EVENT_NAMES.CLICK_CTA, {
    ucsId: UCS_IDS.FORGOT_PASSWORD,
    eventId: EVENT_IDS.CCTA_FP,
    eventName: EVENT_NAMES.CLICK_CTA,
    eventSource: EVENT_SOURCE.SBIP2,
    eventSourceDetail: PAGES.FORGOT_PASSWORD,
    ucsState: UCS_STATE.NON_PV,
    interaction: `resent security code:${action.selectedFactor}`,
  });
}

export function* watchTaggingForgotPasswordEvents(event) {
  const { loginStarted, loginEnded } = yield select(rootSelector);
  const locale = yield select(getLocale);
  const loadTime = getLoadTime(loginStarted, loginEnded);
  const { analytics } = (yield select()).forgotPassword;
  const contextData = getContext(event, locale);

  // Do not send launch tag if no context info found.
  if (!isEmpty(contextData)) {
    yield call(sendFunctionality, FUNCTIONALITIES.FORGOT_PASSWORD, {
      ...analytics, // Common analytics information for all events. Each specific context may choose to overwrite it.
      ...contextData,
      loadTime,
    });
  }
}
