import {
  getActivePartnerIds,
  getConfigForPartner,
  getPartnerLoginImage,
} from '../index';
import {
  BASE_RESOURCES_LOGIN_IMAGE_URL,
  DEFAULT_LOGIN_IMAGE_NAME,
} from '../../constants/login';

describe('check config for partner', () => {
  it('should return null', () => {
    expect(getConfigForPartner('WHITE_LABEL', 'BNE')).toBeNull();
  });
});

describe('getActivePartnerIds', () => {
  [
    {
      templateName: 'SBIP2',
      expectedValue: ['f55', 'investorsgroup', 'gwl', 'partnership'],
    },
    {
      templateName: 'REWARDS',
      expectedValue: ['partner'],
    },
  ].forEach(({ templateName, expectedValue }) => {
    it(`should return ${expectedValue} for ${templateName}`, () => {
      expect(getActivePartnerIds(templateName)).toEqual(expectedValue);
    });
  });

  ['BNE', 'SECURE_KEY', 'NATGO', 'BAO', 'OBE'].forEach(({ templateName }) => {
    it(`should return empty array for ${templateName}`, () => {
      expect(getActivePartnerIds(templateName)).toEqual([]);
    });
  });
});

describe('check the partner login image', () => {
  it('should return default image when partnerId does not exist (french)', () => {
    expect(getPartnerLoginImage('fr', 'abcde')).toEqual(
      `${BASE_RESOURCES_LOGIN_IMAGE_URL}/${DEFAULT_LOGIN_IMAGE_NAME}`,
    );
  });

  it('should return default image when partnerId does not exist (english)', () => {
    expect(getPartnerLoginImage('en', 'abcde')).toEqual(
      `${BASE_RESOURCES_LOGIN_IMAGE_URL}/${DEFAULT_LOGIN_IMAGE_NAME}`,
    );
  });

  [
    {
      locale: 'fr',
      partnerId: 'f55',
      expectedValue: `${BASE_RESOURCES_LOGIN_IMAGE_URL}/partners/f55/img-login.jpg`,
    },
    {
      locale: 'en',
      partnerId: 'f55',
      expectedValue: `${BASE_RESOURCES_LOGIN_IMAGE_URL}/partners/f55/img-login.jpg`,
    },
    {
      locale: 'fr',
      partnerId: 'gwl',
      expectedValue: `${BASE_RESOURCES_LOGIN_IMAGE_URL}/partners/gwl/img-login.jpg`,
    },
    {
      locale: 'en',
      partnerId: 'gwl',
      expectedValue: `${BASE_RESOURCES_LOGIN_IMAGE_URL}/partners/gwl/img-login.jpg`,
    },
    {
      locale: 'fr',
      partnerId: 'investorsgroup',
      expectedValue: `${BASE_RESOURCES_LOGIN_IMAGE_URL}/partners/ig/img-login.jpg`,
    },
    {
      locale: 'en',
      partnerId: 'investorsgroup',
      expectedValue: `${BASE_RESOURCES_LOGIN_IMAGE_URL}/partners/ig/img-login.jpg`,
    },
    {
      locale: 'fr',
      partnerId: 'partnership',
      expectedValue: `${BASE_RESOURCES_LOGIN_IMAGE_URL}/partners/partnership/img-login.jpg`,
    },
    {
      locale: 'en',
      partnerId: 'partnership',
      expectedValue: `${BASE_RESOURCES_LOGIN_IMAGE_URL}/partners/partnership/img-login.jpg`,
    },
  ].forEach(({ locale, partnerId, expectedValue }) => {
    it(`should return ${expectedValue} for partner ${partnerId}, when locale is ${locale}`, () => {
      expect(getPartnerLoginImage(locale, partnerId)).toEqual(expectedValue);
    });
  });
});
