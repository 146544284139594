// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';
import { put } from 'redux-saga/effects';
import requestsManager from 'bnc-react-services/managers/RequestsManager';
import propagateGlobalErrors from 'bnc-react-services/utils/propagateErrorsHandler';
import type { Saga } from 'redux-saga';

import * as actionTypes from './actionTypes';
import {
  tokenCaptchaRequestSuccess,
  tokenCaptchaRequestFailure,
} from './actions';
import api from './api';

import type { TokenCaptchaRequestAction } from './types';

function* watchTokenCaptchaRequest(
  action: TokenCaptchaRequestAction,
): Saga<void> {
  try {
    const response = yield requestsManager.call(
      api.tokenCaptchaFetch,
      action.recaptcha,
      action.nbfgId,
    );
    if (response.expiresIn) {
      const expirationTime = new Date();
      expirationTime.setSeconds(
        expirationTime.getSeconds() + parseInt(response.expiresIn, 10) - 5,
      );
      response.expirationTime = expirationTime;
    }
    yield put(tokenCaptchaRequestSuccess(response));
  } catch (e) {
    yield requestsManager.call(propagateGlobalErrors, e);
    yield put(tokenCaptchaRequestFailure());
  }
}

const workersMapping = createWorkersMapping().takeLatest(
  actionTypes.TOKEN_CAPTCHA_REQUEST,
  watchTokenCaptchaRequest,
);

export default createRootSagaFromWorkersMapping(workersMapping);
