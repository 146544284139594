// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import UnsupportedBrowserHeader from './unsupportedBrowserHeader';
import type { State } from '../../../types/state';
import { TEMPLATE_SERVICE_REDUCER } from '../../../globalRedux/reducers/constants';

const mapStateToProps = (state: State) => ({
  partnerId: state[TEMPLATE_SERVICE_REDUCER].partnerId,
});

export default injectIntl(connect(mapStateToProps)(UnsupportedBrowserHeader));
