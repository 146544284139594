import toUpper from 'lodash/toUpper';
import { Configs } from '../../configs';
import {
  FORGOT_PASSWORD_PREFIX_SEND_OTC,
  FORGOT_PASSWORD_PREFIX_SET_PASSWORD,
  FORGOT_PASSWORD_PREFIX_VERIFY_OTC,
  FORGOT_PASSWORD_PREFIX_VERIFY_USER,
} from '../../utils/authErrorMessages';
import HTTP from '../../utils/constants/http';
import { iamxFetch } from '../../utils/fetchUtils';
import { ACCEPT_LANGUAGE_HEADER } from '../../utils/constants/i18n';

export const forgotPasswordValidateUserFetch = (
  tokenRecaptcha,
  profileData,
  lang,
) => {
  // FIXME: MOCK VERIFY USER RETURN  (HHANH ಠ_ಠ) - ForgotPasswordMockMode
  // return Promise.resolve({
  //   factors: [
  //     {
  //       factorType: 'SMS',
  //       value: '1 xxx-xxx-1012',
  //     },
  //     {
  //       factorType: 'EMAIL',
  //       value: 'u***t@gmail.com',
  //     },
  //   ],
  // });

  // FIXME: MOCK VERIFY USER FAIL  (HHANH ಠ_ಠ)
  // throw ({
  //   code: 1280,
  //   message: "ACCOUNT_LOCKED"
  // })

  return iamxFetch(
    Configs.API.ENDPOINT.IAMX.VERIFY_USER,
    {
      method: HTTP.METHODS.POST,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenRecaptcha}`,
        [ACCEPT_LANGUAGE_HEADER]: lang,
      },
      body: JSON.stringify(profileData),
    },
    FORGOT_PASSWORD_PREFIX_VERIFY_USER,
  );
};

export const sendOTC = (factor, token, lang) => {
  // FIXME: MOCK SEND OTC SUCCESS  (HHANH ಠ_ಠ) - ForgotPasswordMockMode
  // return Promise.resolve({
  //   code: 1241,
  //   message: 'IAMX_OTC_SENT',
  // });

  // FIXME: MOCK SEND OTC FAIL  (HHANH ಠ_ಠ)
  // throw {
  //    "timestamp": "2017-10-05T21:03:16.176+0000",
  //    "code": 800,
  //    "message": "BUSINESS_GENERIC",
  //    "errorIdPrefix": FORGOT_PASSWORD_PREFIX_SEND_OTC
  // }

  return iamxFetch(
    Configs.API.ENDPOINT.IAMX.SEND_OTC,
    {
      method: HTTP.METHODS.POST,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        [ACCEPT_LANGUAGE_HEADER]: lang,
      },
      body: JSON.stringify({ factor: toUpper(factor) }),
    },
    FORGOT_PASSWORD_PREFIX_SEND_OTC,
  );
};

export const verifyOTC = (factor, passcode, token, lang) => {
  // FIXME: MOCK VERIFY OTC SUCCESS  (HHANH ಠ_ಠ) - ForgotPasswordMockMode
  // return Promise.resolve({
  //   factorResult: 'SUCCESS',
  // });

  // FIXME: MOCK VERIFY OTC FAIL  (HHANH ಠ_ಠ)
  // throw {
  //    "timestamp": "2017-10-05T21:03:16.176+0000",
  //    "code": 800,
  //    "message": "ACCOUNT_LOCKED",
  //    "sourceApplication": "iamx-api-local",
  //    "errorIdPrefix": FORGOT_PASSWORD_PREFIX_VERIFY_OTC
  // }

  return iamxFetch(
    Configs.API.ENDPOINT.IAMX.VERIFY_OTC,
    {
      method: HTTP.METHODS.POST,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        [ACCEPT_LANGUAGE_HEADER]: lang,
      },
      body: JSON.stringify({
        factor,
        passcode,
      }),
    },
    FORGOT_PASSWORD_PREFIX_VERIFY_OTC,
  );
};

export const setPasswordFetch = (token, newPassword, lang) => {
  // FIXME: MOCK SET PASSWORD SUCCESS  (AMF ಠ_ಠ) - ForgotPasswordMockMode
  // return Promise.resolve(null);

  // FIXME: MOCK SET PASSWORD FAIL  (AMF ಠ_ಠ)
  // throw {
  //    "timestamp": "2017-10-05T21:03:16.176+0000",
  //    "code": 400,
  //    "message": "BUSINESS_GENERIC",
  //    "sourceApplication": "iamx-api-local",
  //    "errorIdPrefix": FORGOT_PASSWORD_PREFIX_VERIFY_OTC
  // }

  return iamxFetch(
    Configs.API.ENDPOINT.IAMX.SET_PASSWORD,
    {
      method: HTTP.METHODS.PUT,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        [ACCEPT_LANGUAGE_HEADER]: lang,
      },
      body: JSON.stringify({
        newPassword,
      }),
    },
    FORGOT_PASSWORD_PREFIX_SET_PASSWORD,
  );
};
