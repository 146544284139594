import { call } from 'redux-saga/effects';
import { trackState } from '@nbc-studio/analytics';
import {
  isArray,
  isBoolean,
  isEmpty,
  isNull,
  isNumber,
  isPlainObject,
  isString,
} from 'lodash/lang';
import { FLOW_STEPS, FORM_LOGIN_FIELD_ERROR } from './constants';
import { getFirstErrorKey } from '../formUtils';
import {
  BREAKING_POINTS_LABELS,
  BREAKING_POINTS_VALUES,
  // eslint-disable-next-line import/named
  FORM_LOGIN_FIELD_ERROR_BNE,
  trackActionExcludedFields,
} from './bneTaggingConstants';

export const getStepEventName = stepType => {
  switch (stepType || 'step') {
    case FLOW_STEPS.START:
      return 'formStart';
    case FLOW_STEPS.END:
      return 'formComplete';
    case FLOW_STEPS.INTERMEDIATE:
    default:
      return 'formStep';
  }
};

/**
 * send functionnality event with sbip.
 *
 * @param {string} name event name ( formId )
 * @param {object} context event context
 */
export function* sendFunctionality(name, context = {}) {
  const ctx = {
    formId: name,
    ...context,
    eventSourceDetail: context.eventSourceDetail || 'functionality',
  };

  if (ctx.stepType) {
    ctx.eventName = getStepEventName(ctx.stepType);
  }

  yield call(trackState, name, ctx);
}

/**
 * get description about field error with.
 *
 * @param hasLoginFailure
 * @param {object} errors ( object with errors form )
 */
export const getErrorDescription = (hasLoginFailure, errors) => {
  const fieldName = getFirstErrorKey(errors);

  return hasLoginFailure
    ? 'email or password invalid'
    : `${fieldName}:${
        fieldName === 'identity'
          ? FORM_LOGIN_FIELD_ERROR.IDENTITY
          : FORM_LOGIN_FIELD_ERROR.PASSWORD
      }`;
};

/**
 * get description about fields error with.
 *
 * @param hasLoginFailure
 * @param {object} errors ( object with errors form )
 */
export const getAccurateErrorDescription = (hasLoginFailure, errors) => {
  let result = '';
  if (hasLoginFailure) {
    result = 'email or password invalid';
  } else if (Object.keys(errors).length === 1) {
    const fieldName = Object.keys(errors)[0];
    result = `${fieldName}:${
      fieldName === 'identityBne'
        ? FORM_LOGIN_FIELD_ERROR_BNE.IDENTITY
        : FORM_LOGIN_FIELD_ERROR_BNE.PASSWORD
    }`;
  } else {
    for (const key of Object.keys(errors)) {
      if (key === 'identityBne') {
        result += `${key}:${FORM_LOGIN_FIELD_ERROR_BNE.IDENTITY};`;
      } else if (key === 'passwordBne') {
        result += `${key}:${FORM_LOGIN_FIELD_ERROR_BNE.PASSWORD};`;
      }
    }
  }

  return result;
};

export const trimTrackAction = () => {
  return event => {
    if (event.type === 'action') {
      const context = { ...event.context };

      trackActionExcludedFields.forEach(prop => delete context[prop]);
      return {
        ...event,
        context,
      };
    }

    return event;
  };
};

const orderedBreakPointsDimensions = [
  { label: BREAKING_POINTS_LABELS.xxl, value: BREAKING_POINTS_VALUES.xxl },
  { label: BREAKING_POINTS_LABELS.xl, value: BREAKING_POINTS_VALUES.xl },
  { label: BREAKING_POINTS_LABELS.lg, value: BREAKING_POINTS_VALUES.lg },
  { label: BREAKING_POINTS_LABELS.md, value: BREAKING_POINTS_VALUES.md },
  { label: BREAKING_POINTS_LABELS.sm, value: BREAKING_POINTS_VALUES.sm },
  { label: BREAKING_POINTS_LABELS.xs, value: BREAKING_POINTS_VALUES.xs },
];
/**
 * get the responsive dimension of the screen for tagging "breakpoints"
 */
export const findScreenDimension = (
  currentScreenDimension = window.innerWidth,
) => {
  const lowestDimension =
    orderedBreakPointsDimensions[orderedBreakPointsDimensions.length - 1];
  const deviceDimensionIndex = orderedBreakPointsDimensions.findIndex(
    dimensionsCursor => dimensionsCursor.value <= currentScreenDimension,
  );

  return deviceDimensionIndex >= 0
    ? orderedBreakPointsDimensions[deviceDimensionIndex].label
    : lowestDimension.label;
};

function flattenObject(o, prefix = '', result = {}, keepNull = true) {
  if (isString(o) || isNumber(o) || isBoolean(o) || (keepNull && isNull(o))) {
    // eslint-disable-next-line no-param-reassign
    result[prefix] = o;
    return result;
  }

  if (isArray(o) || isPlainObject(o)) {
    // eslint-disable-next-line guard-for-in
    for (const i in o) {
      let pref = prefix;
      if (isArray(o)) {
        pref += `[${i}]`;
      } else if (isEmpty(prefix)) {
        pref = i;
      } else {
        pref = `${prefix}.${i}`;
      }
      flattenObject(o[i], pref, result, keepNull);
    }
    return result;
  }
  return result;
}

export const getFilterKeys = (filter, obj) => {
  const filtered = [];
  const flatObj = flattenObject(obj);
  for (const key in flatObj) {
    if (key.endsWith(filter)) {
      filtered.push(flatObj[key]);
    }
  }
  return filtered;
};
