// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import * as actionTypes from './actionTypes';
import * as workers from './workers';

export const workersMapping = createWorkersMapping()
  .takeLatest(actionTypes.LOGIN_FORM_INIT_REQUEST, workers.watchFormInit)
  .takeLatest(actionTypes.LOGIN_FORM_SUBMIT_REQUEST, workers.watchFormSubmit)
  .takeLatest(
    actionTypes.LOGIN_FORM_CHANGE_STEP_REQUEST,
    workers.watchChangeStepRequest,
  )
  .takeLatest(
    actionTypes.LOGIN_FORM_VALIDATION_REQUEST,
    workers.watchFormValidation,
  );

export default createRootSagaFromWorkersMapping(workersMapping);
