import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';
import ErrorMessage from '../../../organisms/common/ParamUnavailableError';
import Header from '../../../organisms/common/Header';
import './style.scss';

type Props = {
  errorTitle: string,
  errorText: string,
};

const ErrorTemplate = (props: Props) => {
  const { errorTitle, errorText } = props;

  const mainContent = (
    <div className="main-center">
      <ErrorMessage errorTitle={errorTitle} errorText={errorText} />
    </div>
  );

  return (
    <FullScreenFooterTemplate
      header={<Header hasSwitchLang={false} />}
      main={mainContent}
      className="error-background"
    />
  );
};

export default ErrorTemplate;
