import React from 'react';
import type { IntlShape } from 'react-intl';

import Dialog from '@bnc-ui/dialog';
import { Button } from '@bnc-ui/button';
import { Heading } from '@bnc-ui/heading';
import { Icon } from '@nbc-design/icons';
import Biometry from '../../../services/biometryService/biometry';
import type { OS } from '../../../types/browser';
import { getIcon } from '../../../services/biometryService/utils';

import './style.scss';

type Props = {
  intl: IntlShape,
  isShow: boolean,
  onCancel: () => void,
  onValidation: () => void,
  biometry: Biometry,
  os: OS,
};

function BiometryCredentialsDeletionModal(props: Props) {
  const { intl, isShow, onCancel, onValidation, biometry, os } = props;

  return (
    isShow && (
      <Dialog
        titleId="biometryCredentialsDeletion"
        onDialogClose={onCancel}
        className="biometry-deletion-modal"
      >
        <Dialog.Body>
          <div className="dsc-confirmation" role="alert" aria-live="assertive">
            <div className="dsc-confirmation__titleContainer">
              <Icon
                color="primary"
                className="biometry-deletion-modal__icon dsc-confirmation__picto"
                name={getIcon(biometry, os)}
                title={getIcon(biometry, os)}
                size={{ width: 64, height: 64 }}
              />
              <Heading size={4} type="h2" className="dsc-confirmation__title">
                {intl.formatMessage({
                  id: 'mobile.biometric.login.deleteCredentialsModal.body',
                })}
              </Heading>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.CloseBtn appearance="secondary" onClick={onCancel}>
            {intl.formatMessage({
              id: 'mobile.biometric.login.deleteCredentialsModal.cancel',
            })}
          </Dialog.CloseBtn>
          <Button appearance="primary" onClick={onValidation}>
            {intl.formatMessage({
              id: 'mobile.biometric.login.deleteCredentialsModal.confirm',
            })}
          </Button>
        </Dialog.Footer>
      </Dialog>
    )
  );
}

export default BiometryCredentialsDeletionModal;
