// @flow
import React from 'react';

import FormHandler from '../../../molecules/forms/FormHandler';
import LoginForm from '../../../molecules/obe/forms/LoginForm';

import { FORM_TYPES } from '../../../utils/constants/forms';

type Props = {
  className?: string,
};

const defaultProps = {
  className: '',
};

function Login(props: Props) {
  const { className } = props;

  return (
    <div id="loginForm" className="connect-form">
      <FormHandler
        // $FlowFixMe
        formName={FORM_TYPES.LOGIN_FORM}
      >
        <LoginForm className={className} />
      </FormHandler>
    </div>
  );
}

Login.defaultProps = defaultProps;

export default Login;
