// @flow
import React from 'react';
import { toggleFeatureApi } from 'bnc-react-components';

import type { IntlShape } from 'react-intl';
import type {
  LoginFormData,
  LoginFormErrors,
} from '../../../types/forms/loginForm';
import type { SDKError } from '../../../types';

import LoginWithoutIdPartialContentForm from './LoginWithoutIdPartialContent';

import type { Config } from '../../../types/configs';
import { CONFIGS_NAMES } from '../../../utils/constants';
import './style.scss';

type Props = {
  intl: IntlShape,
  formData: LoginFormData,
  formUpdate: (fieldName: string, value: string | boolean) => void,
  changeStepRequest: (nextStep: string, hasToFocus: ?boolean) => void,
  clearLoginErrorMessages: () => void,
  isFetching: boolean,
  errors: LoginFormErrors,
  triggerSubmit: () => void,
  triggerValidation: (fieldName: string) => void,
  setFormInputs: (inputs: any) => void,
  onKeyPress: (event: Event, func: Function) => void,
  hasForgotPwd: boolean,
  loginRouteForgotPassword: () => void,
  forgotPasswordUnavailable: () => void,
  hasMaxIdentities: boolean,
  hasIdentities: boolean,
  hasLoginFailure: boolean,
  sdkError: SDKError,
  hasValidationError: boolean,
  isFirstRender: ?boolean,
  hasToFocus: boolean,
  announceAlertMessage: (message: string) => void,
  announceStatusMessage: (message: string) => void,
  partnerName: string,
  configs: Config,
};

const LoginForm = (props: Props) => {
  const {
    intl,
    formData,
    formUpdate,
    changeStepRequest,
    clearLoginErrorMessages,
    isFetching,
    errors,
    triggerSubmit,
    triggerValidation,
    setFormInputs,
    onKeyPress,
    hasForgotPwd,
    loginRouteForgotPassword,
    forgotPasswordUnavailable,
    hasMaxIdentities,
    hasIdentities,
    hasLoginFailure,
    sdkError,
    hasValidationError,
    isFirstRender,
    hasToFocus,
    announceAlertMessage,
    announceStatusMessage,
    partnerName,
    configs,
  } = props;

  const loginFormInputs = {};

  const onChange = (event: SyntheticInputEvent<any>) => {
    const fieldName = event.target.name;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    formUpdate(fieldName, value);
  };

  const onBlur = (event: SyntheticInputEvent<any>) => {
    const fieldName = event.target.name;
    triggerValidation(fieldName);
  };

  const onClickForgetLink = (event: SyntheticInputEvent<any>) => {
    loginRouteForgotPassword();
    event.preventDefault();
  };

  const onClickForgotUnavailableLink = (event: SyntheticInputEvent<any>) => {
    forgotPasswordUnavailable();
    event.preventDefault();
  };

  const isForgotPasswordAvailable = toggleFeatureApi.isAvailable(
    configs,
    CONFIGS_NAMES.FORGOT_PASSWORD.GLOBAL,
    [],
  );

  const onSubmit = () => {
    setFormInputs(loginFormInputs);
    triggerSubmit();
  };

  const handleKeyPress = event => {
    if (!isFetching) {
      onKeyPress(event, onSubmit);
    }
  };

  return (
    <LoginWithoutIdPartialContentForm
      intl={intl}
      formData={formData}
      onChange={onChange}
      formUpdate={formUpdate}
      onClickForgetLink={
        isForgotPasswordAvailable
          ? onClickForgetLink
          : onClickForgotUnavailableLink
      }
      hasForgotPwd={hasForgotPwd}
      isFetching={isFetching}
      changeStepRequest={changeStepRequest}
      clearLoginErrorMessages={clearLoginErrorMessages}
      hasIdentitiesSaved={hasIdentities}
      errors={errors}
      onBlur={onBlur}
      triggerSubmit={triggerSubmit}
      setFormInputs={setFormInputs}
      handleKeyPress={handleKeyPress}
      hasLoginFailure={hasLoginFailure}
      sdkError={sdkError}
      hasValidationError={hasValidationError}
      isFirstRender={isFirstRender}
      hasToFocus={hasToFocus}
      announceAlertMessage={announceAlertMessage}
      announceStatusMessage={announceStatusMessage}
      partnerName={partnerName}
      isMaximumReached={hasMaxIdentities}
    />
  );
};

export default LoginForm;
