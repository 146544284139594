// @flow
import actionTypes from './actionTypes';

import type { Action } from './types';

export type TemplateState = {|
  +templateName: string,
  +partnerId: string,
|};

const defaultState: TemplateState = {
  templateName: '',
  partnerId: '',
};

export const initialState = () => defaultState;

export default (
  state: TemplateState = initialState(),
  action: Action,
): TemplateState => {
  switch (action.type) {
    case actionTypes.TEMPLATE_SET_CURRENT_TEMPLATE:
      return {
        ...state,
        templateName: action.templateName,
        partnerId: action.partnerId,
      };
    default:
      return state;
  }
};
