import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ForbiddenContactAlert from './forbiddenContactAlert';

import {
  getTemplateName,
  getPartnerIdName,
} from '../../../services/templateService/selectors';

function mapStateToProps(state) {
  return {
    templateName: getTemplateName(state),
    partnerId: getPartnerIdName(state),
  };
}

export default injectIntl(connect(mapStateToProps)(ForbiddenContactAlert));
