import nock from 'nock';
import { testSaga } from 'bnc-utilities-js/testUtils/saga';
import browserHistory from '../../../nav/BrowserHistoryManager/index';
import workers from '../workers';

import { ROUTES } from '../../../utils/constants';
import {
  TEMPLATE_SERVICE_REDUCER,
  I18N_SERVICE_REDUCER,
} from '../../../globalRedux/reducers/constants';
import { MODAL_TYPES } from '../../../utils/constants/modal';
import { SHOW_MODAL } from '../../modalService/actionTypes';
import { LOGIN_SET_RETURN_SBIP_LOGIN_FLAG } from '../../loginService/actionTypes';
import { isRedirected, getParam } from '../helper';

jest.mock('react-router');
browserHistory.push = jest.fn();

jest.mock('../../../utils/urlUtils', () => ({
  parseParams: jest.fn(),
}));

jest.mock('../helper', () => ({
  isRedirected: jest.fn(),
  getParam: jest.fn(),
  shouldRetry: jest.fn(),
}));

jest.mock('../../../configs', () => {
  const configsConstants = require.requireActual('../../../configs');
  return {
    ...configsConstants,
    Configs: {
      ...configsConstants.Configs,
      AUTH: {
        ...configsConstants.Configs.AUTH,
        SAML_EXPIRATION: 2,
      },
    },
  };
});

jest.mock('../../redirectService/actions', () => ({
  redirectToPage: jest.fn(),
}));

afterEach(() => {
  nock.cleanAll();
  jest.clearAllMocks();
});

const validateSamlMock = (response, responseCode, SAML) => {
  // Nock doesn't work with absolute url.. :(
  nock('http://localhost:9000/validate')
    .post('', { SAMLRequest: 'SAML' })
    .reply(responseCode, response);
};

const SAMLValidResponse = { isSAMLValid: true };
const SAMLInvalidResponse = { isSAMLValid: false };

describe('.watchInitParamsUnavailable', () => {
  test('watchInitParamsUnavailable - is redirected to /badRequest', async () => {
    isRedirected.mockReturnValueOnce(true);
    const { sagaDone } = testSaga({
      saga: workers.watchInitParamsUnavailable,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
        [I18N_SERVICE_REDUCER]: {
          locale: 'fr',
        },
      },
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(browserHistory.push).toBeCalledWith(ROUTES.PARAMS_UNAVAILABLE);
  });

  test('watchInitParamsUnavailable isIssuerInstantValid TRUE - is not redirected', async () => {
    isRedirected.mockReturnValueOnce(false);
    getParam.mockReturnValueOnce('SAML');
    validateSamlMock(SAMLValidResponse, 200, 'SAML');
    const { sagaDone } = testSaga({
      saga: workers.watchInitParamsUnavailable,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
        [I18N_SERVICE_REDUCER]: {
          locale: 'fr',
        },
      },
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      { type: LOGIN_SET_RETURN_SBIP_LOGIN_FLAG },
      { modalType: MODAL_TYPES.SESSION_EXPIRED_MODAL, type: SHOW_MODAL },
    ]);
    expect(browserHistory.push).not.toBeCalled();
  });

  test('watchInitParamsUnavailable isIssuerInstantValid FALSE - is redirected to /badRequest', async () => {
    isRedirected.mockReturnValueOnce(false);
    getParam.mockReturnValueOnce('SAML');
    validateSamlMock(SAMLInvalidResponse, 200, 'SAML');
    const { sagaDone } = testSaga({
      saga: workers.watchInitParamsUnavailable,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
        [I18N_SERVICE_REDUCER]: {
          locale: 'fr',
        },
      },
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(browserHistory.push).toBeCalledWith(ROUTES.PARAMS_UNAVAILABLE);
  });

  test('watchInitParamsUnavailable isIssuerInstantValid FALSE - is redirected to /badRequest', async () => {
    isRedirected.mockReturnValueOnce(false);
    getParam.mockReturnValueOnce('SAML');
    validateSamlMock(SAMLInvalidResponse, 500, 'SAML');
    const { sagaDone } = testSaga({
      saga: workers.watchInitParamsUnavailable,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
        [I18N_SERVICE_REDUCER]: {
          locale: 'fr',
        },
      },
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        error: {
          code: undefined,
          message: undefined,
          status: 500,
        },
        type: 'BNC_GLOBAL_ERROR_CAUGHT',
      },
    ]);
    expect(browserHistory.push).toBeCalledWith(ROUTES.PARAMS_UNAVAILABLE);
  });
});
