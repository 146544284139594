import React from 'react';
import cx from 'classnames';
import type { Node } from 'react';

import './style.scss';

type Props = {
  className?: string,
  main: Node,
};

const defaultProps = {
  className: '',
};

function FullScreenCenterTemplate(props: Props) {
  const { className, main } = props;

  return (
    <div className={cx('template-center', className)}>
      <div className="template-center__content template__content--center">
        {main && <main className="main">{main}</main>}
      </div>
    </div>
  );
}

FullScreenCenterTemplate.defaultProps = defaultProps;

export default FullScreenCenterTemplate;
