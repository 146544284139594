import * as cookie from '../cookie';
import { Configs } from '../../configs';

export const SBIE_ENVIRONMENTS = {
  'ti-a': 'A',
  'ti-b': 'B',
  'ti-c': 'C',
  'ti-d': 'D',

  'ta-a': 'A',
  'ta-b': 'B',
  'ta-c': 'C',
  'ta-d': 'D',

  'pp-a': 'A',
  'pp-b': 'B',
};
// eslint-disable-next-line import/prefer-default-export
export const getForgotPasswordUrl = () => {
  const currentSbieEnv = cookie.get('sbieEnv');
  return currentSbieEnv !== '' && currentSbieEnv !== undefined
    ? Configs.BNE.EXTERNAL_URLS.FORGOT_PASSWORD_URL.URL[
        SBIE_ENVIRONMENTS[currentSbieEnv]
      ]
    : Configs.BNE.EXTERNAL_URLS.FORGOT_PASSWORD_DEFAULT_URL;
};
