import React from 'react';
import get from 'lodash/get';
import { Tagging, TrackingTimer, TrackState } from '@nbc-studio/analytics';
import PropTypes from 'prop-types';
import {
  EVENT_IDS,
  EVENT_NAMES,
  EVENT_SOURCE,
  LOB,
  UCS_IDS,
  UCS_STATE,
  PAGES,
  FACTORS_TYPES,
  MFA_FLOW_IDS,
  FORM_STEPS_DEFINITION,
} from '../../../../utils/taggingUtils/constants';

const propTypes = {
  partnerIdName: PropTypes.string,
  sdkError: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  factorType: PropTypes.string,
  isForgotPasswordFlow: PropTypes.bool,
  forgotPasswordFlowId: PropTypes.string,
};

const defaultProps = {
  partnerIdName: '',
  sdkError: {},
  factorType: null,
  isForgotPasswordFlow: false,
  forgotPasswordFlowId: '',
};

const MultiFactorAuthTaggingWrapper = ({
  children,
  partnerIdName,
  sdkError,
  isLoading,
  locale,
  step,
  factorType,
  isForgotPasswordFlow,
  forgotPasswordFlowId,
}) => {
  const errorCode = get(sdkError, 'code', '0000');
  const errorMsg = get(sdkError, 'message', 'Unexpected Error');
  const isTechnicalError = !!sdkError.code;
  const eventSource = partnerIdName || EVENT_SOURCE.SBIP2;
  const taggingId = isForgotPasswordFlow ? PAGES.FORGOT_PASSWORD : PAGES.LOGIN;

  const getFlow = factorSelected => {
    switch (factorSelected) {
      case FACTORS_TYPES.EMAIL:
        return MFA_FLOW_IDS.EMAIL;
      case FACTORS_TYPES.SMS:
        return MFA_FLOW_IDS.SMS;
      case FACTORS_TYPES.CALL:
        return MFA_FLOW_IDS.CALL;
      default:
        return '';
    }
  };

  const flowSelection = isForgotPasswordFlow ? getFlow(factorType) : '';
  const flowId = isForgotPasswordFlow
    ? forgotPasswordFlowId
    : getFlow(factorType);

  const pageName = get(
    FORM_STEPS_DEFINITION,
    `${taggingId}.${step}.pageName`,
    '',
  );

  const stepName = get(
    FORM_STEPS_DEFINITION,
    `${taggingId}.${step}.stepName`,
    '',
  );

  const stepId = get(FORM_STEPS_DEFINITION, `${taggingId}.${step}.stepId`, '');

  const eventId = get(
    FORM_STEPS_DEFINITION,
    `${taggingId}.${step}.eventId`,
    '',
  );

  const ucsId = get(FORM_STEPS_DEFINITION, `${taggingId}.${step}.ucsId`, '');

  const ucsState = get(
    FORM_STEPS_DEFINITION,
    `${taggingId}.${step}.ucsState`,
    '',
  );

  const eventName = get(
    FORM_STEPS_DEFINITION,
    `${taggingId}.${step}.eventName`,
    '',
  );

  const formIdName = get(
    FORM_STEPS_DEFINITION,
    `${taggingId}.${step}.formId`,
    '',
  );

  const eventSourceDetail = get(
    FORM_STEPS_DEFINITION,
    `${taggingId}.${step}.eventSourceDetail`,
    '',
  );

  return isTechnicalError ? (
    <Tagging
      ucsId={UCS_IDS.TECHNICAL_ERROR}
      ucsState={UCS_STATE.PV}
      eventName={EVENT_NAMES.PAGE_ERROR}
      eventId={EVENT_IDS.PE}
      eventSource={partnerIdName || EVENT_SOURCE.SBIP2}
      technicalError={`${errorMsg} (CODE:${errorCode})`}
    >
      {children}
      <TrackState />
    </Tagging>
  ) : (
    <Tagging
      ucsId={ucsId}
      ucsState={ucsState}
      eventName={eventName}
      eventId={eventId}
      eventSourceDetail={eventSourceDetail}
      lob={LOB.PERSONAL}
      pageName={`${eventSource}:${pageName}`}
      eventSource={eventSource}
      language={locale}
      stepName={stepName}
      formId={formIdName}
      stepId={stepId}
      flowId={flowId}
      flowDetails={flowSelection}
    >
      {children}
      <TrackingTimer finished={!isLoading} timeout={5000}>
        <TrackState />
      </TrackingTimer>
    </Tagging>
  );
};
MultiFactorAuthTaggingWrapper.propTypes = propTypes;
MultiFactorAuthTaggingWrapper.defaultProps = defaultProps;

export default MultiFactorAuthTaggingWrapper;
