// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import MultiFactorAuthChoice from './multiFactorAuthChoice';

import { getTemplateName } from '../../services/templateService/selectors';

const mapStateToProps = state => ({
  templateName: getTemplateName(state),
});

export default injectIntl(connect(mapStateToProps)(MultiFactorAuthChoice));
