import React from 'react';

const RSALogo = props => (
  <svg width="180" height="91" viewBox="0 0 180 91">
    <defs>
      <path
        id="vpnk75xa9a"
        d="M21.697 49.011c-.497.38-.746.885-.746 1.514 0 .47.152.88.455 1.233.303.351.822.72 1.558 1.103l.548.284c.444.233.745.435.904.605.159.17.239.378.239.625 0 .312-.127.56-.38.741-.253.182-.6.273-1.04.273-.628 0-1.384-.171-2.267-.514v1.307c.872.172 1.637.258 2.295.258.994 0 1.774-.201 2.34-.603.565-.401.848-.956.848-1.663 0-.497-.15-.92-.45-1.266-.299-.347-.844-.726-1.635-1.138l-.587-.303c-.44-.23-.74-.425-.899-.582-.158-.158-.238-.342-.238-.55 0-.255.114-.46.341-.616.227-.156.53-.234.906-.234.488 0 1.152.145 1.99.437v-1.22c-.82-.175-1.557-.262-2.211-.262-.817 0-1.474.19-1.971.571zm33.754-.071v7.5h3.396c1.296 0 2.304-.343 3.024-1.027.72-.684 1.08-1.644 1.08-2.88 0-1.163-.34-2.052-1.019-2.669-.68-.616-1.657-.924-2.934-.924H55.45zm1.5.5h1.045c.667 0 1.174.069 1.523.208.348.138.646.39.893.753.36.529.539 1.19.539 1.985 0 1.027-.224 1.793-.671 2.297-.448.505-1.127.757-2.037.757h-1.292v-6zm-4.5 7h1.5v-7.5h-1.5v7.5zm-3.31-4.761v-1.106h-1.69v5.867h1.69v-3.725c.421-.637.969-.956 1.642-.956.206 0 .428.041.668.123v-1.41c-.103-.022-.207-.032-.314-.032-.894 0-1.56.413-1.997 1.239zm-5.34-1.24v3.692c-.482.71-.984 1.063-1.504 1.063-.464 0-.697-.333-.697-.999V50.44h-1.65v4.146c0 .573.159 1.025.475 1.357.316.33.746.497 1.292.497.855 0 1.55-.414 2.084-1.24v1.106h1.65V50.44H43.8zm-9.486.795c-.576.53-.864 1.263-.864 2.2 0 .927.28 1.66.839 2.198.559.538 1.32.808 2.285.808.576 0 1.201-.086 1.876-.257v-1.071c-.517.202-1.02.303-1.507.303-.536 0-.964-.181-1.285-.543-.32-.363-.48-.846-.48-1.448 0-.589.148-1.061.445-1.417.297-.355.69-.533 1.177-.533.4 0 .933.103 1.6.309v-1.119c-.641-.15-1.208-.224-1.7-.224-1.015 0-1.81.264-2.386.794zm-6.058.018c-.537.542-.806 1.257-.806 2.145 0 .941.293 1.684.88 2.227.588.544 1.39.816 2.404.816.699 0 1.437-.109 2.216-.325v-1.087c-.69.24-1.31.36-1.861.36-1.163 0-1.8-.546-1.911-1.64h3.772c0-1.146-.205-1.984-.615-2.514-.41-.53-1.06-.794-1.95-.794-.882 0-1.592.27-2.129.812zm2.275-.312c.613 0 .919.5.919 1.5h-2c.098-1 .458-1.5 1.081-1.5z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-642 -477) translate(626 377.573) translate(16 99.427) translate(6.55 3.56)">
              <path
                fill="#C7D0D5"
                d="M0 42.163c0 23.286 18.877 42.162 42.163 42.162 11.488 0 21.904-4.594 29.508-12.046h88.489c1.734 0 3.237-1.203 3.617-2.896 2.015-8.996 3.023-17.993 3.023-26.988 0-8.996-1.008-17.993-3.023-26.989-.38-1.693-1.883-2.896-3.617-2.896H72.137C64.494 4.786 53.887 0 42.163 0 18.877 0 0 18.877 0 42.163z"
              />
              <path
                fill="#25498F"
                d="M12.595 42.625c0 16.386 13.283 29.668 29.668 29.668 16.385 0 29.667-13.282 29.667-29.668 0-16.384-13.282-29.667-29.667-29.667S12.595 26.241 12.595 42.625z"
              />
              <path
                fill="#E41C23"
                d="M23.951 26.44L23.951 45.44 52.03 45.44 54.324 41.732 56.618 45.44 60.451 45.44 60.451 26.44z"
              />
              <path
                fill="#FFF"
                d="M40.639 31.31c-.604.51-.906 1.187-.906 2.029 0 .63.184 1.182.552 1.654.368.471.999.965 1.892 1.48l.667.38c.539.314.904.584 1.098.812.193.228.29.507.29.839 0 .418-.155.75-.462.994-.308.244-.728.366-1.263.366-.763 0-1.681-.23-2.754-.69v1.753c1.06.23 1.989.345 2.788.345 1.207 0 2.155-.269 2.842-.808.686-.538 1.03-1.282 1.03-2.23 0-.667-.182-1.233-.545-1.698-.364-.465-1.026-.974-1.987-1.526l-.714-.407c-.534-.309-.898-.569-1.091-.78-.193-.212-.29-.458-.29-.74 0-.34.139-.615.415-.824.276-.21.643-.315 1.1-.315.594 0 1.4.196 2.418.587v-1.636c-.996-.235-1.892-.352-2.687-.352-.992 0-1.79.255-2.393.766zm12.178-.512L48.94 41.017h1.508l1.037-2.728 2.113-5.419 2.008 5.42 1.038 2.727h2.128l-3.88-10.219h-2.074zm-23.86 0v10.219h1.954V32.2h.72c1.343 0 2.014.455 2.014 1.367 0 1.247-.752 1.871-2.256 1.871h-.47l1.137 1.402 2.47 4.177h2.526l-3.26-4.771c.602-.276 1.075-.673 1.418-1.191.344-.518.516-1.092.516-1.723 0-1.69-1.013-2.534-3.037-2.534h-3.731z"
              />
              <g>
                <path
                  fill="#FFF"
                  d="M3.896.567h69.748c.825 0 1.551.544 1.783 1.336.96 3.273 1.44 6.547 1.44 9.82 0 3.274-.48 6.547-1.44 9.82-.232.792-.958 1.336-1.783 1.336H3.896c-.825 0-1.55-.544-1.783-1.335-.96-3.274-1.44-6.547-1.44-9.82 0-3.274.48-6.548 1.44-9.821C2.345 1.11 3.07.567 3.896.567"
                  transform="translate(79.95 30.44)"
                />
                <path
                  fill="#00314D"
                  fillRule="nonzero"
                  d="M3.81 1.32c-.456.036-.847.35-.978.794-.94 3.206-1.41 6.408-1.41 9.61 0 3.2.47 6.403 1.41 9.608.13.445.522.759.978.794l.086.003h69.748c.492 0 .925-.324 1.064-.797.94-3.205 1.41-6.408 1.41-9.609 0-3.2-.47-6.403-1.41-9.609-.13-.445-.522-.758-.978-.794l-.086-.003H3.896l-.086.003zM73.644-.183c1.158 0 2.177.763 2.503 1.875.98 3.341 1.47 6.686 1.47 10.031 0 3.346-.49 6.69-1.47 10.032-.315 1.074-1.278 1.823-2.388 1.872l-.115.002H3.896c-1.158 0-2.177-.763-2.503-1.874-.98-3.342-1.47-6.686-1.47-10.032 0-3.345.49-6.69 1.47-10.031C1.708.617 2.671-.132 3.781-.181l.115-.002h69.748z"
                  transform="translate(79.95 30.44)"
                />
                <path
                  fill="#1572C5"
                  fillRule="nonzero"
                  d="M12.16 15.5l1.857-1.159-1.502-1.93 2.485.192v-2.206l-2.485.22 1.502-1.958L12.16 7.5l-1.174 2.317L9.812 7.5l-1.83 1.159 1.475 1.958L7 10.397v2.206l2.457-.193-1.474 1.931 1.83 1.159 1.173-2.317L12.16 15.5zm11 0l1.857-1.159-1.502-1.93 2.485.192v-2.206l-2.485.22 1.502-1.958L23.16 7.5l-1.174 2.317L20.812 7.5l-1.83 1.159 1.475 1.958-2.457-.22v2.206l2.457-.193-1.474 1.931 1.83 1.159 1.173-2.317L23.16 15.5zm11 0l1.857-1.159-1.502-1.93 2.485.192v-2.206l-2.485.22 1.502-1.958L34.16 7.5l-1.174 2.317L31.812 7.5l-1.83 1.159 1.475 1.958-2.457-.22v2.206l2.457-.193-1.474 1.931 1.83 1.159 1.173-2.317L34.16 15.5zm11 0l1.857-1.159-1.502-1.93 2.485.192v-2.206l-2.485.22 1.502-1.958L45.16 7.5l-1.174 2.317L42.812 7.5l-1.83 1.159 1.475 1.958-2.457-.22v2.206l2.457-.193-1.474 1.931 1.83 1.159 1.173-2.317L45.16 15.5zm11 0l1.857-1.159-1.502-1.93 2.485.192v-2.206l-2.485.22 1.502-1.958L56.16 7.5l-1.174 2.317L53.812 7.5l-1.83 1.159 1.475 1.958-2.457-.22v2.206l2.457-.193-1.474 1.931 1.83 1.159 1.173-2.317L56.16 15.5zm11 0l1.857-1.159-1.502-1.93 2.485.192v-2.206l-2.485.22 1.502-1.958L67.16 7.5l-1.174 2.317L64.812 7.5l-1.83 1.159 1.475 1.958-2.457-.22v2.206l2.457-.193-1.474 1.931 1.83 1.159 1.173-2.317L67.16 15.5z"
                  transform="translate(79.95 30.44)"
                />
                <path
                  fill="#00314D"
                  fillRule="nonzero"
                  d="M73.644.067H3.896c-1.047 0-1.968.69-2.263 1.695C.66 5.081.173 8.402.173 11.723c0 3.322.487 6.643 1.46 9.961.295 1.005 1.216 1.695 2.263 1.695h69.748c1.047 0 1.968-.69 2.263-1.695.973-3.318 1.46-6.64 1.46-9.96 0-3.322-.487-6.643-1.46-9.962C75.612.757 74.691.067 73.644.067zm-69.748 1h69.748c.603 0 1.134.398 1.304.977.946 3.228 1.42 6.454 1.42 9.68 0 3.224-.474 6.45-1.42 9.679-.17.579-.701.976-1.304.976H3.896c-.603 0-1.134-.397-1.303-.976-.947-3.228-1.42-6.455-1.42-9.68 0-3.225.473-6.451 1.42-9.68.17-.578.7-.976 1.303-.976z"
                  transform="translate(79.95 30.44)"
                />
              </g>
              <path
                fill="#25498F"
                d="M105.308 57.44c-1.026 0-1.857.805-1.857 1.8v5.4c0 .994.831 1.8 1.857 1.8h48.286c1.025 0 1.857-.806 1.857-1.8v-5.4c0-.995-.832-1.8-1.857-1.8h-48.286z"
              />
              <path
                fill="#E41C23"
                d="M137.951 64.94L151.951 64.94 151.951 58.94 137.951 58.94z"
              />
              <use fill="#FFF" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default RSALogo;
