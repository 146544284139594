import React from 'react';
import { Heading } from '@bnc-ui/heading';
import { Text } from '@bnc-ui/text';

type Props = {
  title: string,
  text: Node | string,
};

function TitleSection(props: Props) {
  const { title, text } = props;
  return (
    <div className="title-section">
      <Heading type="h2">{title}</Heading>
      <Text>{text}</Text>
    </div>
  );
}

export default TitleSection;
