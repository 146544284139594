// @flow
import { BNC_AUTH_CHANGE_PASSWORD_COMPLETED } from 'bnc-react-services/services/AuthService/actionTypes';
import * as actionTypes from './actionTypes';

import type { ForgotPasswordState, Action } from './types';
import { FORGOT_PASSWORD_STEPS } from '../../utils/forgotPasswordUtils/constants';
import { FORGOT_PASSWORD_FORM_INIT_DONE } from '../forgotPasswordFormService/actionTypes';

export const initialState = (): ForgotPasswordState => ({
  isFetching: false,
  failure: false,
  sentAt: '',
  profileType: '',
  recoveryToken: '',
  exceptionType: '',
  identificationError: '',
  factors: [],
  backendError: {},
  currentStep: FORGOT_PASSWORD_STEPS.IDENTIFICATION,
  loginStarted: new Date(),
  loginEnded: null,
  analytics: {},
  showOTCSuccessMessage: false,
  numberOfRetry: 0,
  tokenRecaptcha: {},
  isForgotPasswordFlow: false,
});

export default (
  state: ForgotPasswordState = initialState(),
  action: Action,
) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_FLOW_RESET:
      return initialState();

    case actionTypes.FORGOT_PASSWORD_VALIDATE_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
      };

    case actionTypes.FORGOT_PASSWORD_VALIDATE_USER_SUCCESS_MFA_CHOICE:
      return {
        ...state,
        factors: action.factors,
        isFetching: false,
        failure: false,
        backendError: {},
        numberOfRetry: 0,
        currentStep: FORGOT_PASSWORD_STEPS.MFA_CHOICE,
      };

    case actionTypes.FORGOT_PASSWORD_VALIDATE_USER_SUCCESS:
      return {
        ...state,
        factors: action.factors,
        isFetching: false,
        failure: false,
        backendError: {},
        numberOfRetry: 0,
        currentStep: FORGOT_PASSWORD_STEPS.CODE_CONFIRMATION,
      };

    case actionTypes.FORGOT_PASSWORD_VALIDATE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        backendError: action.error,
        numberOfRetry: state.numberOfRetry + 1,
      };

    case actionTypes.FORGOT_PASSWORD_SET_CONTEXT:
      return {
        ...state,
        profileType: action.profileType,
        recoveryToken: action.recoveryToken,
      };

    case actionTypes.FORGOT_PASSWORD_TRIGGER_EXCEPTION:
      return {
        ...state,
        exceptionType: action.exceptionType,
      };

    case actionTypes.FORGOT_PASSWORD_SET_BACKEND_ERROR:
      return {
        ...state,
        backendError: action.error,
      };

    case actionTypes.FORGOT_PASSWORD_RESET:
      return {
        ...state,
        isFetching: false,
        failure: false,
        identificationError: '',
        backendError: {},
        numberOfRetry: 0,
      };

    case FORGOT_PASSWORD_FORM_INIT_DONE:
      return {
        ...state,
        isForgotPasswordFlow: true,
        loginEnded: new Date(),
      };

    case BNC_AUTH_CHANGE_PASSWORD_COMPLETED:
      return {
        ...state,
        loginEnded: new Date(),
      };

    case actionTypes.FORGOT_PASSWORD_SET_ANALYTICS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...action.analytics,
        },
      };

    case actionTypes.SAVE_RECAPTCHA_TOKEN:
      return {
        ...state,
        tokenRecaptcha: action.tokenRecaptcha,
      };
    case actionTypes.FORGOT_PASSWORD_SET_PASSWORD:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.FORGOT_PASSWORD_RESEND_OTC:
    case actionTypes.FORGOT_PASSWORD_VERIFY_OTC:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.FORGOT_PASSWORD_VERIFY_OTC_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentStep: FORGOT_PASSWORD_STEPS.PASSWORD_RESET,
      };

    case actionTypes.FORGOT_PASSWORD_VERIFY_OTC_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.FORGOT_PASSWORD_SHOW_RESEND_OTC_SUCCESS_MESSAGE:
      return {
        ...state,
        isFetching: false,
        showOTCSuccessMessage: action.value,
      };
    case actionTypes.FORGOT_PASSWORD_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentStep: FORGOT_PASSWORD_STEPS.FINAL_CONFIRMATION,
      };
    case actionTypes.FORGOT_PASSWORD_RESEND_OTC_FAILURE:
    case actionTypes.FORGOT_PASSWORD_SET_PASSWORD_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
