import React from 'react';
import { Tagging, TrackAction } from '@nbc-studio/analytics';
import PropTypes from 'prop-types';
import {
  EVENT_IDS,
  EVENT_NAMES,
  EVENT_SOURCE,
  UCS_IDS,
  UCS_STATE,
} from '../../../../utils/taggingUtils/constants';

const propTypes = {
  partnerIdName: PropTypes.string,
  helpText: PropTypes.string.isRequired,
  counting: PropTypes.number.isRequired,
};

const defaultProps = {
  partnerIdName: '',
};

const LoginTaggingWrapper = ({
  children,
  partnerIdName,
  helpText,
  counting,
}) => (
  <Tagging
    ucsId={UCS_IDS.LOGIN_HD}
    ucsState={UCS_STATE.NON_PV}
    eventName={EVENT_NAMES.HELP_DISPLAY}
    eventId={EVENT_IDS.HD}
    helpText={helpText}
    eventSource={partnerIdName || EVENT_SOURCE.SBIP2}
  >
    {children}
    {counting ? <TrackAction key={counting} /> : null}
  </Tagging>
);

LoginTaggingWrapper.propTypes = propTypes;
LoginTaggingWrapper.defaultProps = defaultProps;

export default LoginTaggingWrapper;
