import { connect } from 'react-redux';

import FormHandler from './formHandler';

import type { State } from '../../../types/state';
import type { Dispatch } from '../../../types/store';

import {
  formInitRequest,
  resetForm,
  setFormInputsState,
} from '../../../services/formHandlerService/actions';

import { FORM_HANDLER_SERVICE_REDUCER } from '../../../globalRedux/reducers/constants';

function mapStateToProps(state: State) {
  return {
    formsStatus: state[FORM_HANDLER_SERVICE_REDUCER].formsStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    setFormInputsState: (formName: string, inputs: any) =>
      dispatch(setFormInputsState(formName, inputs)),
    resetForm: (formId: string, formName: string) =>
      dispatch(resetForm(formId, formName)),
    formInitRequest: (formId: string, formName: string, initArgs: any) =>
      dispatch(formInitRequest(formId, formName, initArgs)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormHandler);
