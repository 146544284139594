// @flow
import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import getGreetingsStringId from '../../../utils/momentUtils';
import { getMessageKey } from '../../../utils/templateUtils';

import './style.scss';

type Props = {
  className?: string,
  isVisible?: boolean,
  tagName: string,
  userName?: string,
  templateName: string,
};

const defaultProps = {
  className: '',
  isVisible: true,
  userName: '',
};

function Greeting(props: Props) {
  const { className, isVisible, tagName, userName, templateName } = props;

  if (!isVisible) {
    return null;
  }

  const CustomTagName = tagName;

  const greetingStringKey = getMessageKey(templateName, getGreetingsStringId());

  return (
    <FormattedMessage id={greetingStringKey}>
      {(txt: string) => (
        <CustomTagName className={cx('greeting', className)}>
          {/* $FlowFixMe */}
          {`${txt} ${userName}`}
        </CustomTagName>
      )}
    </FormattedMessage>
  );
}

Greeting.defaultProps = defaultProps;

export default Greeting;
