import React, { useEffect } from 'react';
import type { IntlShape } from 'react-intl';
import { Error } from '@bnc-layouts/error';
import WhiteLabelBrand from '../../molecules/whiteLabel/WhiteLabelBrand';
import { focusOnComponent } from '../../utils/domUtils';
import { queueAnnouncer } from '../../utils/liveAnnouncer/helper';
import './style.scss';

type Props = {
  intl: IntlShape,
  returnToLogin: () => void,
  partnerId: string,
  announceMessage: (msg: string) => void,
};

const ForgotPasswordUnavailable = (props: Props) => {
  const { intl, returnToLogin, partnerId, announceMessage } = props;

  const { locale } = intl;

  useEffect(() => {
    focusOnComponent('#back-to-login');
    const messages = [
      intl.formatMessage({ id: 'text.title.forgotPasswordUnavailable' }),
      intl.formatMessage({ id: 'text.message1.forgotPasswordUnavailable' }),
    ];
    queueAnnouncer(messages, announceMessage);
  });

  return (
    <div className="forgot-password-unavailable">
      <Error
        title={intl.formatMessage({
          id: 'text.title.forgotPasswordUnavailable',
        })}
        locale={locale}
        errorIcon="MaintenanceColor"
        subtitle={intl.formatMessage({
          id: 'text.message1.forgotPasswordUnavailable',
        })}
        customLogo={partnerId ? <WhiteLabelBrand /> : null}
        button={{
          text: intl.formatMessage({ id: 'button.backToLogin' }),
          buttonProps: {
            onClick: returnToLogin,
            id: 'back-to-login',
          },
        }}
      />
    </div>
  );
};

export default ForgotPasswordUnavailable;
