// @flow
import { LOCATION_CHANGE } from 'react-router-redux';
import { TRANSACTION_STATUS } from 'bnc-react-services/managers/AuthManager/constants';

import type { Action as ReduxAction } from './action';

import type { Route } from './constants';

export type LocationChangeAction = ReduxAction<typeof LOCATION_CHANGE> & {
  payload: {
    pathname: Route,
    query: {
      lang: string,
    },
  },
};

export type SDKError = {
  code: string,
  type: string,
  message: string,
};

export type TransactionStatus = $Values<typeof TRANSACTION_STATUS>;
