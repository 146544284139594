import PropTypes from 'prop-types';
import { FORGOT_PASSWORD_FIELDS } from './forgotPasswordUtils/constants';

// eslint-disable-next-line import/prefer-default-export
export const forgotPasswordFormDataShape = PropTypes.shape({
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL]: PropTypes.string,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PRODUCT_TYPE]: PropTypes.string,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE]: PropTypes.instanceOf(
    Date,
  ),
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.OPEN_CHOICES]: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA]: PropTypes.any,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER]: PropTypes.string,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE]: PropTypes.string,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.POSTAL_CODE]: PropTypes.string,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER]: PropTypes.string,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT]:
    PropTypes.string,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER]: PropTypes.string,
});
