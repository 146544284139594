import HTTP from '../../utils/constants/http';
import { bncFetch } from '../../utils/fetchUtils';

const validateSamlFetch = SAML => {
  let baseUrl = '';

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    baseUrl = 'http://localhost:9000';
  }

  const fetchQuery = bncFetch(`${baseUrl}/validate`, {
    method: HTTP.METHODS.POST,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      SAMLRequest: SAML,
    }),
  });
  const resultAsJson = fetchQuery
    .then(response => response.json())
    .catch(err => err);

  return Promise.all([fetchQuery, resultAsJson]).then(
    ([resultFetch, jsonResult]) => {
      if (!resultFetch.ok) {
        const fetchError = {
          status: resultFetch.status,
          code: jsonResult.code,
          message: jsonResult.message,
        };
        throw fetchError;
      }
      return jsonResult;
    },
  );
};

export default {
  validateSamlFetch,
};
