// @flow
import { put, select } from 'redux-saga/effects';
import UAParser from 'ua-parser-js';

import type { Saga } from 'redux-saga';

import * as actions from './actions';
import { initSupportedBrowser } from '../unsupportedBrowserService/actions';

import { TEMPLATE_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';

import {
  isBrowserSupported,
  hasUnsupportedBrowserFeature,
} from '../../utils/browserUtils';

function* watchInitUserAgentRequest(): Saga<void> {
  const userAgent = new UAParser();
  const browser = userAgent.getBrowser();
  const OS = userAgent.getOS();
  const { templateName } = (yield select())[TEMPLATE_SERVICE_REDUCER];

  yield put(actions.initUserAgentDone(browser, OS));

  if (hasUnsupportedBrowserFeature(templateName)) {
    yield put(initSupportedBrowser(isBrowserSupported(browser)));
  } else {
    // Force the browser to be supported
    yield put(initSupportedBrowser(true));
  }
}

export default {
  watchInitUserAgentRequest,
};
