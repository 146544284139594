// @flow
import React from 'react';

import { Button } from 'bnc-react-components';
import type { IntlShape } from 'react-intl';

import './style.scss';

type Props = {
  intl: IntlShape,
  redirectToPage: (pageUrl: string) => void,
  pageUrl: string,
};

function Redirect(props: Props) {
  const { intl, redirectToPage, pageUrl } = props;

  return (
    <Button
      primary
      onClick={() => redirectToPage(pageUrl)}
      className="button__redirection"
    >
      {intl.formatMessage({ id: 'global.button.backToLogin' })}
    </Button>
  );
}

export default Redirect;
