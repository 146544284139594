import React from 'react';
import { Tagging, TrackingTimer, TrackState } from '@nbc-studio/analytics';
import PropTypes from 'prop-types';
import {
  BNE_EVENT_IDS,
  BNE_EVENT_NAMES,
  BNE_EVENT_SOURCE,
  BNE_EVENT_SOURCE_DETAILS,
  BNE_FORM_IDS,
  BNE_LOB,
  BNE_UCS_IDS,
  BNE_UCS_STATE,
} from '../../../../utils/taggingUtils/bneTaggingConstants';
import { findScreenDimension } from '../../../../utils/taggingUtils/helper';

const propTypes = {
  partnerIdName: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  stepName: PropTypes.string,
  flowId: PropTypes.string,
  flowSelection: PropTypes.string,
  flowDetails: PropTypes.string,
};

const defaultProps = {
  partnerIdName: '',
  stepName: '',
  flowId: '',
  flowSelection: '',
  flowDetails: '',
};

const PageReadyTaggingWrapper = ({
  children,
  partnerIdName,
  isLoading,
  locale,
  stepName,
  flowId,
  flowSelection,
  flowDetails,
}) => {
  const eventSource = partnerIdName || BNE_EVENT_SOURCE.BNE;
  const pageN =
    stepName.length > 0
      ? `${eventSource}:${BNE_LOB.BUSINESS}:login:${stepName}`
      : `${eventSource}:${BNE_LOB.BUSINESS}:login`;

  const stepN = stepName.length > 0 ? stepName : null;
  const flId = flowId.length > 0 ? flowId : null;
  const flSec = flowSelection.length > 0 ? flowSelection : null;
  const flDet = flowDetails.length > 0 ? flowDetails : null;

  return (
    <Tagging
      ucsId={BNE_UCS_IDS.LOGIN_0002}
      ucsState={BNE_UCS_STATE.PV}
      eventName={BNE_EVENT_NAMES.PAGE_READY}
      eventId={BNE_EVENT_IDS.PR}
      lob={BNE_LOB.BUSINESS}
      pageName={pageN}
      eventSource={eventSource}
      language={locale}
      eventSourceDetail={BNE_EVENT_SOURCE_DETAILS.LOGIN}
      formId={BNE_FORM_IDS.LOGIN}
      flowId={flId}
      flowSelection={flSec}
      stepName={stepN}
      breakPoints={findScreenDimension()}
      flowDetails={flDet}
    >
      {children}
      <TrackingTimer finished={!isLoading} timeout={1000}>
        <TrackState />
      </TrackingTimer>
    </Tagging>
  );
};

PageReadyTaggingWrapper.propTypes = propTypes;
PageReadyTaggingWrapper.defaultProps = defaultProps;

export default PageReadyTaggingWrapper;
