import actionTypes from './actionTypes';

function initParamsUnavailable() {
  return {
    type: actionTypes.PARAMS_UNAVAILABLE_INIT,
  };
}

export default {
  initParamsUnavailable,
};
