import * as cookie from '../../utils/cookie';
import * as browser from '../../utils/browserUtils';
import { BIOMETRIC_COOKIE_NAME } from './constants';

class Biometry {
  deviceId;

  type;

  user;

  version;

  constructor(deviceID, biometricType, biometricEnabledUser, version) {
    this.deviceId = deviceID;
    this.type = biometricType;
    this.user = biometricEnabledUser;
    this.version = version;
  }

  validUser() {
    return this.user && this.user.trim() !== 'null';
  }

  static available() {
    return (
      browser.isWebView() &&
      !!cookie.get(BIOMETRIC_COOKIE_NAME) &&
      Biometry.parse().validUser()
    );
  }

  static parse() {
    const value = cookie.get(BIOMETRIC_COOKIE_NAME);
    if (!value) {
      return null;
    }

    const parsedCookie = value
      .split(';')
      .map(v => v.split('='))
      .reduce((obj, v) => {
        return { ...obj, [v[0].trim()]: v[1].trim() };
      }, {});

    return new Biometry(
      parsedCookie.deviceID,
      parsedCookie.biometricType,
      parsedCookie.biometricEnabledUser,
      parsedCookie.version,
    );
  }
}

export default Biometry;
