import { LOCATION_CHANGE } from 'react-router-redux';
import { sendPageLoadEvent } from 'bnc-utilities-js/dtmUtils/index';

import handlePageChange from '../handlers';
import {
  getTemplateName,
  getPartnerIdName,
} from '../../../services/templateService/selectors';
import { getDtmMappedValue } from '../../configUtils';

jest.mock('bnc-react-services/managers/SessionManager', () => ({
  getBncIdFromAccessToken: jest.fn().mockReturnValue('BNC_ID'),
}));

jest.mock('bnc-utilities-js/dtmUtils', () => ({
  sendPageLoadEvent: jest.fn(),
  sendFunctionality: jest.fn(),
}));

jest.mock('../../../services/templateService/selectors', () => ({
  getTemplateName: jest.fn().mockReturnValue(''),
  getPartnerIdName: jest.fn().mockReturnValue(''),
}));

jest.mock('../../../utils/configUtils', () => ({
  getDtmMappedValue: jest.fn().mockReturnValue('sbip2'),
}));

describe('handlePageChange given pathname with a partnerId', () => {
  getTemplateName.mockReturnValueOnce('SBIP2_WHITE_LABEL');
  getPartnerIdName.mockReturnValueOnce('f55');

  const action = {
    type: LOCATION_CHANGE,
    payload: {
      pathname: '/p/f55',
    },
  };

  const state = {
    intl: {
      locale: 'fr',
    },
  };

  handlePageChange(action, state);
  expect(sendPageLoadEvent).toHaveBeenCalledWith(
    'sbip2:login',
    'sbip2:Login',
    state,
    'BNC_ID',
    'f55',
    'sbip2',
  );
});

describe('handlePageChange given pathname with a sbip2 template', () => {
  it('fires an PAGELOAD event to DTM with sbip2:login as URI and sbip2:Login as type and f55 as partner ID', () => {
    getTemplateName.mockReturnValueOnce('SBIP2_WHITE_LABEL');
    getPartnerIdName.mockReturnValueOnce('f55');

    const action = {
      type: LOCATION_CHANGE,
      payload: {
        pathname: '/p/f55',
      },
    };

    const state = {
      intl: {
        locale: 'fr',
      },
    };

    handlePageChange(action, state);
    expect(sendPageLoadEvent).toHaveBeenCalledWith(
      'sbip2:login',
      'sbip2:Login',
      state,
      'BNC_ID',
      'f55',
      'sbip2',
    );
  });
});

describe('handlePageChange given pathname with a sbip2 template', () => {
  it('fires an PAGELOAD event to DTM with sbip2:login as URI and sbip2:Login as type and no partner ID', () => {
    getTemplateName.mockReturnValueOnce('SBIP2');
    getPartnerIdName.mockReturnValueOnce('');

    const action = {
      type: LOCATION_CHANGE,
      payload: {
        pathname: '/',
      },
    };

    const state = {
      intl: {
        locale: 'fr',
      },
    };

    handlePageChange(action, state);
    expect(sendPageLoadEvent).toHaveBeenCalledWith(
      'sbip2:login',
      'sbip2:Login',
      state,
      'BNC_ID',
      '',
      'sbip2',
    );
  });
});

describe('handlePageChange given pathname with a secureKey template', () => {
  it('fires an PAGELOAD event to DTM with secureKey template no partner ID', () => {
    getTemplateName.mockReturnValueOnce('SECURE_KEY');
    getPartnerIdName.mockReturnValueOnce('');
    getDtmMappedValue.mockReturnValueOnce('secureKey');

    const action = {
      type: LOCATION_CHANGE,
      payload: {
        pathname: '/secureKey',
      },
    };

    const state = {
      intl: {
        locale: 'fr',
      },
    };

    handlePageChange(action, state);
    expect(sendPageLoadEvent).toHaveBeenCalledWith(
      'secureKey:login',
      'secureKey:Login',
      state,
      'BNC_ID',
      '',
      'secureKey',
    );
  });
});
