// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import type { State } from '../../../types/state';
import { getMFAfactorsWithValues } from '../../../services/loginService/selectors';

import SendMFACode from './sendMFACode';
import { getLocale } from '../../../services/i18nService/selectors';

const mapStateToProps = (state: State) => ({
  factors: getMFAfactorsWithValues(state),
  locale: getLocale(state),
});

export default injectIntl(connect(mapStateToProps)(SendMFACode));
