// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import MultiFactorAuth from './multiFactorAuthTemplate';
import type { State } from '../../../types/state';
import { isHideResend } from '../../../services/loginService/selectors';

const mapStateToProps = (state: State) => ({
  hideFooter: isHideResend(state),
});

export default injectIntl(connect(mapStateToProps)(MultiFactorAuth));
