// @flow
import React from 'react';

import ErrorDisplay from '../../../molecules/common/ErrorDisplay';

type Props = {
  errorTitle: string,
  errorText: string,
};

function Error(props: Props) {
  const { errorTitle, errorText } = props;

  return <ErrorDisplay errorTitle={errorTitle} errorText={errorText} />;
}

export default Error;
