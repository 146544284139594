// @flow
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { IconValidation } from 'bnc-react-components';
import cx from 'classnames';
import './style.scss';

type Props = {
  id: string,
  values: any,
  className?: string,
};

const defaultProps = {
  className: '',
};

function SuccessMessage(props: Props) {
  const { id, values, className } = props;

  return (
    <div className={cx('success-message', className)}>
      <IconValidation success size={64} />
      <FormattedHTMLMessage id={id} values={values} />
    </div>
  );
}

SuccessMessage.defaultProps = defaultProps;

export default SuccessMessage;
