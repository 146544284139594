import * as modules from './dependencies';

// eslint-disable-next-line import/prefer-default-export
export async function initialize() {
  const registeredRoutes = [];
  const registeredServices = [];
  const registerFlags = [];

  const assembler = {
    registerRoute(route) {
      registeredRoutes.push(route);
    },
    registerService(service) {
      registeredServices.push(service);
    },
    registerFlags(namespace, flags) {
      registerFlags.push({ namespace, flags });
    },
    registerMenuItem() {},
  };

  for (const loadModule of Object.values(modules)) {
    // eslint-disable-next-line no-await-in-loop
    await loadModule(assembler);
  }

  return {
    services: registeredServices,
    routes: registeredRoutes,
    flags: registerFlags,
  };
}
