import React from 'react';

const IconChrome = props => (
  <div className="browser-logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      {...props}
    >
      <defs>
        <circle id="5c2jxv9hva" cx="28" cy="28" r="28" />
        <circle id="i38layh6md" cx="28" cy="28" r="28" />
        <circle id="wn17hus8nf" cx="28" cy="28" r="28" />
        <circle id="1g8khjnt6i" cx="28" cy="28" r="28" />
        <circle id="y30nzd8d5k" cx="28" cy="28" r="28" />
        <circle id="nmwrtjmf9p" cx="28" cy="28" r="28" />
        <circle id="gbpwxymkur" cx="28" cy="28" r="28" />
        <circle id="r52kf7wzzv" cx="28" cy="28" r="28" />
        <circle id="9phr8g9nmy" cx="28" cy="28" r="28" />
        <circle id="p2mffydhyC" cx="28" cy="28" r="28" />
        <circle id="k7hpus996F" cx="28" cy="28" r="28" />
        <circle id="711xg115eI" cx="28" cy="28" r="28" />
        <circle id="1t4gnltnsK" cx="28" cy="28" r="28" />
        <circle id="in53c7uyaM" cx="28" cy="28" r="28" />
        <radialGradient
          id="eczjv866pw"
          cx="-4.878%"
          cy="-.248%"
          r="401.327%"
          fx="-4.878%"
          fy="-.248%"
          gradientTransform="matrix(.26722 0 0 1 -.036 0)"
        >
          <stop offset="0%" stopColor="#3E2723" stopOpacity="0.2" />
          <stop offset="100%" stopColor="#3E2723" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="38ynrire1D"
          cx="-.166%"
          cy=".104%"
          r="136.321%"
          fx="-.166%"
          fy=".104%"
          gradientTransform="scale(1 .84752)"
        >
          <stop offset="0%" stopColor="#3E2723" stopOpacity="0.2" />
          <stop offset="100%" stopColor="#3E2723" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="f30hkyahtG"
          cx="10.34%"
          cy="-12.215%"
          r="226.352%"
          fx="10.34%"
          fy="-12.215%"
          gradientTransform="matrix(1 0 0 .49642 0 -.062)"
        >
          <stop offset="0%" stopColor="#263238" stopOpacity="0.2" />
          <stop offset="100%" stopColor="#263238" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="zankil1e7O"
          cx="14.935%"
          cy="13.644%"
          r="100.424%"
          fx="14.935%"
          fy="13.644%"
        >
          <stop offset="0%" stopColor="#FFF" stopOpacity="0.1" />
          <stop offset="100%" stopColor="#FFF" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="j0q2dolt4c"
          x1="4.547%"
          x2="36.948%"
          y1="55.356%"
          y2="42.741%"
        >
          <stop offset="0%" stopColor="#A52714" stopOpacity="0.6" />
          <stop offset="66%" stopColor="#A52714" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="58dit5nxph"
          x1="70.025%"
          x2="41.909%"
          y1="87.773%"
          y2="66.355%"
        >
          <stop offset="0%" stopColor="#055524" stopOpacity="0.4" />
          <stop offset="33%" stopColor="#055524" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="0u8319z23o"
          x1="40.978%"
          x2="49.247%"
          y1="-4.841%"
          y2="45.414%"
        >
          <stop offset="0%" stopColor="#EA6100" stopOpacity="0.3" />
          <stop offset="66%" stopColor="#EA6100" stopOpacity="0" />
        </linearGradient>
        <path
          id="ydq1eiu42m"
          d="M0 50.909h26.654l12.371-12.371v-9.265H16.978L0 .153z"
        />
        <path
          id="po05ge63lt"
          d="M1.591 0l11.025 19.091L.245 40.727h29.346V0z"
        />
        <path
          id="nqqxi7gtyA"
          d="M.309 0v12.835l12.533 21.529 11.022-19.091h28V0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="v7g7s7gfub" fill="#fff">
          <use xlinkHref="#5c2jxv9hva" />
        </mask>
        <g fillRule="nonzero" mask="url(#v7g7s7gfub)">
          <path fill="#DB4437" d="M4.445 0v34.364h12.533L28 15.273h28V0z" />
          <path
            fill="url(#j0q2dolt4c)"
            d="M.309 0v34.364h12.533l11.022-19.091h28V0z"
            transform="translate(4.136)"
          />
        </g>
        <mask id="zgszqm94qe" fill="#fff">
          <use xlinkHref="#i38layh6md" />
        </mask>
        <path
          fill="#3E2723"
          fillOpacity="0.15"
          fillRule="nonzero"
          d="M17.28 34.252L4.607 12.517l-.184.318 12.581 21.573z"
          mask="url(#zgszqm94qe)"
        />
        <mask id="jia82lsjeg" fill="#fff">
          <use xlinkHref="#wn17hus8nf" />
        </mask>
        <g fillRule="nonzero" mask="url(#jia82lsjeg)">
          <path
            fill="#0F9D58"
            d="M0 55.999h26.654l12.371-12.371v-9.265H16.978L0 5.243z"
          />
          <path
            fill="url(#58dit5nxph)"
            d="M0 50.909h26.654l12.371-12.371v-9.265H16.978L0 .153z"
            transform="translate(0 5.09)"
          />
        </g>
        <mask id="x03wn9w0uj" fill="#fff">
          <use xlinkHref="#1g8khjnt6i" />
        </mask>
        <path
          fill="#263238"
          fillOpacity="0.15"
          fillRule="nonzero"
          d="M38.767 34.787l-.264-.153L26.288 56h.366l12.123-21.204z"
          mask="url(#x03wn9w0uj)"
        />
        <mask id="yq3ihlveel" fill="#fff">
          <use xlinkHref="#y30nzd8d5k" />
        </mask>
        <g mask="url(#yq3ihlveel)">
          <g transform="translate(0 5.09)">
            <mask id="ps8f61lubn" fill="#fff">
              <use xlinkHref="#ydq1eiu42m" />
            </mask>
            <g fillRule="nonzero" mask="url(#ps8f61lubn)">
              <path
                fill="#FFCD40"
                d="M28.001 10.182l11.025 19.091-12.371 21.636h29.346V10.182z"
              />
              <path
                fill="url(#0u8319z23o)"
                d="M1.591 0l11.025 19.091L.245 40.727h29.346V0z"
                transform="translate(26.41 10.182)"
              />
            </g>
          </g>
        </g>
        <mask id="kvcw4jvcoq" fill="#fff">
          <use xlinkHref="#nmwrtjmf9p" />
        </mask>
        <g fillRule="nonzero" mask="url(#kvcw4jvcoq)">
          <path
            fill="#FFCD40"
            d="M28.001 15.273l11.025 19.091L26.655 56h29.346V15.273z"
          />
          <path
            fill="url(#0u8319z23o)"
            d="M1.591 0l11.025 19.091L.245 40.727h29.346V0z"
            transform="translate(26.41 15.273)"
          />
        </g>
        <mask id="su0xy4gj9s" fill="#fff">
          <use xlinkHref="#gbpwxymkur" />
        </mask>
        <g mask="url(#su0xy4gj9s)">
          <g transform="translate(26.41 15.273)">
            <mask id="hbl9x5tdtu" fill="#fff">
              <use xlinkHref="#po05ge63lt" />
            </mask>
            <g fillRule="nonzero" mask="url(#hbl9x5tdtu)">
              <path
                fill="#DB4437"
                d="M-21.964-15.273v34.364h12.533L1.591 0h28v-15.273z"
              />
              <path
                fill="url(#j0q2dolt4c)"
                d="M.309 0v34.364h12.533l11.022-19.091h28V0z"
                transform="translate(-22.273 -15.273)"
              />
            </g>
          </g>
        </g>
        <mask id="z244jj0tdx" fill="#fff">
          <use xlinkHref="#r52kf7wzzv" />
        </mask>
        <path
          fill="url(#eczjv866pw)"
          fillRule="nonzero"
          d="M28 15.273v6.666l24.945-6.666z"
          mask="url(#z244jj0tdx)"
        />
        <mask id="l874vt4ucz" fill="#fff">
          <use xlinkHref="#9phr8g9nmy" />
        </mask>
        <g mask="url(#l874vt4ucz)">
          <g transform="translate(4.136)">
            <mask id="dchrtrnuoB" fill="#fff">
              <use xlinkHref="#nqqxi7gtyA" />
            </mask>
            <g fillRule="nonzero" mask="url(#dchrtrnuoB)">
              <path
                fill="#0F9D58"
                d="M-4.136 55.999h26.654l12.371-12.371v-9.265H12.842L-4.136 5.243z"
              />
              <path
                fill="url(#58dit5nxph)"
                d="M0 50.909h26.654l12.371-12.371v-9.265H16.978L0 .153z"
                transform="translate(-4.136 5.09)"
              />
            </g>
          </g>
        </g>
        <mask id="tolaa68vtE" fill="#fff">
          <use xlinkHref="#p2mffydhyC" />
        </mask>
        <path
          fill="url(#38ynrire1D)"
          fillRule="nonzero"
          d="M4.445 12.87l18.216 18.213-5.683 3.281z"
          mask="url(#tolaa68vtE)"
        />
        <g>
          <mask id="w8f9mjlyhH" fill="#fff">
            <use xlinkHref="#k7hpus996F" />
          </mask>
          <path
            fill="url(#f30hkyahtG)"
            fillRule="nonzero"
            d="M26.673 55.965l6.669-24.882 5.683 3.281z"
            mask="url(#w8f9mjlyhH)"
          />
        </g>
        <g>
          <mask id="g4sh36yzvJ" fill="#fff">
            <use xlinkHref="#711xg115eI" />
          </mask>
          <g fillRule="nonzero" mask="url(#g4sh36yzvJ)">
            <g transform="translate(15.273 15.273)">
              <circle cx="12.727" cy="12.727" r="12.727" fill="#F1F1F1" />
              <circle cx="12.727" cy="12.727" r="10.182" fill="#4285F4" />
            </g>
          </g>
        </g>
        <g>
          <mask id="3emr4ulh2L" fill="#fff">
            <use xlinkHref="#1t4gnltnsK" />
          </mask>
          <g fillRule="nonzero" mask="url(#3emr4ulh2L)">
            <path
              fill="#3E2723"
              fillOpacity="0.2"
              d="M28 14.954c-7.029 0-12.727 5.698-12.727 12.727v.318c0-7.029 5.698-12.727 12.727-12.727h28v-.318H28z"
            />
            <path
              fill="#FFF"
              fillOpacity="0.1"
              d="M39.01 34.363c-2.203 3.799-6.304 6.363-11.01 6.363-4.71 0-8.81-2.564-11.012-6.363h-.013L0 5.243v.317l16.978 29.12h.013c2.202 3.799 6.303 6.364 11.012 6.364 4.706 0 8.807-2.562 11.01-6.364h.015v-.318h-.019z"
            />
            <path
              fill="#3E2723"
              d="M28.318 15.272c-.054 0-.105.006-.159.01 6.956.085 12.568 5.743 12.568 12.717 0 6.975-5.612 12.632-12.568 12.718.054 0 .105.01.16.01 7.028 0 12.726-5.7 12.726-12.728 0-7.029-5.698-12.727-12.727-12.727z"
              opacity="0.1"
            />
            <path
              fill="#FFF"
              fillOpacity="0.2"
              d="M39.136 34.786a12.685 12.685 0 001.114-10.198c.302 1.088.477 2.23.477 3.414 0 2.317-.627 4.48-1.708 6.351l.006.013-12.37 21.636h.368l12.12-21.203-.007-.013z"
            />
          </g>
        </g>
        <g>
          <mask id="o8za3it0yN" fill="#fff">
            <use xlinkHref="#in53c7uyaM" />
          </mask>
          <g fillRule="nonzero" mask="url(#o8za3it0yN)">
            <path
              fill="#FFF"
              fillOpacity="0.2"
              d="M28 .318c15.41 0 27.91 12.45 27.997 27.841 0-.054.003-.105.003-.159C56 12.536 43.464 0 28 0S0 12.536 0 28c0 .054.003.105.003.16C.09 12.768 12.59.317 28 .317z"
            />
            <path
              fill="#3E2723"
              fillOpacity="0.15"
              d="M28 55.682c15.41 0 27.91-12.45 27.997-27.841 0 .054.003.105.003.159 0 15.464-12.536 28-28 28S0 43.464 0 28c0-.054.003-.105.003-.16C.09 43.232 12.59 55.683 28 55.683z"
            />
          </g>
        </g>
        <circle
          cx="28"
          cy="28"
          r="28"
          fill="url(#zankil1e7O)"
          fillRule="nonzero"
        />
      </g>
    </svg>
  </div>
);

export default IconChrome;
