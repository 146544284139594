import get from 'lodash/get';
import service from '.';
import { BIOMETRY_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';

import getReducerState from '../../utils/stateSelectorHelper';

export const getBiometryState = state =>
  getReducerState(state, service.namespace, BIOMETRY_SERVICE_REDUCER);

export const biometrySelector = state => {
  return get(getBiometryState(state), 'biometry', null);
};

export const errorSelector = state => {
  return get(getBiometryState(state), 'error', null);
};

export const forceLoginSelector = state => {
  return get(getBiometryState(state), 'forceLogin', false);
};
