import React from 'react';
import cx from 'classnames';
import { toggleFeatureApi } from 'bnc-react-components';

import SignUp from '../../../molecules/common/SignUp';

import { CONFIGS_NAMES } from '../../../utils/constants/configs';

import type { Config } from '../../../types/configs';

type Props = {
  className?: string,
  configs: Config,
  hasSignUp?: boolean,
};

const defaultProps = {
  className: '',
  hasSignUp: true,
};

function LoginFooter(props: Props) {
  const { className, configs, hasSignUp } = props;

  const isSignUpAvailable = toggleFeatureApi.isAvailable(
    configs,
    CONFIGS_NAMES.SIGN_UP.GLOBAL,
    [],
  );

  return (
    isSignUpAvailable && (
      <div className={cx('login-footer', className)}>
        <SignUp isVisible={hasSignUp} theme="entreprise" />
      </div>
    )
  );
}

LoginFooter.defaultProps = defaultProps;

export default LoginFooter;
