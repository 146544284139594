// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as reducers from '../../../globalRedux/reducers/constants';

import WhiteLabelMedia from './whiteLabelMedia';

const mapStateToProps = state => ({
  partnerId: state[reducers.TEMPLATE_SERVICE_REDUCER].partnerId,
});

export default injectIntl(connect(mapStateToProps)(WhiteLabelMedia));
