// @flow
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import LoginWithoutIdPartialContentForm from './loginWithoutIdPartialContentForm';
import type { State } from '../../../../../types/state';
import { getLocale } from '../../../../../services/i18nService/selectors';

// eslint-disable-next-line import/named
import {
  getFormData,
  getLoginFormState,
  isLoginFormDone,
} from '../../../../../services/loginFormService/selectors';

function mapStateToProps(state: State) {
  const loginFormState = getLoginFormState(state);
  return {
    locale: getLocale(state),
    formDataBne: getFormData(state),
    isFormDone: isLoginFormDone(state),
    isSubmitFormFailed: loginFormState.submitFailed,
  };
}

export default connect(mapStateToProps)(
  injectIntl(LoginWithoutIdPartialContentForm),
);
