// @flow
import * as actionTypes from './actionTypes';
import type { ProfileData } from '../../types/forms/forgotPasswordForm';
import type {
  ProfileType,
  ExceptionType,
} from '../../utils/constants/forgotPassword';
import type {
  FactorData,
  ValidateUserFailureAction,
  ValidateUserSuccessAction,
} from './types';

export function validateUserRequest(userProfileData: ProfileData) {
  return {
    type: actionTypes.FORGOT_PASSWORD_VALIDATE_USER_REQUEST,
    ...userProfileData,
  };
}

export function validateUserSuccess(
  factors: Array<FactorData>,
  selectedFactor,
): ValidateUserSuccessAction {
  return {
    type: actionTypes.FORGOT_PASSWORD_VALIDATE_USER_SUCCESS,
    factors,
    selectedFactor,
  };
}

export function triggerMFAChoiceStep(
  factors: Array<FactorData>,
): ValidateUserSuccessAction {
  return {
    type: actionTypes.FORGOT_PASSWORD_VALIDATE_USER_SUCCESS_MFA_CHOICE,
    factors,
  };
}

export function validateUserFailure(error): ValidateUserFailureAction {
  return {
    type: actionTypes.FORGOT_PASSWORD_VALIDATE_USER_FAILURE,
    error,
  };
}

export function setContext(profileType: ProfileType, recoveryToken: string) {
  return {
    type: actionTypes.FORGOT_PASSWORD_SET_CONTEXT,
    profileType,
    recoveryToken,
  };
}

export function triggerException(exceptionType: ExceptionType) {
  return {
    type: actionTypes.FORGOT_PASSWORD_TRIGGER_EXCEPTION,
    exceptionType,
  };
}

export function reset() {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESET,
  };
}

export function setAnalytics(analytics) {
  return {
    type: actionTypes.FORGOT_PASSWORD_SET_ANALYTICS,
    analytics,
  };
}

export function sendAnalytics(stepName) {
  return {
    type: actionTypes.FORGOT_PASSWORD_SEND_ANALYTICS,
    stepName,
  };
}

export function setBackendError(error) {
  return {
    type: actionTypes.FORGOT_PASSWORD_SET_BACKEND_ERROR,
    error,
  };
}

export function showResendOTCSuccessMessage(value) {
  return {
    type: actionTypes.FORGOT_PASSWORD_SHOW_RESEND_OTC_SUCCESS_MESSAGE,
    value,
  };
}

export function resendOTC(factor) {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESEND_OTC,
    factor,
  };
}

export function resendOTCFailure() {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESEND_OTC_FAILURE,
  };
}

export function verifyOTC() {
  return {
    type: actionTypes.FORGOT_PASSWORD_VERIFY_OTC,
  };
}

export function verifyOTCSuccess() {
  return {
    type: actionTypes.FORGOT_PASSWORD_VERIFY_OTC_SUCCESS,
  };
}

export function verifyOTCFailure() {
  return {
    type: actionTypes.FORGOT_PASSWORD_VERIFY_OTC_FAILURE,
  };
}

export function resetForgotPasswordFlow() {
  return {
    type: actionTypes.FORGOT_PASSWORD_FLOW_RESET,
  };
}

export function saveRecaptchaToken(tokenRecaptcha) {
  return {
    type: actionTypes.SAVE_RECAPTCHA_TOKEN,
    tokenRecaptcha,
  };
}

export function setPassword(newPassword) {
  return {
    type: actionTypes.FORGOT_PASSWORD_SET_PASSWORD,
    newPassword,
  };
}

export function setPasswordSuccess() {
  return {
    type: actionTypes.FORGOT_PASSWORD_SET_PASSWORD_SUCCESS,
  };
}

export function setPasswordFail() {
  return {
    type: actionTypes.FORGOT_PASSWORD_SET_PASSWORD_FAIL,
  };
}

export function forgotPasswordUnavailableCheck(routeToValidate: string) {
  return {
    type: actionTypes.FORGOT_PASSWORD_UNAVAILABLE_CHECK,
    routeToValidate,
  };
}

export function triggerVerificationCodeStep(factor) {
  return {
    type: actionTypes.TRIGGER_VERIFICATION_CODE_STEP,
    factor,
  };
}

export function forgotPasswordTrackResendCode(selectedFactor) {
  return {
    type: actionTypes.FORGOT_PASSWORD_TRACK_RESEND_SUCCESS,
    selectedFactor,
  };
}
