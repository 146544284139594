// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getTemplateName } from '../../../../services/templateService/selectors';

import MultiFactorAuthForm from './multiFactorAuthForm';
import { Dispatch } from '../../../../types/store';
import { displayResend } from '../../../../services/loginService/actions';
import type { State } from '../../../../types/state';
import { getLocale } from '../../../../services/i18nService/selectors';
import { getMFAselected } from '../../../../services/loginService/selectors';

const mapStateToProps = (state: State) => {
  return {
    templateName: getTemplateName(state),
    locale: getLocale(state),
    selectedFactor: getMFAselected(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  displayResend: () => dispatch(displayResend()),
});

export default injectIntl(
  connect(null, mapDispatchToProps)(MultiFactorAuthForm),
  connect(null, mapStateToProps)(MultiFactorAuthForm),
);
