import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ExpirationModal from './expirationModal';

import {
  isShowSessionExpiredModal,
  isShowMFACodeExpiredModal,
} from '../../../services/modalService/selectors';

function mapStateToProps(state) {
  return {
    isShowSessionExpiredModal: isShowSessionExpiredModal(state),
    isShowMFACodeExpiredModal: isShowMFACodeExpiredModal(state),
  };
}

export default injectIntl(connect(mapStateToProps)(ExpirationModal));
