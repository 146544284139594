import testReducer from 'bnc-utilities-js/testUtils/reducer';
import templateServiceReducer from '../reducer';
import actions from '../actions';

import TEMPLATES from '../../../utils/constants/template';

testReducer(templateServiceReducer)
  .initialState()
  .expectDiff({
    templateName: '',
    partnerId: '',
  });

testReducer(templateServiceReducer)
  .withAnyState()
  .on(actions.setCurrentTemplate(TEMPLATES.SBIP2))
  .expectDiff({
    templateName: TEMPLATES.SBIP2,
  });

testReducer(templateServiceReducer)
  .withAnyState()
  .on(actions.setCurrentTemplate(TEMPLATES.SBIP2, 'f55'))
  .expectDiff({
    templateName: TEMPLATES.SBIP2,
    partnerId: 'f55',
  });
