// @flow
import { connect } from 'react-redux';

import * as reducers from '../../../globalRedux/reducers/constants';
import UnsupportedBrowserFooter from './unsupportedBrowserFooter';
import type { State } from '../../../types/state';

function mapStateToProps(state: State) {
  return {
    browser: state[reducers.USER_AGENT_SERVICE_REDUCER].browser,
  };
}

export default connect(mapStateToProps)(UnsupportedBrowserFooter);
