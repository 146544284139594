// @flow
import React from 'react';
import type { intlShape } from 'react-intl';

import SecureKeyUnauthorizedTemplate from '../../templates/secureKey/ErrorTemplate';
import RewardsWLUnauthorizedTemplate from '../../templates/rewardsWhiteLabel/ErrorTemplate';
import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';

type Props = {
  intl: intlShape,
  templateName: string,
};

const Unauthorized = (props: Props) => {
  const { intl, templateName } = props;
  const title = intl.formatMessage({ id: 'text.message.error.title' });
  const text = intl.formatMessage({ id: 'text.message.unauthorizedProspect' });
  const unauthorizedTemplates = {
    [TEMPLATES.SECURE_KEY]: (
      <SecureKeyUnauthorizedTemplate errorTitle={title} errorText={text} />
    ),
    [TEMPLATES.REWARDS_WHITE_LABEL]: (
      <RewardsWLUnauthorizedTemplate errorTitle={title} errorText={text} />
    ),
  };

  return getTemplateComponent(unauthorizedTemplates, templateName);
};

export default Unauthorized;
