import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router as ReactRouter } from 'react-router';

const propTypes = {
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
};

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = props.store.getState();
    this.renderChildren = this.renderChildren.bind(this);
  }

  renderChildren(children) {
    const modifiedChildren = React.Children.map(children, (child, index) => {
      let childToReturn = child;
      const { path } = child.props;
      childToReturn = React.cloneElement(child, {
        path,
        children: child.props.children
          ? this.renderChildren(child.props.children)
          : undefined,
      });
      return childToReturn;
    });
    return modifiedChildren;
  }

  render() {
    const { children, ...otherProps } = this.props;
    return (
      <ReactRouter {...otherProps}>{this.renderChildren(children)}</ReactRouter>
    );
  }
}

Router.propTypes = propTypes;

export default Router;
