import I18N from '../constants/i18n';

export const FORGOT_PASSWORD_FIELDS = {
  IDENTIFICATION_FORM: {
    EMAIL: 'email',
    BIRTHDATE: 'birthDate',
    PRODUCT_TYPE: 'productType',
    OPEN_CHOICES: 'open_choices',
    RECAPTCHA: 'recaptchaResponse',
    ORIGINAL_AMOUNT: 'originalAmount',
    CARD_NUMBER: 'cardNumber',
    EXPIRY_DATE: 'expiryDate',
    ACCOUNT_NUMBER: 'accountNumber',
    PHONE_NUMBER: 'phone',
  },
  VALIDATION_FORM: {
    CODE: 'code',
    REMEMBER_DEVICE: 'rememberDevice',
  },
};

export const FORGOT_PASSWORD_VALIDATION_RULES = {
  DEBIT: {
    CARD_LENGTH: 6,
    PREFIX: '5002359 ******',
    EXPIRATION_DATE_MASK: '99/99',
  },
  LOAN: {
    ACCOUNT_MIN_LENGTH: 11,
    ACCOUNT_MAX_LENGTH: 12,
  },
  CREDIT: {
    CARD_LENGTH: 6,
    PREFIX: '5258 ******',
    EXPIRY_DATE_MASK: '99/99',
  },
  INVESTMENT: {
    ACCOUNT_LENGTH: 12,
    PHONE_NUMBER_MASK: {
      [I18N.LANG.FR]: '999 999-9999',
      [I18N.LANG.EN]: '999-999-9999',
    },
  },
  INVESTMENT_BROKERAGE: {
    ACCOUNT_LENGTH: 6,
    PHONE_NUMBER_MASK: {
      [I18N.LANG.FR]: '999 999-9999',
      [I18N.LANG.EN]: '999-999-9999',
    },
  },
  MORTGAGE: {
    ACCOUNT_MIN_LENGTH: 7,
    ACCOUNT_MAX_LENGTH: 11,
  },
  PROSPECT: {
    PHONE_NUMBER_MASK: {
      [I18N.LANG.FR]: '999 999-9999',
      [I18N.LANG.EN]: '999-999-9999',
    },
  },
  REGEX: {
    CREDIT_CARD_NUMBER: /^\d{6}$/,
    DEBIT_CARD_NUMBER: /^\d{6}$/,
    MORTGAGE_ACCOUNT_NUMBER: /^(\d{7}|\d{11})$/,
    CARD_EXPIRY_DATE: /^(?:0[1-9]|1[0-2])(?:\/)(?:\d{4}|\d{2})$/,
    PHONE_NUMBER: /^(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})$/,
  },
};

export const LANG = {
  FR: 'fr',
  EN: 'en',
};

export const FORGOT_PASSWORD_PRODUCT_TYPE = {
  DEBIT: 'BANKCARD',
  CREDIT: 'CREDITCARD',
  INVESTMENT: 'INVESTMENT',
  LOAN: 'LOAN',
  MORTGAGE: 'MORTGAGE',
  PROSPECT: 'NO_PRODUCT',
  INVESTMENT_BROKERAGE: 'INVESTMENT_BROKERAGE',
};

export const FORGOT_PASSWORD_STEPS = {
  IDENTIFICATION: 'IDENTIFICATION',
  MFA_CHOICE: 'MFA_CHOICE',
  CODE_CONFIRMATION: 'CODE_CONFIRMATION',
  PASSWORD_RESET: 'PASSWORD_RESET',
  FINAL_CONFIRMATION: 'FINAL_CONFIRMATION',
};

export const MAX_CHOICES_TO_SHOW = 3;
export const MAX_CHOICES_TO_SHOW_REDUCED_MODE = 2;
