/**
 * Update counting when eye button of an input password is clicked.
 *
 * @param {*} passwordInputRef password input ref
 * @param {*} updateCounting set counting function
 */

/* eslint-disable no-param-reassign */
const onEyeButtonClick = (passwordInputRef, updateCounting) => {
  if (passwordInputRef && passwordInputRef.nextSibling) {
    passwordInputRef.nextSibling.onclick = () => updateCounting(c => c + 1);
  }
};

export default onEyeButtonClick;
