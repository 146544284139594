import { testRootSagaMappings } from 'bnc-utilities-js/testUtils/saga';
import rootSaga, { workersMapping } from '..';

jest.mock('../workers', () => ({
  watchLoginLocked: jest.fn(),
  watchLoginLockedVsd: jest.fn(),
  watchLoginLockedFraud: jest.fn(),
  watchLoginMFA: jest.fn(),
  watchLoginRSA: jest.fn(),
  watchLoginPasswordException: jest.fn(),
  watchLoginPasswordExpired: jest.fn(),
  watchLoginAppOrigin: jest.fn(),
  watchLoginSignUp: jest.fn(),
  watchLoginForgotPassword: jest.fn(),
  watchLoginForgotPasswordException: jest.fn(),
  watchLoginHelpPage: jest.fn(),
  watchLoginResetPassword: jest.fn(),
  watchLoginRouteWithoutSignIn: jest.fn(),
  watchLoginForgotPasswordUnavailable: jest.fn(),
  watchLoginMFAChoice: jest.fn(),
  watchLoginMfaSendCodeRequest: jest.fn(),
  watchTriggerSendCodeRequest: jest.fn(),
}));

testRootSagaMappings(rootSaga, workersMapping);
