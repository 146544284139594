import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { Button } from '@bnc-ui/button';

import BackLinkButton from '../../../common/BackLinkButton';

const propTypes = {
  intl: intlShape.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  returnToLogin: PropTypes.func.isRequired,
};

const Final = ({ intl, isFetching, onSubmit, returnToLogin }) => (
  <div className="forgot-password-form--group forgot-password-form--group__final">
    <Button
      id="forgotPasswordFormSubmitButton"
      type="submit"
      appearance="primary"
      fluid
      disabled={isFetching}
      onClick={!isFetching ? onSubmit : () => {}}
      size="large"
      aria-label={intl.formatMessage({
        id: 'text.aria.forgotPwdAsking',
      })}
    >
      {isFetching
        ? intl.formatMessage({ id: 'global.button.label.pending' })
        : intl.formatMessage({ id: 'global.button.continue.label' })}
    </Button>
    <BackLinkButton
      className="forgot-password-form__link"
      label={intl.formatMessage({ id: 'global.button.backToLogin' })}
      onClick={returnToLogin}
    />
  </div>
);

Final.propTypes = propTypes;
export default Final;
