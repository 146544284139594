// @flow

import { FORM_TYPES } from '../../utils/constants/forms';

import type { FormType } from '../../types/constants';

import {
  initForm as initLoginForm,
  initFormDone as initLoginFormDone,
} from '../loginFormService/actions';

import {
  LOGIN_FORM_INIT_DONE,
  LOGIN_FORM_INIT_REQUEST,
} from '../loginFormService/actionTypes';

import {
  initForm as initMultiFactorAuthForm,
  initFormDone as initMultiFactorAuthFormDone,
} from '../multiFactorAuthFormService/actions';

import {
  MULTI_FACTOR_AUTH_FORM_INIT_DONE,
  MULTI_FACTOR_AUTH_FORM_INIT_REQUEST,
} from '../multiFactorAuthFormService/actionTypes';

import {
  initForm as initForgotPasswordForm,
  initFormDone as initForgotPasswordFormDone,
  resetForm as ForgotPasswordFormReset,
} from '../forgotPasswordFormService/actions';

import {
  FORGOT_PASSWORD_FORM_INIT_DONE,
  FORGOT_PASSWORD_FORM_INIT_REQUEST,
} from '../forgotPasswordFormService/actionTypes';

export type FormInfos = {
  [formName: FormType | string]: {
    initForm: Function,
    initDoneActionType: string,
    initFormDone: Function,
    initRequestActionType: string,
  },
};

const formInfos: FormInfos = {
  [FORM_TYPES.LOGIN_FORM]: {
    initForm: initLoginForm,
    initDoneActionType: LOGIN_FORM_INIT_DONE,
    initFormDone: initLoginFormDone,
    initRequestActionType: LOGIN_FORM_INIT_REQUEST,
  },
  [FORM_TYPES.MULTI_FACTOR_AUTH_FORM]: {
    initForm: initMultiFactorAuthForm,
    initDoneActionType: MULTI_FACTOR_AUTH_FORM_INIT_DONE,
    initFormDone: initMultiFactorAuthFormDone,
    initRequestActionType: MULTI_FACTOR_AUTH_FORM_INIT_REQUEST,
  },
  [FORM_TYPES.FORGOT_PASSWORD_FORM]: {
    initForm: initForgotPasswordForm,
    initDoneActionType: FORGOT_PASSWORD_FORM_INIT_DONE,
    initFormDone: initForgotPasswordFormDone,
    initRequestActionType: FORGOT_PASSWORD_FORM_INIT_REQUEST,
    resetForm: ForgotPasswordFormReset,
  },
};

export default {
  formInfos,
};
