import PropTypes from 'prop-types';
import { Tagging, TrackAction } from '@nbc-studio/analytics';
import React from 'react';
import {
  BNE_EVENT_IDS,
  BNE_EVENT_NAMES,
  BNE_EVENT_SOURCE,
  BNE_EVENT_SOURCE_DETAILS,
  BNE_UCS_IDS,
  BNE_UCS_STATE,
} from '../../../../utils/taggingUtils/bneTaggingConstants';

const propTypes = {
  modalType: PropTypes.string,
};

const defaultProps = {
  modalType: '',
};

const ExpiredModalTaggingWrapper = ({ children, modalType }) => {
  const interaction = `open:expired-${modalType}`;

  return (
    <Tagging
      ucsId={BNE_UCS_IDS.EXPIRATION}
      ucsState={BNE_UCS_STATE.NON_PV}
      eventName={BNE_EVENT_NAMES.EXPIRATION}
      eventId={BNE_EVENT_IDS.EXPIRATION}
      eventSourceDetail={BNE_EVENT_SOURCE_DETAILS.LOGIN}
      eventSource={BNE_EVENT_SOURCE.BNE}
    >
      {children}
      {interaction && (
        <TrackAction interaction={interaction} dependencies={[interaction]} />
      )}
    </Tagging>
  );
};

ExpiredModalTaggingWrapper.propTypes = propTypes;
ExpiredModalTaggingWrapper.defaultProps = defaultProps;

export default ExpiredModalTaggingWrapper;
