import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { FormGroup } from '@bnc-ui/formGroup';
import { InputGroup } from '@bnc-ui/inputGroup';
import { Message } from '@bnc-ui/message';

import I18N from '../../../../utils/constants/i18n';
import {
  FORGOT_PASSWORD_FIELDS,
  FORGOT_PASSWORD_VALIDATION_RULES,
} from '../../../../utils/forgotPasswordUtils/constants';
import { forgotPasswordFormDataShape } from '../../../../utils/shapes';

const propTypes = {
  intl: intlShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  formData: forgotPasswordFormDataShape.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const ProspectInfo = ({ intl, errors, formData, onChange, onBlur, locale }) => (
  <div className="forgot-password-form--group--prospect">
    <FormGroup
      label={{
        text: intl.formatMessage({
          id: 'text.label.forgotPassword.phoneNumber',
        }),
        htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER,
      }}
      validate={{
        hasError:
          FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER in errors,
        errorMsg: intl.formatMessage({
          id: 'text.error.field.phoneNumberIncorrectFormat',
        }),
      }}
    >
      <InputGroup
        inputId={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER}
        inputName={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER}
        onChange={onChange}
        onBlur={onBlur}
        mask={get(
          FORGOT_PASSWORD_VALIDATION_RULES,
          `PROSPECT.PHONE_NUMBER_MASK.${locale}`,
          FORGOT_PASSWORD_VALIDATION_RULES.PROSPECT.PHONE_NUMBER_MASK[I18N.EN],
        )}
        placeholder={intl.formatMessage({
          id: 'text.field.placeholder.forgotPassword.phoneNumber',
        })}
        value={get(
          formData,
          FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER,
        )}
      />
    </FormGroup>

    <Message appearance="information">
      {intl.formatMessage({
        id: 'text.message.forgotPassword.prospectPhoneNumber',
      })}
    </Message>
  </div>
);

ProspectInfo.propTypes = propTypes;

export default ProspectInfo;
