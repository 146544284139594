import { Configs } from '../../configs';
import HTTP from '../../utils/constants/http';
import { bncFetch } from '../../utils/fetchUtils';

const tokenCaptchaFetch = (token, nbfgId) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (nbfgId) {
    headers.nbfgId = nbfgId;
  }

  const fetchQuery = bncFetch(Configs.RECAPTCHA.EXCHANGE_TOKEN_ENDPOINT, {
    method: HTTP.METHODS.POST,
    headers,
    body: JSON.stringify({
      recaptcha: token,
      siteKeyType: 'frontend',
    }),
  });
  const resultAsJson = fetchQuery
    .then(response => response.json())
    .catch(err => err);

  return Promise.all([fetchQuery, resultAsJson]).then(
    ([resultFetch, jsonResult]) => {
      if (!resultFetch.ok) {
        const fetchError = {
          status: resultFetch.status,
          code: jsonResult.code,
          message: jsonResult.message,
        };
        throw fetchError;
      }
      return jsonResult;
    },
  );
};

export default {
  tokenCaptchaFetch,
};
