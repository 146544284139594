// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import MultiFactorAuthChoice from './multiFactorAuthChoice';

import type { Dispatch } from '../../../types/store';
import type { State } from '../../../types/state';

import { returnToLogin } from '../../../services/loginService/actions';

import { getSdkError } from '../../../services/loginService/selectors';
import { getAuthErrorMessageId } from '../../../utils/authErrorMessages';
import { triggerVerificationCodeStep } from '../../../services/forgotPasswordService/actions';
import {
  factorsSelector,
  getAnalytics,
  isForgotPasswordFlowSelector,
} from '../../../services/forgotPasswordService/selectors';
import { getPartnerIdName } from '../../../services/templateService/selectors';

const mapStateToProps = (state: State) => ({
  sdkError: getSdkError(state),
  getAuthErrorMessageId,
  factors: factorsSelector(state),
  isForgotPasswordFlow: isForgotPasswordFlowSelector(state),
  forgotPasswordFlowId: getAnalytics(state).flowId,
  partnerIdName: getPartnerIdName(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  returnToLogin: () => dispatch(returnToLogin()),
  triggerCodeRequest: factor => dispatch(triggerVerificationCodeStep(factor)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuthChoice),
);
