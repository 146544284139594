// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import * as actionTypes from './actionTypes';
import * as workers from './workers';

export const workersMapping = createWorkersMapping()
  .takeLatest(
    actionTypes.CONFIGS_SERVICE_RETRIEVE_CONFIGS_REQUEST,
    workers.watchRetrieveConfigRequest,
  )
  .takeLatest(
    actionTypes.CONFIGS_SERVICE_RETRIEVE_CONFIGS_SUCCESS,
    workers.watchRetrieveConfigSuccess,
  );

export default createRootSagaFromWorkersMapping(workersMapping);
