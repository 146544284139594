import React from 'react';
import { bool, func } from 'prop-types';

import { Configs } from '../../../../configs';
import BncRecaptcha from '../../../../atoms/BncRecaptcha';

import { FORGOT_PASSWORD_FIELDS } from '../../../../utils/forgotPasswordUtils/constants';

let recaptchaRef;

const propTypes = {
  isRecaptchaError: bool.isRequired,
  hasToResetRecaptcha: bool.isRequired,
  formUpdate: func.isRequired,
  triggerFieldValidation: func.isRequired,
  resetRecaptchaDone: func.isRequired,
  resetRecaptcha: func.isRequired,
};

const Security = ({
  isRecaptchaError,
  hasToResetRecaptcha,
  formUpdate,
  triggerFieldValidation,
  resetRecaptcha,
  resetRecaptchaDone,
}) => {
  // Reset recaptcha if sending fails or if we have errors
  isRecaptchaError && recaptchaRef && recaptchaRef.getWrappedInstance().reset();

  const failure = msg => {
    resetRecaptcha();
  };

  const success = msg => {
    formUpdate(FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA, msg);
    triggerFieldValidation(
      FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA,
    );
  };

  const assignRef = e => {
    recaptchaRef = e;
    return recaptchaRef;
  };

  const timeoutRecaptcha = () => {
    formUpdate(FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA, null);
  };

  return (
    <BncRecaptcha
      ref={assignRef}
      siteKey={Configs.RECAPTCHA.SITE_KEY}
      exhangeTokenEndpoint={Configs.RECAPTCHA.EXCHANGE_TOKEN_ENDPOINT}
      googleRecaptchaEndpoint={Configs.RECAPTCHA.GOOGLE_ENDPOINT}
      success={success}
      failure={failure}
      timeoutRecaptcha={timeoutRecaptcha}
      hasToReset={hasToResetRecaptcha}
      resetDone={resetRecaptchaDone}
      nbfgId={Configs.RECAPTCHA.FORGOT_PWD_NBFG_ID}
    />
  );
};

Security.propTypes = propTypes;
export default Security;
