import { v4 } from 'uuid';
import * as helpers from '../helper';
import * as cookie from '../../../utils/cookie';
import { hashLogin, removeIdentitiesCookie } from '../helper';

jest.mock('uuid', () => ({
  v4: jest.fn(),
}));

jest.mock('../../../utils/cookie', () => ({
  remove: jest.fn(),
}));

describe('getPartnerCookieIdentityName()', () => {
  [
    { templateName: 'SBIP2', partnerId: '', expectedResult: 'SBIP2' },
    { templateName: 'SBIP2', partnerId: 'f55', expectedResult: 'f55' },
    { templateName: 'NATGO', partnerId: '', expectedResult: 'SBIP2' },
    { templateName: '', partnerId: '', expectedResult: '' },
  ].forEach(({ templateName, partnerId, expectedResult }) => {
    it(`templateName '${templateName}' and partnerId '${partnerId}' should return '${expectedResult}'`, () => {
      expect(
        helpers.getPartnerCookieIdentityName(templateName, partnerId),
      ).toEqual(expectedResult);
    });
  });
});

describe('createIdentitiesCookie()', () => {
  [
    {
      currentIdentities: {},
      profile: {
        login: 'test@mail.com',
        deviceToken: '01234567890123456789012345678912',
      },
      partnerCookieIdentityName: 'SBIP2',
      expectedResult: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678912',
          },
        ],
      },
    },
    {
      currentIdentities: {
        SBIP2: [
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678913',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678914',
          },
        ],
      },
      profile: {
        login: 'test@mail.com',
        deviceToken: '01234567890123456789012345678915',
      },
      partnerCookieIdentityName: 'SBIP2',
      expectedResult: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678915',
          },
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678913',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678914',
          },
        ],
      },
    },
    {
      currentIdentities: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678911',
          },
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678921',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678922',
          },
        ],
      },
      profile: {
        login: 'test3@mail.com',
        deviceToken: '01234567890123456789012345678923',
      },
      partnerCookieIdentityName: 'SBIP2',
      expectedResult: {
        SBIP2: [
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678923',
          },
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678911',
          },
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678921',
          },
        ],
      },
    },
    {
      currentIdentities: { SBIP2: [{ login: 'test@mail.com' }] },
      profile: {
        login: 'test@mail.com',
        deviceToken: '01234567890123456789012345678925',
      },
      partnerCookieIdentityName: 'SBIP2',
      expectedResult: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678925',
          },
        ],
      },
    },
    {
      currentIdentities: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678926',
          },
        ],
      },
      profile: {
        login: 'test@mail.com',
        deviceToken: '01234567890123456789012345678927',
      },
      partnerCookieIdentityName: 'SBIP2',
      expectedResult: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678927',
          },
        ],
      },
    },
    {
      currentIdentities: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678928',
          },
        ],
      },
      profile: {
        login: 'test@mail.com',
        deviceToken: '01234567890123456789012345678929',
      },
      partnerCookieIdentityName: 'f55',
      expectedResult: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678928',
          },
        ],
        f55: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678929',
          },
        ],
      },
    },
    {
      currentIdentities: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678930',
          },
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678931',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678932',
          },
          {
            login: 'test4@mail.com',
            deviceToken: '01234567890123456789012345678933',
          },
        ],
      },
      profile: {
        login: 'test5@mail.com',
        deviceToken: '01234567890123456789012345678934',
      },
      partnerCookieIdentityName: 'SBIP2',
      expectedResult: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678930',
          },
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678931',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678932',
          },
          {
            login: 'test4@mail.com',
            deviceToken: '01234567890123456789012345678933',
          },
        ],
      },
    },
    {
      currentIdentities: {
        SBIP2: [
          { login: 'test@mail.com' },
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678936',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678937',
          },
          {
            login: 'test4@mail.com',
            deviceToken: '01234567890123456789012345678938',
          },
        ],
      },
      profile: {
        login: 'test@mail.com',
        deviceToken: '01234567890123456789012345678930',
      },
      partnerCookieIdentityName: 'f55',
      expectedResult: {
        SBIP2: [
          { login: 'test@mail.com' },
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678936',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678937',
          },
          {
            login: 'test4@mail.com',
            deviceToken: '01234567890123456789012345678938',
          },
        ],
        f55: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678930',
          },
        ],
      },
    },
    {
      currentIdentities: {
        SBIP2: [
          { login: 'test@mail.com' },
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678940',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678941',
          },
          {
            login: 'test4@mail.com',
            deviceToken: '01234567890123456789012345678942',
          },
        ],
      },
      profile: {
        login: 'test@mail.com',
        color: 'testColor',
        deviceToken: '01234567890123456789012345678930',
      },
      partnerCookieIdentityName: 'SBIP2',
      expectedResult: {
        SBIP2: [
          {
            login: 'test@mail.com',
            color: 'testColor',
            deviceToken: '01234567890123456789012345678930',
          },
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678940',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678941',
          },
          {
            login: 'test4@mail.com',
            deviceToken: '01234567890123456789012345678942',
          },
        ],
      },
    },
  ].forEach(
    ({
      currentIdentities,
      profile,
      partnerCookieIdentityName,
      expectedResult,
    }) => {
      it(`currentIdentities '${currentIdentities}', profile '${profile}', and partnerCookieIdentityName '${partnerCookieIdentityName}' should return '${expectedResult}'`, () => {
        expect(
          helpers.createIdentitiesCookie(
            currentIdentities,
            profile,
            partnerCookieIdentityName,
          ),
        ).toEqual(expectedResult);
      });
    },
  );
});

describe('deleteIdentityCookie()', () => {
  [
    {
      currentIdentities: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678930',
          },
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678931',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678932',
          },
          {
            login: 'test4@mail.com',
            deviceToken: '01234567890123456789012345678933',
          },
        ],
      },
      identity: { login: 'test@mail.com' },
      partnerCookieIdentityName: 'SBIP2',
      expectedResult: {
        SBIP2: [
          {
            login: 'test2@mail.com',
            deviceToken: '01234567890123456789012345678931',
          },
          {
            login: 'test3@mail.com',
            deviceToken: '01234567890123456789012345678932',
          },
          {
            login: 'test4@mail.com',
            deviceToken: '01234567890123456789012345678933',
          },
        ],
      },
    },
    {
      currentIdentities: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678935',
          },
        ],
      },
      identity: { login: 'test@mail.com' },
      partnerCookieIdentityName: 'SBIP2',
      expectedResult: { SBIP2: [] },
    },
    {
      currentIdentities: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678930',
          },
        ],
        f55: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678931',
          },
        ],
      },
      identity: {
        login: 'test@mail.com',
        deviceToken: '01234567890123456789012345678941',
      },
      partnerCookieIdentityName: 'f55',
      expectedResult: {
        SBIP2: [
          {
            login: 'test@mail.com',
            deviceToken: '01234567890123456789012345678930',
          },
        ],
        f55: [],
      },
    },
    {
      currentIdentities: {},
      identity: { login: 'test@mail.com' },
      partnerCookieIdentityName: 'SBIP2',
      expectedResult: { SBIP2: [] },
    },
  ].forEach(
    ({
      currentIdentities,
      identity,
      partnerCookieIdentityName,
      expectedResult,
    }) => {
      it(`currentIdentities '${currentIdentities}', identity '${identity}' and partnerCookieIdentityName '${partnerCookieIdentityName}' should return '${expectedResult}'`, () => {
        expect(
          helpers.deleteIdentityCookie(
            currentIdentities,
            identity,
            partnerCookieIdentityName,
          ),
        ).toEqual(expectedResult);
      });
    },
  );
});

describe('getDeviceToken', () => {
  v4.mockImplementation(() => 'd83b-a6fedc1-2483-1bc049a-c781-3c6384');
  [
    {
      rememberMe: true,
      identities: [],
      result: 'd83ba6fedc124831bc049ac7813c6384',
    },
    {
      rememberMe: false,
      identities: [],
      result: null,
    },
    {
      rememberMe: true,
      identities: [
        {
          login: 'test@bnc.ca',
          deviceToken: 'd83ba6fedc124831bc049ac7813c6100',
        },
        { login: 'test2@bnc.ca' },
      ],
      username: 'test@bnc.ca',
      result: 'd83ba6fedc124831bc049ac7813c6100',
    },
    {
      rememberMe: true,
      identities: [{ login: 'test@bnc.ca' }, { login: 'test2@bnc.ca' }],
      username: 'test@bnc.ca',
      result: 'd83ba6fedc124831bc049ac7813c6384',
    },
    {
      rememberMe: true,
      identities: [{ login: 'test@bnc.ca' }, { login: 'test2@bnc.ca' }],
      username: 'test3@bnc.ca',
      result: 'd83ba6fedc124831bc049ac7813c6384',
    },
  ].forEach(({ rememberMe, identities, username, result }) => {
    it(`should has value ${result} when rememberMe ${rememberMe}, identities=${identities}, username=${username}`, () => {
      expect(helpers.getDeviceToken(rememberMe, identities, username)).toEqual(
        result,
      );
    });
  });
});

describe('loginService helper - hashLogin', () => {
  it('should return a hash value containing only one colon for login input', () => {
    const result = hashLogin('testEmail', 'testPassword');
    expect(result).toMatch(/^[a-zA-Z0-9+=/]*[:][a-zA-Z0-9+=/]*$/);
    expect(result).toEqual('dGVzdEVtYWls:dGVzdFBhc3N3b3Jk');
  });

  it('should return a value containing only one colon for empty password input', () => {
    const result = hashLogin('testEmail', '');
    expect(result).toMatch(/^[a-zA-Z0-9+=/]*[:][a-zA-Z0-9+=/]*$/);
    expect(result).toEqual('dGVzdEVtYWls:');
  });

  it('should return null', () => {
    const result = hashLogin('', '');
    expect(result).toEqual(null);
  });

  it('should return null value for undefined login input', () => {
    const result = hashLogin(undefined, undefined);
    expect(result).toEqual(null);
  });

  it('should return null value for null login input', () => {
    const result = hashLogin(null, null);
    expect(result).toEqual(null);
  });
});

describe('getRememberMe', () => {
  [
    {
      rememberMe: false,
      identities: [],
      result: false,
    },
    {
      rememberMe: true,
      identities: [],
      result: true,
    },
    {
      rememberMe: false,
      identities: [{ login: 'test@bnc.ca' }, { login: 'test2@bnc.ca' }],
      username: 'test@bnc.ca',
      result: false,
    },
    {
      rememberMe: true,
      identities: [
        { login: 'test@bnc.ca' },
        { login: 'test2@bnc.ca' },
        { login: 'test3@bnc.ca' },
        { login: 'test4@bnc.ca' },
      ],
      username: 'test2@bnc.ca',
      result: true,
    },
    {
      rememberMe: false,
      identities: [
        { login: 'test@bnc.ca' },
        { login: 'test2@bnc.ca' },
        { login: 'test3@bnc.ca' },
        { login: 'test4@bnc.ca' },
        { login: 'test5@bnc.ca' },
      ],
      username: 'test6@bnc.ca',
      result: false,
    },
  ].forEach(({ rememberMe, identities, username, result }) => {
    it(`should have value ${result} when rememberMe ${rememberMe}, identities=${identities}, username=${username}`, () => {
      expect(helpers.getRememberMe(rememberMe, identities, username)).toEqual(
        result,
      );
    });
  });
});

describe('getBneDeviceToken', () => {
  v4.mockImplementation(() => 'd83b-a6fedc1-2483-1bc049a-c781-3c6384');
  [
    {
      identities: [],
      result: 'd83ba6fedc124831bc049ac7813c6384',
    },
    {
      identities: [
        {
          login: 'test@bnc.ca',
          deviceToken: 'd83ba6fedc124831bc049ac7813c6100',
        },
        { login: 'test2@bnc.ca' },
      ],
      username: 'test@bnc.ca',
      result: 'd83ba6fedc124831bc049ac7813c6100',
    },
    {
      identities: [{ login: 'test@bnc.ca' }, { login: 'test2@bnc.ca' }],
      username: 'test@bnc.ca',
      result: 'd83ba6fedc124831bc049ac7813c6384',
    },
    {
      identities: [{ login: 'test@bnc.ca' }, { login: 'test2@bnc.ca' }],
      username: 'test3@bnc.ca',
      result: 'd83ba6fedc124831bc049ac7813c6384',
    },
  ].forEach(({ identities, username, result }) => {
    it(`should has value ${result} when identities=${identities}, username=${username}`, () => {
      expect(helpers.getBneDeviceToken(identities, username)).toEqual(result);
    });
  });
});

describe('removeIdentitiesCookie', () => {
  test('removeIdentitiesCookie', () => {
    removeIdentitiesCookie();
    expect(cookie.remove).toBeCalled();
  });
});
