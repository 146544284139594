// @flow
import * as actionTypes from './actionTypes';
import type { Action as ReduxAction } from '../../types/action';

export type TokenCaptcha = {
  accessToken: string,
  scope: string,
  expiresIn: number,
  expirationTime?: Date,
};

export type TokenCaptchaRequestAction = ReduxAction<
  typeof actionTypes.TOKEN_CAPTCHA_REQUEST,
> & {
  recaptcha: string,
  nbfgId: string,
};

export type TokenCaptchaRequestSuccessAction = ReduxAction<
  typeof actionTypes.TOKEN_CAPTCHA_REQUEST_SUCCESS,
> & {
  token: TokenCaptcha,
};

export type TokenCaptchaRequestFailureAction = ReduxAction<
  typeof actionTypes.TOKEN_CAPTCHA_REQUEST_FAILURE,
> & {};

export type TokenCaptchaResetAction = ReduxAction<
  typeof actionTypes.TOKEN_CAPTCHA_RESET,
> & {};

export type TokenCaptchaState = {|
  token: TokenCaptcha | null,
  failure: boolean,
|};

export type Action =
  | TokenCaptchaRequestAction
  | TokenCaptchaRequestSuccessAction
  | TokenCaptchaRequestFailureAction
  | TokenCaptchaResetAction;
