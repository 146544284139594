import { call, select } from 'redux-saga/effects';
import { trackAction } from '@nbc-studio/analytics';
import {
  EVENT_IDS,
  EVENT_NAMES,
  EVENT_SOURCE,
  PAGES,
  UCS_IDS,
  UCS_STATE,
} from '../../utils/taggingUtils/constants';
import * as reducers from '../../globalRedux/reducers/constants';
import TEMPLATES from '../../utils/constants/template';

export const UCS_ID_MFA = UCS_IDS.LOGIN_0001;

export function* watchTrackResendCodeSucess(action) {
  const { templateName } = (yield select())[reducers.TEMPLATE_SERVICE_REDUCER];

  if (templateName === TEMPLATES.SBIP2) {
    yield call(trackAction, EVENT_NAMES.CLICK_CTA, {
      ucsId: UCS_ID_MFA,
      eventId: EVENT_IDS.CCTA_MFA,
      eventName: EVENT_NAMES.CLICK_CTA,
      eventSource: EVENT_SOURCE.SBIP2,
      eventSourceDetail: PAGES.LOGIN,
      ucsState: UCS_STATE.NON_PV,
      interaction: `resent security code:${action.selectedFactor}`,
    });
  }
}
