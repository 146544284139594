// @flow
import * as actionTypes from './actionTypes';

import type {
  ResetPasswordRequestAction,
  ResetPasswordFailureAction,
} from './types';

export function resetPasswordRequest(
  newPassword: string,
  passwordIsExpired: boolean,
  currentPassword: string,
): ResetPasswordRequestAction {
  return {
    type: actionTypes.RESET_PASSWORD_REQUEST,
    passwordIsExpired,
    newPassword,
    currentPassword,
  };
}

export function resetPasswordSuccess(): ResetPasswordFailureAction {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
  };
}

export function resetPasswordFailure(error: any): ResetPasswordFailureAction {
  return {
    type: actionTypes.RESET_PASSWORD_FAILURE,
    error,
  };
}

export function setDtmType(dtmType: ?string) {
  return {
    type: actionTypes.RESET_PASSWORD_SET_DTM_TYPE,
    dtmType,
  };
}
