// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Close from './close';

import type { Dispatch } from '../../../types/store';

import { cannotContinueWithUnsupportedBrowser } from '../../../services/unsupportedBrowserService/actions';

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    cannotContinueWithUnsupportedBrowser: () =>
      dispatch(cannotContinueWithUnsupportedBrowser()),
  };
}

export default injectIntl(connect(null, mapDispatchToProps)(Close));
