import React from 'react';

import Right2ColumnsTemplate from '../../models/Right2ColumnsTemplate';

import Header from '../../../organisms/common/Header';
import LoginObe from '../../../organisms/obe/Login';
import ExpirationModal from '../../../organisms/common/ExpirationModal';

import BncMedia from '../../../molecules/common/BncMedia';

const LoginTemplate = () => (
  <Right2ColumnsTemplate
    header={<Header logoTagName="h1" />}
    main={<LoginObe />}
    assets={<BncMedia />}
    modal={<ExpirationModal />}
  />
);

export default LoginTemplate;
