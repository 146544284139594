// @flow
import { testSaga } from 'bnc-utilities-js/testUtils/saga';
import { BNC_AUTH_CHANGE_PASSWORD_REQUEST } from 'bnc-react-services/services/AuthService/actionTypes';
import { TRANSACTION_STATUS } from 'bnc-react-services/managers/AuthManager/constants';
import sessionManager from 'bnc-react-services/managers/SessionManager';
import AuthManager from 'bnc-react-services/managers/AuthManager';

import { ANNOUNCE_ALERT_MESSAGE } from 'bnc-react-services/services/LiveAnnouncerService/actionTypes';

import {
  callbackAnimateLoginFlowDone,
  watchBncAuthResetPasswordCompleted,
  watchBncAuthResetPasswordFailure,
  watchChangeRoute,
  watchResetPasswordRequest,
} from '../workers';
import { RESET_PASSWORD_FAILURE, RESET_PASSWORD_SUCCESS } from '../actionTypes';
import {
  LOGIN_FLOW_SUCCESS,
  LOGIN_SET_RETURN_SBIP_LOGIN_FLAG,
} from '../../loginService/actionTypes';
import {
  I18N_SERVICE_CHANGE_LOCALE_EXT,
  I18N_SERVICE_PROTECT_CHANGE_LOCALE,
} from '../../i18nService/actionTypes';
import ROUTES from '../../../utils/constants/routes';
import TEMPLATES from '../../../utils/constants/template';
import { TEMPLATE_SERVICE_REDUCER } from '../../../globalRedux/reducers/constants';
import { EXCEPTION_TYPES } from '../../../utils/constants/forgotPassword';
import { FORGOT_PASSWORD_TRIGGER_EXCEPTION } from '../../forgotPasswordService/actionTypes';

jest.mock('bnc-react-services/managers/SessionManager', () => ({
  getTransactionStatus: jest.fn(),
}));

jest.mock('bnc-react-services/managers/AuthManager', () => ({
  getStateToken: jest.fn(),
}));

test('.callbackAnimateLoginFlowDone', async () => {
  const { sagaDone } = testSaga({
    saga: callbackAnimateLoginFlowDone,
  });

  const { actions } = await sagaDone;

  expect(actions).toEqual([
    {
      type: LOGIN_FLOW_SUCCESS,
    },
  ]);
});

test('.watchBncAuthResetPasswordFailure', async () => {
  const error = 'something';

  const { sagaDone } = testSaga({
    saga: watchBncAuthResetPasswordFailure,
    args: [{ error }],
  });

  const { actions } = await sagaDone;

  expect(actions).toEqual([
    {
      type: RESET_PASSWORD_FAILURE,
      error,
    },
  ]);
});

test('.watchResetPasswordRequest', async () => {
  const passwordIsExpired = true;
  const newPassword = 'mbappe123';
  const currentPassword = 'neymar123';

  const { sagaDone } = testSaga({
    saga: watchResetPasswordRequest,
    args: [
      {
        passwordIsExpired,
        newPassword,
        currentPassword,
      },
    ],
  });

  const { actions } = await sagaDone;

  expect(actions).toEqual([
    {
      type: BNC_AUTH_CHANGE_PASSWORD_REQUEST,
      passwordIsExpired,
      newPassword,
      currentPassword,
    },
  ]);
});

describe('watchChangeRoute', () => {
  const templateName = TEMPLATES.SBIP2;
  const partnerId = '';
  test('.watchChangeRoute - ResetPassword - FR', async () => {
    const payload = {
      pathname: ROUTES.RESETPASSWORD,
      query: {
        lang: 'fr',
      },
    };

    const { sagaDone } = testSaga({
      saga: watchChangeRoute,
      args: [{ payload }],
      state: { [TEMPLATE_SERVICE_REDUCER]: { templateName, partnerId } },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: I18N_SERVICE_CHANGE_LOCALE_EXT,
        locale: payload.query.lang,
      },
      {
        type: I18N_SERVICE_PROTECT_CHANGE_LOCALE,
      },
      {
        type: LOGIN_SET_RETURN_SBIP_LOGIN_FLAG,
      },
    ]);
  });
  test('.watchChangeRoute - ResetPassword - No Lang - Password Reset', async () => {
    sessionManager.getTransactionStatus.mockReturnValueOnce(
      TRANSACTION_STATUS.PASSWORD_RESET,
    );
    const payload = {
      pathname: ROUTES.RESETPASSWORD,
      query: {},
    };

    const { sagaDone } = testSaga({
      saga: watchChangeRoute,
      args: [{ payload }],
      state: { [TEMPLATE_SERVICE_REDUCER]: { templateName, partnerId } },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: LOGIN_SET_RETURN_SBIP_LOGIN_FLAG,
      },
      {
        type: FORGOT_PASSWORD_TRIGGER_EXCEPTION,
        exceptionType: EXCEPTION_TYPES.CALL_VSD,
      },
    ]);
  });
  test('.watchChangeRoute - ResetPassword - No Lang - Catch', async () => {
    sessionManager.getTransactionStatus.mockReturnValueOnce('');
    AuthManager.getStateToken.mockImplementationOnce(() => {
      throw new Error();
    });
    const payload = {
      pathname: ROUTES.RESETPASSWORD,
      query: {},
    };

    const { sagaDone } = testSaga({
      saga: watchChangeRoute,
      args: [{ payload }],
      state: { [TEMPLATE_SERVICE_REDUCER]: { templateName, partnerId } },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: LOGIN_SET_RETURN_SBIP_LOGIN_FLAG,
      },
      {
        type: FORGOT_PASSWORD_TRIGGER_EXCEPTION,
        exceptionType: EXCEPTION_TYPES.GENERIC,
      },
    ]);
  });
  test('.watchChangeRoute - ResetPassword - No Lang', async () => {
    sessionManager.getTransactionStatus.mockReturnValueOnce('');
    AuthManager.getStateToken.mockReturnValueOnce('nlciwwevnoweno');
    const payload = {
      pathname: ROUTES.RESETPASSWORD,
      query: {},
    };

    const { sagaDone } = testSaga({
      saga: watchChangeRoute,
      args: [{ payload }],
      state: { [TEMPLATE_SERVICE_REDUCER]: { templateName, partnerId } },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: LOGIN_SET_RETURN_SBIP_LOGIN_FLAG,
      },
    ]);
  });
  test('.watchChangeRoute - Password Change Success', async () => {
    const payload = {
      pathname: ROUTES.PASSWORD_CHANGE_SUCCESS,
    };

    const { sagaDone } = testSaga({
      saga: watchChangeRoute,
      args: [{ payload }],
      state: { [TEMPLATE_SERVICE_REDUCER]: { templateName, partnerId } },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: ANNOUNCE_ALERT_MESSAGE,
        politeness: 'assertive',
        message: 'text.message.passwordChanged',
      },
    ]);
  });
  test('.watchChangeRoute - Other Route', async () => {
    const payload = {
      pathname: ROUTES.ALL,
    };

    const { sagaDone } = testSaga({
      saga: watchChangeRoute,
      args: [{ payload }],
      state: { [TEMPLATE_SERVICE_REDUCER]: { templateName, partnerId } },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([]);
  });
});

test('.watchBncAuthResetPasswordCompleted', async () => {
  const { sagaDone } = testSaga({
    saga: watchBncAuthResetPasswordCompleted,
  });

  const { actions } = await sagaDone;

  expect(actions).toEqual([
    {
      type: RESET_PASSWORD_SUCCESS,
    },
  ]);
});
