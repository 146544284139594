// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';
import cx from 'classnames';

import { Button } from '@bnc-ui/button';
import { Heading } from '@bnc-ui/heading';
import { Text } from '@bnc-ui/text';

import './style.scss';

type Props = {
  intl: IntlShape,
  className?: string,
  isVisible?: boolean,
  loginRouteRequestSignUp: () => void,
};

const defaultProps = {
  className: '',
  isVisible: true,
};

function SignUpMigration(props: Props) {
  const { className, isVisible, intl, loginRouteRequestSignUp } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div className={cx('sign-up', className)}>
      <Heading type="h2" tabIndex={0}>
        {intl.formatMessage({ id: 'text.title.inviteToMigrate' })}
      </Heading>
      <Text className={cx('sign-up__subtitle', className)} tabIndex={0}>
        {intl.formatMessage({ id: 'text.message.inviteToMigrate' })}
      </Text>
      <Button
        onClick={loginRouteRequestSignUp}
        size="medium"
        appearance="secondary"
      >
        {intl.formatMessage({ id: 'text.button.goToMigrate' })}
      </Button>
    </div>
  );
}

SignUpMigration.defaultProps = defaultProps;

export default SignUpMigration;
