import type { IntlShape } from 'react-intl';

import type { LoginFormErrors } from '../../types/forms/loginForm';
import type { SDKError } from '../../types';

import { getFirstErrorKey } from '../formUtils';
import { getAuthErrorMessageId } from '../authErrorMessages';
import { getMessageKey } from '../templateUtils';

export const getErrorMessage = (
  hasValidationError: boolean,
  hasLoginFailure: boolean,
  errors: LoginFormErrors,
  sdkError: SDKError,
  templateName: string,
  intl: IntlShape,
): { message: string, type: string } => {
  if (hasValidationError) {
    if (errors.identityBne != null) {
      if (
        errors.identityBne.indexOf('identity-enterprise-error-msg') > -1 &&
        errors.identityBne.indexOf('identity-error-msg') < 0
      ) {
        return {
          message: intl.formatMessage({
            id: `text.error.invalididentityBneEnterprise`,
          }),
          type: 'error',
        };
      }

      if (errors.identityBne != null && errors.passwordBne != null) {
        return {
          message: intl.formatMessage({
            id: `text.error.invalidcreditentialsBne`,
          }),
          type: 'error',
        };
      }
    }
    return {
      message: intl.formatMessage({
        id: `text.error.invalid${getFirstErrorKey(errors)}`,
      }),
      type: 'error',
    };
  }
  if (hasLoginFailure) {
    return {
      message: intl.formatMessage(
        {
          id: getMessageKey(
            templateName,
            `auth.error.${getAuthErrorMessageId(sdkError)}`,
          ),
        },
        { code: sdkError.code },
      ),
      type: 'error',
    };
  }
  return { message: null, type: null };
};

export const clearLoginErrorMessageIfNecessary = (
  hasValidationError: boolean,
  hasLoginFailure: boolean,
  clearLoginErrorMessages: () => void,
) => {
  if (hasValidationError && hasLoginFailure) {
    clearLoginErrorMessages();
  }
};
