// @flow
import type { Saga } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { changePasswordRequest } from 'bnc-react-services/services/AuthService/actions';
import propagateGlobalErrors from 'bnc-react-services/utils/propagateErrorsHandler';
import { TRANSACTION_STATUS } from 'bnc-react-services/managers/AuthManager/constants';
import AuthManager from 'bnc-react-services/managers/AuthManager';
import requestsManager from 'bnc-react-services/managers/RequestsManager';
import sessionManager from 'bnc-react-services/managers/SessionManager';
import { announceAlertMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';

import browserHistory from '../../nav/BrowserHistoryManager';

import type {
  ResetPasswordRequestAction,
  BncAuthChangePasswordFailureAction,
} from './types';
import type { LocationChangeAction } from '../../types';
import { changeLocaleExt, protectChangeLocale } from '../i18nService/actions';
import { resetPasswordFailure, resetPasswordSuccess } from './actions';
import {
  loginFlowSuccess,
  setReturnToSbipLoginFlag,
} from '../loginService/actions';
import ROUTES from '../../utils/constants/routes';
import { buildPath } from '../../nav/Router/routerHelper';
import {
  getTemplateName,
  getPartnerIdName,
} from '../templateService/selectors';
import { triggerException } from '../forgotPasswordService/actions';
import { EXCEPTION_TYPES } from '../../utils/constants/forgotPassword';

// Imported from LoginService since all MFA logic is handled there
// import { createTaskTriggerDisplayResend } from '../loginService';

export function* callbackAnimateLoginFlowDone(): Saga<void> {
  yield put(loginFlowSuccess());
}

export function* watchChangeRoute(action: LocationChangeAction): Saga<void> {
  // watching LOCATION_CHANGE from react-router-redux = forced to use action.payload
  const templateName = yield select(getTemplateName);
  const partnerId = yield select(getPartnerIdName);
  if (
    action.payload.pathname ===
    buildPath(ROUTES.RESETPASSWORD, templateName, partnerId)
  ) {
    // Watch for a lang parameter
    if (action.payload.query.lang) {
      yield put(changeLocaleExt(action.payload.query.lang));
      yield put(protectChangeLocale());
    }
    yield put(setReturnToSbipLoginFlag());

    // Coming from login with a PASSWORD_RESET status
    if (
      sessionManager.getTransactionStatus() ===
      TRANSACTION_STATUS.PASSWORD_RESET
    ) {
      // Redirect to error page if we don't have this specific status
      yield put(triggerException(EXCEPTION_TYPES.CALL_VSD));
    } else {
      try {
        AuthManager.getStateToken();
      } catch (e) {
        // throws an exception if stateToken not set
        yield put(triggerException(EXCEPTION_TYPES.GENERIC));
      }
    }
  }
  if (
    action.payload.pathname ===
    buildPath(ROUTES.RESET_PASSWORD_EXCEPTION, templateName, partnerId)
  ) {
    yield put(setReturnToSbipLoginFlag());
  }
  if (
    action.payload.pathname ===
    buildPath(ROUTES.PASSWORD_CHANGE_SUCCESS, templateName, partnerId)
  ) {
    yield put(announceAlertMessage('text.message.passwordChanged'));
  }
}

export function* watchResetPasswordRequest(
  action: ResetPasswordRequestAction,
): Saga<void> {
  try {
    yield put(
      changePasswordRequest(
        action.passwordIsExpired,
        action.newPassword,
        action.currentPassword,
      ),
    );
  } catch (e) {
    yield requestsManager.call(propagateGlobalErrors, e);
  }
}

export function* watchBncAuthResetPasswordFailure(
  action: BncAuthChangePasswordFailureAction,
): Saga<void> {
  yield put(resetPasswordFailure(action.error));
}

export function* watchBncAuthResetPasswordCompleted() {
  yield put(resetPasswordSuccess());
  browserHistory.push(ROUTES.PASSWORD_CHANGE_SUCCESS);
}
