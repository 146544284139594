// @flow
import * as actionTypes from './actionTypes';

import type { Action, TokenCaptchaState } from './types';

export const initialState = (): TokenCaptchaState => ({
  token: null,
  failure: false,
});

export default (state: TokenCaptchaState = initialState(), action: Action) => {
  switch (action.type) {
    case actionTypes.TOKEN_CAPTCHA_REQUEST:
      return {
        ...state,
        failure: false,
      };
    case actionTypes.TOKEN_CAPTCHA_REQUEST_SUCCESS:
      return {
        ...state,
        token: action.token,
      };
    case actionTypes.TOKEN_CAPTCHA_REQUEST_FAILURE:
      return {
        ...state,
        token: null,
        failure: true,
      };
    case actionTypes.TOKEN_CAPTCHA_RESET:
      return initialState();
    default:
      return state;
  }
};
