import { createSelector } from 'reselect';
import get from 'lodash/get';

import getReducerState from '../../utils/stateSelectorHelper';

import { MFA_FORM_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';

export const getMFAFormState = (state, modulePath = '') =>
  getReducerState(state, modulePath, MFA_FORM_SERVICE_REDUCER);

export const isHideResend = createSelector(getMFAFormState, mfaFormState =>
  get(mfaFormState, 'hideResend', false),
);

export const getFactorTypes = createSelector(getMFAFormState, mfaFormState =>
  get(mfaFormState, 'factorTypes', []),
);

export const getSelectedFactor = createSelector(getMFAFormState, mfaFormState =>
  get(mfaFormState, 'selectedFactor', ''),
);

export const getContact = createSelector(getMFAFormState, mfaFormState =>
  get(mfaFormState, 'contact', ''),
);

export const getMFAFormData = createSelector(getMFAFormState, mfaFormState =>
  get(mfaFormState, 'formData', {}),
);

export const getMFAFormErrors = createSelector(getMFAFormState, mfaFormState =>
  get(mfaFormState, 'errors', {}),
);

export const getMFAExecutedAction = createSelector(
  getMFAFormState,
  mfaFormState => get(mfaFormState, 'executedAction', {}),
);

export const isAuthFormDone = createSelector(getMFAFormState, initFormState =>
  get(initFormState, 'initDone', false),
);
