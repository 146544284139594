// @flow
import React from 'react';

type Props = {
  className?: string,
  message: string,
};

const defaultProps = {
  className: '',
};

const Paragraph = (props: Props) => {
  const { className, message } = props;
  return <p className={className}>{message}</p>;
};

Paragraph.defaultProps = defaultProps;

export default Paragraph;
