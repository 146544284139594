import getTimePeriod from 'bnc-utilities-js/date/getTimePeriod';
import { MOMENT_TYPES } from 'bnc-utilities-js/date/constants';

const getGreetingsStringId = () => {
  const momentType = getTimePeriod();
  let greetingsStringId = '';
  switch (momentType) {
    case MOMENT_TYPES.AFTERNOON:
      greetingsStringId = 'text.title.afternoonGreetings';
      break;
    case MOMENT_TYPES.EVENING:
      greetingsStringId = 'text.title.eveningGreetings';
      break;
    case MOMENT_TYPES.MORNING:
      greetingsStringId = 'text.title.morningGreetings';
      break;
    default:
      greetingsStringId = 'text.title.afternoonGreetings';
      break;
  }
  return greetingsStringId;
};
export default getGreetingsStringId;
