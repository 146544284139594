// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import type { State } from '../../../types/state';

import ErrorModal from './errorModal';
import {
  errorSelector,
  biometrySelector,
} from '../../../services/biometryService/selectors';
import userAgentSelectors from '../../../services/userAgentService/selectors';
import { hasLoginFailed } from '../../../services/loginService/selectors';
import type { Dispatch } from '../../../types/store';
import type { BiometryError } from '../../../services/biometryService/types';
import { biometryForceLogin } from '../../../services/biometryService/actions';

function mapStateToProps(state: State) {
  const error: BiometryError = errorSelector(state);

  return {
    error,
    os: userAgentSelectors.getOS(state),
    biometry: biometrySelector(state),
    isLoginFailure: hasLoginFailed(state),
    isShow: (error !== null && error.openInModal) || hasLoginFailed(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  forceLogin: () => dispatch(biometryForceLogin()),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ErrorModal),
);
