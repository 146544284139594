import React from 'react';

import Right2ColumnsTemplate from '../models/Right2ColumnsTemplate';

import HeaderEnterprise from '../../../organisms/common/HeaderEnterprise';
import LoginEnterprise from '../../../organisms/bne/Login';
import ExpirationModal from '../../../organisms/common/ExpirationModal';
import LoginFooter from '../../../organisms/bne/LoginFooter';

import BncMediaEnterprise from '../../../molecules/common/BncMediaEnterprise';

import './style.scss';

const LoginTemplate = () => (
  <Right2ColumnsTemplate
    className="container--enterprise"
    header={<HeaderEnterprise />}
    main={<LoginEnterprise />}
    footer={<LoginFooter />}
    assets={<BncMediaEnterprise />}
    modal={<ExpirationModal />}
  />
);

export default LoginTemplate;
