/**
 * Ad-hoc time format.
 *
 * @param {*} intl intl object
 * @param {*} time time string
 *
 * @return formatted time
 */
export default function customTimeFormat(intl, time) {
  switch (intl.locale.toLowerCase()) {
    case 'en':
      return intl
        .formatTime(time)
        .replace(/^0+/, '')
        .replace('AM', 'a.m.')
        .replace('PM', 'p.m.');
    case 'fr': {
      const terms = intl.formatTime(time).split(':');
      return `${removeLeadingZeros(terms[0])} h ${terms[1]}`;
    }
    default:
      return intl.formatTime(time);
  }
}

const removeLeadingZeros = x => {
  const term = x.replace(/^0+/, '');
  return term === '' ? '00' : term;
};
