// @flow

const TEMPLATES = {
  SBIP2: 'SBIP2',
  NATGO: 'NATGO',
  SECURE_KEY: 'SECURE_KEY',
  SBIP2_WHITE_LABEL: 'SBIP2_WHITE_LABEL',
  BNE: 'BNE',
  REWARDS: 'REWARDS',
  REWARDS_WHITE_LABEL: 'REWARDS_WHITE_LABEL',
  BAO: 'BAO',
  OBE: 'OBE',
  ORION_WHITE_LABEL: 'ORION_WHITE_LABEL',
};
export default TEMPLATES;
