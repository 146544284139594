// @flow
import get from 'lodash/get';
import has from 'lodash/has';

import cmp from 'semver-compare';
import openExternal from 'bnc-utilities-js/url/openExternal';
import type { Browser, OS } from '../types/browser';

import { Configs } from '../configs';
import { hasFeature } from './featureUtils';

export const isBrowserSupported = (browser: Browser) => {
  if (Configs.SUPPORTED_BROWSER_VERSIONS[browser.name]) {
    return (
      cmp(
        browser.version,
        Configs.SUPPORTED_BROWSER_VERSIONS[browser.name].version,
      ) >= 0
    );
  }
  return false;
};

export const isBrowserSupportedRegardlessOfVersion = (browser: Browser) => {
  const browserVersions = Configs.SUPPORTED_BROWSER_VERSIONS;
  return has(browserVersions, browser.name);
};

export const IE11_BROWSER_NAME = 'IE';

export const getCompatibleBrowserList = (browser: Browser, os: OS) => {
  const supportedBrowserList = [];
  const browserVersions = Configs.SUPPORTED_BROWSER_VERSIONS;
  const osSupported = Configs.OS_SUPPORTED;

  if (browserVersions[browser.name] && browser.name !== IE11_BROWSER_NAME) {
    supportedBrowserList.push(browser);
  } else {
    if (has(browserVersions, 'Chrome')) {
      supportedBrowserList.push(browserVersions.Chrome);
    }

    if (has(browserVersions, 'Firefox')) {
      supportedBrowserList.push(browserVersions.Firefox);
    }

    if (
      (os.name === get(osSupported, 'IOS.name') ||
        os.name === get(osSupported, 'MAC_OS.name')) &&
      has(browserVersions, 'Safari')
    ) {
      supportedBrowserList.push(browserVersions.Safari);
    }

    if (
      os.name === get(osSupported, 'WINDOWS.name') &&
      os.version >= get(osSupported, 'WINDOWS.version') &&
      has(browserVersions, 'Edge')
    ) {
      supportedBrowserList.push(browserVersions.Edge);
    }
  }
  return supportedBrowserList;
};

export const getCurrentQueryString = () => document.location.search;

export const isIE11 = () =>
  !!window.MSInputMethodContext && !!document.documentMode;

export const hasUnsupportedBrowserFeature = (templateName: string) =>
  hasFeature(templateName, 'UNSUPPORTED_BROWSER');

export const isWebView = () =>
  !!(window.ReactNativeWebView && window.ReactNativeWebView.postMessage);

export function sendMessage(message) {
  if (isWebView()) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  }
}

export const openWebUrl = url => {
  const urlJSON = {
    actionType: 'openExternalLink',
    payload: {
      externalLink: url,
    },
  };

  if (isWebView()) {
    // web view (native app)
    sendMessage(urlJSON);
  } else {
    // classic web
    openExternal(url, '_blank');
  }
};
