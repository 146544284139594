import get from 'lodash/get';

import getReducerState from '../../utils/stateSelectorHelper';

import { USER_AGENT_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';

const getUserAgentState = (state, modulePath = '') =>
  getReducerState(state, modulePath, USER_AGENT_SERVICE_REDUCER);

const getBrowserName = (state, modulePath = '') =>
  get(
    getReducerState(state, modulePath, USER_AGENT_SERVICE_REDUCER),
    `browser.name`,
    '',
  );

const getBrowserVersion = (state, modulePath = '') =>
  get(
    getReducerState(state, modulePath, USER_AGENT_SERVICE_REDUCER),
    `browser.version`,
    '',
  );

const getOS = (state, modulePath = '') =>
  get(getReducerState(state, modulePath, USER_AGENT_SERVICE_REDUCER), `os`, {});

export default {
  getUserAgentState,
  getBrowserName,
  getBrowserVersion,
  getOS,
};
