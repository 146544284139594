// @flow
export const MULTI_FACTOR_AUTH_FORM_VALIDATION_REQUEST =
  'MULTI_FACTOR_AUTH_FORM_VALIDATION_REQUEST';
export const MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE =
  'MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE';
export const MULTI_FACTOR_AUTH_FORM_VALIDATION_SUCCESS =
  'MULTI_FACTOR_AUTH_FORM_VALIDATION_SUCCESS';
export const MULTI_FACTOR_AUTH_FORM_UPDATE = 'MULTI_FACTOR_AUTH_FORM_UPDATE';
export const MULTI_FACTOR_AUTH_FORM_UPDATE_SUCCESS =
  'MULTI_FACTOR_AUTH_FORM_UPDATE_SUCCESS';
export const MULTI_FACTOR_AUTH_FORM_INIT_REQUEST =
  'MULTI_FACTOR_AUTH_FORM_INIT_REQUEST';
export const MULTI_FACTOR_AUTH_FORM_INIT_DONE =
  'MULTI_FACTOR_AUTH_FORM_INIT_DONE';
export const MULTI_FACTOR_AUTH_FORM_SUBMIT_REQUEST =
  'MULTI_FACTOR_AUTH_FORM_SUBMIT_REQUEST';
export const MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE =
  'MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE';
export const MULTI_FACTOR_AUTH_FORM_SUBMIT_SUCCESS =
  'MULTI_FACTOR_AUTH_FORM_SUBMIT_SUCCESS';

export const MULTI_FACTOR_AUTH_FORM_TRIGGER_SEND_CODE_REQUEST =
  'MULTI_FACTOR_AUTH_FORM_TRIGGER_SEND_CODE_REQUEST';

export const MULTI_FACTOR_AUTH_FORM_TRIGGER_RESEND_CODE_REQUEST =
  'MULTI_FACTOR_AUTH_FORM_TRIGGER_RESEND_CODE_REQUEST';

export const MULTI_FACTOR_AUTH_DISPLAY_RESEND =
  'MULTI_FACTOR_AUTH_DISPLAY_RESEND';
export const MULTI_FACTOR_AUTH_HIDE_RESEND = 'MULTI_FACTOR_AUTH_HIDE_RESEND';
export const MULTI_FACTOR_AUTH_UPDATE_FACTORS =
  'MULTI_FACTOR_AUTH_UPDATE_FACTORS';
export const MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE =
  'MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE';

export const MULTI_FACTOR_AUTH_FORM_CLEAR_ERRORS =
  'MULTI_FACTOR_AUTH_FORM_CLEAR_ERRORS';
