// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import ContinueAnyway from './continueAnyway';

import type { Dispatch } from '../../../types/store';

import { continueWithUnsupportedBrowser } from '../../../services/unsupportedBrowserService/actions';

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    continueWithUnsupportedBrowser: () =>
      dispatch(continueWithUnsupportedBrowser()),
  };
}

export default injectIntl(connect(null, mapDispatchToProps)(ContinueAnyway));
