import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  announceAlertMessage,
  announceStatusMessage,
} from 'bnc-react-services/services/LiveAnnouncerService/actions';
import type { Dispatch } from '../../../types/store';

import LoginForm from './loginForm';
import {
  changeStepRequest,
  formUpdate,
  triggerSubmit,
  triggerValidation,
} from '../../../services/loginFormService/actions';
import {
  removeIdentity,
  clearLoginErrorMessages,
} from '../../../services/loginService/actions';
import {
  forgotPasswordUnavailable,
  loginRouteForgotPassword,
} from '../../../services/loginRouteService/actions';
import {
  hasMaxIdentities,
  getLoginState,
  getIdentities,
  hasIdentities,
  hasLoginFailed,
  getSdkError,
  getIdentityWithEmail,
} from '../../../services/loginService/selectors';
import {
  getLoginFormState,
  hasValidationErrors,
  isLoginFormDone,
} from '../../../services/loginFormService/selectors';
import {
  getPartnerIdName,
  getPartnerName,
} from '../../../services/templateService/selectors';
import { getAllConfigs } from '../../../services/configsService/selectors';

function mapStateToProps(state) {
  const loginState = getLoginState(state);
  const loginFormState = getLoginFormState(state);

  return {
    isFetching: loginState.isFetching,
    isDeletingIdentities: loginFormState.isDeletingIdentities,
    identities: getIdentities(state),
    hasMaxIdentities: hasMaxIdentities(state),
    hasIdentities: hasIdentities(state),
    formData: loginFormState.formData,
    currentStep: loginFormState.currentStep,
    errors: loginFormState.errors,
    identitySelected: getIdentityWithEmail(state),
    hasValidationError: hasValidationErrors(state),
    hasLoginFailure: hasLoginFailed(state),
    sdkError: getSdkError(state),
    isFirstRender: loginFormState.isFirstRender,
    hasToFocus: loginFormState.hasToFocus,
    partnerName: getPartnerName(state),
    partnerIdName: getPartnerIdName(state),
    configs: getAllConfigs(state),
    isFormDone: isLoginFormDone(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  formUpdate: (fieldName, value) => dispatch(formUpdate(fieldName, value)),
  changeStepRequest: (nextStep, hasToFocus) =>
    dispatch(changeStepRequest(nextStep, hasToFocus)),
  removeIdentity: identity => dispatch(removeIdentity(identity)),
  triggerSubmit: () => dispatch(triggerSubmit()),
  triggerValidation: fieldName => dispatch(triggerValidation(fieldName)),
  loginRouteForgotPassword: () => dispatch(loginRouteForgotPassword()),
  forgotPasswordUnavailable: () => dispatch(forgotPasswordUnavailable()),
  clearLoginErrorMessages: () => dispatch(clearLoginErrorMessages()),
  announceAlertMessage: message => dispatch(announceAlertMessage(message)),
  announceStatusMessage: message => dispatch(announceStatusMessage(message)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm),
);
