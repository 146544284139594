import { createSelector } from 'reselect';

import {
  getPartnerIdName,
  getTemplateName,
} from '../templateService/selectors';
import { getLocale } from '../i18nService/selectors';
import TEMPLATES from '../../utils/constants/template';
import { getAppBaseUrl } from '../../utils/configUtils';

const getRedirectPage = createSelector(
  [getTemplateName, getPartnerIdName, getLocale],
  (templateName, partnerId, locale) => {
    const templateNameUrl =
      templateName === TEMPLATES.SECURE_KEY ? null : templateName;
    return templateNameUrl
      ? getAppBaseUrl(templateNameUrl, partnerId, locale)
      : null;
  },
);

export default {
  getRedirectPage,
};
