import { connect } from 'react-redux';

import PasswordChangeSuccess from './passwordChangeSuccess';

import { getTemplateName } from '../../services/templateService/selectors';

import type { State } from '../../types/state';

function mapStateToProps(state: State) {
  return {
    templateName: getTemplateName(state),
  };
}

export default connect(mapStateToProps)(PasswordChangeSuccess);
