// @flow
import omit from 'lodash/omit';
import merge from 'lodash/merge';

import * as actionTypes from './actionTypes';

import type { Action, ForgotPasswordFormState } from './types';
import {
  FORGOT_PASSWORD_PRODUCT_TYPE,
  FORGOT_PASSWORD_FIELDS,
} from '../../utils/forgotPasswordUtils/constants';

export const initialState = (): ForgotPasswordFormState => ({
  formId: '',
  isValid: false,
  errors: {},
  formData: {
    [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL]: '',
    [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE]: null,
    [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PRODUCT_TYPE]:
      FORGOT_PASSWORD_PRODUCT_TYPE.DEBIT,
    [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.OPEN_CHOICES]: false,
    [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA]: {},
    [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER]: '',
    [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE]: '',
    [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER]: '',
    [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT]: '',
    [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER]: '',
  },
  hasToResetRecaptcha: false,
  failure: false,
  initDone: false,
  executedAction: {},
});

export default (
  state: ForgotPasswordFormState = initialState(),
  action: Action,
) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_FORM_UPDATE:
      return {
        ...state,
        failure: false,
        formData: { ...state.formData, [action.fieldName]: action.fieldValue },
        executedAction: { ...action },
      };

    case actionTypes.FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, [action.fieldName]: action.errors },
        isValid: false,
        failure: false,
      };

    case actionTypes.FORGOT_PASSWORD_FORM_FIELD_VALIDATION_SUCCESS:
      return {
        ...state,
        isValid: action.isValid,
        failure: false,
        errors: omit(state.errors, action.fieldName),
      };

    case actionTypes.FORGOT_PASSWORD_FORM_VALIDATION_FAILURE:
      return {
        ...state,
        errors: action.errors,
        isValid: false,
      };

    case actionTypes.FORGOT_PASSWORD_FORM_VALIDATION_SUCCESS:
      return {
        ...state,
        isValid: true,
      };

    case actionTypes.FORGOT_PASSWORD_FORM_INIT_REQUEST:
      return {
        ...state,
        formId: action.formId,
        formData: merge({ ...state.formData }, action.defaultValues),
      };
    case actionTypes.FORGOT_PASSWORD_FORM_INIT_DONE:
      return {
        ...state,
        ...action.payload,
        initDone: true,
      };

    case actionTypes.FORGOT_PASSWORD_FORM_RESET:
      return initialState();
    case actionTypes.FORGOT_PASSWORD_RESET_RECAPTCHA:
      return {
        ...state,
        hasToResetRecaptcha: true,
      };
    case actionTypes.FORGOT_PASSWORD_RESET_RECAPTCHA_DONE:
      return {
        ...state,
        hasToResetRecaptcha: false,
      };

    default:
      return state;
  }
};
