// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';
import { Announcer } from 'bnc-react-components';
import { getTranslatedMessage } from '../../../utils/liveAnnouncer/helper';

type Props = {
  message: string,
  politeness?: string,
  intl: IntlShape,
};

const defaultProps = {
  politeness: 'polite',
};

function LiveAnnouncer(props: Props) {
  const { message, politeness, intl } = props;
  const { text, aria } = getTranslatedMessage(intl, message);
  return <Announcer message={text} politeness={politeness} {...aria} />;
}

LiveAnnouncer.defaultProps = defaultProps;

export default LiveAnnouncer;
