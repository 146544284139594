import React from 'react';

const IconFirefox = props => (
  <div className="browser-logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      {...props}
    >
      <defs>
        <radialGradient
          id="ov9a7ecgfb"
          cx="85.913%"
          cy="-12.934%"
          r="128.347%"
          fx="85.913%"
          fy="-12.934%"
          gradientTransform="matrix(.81314 0 0 1 .16 0)"
        >
          <stop offset="12.9%" stopColor="#FFBD4F" />
          <stop offset="18.6%" stopColor="#FFAC31" />
          <stop offset="24.7%" stopColor="#FF9D17" />
          <stop offset="28.3%" stopColor="#FF980E" />
          <stop offset="40.3%" stopColor="#FF563B" />
          <stop offset="46.7%" stopColor="#FF3750" />
          <stop offset="71%" stopColor="#F5156C" />
          <stop offset="78.2%" stopColor="#EB0878" />
          <stop offset="86%" stopColor="#E50080" />
        </radialGradient>
        <radialGradient
          id="jrtr82s4ec"
          cx="47.778%"
          cy="39.538%"
          r="128.347%"
          fx="47.778%"
          fy="39.538%"
          gradientTransform="matrix(.81314 0 0 1 .09 0)"
        >
          <stop offset="30%" stopColor="#960E18" />
          <stop offset="35.1%" stopColor="#B11927" stopOpacity="0.74" />
          <stop offset="43.5%" stopColor="#DB293D" stopOpacity="0.343" />
          <stop offset="49.7%" stopColor="#F5334B" stopOpacity="0.094" />
          <stop offset="53%" stopColor="#FF3750" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="zcxn8nfnad"
          cx="49.996%"
          cy="-29.998%"
          r="314.953%"
          fx="49.996%"
          fy="-29.998%"
          gradientTransform="matrix(1 0 0 .58897 0 -.123)"
        >
          <stop offset="13.2%" stopColor="#FFF44F" />
          <stop offset="25.2%" stopColor="#FFDC3E" />
          <stop offset="50.6%" stopColor="#FF9D12" />
          <stop offset="52.6%" stopColor="#FF980E" />
        </radialGradient>
        <radialGradient
          id="9axv18bxme"
          cx="19.455%"
          cy="110.087%"
          r="111.743%"
          fx="19.455%"
          fy="110.087%"
          gradientTransform="matrix(1 0 0 .91227 0 .097)"
        >
          <stop offset="35.3%" stopColor="#3A8EE6" />
          <stop offset="47.2%" stopColor="#5C79F0" />
          <stop offset="66.9%" stopColor="#9059FF" />
          <stop offset="100%" stopColor="#C139E6" />
        </radialGradient>
        <radialGradient
          id="x4fzozrqvf"
          cx="58.238%"
          cy="36.619%"
          r="59.246%"
          fx="58.238%"
          fy="36.619%"
          gradientTransform="scale(1 .91227) rotate(-13.592 .73 .384)"
        >
          <stop offset="20.6%" stopColor="#9059FF" stopOpacity="0" />
          <stop offset="27.8%" stopColor="#8C4FF3" stopOpacity="0.064" />
          <stop offset="74.7%" stopColor="#7716A8" stopOpacity="0.45" />
          <stop offset="97.5%" stopColor="#6E008B" stopOpacity="0.6" />
        </radialGradient>
        <radialGradient
          id="4sc50p0arg"
          cx="208.116%"
          cy="-103.936%"
          r="293.24%"
          fx="208.116%"
          fy="-103.936%"
          gradientTransform="matrix(1 0 0 .9963 0 -.004)"
        >
          <stop offset="0%" stopColor="#FFE226" />
          <stop offset="12.1%" stopColor="#FFDB27" />
          <stop offset="29.5%" stopColor="#FFC82A" />
          <stop offset="50.2%" stopColor="#FFA930" />
          <stop offset="73.2%" stopColor="#FF7E37" />
          <stop offset="79.2%" stopColor="#FF7139" />
        </radialGradient>
        <radialGradient
          id="ytzznlolzh"
          cx="79.305%"
          cy="-40.886%"
          r="193.792%"
          fx="79.305%"
          fy="-40.886%"
          gradientTransform="matrix(.83955 0 0 1 .127 0)"
        >
          <stop offset="11.3%" stopColor="#FFF44F" />
          <stop offset="45.6%" stopColor="#FF980E" />
          <stop offset="62.2%" stopColor="#FF5634" />
          <stop offset="71.6%" stopColor="#FF3647" />
          <stop offset="90.4%" stopColor="#E31587" />
        </radialGradient>
        <radialGradient
          id="3k9t9cn0ti"
          cx="6.322%"
          cy="-10.342%"
          r="348.744%"
          fx="6.322%"
          fy="-10.342%"
          gradientTransform="scale(1 .47353) rotate(83.976 .127 -.16)"
        >
          <stop offset="0%" stopColor="#FFF44F" />
          <stop offset="6%" stopColor="#FFE847" />
          <stop offset="16.8%" stopColor="#FFC830" />
          <stop offset="30.4%" stopColor="#FF980E" />
          <stop offset="35.6%" stopColor="#FF8B16" />
          <stop offset="45.5%" stopColor="#FF672A" />
          <stop offset="57%" stopColor="#FF3647" />
          <stop offset="73.7%" stopColor="#E31587" />
        </radialGradient>
        <radialGradient
          id="wyl1gu68gj"
          cx="15.372%"
          cy="-42.681%"
          r="238.693%"
          fx="15.372%"
          fy="-42.681%"
          gradientTransform="matrix(.9813 0 0 1 .003 0)"
        >
          <stop offset="13.7%" stopColor="#FFF44F" />
          <stop offset="48%" stopColor="#FF980E" />
          <stop offset="59.2%" stopColor="#FF5634" />
          <stop offset="65.5%" stopColor="#FF3647" />
          <stop offset="90.4%" stopColor="#E31587" />
        </radialGradient>
        <radialGradient
          id="u3pg43x7bk"
          cx="155.387%"
          cy="-44.592%"
          r="312.831%"
          fx="155.387%"
          fy="-44.592%"
          gradientTransform="matrix(.89553 0 0 1 .162 0)"
        >
          <stop offset="9.4%" stopColor="#FFF44F" />
          <stop offset="23.1%" stopColor="#FFE141" />
          <stop offset="50.9%" stopColor="#FFAF1E" />
          <stop offset="62.6%" stopColor="#FF980E" />
        </radialGradient>
        <linearGradient
          id="mb9cxkgzaa"
          x1="87.259%"
          x2="9.369%"
          y1="15.492%"
          y2="93.116%"
        >
          <stop offset="4.8%" stopColor="#FFF44F" />
          <stop offset="11.1%" stopColor="#FFE847" />
          <stop offset="22.5%" stopColor="#FFC830" />
          <stop offset="36.8%" stopColor="#FF980E" />
          <stop offset="40.1%" stopColor="#FF8B16" />
          <stop offset="46.2%" stopColor="#FF672A" />
          <stop offset="53.4%" stopColor="#FF3647" />
          <stop offset="70.5%" stopColor="#E31587" />
        </linearGradient>
        <linearGradient
          id="1fxsk95lol"
          x1="86.323%"
          x2="20.047%"
          y1="15.077%"
          y2="83.535%"
        >
          <stop offset="16.7%" stopColor="#FFF44F" stopOpacity="0.8" />
          <stop offset="26.6%" stopColor="#FFF44F" stopOpacity="0.634" />
          <stop offset="48.9%" stopColor="#FFF44F" stopOpacity="0.217" />
          <stop offset="60%" stopColor="#FFF44F" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g fill="none">
        <path
          fill="url(#mb9cxkgzaa)"
          d="M52.231 18.779c-1.179-2.837-3.57-5.9-5.442-6.867a28.188 28.188 0 012.747 8.235l.005.045c-3.067-7.647-8.269-10.73-12.517-17.445-.215-.34-.43-.68-.64-1.04a7.796 7.796 0 01-.298-.56c-.176-.34-.312-.7-.405-1.074a.071.071 0 00-.061-.07.114.114 0 00-.052 0c-.003 0-.009.007-.013.008-.004.002-.013.008-.02.01l.011-.018c-6.814 3.99-9.127 11.377-9.34 15.071a13.58 13.58 0 00-7.466 2.878 7.84 7.84 0 00-.7-.53 12.585 12.585 0 01-.076-6.632 20.107 20.107 0 00-6.53 5.047h-.013c-1.075-1.362-1-5.856-.938-6.795a4.908 4.908 0 00-.906.48 19.797 19.797 0 00-2.651 2.272 23.723 23.723 0 00-2.536 3.043v.004-.005a22.894 22.894 0 00-3.64 8.22l-.037.18c-.05.238-.235 1.434-.266 1.694 0 .02-.005.039-.007.06A25.96 25.96 0 000 28.73v.14c.012 14.082 10.797 25.814 24.829 27.009 14.032 1.195 26.645-8.544 29.039-22.422.045-.35.082-.696.123-1.05a27.893 27.893 0 00-1.76-13.628zM20.962 40.015c.127.061.246.127.376.185l.019.012a12.096 12.096 0 01-.395-.197z"
          transform="translate(.7)"
        />
        <path
          fill="url(#ov9a7ecgfb)"
          d="M52.231 18.779c-1.179-2.837-3.57-5.9-5.442-6.867a28.188 28.188 0 012.747 8.235v.026l.005.028a24.568 24.568 0 01-.844 18.31c-3.11 6.673-10.636 13.51-22.417 13.178C13.55 51.33 2.34 41.883.244 29.511c-.381-1.95 0-2.94.192-4.525A20.13 20.13 0 000 28.729v.14c.012 14.083 10.797 25.815 24.829 27.01 14.032 1.195 26.645-8.544 29.039-22.422.045-.35.082-.696.123-1.05a27.893 27.893 0 00-1.76-13.628z"
          transform="translate(.7)"
        />
        <path
          fill="url(#jrtr82s4ec)"
          d="M52.231 18.779c-1.179-2.837-3.57-5.9-5.442-6.867a28.188 28.188 0 012.747 8.235v.026l.005.028a24.568 24.568 0 01-.844 18.31c-3.11 6.673-10.636 13.51-22.417 13.178C13.55 51.33 2.34 41.883.244 29.511c-.381-1.95 0-2.94.192-4.525A20.13 20.13 0 000 28.729v.14c.012 14.083 10.797 25.815 24.829 27.01 14.032 1.195 26.645-8.544 29.039-22.422.045-.35.082-.696.123-1.05a27.893 27.893 0 00-1.76-13.628z"
          transform="translate(.7)"
        />
        <path
          fill="url(#zcxn8nfnad)"
          d="M39.047 21.968c.06.041.114.083.17.124a14.81 14.81 0 00-2.52-3.287C28.263 10.372 34.486.52 35.534.02l.011-.016c-6.814 3.99-9.127 11.377-9.34 15.071.317-.021.63-.048.954-.048 4.922.01 9.46 2.66 11.887 6.942z"
          transform="translate(.7)"
        />
        <path
          fill="url(#9axv18bxme)"
          d="M27.177 23.652c-.044.675-2.428 3.003-3.262 3.003-7.715 0-8.967 4.666-8.967 4.666.341 3.93 3.08 7.167 6.39 8.88.151.078.305.148.458.218.265.117.531.226.797.326 1.136.402 2.325.631 3.53.68 13.521.635 16.14-16.17 6.383-21.045a9.36 9.36 0 016.541 1.588 13.692 13.692 0 00-11.887-6.942c-.322 0-.637.027-.953.048a13.578 13.578 0 00-7.467 2.878c.414.35.881.818 1.865 1.787 1.84 1.814 6.562 3.693 6.572 3.913z"
          transform="translate(.7)"
        />
        <path
          fill="url(#x4fzozrqvf)"
          d="M27.177 23.652c-.044.675-2.428 3.003-3.262 3.003-7.715 0-8.967 4.666-8.967 4.666.341 3.93 3.08 7.167 6.39 8.88.151.078.305.148.458.218.265.117.531.226.797.326 1.136.402 2.325.631 3.53.68 13.521.635 16.14-16.17 6.383-21.045a9.36 9.36 0 016.541 1.588 13.692 13.692 0 00-11.887-6.942c-.322 0-.637.027-.953.048a13.578 13.578 0 00-7.467 2.878c.414.35.881.818 1.865 1.787 1.84 1.814 6.562 3.693 6.572 3.913z"
          transform="translate(.7)"
        />
        <path
          fill="url(#4sc50p0arg)"
          d="M17.476 17.05c.22.14.4.262.56.372a12.575 12.575 0 01-.077-6.632 20.107 20.107 0 00-6.53 5.047c.132-.003 4.068-.074 6.046 1.213z"
          transform="translate(.7)"
        />
        <path
          fill="url(#ytzznlolzh)"
          d="M.248 29.511C2.342 41.883 13.554 51.33 26.284 51.69c11.78.334 19.307-6.505 22.416-13.177a24.568 24.568 0 00.845-18.31v-.027c0-.02-.005-.032 0-.026l.005.046c.962 6.284-2.234 12.372-7.23 16.488l-.016.035c-9.736 7.93-19.053 4.784-20.939 3.5a14.42 14.42 0 01-.394-.196c-5.677-2.713-8.022-7.885-7.52-12.32a6.969 6.969 0 01-6.426-4.043 10.235 10.235 0 019.975-.4 13.51 13.51 0 0010.186.4c-.01-.22-4.732-2.1-6.573-3.913-.984-.97-1.45-1.436-1.864-1.787a7.987 7.987 0 00-.7-.53c-.161-.11-.343-.23-.56-.372-1.98-1.287-5.915-1.216-6.045-1.213h-.012c-1.075-1.362-1-5.856-.938-6.795a4.908 4.908 0 00-.906.48 19.743 19.743 0 00-2.652 2.272 23.736 23.736 0 00-2.546 3.036v.004-.005a22.894 22.894 0 00-3.64 8.22c-.014.056-.978 4.27-.502 6.455z"
          transform="translate(.7)"
        />
        <path
          fill="url(#3k9t9cn0ti)"
          d="M36.697 18.805c.99.973 1.84 2.08 2.52 3.29.15.113.289.225.407.334 6.151 5.67 2.928 13.685 2.688 14.255 4.997-4.117 8.19-10.204 7.23-16.488-3.068-7.651-8.27-10.735-12.518-17.45a33.6 33.6 0 01-.64-1.038 8.245 8.245 0 01-.298-.56 4.962 4.962 0 01-.405-1.075.071.071 0 00-.061-.07.114.114 0 00-.052 0c-.003 0-.009.007-.013.008-.004.002-.013.008-.02.01-1.049.498-7.271 10.35 1.163 18.784z"
          transform="translate(.7)"
        />
        <path
          fill="url(#wyl1gu68gj)"
          d="M39.62 22.425a5.843 5.843 0 00-.407-.333c-.055-.041-.11-.083-.168-.124a9.365 9.365 0 00-6.542-1.588c9.758 4.879 7.14 21.68-6.383 21.046a12.117 12.117 0 01-3.53-.681c-.266-.1-.531-.208-.797-.326-.153-.07-.306-.14-.458-.219l.02.012c1.885 1.287 11.2 4.433 20.938-3.5l.015-.035c.243-.567 3.466-8.584-2.688-14.252z"
          transform="translate(.7)"
        />
        <path
          fill="url(#u3pg43x7bk)"
          d="M14.948 31.321s1.252-4.666 8.967-4.666c.834 0 3.22-2.328 3.262-3.003a13.511 13.511 0 01-10.186-.4 10.233 10.233 0 00-9.974.4 6.966 6.966 0 006.426 4.043c-.502 4.436 1.843 9.607 7.52 12.32.126.06.245.126.375.185-3.313-1.712-6.049-4.949-6.39-8.879z"
          transform="translate(.7)"
        />
        <path
          fill="url(#1fxsk95lol)"
          d="M52.231 18.779c-1.179-2.837-3.57-5.9-5.442-6.867a28.188 28.188 0 012.747 8.235l.005.045c-3.067-7.647-8.269-10.73-12.517-17.445-.215-.34-.43-.68-.64-1.04a7.796 7.796 0 01-.298-.56c-.176-.34-.312-.7-.405-1.074a.071.071 0 00-.061-.07.114.114 0 00-.052 0c-.003 0-.009.007-.013.008-.004.002-.013.008-.02.01l.011-.018c-6.814 3.99-9.127 11.377-9.34 15.071.317-.021.63-.048.954-.048 4.922.01 9.46 2.66 11.887 6.942a9.362 9.362 0 00-6.541-1.588c9.758 4.879 7.14 21.68-6.383 21.046a12.11 12.11 0 01-3.53-.681c-.266-.1-.532-.208-.797-.326-.153-.07-.307-.14-.458-.219l.019.012a12.096 12.096 0 01-.395-.197c.127.061.246.127.376.185-3.313-1.712-6.049-4.949-6.39-8.879 0 0 1.252-4.666 8.967-4.666.834 0 3.22-2.328 3.262-3.003-.01-.22-4.732-2.1-6.572-3.913-.984-.97-1.45-1.436-1.865-1.787a7.84 7.84 0 00-.7-.53 12.585 12.585 0 01-.076-6.632 20.107 20.107 0 00-6.53 5.047h-.013c-1.075-1.362-1-5.856-.938-6.795a4.908 4.908 0 00-.906.48 19.797 19.797 0 00-2.651 2.272 23.723 23.723 0 00-2.536 3.043v.004-.005a22.894 22.894 0 00-3.64 8.22l-.037.18c-.05.238-.28 1.45-.313 1.711 0 .02 0-.02 0 0A31.61 31.61 0 000 28.73v.14c.012 14.083 10.797 25.815 24.829 27.01 14.032 1.195 26.645-8.544 29.039-22.422.045-.35.082-.696.123-1.05a27.893 27.893 0 00-1.76-13.628z"
          transform="translate(.7)"
        />
      </g>
    </svg>
  </div>
);

export default IconFirefox;
