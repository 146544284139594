// @flow
export const FORM_TYPES = {
  RESET_PASSWORD_FORM: 'resetPasswordForm',
  PASSWORD_CHANGE_REQUEST_FORM: 'passwordChangeRequestForm',
  LOGIN_FORM: 'loginForm',
  MULTI_FACTOR_AUTH_FORM: 'multiFactorAuthForm',
  FORGOT_PASSWORD_FORM: 'forgotPasswordForm',
};

export const AUTOCOMPLETE = {
  NEWPASSWORD: 'new-password',
  CURRENTPASSWORD: 'current-password',
};

export const BIRTHDATE_MASK = '11 / 11 / 1111';

export const VALIDATION_DELAY = 300;

export const WELCOME_ANNOUNCE_DELAY = 3000;

export const PASSWORD_FIELD_MAX_LENGTH = '250';

export const IDENTITY_FIELD_MAX_LENGTH = '255';

export const NEW_PASSWORD_FIELD_ERRORS = {
  minLength: 'min-length',
  minNumber: 'no_digit',
  minLowerCase: 'min-lower-case',
  minUpperCase: 'min-upper-case',
  minSymbol: 'min-symbol',
  notEmpty: 'not-empty',
  noSpacesStartEnd: 'no-spaces-start-end',
};

export const REGEX_NO_SPACES_START_END = /^\S$|^\S[\s\S]*\S$/;

export const DATE_MIN_BIRTH = 120;
export const BIRTHDAY_SKIP_YEARS = 7;
