import { expectSaga } from 'redux-saga-test-plan';

import nock from 'nock';
import { testSaga } from 'bnc-utilities-js/testUtils/saga';

import has from 'lodash/has';

import browserHistory from '../../../nav/BrowserHistoryManager/index';
import { ROUTES } from '../../../utils/constants';
import {
  I18N_SERVICE_REDUCER,
  TEMPLATE_SERVICE_REDUCER,
} from '../../../globalRedux/reducers/constants';
import TEMPLATES from '../../../utils/constants/template';
import {
  watchLoginLocked,
  watchLoginPasswordException,
  watchLoginPasswordExpired,
  watchLoginSignUp,
  watchLoginAppOrigin,
  watchLoginMFA,
  watchLoginForgotPasswordException,
  watchLoginResetPassword,
  watchLoginRouteWithoutSignIn,
  watchLoginForgotPasswordUnavailable,
  watchLoginRSA,
} from '../workers';
import * as actionTypesRedirect from '../../redirectService/actionTypes';
import { getAppBaseUrl } from '../../../utils/configUtils';
import { FORGOT_PASSWORD_UNAVAILABLE_CHECK } from '../../forgotPasswordService/actionTypes';
import loginRouteSagas from '../index';
import {
  forgotPasswordUnavailableCheck,
  resetForgotPasswordFlow,
} from '../../forgotPasswordService/actions';
import { loginRouteForgotPassword } from '../actions';

afterEach(() => {
  nock.cleanAll();
});

jest.mock('../../../nav/BrowserHistoryManager/index', () => ({
  browserHistoryManager: {
    push: jest.fn(),
  },
}));

browserHistory.push = jest.fn();

for (const template in TEMPLATES) {
  if (Object.prototype.hasOwnProperty.call(TEMPLATES, template)) {
    describe(`watchLoginPasswordExpired from ${template}`, () => {
      const templateName = TEMPLATES[template];
      test('watchLoginPasswordExpired - Go to forgot password page', async () => {
        const { sagaDone } = testSaga({
          saga: watchLoginPasswordExpired,
          state: {
            [TEMPLATE_SERVICE_REDUCER]: {
              templateName,
            },
          },
          args: [],
        });
        const { actions } = await sagaDone;
        expect(browserHistory.push).toBeCalledWith(ROUTES.RESETPASSWORD);
        if (template !== TEMPLATES.BNE) {
          expect(actions).toEqual([
            {
              type: FORGOT_PASSWORD_UNAVAILABLE_CHECK,
              routeToValidate: ROUTES.RESETPASSWORD,
            },
          ]);
        }
      });
    });
  }
}

test('watchLoginLocked - Go to account locked page', async () => {
  watchLoginLocked();
  expect(browserHistory.push).toBeCalledWith(ROUTES.ACCOUNT_LOCKED);
});

test('watchLoginPasswordException', () => {
  watchLoginPasswordException();
  expect(browserHistory.push).toBeCalledWith(ROUTES.RESET_PASSWORD_EXCEPTION);
});

test('watchLoginMFA', () => {
  watchLoginMFA();
  expect(browserHistory.push).toBeCalledWith(ROUTES.MFA);
});

test('watchLoginRSA', () => {
  watchLoginRSA();
  expect(browserHistory.push).toBeCalledWith(ROUTES.RSA);
});

test('watchLoginForgotPasswordException', () => {
  watchLoginForgotPasswordException();
  expect(browserHistory.push).toBeCalledWith(ROUTES.FORGOT_PASSWORD_EXCEPTION);
});

for (const template in TEMPLATES) {
  if (has(TEMPLATES, template)) {
    describe(`watchLoginSignUp from ${template}`, () => {
      const templateName =
        TEMPLATES[template] === TEMPLATES.SECURE_KEY || TEMPLATES.REWARDS
          ? TEMPLATES.SBIP2
          : TEMPLATES[template];
      const partnerId =
        templateName === TEMPLATES.SBIP2_WHITE_LABEL ? 'test' : '';
      const appBaseUrl = getAppBaseUrl(templateName, partnerId, 'FR');

      test('watchLoginSignUp - Go to signUp page', async () => {
        const { sagaDone } = testSaga({
          saga: watchLoginSignUp,
          state: {
            [TEMPLATE_SERVICE_REDUCER]: {
              templateName,
              partnerId,
            },
            [I18N_SERVICE_REDUCER]: {
              locale: 'fr',
            },
          },
          args: [],
        });
        const { actions } = await sagaDone;
        expect(actions).toEqual([
          {
            type: actionTypesRedirect.REDIRECT_TO_PAGE,
            pageUrl: `${appBaseUrl}/signUp`,
          },
        ]);
      });
    });

    describe(`watchLoginAppOrigin from ${template}`, () => {
      const templateName = TEMPLATES[template];
      const partnerId =
        templateName === TEMPLATES.SBIP2_WHITE_LABEL ? 'test' : '';
      const appBaseUrl = getAppBaseUrl(templateName, partnerId, 'FR');

      test('watchLoginAppOrigin', async () => {
        const { sagaDone } = testSaga({
          saga: watchLoginAppOrigin,
          state: {
            [TEMPLATE_SERVICE_REDUCER]: {
              templateName: template,
              partnerId,
            },
            [I18N_SERVICE_REDUCER]: {
              locale: 'fr',
            },
          },
          args: [],
        });
        const { actions } = await sagaDone;
        expect(actions).toEqual([
          {
            type: actionTypesRedirect.REDIRECT_TO_PAGE,
            pageUrl: appBaseUrl,
          },
        ]);
      });
    });
  }
}

test('watchLoginResetPassword', () => {
  watchLoginResetPassword();
  expect(browserHistory.push).toBeCalledWith(ROUTES.RESETPASSWORD);
});

describe(`watchLoginRouteWithoutSignIn for BAO project`, () => {
  test('watchLoginRouteWithoutSignIn', async () => {
    const { sagaDone } = testSaga({
      saga: watchLoginRouteWithoutSignIn,
      state: {
        [I18N_SERVICE_REDUCER]: {
          locale: 'fr',
        },
      },
      args: [],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: actionTypesRedirect.REDIRECT_TO_PAGE,
        pageUrl: 'http://localhost:3000/bao/welcome?lang=fr',
      },
    ]);
  });
});

test('watchLoginForgotPasswordUnavailable', () => {
  watchLoginForgotPasswordUnavailable();
  expect(browserHistory.push).toBeCalledWith(
    ROUTES.FORGOT_PASSWORD_UNAVAILABLE,
  );
});

test('watchLoginForgotPassword', async () => {
  await expectSaga(loginRouteSagas)
    .put(resetForgotPasswordFlow())
    .put(forgotPasswordUnavailableCheck(ROUTES.FORGOT_PASSWORD))
    .dispatch(loginRouteForgotPassword())
    .run();
  expect(browserHistory.push).toBeCalledWith(ROUTES.FORGOT_PASSWORD);
});
