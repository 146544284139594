import React from 'react';
import get from 'lodash/get';

import { FORGOT_PASSWORD_STEPS } from '../../../utils/forgotPasswordUtils/constants';
import TEMPLATES from '../../../utils/constants/template';

import ForgotPasswordSbip2 from '../../../organisms/sbip2/ForgotPassword/index';
import ForgotPasswordNatgo from '../../../organisms/natgo/ForgotPassword/index';
import ForgotPasswordRewards from '../../../organisms/rewards/ForgotPassword/index';
import ForgotPasswordBao from '../../../organisms/bao/ForgotPassword/index';
import ForgotPasswordOrionWhiteLabel from '../../../organisms/orionWhiteLabel/ForgotPassword/index';

import ResetPasswordSbip2 from '../../../organisms/sbip2/ResetPassword/indexForgotPassword';
import ResetPasswordNatgo from '../../../organisms/natgo/ResetPassword/indexForgotPassword';
import ResetPasswordRewards from '../../../organisms/rewards/ResetPassword/indexForgotPassword';
import ResetPasswordBao from '../../../organisms/bao/ResetPassword/indexForgotPassword';
import ResetPasswordOrionWhiteLabel from '../../../organisms/orionWhiteLabel/ResetPassword/indexForgotPassword';

import MultiFactorAuth from '../../../organisms/common/MultiFactorAuth/indexForgotPassword';
import NeedHelp from '../../../molecules/common/NeedHelp/index';
import MultiFactorAuthFooterContent from '../../../organisms/common/MultiFactorAuthFooter/indexForgotPassword';

import PasswordChangeSuccessSbip2 from '../../../organisms/sbip2/PasswordChangeSuccess/index';
import PasswordChangeSuccessNatgo from '../../../organisms/natgo/PasswordChangeSuccess/index';
import PasswordChangeSuccessRewards from '../../../organisms/rewards/PasswordChangeSuccess/index';
import PasswordChangeSuccessBao from '../../../organisms/bao/PasswordChangeSuccess/index';
import PasswordChangeSuccessOrionWhiteLabel from '../../../organisms/orionWhiteLabel/PasswordChangeSuccess/index';
import MultiFactorAuthChoice from '../../../organisms/common/MultiFactorAuthChoice/indexForgotPassword';

export const COMPONENT_TYPE = {
  MAIN: 'main',
  FOOTER: 'footer',
};

const forgotPasswordSetup = {
  [FORGOT_PASSWORD_STEPS.IDENTIFICATION]: {
    [TEMPLATES.SBIP2]: {
      main: ForgotPasswordSbip2,
    },
    [TEMPLATES.OBE]: {
      main: ForgotPasswordSbip2,
    },
    [TEMPLATES.SBIP2_WHITE_LABEL]: {
      main: ForgotPasswordSbip2,
    },
    [TEMPLATES.NATGO]: {
      main: ForgotPasswordNatgo,
    },
    [TEMPLATES.REWARDS]: {
      main: ForgotPasswordRewards,
    },
    [TEMPLATES.BAO]: {
      main: ForgotPasswordBao,
    },
    [TEMPLATES.ORION_WHITE_LABEL]: {
      main: ForgotPasswordOrionWhiteLabel,
    },
    DEFAULT: {
      main: () => <div>Not implemented</div>,
      footer: NeedHelp,
    },
  },
  [FORGOT_PASSWORD_STEPS.MFA_CHOICE]: {
    DEFAULT: {
      main: MultiFactorAuthChoice,
      footer: NeedHelp,
    },
  },
  [FORGOT_PASSWORD_STEPS.CODE_CONFIRMATION]: {
    DEFAULT: {
      main: MultiFactorAuth,
      footer: MultiFactorAuthFooterContent,
    },
  },
  [FORGOT_PASSWORD_STEPS.PASSWORD_RESET]: {
    [TEMPLATES.SBIP2]: {
      main: ResetPasswordSbip2,
    },
    [TEMPLATES.OBE]: {
      main: ResetPasswordSbip2,
    },
    [TEMPLATES.SBIP2_WHITE_LABEL]: {
      main: ResetPasswordSbip2,
    },
    [TEMPLATES.NATGO]: {
      main: ResetPasswordNatgo,
    },
    [TEMPLATES.REWARDS]: {
      main: ResetPasswordRewards,
    },
    [TEMPLATES.BAO]: {
      main: ResetPasswordBao,
    },
    [TEMPLATES.ORION_WHITE_LABEL]: {
      main: ResetPasswordOrionWhiteLabel,
    },
    DEFAULT: {
      main: () => <div>Not implemented</div>,
      footer: NeedHelp,
    },
  },
  [FORGOT_PASSWORD_STEPS.FINAL_CONFIRMATION]: {
    [TEMPLATES.SBIP2]: { main: PasswordChangeSuccessSbip2 },
    [TEMPLATES.OBE]: { main: PasswordChangeSuccessSbip2 },
    [TEMPLATES.SBIP2_WHITE_LABEL]: { main: PasswordChangeSuccessSbip2 },
    [TEMPLATES.NATGO]: { main: PasswordChangeSuccessNatgo },
    [TEMPLATES.REWARDS]: { main: PasswordChangeSuccessRewards },
    [TEMPLATES.BAO]: { main: PasswordChangeSuccessBao },
    [TEMPLATES.ORION_WHITE_LABEL]: {
      main: PasswordChangeSuccessOrionWhiteLabel,
    },
    DEFAULT: {
      main: () => <div>Not implemented</div>,
      footer: NeedHelp,
    },
  },
};

export const getComponent = (
  currentStep,
  template,
  componentType = COMPONENT_TYPE.MAIN,
) =>
  get(
    forgotPasswordSetup,
    `${currentStep}.${template}.${componentType}`,
    get(forgotPasswordSetup, `${currentStep}.DEFAULT.${componentType}`),
  );
