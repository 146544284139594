// @flow
import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';

import Header from '../../../organisms/common/Header';
import Footer from '../../../organisms/common/Footer';
import NeedHelp from '../../../molecules/common/NeedHelp';

import ResetPassword from '../../../organisms/sbip2/ResetPassword';

function ResetPasswordTemplate() {
  return (
    <FullScreenFooterTemplate
      header={<Header hasSwitchLang={false} />}
      main={<ResetPassword />}
      footer={<Footer content={<NeedHelp />} />}
    />
  );
}

export default ResetPasswordTemplate;
