import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  announceAlertMessage,
  announceStatusMessage,
} from 'bnc-react-services/services/LiveAnnouncerService/actions';
import type { Dispatch } from '../../../../types/store';

import loginForm from './loginForm';
import {
  changeStepRequest,
  formUpdate,
  triggerSubmit,
  triggerValidation,
} from '../../../../services/loginFormService/actions';
import { clearLoginErrorMessages } from '../../../../services/loginService/actions';
import {
  getLoginState,
  hasLoginFailed,
  getSdkError,
} from '../../../../services/loginService/selectors';
import {
  getLoginFormState,
  hasValidationErrors,
  isLoginFormDone,
} from '../../../../services/loginFormService/selectors';
import {
  getPartnerIdName,
  getPartnerName,
} from '../../../../services/templateService/selectors';

function mapStateToProps(state) {
  const loginState = getLoginState(state);
  const loginFormState = getLoginFormState(state);

  return {
    isFetching: loginState.isFetching,
    formData: loginFormState.formData,
    currentStep: loginFormState.currentStep,
    errors: loginFormState.errors,
    hasValidationError: hasValidationErrors(state),
    hasLoginFailure: hasLoginFailed(state),
    sdkError: getSdkError(state),
    isFirstRender: loginFormState.isFirstRender,
    hasToFocus: loginFormState.hasToFocus,
    partnerName: getPartnerName(state),
    partnerIdName: getPartnerIdName(state),
    isFormDone: isLoginFormDone(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  formUpdate: (fieldName, value) => dispatch(formUpdate(fieldName, value)),
  changeStepRequest: (nextStep, hasToFocus) =>
    dispatch(changeStepRequest(nextStep, hasToFocus)),
  triggerSubmit: () => dispatch(triggerSubmit()),
  triggerValidation: fieldName => dispatch(triggerValidation(fieldName)),
  clearLoginErrorMessages: () => dispatch(clearLoginErrorMessages()),
  announceAlertMessage: message => dispatch(announceAlertMessage(message)),
  announceStatusMessage: message => dispatch(announceStatusMessage(message)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(loginForm),
);
