import sessionManager from 'bnc-react-services/managers/SessionManager';
import { sendPageLoadEvent } from 'bnc-utilities-js/dtmUtils';

import { getDtmPageObject } from './helper';
import ROUTES from '../constants/routes';
import {
  getTemplateName,
  getPartnerIdName,
} from '../../services/templateService/selectors';
import { buildPath } from '../../nav/Router/routerHelper';

/**
 * Handler for location change.
 * @param {Object} action LOCATION_CHANGE action.
 * @param {Object} state application store.
 */
function handlePageChange(action, state) {
  if (action.payload && action.payload.pathname) {
    const partnerId = getPartnerIdName(state);
    const templateName = getTemplateName(state);
    const pages = getDtmPageObject(templateName);
    switch (action.payload.pathname) {
      case buildPath(ROUTES.LOGIN, templateName, partnerId):
        sendPageLoadEvent(
          pages.LOGIN.uri,
          pages.LOGIN.type,
          state,
          sessionManager.getBncIdFromAccessToken(),
          partnerId,
          pages.LOGIN.context,
        );
        break;
      case buildPath(ROUTES.MFA, templateName, partnerId):
        sendPageLoadEvent(
          pages.LOGIN_VERIFY.uri,
          pages.LOGIN_VERIFY.type,
          state,
          sessionManager.getBncIdFromAccessToken(),
          partnerId,
          pages.LOGIN_VERIFY.context,
        );
        break;
      default:
        break;
    }
  }
}
export default handlePageChange;
