// @flow
import omit from 'lodash/omit';
import * as actionTypes from './actionTypes';

import type { Action } from './types';

export type FormHandlerState = {|
  +forms: { [formName: string]: any },
  +formsStatus: { [formId: string]: any },
|};

const defaultState: FormHandlerState = {
  // export for test and init
  forms: {},
  formsStatus: {},
};

export const initialState = () => defaultState;

export default (
  state: FormHandlerState = initialState(),
  action: Action,
): FormHandlerState => {
  switch (action.type) {
    case actionTypes.FORM_HANDLER_POPULATE_FORM_INPUTS:
      return {
        ...state,
        // $FlowFixMe
        forms: { ...state.forms, [action.formName]: action.inputs },
      };
    case actionTypes.FORM_HANDLER_FORM_INIT_DONE:
      return {
        ...state,
        formsStatus: {
          ...state.formsStatus,
          // $FlowFixMe
          [action.formId]: {
            // $FlowFixMe
            ...state.formsStatus[action.formId],
            initDone: true,
          },
        },
      };
    case actionTypes.FORM_HANDLER_RESET_FORM_REQUEST:
      return {
        ...state,
        // $FlowFixMe
        forms: omit(state.forms, action.formId),
        // $FlowFixMe
        formsStatus: omit(state.formsStatus, action.formId),
      };
    default:
      return state;
  }
};
