// @flow
import * as actionTypes from './actionTypes';

import type { UnsupportedBrowserState, Action } from './types';

export const initialState = (): UnsupportedBrowserState => ({
  isSupportedBrowser: false,
  continueWithUnsupportedBrowser: false,
  cannotContinueWithUnsupportedBrowser: false,
});

export default (
  state: UnsupportedBrowserState = initialState(),
  action: Action,
) => {
  switch (action.type) {
    case actionTypes.UNSUPPORTED_BROWSER_INIT:
      return {
        ...state,
        isSupportedBrowser: action.isSupportedBrowser,
      };

    case actionTypes.UNSUPPORTED_BROWSER_CONTINUE_ANYWAY:
      return {
        ...state,
        continueWithUnsupportedBrowser: true,
      };
    case actionTypes.UNSUPPORTED_BROWSER_CANNOT_CONTINUE:
      return {
        ...state,
        cannotContinueWithUnsupportedBrowser: true,
      };
    default:
      return state;
  }
};
