// @flow

export const PROFILE_TYPES = {
  CLIENT: 'client',
  PROSPECT: 'prospect',
  MONO_PP: 'monopp',
  MONO_PH: 'monoph',
  INVEST: 'invest',
};

export const EXCEPTION_TYPES = {
  GENERIC: 'GENERIC',
  CALL_VSD: 'CALL_VSD',
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
};

export const LOCKED_OUT_TOO_MUCH_TRIES = 'LOCKED_OUT_TOO_MUCH_TRIES';

export const CONTEXT_IDS = {
  SBIP: 'SBIP',
  NATGO: 'NATGO',
  GWL: 'GWL',
  F55: 'F55',
  INVESTORS_GROUP: 'INVESTORS_GROUP',
  PARTNERSHIP: 'PARTNERSHIP',
};

export const PROFILE_ERROR = 'BUSINESS_VALIDATION_ERROR';

export const IAMX_USER_LOCKED_OUT_VSD = 'LOCKED_OUT_VSD';
export const IAMX_USER_ACCOUNT_LOCKED = 'ACCOUNT_LOCKED';
/**
 * Mapping each exception message to a trigger action
 */
export const EXCEPTION_TRIGGER_MAPPING = {
  [IAMX_USER_LOCKED_OUT_VSD]: EXCEPTION_TYPES.CALL_VSD,
  [IAMX_USER_ACCOUNT_LOCKED]: EXCEPTION_TYPES.ACCOUNT_LOCKED,
};

export type ContextId = $Values<typeof CONTEXT_IDS>;

export type ExceptionType = $Values<typeof EXCEPTION_TYPES>;

export type ProfileType = $Values<typeof PROFILE_TYPES>;

export const VERIFY_USER_MAXIMUM_RETRIES = 3;

export const INADEQUATE_PASSWORD_CODE = 'Inadequate_password';
export const PASSWORD_POLICIES_ERROR_CODE = 'Password_policies_error';
export const POLICIES_NOT_MET = 'Password requirements were not met';
