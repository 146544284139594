// @flow
import * as actionTypes from './actionTypes';

import type { UserAgentState, Action } from './types';

export const initialState = (): UserAgentState => ({
  browser: {
    name: '',
    version: '',
    major: '',
  },
  os: {
    name: '',
    version: '',
  },
});

export default (state: UserAgentState = initialState(), action: Action) => {
  switch (action.type) {
    case actionTypes.USER_AGENT_INIT_DONE:
      return {
        ...state,
        browser: action.browser,
        os: action.os,
      };
    default:
      return state;
  }
};
