import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { FormGroup } from '@bnc-ui/formGroup';
import { InputGroup } from '@bnc-ui/inputGroup';

import {
  FORGOT_PASSWORD_FIELDS,
  FORGOT_PASSWORD_VALIDATION_RULES,
} from '../../../../utils/forgotPasswordUtils/constants';
import { forgotPasswordFormDataShape } from '../../../../utils/shapes';
import I18N from '../../../../utils/constants/i18n';

const propTypes = {
  intl: intlShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  formData: forgotPasswordFormDataShape.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const InvestmentInfo = ({
  intl,
  errors,
  formData,
  onChange,
  onBlur,
  locale,
}) => (
  <div className="forgot-password-form--group-investment">
    <FormGroup
      label={{
        text: intl.formatMessage({
          id: 'text.label.forgotPassword.investmentAccountNumber',
        }),
        htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER,
      }}
      validate={{
        hasError:
          FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER in errors,
        errorMsg: intl.formatMessage({
          id: 'text.error.field.investmentAccountNumberIncorrectFormat',
        }),
      }}
      tooltip={{
        content: intl.formatMessage({
          id: 'text.tooltip.forgotPassword.investmentAccountNumber',
        }),
        position: 'top',
      }}
    >
      <InputGroup
        inputId={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER}
        inputName={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER}
        placeholder={intl.formatMessage({
          id: 'text.field.placeholder.forgotPassword.investmentAccountNumber',
        })}
        onChange={onChange}
        onBlur={onBlur}
        value={get(
          formData,
          FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER,
        )}
        maxLength={FORGOT_PASSWORD_VALIDATION_RULES.INVESTMENT.ACCOUNT_LENGTH}
      />
    </FormGroup>

    <FormGroup
      label={{
        text: intl.formatMessage({
          id: 'text.label.forgotPassword.phoneNumber',
        }),
        htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER,
      }}
      validate={{
        hasError:
          FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER in errors,
        errorMsg: intl.formatMessage({
          id: 'text.error.field.phoneNumberIncorrectFormat',
        }),
      }}
    >
      <InputGroup
        inputId={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER}
        inputName={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER}
        onChange={onChange}
        onBlur={onBlur}
        mask={get(
          FORGOT_PASSWORD_VALIDATION_RULES,
          `INVESTMENT.PHONE_NUMBER_MASK.${locale}`,
          FORGOT_PASSWORD_VALIDATION_RULES.INVESTMENT.PHONE_NUMBER_MASK[
            I18N.EN
          ],
        )}
        placeholder={intl.formatMessage({
          id: 'text.field.placeholder.forgotPassword.phoneNumber',
        })}
        value={get(
          formData,
          FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER,
        )}
      />
    </FormGroup>
  </div>
);

InvestmentInfo.propTypes = propTypes;

export default InvestmentInfo;
