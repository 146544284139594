// @flow
import React, { Component } from 'react';

import { Browser, OS } from '../../types/browser';

import UnsupportedBrowserTemplate from '../../templates/common/unsupportedBrowserTemplate';

import browserHistory from '../../nav/BrowserHistoryManager';

import {
  getCompatibleBrowserList,
  isBrowserSupportedRegardlessOfVersion,
} from '../../utils/browserUtils';
import ROUTES from '../../utils/constants/routes';

import { Configs } from '../../configs';
import { openExternalInSameTab } from '../../utils/externalUrlUtils';

type Props = {
  browser: Browser,
  os: OS,
  continueWithUnsupportedBrowser: boolean,
  cannotContinueWithUnsupportedBrowser: boolean,
  locale: string,
  isBypassing: boolean,
};

class UnsupportedBrowser extends Component<Props> {
  componentDidMount = () => {
    const {
      continueWithUnsupportedBrowser,
      cannotContinueWithUnsupportedBrowser,
      os,
      browser,
      locale,
      isBypassing,
    } = this.props;
    // Redirect to the Login page
    // If a webview is open or if the user wants to use the website with an desktop unsupported browser
    if (
      isBypassing ||
      continueWithUnsupportedBrowser ||
      (!browser.name && !os.name)
    ) {
      browserHistory.push(`${ROUTES.LOGIN}${window.location.search}`);
    }

    if (cannotContinueWithUnsupportedBrowser || (!browser.name && !os.name)) {
      openExternalInSameTab(
        Configs.BNE.EXTERNAL_URLS.ENTERPRISE_HOME_URL.URL[locale],
      );
    }
  };

  render() {
    const { browser, os } = this.props;

    const supportedBrowserList = getCompatibleBrowserList(browser, os);
    const browserVersions = Configs.SUPPORTED_BROWSER_VERSIONS;

    if (isBrowserSupportedRegardlessOfVersion(browser)) {
      browserHistory.push(`${ROUTES.LOGIN}${window.location.search}`);
    }

    return (
      <UnsupportedBrowserTemplate
        browser={browser}
        browserList={supportedBrowserList}
        browserVersions={browserVersions}
      />
    );
  }
}

export default UnsupportedBrowser;
