// @flow
import React, { useEffect, useState } from 'react';
import type { intlShape } from 'react-intl';

import { FormGroup } from '@bnc-ui/formGroup';
import { Input } from '@bnc-ui/input';
import { Button } from '@bnc-ui/button';
import { Link } from '@bnc-ui/link';
import { Checkbox } from '@bnc-ui/checkbox';
import ExpirationModal from '../../../../organisms/common/ExpirationModal';

import type { MultiFactorAuthFormData } from '../../../../types/forms/multiFactorAuthForm';
import { FORGOT_PASSWORD_FIELDS } from '../../../../utils/forgotPasswordUtils/constants';
import { OTC_CODE_LENGTH } from '../../../../services/multiFactorAuthFormService/formSchema';

// Loading Styles and style Helper
import './style.scss';
import ClickTaggingWrapper from '../../TaggingBNE/ClickTaggingWrapper';

type Props = {
  intl: intlShape,
  isFetching: boolean,
  formData: MultiFactorAuthFormData,
  formUpdate: (fieldName: string, fieldValue: string) => void,
  triggerSubmit: () => void,
  triggerValidation: (fieldName: string) => void,
  errors: *,
  sdkErrors: *,
  setFormInputs?: (input: any) => void,
  onKeyPress?: (event: any, callback: Function) => void,
  displayResend: () => void,
  selectedFactor: string,
};

const defaultProps = {
  setFormInputs: () => {},
  onKeyPress: () => {},
};

const MultiFactorAuthForm = (props: Props) => {
  const {
    intl,
    formUpdate,
    errors,
    triggerSubmit,
    triggerValidation,
    isFetching,
    onKeyPress,
    formData,
    setFormInputs,
    displayResend,
    sdkErrors,
    selectedFactor,
  } = props;

  const [isResendCode, setIsResendCode] = useState(false);
  const [isOTCSubmitted, setIsOTCSubmitted] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);

  const codeError = errors?.code;

  const onChange = event => {
    const { value, name } = event.target;
    formUpdate(name, value);
    setIsResendCode(false);
  };
  const onChangeCheckbox = event => {
    formUpdate(event.target.name, event.target.checked);
    setIsResendCode(false);
    setIsRememberMe(event.target.checked);
  };

  const onBlur = event => {
    const fieldName = event.target.name;
    if (!isResendCode) {
      setTimeout(() => validateField(fieldName), 100);
    }
  };

  const showResend = e => {
    // used with onMouseDown event to skip the triggered validation by the onBlur event
    // and remove the focus from the input validation code
    displayResend();
    setIsResendCode(true);
    e.target.focus();
    e.preventDefault();
  };

  const onSubmitCode = () => {
    validateField(FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE);
    // $FlowFixMe
    setIsOTCSubmitted(true);
    setFormInputs({
      [FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE]: {},
      [FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.REMEMBER_DEVICE]: {},
    });
    triggerSubmit();
  };

  const validateField = (fieldName: string) => {
    triggerValidation(fieldName);
  };

  const handleKeyPress = event => {
    if (!isFetching) {
      // $FlowFixMe
      onKeyPress(event, onSubmitCode);
    }
  };

  const sdkError = sdkErrors?.code;
  const codeLabel = intl.formatMessage({ id: 'text.field.validationCode' });
  const submitLabel = isFetching
    ? intl.formatMessage({ id: 'multiFactorAuth.button.loading' })
    : intl.formatMessage({ id: 'text.button.validationCodeConfirm' });

  const onUnload = e => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = '';
  };

  const interaction = selectedFactor
    ? `resend-security-code:${selectedFactor}`
    : 'resend-security-code';
  const interaction1 = selectedFactor
    ? `remember-device:${selectedFactor}`
    : 'remember-device';

  useEffect(() => {
    if (!isOTCSubmitted)
      isFetching
        ? window.removeEventListener('beforeunload', onUnload)
        : window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [isFetching, isOTCSubmitted]);

  return (
    <>
      <div className="otc-form-labels">
        <label className="dsc-label validation-code__label" htmlFor="code">
          <span>{codeLabel}</span>
        </label>
        <Link
          className="otc-form-labels__link"
          onMouseDown={showResend}
          id="otc-not-received"
          underlined="true"
          tabIndex="0"
        >
          {intl.formatMessage({ id: 'text.message.link.notreceived.bne' })}
        </Link>
        {isResendCode === true && (
          <ClickTaggingWrapper interaction={interaction} />
        )}
      </div>
      <FormGroup
        validate={{
          hasError: codeError || sdkError,
        }}
        onKeyPress={handleKeyPress}
      >
        <Input
          className={codeError || sdkError ? 'error' : ''}
          id={FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE}
          name={FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE}
          placeholder={intl.formatMessage({
            id: 'text.field.validationCodePlaceholder',
          })}
          type="text"
          autoComplete="off"
          onChange={onChange}
          onBlur={onBlur}
          aria-invalid={codeError}
          value={formData.code}
          maxLength={OTC_CODE_LENGTH}
        />
      </FormGroup>
      <Checkbox
        id={FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.REMEMBER_DEVICE}
        label={intl.formatMessage({
          id: 'multiFactorAuth.field.rememberdevice.label.bne',
        })}
        name={FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.REMEMBER_DEVICE}
        onChange={onChangeCheckbox}
      />
      {isRememberMe === true && (
        <ClickTaggingWrapper interaction={interaction1} />
      )}
      <div className="remember-device-text">
        <p className="remember-device-text__paragraph">
          {intl.formatMessage({
            id: 'multiFactorAuth.field.rememberdevice.subtext.bne',
          })}
        </p>
      </div>
      <Button
        type="submit"
        id="submitOtcForm"
        disabled={isFetching}
        onMouseDown={event => event.preventDefault()}
        onClick={onSubmitCode}
        appearance="primary"
        theme="entreprise"
        className="validate-otc-button--margins"
        fluid
      >
        {submitLabel}
      </Button>
      <ExpirationModal isShowSessionExpiredModal={false} />
    </>
  );
};

MultiFactorAuthForm.defaultProps = defaultProps;

export default MultiFactorAuthForm;
