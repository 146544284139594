import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { FormGroup } from '@bnc-ui/formGroup';
import { InputGroup } from '@bnc-ui/inputGroup';
import { forgotPasswordFormDataShape } from '../../../../utils/shapes';
import {
  FORGOT_PASSWORD_FIELDS,
  FORGOT_PASSWORD_VALIDATION_RULES,
} from '../../../../utils/forgotPasswordUtils/constants';

const propTypes = {
  intl: intlShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  formData: forgotPasswordFormDataShape.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

const CreditInfo = ({ intl, errors, formData, onChange, onBlur }) => (
  <>
    <div className="forgot-password-form--group--credit">
      <FormGroup
        label={{
          text: intl.formatMessage({
            id: 'text.label.forgotPassword.creditCardNumber',
          }),
          htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER,
        }}
        validate={{
          hasError:
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER in errors,
          errorMsg: intl.formatMessage({
            id: 'text.error.forgotPassword.creditCardNumber',
          }),
        }}
        tooltip={{
          content: intl.formatMessage({
            id: 'text.tooltip.forgotPassword.creditCardNumber',
          }),
          position: 'top',
        }}
      >
        <InputGroup
          inputId={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER}
          inputName={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER}
          className="forgot-password-form--group--credit--card"
          prefix={FORGOT_PASSWORD_VALIDATION_RULES.CREDIT.PREFIX}
          placeholder={intl.formatMessage({
            id: 'text.field.placeholder.forgotPassword.creditCardNumber',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={get(
            formData,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER,
          )}
          maxLength={FORGOT_PASSWORD_VALIDATION_RULES.CREDIT.CARD_LENGTH}
          tabIndex={0}
        />
      </FormGroup>
    </div>
    <div className="forgot-password-form--group--expiryDate">
      <FormGroup
        label={{
          text: intl.formatMessage({
            id: 'text.label.forgotPassword.expirationDate',
          }),
          htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE,
        }}
        validate={{
          hasError:
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE in errors,
          errorMsg: intl.formatMessage({
            id: 'text.error.forgotPassword.expirationDate',
          }),
        }}
      >
        <InputGroup
          inputId={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE}
          inputName={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE}
          onChange={onChange}
          onBlur={onBlur}
          mask={FORGOT_PASSWORD_VALIDATION_RULES.CREDIT.EXPIRY_DATE_MASK}
          placeholder={intl.formatMessage({
            id: 'text.field.placeholder.forgotPassword.expirationDate',
          })}
          value={get(
            formData,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE,
          )}
          tabIndex={0}
        />
      </FormGroup>
    </div>
  </>
);

CreditInfo.propTypes = propTypes;

export default CreditInfo;
