// @flow
import actionTypes from './actionTypes';

import type { SetCurrentTemplateAction } from './types';

function setCurrentTemplate(
  templateName: string,
  partnerId: string,
): SetCurrentTemplateAction {
  return {
    type: actionTypes.TEMPLATE_SET_CURRENT_TEMPLATE,
    templateName,
    partnerId,
  };
}

export default {
  setCurrentTemplate,
};
