// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getTemplateName } from '../../services/templateService/selectors';

import type { State } from '../../types/state';

import Unauthorized from './unauthorized';

function mapStateToProps(state: State) {
  return {
    templateName: getTemplateName(state),
  };
}

export default injectIntl(connect(mapStateToProps)(Unauthorized));
