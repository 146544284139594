import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Sbip2CompleteUnavailability from '../../templates/sbip2/CompleteUnavailabilityTemplate';
import NatgoCompleteUnavailability from '../../templates/natgo/CompleteUnavailabilityTemplate';
import SecureKeyCompleteUnavailability from '../../templates/secureKey/CompleteUnavailabilityTemplate';
import WhiteLabelCompleteUnavailability from '../../templates/whiteLabel/CompleteUnavailabilityTemplate';
import RewardsCompleteUnavailability from '../../templates/rewards/CompleteUnavailabilityTemplate';
import RewardsWhiteLabelCompleteUnavailability from '../../templates/rewardsWhiteLabel/CompleteUnavailabilityTemplate';
import BaoCompleteUnavailability from '../../templates/bao/CompleteUnavailabilityTemplate';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';
import urlUtils from '../../utils/urlUtils';

const propTypes = {
  templateName: PropTypes.string,
};

const defaultProps = {
  templateName: '',
};

const CompleteUnavailability = props => {
  useEffect(() => {
    urlUtils.listenPopEventAndRedirectToBaseUrl();
  });

  const { templateName } = props;

  const completeUnavailabilityTemplates = {
    [TEMPLATES.SBIP2]: <Sbip2CompleteUnavailability />,
    [TEMPLATES.OBE]: <Sbip2CompleteUnavailability />,
    [TEMPLATES.NATGO]: <NatgoCompleteUnavailability />,
    [TEMPLATES.SECURE_KEY]: <SecureKeyCompleteUnavailability />,
    [TEMPLATES.SBIP2_WHITE_LABEL]: <WhiteLabelCompleteUnavailability />,
    [TEMPLATES.REWARDS]: <RewardsCompleteUnavailability />,
    [TEMPLATES.REWARDS_WHITE_LABEL]: (
      <RewardsWhiteLabelCompleteUnavailability />
    ),
    [TEMPLATES.BAO]: <BaoCompleteUnavailability />,
  };
  return getTemplateComponent(completeUnavailabilityTemplates, templateName);
};

CompleteUnavailability.propTypes = propTypes;
CompleteUnavailability.defaultProps = defaultProps;

export default CompleteUnavailability;
