import { connect } from 'react-redux';

import ParamUnavailableError from './paramUnavailableError';

import type { State } from '../../../types/state';
import redirectServiceSelectors from '../../../services/redirectService/selectors';

function mapStateToProps(state: State) {
  return {
    redirectPageUrl: redirectServiceSelectors.getRedirectPage(state),
  };
}

export default connect(mapStateToProps)(ParamUnavailableError);
