// @flow
import React from 'react';
import cx from 'classnames';

import SignUpMigration from '../../../molecules/common/SignUpMigration';

type Props = {
  className?: string,
  hasSignUpMigration?: boolean,
};

const defaultProps = {
  className: '',
  hasSignUpMigration: false,
};

function LoginFooter(props: Props) {
  const { className, hasSignUpMigration } = props;

  return (
    <div className={cx('login-footer', className)}>
      <SignUpMigration isVisible={hasSignUpMigration} />
    </div>
  );
}

LoginFooter.defaultProps = defaultProps;

export default LoginFooter;
