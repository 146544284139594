// @flow
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import has from 'lodash/has';
import type { IntlShape } from 'react-intl';

import { Constants } from 'bnc-react-components';
import { Input } from '@bnc-ui/input';
import { Password } from '@bnc-ui/password';
import { Button } from '@bnc-ui/button';
import { Text } from '@bnc-ui/text';
import { Link } from '@bnc-ui/link';
import { Lock } from '@nbc-design/icons/lib/web/Lock';
import { Alert } from '@bnc-ui/alert';

import LoginFormMessage from '../../../LoginFormMessage';
import Greeting from '../../../../common/Greeting';

import type { SDKError } from '../../../../../types';
import type {
  LoginFormData,
  LoginFormErrors,
} from '../../../../../types/forms/loginForm';

import { WELCOME_ANNOUNCE_DELAY } from '../../../../../utils/constants/forms';

import { getWelcomeMsgKey } from '../../../../../utils/liveAnnouncer/helper';

import './style.scss';

import MobileBottomLinks from '../../../../../organisms/bne/MobileBottomLinks';
import ClickTaggingWrapper from '../../../TaggingBNE/ClickTaggingWrapper';
import PageReadyTaggingWrapper from '../../../TaggingBNE/PageReadyTaggingWrapper';
import * as cookie from '../../../../../utils/cookie';
import { getForgotPasswordUrl } from '../../../../../utils/bne/EnvironmentUtils';

const PASSWORD_FIELD_MAX_LENGTH_BNE = '250';
const IDENTITY_FIELD_MAX_LENGTH_BNE = '20';

type Props = {
  className?: string,
  intl: IntlShape,
  formData: LoginFormData,
  formUpdate: (fieldName, fieldValue) => void,
  onBlur: (event: SyntheticInputEvent<any>) => void,
  isFetching: boolean,
  onChange: (event: SyntheticInputEvent<any>) => void,
  clearLoginErrorMessages: () => void,
  errors: LoginFormErrors,
  triggerSubmit: () => void,
  setFormInputs: (inputs: any) => void,
  handleKeyPress: (event: Event) => void,
  hasForgotPwd: boolean,
  hasLoginFailure: boolean,
  sdkError: SDKError,
  hasValidationError: boolean,
  isFirstRender: ?boolean,
  announceAlertMessage: (message: string) => void,
  hasToFocus: boolean,
  partnerName: string,
  informationalMessage: string,
  locale: string,
  formDataBne: any,
  isFormDone: boolean,
  isSubmitFormFailed: boolean,
};

const defaultProps = {
  className: '',
};

const LoginWithoutIdPartialContentForm = (props: Props) => {
  const {
    intl,
    onBlur,
    formUpdate,
    formData,
    isFetching,
    onChange,
    clearLoginErrorMessages,
    errors,
    triggerSubmit,
    setFormInputs,
    handleKeyPress,
    hasForgotPwd,
    hasLoginFailure,
    sdkError,
    className,
    hasValidationError,
    isFirstRender,
    announceAlertMessage,
    hasToFocus,
    partnerName,
    informationalMessage,
    locale,
    formDataBne,
    isFormDone,
    isSubmitFormFailed,
  } = props;

  const [buttonClicked, setButtonClicked] = useState(false);
  let isFailureMessageVisible: boolean = false;
  const FAILURE_MESSAGE_COOKIE = 'unifiedLoginFailureMessage';
  useEffect(() => {
    if (hasToFocus) {
      // Focus identity input field when component is rendered
      const identityInput = document.querySelector('#identity');
      if (identityInput) {
        identityInput.focus();
      }
    }
    if (isFirstRender === null || isFirstRender) {
      setTimeout(
        () => announceAlertMessage(getWelcomeMsgKey(partnerName)),
        WELCOME_ANNOUNCE_DELAY,
      );
    }
  }, [hasToFocus, isFirstRender, partnerName, announceAlertMessage, locale]);

  const decoder = cookie.get(FAILURE_MESSAGE_COOKIE);
  if (typeof decoder !== 'undefined') {
    isFailureMessageVisible = false;
    if (decoder[locale] && decoder[locale].toLowerCase() !== 'null') {
      isFailureMessageVisible = true;
    }
  }

  const loginFormInputs = {};

  const hasIdentityError = has(errors, 'identityBne');
  const hasPasswordError = has(errors, 'passwordBne');

  const identityErrorClassName = hasIdentityError
    ? 'error loginForm_flexitem'
    : '';
  const passwordErrorClassName = hasPasswordError
    ? 'error loginForm_flexitem'
    : '';

  const checkFormFields = () => {
    // Fix to trigger the onChange and OnBlur events on the username and the password inputs
    // onChange/onBlur helps to update and validate form fields else the submit button won't work
    // after clicking on back to sign in  then sign in
    if (!!formDataBne.identityBne && !!formDataBne.passwordBne) {
      const identityInput = document.querySelector('#identityBne');
      const passwordInput = document.querySelector('#password');
      const submitButton = document.querySelector('#login__submit');
      identityInput.focus();
      passwordInput.focus();
      submitButton.focus();
    }
  };
  const onSubmit = event => {
    event.preventDefault();
    checkFormFields();
    setFormInputs(loginFormInputs);
    triggerSubmit();
  };

  // Update form.identity on form.identityBne edit
  const onChangeIdentityBne = (event: SyntheticInputEvent<any>) => {
    formUpdate('identityBne', event.target.value);
    formUpdate('identity', event.target.value);
    onChange(event);
  };
  const onBlurIdentityBne = (event: SyntheticInputEvent<any>) => {
    formUpdate('identityBne', event.target.value);
    formUpdate('identity', event.target.value);
    onBlur(event);
  };

  const onChangePassword = (event: SyntheticInputEvent<any>) => {
    formUpdate('passwordBne', event.target.value);
    formUpdate('password', event.target.value);
    onChange(event);
  };

  const onBLurPassword = (event: SyntheticInputEvent<any>) => {
    formUpdate('passwordBne', event.target.value);
    formUpdate('password', event.target.value);
    onBlur(event);
  };

  const interaction = 'forgot-password';

  formData.remember = false;

  return (
    <PageReadyTaggingWrapper locale={intl.locale} isLoading={isFormDone}>
      <div className={cx(className)} role="none" onKeyPress={handleKeyPress}>
        <h2 className="a11y-hide">
          {intl.formatMessage({ id: 'text.aria.greetingsContext' })}
        </h2>
        <Greeting tagName="span" className="title" />
        {informationalMessage && <Text>{informationalMessage}</Text>}
        {isFailureMessageVisible && (
          <Alert
            title={decoder[locale]}
            appearance="information"
            size="small"
            className="unifiedLoginFailureMessage"
            isClosable
          />
        )}
        <LoginFormMessage
          errors={errors}
          hasValidationError={hasValidationError}
          hasLoginFailure={hasLoginFailure}
          sdkError={sdkError}
          clearLoginErrorMessages={clearLoginErrorMessages}
          isSubmitFailed={isSubmitFormFailed}
        />
        <div className="combo">
          <label className="login_label" htmlFor="identityBne">
            {intl.formatMessage({
              id: 'text.label.identity.bne',
            })}
          </label>
          <div className="combo__entry combo__entry--small">
            <Input
              type="text"
              name="identityBne"
              placeholder={intl.formatMessage({
                id: 'text.bne.field.identityPlaceholder.bne',
              })}
              id="identityBne"
              onChange={onChangeIdentityBne}
              onBlur={onBlurIdentityBne}
              className={cx(identityErrorClassName, className)}
              autoComplete={Constants.AUTOCOMPLETE.USER_NAME}
              aria-invalid={hasIdentityError}
              maxLength={IDENTITY_FIELD_MAX_LENGTH_BNE}
              value={formData.identityBne}
            />
          </div>
          <label className="login_label" htmlFor="password">
            {intl.formatMessage({
              id: 'text.label.password',
            })}
          </label>
          <div className="combo__entry combo__entry--small">
            <Password
              id="password"
              name="passwordBne"
              placeholder={intl.formatMessage({
                id: 'text.field.passwordConfirmationPlaceholder.bne',
              })}
              className={cx(passwordErrorClassName, className)}
              autoComplete={Constants.AUTOCOMPLETE.CURRENT_PASSWORD}
              maxLength={PASSWORD_FIELD_MAX_LENGTH_BNE}
              aria-invalid={hasPasswordError}
              ariaLabelShow={intl.formatMessage({
                id: 'title.field.passwordButton.show',
              })}
              ariaLabelHide={intl.formatMessage({
                id: 'title.field.passwordButton.hide',
              })}
              value={formData.passwordBne}
              onChange={onChangePassword}
              onBlur={onBLurPassword}
            />
          </div>
          {hasForgotPwd && (
            <Text
              size="small"
              className="link-forgot-password-paragraph__padding"
            >
              <Link
                href={getForgotPasswordUrl()}
                id="link-forgot-password"
                underlined="true"
                onClick={isFetching ? () => {} : () => setButtonClicked(true)}
                tabIndex="0"
              >
                {intl.formatMessage({ id: 'text.link.forgotPassword.bne' })}
              </Link>
            </Text>
          )}
          {buttonClicked === true && (
            <ClickTaggingWrapper interaction={interaction} />
          )}
        </div>
        <Button
          appearance="primary"
          theme="entreprise"
          fluid
          size="large"
          disabled={isFetching}
          onClick={isFetching ? () => {} : onSubmit}
          className="login__submit"
          type="button"
          id="login__submit"
          loading={{
            isLoading: isFetching,
            ariaLabel: intl.formatMessage({ id: 'loginForm.button.loading' }),
          }}
        >
          <Lock size="small" title="lock" className="lockPadding" />
          <div className="notranslate">
            {intl.formatMessage({ id: 'text.button.login' })}
          </div>
        </Button>

        <MobileBottomLinks />
      </div>
    </PageReadyTaggingWrapper>
  );
};

LoginWithoutIdPartialContentForm.defaultProps = defaultProps;

export default LoginWithoutIdPartialContentForm;
