import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { executedActionSelector } from '../../../services/forgotPasswordFormService/selectors';
import ForgotPasswordForm from './forgotPasswordForm';

const mapStateToProps = state => ({
  executedAction: executedActionSelector(state),
});

export default injectIntl(connect(mapStateToProps)(ForgotPasswordForm));
