// @flow
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import SendMFACode from '../SendMFACode';

import './style.scss';

type Props = {
  triggerCodeRequest: (factor: *) => void,
  isHideResend: boolean,
};

const ResendMFACode = (props: Props) => {
  const { triggerCodeRequest, isHideResend } = props;

  const triggerCodeRequestFactorType = factor => {
    triggerCodeRequest(factor.factorType);
  };

  return (
    <CSSTransition
      in={!isHideResend}
      classNames="resend-group--animated"
      timeout={500}
      unmountOnExit
    >
      <div id="multiFactorResendAuthChoice">
        <SendMFACode triggerCodeRequest={triggerCodeRequestFactorType} />
      </div>
    </CSSTransition>
  );
};

export default ResendMFACode;
