// @flow
import React from 'react';

import { Browser, SupportedBrowserList } from '../../../types/browser';

import FullScreenCenterTemplate from '../../models/FullScreenCenterTemplate';

import UnsupportedBrowserHeader from '../../../organisms/common/UnsupportedBrowserHeader';
import UnsupportedBrowserFooter from '../../../organisms/common/UnsupportedBrowserFooter';
import BrowserList from '../../../organisms/common/BrowserList';

import './style.scss';

type Props = {
  browser: Browser,
  browserVersions: SupportedBrowserList,
  browserList: Array<Browser>,
};

const UnsupportedBrowserTemplate = (props: Props) => {
  const { browser, browserVersions, browserList } = props;

  const unsupportedBrowserContent = (
    <div className="browser-unsupported">
      <UnsupportedBrowserHeader
        browser={browser}
        browserVersions={browserVersions}
      />
      <BrowserList
        browserList={browserList}
        browserVersions={browserVersions}
      />
      <UnsupportedBrowserFooter />
    </div>
  );

  return (
    <FullScreenCenterTemplate
      main={unsupportedBrowserContent}
      className="error-background"
    />
  );
};

export default UnsupportedBrowserTemplate;
