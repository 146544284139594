// @flow
const LANG = {
  FR: 'fr',
  EN: 'en',
};

export type Lang = $Keys<typeof LANG>;

export type LangAvailable = Array<Lang>;

const I18N = {
  LANG,
  LANG_AVAILABLE: [LANG.FR, LANG.EN],
  LANG_DEFAULT: LANG.FR,
  COOKIE_NAME: 'bneLanguage',
};

export type I18n = Lang | LangAvailable | 'LANG_DEFAULT' | 'COOKIE_NAME';

export const ACCEPT_LANGUAGE_HEADER = 'Accept-Language';

export default I18N;
