// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import * as actionTypes from './actionTypes';
import workers from './workers';

export const workersMapping = createWorkersMapping().takeLatest(
  actionTypes.USER_AGENT_INIT_REQUEST,
  workers.watchInitUserAgentRequest,
);

export default createRootSagaFromWorkersMapping(workersMapping);
