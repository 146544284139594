// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Redirect from './redirect';

import type { Dispatch } from '../../../types/store';
import redirectServiceActions from '../../../services/redirectService/actions';

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    redirectToPage: (pageUrl: string) =>
      dispatch(redirectServiceActions.redirectToPage(pageUrl)),
  };
}

export default injectIntl(connect(null, mapDispatchToProps)(Redirect));
