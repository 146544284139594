// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { announceMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';
import {
  setBackendError,
  verifyOTC,
} from '../../../services/forgotPasswordService/actions';
import {
  getAnalytics,
  getBackendError,
  isFetchingSelector,
  isForgotPasswordFlowSelector,
  showOTCSuccessMessageSelector,
} from '../../../services/forgotPasswordService/selectors';
import { returnToLogin } from '../../../services/loginService/actions';
import {
  formUpdate,
  triggerValidation,
} from '../../../services/multiFactorAuthFormService/actions';
import {
  getContact,
  getFactorTypes,
  getMFAExecutedAction,
  getMFAFormData,
  getMFAFormErrors,
  getSelectedFactor,
  isHideResend,
} from '../../../services/multiFactorAuthFormService/selectors';
import type { State } from '../../../types/state';
import type { Dispatch } from '../../../types/store';
import MultiFactorAuth from './multiFactorAuth';
import { getAuthForgotPasswordErrorMessageId } from '../../../utils/authErrorMessages';
import { getPartnerIdName } from '../../../services/templateService/selectors';

const mapStateToProps = (state: State) => ({
  formData: getMFAFormData(state),
  errors: getMFAFormErrors(state),
  isFetching: isFetchingSelector(state),
  sdkError: getBackendError(state),
  isHideResend: isHideResend(state),
  factorTypes: getFactorTypes(state),
  selectedFactor: getSelectedFactor(state),
  contact: getContact(state),
  executedAction: getMFAExecutedAction(state),
  isShowResendOTCSuccessMessage: showOTCSuccessMessageSelector(state),
  getAuthErrorMessageId: getAuthForgotPasswordErrorMessageId,
  isForgotPasswordFlow: isForgotPasswordFlowSelector(state),
  forgotPasswordFlowId: getAnalytics(state).flowId,
  partnerIdName: getPartnerIdName(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  formUpdate: (fieldName, fieldValue) => {
    dispatch(setBackendError({}));
    dispatch(formUpdate(fieldName, fieldValue));
  },
  triggerValidation: fieldName => dispatch(triggerValidation(fieldName, true)),
  triggerSubmit: () => dispatch(verifyOTC()),
  returnToLogin: () => dispatch(returnToLogin()),
  announceMessage: msg => dispatch(announceMessage(msg, 'polite')),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuth),
);
