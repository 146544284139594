// @flow
import React, { Component } from 'react';
import sessionManager from 'bnc-react-services/managers/SessionManager';

import Sbip2MultiFactorAuthTemplate from '../../templates/sbip2/MultiFactorAuthTemplate';
import WhiteLabelMultiFactorAuthTemplate from '../../templates/whiteLabel/MultiFactorAuthTemplate';
import SecureKeyMultiFactorAuthTemplate from '../../templates/secureKey/MultiFactorAuthTemplate';
import NatgoMultiFactorAuthTemplate from '../../templates/natgo/MultiFactorAuthTemplate';
import BneMultiFactorAuthTemplate from '../../templates/bne/MultiFactorAuthTemplate';
import RewardsMultiFactorAuthTemplate from '../../templates/rewards/MultiFactorAuthTemplate';
import BaoMultiFactorAuthTemplate from '../../templates/bao/MultiFactorAuthTemplate';
import RewardsWhiteLabelMultiFactorAuthTemplate from '../../templates/rewardsWhiteLabel/MultiFactorAuthTemplate';

import browserHistory from '../../nav/BrowserHistoryManager';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';
import ROUTES from '../../utils/constants/routes';

type Props = {
  templateName: string,
};

class MultiFactorAuth extends Component<Props> {
  componentDidMount = () => {
    const transactionStatus = sessionManager.getTransactionStatus();

    // Check if an Okta transaction has been started already
    if (!transactionStatus) {
      browserHistory.push(`${ROUTES.LOGIN}${window.location.search}`);
    }
  };

  render() {
    const { templateName } = this.props;

    const loginTemplates = {
      [TEMPLATES.SBIP2]: <Sbip2MultiFactorAuthTemplate />,
      [TEMPLATES.OBE]: <Sbip2MultiFactorAuthTemplate />,
      [TEMPLATES.SBIP2_WHITE_LABEL]: <WhiteLabelMultiFactorAuthTemplate />,
      [TEMPLATES.SECURE_KEY]: <SecureKeyMultiFactorAuthTemplate />,
      [TEMPLATES.NATGO]: <NatgoMultiFactorAuthTemplate />,
      [TEMPLATES.BNE]: <BneMultiFactorAuthTemplate />,
      [TEMPLATES.REWARDS]: <RewardsMultiFactorAuthTemplate />,
      [TEMPLATES.BAO]: <BaoMultiFactorAuthTemplate />,
      [TEMPLATES.REWARDS_WHITE_LABEL]: (
        <RewardsWhiteLabelMultiFactorAuthTemplate />
      ),
    };

    return getTemplateComponent(loginTemplates, templateName);
  }
}

export default MultiFactorAuth;
