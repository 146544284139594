// @flow
import React from 'react';
import cx from 'classnames';

import type { Node } from 'react';

import BncBrand from '../../../molecules/common/BncBrand';
import SwitchLang from '../../../molecules/common/SwitchLang';

type Props = {
  className?: string,
  hasLogo?: boolean,
  logoTagName?: string,
  hasTitle?: boolean,
  title?: Node | string,
  hasSwitchLang?: boolean,
  hasTagBrand?: boolean,
};

const defaultProps = {
  className: '',
  hasLogo: true,
  logoTagName: 'div',
  hasTitle: true,
  title: '',
  hasSwitchLang: true,
  hasTagBrand: true,
};

function Header(props: Props) {
  const {
    className,
    hasLogo,
    logoTagName,
    hasTitle,
    title,
    hasSwitchLang,
    hasTagBrand,
  } = props;

  return (
    <div className={cx('header', className)}>
      <SwitchLang isVisible={hasSwitchLang} />
      <BncBrand
        isVisible={hasLogo}
        logoTagName={logoTagName}
        hasTagBrand={hasTagBrand}
      />
      {hasTitle && !!title && <div>{title}</div>}
    </div>
  );
}

Header.defaultProps = defaultProps;

export default Header;
