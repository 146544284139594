import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { intlShape, FormattedHTMLMessage } from 'react-intl';

import { Heading } from '@bnc-ui/heading';
import { Alert } from '@bnc-ui/alert';

import LockLogo from '../../../atoms/LockLogo';

import './style.scss';

const propTypes = {
  className: PropTypes.string,
  intl: intlShape.isRequired,
};

const defaultProps = {
  className: '',
};

const ForbiddenActionAlert = props => {
  const { intl, className } = props;

  return (
    <Alert
      className={cx('forbidden-actions__alert', className)}
      appearance="neutral"
    >
      <LockLogo />
      <div>
        <Heading type="span" size={3}>
          {intl.formatMessage({ id: 'text.title.forbiddenInstructions' })}
        </Heading>
        <FormattedHTMLMessage
          tagName="ul"
          id="text.message.forbiddenInstructions"
        />
      </div>
    </Alert>
  );
};

ForbiddenActionAlert.propTypes = propTypes;
ForbiddenActionAlert.defaultProps = defaultProps;

export default ForbiddenActionAlert;
