import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getPartnerIdName } from '../../../services/templateService/selectors';
import HeadTitle from './headTitle';

function mapStateToProps(state) {
  return {
    partnerId: getPartnerIdName(state),
  };
}

export default injectIntl(connect(mapStateToProps)(HeadTitle));
