import get from 'lodash/get';
import includes from 'lodash/includes';

import { Configs } from '../configs';

export const getFeatures = (templateName: string) =>
  get(Configs, ['TEMPLATES', templateName, 'AVAILABLE_FEATURE'], []);

export const hasFeature = (templateName: string, featureName: string) => {
  const availableFeatures = getFeatures(templateName);
  return includes(availableFeatures, featureName.toUpperCase());
};
