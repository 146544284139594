// @flow
import type { Action as ReduxAction } from '../../types/action';
import actionTypes from './actionTypes';

export type SetCurrentTemplateAction = ReduxAction<
  typeof actionTypes.TEMPLATE_SET_CURRENT_TEMPLATE,
> & {
  templateName: string,
  partnerId: string,
};

export type Action = SetCurrentTemplateAction;
