import get from 'lodash/get';
import toLower from 'lodash/toLower';
import { FORGOT_PASSWORD_CODE_TECHNICAL_ERROR } from './authErrorMessages';
import {
  PASSWORD_POLICIES_ERROR_CODE,
  POLICIES_NOT_MET,
} from './constants/forgotPassword';
import extraHeaders from './extraHeaders';

export const addExtraHeaders = (options, headers) => ({
  ...options,
  headers: { ...options.headers, ...headers },
});

export const bncFetch = (url, options) => {
  const finalOptions = addExtraHeaders(options, extraHeaders);
  console.group('BncFetch');
  console.log('url = ', url);
  console.log('option = ', finalOptions);
  console.groupEnd();
  return fetch(url, finalOptions);
};

export const iamxFetch = (url, options, errorIdPrefix) => {
  const query = bncFetch(url, options);

  // in case body could not be parsed as JSON (for example body is empty), let returns {}.
  const resultAsJson = query.then(response => response.json().catch(e => ({})));

  return Promise.all([query, resultAsJson])
    .then(([resultFetch, jsonResult]) => {
      if (!resultFetch.ok) {
        const code =
          get(jsonResult, 'error.errorCode', '') ||
          FORGOT_PASSWORD_CODE_TECHNICAL_ERROR;

        const message =
          jsonResult.message ||
          jsonResult.errorSummary ||
          jsonResult.error.message ||
          '';

        // account is locked for a certain amount of time
        if (toLower(code) === 'account_locked') {
          // BEGIN:  temporally consider account locked response as generic technical error
          const genericTechnicalError = {
            code: FORGOT_PASSWORD_CODE_TECHNICAL_ERROR,
            message: '',
            errorIdPrefix,
          };
          throw genericTechnicalError;
          // END:  temporally consider account locked response as generic technical error
        }

        const throwObj = {
          status: resultFetch.status,
          code:
            message === POLICIES_NOT_MET ? PASSWORD_POLICIES_ERROR_CODE : code,
          message,
          errorIdPrefix,
        };

        throw throwObj;
      }
      return jsonResult;
    })
    .catch(err => {
      // catch runtime fetch error
      const throwObj = {
        code: err.code,
        message: err.message,
        errorIdPrefix,
      };
      throw throwObj;
    });
};
