import browserHistory from '../../nav/BrowserHistoryManager';
import { ROUTES } from '../../utils/constants';
import Biometry from './biometry';
import type { OS } from '../../types/browser';

const format = (value, pattern) => {
  let i = 0;
  const v = value.toString();

  // eslint-disable-next-line no-plusplus
  return pattern.replace(/#/g, _ => v[i++]);
};

export const anonymizeUsername = username => format(username, '###*****');

export const redirectToLogin = () =>
  browserHistory.push(`${ROUTES.LOGIN}${window.location.search}`);

const osMapping = (name: string): string => {
  if (name === 'iOS') {
    return 'apple';
  }

  return 'android';
};

const biometryTypeMapping = (type: string, os: string): string => {
  const facial = 'facialRecognition';
  if (os === 'apple') {
    return type === facial ? 'face-id' : 'touch-id';
  }

  return type === facial ? 'face' : 'fingerprint';
};

export const getIcon = (biometry: Biometry, os: OS) => {
  const osDistro = osMapping(os.name);
  return `${osDistro}-${biometryTypeMapping(biometry.type, osDistro)}`;
};

export default anonymizeUsername;
