// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import * as actionTypes from './actionTypes';
import * as workers from './workers';

export const workersMapping = createWorkersMapping()
  .takeLatest(actionTypes.I18N_SERVICE_CHANGE_LOCALE, workers.watchChangeLocale)
  .takeLatest(
    actionTypes.I18N_SERVICE_CHANGE_LOCALE_EXT,
    workers.watchChangeLocaleExt,
  );

export default createRootSagaFromWorkersMapping(workersMapping);
