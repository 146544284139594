import { createSelector } from 'reselect';
import get from 'lodash/get';

import { TRANSACTION_STATUS } from 'bnc-react-services/managers/AuthManager/constants';
import getReducerState from '../../utils/stateSelectorHelper';

import {
  MAX_IDENTITIES_SAVED,
  RESEND_DISPLAY_COUTDOWN,
} from '../../utils/constants/login';

import { LOGIN_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';

import { getFormIdentity } from '../loginFormService/selectors';

export const getLoginState = (state, modulePath = '') =>
  getReducerState(state, modulePath, LOGIN_SERVICE_REDUCER);

export const getIdentities = createSelector(getLoginState, loginState =>
  get(loginState, 'identities', []),
);

export const hasLoginFailed = createSelector(getLoginState, loginState =>
  get(loginState, 'failure', false),
);

export const isFetching = createSelector(getLoginState, loginState =>
  get(loginState, 'isFetching', false),
);

export const isHideResend = createSelector(getLoginState, loginState =>
  get(loginState, 'hideResend', false),
);

export const getContact = createSelector(getLoginState, loginState =>
  get(loginState, 'contact', ''),
);

export const getMFAselected = createSelector(getLoginState, loginState =>
  get(loginState, 'MFAselected', ''),
);

export const getSdkError = createSelector(getLoginState, loginState =>
  get(loginState, 'sdkError', {}),
);

export const hasMaxIdentities = createSelector(
  getIdentities,
  identities => identities.length >= MAX_IDENTITIES_SAVED,
);

export const hasIdentities = createSelector(
  getIdentities,
  identities => identities.length > 0,
);

export const getIdentityWithEmail = createSelector(
  [getIdentities, getFormIdentity],
  (identities, formIdentity) =>
    get(
      identities.filter(identity => identity.login === formIdentity),
      0,
      null,
    ),
);

export const getMFAfactors = createSelector(getLoginState, loginState =>
  get(loginState, 'MFAfactors', []).map(factorType => ({
    factorType,
    value: '', // login flow does not require to display contact in resend buttons
  })),
);

export const isEnrollAfterBeenChallenged = createSelector(
  getLoginState,
  loginState =>
    get(loginState, 'isEnrollAfterBeenChallenged', false) &&
    get(loginState, 'transactionStatus', '') ===
      TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE,
);

export const getLoginFactorsHint = createSelector(getLoginState, loginState =>
  get(loginState, 'MFAfactorsHint', []).map(cursor => {
    const factorTypeKey = Object.keys(cursor)[0];
    return {
      factorType: factorTypeKey.toLowerCase(),
      value: cursor[factorTypeKey],
    };
  }),
);

export const showOTCSuccessMessageSelector = createSelector(
  getLoginState,
  loginState => get(loginState, 'showOTCSuccessMessage', false),
);

export const getIdentityWithDeviceToken = createSelector(
  getLoginState,
  loginState => get(loginState, 'identityWithDeviceToken', []),
);

export const getLockedStatus = createSelector(getLoginState, loginState =>
  get(loginState, 'lockedStatus', ''),
);

export const getMFAfactorsWithValues = createSelector(
  getLoginState,
  loginState => get(loginState, 'MFAfactors', []),
);

export const getMfaDisplayResendCounter = createSelector(
  getLoginState,
  loginState =>
    get(loginState, 'mfaDisplayResendCounter', RESEND_DISPLAY_COUTDOWN),
);

export const getLockedUserPassword = createSelector(getLoginState, loginState =>
  get(loginState, 'userPasswordLocked', false),
);

export const getLastLoginFailureDate = createSelector(
  getLoginState,
  loginState => get(loginState, 'lastLoginFailureDate', {}),
);

export const getHashLogin = createSelector(getLoginState, loginState =>
  get(loginState, 'hashLogin', ''),
);
