import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import Modal from '@bnc-ui/modal';
import { Heading } from '@bnc-ui/heading';

import './style.scss';

import SessionExpired from '../../../molecules/common/SessionExpired';
import MFAExpired from '../../../molecules/common/MFAExpired';
import ExpiredModalTaggingWrapper from '../../../molecules/bne/TaggingBNE/ExpiredModalTaggingWrapper';

const propTypes = {
  intl: intlShape.isRequired,
  isShowSessionExpiredModal: PropTypes.bool.isRequired,
  isShowMFACodeExpiredModal: PropTypes.bool.isRequired,
};

function ExpirationModal(props) {
  const { intl, isShowSessionExpiredModal, isShowMFACodeExpiredModal } = props;

  const modalTitle = isShowMFACodeExpiredModal
    ? intl.formatMessage({
        id: 'text.title.expiredMFA',
      })
    : intl.formatMessage({
        id: 'text.title.expiredSession',
      });

  const displayMainContent = () =>
    isShowMFACodeExpiredModal ? <MFAExpired /> : <SessionExpired />;

  const isShow = isShowSessionExpiredModal || isShowMFACodeExpiredModal;

  const modalType = isShowMFACodeExpiredModal ? 'MFA' : 'Session';

  return (
    isShow && (
      <>
        <Modal className="expiration-modal" appearance="light" isDialog>
          <Modal.Header
            isCloseHidden
            closeBtnAriaLabel={intl.formatMessage({
              id: 'global.modal.close.button',
            })}
          />
          <Modal.Body>
            <Heading type="h2" size={2}>
              {modalTitle}
            </Heading>
            {displayMainContent()}
          </Modal.Body>
        </Modal>
        <ExpiredModalTaggingWrapper modalType={modalType} />
      </>
    )
  );
}

ExpirationModal.propTypes = propTypes;

export default ExpirationModal;
