// @flow
import join from 'lodash/join';

export const OPTION_PATH = 'options';

export const CONFIG_KEYS = {
  GLOBAL: 'global',
  MAINTENANCE_MODE: 'maintenance_mode',
  UNAVAILABLE_MODE: 'unavailable_mode',
  SIGN_UP: {
    GLOBAL: 'sign_up',
  },
  FORGOT_PASSWORD: {
    GLOBAL: 'forgot_password',
    CREDIT_CARD: 'credit_card',
  },
  LOGIN: {
    GLOBAL: 'login',
    ALERT: 'alert',
  },
  CHATBOT: {
    GLOBAL: 'chatbot',
    LOGIN: 'chatbot.options.login',
  },
};

export const CONFIGS_NAMES = {
  GLOBAL: {
    UNAVAILABLE_MODE: `${CONFIG_KEYS.GLOBAL}.${OPTION_PATH}.${CONFIG_KEYS.UNAVAILABLE_MODE}`,
    MAINTENANCE_MODE: `${CONFIG_KEYS.GLOBAL}.${OPTION_PATH}.${CONFIG_KEYS.MAINTENANCE_MODE}`,
  },
  SIGN_UP: {
    ...CONFIG_KEYS.SIGN_UP,
  },
  FORGOT_PASSWORD: {
    ...CONFIG_KEYS.FORGOT_PASSWORD,
    CREDIT_CARD: join(
      [
        CONFIG_KEYS.FORGOT_PASSWORD.GLOBAL,
        OPTION_PATH,
        CONFIG_KEYS.FORGOT_PASSWORD.CREDIT_CARD,
      ],
      '.',
    ),
  },
  CHATBOT: {
    ...CONFIG_KEYS.CHATBOT,
  },
};
