// @flow
import React from 'react';
import type { intlShape } from 'react-intl';
import get from 'lodash/get';
import { FormGroup } from '@bnc-ui/formGroup';
import { Input } from '@bnc-ui/input';
import { Button } from '@bnc-ui/button';
import { TrackAction } from '@nbc-studio/analytics';
import type { MultiFactorAuthFormData } from '../../../types/forms/multiFactorAuthForm';
import { FORGOT_PASSWORD_FIELDS } from '../../../utils/forgotPasswordUtils/constants';
import { OTC_CODE_LENGTH } from '../../../services/multiFactorAuthFormService/formSchema';
import {
  EVENT_IDS,
  EVENT_NAMES,
  EVENT_SOURCE,
  UCS_IDS,
  UCS_STATE,
} from '../../../utils/taggingUtils/constants';

import './style.scss';

type Props = {
  intl: intlShape,
  isFetching: boolean,
  formData: MultiFactorAuthFormData,
  formUpdate: (fieldName: string, fieldValue: string) => void,
  triggerSubmit: () => void,
  triggerValidation: (fieldName: string) => void,
  errors: *,
  setFormInputs?: (input: any) => void,
  partnerIdName: string,
};

const defaultProps = {
  setFormInputs: () => {},
};

const MultiFactorAuthForm = (props: Props) => {
  const {
    intl,
    formUpdate,
    errors,
    triggerSubmit,
    triggerValidation,
    isFetching,
    formData,
    setFormInputs,
    partnerIdName,
  } = props;

  const onChange = event => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    formUpdate(event.target.name, value);
  };

  const onBlur = event => {
    const fieldName = event.target.name;
    triggerValidation(fieldName);
  };

  const onSubmitCode = () => {
    triggerValidation(FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE);
    // $FlowFixMe
    setFormInputs({ [FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE]: {} });
    triggerSubmit();
  };

  const handleKeyPress = event => {
    if (!isFetching && get(event, 'key', '').toUpperCase() === 'ENTER') {
      onSubmitCode();
    }
  };

  const codeError = get(errors, 'code.0');
  const codeLabel = intl.formatMessage({ id: 'text.field.validationCode' });
  const submitLabel = isFetching
    ? intl.formatMessage({ id: 'multiFactorAuth.button.loading' })
    : intl.formatMessage({ id: 'text.button.validationCodeConfirm' });

  return (
    <>
      <FormGroup
        className="mfa-validation-code"
        label={{
          text: codeLabel,
          htmlFor: FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE,
        }}
        validate={{
          hasError: !!codeError,
          errorMsg: intl.formatMessage({
            id: codeError || 'text.message.validationCodeError',
          }),
        }}
        onKeyPress={handleKeyPress}
      >
        <Input
          id={FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE}
          name={FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE}
          placeholder={intl.formatMessage({
            id: 'text.field.validationCodePlaceholder',
          })}
          type="text"
          autoComplete="off"
          onChange={onChange}
          onBlur={onBlur}
          value={formData.code}
          maxLength={OTC_CODE_LENGTH}
          autoFocus
        />
      </FormGroup>
      {codeError && (
        <TrackAction
          ucsId={UCS_IDS.FORM_ERROR}
          ucsState={UCS_STATE.NON_PV}
          eventName={EVENT_NAMES.FORM_ERROR}
          eventId={EVENT_IDS.FORM_ERROR}
          eventSource={partnerIdName || EVENT_SOURCE.SBIP2}
          formError={`${FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE}:invalid`}
          dependencies={[errors, partnerIdName]}
        />
      )}
      <Button
        type="submit"
        disabled={isFetching}
        onMouseDown={event => event.preventDefault()}
        onClick={!isFetching ? onSubmitCode : () => {}}
        appearance="primary"
        fluid
      >
        {submitLabel}
      </Button>
    </>
  );
};

MultiFactorAuthForm.defaultProps = defaultProps;

export default MultiFactorAuthForm;
