import { hasFeature } from '../featureUtils';

describe('check feature presence for template', () => {
  it('should return true when feature exists for template', () => {
    expect(hasFeature('SECURE_KEY', 'SESSION_EXPIRED_PAGE')).toBe(true);
  });

  it('should return false when feature does not exist for template', () => {
    expect(hasFeature('SBIP2', 'FAKE_FEATURE')).toBe(false);
  });
});
