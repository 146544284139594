import { testSaga } from 'bnc-utilities-js/testUtils/saga';
import browserHistory from '../../../nav/BrowserHistoryManager/index';
import {
  watchCannotContinue,
  watchContinueAnyway,
  watchInitUnsupportedBrowser,
} from '../workers';

import { ROUTES } from '../../../utils/constants';

import { shouldShowIE11Page } from '../helper';
import { IE11_BROWSER_NAME } from '../../../utils/browserUtils';
import { openExternalInSameTab } from '../../../utils/externalUrlUtils';

jest.mock('react-router');
browserHistory.push = jest.fn();

jest.mock('../helper', () => ({
  shouldShowIE11Page: jest.fn(),
}));

jest.mock('../../../utils/externalUrlUtils', () => ({
  openExternalInSameTab: jest.fn(),
}));

afterEach(() => {
  jest.clearAllMocks();
});

describe('.watchInitUnsupportedBrowser', () => {
  test('.watchInitUnsupportedBrowser - browser is supported', async () => {
    const { sagaDone } = testSaga({
      saga: watchInitUnsupportedBrowser,
      state: {
        unsupportedBrowserServiceReducer: {
          continueWithUnsupportedBrowser: false,
        },
        configsServiceReducer: {
          isInWebView: false,
        },
        templateServiceReducer: {
          templateName: 'SBIP2',
        },
      },
      args: [{ isSupportedBrowser: true }],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(browserHistory.push).not.toBeCalled();
  });

  test('.watchInitUnsupportedBrowser - browser is unsupported - continue anyway', async () => {
    const { sagaDone } = testSaga({
      saga: watchInitUnsupportedBrowser,
      state: {
        unsupportedBrowserServiceReducer: {
          continueWithUnsupportedBrowser: true,
        },
        configsServiceReducer: {
          isInWebView: false,
        },
        templateServiceReducer: {
          templateName: 'SBIP2',
        },
      },
      args: [{ isSupportedBrowser: false }],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(browserHistory.push).not.toBeCalled();
  });

  test('.watchInitUnsupportedBrowser - browser is unsupported', async () => {
    const { sagaDone } = testSaga({
      saga: watchInitUnsupportedBrowser,
      state: {
        unsupportedBrowserServiceReducer: {
          continueWithUnsupportedBrowser: false,
        },
        configsServiceReducer: {
          isInWebView: false,
        },
        templateServiceReducer: {
          templateName: 'SBIP2',
        },
      },
      args: [{ isSupportedBrowser: false }],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(browserHistory.push).toBeCalledWith(ROUTES.UNSUPPORTED_BROWSER);
  });

  test('.watchInitUnsupportedBrowser - browser is not unsupported IE11', async () => {
    shouldShowIE11Page.mockReturnValueOnce(true);

    const { sagaDone } = testSaga({
      saga: watchInitUnsupportedBrowser,
      state: {
        unsupportedBrowserServiceReducer: {
          continueWithUnsupportedBrowser: false,
        },
        configsServiceReducer: {
          isInWebView: false,
        },
        userAgentServiceReducer: {
          browser: {
            name: IE11_BROWSER_NAME,
          },
        },
        templateServiceReducer: {
          templateName: 'SBIP2',
        },
      },
      args: [{ isSupportedBrowser: true }],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(browserHistory.push).toBeCalledWith(ROUTES.UNSUPPORTED_BROWSER);
  });
});

describe('.watchContinueAnyway', () => {
  test('.watchContinueAnyway', async () => {
    const { sagaDone } = testSaga({
      saga: watchContinueAnyway,
      state: {},
      args: [{}],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(browserHistory.push).toBeCalledWith(ROUTES.LOGIN);
  });
});

describe('.watchCannotContinue', () => {
  test('.watchCannotContinue', async () => {
    const { sagaDone } = testSaga({
      saga: watchCannotContinue,
      state: {},
      args: [{}],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(openExternalInSameTab).toBeCalled();
  });
});
