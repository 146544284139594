// @flow
import React from 'react';

import type { IntlShape } from 'react-intl';

import { getPartnerImage } from './helpers';

type Props = {
  intl: IntlShape,
  isVisible?: boolean,
  partnerId: string,
};

const defaultProps = {
  isVisible: true,
};

function WhiteLabelBrand(props: Props) {
  const { intl, isVisible, partnerId } = props;

  if (!isVisible) {
    return null;
  }

  const altText = intl.formatMessage({ id: `text.title.${partnerId}.logo` });
  return <img src={getPartnerImage(partnerId, intl.locale)} alt={altText} />;
}

WhiteLabelBrand.defaultProps = defaultProps;

export default WhiteLabelBrand;
