export const FUNCTIONALITIES = {
  SIGN_UP: 'signUp',
};

export const STEPS = {
  INTRODUCTION: 'intro',
  START: 'start',
  START2: 'start2',
  SUMMARY: 'summary',
  CONFIRM: 'confirm',
  EDIT: 'edit',
  DELETE: 'delete',
  SAVE: 'save',
  SUBMIT: {
    SUCCESS: 'submit_success',
    REQUEST: 'submit_request',
    FAILURE: 'submit_failure',
  },
  INIT: {
    SUCCESS: 'init_success',
    REQUEST: 'init_request',
    FAILURE: 'init_failure',
    RESUME: 'resume_application',
  },
  GOTO: {
    SUCCESS: 'goto_success',
    REQUEST: 'goto_request',
    FAILURE: 'goto_failure',
  },
  EXIT: 'save_application',
};

export const APPLICATION = 'sbip';
