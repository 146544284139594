// @flow
import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';

import Header from '../../../organisms/common/Header';
import MultiFactorAuth from '../../../organisms/common/MultiFactorAuth';
import MultiFactorAuthFooterContent from '../../../organisms/common/MultiFactorAuthFooter';
import Footer from '../../../organisms/common/Footer';

import './style.scss';

const MultiFactorAuthTemplate = () => (
  <FullScreenFooterTemplate
    header={<Header hasSwitchLang={false} />}
    main={<MultiFactorAuth className="secureKey-mfa" />}
    footer={<Footer content={<MultiFactorAuthFooterContent />} />}
  />
);

export default MultiFactorAuthTemplate;
