import { connect } from 'react-redux';

import LoginFooter from './loginFooter';

import { getAllConfigs } from '../../../services/configsService/selectors';

const mapStateToProps = state => ({
  configs: getAllConfigs(state),
});

export default connect(mapStateToProps)(LoginFooter);
