// @flow
import React, { useEffect, useState } from 'react';
import {
  Input,
  Tooltip,
  Label,
  Constants,
  LinkButton,
} from 'bnc-react-components';

import { Info } from '@nbc-design/icons/lib/web/Info';
import { Lock } from '@nbc-design/icons/lib/web/Lock';
import { Button } from '@bnc-ui/button';
import { Text } from '@bnc-ui/text';

import cx from 'classnames';
import has from 'lodash/has';

import type { IntlShape } from 'react-intl';
import * as cookie from '../../../../utils/cookie';

import LoginFormMessage from '../../../common/LoginFormMessage';
import Greeting from '../../../common/Greeting';

import type { SDKError } from '../../../../types';
import type {
  LoginFormData,
  LoginFormErrors,
} from '../../../../types/forms/loginForm';

import { LOGIN_FORM_STEPS } from '../../../../utils/constants/login';
import {
  IDENTITY_FIELD_MAX_LENGTH,
  PASSWORD_FIELD_MAX_LENGTH,
  WELCOME_ANNOUNCE_DELAY,
} from '../../../../utils/constants/forms';

import { getWelcomeMsgKey } from '../../../../utils/liveAnnouncer/helper';
import onEyeButtonClick from '../LoginTaggingWrapper/helper';
import LoginTaggingWrapper from '../LoginTaggingWrapper/index';

type Props = {
  className?: string,
  intl: IntlShape,
  formData: LoginFormData,
  onBlur: (event: SyntheticInputEvent<any>) => void,
  isFetching: boolean,
  onChange: (event: SyntheticInputEvent<any>) => void,
  hasIdentitiesSaved: boolean,
  changeStepRequest: (nextStep: string, hasToFocus: ?boolean) => void,
  clearLoginErrorMessages: () => void,
  onClickForgetLink: (event: SyntheticInputEvent<any>) => void,
  errors: LoginFormErrors,
  triggerSubmit: () => void,
  isMaximumReached: boolean,
  setFormInputs: (inputs: any) => void,
  handleKeyPress: (event: Event) => void,
  hasForgotPwd: boolean,
  hasLoginFailure: boolean,
  sdkError: SDKError,
  hasValidationError: boolean,
  isFirstRender: ?boolean,
  announceAlertMessage: (message: string) => void,
  hasToFocus: boolean,
  partnerName: string,
  informationalMessage: string,
  signInButtonTheme?: string,
};

const defaultProps = {
  className: '',
  signInButtonTheme: null,
};

const LoginWithoutIdPartialContentForm = (props: Props) => {
  const {
    intl,
    onBlur,
    formData,
    isFetching,
    onChange,
    hasIdentitiesSaved,
    changeStepRequest,
    clearLoginErrorMessages,
    onClickForgetLink,
    errors,
    triggerSubmit,
    isMaximumReached,
    setFormInputs,
    handleKeyPress,
    hasForgotPwd,
    hasLoginFailure,
    sdkError,
    className,
    hasValidationError,
    isFirstRender,
    announceAlertMessage,
    hasToFocus,
    partnerName,
    informationalMessage,
    signInButtonTheme,
  } = props;

  const [trackTooltip, setTrackTooltip] = useState(0);
  const [trackPassword, setTrackPassword] = useState(0);

  useEffect(() => {
    if (hasToFocus) {
      // Focus identity input field when component is rendered
      const identityInput = document.querySelector('#identity');
      if (identityInput) {
        identityInput.focus();
      }
    }
    if (isFirstRender === null || isFirstRender) {
      setTimeout(
        () => announceAlertMessage(getWelcomeMsgKey(partnerName)),
        WELCOME_ANNOUNCE_DELAY,
      );
    }
  }, [hasToFocus, isFirstRender, partnerName, announceAlertMessage]);

  let loginFormInputs = {};

  const hasIdentityError = has(errors, 'identity');
  const hasPasswordError = has(errors, 'password');
  const identityErrorClassName = hasIdentityError
    ? 'error loginForm_flexitem'
    : '';
  const passwordErrorClassName = hasPasswordError
    ? 'error loginForm_flexitem'
    : '';

  const goToIdentitySelection = () => {
    changeStepRequest(LOGIN_FORM_STEPS.LOGIN_WITH_ID_STEP, true);
  };

  const onSubmit = () => {
    cookie.remove('currentOktaSession');
    setFormInputs(loginFormInputs);
    triggerSubmit();
  };

  const handleTooltipShow = isVisible => {
    isVisible && announceAlertMessage('text.message.tooltipRememberMe');
    isVisible && setTrackTooltip(trackTooltip + 1);
  };

  const renderRememberMe = () => {
    if (!isMaximumReached) {
      return (
        <Label className="label-inline login__rememberMe">
          <Input
            className="is-small"
            type="checkbox"
            name="remember"
            onChange={onChange}
            checked={formData.remember}
          />
          {intl.formatMessage({ id: 'text.label.rememberMe' })}
          <Tooltip
            overlay={intl.formatMessage({
              id: 'text.message.tooltipRememberMe',
            })}
            ariaLabel={intl.formatMessage({
              id: 'text.aria.rememberMe',
            })}
            onVisibleChange={handleTooltipShow}
          >
            <Info />
          </Tooltip>
        </Label>
      );
    }
    return null;
  };

  return (
    <div className={cx(className)} role="none" onKeyPress={handleKeyPress}>
      <h2 className="a11y-hide">
        {intl.formatMessage({ id: 'text.aria.greetingsContext' })}
      </h2>
      <Greeting tagName="span" className="title" />
      {hasIdentitiesSaved && (
        <LinkButton
          onClick={goToIdentitySelection}
          label={intl.formatMessage({
            id: 'text.link.useAnotherSavedIdentity',
          })}
          ariaLabel={intl.formatMessage({
            id: 'text.aria.useAnotherSavedIdentity',
          })}
        />
      )}
      {informationalMessage && <Text>{informationalMessage}</Text>}
      <LoginFormMessage
        errors={errors}
        hasValidationError={hasValidationError}
        hasLoginFailure={hasLoginFailure}
        sdkError={sdkError}
        clearLoginErrorMessages={clearLoginErrorMessages}
      />
      <div className="combo">
        <label className="login_label" htmlFor="identity">
          {intl.formatMessage({
            id: 'text.label.identity',
          })}
        </label>

        <div className="combo__entry combo__entry--small">
          <Input
            type="email"
            name="identity"
            placeholder={intl.formatMessage({
              id: 'text.field.identityPlaceholder',
            })}
            id="identity"
            onChange={onChange}
            onBlur={onBlur}
            className={cx(identityErrorClassName, className)}
            ariaInvalid={hasIdentityError}
            maxLength={IDENTITY_FIELD_MAX_LENGTH}
            value={formData.identity}
            innerRef={identityInputRef => {
              loginFormInputs = {
                ...loginFormInputs,
                identity: identityInputRef,
              };
            }}
          />
        </div>

        <label className="login_label" htmlFor="password">
          {intl.formatMessage({
            id: 'text.label.password',
          })}
        </label>
        <div className="combo__entry combo__entry--small">
          <LoginTaggingWrapper helpText="password" counting={trackPassword}>
            <Input
              type="password"
              name="password"
              placeholder={intl.formatMessage({
                id: 'text.field.passwordPlaceholder',
              })}
              id="password"
              onChange={onChange}
              onBlur={onBlur}
              className={cx(passwordErrorClassName, className)}
              ariaInvalid={hasPasswordError}
              maxLength={PASSWORD_FIELD_MAX_LENGTH}
              autoComplete={Constants.AUTOCOMPLETE.CURRENT_PASSWORD}
              value={formData.password}
              showPasswordIconAriaLabel={intl.formatMessage({
                id: 'text.aria.displayPassword',
              })}
              hidePasswordIconAriaLabel={intl.formatMessage({
                id: 'text.aria.hidePassword',
              })}
              innerRef={passwordInputRef => {
                loginFormInputs = {
                  ...loginFormInputs,
                  password: passwordInputRef,
                };
                onEyeButtonClick(passwordInputRef, setTrackPassword);
              }}
            />
          </LoginTaggingWrapper>
        </div>
        {hasForgotPwd && (
          <LinkButton
            onClick={onClickForgetLink}
            label={intl.formatMessage({ id: 'text.link.forgotPassword' })}
          />
        )}
      </div>
      {renderRememberMe()}
      <Button
        type="submit"
        appearance="primary"
        theme={signInButtonTheme}
        size="large"
        fluid
        icon={<Lock size="small" title="lock" />}
        disabled={isFetching}
        className="login__submit"
        onClick={isFetching ? () => {} : onSubmit}
      >
        {isFetching
          ? intl.formatMessage({ id: 'loginForm.button.loading' })
          : intl.formatMessage({ id: 'text.button.login' })}
      </Button>
    </div>
  );
};

LoginWithoutIdPartialContentForm.defaultProps = defaultProps;

export default LoginWithoutIdPartialContentForm;
