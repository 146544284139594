import { createSelector } from 'reselect';
import get from 'lodash/get';
import includes from 'lodash/includes';

import getReducerState from '../../utils/stateSelectorHelper';

import { MODAL_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';
import { MODAL_TYPES } from '../../utils/constants/modal';

export const getModalState = (state, modulePath = '') =>
  getReducerState(state, modulePath, MODAL_SERVICE_REDUCER);

export const getModalStack = createSelector(getModalState, modalState =>
  get(modalState, 'modalStack', []),
);

export const isShowSessionExpiredModal = createSelector(
  getModalStack,
  modalStack => includes(modalStack, MODAL_TYPES.SESSION_EXPIRED_MODAL),
);

export const isShowMFACodeExpiredModal = createSelector(
  getModalStack,
  modalStack => includes(modalStack, MODAL_TYPES.MFA_EXPIRED_MODAL),
);
