// @flow
import React from 'react';
import type { intlShape } from 'react-intl';
import { Button } from 'bnc-react-components';

import Paragraph from '../../../atoms/Paragraph';

import './style.scss';

type Props = {
  intl: intlShape,
  returnToLogin: () => void,
};

function ResetPasswordException(props: Props) {
  const { intl, returnToLogin } = props;
  const expiredPasswordMessage = intl.formatMessage({
    id: 'text.message.expiredPassword',
  });

  return (
    <div>
      <Paragraph
        className="returnToSbipLogin_reset-exception"
        message={expiredPasswordMessage}
      />

      <Button
        primary
        className="returnToSbipLogin__button"
        onClick={returnToLogin}
      >
        {intl.formatMessage({ id: 'text.link.sbip2Login' })}
      </Button>
    </div>
  );
}

export default ResetPasswordException;
