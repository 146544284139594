// @flow
import * as actionTypes from './actionTypes';
import type { Action as ReduxAction } from '../../types/action';

export type UnsupportedBrowserState = {|
  +isSupportedBrowser: boolean,
  +continueWithUnsupportedBrowser: boolean,
  +cannotContinueWithUnsupportedBrowser: boolean,
|};

export type InitSupportedBrowserAction = ReduxAction<
  typeof actionTypes.UNSUPPORTED_BROWSER_INIT,
> & { isSupportedBrowser: boolean };

export type ContinueWithUnsupportedBrowserAction = ReduxAction<
  typeof actionTypes.UNSUPPORTED_BROWSER_CONTINUE_ANYWAY,
>;

export type CannotContinueWithUnsupportedBrowserAction = ReduxAction<
  typeof actionTypes.UNSUPPORTED_BROWSER_CANNOT_CONTINUE,
>;

export type Action =
  | ContinueWithUnsupportedBrowserAction
  | CannotContinueWithUnsupportedBrowserAction
  | InitSupportedBrowserAction;
