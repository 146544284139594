import { createSelector } from 'reselect';
import get from 'lodash/get';

import getReducerState from '../../utils/stateSelectorHelper';

import { I18N_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';

import I18N from '../../utils/constants/i18n';

export const getI18nState = (state, modulePath = '') =>
  getReducerState(state, modulePath, I18N_SERVICE_REDUCER);

export const getLocale = createSelector(getI18nState, i18nState =>
  get(i18nState, 'locale', I18N.LANG_DEFAULT),
);
