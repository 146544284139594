import { getDtmPageObject } from '../helper';

describe('getDtmPageObject with SECURE_KEY as template name', () => {
  it('returns DTM page object based on given template name', () => {
    const dtmPageObject = getDtmPageObject('SECURE_KEY');
    expect(dtmPageObject).toEqual({
      LOGIN: {
        uri: 'secureKey:login',
        type: 'secureKey:Login',
        context: 'secureKey',
      },
      LOGIN_VERIFY: {
        uri: 'secureKey:login:verify',
        type: 'secureKey:Login',
        context: 'secureKey',
      },
      NOT_FOUND: {
        uri: 'secureKey:404',
        type: 'secureKey:404',
        context: 'secureKey',
      },
    });
  });
});
