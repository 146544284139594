// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { announceStatusMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';

import MultiFactorAuth from './multiFactorAuth';

import type { Dispatch } from '../../../types/store';
import type { State } from '../../../types/state';

import {
  formUpdate,
  triggerSubmit,
  triggerValidation,
} from '../../../services/multiFactorAuthFormService/actions';
import { returnToLogin } from '../../../services/loginService/actions';
import {
  getMFAfactors,
  getSdkError,
  isFetching,
  showOTCSuccessMessageSelector,
  isHideResend,
  getContact,
  getMFAselected,
  isEnrollAfterBeenChallenged,
} from '../../../services/loginService/selectors';

import {
  getMFAFormData,
  getMFAFormErrors,
  isAuthFormDone,
  getMFAExecutedAction,
} from '../../../services/multiFactorAuthFormService/selectors';
import { getAuthErrorMessageId } from '../../../utils/authErrorMessages';
import { getPartnerIdName } from '../../../services/templateService/selectors';

const mapStateToProps = (state: State) => ({
  formData: getMFAFormData(state),
  errors: getMFAFormErrors(state),
  isFetching: isFetching(state),
  sdkError: getSdkError(state),
  isHideResend: isHideResend(state),
  factorTypes: getMFAfactors(state),
  selectedFactor: getMFAselected(state),
  contact: getContact(state),
  isFormDone: isAuthFormDone(state),
  getAuthErrorMessageId,
  executedAction: getMFAExecutedAction(state),
  isShowResendOTCSuccessMessage: showOTCSuccessMessageSelector(state),
  isHardEnrolling: isEnrollAfterBeenChallenged(state),
  partnerIdName: getPartnerIdName(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  formUpdate: (fieldName, fieldValue) =>
    dispatch(formUpdate(fieldName, fieldValue)),
  triggerValidation: fieldName => dispatch(triggerValidation(fieldName)),
  triggerSubmit: () => dispatch(triggerSubmit()),
  returnToLogin: () => dispatch(returnToLogin()),
  announceMessage: message => dispatch(announceStatusMessage(message)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuth),
);
