import React from 'react';
import { Configs } from '../../configs';

const {
  MCO_BNC_MAIN_SCRIPT_URL,
  MCO_BNC_IMAGE_SAFETY_BELT_URL,
  MCO_BNC_CSS_SAFETY_BELT_URL,
} = Configs;

const injectElement = (elementType, url) => {
  let element;
  if (!url) {
    return;
  }

  switch (elementType) {
    case 'script':
      element = document.createElement('script');
      element.src = url;
      element.defer = true;
      element.referrerPolicy = 'strict-origin-when-cross-origin';
      break;
    case 'link':
      element = document.createElement('link');
      element.rel = 'stylesheet';
      element.href = url;
      break;
    default:
      return;
  }

  if (elementType === 'script' || elementType === 'link') {
    document.head.appendChild(element);
  }
};

export const injectMemcycoMainScript = () => {
  injectElement('script', MCO_BNC_MAIN_SCRIPT_URL);
};

export const InjectMemcycoImgSafetyBelt = () => {
  return (
    <img
      src={MCO_BNC_IMAGE_SAFETY_BELT_URL}
      style={{
        display: 'none',
        height: '0px',
        width: '0px',
        pointerEvents: 'unset',
      }}
      alt="bnc-logo"
    />
  );
};

export const injectMemcycoCssSafetyBelt = () => {
  injectElement('link', MCO_BNC_CSS_SAFETY_BELT_URL);
};

export const injectMemcycoSnippets = () => {
  injectMemcycoMainScript();
  injectMemcycoCssSafetyBelt();
};

export default InjectMemcycoImgSafetyBelt;
