import React from 'react';

import { LANG } from 'bnc-react-components/dist/utils/constants';
import { FACTOR_TYPES } from 'bnc-react-services/managers/AuthManager/constants';

import { Envelope } from '@nbc-design/icons/lib/web/Envelope';
import { PhoneSms } from '@nbc-design/icons/lib/web/PhoneSms';
import { Telephone } from '@nbc-design/icons/lib/web/Telephone';
import { EmailCodeColor } from '@nbc-design/icons/lib/web/EmailCodeColor';
import { MobileCodeColor } from '@nbc-design/icons/lib/web/MobileCodeColor';
import { VoiceCodeColor } from '@nbc-design/icons/lib/web/VoiceCodeColor';
import { SecurIdTokenHiglightColor } from '@nbc-design/icons/lib/web/SecurIdTokenHiglightColor';

import {
  formatFactorValue,
  getFactorTarget,
  getFactorIcon,
  orderFactors,
} from '../factorUtils';

describe('formatFactorValue', () => {
  for (const l in LANG) {
    if (l) {
      // not sms factor
      for (const f in [FACTOR_TYPES.EMAIL, FACTOR_TYPES.UNKNOWN]) {
        if (f) {
          [
            {
              factorType: f,
              value: 'whatever',
              locale: LANG[l],
              output: 'whatever',
            },
          ].forEach(({ factorType, value, locale, output }) => {
            test(`input ${JSON.stringify({
              factorType,
              value,
              locale,
            })} should returns ${output}`, () => {
              expect(formatFactorValue(factorType, value, locale)).toEqual(
                output,
              );
            });
          });
        }
      }

      // sms factor
      [
        {
          factorType: FACTOR_TYPES.SMS,
          value: '+1 514 abc-xyzt',
          locale: LANG[l],
          output: LANG[l] === LANG.EN ? '514-abc-xyzt' : '514 abc-xyzt',
        },

        {
          factorType: FACTOR_TYPES.SMS,
          value: '514 abc-xyzt',
          locale: LANG[l],
          output: LANG[l] === LANG.EN ? '514-abc-xyzt' : '514 abc-xyzt',
        },

        {
          factorType: FACTOR_TYPES.SMS,
          value: '514-abc-xyzt',
          locale: LANG[l],
          output: LANG[l] === LANG.EN ? '514-abc-xyzt' : '514 abc-xyzt',
        },

        {
          factorType: FACTOR_TYPES.SMS,
          value: '1514abcxyzt',
          locale: LANG[l],
          output: LANG[l] === LANG.EN ? '514-abc-xyzt' : '514 abc-xyzt',
        },
      ].forEach(({ factorType, value, locale, output }) => {
        test(`input ${JSON.stringify({
          factorType,
          value,
          locale,
        })} should returns ${output}`, () => {
          expect(formatFactorValue(factorType, value, locale)).toEqual(output);
        });
      });

      // phone factor
      [
        {
          factorType: FACTOR_TYPES.CALL,
          value: { phoneNumber: '+1 514 345-6789', phoneExtension: '1234' },
          locale: LANG[l],
          output:
            LANG[l] === LANG.EN ? '514-345-6789 #1234' : '514 345-6789 #1234',
        },
        {
          factorType: FACTOR_TYPES.CALL,
          value: { phoneNumber: '+1 514 345-6789', phoneExtension: '' },
          locale: LANG[l],
          output: LANG[l] === LANG.EN ? '514-345-6789' : '514 345-6789',
        },
      ].forEach(({ factorType, value, locale, output }) => {
        test(`input ${JSON.stringify({
          factorType,
          value,
          locale,
        })} should returns ${output}`, () => {
          expect(formatFactorValue(factorType, value, locale)).toEqual(output);
        });
      });
    }
  }
});

describe('getFactorTarget', () => {
  it('When factor type is SMS, return phone number', () => {
    const result = getFactorTarget(
      {
        factorType: 'sms',
        profile: {
          phoneNumber: '+1 XXX-XXX-1337',
        },
      },
      'EN',
    );

    expect(result).toBe('XXX-XXX-1337');
  });
  it('When factor type is EMAIL, return email', () => {
    const result = getFactorTarget(
      {
        factorType: 'email',
        profile: {
          email: 'some.user@bnc.ca',
        },
      },
      'EN',
    );

    expect(result).toBe('some.user@bnc.ca');
  });
  it('When factor type is VOICE (call), return phone number', () => {
    const result = getFactorTarget(
      {
        factorType: FACTOR_TYPES.CALL,
        profile: {
          phoneNumber: '+1 XXX-XXX-1337',
        },
      },
      'EN',
    );

    expect(result).toBe('XXX-XXX-1337');
  });
  it('When factor type is VOICE (call), return phone number with extension', () => {
    const result = getFactorTarget(
      {
        factorType: FACTOR_TYPES.CALL,
        profile: {
          phoneNumber: '+1 XXX-XXX-1337',
          phoneExtension: '1234',
        },
      },
      'EN',
    );

    expect(result).toBe('XXX-XXX-1337 #1234');
  });
  it('Otherwise, return unknown', () => {
    const result = getFactorTarget(
      {
        factorType: 'unkown factor type',
        profile: {
          phoneNumber: '+1 XXX-XXX-1337',
          email: 'some.user@bnc.ca',
        },
      },
      'EN',
    );

    expect(result).toBe('unknown');
  });
});

describe('getBneFactorIcon not resend', () => {
  it('should return Email icon when factor is sms', () => {
    const result = getFactorIcon(FACTOR_TYPES.SMS, false);
    const expected = <PhoneSms size="large" title="phone-sms" />;

    expect(result).toStrictEqual(expected);
  });
  it('should return Email icon when factor is email', () => {
    const result = getFactorIcon(FACTOR_TYPES.EMAIL, false);
    const expected = <Envelope size="large" title="email" />;

    expect(result).toStrictEqual(expected);
  });
  it('should return Email icon when factor is call', () => {
    const result = getFactorIcon(FACTOR_TYPES.CALL, false);
    const expected = <Telephone size="large" title="telephone" />;

    expect(result).toStrictEqual(expected);
  });
  it('should return RSA Token icon when factor is token', () => {
    const result = getFactorIcon(FACTOR_TYPES.TOKEN, false);
    const expected = (
      <SecurIdTokenHiglightColor
        size="large"
        title="secur-id-token-higlight-color"
      />
    );

    expect(result).toStrictEqual(expected);
  });
});

describe('getBneFactorIcon is resend', () => {
  it('should return Email icon when factor is sms', () => {
    const result = getFactorIcon(FACTOR_TYPES.SMS, true);
    const expected = <MobileCodeColor size="large" title="mobile-code-color" />;

    expect(result).toStrictEqual(expected);
  });
  it('should return Email icon when factor is email', () => {
    const result = getFactorIcon(FACTOR_TYPES.EMAIL, true);
    const expected = <EmailCodeColor size="large" title="email-code-color" />;

    expect(result).toStrictEqual(expected);
  });
  it('should return Email icon when factor is call', () => {
    const result = getFactorIcon(FACTOR_TYPES.CALL, true);
    const expected = <VoiceCodeColor size="large" title="voice-code-color" />;

    expect(result).toStrictEqual(expected);
  });
  it('should return RSA Token icon when factor is token', () => {
    const result = getFactorIcon(FACTOR_TYPES.TOKEN, true);
    const expected = (
      <SecurIdTokenHiglightColor
        size="large"
        title="secur-id-token-higlight-color"
      />
    );

    expect(result).toStrictEqual(expected);
  });
});

describe('orderFactors', () => {
  it('should order factors with sms first, email second and others last', () => {
    const factors = [
      { factorType: 'email' },
      { factorType: 'call' },
      { factorType: 'sms' },
    ];

    const result = orderFactors(factors);

    const expected = [
      { factorType: 'sms' },
      { factorType: 'email' },
      { factorType: 'call' },
    ];

    expect(result).toStrictEqual(expected);
  });
});
