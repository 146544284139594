// @flow
import React from 'react';
import cx from 'classnames';

import './style.scss';

type Props = {
  className?: string,
  content: any,
};

const defaultProps = {
  className: '',
};

function Footer(props: Props) {
  const { className, content } = props;

  return <div className={cx('common-footer', className)}>{content}</div>;
}

Footer.defaultProps = defaultProps;

export default Footer;
