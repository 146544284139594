// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import type { State } from '../../../types/state';

import { TEMPLATE_SERVICE_REDUCER } from '../../../globalRedux/reducers/constants';

import WhiteLabelBrand from './whiteLabelBrand';

function mapStateToProps(state: State) {
  return {
    partnerId: state[TEMPLATE_SERVICE_REDUCER].partnerId,
  };
}

export default injectIntl(connect(mapStateToProps, null)(WhiteLabelBrand));
