import store from '../__mocks__/store';

import register from '../handler';

register(store);

beforeEach(store.dispatch.mockClear);

describe('register', () => {
  it('should dispatch a valid biometricValidationResults action', () => {
    const payload = {
      validationResults: 'success',
      retrievedCredentials: 'dummypassword',
    };
    const event = JSON.stringify({
      actionType: 'biometricValidationResults',
      payload,
    });
    const expected = {
      type: 'BIOMETRY_EVENT_BIOMETRY_VALIDATION_RESULT',
      payload,
    };

    window.callbackSbie(event);
    expect(store.dispatch).toHaveBeenCalledTimes(1);
    expect(store.dispatch).toHaveBeenCalledWith(expected);
  });

  it('should dispatch a valid deleteBiometricCredentialsResults action', () => {
    const payload = {
      results: 'success',
    };
    const event = JSON.stringify({
      actionType: 'deleteBiometricCredentialsResults',
      payload,
    });
    const expected = {
      type: 'BIOMETRY_CREDENTIALS_DELETION_RESULT',
      payload,
    };

    window.callbackSbie(event);
    expect(store.dispatch).toHaveBeenCalledTimes(1);
    expect(store.dispatch).toHaveBeenCalledWith(expected);
  });
});
