import {
  getTranslatedMessage,
  getWelcomeMsgKey,
} from '../liveAnnouncer/helper';

describe('getTranslatedMessage', () => {
  // Test data & simple intl implemented stub
  const msgTestDataMain = 'Message Test 1'; /* Simple message  */
  const msgTestDataMain2 =
    'Message Test 1. Message Test 2'; /*  Message Test 1. {m1}  */
  const msgTestDataMain23 =
    'Message Test 1. Message Test 2. Message Test 3'; /*  Message Test 1. {m1} {m2} */
  const msgTestData2 = 'Message Test 2'; /* Simple message  */
  const msgTestData3 = 'Message Test 3'; /* Simple message  */
  const codeTest = 'CODETEST';
  const msgTestData4 = `Message Test 4 ${codeTest}`; /*  Message Test 1. {code}  */
  const msgTestData5 = `Message Test 4 ${codeTest} Message Test 2`; /*  Message Test 1. {code} {m2} */

  class Intl {
    constructor() {
      this.objMsgTestDataMain = { id: 'message.test1' };
      this.objMsgTestData2 = { id: 'message.test2' };
      this.objMsgTestData3 = { id: 'message.test3' };
      this.objKeysTestData2 = { m1: msgTestData2 };
      this.objKeysTestData23 = { m1: msgTestData2, m2: msgTestData3 };
      this.objKeysTestData4 = { code: codeTest };
      this.objKeysTestData5 = { code: codeTest, m2: msgTestData2 };
    }

    formatMessage(objMsg, objKeys) {
      if (
        JSON.stringify(objMsg) === JSON.stringify(this.objMsgTestDataMain) &&
        !objKeys
      ) {
        return msgTestDataMain;
      }
      if (
        objKeys &&
        JSON.stringify(objKeys) === JSON.stringify(this.objKeysTestData2)
      ) {
        return msgTestDataMain2;
      }
      if (
        objKeys &&
        JSON.stringify(objKeys) === JSON.stringify(this.objKeysTestData23)
      ) {
        return msgTestDataMain23;
      }
      if (
        objKeys &&
        JSON.stringify(objKeys) === JSON.stringify(this.objKeysTestData4)
      ) {
        return msgTestData4;
      }
      if (
        objKeys &&
        JSON.stringify(objKeys) === JSON.stringify(this.objKeysTestData5)
      ) {
        return msgTestData5;
      }
      if (
        JSON.stringify(objMsg) === JSON.stringify(this.objMsgTestData2) &&
        !objKeys
      ) {
        return msgTestData2;
      }
      if (
        JSON.stringify(objMsg) === JSON.stringify(this.objMsgTestData3) &&
        !objKeys
      ) {
        return msgTestData3;
      }
      return null;
    }
  }
  const intl = new Intl();

  // Test battery
  it('should return empty message when message is null', () => {
    const message = null;
    expect(getTranslatedMessage(intl, message)).toEqual({
      aria: { ariaRelevant: 'all' },
      text: '',
    });
  });

  it('should return empty message when message is empty string', () => {
    const message = '';
    expect(getTranslatedMessage(intl, message)).toEqual({
      aria: { ariaRelevant: 'all' },
      text: '',
    });
  });

  it('should return appropiate translated simple message when simple message is provided', () => {
    const message = 'message.test1';
    expect(getTranslatedMessage(intl, message)).toEqual({
      aria: { ariaRelevant: 'all' },
      text: msgTestDataMain,
    });
  });

  it('should return appropiate translated complex message when complex message with 1 extra message (value property) is provided', () => {
    const message = 'message.test1|message.test2';
    expect(getTranslatedMessage(intl, message)).toEqual({
      aria: { ariaRelevant: 'all' },
      text: msgTestDataMain2,
    });
  });

  it('should return appropiate translated complex message when complex message with 2 extra messages (value property) is provided', () => {
    const message = 'message.test1|message.test2|message.test3';
    expect(getTranslatedMessage(intl, message)).toEqual({
      aria: { ariaRelevant: 'all' },
      text: msgTestDataMain23,
    });
  });

  it('should return appropiate translated complex message when complex message with 1 extra message (key and value property) is provided', () => {
    const message = 'message.test4|code=CODETEST';
    expect(getTranslatedMessage(intl, message)).toEqual({
      aria: { ariaRelevant: 'all' },
      text: msgTestData4,
    });
  });

  it(
    'should return appropiate translated complex message' +
      'when complex message with 2 extra message (one with key and value property and other with value property) ' +
      'is provided',
    () => {
      const message = 'message.test4|code=CODETEST|message.test2';
      expect(getTranslatedMessage(intl, message)).toEqual({
        aria: { ariaRelevant: 'all' },
        text: msgTestData5,
      });
    },
  );
});

describe('getWelcomeMsgKey', () => {
  const testPartner = 'test';
  const defaultWelcomeMsgKey = 'text.arialive.bienvenue';
  const testWelcomeMsgKey = `text.arialive.bienvenue.wl|partner=${testPartner}`;
  it('should return default welcome message key if partnerId is null', () => {
    expect(getWelcomeMsgKey(null)).toEqual(defaultWelcomeMsgKey);
  });
  it('should return default welcome message key if partnerId is empty string', () => {
    expect(getWelcomeMsgKey('')).toEqual(defaultWelcomeMsgKey);
  });
  it('should return appropiated welcome message key with partner parameter if partnerId is provided', () => {
    expect(getWelcomeMsgKey(testPartner)).toEqual(testWelcomeMsgKey);
  });
});
