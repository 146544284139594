// @flow
import React, { useEffect } from 'react';
import type { intlShape } from 'react-intl';
import { FormattedHTMLMessage } from 'react-intl';
import cx from 'classnames';

import { Heading } from '@bnc-ui/heading';
import { Message } from '@bnc-ui/message';
import { Alert } from '@bnc-ui/alert';
import { FailureFilledXsmallColor } from '@nbc-design/icons/lib/web/FailureFilledXsmallColor';

import { Tagging, TrackAction, TrackState } from '@nbc-studio/analytics';
import FormHandler from '../../../molecules/forms/FormHandler';
import MultiFactorAuthForm from '../../../molecules/bne/forms/MultiFactorAuthForm';
import BackLinkButton from '../../../molecules/common/BackLinkButton';

import type { MultiFactorAuthFormData } from '../../../types/forms/multiFactorAuthForm';
import type { SDKError } from '../../../types';
import {
  MULTI_FACTOR_AUTH_FORM_INIT_DONE,
  MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
  MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE,
  MULTI_FACTOR_AUTH_UPDATE_FACTORS,
} from '../../../services/multiFactorAuthFormService/actionTypes';
import { formatFactorValue, getFactorIcon } from '../../../utils/factorUtils';
import { focusOnComponent } from '../../../utils/domUtils';
import { queueAnnouncer } from '../../../utils/liveAnnouncer/helper';
import getOtcErrorMessage from '../../../utils/bne/errorMessageUtils';
import { FORM_TYPES } from '../../../utils/constants/forms';
import { FORGOT_PASSWORD_FIELDS } from '../../../utils/forgotPasswordUtils/constants';

import './style.scss';
import BneBroadcastChannel from '../BneBroadcastChannel';
import {
  BNE_EVENT_IDS,
  BNE_EVENT_NAMES,
  BNE_EVENT_SOURCE,
  BNE_STEP_NAMES,
  BNE_UCS_IDS,
  BNE_UCS_STATE,
} from '../../../utils/taggingUtils/bneTaggingConstants';
import PageReadyTaggingWrapper from '../../../molecules/bne/TaggingBNE/PageReadyTaggingWrapper';

type Props = {
  intl: intlShape,
  className?: string,
  isFetching: boolean,
  formUpdate: (fieldName: string, fieldValue: string) => void,
  triggerSubmit: () => void,
  triggerValidation: (fieldName: string) => void,
  errors: *,
  factorTypes: Array<string>,
  formData: MultiFactorAuthFormData,
  returnToLogin: () => void,
  clearLoginErrorMessages: () => void,
  clearMfaFormErrors: () => void,
  sdkError: SDKError,
  selectedFactor: string,
  contact: string,
  isShowResendOTCSuccessMessage?: boolean,
  getAuthErrorMessageId: ({}) => string,
  executedAction?: any,
  announceMessage?: string => void,
  templateName: string,
  lastLoginFailureDate: Date,
  isFormDone: boolean,
  isMFASubmitFailed: boolean,
};

const defaultProps = {
  className: '',
  isShowResendOTCSuccessMessage: false,
  executedAction: {},
  announceMessage: () => {},
};

const MultiFactorAuth = (props: Props) => {
  const {
    className,
    intl,
    formUpdate,
    errors,
    triggerSubmit,
    triggerValidation,
    isFetching,
    factorTypes,
    formData,
    contact,
    sdkError,
    selectedFactor,
    returnToLogin,
    clearLoginErrorMessages,
    clearMfaFormErrors,
    isShowResendOTCSuccessMessage,
    getAuthErrorMessageId,
    executedAction,
    announceMessage,
    templateName,
    lastLoginFailureDate,
    isFormDone,
    isMFASubmitFailed,
  } = props;

  useEffect(() => {
    const { type } = executedAction;
    if (type === MULTI_FACTOR_AUTH_FORM_INIT_DONE) {
      const messages = [
        intl.formatMessage({ id: 'text.title.validationCode' }),
        intl.formatMessage({ id: 'text.message.delayCode' }),
        intl.formatMessage(
          { id: `text.message.validationCodeSent.bne.${selectedFactor}` },
          { contact: formatFactorValue(selectedFactor, contact, intl.locale) },
        ),
      ];
      queueAnnouncer(messages, announceMessage);
    }
  }, [executedAction, intl, selectedFactor, announceMessage, contact]);

  const getFormErrorMessagekey = () => {
    if (formData.code) {
      return 'text.message.validationCodeError.bne';
    }
    return 'text.message.missingCodeError.bne';
  };

  useEffect(() => {
    if (errors.code) {
      queueAnnouncer(
        [
          intl.formatMessage({
            id: getFormErrorMessagekey(),
          }),
        ],
        announceMessage,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, intl, announceMessage]);

  useEffect(() => {
    if (isShowResendOTCSuccessMessage) {
      queueAnnouncer(
        [intl.formatMessage({ id: 'text.title.resendSuccess.bne' })],
        announceMessage,
      );
    }
  }, [isShowResendOTCSuccessMessage, intl, announceMessage]);

  useEffect(() => {
    if (sdkError.code) {
      queueAnnouncer(
        [
          getOtcErrorMessage(
            templateName,
            sdkError,
            lastLoginFailureDate,
            intl,
          ),
        ],
        announceMessage,
      );
    }
  }, [
    sdkError,
    getAuthErrorMessageId,
    intl,
    announceMessage,
    templateName,
    lastLoginFailureDate,
  ]);

  useEffect(() => {
    const { type } = executedAction;
    if (
      !!sdkError.code ||
      type === MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE ||
      type === MULTI_FACTOR_AUTH_UPDATE_FACTORS ||
      type === MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE
    ) {
      focusOnComponent(`#${FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE}`);
    }
  }, [sdkError, executedAction]);

  const codeSendTitle = (
    <FormattedHTMLMessage
      id={`text.message.validationCodeSent.bne.${selectedFactor}`}
      values={{
        contact: formatFactorValue(selectedFactor, contact, intl.locale),
      }}
    />
  );

  const goBackToLogin = () => {
    clearLoginErrorMessages();
    clearMfaFormErrors();
    returnToLogin();
  };

  return (
    <>
      <PageReadyTaggingWrapper
        locale={intl.locale}
        isLoading={isFormDone}
        stepName={BNE_STEP_NAMES.ENTER_SECURITY_CODE}
        flowId={selectedFactor}
      >
        <div id="multiFactorAuth" className={cx('connect-form mfa', className)}>
          <Heading type="h1" size={1}>
            {intl.formatMessage({ id: 'text.title.validationCode' })}
          </Heading>

          {isShowResendOTCSuccessMessage && !sdkError?.code && !errors?.code && (
            <PageReadyTaggingWrapper
              locale={intl.locale}
              isLoading={isFormDone}
              stepName={BNE_STEP_NAMES.ENTER_SECURITY_CODE}
              flowId={selectedFactor}
              flowDetails={intl.formatMessage({
                id: 'text.title.resendSuccess.bne',
              })}
            >
              <Alert
                className="resend-success-message"
                title={intl.formatMessage({
                  id: 'text.title.resendSuccess.bne',
                })}
                appearance="success"
                size="small"
              />
            </PageReadyTaggingWrapper>
          )}
          {!!sdkError.code && !errors?.code && (
            <Tagging
              ucsId={BNE_UCS_IDS.TECHNICAL_ERROR}
              ucsState={BNE_UCS_STATE.NON_PV}
              eventName={BNE_EVENT_NAMES.PAGE_ERROR}
              eventId={BNE_EVENT_IDS.PE}
              eventSource={BNE_EVENT_SOURCE.BNE}
              technicalError={getOtcErrorMessage(
                templateName,
                sdkError,
                lastLoginFailureDate,
                intl,
              )}
            >
              <TrackState />
              <Alert
                className="send-error-message"
                title={getOtcErrorMessage(
                  templateName,
                  sdkError,
                  lastLoginFailureDate,
                  intl,
                )}
                appearance="error"
                size="small"
                icon={<FailureFilledXsmallColor size="small" title="Error" />}
              />
            </Tagging>
          )}
          {!!errors.code && isMFASubmitFailed && (
            <Tagging
              ucsId={BNE_UCS_IDS.FORM_ERROR}
              ucsState={BNE_UCS_STATE.NON_PV}
              eventName={BNE_EVENT_NAMES.FORM_ERROR}
              eventId={BNE_EVENT_IDS.FORM_ERROR}
              eventSource={BNE_EVENT_SOURCE.BNE}
            >
              <TrackAction
                formError={intl.formatMessage({
                  id: getFormErrorMessagekey(),
                })}
                dependencies={[errors]}
              />
            </Tagging>
          )}
          {!!errors.code && (
            <Alert
              className="send-error-message"
              title={intl.formatMessage({
                id: getFormErrorMessagekey(),
              })}
              appearance="error"
              size="small"
              icon={<FailureFilledXsmallColor size="small" title="Error" />}
            />
          )}

          <Alert
            className="send-message"
            title={codeSendTitle}
            type="h1"
            size="normal"
            icon={getFactorIcon(selectedFactor, false)}
            appearance="information"
          />
          <Message appearance="information" className="info-otc">
            {intl.formatMessage({ id: 'text.message.delayCode.bne' })}
            <br />
            {intl.formatMessage({ id: 'text.message.delayCode.subtext.bne' })}
          </Message>
          <FormHandler formName={FORM_TYPES.MULTI_FACTOR_AUTH_FORM}>
            <MultiFactorAuthForm
              isFetching={isFetching}
              formData={formData}
              formUpdate={formUpdate}
              triggerSubmit={triggerSubmit}
              triggerValidation={triggerValidation}
              errors={errors}
              sdkErrors={sdkError}
              factorTypes={factorTypes}
            />
          </FormHandler>

          <BackLinkButton
            label={intl.formatMessage({
              id: 'global.button.backToLogin.bne',
            })}
            className="back-link-button--entreprise"
            onClick={goBackToLogin}
          />
          <BneBroadcastChannel channelName="accountLocked_sync" />
        </div>
      </PageReadyTaggingWrapper>
    </>
  );
};

MultiFactorAuth.defaultProps = defaultProps;

export default MultiFactorAuth;
