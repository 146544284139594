import React from 'react';
import { Link } from '@bnc-ui/link';
import type { IntlShape } from 'react-intl';
import { isWebView, openWebUrl } from '../../../utils/browserUtils';
import { goToRetail } from '../../../services/biometryService/webviewActions';

import './style.scss';
import { Configs } from '../../../configs';

type Props = {
  intl: IntlShape,
};

const GoToRetailButton = ({ intl }: Props) => (
  <div id="sbip-account">
    <Link
      href="#"
      id="sbip-account-link"
      underlined={false}
      onClick={() => goToRetail()}
      tabIndex="0"
    >
      {intl.formatMessage({ id: 'text.bne.goToSbip' })}
    </Link>
  </div>
);

const openFullSBIESite = intl => {
  // redirect to SBIE full site
  openWebUrl(Configs.TEMPLATES.BNE.APP_URL[intl.locale.toUpperCase()]);
};

const FullSiteLink = ({ intl }: Props) => (
  <div id="sbip-account">
    <Link
      className="center"
      href="#"
      id="sbip-account-link"
      underlined={false}
      onClick={() => openFullSBIESite(intl)}
      tabIndex="0"
    >
      {intl.formatMessage({ id: 'text.bne.goToSbie' })}
    </Link>
  </div>
);

const MobileBottomLinks = ({ intl }: Props) => (
  <>
    {isWebView() && (
      <div className="biometry-login__bottom-links">
        <FullSiteLink intl={intl} />
        <GoToRetailButton intl={intl} />
      </div>
    )}
  </>
);

export default MobileBottomLinks;
