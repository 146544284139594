import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import cx from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Intro from './Intro/index';
import PersonalInfo from './PersonalInfo/index';
import BankProductChoice from './BankProductChoice/index';
import BankProductInfo from './BankProductInfo/index';
import Security from './Security/index';
import Final from './Final/index';

import { VERIFY_USER_MAXIMUM_RETRIES } from '../../../utils/constants/forgotPassword';
import { FORGOT_PASSWORD_FIELDS } from '../../../utils/forgotPasswordUtils/constants';
import { FORGOT_PASSWORD_FORM_UPDATE } from '../../../services/forgotPasswordFormService/actionTypes';
import {
  FORGOT_PASSWORD_PREFIX_VERIFY_USER,
  FORGOT_PASSWORD_CODE_TECHNICAL_ERROR,
  FORGOT_PASSWORD_CODE_VERIFY_USER_TOO_MANY_RETRIES,
  getAuthForgotPasswordErrorMessageId,
} from '../../../utils/authErrorMessages';

import { focusOnComponent } from '../../../utils/domUtils';
import { getPhoneAssistanceNumber } from '../../../utils/templateUtils';
import { ASSISTANT_PHONE_TYPE } from '../../../utils/constants/phoneType';
import './style.scss';
import TEMPLATES from '../../../utils/constants/template';

type Props = {
  intl: intlShape.isRequired,
  className?: string,
  formData: any,
  errors: any,
  isFetching: boolean,
  backendError: {},
  numberOfRetry: number,
  formUpdate: (fieldName: string, fieldValue: string) => void,
  triggerFieldValidation: (fieldName: string) => void,
  triggerFormValidation: () => void,
  setFormInputs?: (inputs: any) => void,
  returnToLogin: () => void,
  displayErrorMessage: boolean,
  templateName: string,
  partnerId: string,
  executedAction: any,
};

const forgotPasswordFormInputs = {};

const getErrorMessageId = (
  backendError,
  numberOfRetry,
  templateName,
  partnerId,
) => {
  if (isEmpty(backendError)) {
    return getAuthForgotPasswordErrorMessageId({
      errorIdPrefix: FORGOT_PASSWORD_PREFIX_VERIFY_USER,
      code: FORGOT_PASSWORD_CODE_TECHNICAL_ERROR,
    });
  }

  if (numberOfRetry >= VERIFY_USER_MAXIMUM_RETRIES) {
    const messageId = getAuthForgotPasswordErrorMessageId({
      errorIdPrefix: FORGOT_PASSWORD_PREFIX_VERIFY_USER,
      code: FORGOT_PASSWORD_CODE_VERIFY_USER_TOO_MANY_RETRIES,
    });
    return (
      messageId +
      (templateName === TEMPLATES.ORION_WHITE_LABEL && partnerId
        ? `.${partnerId}`
        : '')
    );
  }

  return getAuthForgotPasswordErrorMessageId(backendError);
};

class ForgotPasswordForm extends Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    setFormInputs: () => {},
    className: '',
  };

  constructor(props: Props) {
    super(props);
    this.elementRef = null;
    this.oldErrorMessageId = null;
  }

  componentDidUpdate() {
    const {
      displayErrorMessage,
      executedAction,
      formData,
      backendError,
      numberOfRetry,
      templateName,
      partnerId,
    } = this.props;
    if (displayErrorMessage && this.elementRef) {
      const errorId = getErrorMessageId(
        backendError,
        numberOfRetry,
        templateName,
        partnerId,
      );

      if (this.oldErrorMessageId !== errorId) {
        this.elementRef.scrollIntoView(true);
        this.oldErrorMessageId = errorId;
      }
    }

    const { type, fieldValue, fieldName } = executedAction;
    // just click on open choices
    if (
      type === FORGOT_PASSWORD_FORM_UPDATE &&
      fieldName === FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.OPEN_CHOICES &&
      fieldValue
    ) {
      const { productType } = formData;
      focusOnComponent(`input[value=${productType}]`);
    }
  }

  componentDidMount() {
    focusOnComponent(`#${FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL}`);
  }

  handleRef(ref: any) {
    if (ref) {
      this.elementRef = ref;
    }
  }

  render() {
    const {
      intl,
      formData,
      errors,
      isFetching,
      formUpdate,
      triggerFieldValidation,
      triggerFormValidation,
      setFormInputs,
      returnToLogin,
      className,
      templateName,
      partnerId,
      displayErrorMessage,
      backendError,
      numberOfRetry,
    } = this.props;

    const onSubmit = () => {
      this.oldErrorMessageId = null;
      setFormInputs(forgotPasswordFormInputs);
      triggerFormValidation();
    };

    return (
      <div
        className={cx('forgot-password-form', className)}
        ref={ref => this.handleRef(ref)}
      >
        <Intro
          displayErrorMessage={displayErrorMessage}
          vsdPhoneNumber={getPhoneAssistanceNumber(
            templateName,
            partnerId,
            intl.locale,
            ASSISTANT_PHONE_TYPE.INTERNATIONAL,
          )}
          errorMessageId={getErrorMessageId(
            backendError,
            numberOfRetry,
            templateName,
            partnerId,
          )}
        />
        <PersonalInfo
          formUpdate={formUpdate}
          triggerFieldValidation={triggerFieldValidation}
          formValues={{
            [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL]: get(
              formData,
              FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL,
            ),
            [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE]: get(
              formData,
              FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE,
            ),
          }}
          errors={{
            [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL]: get(
              errors,
              FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL,
            ),
            [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE]: get(
              errors,
              FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE,
            ),
          }}
        />
        <BankProductChoice
          contextChoice={get(
            formData,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PRODUCT_TYPE,
          )}
          hasOpenChoices={get(
            formData,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.OPEN_CHOICES,
          )}
          templateName={templateName}
          partnerId={partnerId}
          formUpdate={formUpdate}
        />
        <BankProductInfo
          formData={formData}
          formUpdate={formUpdate}
          triggerValidation={triggerFieldValidation}
          errors={errors}
        />
        <Security
          isRecaptchaError={
            errors &&
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA in errors
          }
          formUpdate={formUpdate}
          triggerFieldValidation={triggerFieldValidation}
        />
        <Final
          isFetching={isFetching}
          onSubmit={onSubmit}
          returnToLogin={returnToLogin}
        />
      </div>
    );
  }
}

export default ForgotPasswordForm;
