// @flow
const ROUTES = {
  LOGIN: '/',
  LOGIN_BIOMETRY: '/biometry',
  NOT_FOUND: '/404',
  ALL: '/*',
  RESETPASSWORD: '/resetPassword',
  RESET_PASSWORD_EXCEPTION: '/resetPasswordException',
  ACCOUNT_LOCKED: '/locked',
  MFA: '/verify',
  RSA: '/rsa',
  MFA_CHOICE: '/challenge',
  UNAUTHORIZED: '/unauthorized',
  UNAVAILABLE: '/unavailable',
  UNSUPPORTED_BROWSER: '/unsupportedBrowser',
  PARAMS_UNAVAILABLE: '/badRequest',
  PASSWORD_CHANGE_SUCCESS: '/passwordChangeSuccess',
  FORGOT_PASSWORD: '/forgotPassword',

  FORGOT_PASSWORD_EXCEPTION: '/forgotPasswordException',
  COMPLETE_UNAVAILABILITY: '/completeUnavailability',
  SESSION_EXPIRED: '/sessionExpired',
  FORBIDDEN: '/forbidden',
  FORGOT_PASSWORD_UNAVAILABLE: '/forgotPasswordUnavailable',
};

export default ROUTES;
