import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';
import Header from '../../../organisms/common/Header';
import ForbiddenError from '../../../organisms/common/ForbiddenError';

import './style.scss';

const ForbiddenTemplate = () => (
  <FullScreenFooterTemplate
    className="forbidden__template"
    header={<Header hasSwitchLang={false} hasTagBrand={false} />}
    main={<ForbiddenError />}
  />
);

export default ForbiddenTemplate;
