// @flow
import React from 'react';
import cx from 'classnames';

import './style.scss';

type Props = {
  className?: string,
  initials: string,
  color?: string,
};

const defaultProps = {
  className: '',
  color: '',
};

const Avatar = (props: Props) => {
  const { className, initials, color } = props;
  return (
    <div
      className={cx('login-avatar', className)}
      style={{ backgroundColor: color }}
      aria-hidden
    >
      {initials}
    </div>
  );
};

Avatar.defaultProps = defaultProps;

export default Avatar;
