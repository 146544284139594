// @flow
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import {
  BNC_AUTH_SIGN_IN_REQUEST,
  BNC_AUTH_SEND_SUCCESS,
  BNC_AUTH_RESEND_SUCCESS,
  BNC_AUTH_SIGN_IN_COMPLETED,
  BNC_AUTH_RESEND_REQUEST,
  BNC_AUTH_RESEND_FAILURE,
  BNC_AUTH_TOKEN_ENROLL,
} from 'bnc-react-services/services/AuthService/actionTypes';
import * as actionTypes from './actionTypes';
import type { Action, LoginState } from './types';
import { RESEND_DISPLAY_COUTDOWN } from '../../utils/constants/login';

export const initialState = (): LoginState => ({
  isFetching: false,
  failure: false,
  sdkError: {},
  identities: [],
  identityRemembered: null,
  identityWithDeviceToken: [],
  MFAfactors: [],
  MFAfactorsHint: [],
  MFAselected: '',
  transactionStatus: '',
  isEnrollAfterBeenChallenged: false,
  contact: '',
  hideResend: true,
  returnToSbip: false,
  showOTCSuccessMessage: false,
  lockedStatus: '',
  mfaDisplayResendCounter: RESEND_DISPLAY_COUTDOWN,
  userPasswordLocked: false,
  lastLoginFailureDate: {},
  hashLogin: '',
  rsaReturnLoginFlag: false,
  rememberDeviceBne: false,
});

export default (state: LoginState = initialState(), action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        failure: false,
        isFetching: false,
        identityRemembered: null,
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        sdkError: action.sdkError,
        identityRemembered: null,
        showOTCSuccessMessage: false,
      };
    case actionTypes.LOGIN_LOAD_IDENTITY_SUCCESS:
      return {
        ...state,
        identities: action.cookieValue,
        isFetching: false,
      };
    case actionTypes.LOGIN_MFA:
      return {
        ...state,
        contact: action.contact,
        sdkError: {},
        failure: false,
        MFAfactors: action.MFAfactors,
        MFAselected: action.MFAselected,
        MFAfactorsHint: get(action, 'MFAfactorsHint', state.MFAfactorsHint),
        transactionStatus: action.transactionStatus,
        isEnrollAfterBeenChallenged: action.isHardEnrolling,
      };
    case actionTypes.LOGIN_MFA_DISPLAY_RESEND:
      return {
        ...state,
        hideResend: false,
      };
    case actionTypes.LOGIN_MFA_HIDE_RESEND:
      return {
        ...state,
        hideResend: true,
      };
    case actionTypes.LOGIN_MFA_UPDATE_CONTACT:
      return {
        ...state,
        MFAselected: action.MFAselected,
        contact: action.contact,
      };
    case actionTypes.LOGIN_MFA_CODE_VALIDATION_REQUEST:
      return {
        ...state,
        failure: false,
        isFetching: true,
      };
    case actionTypes.LOGIN_SET_RETURN_SBIP_LOGIN_FLAG:
      return {
        ...state,
        returnToSbip: true,
      };
    // WATCH ON BNC SERVICES ACTION TYPES
    case BNC_AUTH_SIGN_IN_REQUEST:
      return {
        ...state,
        // $FlowFixMe
        identityRemembered: action.deviceToken ? action.identity : null,
      };
    case BNC_AUTH_RESEND_REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        showOTCSuccessMessage: false,
      };
    case BNC_AUTH_RESEND_FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        sdkError: action.error,
        showOTCSuccessMessage: false,
      };
    case BNC_AUTH_SEND_SUCCESS:
      return {
        ...state,
        isFetching: false,
        failure: false,
        sdkError: {},
        showOTCSuccessMessage: false,
      };
    case BNC_AUTH_RESEND_SUCCESS:
      return {
        ...state,
        isFetching: false,
        failure: false,
        sdkError: {},
        showOTCSuccessMessage: true,
      };
    case BNC_AUTH_SIGN_IN_COMPLETED:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.LOGIN_CLEAR_ERROR_MESSAGES:
      return {
        ...state,
        isFetching: false,
        failure: false,
        sdkError: {},
      };
    case actionTypes.PUT_IDENTITY_WITH_DEVICE_TOKEN:
      return {
        ...state,
        identityWithDeviceToken: uniqBy(
          [
            { login: action.login, deviceToken: action.deviceToken },
            ...get(state, 'identityWithDeviceToken', []),
          ],
          'login',
        ),
      };

    case actionTypes.UPDATE_LOCKED_STATUS:
      return {
        ...state,
        lockedStatus: action.lockedStatus,
      };

    case actionTypes.START_MFA_DISPLAY_RESEND_COUNTER:
      return {
        ...state,
        mfaDisplayResendCounter: RESEND_DISPLAY_COUTDOWN,
        hideResend: true,
      };

    case actionTypes.TICK_MFA_DISPLAY_RESEND_COUNTER:
      return {
        ...state,
        mfaDisplayResendCounter: state.mfaDisplayResendCounter - 1,
      };
    case actionTypes.CLEAR_OTC_RESEND_MESSAGE:
      return {
        ...state,
        showOTCSuccessMessage: false,
      };
    case actionTypes.USER_LOCK_PASSWORD:
      return {
        ...state,
        userPasswordLocked: true,
      };
    case actionTypes.USER_UNLOCK_PASSWORD:
      return {
        ...state,
        userPasswordLocked: false,
      };
    case actionTypes.UPDATE_LAST_LOGIN_FAILURE_DATE:
      return {
        ...state,
        lastLoginFailureDate: action.date,
      };
    case actionTypes.SAVE_HASH_LOGIN:
      return {
        ...state,
        hashLogin: action.hashLogin,
      };
    case BNC_AUTH_TOKEN_ENROLL:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.RSA_RETURN_LOGIN_FLAG:
      return {
        ...state,
        rsaReturnLoginFlag: action.flagStatus,
      };
    case actionTypes.REMEMBER_DEVICE_BNE:
      return {
        ...state,
        rememberDeviceBne: action.flagStatus,
      };
    default:
      return state;
  }
};
