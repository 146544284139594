// @flow
import type { Action as ReduxAction } from '../../types/action';
import * as actionTypes from './actionTypes';

export type ChangeLocaleAction = ReduxAction<
  typeof actionTypes.I18N_SERVICE_CHANGE_LOCALE,
> & {
  locale: string,
};

export type ChangeLocaleExtAction = ReduxAction<
  typeof actionTypes.I18N_SERVICE_CHANGE_LOCALE_EXT,
> & {
  locale: string,
};

export type ProtectChangeLocaleAction = ReduxAction<
  typeof actionTypes.I18N_SERVICE_PROTECT_CHANGE_LOCALE,
>;

export type Action =
  | ChangeLocaleAction
  | ChangeLocaleExtAction
  | ProtectChangeLocaleAction;
