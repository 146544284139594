import get from 'lodash/get';
import {
  BASE_RESOURCES_URL,
  IMAGES_PATH,
  DEFAULT_IMAGE_PATH,
} from '../../../utils/constants/partner';

export const getPartnerImage = (partnerId, locale) =>
  `${BASE_RESOURCES_URL}/${get(
    IMAGES_PATH,
    [partnerId, 'LOGO', locale],
    DEFAULT_IMAGE_PATH.LOGO,
  )}`;

export default {
  getPartnerImage,
};
