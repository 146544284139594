import React from 'react';
import PropTypes from 'prop-types';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';
import ErrorMessage from '../../../organisms/common/ParamUnavailableError';
import Header from '../../../organisms/whiteLabel/Header';

import './style.scss';

const propTypes = {
  errorTitle: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
};

const ErrorTemplate = props => {
  const { errorTitle, errorText } = props;

  const mainContent = (
    <div className="main-center">
      <ErrorMessage errorTitle={errorTitle} errorText={errorText} />
    </div>
  );

  return (
    <FullScreenFooterTemplate
      header={<Header hasSwitchLang={false} />}
      main={mainContent}
      className="error-background"
    />
  );
};

ErrorTemplate.propTypes = propTypes;

export default ErrorTemplate;
