import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getLocale } from '../../../services/i18nService/selectors';
import { getTemplateName } from '../../../services/templateService/selectors';

import RewardsWLBrand from './rewardsWLBrand';

function mapStateToProps(state) {
  return {
    locale: getLocale(state),
    templateName: getTemplateName(state),
  };
}

export default injectIntl(connect(mapStateToProps, null)(RewardsWLBrand));
