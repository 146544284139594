import { datadogRum } from '@datadog/browser-rum';
import initDatadogRum from '../datadogOptions';
import { Configs as ConfigsModule } from '../../configs';

jest.mock('@datadog/browser-rum', () => ({
  datadogRum: {
    init: jest.fn(),
  },
}));

describe('initDatadogRum', () => {
  const mockConfig = {
    ACTIVE: true,
    APPLICATION_ID: 'c6f28874-228b-4270-bef0-deee8c7b9397',
    CLIENT_TOKEN: 'pub38d1f9ba1e3862ccbd3557c6e413196b',
    SAMPLE_RATE: 100,
    TRACK_INTERACTIONS: true,
    ENV: 'app_7571_iamx-login-page-bne_ti',
  };

  beforeEach(() => {
    jest.clearAllMocks();
    ConfigsModule.DATADOG_RUM = mockConfig;
  });

  it('Should not initialize Datadog RUM when not active', () => {
    ConfigsModule.DATADOG_RUM = { ACTIVE: false };

    initDatadogRum();

    expect(datadogRum.init).not.toHaveBeenCalled();
  });
});
