// @flow
import React from 'react';
import cx from 'classnames';
import type { Node } from 'react';

import './style.scss';

type Props = {
  className?: string,
  header: Node,
  main: Node,
  footer?: Node,
  assets: Node,
  modal?: Node,
};

const defaultProps = {
  className: '',
  footer: null,
  modal: null,
};

function Base2ColumnsTemplate(props: Props) {
  const { className, header, main, footer, assets, modal } = props;

  return (
    <div className={cx('template-column', className)}>
      {modal}
      <div className="template-column__content template-column__content--left">
        {header && <header className="header">{header}</header>}
        {main && <main className="main">{main}</main>}
        {footer && <footer className="footer">{footer}</footer>}
      </div>
      {assets && (
        <div className="template-column__content template-column__content--right">
          {assets}
        </div>
      )}
    </div>
  );
}

Base2ColumnsTemplate.defaultProps = defaultProps;

export default Base2ColumnsTemplate;
