/* eslint-disable no-param-reassign */
import { datadogRum } from '@datadog/browser-rum';
import get from 'lodash/get';

import { Configs } from '../configs';
import packageJson from '../../package.json';

const { DATADOG_RUM } = Configs;

const initDatadogRum = () => {
  if (datadogRum && get(DATADOG_RUM, 'ACTIVE')) {
    datadogRum.init({
      applicationId: DATADOG_RUM.APPLICATION_ID,
      clientToken: DATADOG_RUM.CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'iamx-login-front-end-bne',
      version: packageJson.version,
      sampleRate: DATADOG_RUM.SAMPLE_RATE,
      trackInteractions: DATADOG_RUM.TRACK_INTERACTIONS,
      env: DATADOG_RUM.ENV,
      datacenter: 'us',
    });
  }
};

export default initDatadogRum;
