// @flow
import React, { Component } from 'react';
import sessionManager from 'bnc-react-services/managers/SessionManager';

import BneMultiFactorAuthRSATemplate from '../../templates/bne/MultiFactorAuthRSATemplate';
import browserHistory from '../../nav/BrowserHistoryManager';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';
import ROUTES from '../../utils/constants/routes';

type Props = {
  templateName: string,
};

class MultiFactorAuthRSA extends Component<Props> {
  componentDidMount = () => {
    const transactionStatus = sessionManager.getTransactionStatus();

    // Check if an Okta transaction has been started already
    if (!transactionStatus) {
      browserHistory.push(`${ROUTES.LOGIN}${window.location.search}`);
    }
  };

  render() {
    const { templateName } = this.props;

    const loginTemplates = {
      [TEMPLATES.BNE]: <BneMultiFactorAuthRSATemplate />,
    };

    return getTemplateComponent(loginTemplates, templateName);
  }
}

export default MultiFactorAuthRSA;
