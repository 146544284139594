import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { FormGroup } from '@bnc-ui/formGroup';
import { InputGroup } from '@bnc-ui/inputGroup';
import { CurrencyInput } from '@bnc-ui/currencyInput';

import {
  FORGOT_PASSWORD_FIELDS,
  FORGOT_PASSWORD_VALIDATION_RULES,
} from '../../../../utils/forgotPasswordUtils/constants';
import { forgotPasswordFormDataShape } from '../../../../utils/shapes';
import onAmountBlur from './common';

const propTypes = {
  intl: intlShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  formData: forgotPasswordFormDataShape.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

const MortgageInfo = ({ intl, errors, formData, onChange, onBlur }) => {
  return (
    <div className="forgot-password-form--group--mortgage">
      <FormGroup
        label={{
          text: intl.formatMessage({
            id: 'text.label.forgotPassword.mortgageAccountNumber',
          }),
          htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER,
        }}
        validate={{
          hasError:
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER in errors,
          errorMsg: intl.formatMessage({
            id: 'text.error.forgotPassword.mortgageAccountNumber',
          }),
        }}
        tooltip={{
          content: intl.formatMessage({
            id: 'text.tooltip.forgotPassword.mortgageAccountNumber',
          }),
          position: 'top',
        }}
      >
        <InputGroup
          inputId={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER}
          inputName={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER}
          placeholder={intl.formatMessage({
            id: 'text.field.placeholder.forgotPassword.mortgageAccountNumber',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={get(
            formData,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER,
          )}
          maxLength={
            FORGOT_PASSWORD_VALIDATION_RULES.MORTGAGE.ACCOUNT_MAX_LENGTH
          }
          minLength={
            FORGOT_PASSWORD_VALIDATION_RULES.MORTGAGE.ACCOUNT_MIN_LENGTH
          }
        />
      </FormGroup>

      <FormGroup
        label={{
          text: intl.formatMessage({
            id: 'text.label.forgotPassword.mortgageOriginalAmount',
          }),
          htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT,
        }}
        validate={{
          hasError:
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT in
            errors,
          errorMsg: intl.formatMessage({
            id: 'text.error.forgotPassword.mortgageOriginalAmount',
          }),
        }}
      >
        <CurrencyInput
          id={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT}
          name={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT}
          locale={intl.locale}
          onBlur={onAmountBlur(
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT,
            intl.locale,
            onChange,
            onBlur,
          )}
          value={get(
            formData,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT,
          )}
          placeholder={intl.formatMessage({
            id: 'global.placeholder.amount',
          })}
        />
      </FormGroup>
    </div>
  );
};

MortgageInfo.propTypes = propTypes;

export default MortgageInfo;
