import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isValid from 'date-fns/isValid';

import addToDate from 'bnc-utilities-js/date/addToDate';

import requestsManager from 'bnc-react-services/managers/RequestsManager';

import { BIRTHDAY_SKIP_YEARS, DATE_MIN_BIRTH } from '../constants/forms';

export function getFirstErrorKey(errors) {
  return Object.keys(errors)[0];
}

export function yupIsValid(yupSchema, value) {
  return yupSchema.isValid(value);
}

export function yupValidate(yupSchema, value) {
  const result = yupSchema
    .validate(value, { abortEarly: false })
    .catch(err => err);
  return Promise.resolve(result);
}

export function formatFieldsError(formErrors, isValidateForm) {
  const errorsObject = {};
  const errorsTable = [];

  if (formErrors.inner !== undefined) {
    formErrors.inner.forEach(inr => {
      // In the case of only one field in the schema
      if (inr.path === undefined || inr.path === '') {
        errorsTable.push(inr.message);
      } else {
        if (errorsObject[inr.path] === undefined) {
          errorsObject[inr.path] = [];
        }
        errorsObject[inr.path].push(inr.message);
      }
    });
  } else {
    errorsTable.push(formErrors.message);
  }

  return isValidateForm ? errorsObject : errorsTable;
}

export function isNewPasswordFieldValid(resetPasswordForm, input, field) {
  if (resetPasswordForm.isValid) return true;
  if (isEmpty(resetPasswordForm.errors[input])) {
    return !isEmpty(resetPasswordForm.formData[input]);
  }
  return (
    resetPasswordForm.errors[input] &&
    resetPasswordForm.errors[input].indexOf(field) === -1
  );
}

export function* isFieldValid(formData, action, formSchema) {
  const fieldErrors = yield requestsManager.call(
    yupValidate,
    get(formSchema.fields, action.fieldName.replace(/\./g, '.fields.')),
    get(formData, action.fieldName),
  );
  const fieldValid = !fieldErrors || fieldErrors.name !== 'ValidationError';
  let errorField = [];
  let formValid = false;

  // validation failed
  if (!fieldValid) {
    errorField = formatFieldsError(fieldErrors, false);
  } else {
    // validation of entire form if needed
    formValid = yield requestsManager.call(yupIsValid, formSchema, formData);
  }

  return yield {
    errorField,
    formValid,
    fieldValid,
  };
}

export function* isFormValid(formData, formSchema) {
  const formErrors = yield requestsManager.call(
    yupValidate,
    formSchema,
    formData,
  );
  const formValid = !(
    !!formErrors.name && formErrors.name === 'ValidationError'
  );
  let formattedErrors = [];

  if (!formValid) {
    formattedErrors = formatFieldsError(formErrors, true);
  }

  return yield {
    formattedErrors,
    formValid,
  };
}

/**
 * Convert birthday date fields of {DateFields} component to Date object.
 *
 * @param {*} dateFields birthday date fields
 * @returns Date object or null if the fields are invalid
 */
export const convertDateFieldsBirthDayToDate = dateFields => {
  if (dateFields && dateFields.day && dateFields.month && dateFields.year) {
    const year = parseInt(dateFields.year, 10);
    const month = parseInt(dateFields.month, 10);
    const day = parseInt(dateFields.day, 10);

    if (isNaN(year) || isNaN(month) || isNaN(day)) return null;

    const date = new Date(year, month - 1, day);
    date.setFullYear(year);

    /**
     * Date constructor accepts the out of range day and offset the month and date accordingly.
     * For example:
     *
     *   - Date(1990, January, 0) is valid and is translated to 31 December 1990
     *   - Date(1995, December, 0) is valid and is translated to 30 November 1995
     *
     * But it does not make sense to users. Therefore, we do not accept any offset at all and
     * consider those cases as invalid.
     *
     */
    if (date.getDate() !== day) return null;
    if (date.getMonth() !== month - 1) return null;

    if (!isValid(date)) return null;

    const maxDate = addToDate(new Date(), { years: -BIRTHDAY_SKIP_YEARS });
    const minDate = addToDate(new Date(), { years: -DATE_MIN_BIRTH });

    return date >= minDate && date <= maxDate ? date : null;
  }
  return null;
};

export const buildExpiryDate = mmyy => {
  if (!mmyy) return null;
  const [mm, yy] = mmyy.split('/').map(x => Number(x));
  const currentYear = new Date().getFullYear();
  return new Date(yy + currentYear - (currentYear % 100), mm - 1, 27);
};

export const buildPhoneNumber = phoneNumber =>
  phoneNumber ? phoneNumber.toString().replace(/\D+/g, '') : phoneNumber;

export const formatMessageWithFallback = (
  intl,
  messageId,
  defaultValue,
  placeHolderValues,
) =>
  intl.formatMessage({ id: messageId }, placeHolderValues) === messageId
    ? defaultValue
    : intl.formatMessage({ id: messageId }, placeHolderValues);
