// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import type { Dispatch } from '../../../types/store';
import type { State } from '../../../types/state';

import { loginRouteWithoutSignIn } from '../../../services/loginRouteService/actions';
import BackAppRoot from './backAppRoot';

import { getTemplateName } from '../../../services/templateService/selectors';

const mapStateToProps = (state: State) => {
  return {
    templateName: getTemplateName(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loginRouteWithoutSignIn: () => dispatch(loginRouteWithoutSignIn()),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(BackAppRoot),
);
