import React from 'react';
import cx from 'classnames';

import BackAppRoot from '../../../molecules/bao/BackAppRoot';

type Props = {
  className?: string,
};

const defaultProps = {
  className: '',
};

const LoginFooter = (props: Props) => {
  const { className } = props;

  return (
    <div className={cx('bao-login-footer', className)}>
      <BackAppRoot />
    </div>
  );
};

LoginFooter.defaultProps = defaultProps;

export default LoginFooter;
