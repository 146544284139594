import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { announceStatusMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';
import { getAnalytics } from '../../../services/forgotPasswordService/selectors';

import ResetPasswordHandler from './resetPasswordHandler';
import { getHashLogin } from '../../../services/loginService/selectors';

const mapStateToProps = state => ({
  analytics: getAnalytics(state),
  hashLogin: getHashLogin(state),
});

const mapDispatchToProps = dispatch => ({
  announceMessage: msg => dispatch(announceStatusMessage(msg)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordHandler),
);
