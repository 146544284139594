import get from 'lodash/get';
import includes from 'lodash/includes';
import {
  SPECIFIC_MESSAGES_ARRAY,
  GENERIC_WITHOUT_CODE,
  GENERIC_WITH_CODE,
  ERROR_CODE_KEY,
} from './constants';

export const getAuthErrorMessageId = error => {
  if (!get(error, ERROR_CODE_KEY, false)) {
    // Can't find the code in the error provided, we return the generic code without code
    return GENERIC_WITHOUT_CODE;
  }
  if (includes(SPECIFIC_MESSAGES_ARRAY, error[ERROR_CODE_KEY])) {
    // This code has a specific message so we use it as key
    return error[ERROR_CODE_KEY];
  }
  // Returning generic error message
  return GENERIC_WITH_CODE;
};

/**
 * Forgot Password Error Message Mapping
 */

export const FORGOT_PASSWORD_PREFIX_VERIFY_USER = 'verifyUser';
export const FORGOT_PASSWORD_PREFIX_VERIFY_OTC = 'verifyOTC';
export const FORGOT_PASSWORD_PREFIX_SEND_OTC = 'sendOTC';
export const FORGOT_PASSWORD_PREFIX_SET_PASSWORD = 'setPassword';

export const FORGOT_PASSWORD_CODE_TECHNICAL_ERROR = 99999;
export const FORGOT_PASSWORD_CODE_VERIFY_USER_TOO_MANY_RETRIES = 99998;
export const FORGOT_PASSWORD_CODE_FORM_ERROR = 99997;

export const getAuthForgotPasswordErrorMessageId = ({
  code,
  errorIdPrefix,
}) => {
  const prefixErrorId = id => `forgotPassword.${errorIdPrefix}.${id}`;
  switch (errorIdPrefix) {
    case FORGOT_PASSWORD_PREFIX_VERIFY_USER:
      switch (code) {
        case FORGOT_PASSWORD_CODE_VERIFY_USER_TOO_MANY_RETRIES:
          return prefixErrorId('manyRetries');
        case FORGOT_PASSWORD_CODE_FORM_ERROR:
          return prefixErrorId('form');
        case FORGOT_PASSWORD_CODE_TECHNICAL_ERROR:
          return prefixErrorId('technical');
        default:
          return prefixErrorId('business');
      }

    case FORGOT_PASSWORD_PREFIX_SEND_OTC:
    case FORGOT_PASSWORD_PREFIX_VERIFY_OTC:
      return code === FORGOT_PASSWORD_CODE_TECHNICAL_ERROR
        ? prefixErrorId('technical')
        : prefixErrorId('business');

    default:
      return prefixErrorId('unimplemented');
  }
};
