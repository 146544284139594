export const BIOMETRIC_COOKIE_NAME = 'biometric';

export const BIOMETRIC_VALIDATION = 'biometricValidation';
export const BIOMETRIC_VALIDATION_RESULT = 'biometricValidationResults';
export const BIOMETRIC_CREDENTIALS_DELETION = 'deleteBiometricCredentials';
export const BIOMETRIC_CREDENTIALS_UPDATE = 'setBiometricCredentials';
export const BIOMETRIC_CREDENTIALS_DELETION_RESULT =
  'deleteBiometricCredentialsResults';
export const BIOMETRY_SUCCESS = 'success';
export const BIOMETRY_FAILED = 'fail';
export const BIOMETRY_THREE_ATTEMPTS_FAILS = 'threeAttemptsFailed'; // only on iOS. Android manage 5 biometry errors and return a failed status
export const BIOMETRY_CREDENTIALS_DELETION_FAILED =
  'biometryCredentialsDeletionFailed';
export const BIOMETRY_CANCEL = 'cancel';
