import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { announceAlertMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';

import ResetPassword from './resetPassword';
import { returnToLogin } from '../../../services/loginService/actions';
import { failureSelector } from '../../../services/resetPasswordService/selectors';

import type { Dispatch } from '../../../types/store';
import { setPassword } from '../../../services/forgotPasswordService/actions';
import { getBackendError } from '../../../services/forgotPasswordService/selectors';

const mapStateToProps = state => ({
  isSubmitFailed: failureSelector(state),
  backendError: getBackendError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  returnToLogin: () => dispatch(returnToLogin()),
  announceAlertMessage: message => dispatch(announceAlertMessage(message)),
  resetPasswordRequest: newPassword => dispatch(setPassword(newPassword)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword),
);
