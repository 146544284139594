import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { announceAlertMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';

import ResetPassword from './resetPassword';
import { returnToLogin } from '../../../services/loginService/actions';
import {
  failureSelector,
  isFetching,
  getBackendError,
} from '../../../services/resetPasswordService/selectors';
import { resetPasswordRequest } from '../../../services/resetPasswordService/actions';

import type { Dispatch } from '../../../types/store';

const mapStateToProps = state => ({
  isSubmitFailed: failureSelector(state),
  isFetching: isFetching(state),
  backendError: getBackendError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  returnToLogin: () => dispatch(returnToLogin()),
  announceAlertMessage: message => dispatch(announceAlertMessage(message)),
  resetPasswordRequest: (newPassword, passwordIsExpired, currentPassword) =>
    dispatch(
      resetPasswordRequest(newPassword, passwordIsExpired, currentPassword),
    ),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword),
);
