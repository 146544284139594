// @flow
import React from 'react';
import cx from 'classnames';
import type { IntlShape } from 'react-intl';
import { Title } from 'bnc-react-components';
import { Button } from '@bnc-ui/button';

import { getMessageKey } from '../../../utils/templateUtils';

import './style.scss';

type Props = {
  intl: IntlShape,
  className?: string,
  isVisible?: boolean,
  loginRouteRequestSignUp: () => void,
  templateName: string,
};

const defaultProps = {
  className: '',
  isVisible: true,
};

function SignUp(props: Props) {
  const {
    intl,
    className,
    isVisible,
    loginRouteRequestSignUp,
    templateName,
  } = props;

  const signUpMsgKey = getMessageKey(
    templateName,
    'rewards.text.mess.createaccount',
  );
  const signUpButtonKey = getMessageKey(
    templateName,
    'text.button.signUpRewards',
  );
  const signUpAriaKey = getMessageKey(templateName, 'text.aria.signUp');

  if (!isVisible) {
    return null;
  }

  const onSignUpRequest = () => {
    loginRouteRequestSignUp();
  };

  const ariaSignUpMessage = intl.formatMessage({ id: signUpAriaKey });
  const signUpMessage = intl.formatMessage({ id: signUpMsgKey });

  return (
    <div className={cx('sign-up', className)}>
      <Title component="h2" defaultClasses="sign-up__title">
        {intl.formatMessage({ id: 'rewards.text.title.firstconnection' })}
      </Title>
      <p className={cx('sign-up__message', className)}>{signUpMessage}</p>

      <Button
        appearance="outlined"
        size="medium"
        onClick={onSignUpRequest}
        ariaLabel={ariaSignUpMessage}
      >
        {intl.formatMessage({ id: signUpButtonKey })}
      </Button>
    </div>
  );
}

SignUp.defaultProps = defaultProps;

export default SignUp;
