export default class RecaptchaSdk {
  constructor(
    siteKey,
    exchangeTokenEndpoint,
    googleRecaptchaApiEndpoint,
    parentDiv,
    locale,
    nbfgId,
  ) {
    this.locale = locale;
    this.siteKey = siteKey;
    this.exchangeTokenEndpoint = exchangeTokenEndpoint;
    this.parentDiv = parentDiv;
    this.nbfgId = nbfgId;
    this.reCapatchaId = null;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${googleRecaptchaApiEndpoint}?hl=${locale}`;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  initCallBacks = (success, failure, timeoutRecaptcha) => {
    this.success = success;
    this.failure = failure;
    this.timeoutRecaptcha = timeoutRecaptcha;
  };

  show = () => {
    if (this.isReadyCheck()) {
      this.displayRecaptcha();
    } else {
      this.readyCheck = setInterval(this.updateReadyState, 1000);
    }
  };

  isReadyCheck = () =>
    typeof window !== 'undefined' &&
    typeof window.grecaptcha !== 'undefined' &&
    typeof window.grecaptcha.render === 'function' &&
    document.getElementById(this.parentDiv);

  updateReadyState = () => {
    if (this.isReadyCheck()) {
      clearInterval(this.readyCheck);
      this.displayRecaptcha();
    }
  };

  displayRecaptcha = () => {
    const parentElement = document.getElementById(this.parentDiv);
    // make sure it's empty (required by Google)
    parentElement.innerHTML = '';
    this.reCapatchaId = window.grecaptcha.render(parentElement, {
      sitekey: this.siteKey,
      callback: this.exchangeToken,
      'error-callback': this.failure,
      'expired-callback': this.timeoutRecaptcha,
      hl: this.locale,
    });
  };

  reset = () => {
    if (this.reCapatchaId !== null) {
      window.grecaptcha.reset(this.reCapatchaId);
    }
  };

  exchangeToken = token => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (this.nbfgId) {
      headers.nbfgId = this.nbfgId;
    }
    fetch(this.exchangeTokenEndpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        recaptcha: token,
        siteKeyType: this.siteKey,
      }),
    })
      .then(response => response.json())
      // eslint-disable-next-line consistent-return
      .then(json => {
        if (json.expiresIn) {
          const expirationTime = new Date();
          // Reduce the expiration time by 5 seconds to make sure that the token is still after the validation check
          expirationTime.setSeconds(
            expirationTime.getSeconds() + parseInt(json.expiresIn, 10) - 5,
          );
          // eslint-disable-next-line no-param-reassign
          json.expirationTime = expirationTime;
          return this.success(json);
        }
        this.failure('Incorrect token');
      })
      .catch(err => this.failure(err));
  };
}
