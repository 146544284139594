// @flow
import * as actionTypes from './actionTypes';
import type { Action as ReduxAction } from '../../types/action';

import type {
  ForgotPasswordFormData,
  ForgotPasswordFormErrors,
} from '../../types/forms/forgotPasswordForm';
import type { FormType } from '../../types/constants';

export type ForgotPasswordFormUpdateAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_FORM_UPDATE,
> & {
  fieldName: string,
  fieldValue: string,
};

export type ForgotPasswordFormTriggerFieldValidationAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_FORM_FIELD_VALIDATION_REQUEST,
> & {
  fieldName: string,
};

export type ForgotPasswordFormFieldValidationSuccessAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_FORM_FIELD_VALIDATION_SUCCESS,
> & {
  fieldName: string,
  isValid: boolean,
};

export type ForgotPasswordFormFieldValidationFailureAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE,
> & {
  fieldName: string,
  errors: ForgotPasswordFormErrors,
};

export type ForgotPasswordFormInitFormAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_FORM_INIT_REQUEST,
> & {
  formId: FormType,
  defaultValues: ForgotPasswordFormData,
};

export type ForgotPasswordFormInitFormDoneAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_FORM_INIT_DONE,
> & {
  payload: ForgotPasswordFormData,
};

export type ForgotPasswordFormState = {|
  formId: FormType,
  isValid: boolean,
  errors: ForgotPasswordFormErrors,
  formData: ForgotPasswordFormData,
  failure: boolean,
  initDone: boolean,
|};

export type Action =
  | ForgotPasswordFormInitFormAction
  | ForgotPasswordFormInitFormDoneAction
  | ForgotPasswordFormTriggerFieldValidationAction
  | ForgotPasswordFormUpdateAction
  | ForgotPasswordFormFieldValidationFailureAction
  | ForgotPasswordFormFieldValidationSuccessAction;
