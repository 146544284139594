import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import cx from 'classnames';

import { Heading } from '@nbc-design/heading';
import { Text } from '@nbc-design/text';
import isEmpty from 'lodash/isEmpty';
import { Alert } from '@nbc-design/alert';
import { FormGroup } from '@nbc-design/form-group';
import { Password } from '@nbc-design/password';
import { PasswordStrengthBar } from '@nbc-design/password-strength-bar';
import { Constants } from 'bnc-react-components';
import { TaggingForm, TaggingStep } from '@nbc-studio/analytics';
import { Message } from '@bnc-ui/message';

import { isFocusOnInputComponents } from '../../../../utils/domUtils';
import PasswordCriteriaForm from '../PasswordCriteria/index';

import { taggingErrors, voidCopyPasteEvents } from '../helpers';
import ResetPasswordButtonSection from '../../../../organisms/common/ResetPasswordButtonSection/index';

const CURRENT_PASSWORD = 'currentPassword';
const NEW_PASSWORD = 'newPassword';
const PASSWORD_CONFIRMATION = 'newPasswordConfirmation';
const PASSWORD_STRENGTH = 'passwordStrength';

const propTypes = {
  intl: intlShape.isRequired,
  resetForm: PropTypes.func.isRequired,
  formStatus: PropTypes.shape({
    hasCurrentPassword: PropTypes.bool,
    hasRequestError: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool,
  }),
  content: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    description: PropTypes.string,
    instructionMessage: PropTypes.string,
    requestError: PropTypes.string,
    currentPasswordLabel: PropTypes.string,
    currentPasswordError: PropTypes.string,
    currentPasswordPlaceHolder: PropTypes.string,
    newPasswordLabel: PropTypes.string,
    newPasswordError: PropTypes.string,
    newPasswordRequired: PropTypes.string,
    newPasswordPlaceHolder: PropTypes.string,
    newPasswordAriaLabelShow: PropTypes.string,
    newPasswordAriaLabelHide: PropTypes.string,
    passwordStrengthInformationTitle: PropTypes.string,
    passwordStrengthInformationMessage: PropTypes.string,
    passwordConfirmationLabel: PropTypes.string,
    passwordConfirmationPlaceHolder: PropTypes.string,
  }),
  criteriaMap: PropTypes.shape({}).isRequired,
  analyticsData: PropTypes.shape({ step: PropTypes.shape({}) }),
  functions: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    onPreSubmit: PropTypes.func.isRequired,
  }).isRequired,
  announceMessage: PropTypes.func.isRequired,
};

const defaultProps = {
  formStatus: {
    hasCurrentPassword: false,
    isProcessing: false,
  },
  content: {},
  analyticsData: { step: {} },
};

const ResetPassword = ({
  intl,
  formStatus,
  content,
  analyticsData,
  criteriaMap,
  functions,
  resetForm,
  announceMessage,
}) => {
  const {
    title,
    message,
    description,
    instructionMessage,
    requestError,
    currentPasswordLabel,
    currentPasswordError,
    currentPasswordPlaceHolder,
    newPasswordLabel,
    newPasswordError,
    newPasswordRequired,
    newPasswordPlaceHolder,
    newPasswordAriaLabelShow,
    newPasswordAriaLabelHide,
    passwordStrengthInformationTitle,
    passwordStrengthInformationMessage,
    passwordConfirmationLabel,
    passwordConfirmationPlaceHolder,
  } = content;

  const { hasCurrentPassword, hasRequestError, isProcessing } = formStatus;

  const { onSubmit, onValidate, onPreSubmit } = functions;

  const { step } = analyticsData;

  return (
    <TaggingForm>
      <TaggingStep {...step}>
        <Formik
          initialValues={{
            ...(hasCurrentPassword && { currentPassword: '' }),
            newPassword: '',
            newPasswordConfirmation: '',
          }}
          onSubmit={onSubmit}
          validate={onValidate}
        >
          {({
            errors,
            touched,
            setFieldTouched,
            setFieldValue,
            dirty,
            validateForm,
            values,
          }) => {
            return (
              <div className="reset-password-form-container">
                <Form
                  id="resetPasswordForm"
                  className={cx('connect-form', 'reset-password-form')}
                >
                  {title && (
                    <Heading type="h1" size={1}>
                      {title}
                    </Heading>
                  )}
                  {message && (
                    <Alert
                      className="reset-password-form__success"
                      appearance="success"
                      size="small"
                      title={message}
                    />
                  )}
                  {description && (
                    <Message
                      className="reset-password-form__temp"
                      appearance="information"
                    >
                      {description}
                    </Message>
                  )}

                  {hasCurrentPassword && (
                    <div className="form-field">
                      <FormGroup
                        label={{
                          text: currentPasswordLabel,
                          htmlFor: { CURRENT_PASSWORD },
                        }}
                        validate={{
                          hasError:
                            errors.currentPassword && touched.currentPassword,
                          errorMsg: currentPasswordError,
                        }}
                      >
                        <Field name={CURRENT_PASSWORD}>
                          {({ field }) => (
                            <Password
                              id={CURRENT_PASSWORD}
                              placeholder={currentPasswordPlaceHolder}
                              ariaInvalid={errors.currentPassword}
                              autoComplete={
                                Constants.AUTOCOMPLETE.CURRENT_PASSWORD
                              }
                              {...field}
                              onBlur={() => taggingErrors(errors, field.name)}
                            />
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                  )}
                  <FormGroup
                    className="reset-password-form-group__new-password"
                    label={{
                      text: newPasswordLabel,
                      htmlFor: { NEW_PASSWORD },
                    }}
                    validate={{
                      hasError:
                        !isEmpty(errors.notContainEmailID) ||
                        !isEmpty(errors.newPassword),
                      errorMsg:
                        (errors.notContainEmailID &&
                          intl.formatMessage({
                            id: errors.notContainEmailID,
                          })) ||
                        (errors.newPasswordEmpty && newPasswordRequired) ||
                        newPasswordError,
                    }}
                  >
                    <Text
                      size="paragraph"
                      className="reset-password-instruction-message"
                      appearance="muted"
                    >
                      {instructionMessage}
                    </Text>
                    <Field name={NEW_PASSWORD}>
                      {({ field }) => (
                        <Password
                          id={NEW_PASSWORD}
                          placeholder={newPasswordPlaceHolder}
                          onCut={voidCopyPasteEvents}
                          onPaste={voidCopyPasteEvents}
                          onCopy={voidCopyPasteEvents}
                          ariaLabelShow={newPasswordAriaLabelShow}
                          ariaLabelHide={newPasswordAriaLabelHide}
                          autoComplete={Constants.AUTOCOMPLETE.NEW_PASSWORD}
                          {...field}
                          onBlur={() => {
                            validateForm();
                            taggingErrors(errors, field.name);
                            setFieldTouched('newPassword');
                          }}
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <PasswordStrengthBar
                    password={values.newPassword}
                    locale={intl.locale}
                    minLength={1}
                    onChangeScore={score => {
                      setFieldValue(PASSWORD_STRENGTH, score < 2);
                    }}
                  />
                  {values[PASSWORD_STRENGTH] &&
                    !isFocusOnInputComponents() &&
                    !errors.newPassword && (
                      <Alert
                        title={passwordStrengthInformationTitle}
                        description={passwordStrengthInformationMessage}
                        appearance="information"
                      />
                    )}
                  <PasswordCriteriaForm
                    criteriaMessageMap={criteriaMap}
                    errors={errors}
                    dirty={dirty}
                    announceMessage={announceMessage}
                  />
                  <FormGroup
                    label={{
                      text: passwordConfirmationLabel,
                      htmlFor: { PASSWORD_CONFIRMATION },
                    }}
                    validate={{
                      hasError:
                        errors.newPasswordConfirmation &&
                        values.newPasswordConfirmation,
                      errorMsg:
                        errors.newPasswordConfirmation &&
                        intl.formatMessage({
                          id: errors.newPasswordConfirmation,
                        }),
                    }}
                  >
                    <Field name={PASSWORD_CONFIRMATION}>
                      {({ field }) => (
                        <Password
                          id={PASSWORD_CONFIRMATION}
                          placeholder={passwordConfirmationPlaceHolder}
                          onCut={voidCopyPasteEvents}
                          onPaste={voidCopyPasteEvents}
                          onCopy={voidCopyPasteEvents}
                          ariaLabelShow={newPasswordAriaLabelShow}
                          ariaLabelHide={newPasswordAriaLabelHide}
                          {...field}
                          onBlur={() => {
                            validateForm();
                            taggingErrors(errors, field.name);
                          }}
                        />
                      )}
                    </Field>
                  </FormGroup>
                  {hasRequestError && (
                    <Alert
                      appearance="error"
                      size="small"
                      title={requestError}
                    />
                  )}
                  <ResetPasswordButtonSection
                    isProcessing={isProcessing}
                    onClickFunction={() => onPreSubmit(values, validateForm)}
                    resetForm={resetForm}
                  />
                </Form>
              </div>
            );
          }}
        </Formik>
      </TaggingStep>
    </TaggingForm>
  );
};

ResetPassword.propTypes = propTypes;
ResetPassword.defaultProps = defaultProps;
export default ResetPassword;
