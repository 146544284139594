import clickToCallNumberFormatter from '../phoneNumberUtils';

describe('clickToCallNumberFormatter', () => {
  const testCases = [
    {
      phoneNumber: '514-333-5555',
      withCommand: true,
      expected: 'tel:+1-514-333-5555',
    },
    {
      phoneNumber: '5143335555',
      withCommand: true,
      expected: 'tel:+1-514-333-5555',
    },
    {
      phoneNumber: '(514) 333 5555',
      withCommand: true,
      expected: 'tel:+1-514-333-5555',
    },
    {
      phoneNumber: '+1 (514) 333 5555',
      withCommand: true,
      expected: 'tel:+1-514-333-5555',
    },
    {
      phoneNumber: '+1 514-333 5555',
      withCommand: true,
      expected: 'tel:+1-514-333-5555',
    },
    {
      phoneNumber: '+1-514-333-5555',
      withCommand: true,
      expected: 'tel:+1-514-333-5555',
    },
    {
      phoneNumber: '514-333-5555',
      withCommand: false,
      expected: '1-514-333-5555',
    },
    {
      phoneNumber: '5143335555',
      withCommand: false,
      expected: '1-514-333-5555',
    },
    {
      phoneNumber: '(514) 333 5555',
      withCommand: false,
      expected: '1-514-333-5555',
    },
    {
      phoneNumber: '+1 (514) 333 5555',
      withCommand: false,
      expected: '1-514-333-5555',
    },
    {
      phoneNumber: '+1 514-333 5555',
      withCommand: false,
      expected: '1-514-333-5555',
    },
    {
      phoneNumber: '1-514-333-5555',
      withCommand: false,
      expected: '1-514-333-5555',
    },
  ];

  testCases.map(testCase =>
    it(`clickToCallNumberFormatter with phoneNumber: ${testCase.phoneNumber}, withCommand: ${testCase.withCommand} giving ${testCase.expected} as result`, () => {
      expect(
        clickToCallNumberFormatter(testCase.phoneNumber, testCase.withCommand),
      ).toEqual(testCase.expected);
    }),
  );
});
