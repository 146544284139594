import React from 'react';

import TEMPLATES from '../../utils/constants/template';
import BiometryLogin from '../../templates/bne/LoginBiometry';
import { getTemplateComponent } from '../../utils/templateUtils';

type Props = {
  templateName: string,
};

function LoginBiometry(props: Props) {
  const { templateName } = props;

  const loginBiometryTemplates = {
    [TEMPLATES.BNE]: <BiometryLogin />,
  };

  return getTemplateComponent(loginBiometryTemplates, templateName);
}

export default LoginBiometry;
