// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';
import { Button } from '@bnc-ui/button';
import { Text } from '@bnc-ui/text';
import { Confirmation } from '@bnc-ui/confirmation';

import './style.scss';

type Props = {
  intl: IntlShape,
  loginRouteRequestAppOrigin: () => void,
};

const PasswordChangeSuccess = (props: Props) => {
  const { intl, loginRouteRequestAppOrigin } = props;

  return (
    <div className="rewards-password-change-success rewards">
      <Confirmation
        type="h1"
        appearance="success"
        title={intl.formatMessage({ id: 'text.message.passwordChanged' })}
      />

      <Text>
        {intl.formatMessage({ id: 'text.message.passwordChangedInstructions' })}
      </Text>

      <Button appearance="primary" onClick={() => loginRouteRequestAppOrigin()}>
        {intl.formatMessage({ id: 'global.link.backToLogin' })}
      </Button>
    </div>
  );
};

export default PasswordChangeSuccess;
