import { connect } from 'react-redux';
import CompleteUnavailability from './completeUnavailability';
import { getTemplateName } from '../../services/templateService/selectors';

function mapStateToProps(state) {
  return {
    templateName: getTemplateName(state),
  };
}

export default connect(mapStateToProps)(CompleteUnavailability);
