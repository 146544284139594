import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getMessage,
  getPoliteness,
  getForceRender,
} from 'bnc-react-services/services/LiveAnnouncerService/selectors';
import type { State } from '../../../types/state';

import LiveAnnouncer from './liveAnnouncer';

function mapStateToProps(state: State) {
  return {
    message: getMessage(state),
    politeness: getPoliteness(state),
    forceRender: getForceRender(state),
  };
}

export default injectIntl(connect(mapStateToProps)(LiveAnnouncer));
