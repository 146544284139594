import { connect } from 'react-redux';

import Security from './security';
import {
  resetRecaptcha,
  resetRecaptchaDone,
} from '../../../../services/forgotPasswordFormService/actions';

const mapStateToProps = state => ({
  hasToResetRecaptcha: state.forgotPasswordForm.hasToResetRecaptcha,
});

const mapDispatchToProps = dispatch => ({
  resetRecaptchaDone: () => dispatch(resetRecaptchaDone()),
  resetRecaptcha: () => dispatch(resetRecaptcha()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Security);
