// @flow
export const OS = {
  name: 'Mac OS',
  version: '10.12.5',
};

export const Browser = {
  major: '62',
  name: 'Chrome',
  version: '62.0.3202.94',
};

export const UnsupportedBrowser = {
  major: '10',
  name: 'Opera',
  version: '10.0',
};
