// @flow
import React from 'react';
import cx from 'classnames';
import type { IntlShape } from 'react-intl';
import { Button } from '@bnc-ui/button';
import { Heading } from '@bnc-ui/heading';

import './style.scss';

type Props = {
  intl: IntlShape,
  className?: string,
  loginRouteWithoutSignIn: () => void,
};

const defaultProps = {
  className: '',
};

const BackAppRoot = (props: Props) => {
  const { intl, className, loginRouteWithoutSignIn } = props;

  const backAppRootMsgKey = 'bao.text.accessQuestion';
  const backAppRootButtonKey = 'bao.button.continueWithoutSignUp';
  const backAppRootAriaKey = 'bao.aria.accessQuestion';

  const onBackAppRootRequest = () => {
    loginRouteWithoutSignIn();
  };

  const ariaBackAppRootMessage = intl.formatMessage({ id: backAppRootAriaKey });
  const backAppRootMessage = intl.formatMessage({ id: backAppRootMsgKey });

  return (
    <div className={cx('back-app-root', className)}>
      <Heading
        type="h4"
        size={4}
        className={cx('back-app-root__message', className)}
      >
        {backAppRootMessage}
      </Heading>

      <Button
        appearance="secondary"
        size="medium"
        onClick={onBackAppRootRequest}
        aria-label={ariaBackAppRootMessage}
      >
        {intl.formatMessage({ id: backAppRootButtonKey })}
      </Button>
    </div>
  );
};

BackAppRoot.defaultProps = defaultProps;

export default BackAppRoot;
