// @flow
import { put, take, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import findKey from 'lodash/findKey';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import head from 'lodash/head';
import keys from 'lodash/keys';
import isArray from 'lodash/isArray';
import startsWith from 'lodash/startsWith';

import type {
  FocusOnFirstErrorFieldAction,
  ResetFormAction,
  FormInitRequestAction,
} from './types';

import * as actions from './actions';
import helper from './helper';

/**
 *
 * action.type = FORM_FOCUS_ERROR_INPUT
 * @param {*} action
 */
export function* watchFocusOnFirstErrorField(
  action: FocusOnFirstErrorFieldAction,
): Saga<void> {
  const { forms } = (yield select()).formHandlerServiceReducer;
  const actionForm = get(forms, action.formName, false);
  const errors = get(action, 'errors', {});

  if (actionForm && !isEmpty(errors)) {
    /**
     * firstKey can be a form or a field
     * Field gonna be on the form of :
     * amount: ['error1','error2']
     *
     * if it's a form, it's gonna be like :
     * uniqueId : {
     *  amount: ['error'],
     *  somethingElseInError: ['allError','error']
     * }
     */
    const firstKey = head(keys(errors));

    let input: HTMLElement | null = get(actionForm, firstKey, null);

    // Is a form with embedded ones ?
    if (!isArray(get(errors, firstKey, []))) {
      // find the first form in error (not necessarily firstKey)
      const firstForm = findKey(errors, e => !isEmpty(e));

      // Take the first attribute of the first form in error
      const firstField = head(keys(get(errors, firstForm, false)));

      input = get(actionForm, [firstForm, firstField], null);
      const elem = document.getElementsByName(firstField);

      if (!input && !isEmpty(elem)) {
        // Take the first attribute of the first field in first form error and the element Id is the special one.
        input = head(elem);
      }
    }

    if (!isEmpty(input)) {
      if (!startsWith(get(input, ['parentNode', 'id'], ''), 'datepicker_')) {
        // Datepicker, focus don't open the calendar
        input.focus && input.focus();
      }
      input.scrollIntoView && input.scrollIntoView(action.scrollTop);
    } else if (document.querySelector(`#${firstKey}`)) {
      // Dialog, find the error on dialog modals
      const field: ?Element = document.querySelector(`#dialog #${firstKey}`);
      field && field.scrollIntoView(action.scrollTop);
    }

    yield put(actions.focusOnFirstErrorFieldSuccess());
    return;
  }
  yield put(actions.noFieldToFocusOn());
}

export function* watchResetFormRequest(action: ResetFormAction): Saga<void> {
  const resetForm = get(
    helper,
    ['formInfos', action.formName, 'resetForm'],
    false,
  );
  if (resetForm) {
    yield put(resetForm(action.formId));
  }
}

export function* watchFormInitRequest(
  action: FormInitRequestAction,
): Saga<void> {
  const initForm = get(
    helper,
    ['formInfos', action.formName, 'initForm'],
    false,
  );
  const initDoneActionType = get(
    helper,
    ['formInfos', action.formName, 'initDoneActionType'],
    false,
  );
  if (initForm && initDoneActionType) {
    yield put(initForm(action.formId, ...action.initArgs));
    yield take(initDoneActionType);
    yield put(actions.formInitDone(action.formId));
  }
}
