import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Login from './login';
import type { BiometryState } from '../../../services/biometryService/types';
import { forceLoginSelector } from '../../../services/biometryService/selectors';

const mapStateToProps = (state: BiometryState) => ({
  isForceLogin: forceLoginSelector(state),
});

export default injectIntl(connect(mapStateToProps, null)(Login));
