// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import BneBroadcastChannel from './bneBroadcastChannel';

import type { Dispatch } from '../../../types/store';
import type { State } from '../../../types/state';
import { updateLockedStatus } from '../../../services/loginService/actions';

import { getTemplateName } from '../../../services/templateService/selectors';
import { loginRouteRequestLocked } from '../../../services/loginRouteService/actions';

const mapStateToProps = (state: State) => ({
  templateName: getTemplateName(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateLockedStatus: lockedStatus =>
    dispatch(updateLockedStatus(lockedStatus)),
  loginRouteRequestLocked: () => dispatch(loginRouteRequestLocked()),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(BneBroadcastChannel),
);
