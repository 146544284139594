import get from 'lodash/get';
import {
  BASE_RESOURCES_URL,
  IMAGES_PATH,
  DEFAULT_IMAGE_PATH,
} from '../../utils/constants/partner';

const getPartnerFile = (partnerId, fileType, locale) =>
  `${BASE_RESOURCES_URL}/${get(
    IMAGES_PATH,
    [partnerId, fileType, locale],
    DEFAULT_IMAGE_PATH[fileType],
  )}`;

const getFaviconsForBNC = () => ({
  faviconHref: `${process.env.PUBLIC_URL}/favicons/favicon.ico`,
  pngIconHref: `${process.env.PUBLIC_URL}/favicons/icon.png`,
  appleTouchIconHref: `${process.env.PUBLIC_URL}/favicons/ios-icon.png`,
  msApplication310Href: `${process.env.PUBLIC_URL}/favicons/icon_largetile.png`,
});

const getFaviconsForPartner = (partnerId, locale) => ({
  faviconHref: getPartnerFile(partnerId, 'FAVICON', locale),
  pngIconHref: getPartnerFile(partnerId, 'PNG_ICON', locale),
  appleTouchIconHref: getPartnerFile(partnerId, 'IOS_ICON', locale),
  msApplication310Href: getPartnerFile(partnerId, 'LARGE_ICON', locale),
});

const getFavicons = (partnerId, locale) =>
  partnerId ? getFaviconsForPartner(partnerId, locale) : getFaviconsForBNC();

export default {
  getFavicons,
};
