/* import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { Dispatch } from '../../../types/store';

import LoginBiometry from './loginBiometry';
import type { BiometryState } from '../../../services/biometryService/types';

import { initBiometry } from '../../../services/biometryService/actions';
import { biometrySelector } from '../../../services/biometryService/selectors';

const mapStateToProps = (state: BiometryState) => ({
  biometry: biometrySelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initBiometry: () => dispatch(initBiometry()),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LoginBiometry),
);
*/
import LoginBiometry from './loginBiometry';

export default LoginBiometry;
