import React from 'react';

import isEmpty from 'lodash/isEmpty';

import HeadTitle from '../../common/HeadTitle';
import FormHandler from '../../../molecules/forms/FormHandler';
import ForgotPasswordForm from '../../../molecules/forms/ForgotPasswordForm';
import { FORM_TYPES } from '../../../utils/constants/forms';

type Props = {
  formData: any,
  errors: any,
  formUpdate: (fieldName: string, fieldValue: string) => void,
  triggerFieldValidation: (fieldName: string) => void,
  triggerFormValidation: () => void,
  returnToLogin: () => void,
  isFetching: boolean,
  isFailure: boolean,
  isTokenCaptchaError: boolean,
  backendError: {},
  numberOfRetry: number,
  templateName: string,
};

const ForgotPassword = (props: Props) => {
  const {
    formData,
    errors,
    formUpdate,
    triggerFieldValidation,
    triggerFormValidation,
    isFetching,
    isFailure,
    returnToLogin,
    isTokenCaptchaError,
    backendError,
    numberOfRetry,
    templateName,
  } = props;

  const defaultValues = {
    email: '',
    recaptchaResponse: {},
  };

  return (
    <div className="forgot-password">
      <HeadTitle messageKey="text.title.passwordReset" />

      <FormHandler
        formName={FORM_TYPES.FORGOT_PASSWORD_FORM}
        initFormArgs={[defaultValues]}
      >
        <ForgotPasswordForm
          numberOfRetry={numberOfRetry}
          backendError={backendError}
          formData={formData}
          errors={errors}
          formUpdate={formUpdate}
          triggerFieldValidation={triggerFieldValidation}
          triggerFormValidation={triggerFormValidation}
          isFetching={isFetching}
          returnToLogin={returnToLogin}
          displayErrorMessage={
            isTokenCaptchaError || isFailure || !isEmpty(backendError)
          }
          templateName={templateName}
        />
      </FormHandler>
    </div>
  );
};

export default ForgotPassword;
