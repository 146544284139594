import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';
import { ASSISTANT_PHONE_TYPE } from '../constants/phoneType';

import {
  getCurrentTemplate,
  getTemplateComponent,
  getPhoneAssistanceNumber,
  getMessageKey,
  isPartnerVanilla,
} from '../templateUtils';
import { Configs } from '../../configs';
import TEMPLATES from '../constants/template';

const Templates = Configs.TEMPLATES;

describe('getCurrentTemplate', () => {
  it('should return null with null as a template schema', () => {
    expect(getCurrentTemplate(null)).toBe(null);
  });

  it('should return null with an empty object as a template schema', () => {
    expect(getCurrentTemplate({})).toBe(null);
  });

  it('should return null with undefined as a template schema', () => {
    expect(getCurrentTemplate(undefined)).toBe(null);
  });

  it('should return SBIP2 with the default localhost', () => {
    const expectedResult = { selectedTemplate: TEMPLATES.SBIP2, partnerId: '' };
    expect(getCurrentTemplate(Templates)).toEqual(expectedResult);
  });
});

describe('getTemplateComponent', () => {
  it('should return null with undefined as inputs', () => {
    expect(getTemplateComponent(undefined, undefined)).toBe(null);
  });

  it('should return null with null as templateName', () => {
    expect(getTemplateComponent(null, null)).toBe(null);
  });

  it('should return null with undefined templates', () => {
    expect(getTemplateComponent(undefined, '')).toBe(null);
  });

  it('should return null with undefined as templateName', () => {
    expect(getTemplateComponent({ test: 'test' }, undefined)).toBe(null);
  });

  it('should return null with empty as inputs', () => {
    expect(getTemplateComponent({}, '')).toBe(null);
  });

  it('should return the matched key', () => {
    expect(getTemplateComponent({ test: 'test' }, 'test')).toBe('test');
  });

  it('should return null when the key is not found', () => {
    expect(getTemplateComponent({ test: 'test' }, 'bnc')).toBe(null);
  });
});

describe('getPhoneAssistanceNumber', () => {
  const testCases = [
    {
      message: 'should return SBIP2 assistance french phone number',
      templateName: 'SBIP2',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message: 'should return SBIP2 assistance english phone number',
      templateName: 'SBIP2',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message: 'should return SBIP2 fraud assistance english phone number',
      templateName: 'SBIP2',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'en',
    },
    {
      message: 'should return SBIP2 fraud assistance french phone number',
      templateName: 'SBIP2',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'fr',
    },
    {
      message: 'should return NATGO assistance french phone number',
      templateName: 'NATGO',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message: 'should return NATGO assistance english phone number',
      templateName: 'NATGO',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message: 'should return NATGO fraud assistance french phone number',
      templateName: 'NATGO',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'fr',
    },
    {
      message: 'should return NATGO fraud assistance english phone number',
      templateName: 'NATGO',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'en',
    },
    {
      message: 'should return REWARDS assistance french phone number',
      templateName: 'REWARDS',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message: 'should return REWARDS assistance english phone number',
      templateName: 'REWARDS',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message: 'should return REWARDS fraud assistance french phone number',
      templateName: 'REWARDS',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'fr',
    },
    {
      message: 'should return REWARDS fraud assistance english phone number',
      templateName: 'REWARDS',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'en',
    },
    {
      message: 'should return BAO assistance french phone number',
      templateName: 'BAO',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message: 'should return BAO assistance english phone number',
      templateName: 'BAO',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message: 'should return BAO fraud  assistance french phone number',
      templateName: 'BAO',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'fr',
    },
    {
      message: 'should return BAO fraud assistance english phone number',
      templateName: 'BAO',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'en',
    },
    {
      message: 'should return SECUREKEY assistance french phone number',
      templateName: 'SECURE_KEY',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message: 'should return SECUREKEY assistance english phone number',
      templateName: 'SECURE_KEY',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message: 'should return SECUREKEY assistance french phone number',
      templateName: 'SECURE_KEY',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'fr',
    },
    {
      message: 'should return SECUREKEY assistance english phone number',
      templateName: 'SECURE_KEY',
      partnerId: '',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'en',
    },
    {
      message: 'should return f55 white label assistance french phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'f55',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message:
        'should return f55 white label assistance french phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'f55',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'fr',
    },
    {
      message:
        'should return f55 fraud white label assistance french phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'f55',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'fr',
    },
    {
      message: 'should return f55 white label assistance english phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'f55',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message:
        'should return f55 white label assistance english phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'f55',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'en',
    },
    {
      message:
        'should return f55 white label fraud  assistance english phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'f55',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'en',
    },
    {
      message: 'should return gwl white label assistance french phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'gwl',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message:
        'should return gwl white label assistance french phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'gwl',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'fr',
    },
    {
      message:
        'should return gwl white label fraud assistance french phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'gwl',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'fr',
    },
    {
      message: 'should return gwl white label assistance english phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'gwl',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message:
        'should return gwl white label assistance english phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'gwl',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'en',
    },
    {
      message:
        'should return gwl white label fraud assistance english phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'gwl',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'en',
    },
    {
      message:
        'should return investorsgroup white label assistance french phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'investorsgroup',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message:
        'should return investorsgroup white label assistance french phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'investorsgroup',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'fr',
    },
    {
      message:
        'should return investorsgroup white label fraud assistance french phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'investorsgroup',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'fr',
    },
    {
      message:
        'should return investorsgroup white label assistance english phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'investorsgroup',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message:
        'should return investorsgroup white label assistance english phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'investorsgroup',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'en',
    },
    {
      message:
        'should return investorsgroup white label fraud assistance english phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'investorsgroup',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'en',
    },
    {
      message:
        'should return partnership white label assistance french phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'partnership',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message:
        'should return partnership white label assistance french phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'partnership',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'fr',
    },
    {
      message:
        'should return partnership white label fraud assistance french phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'partnership',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'fr',
    },
    {
      message:
        'should return partnership white label assistance english phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'partnership',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message:
        'should return partnership white label assistance english phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'partnership',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'en',
    },
    {
      message:
        'should return partnership white label fraud assistance english phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'partnership',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'en',
    },
    {
      message:
        'should return other partnership white label assistance french phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'anyother',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message:
        'should return other partnership white label assistance french phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'anyother',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'fr',
    },
    {
      message:
        'should return other partnership white label fraud assistance french phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'anyother',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'fr',
    },
    {
      message:
        'should return other partnership white label assistance english phone number',
      templateName: 'WHITE_LABEL',
      partnerId: 'anyother',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message:
        'should return other partnership white label assistance english phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'anyother',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'en',
    },
    {
      message:
        'should return other partnership white label fraud assistance english phone number toll free',
      templateName: 'WHITE_LABEL',
      partnerId: 'anyother',
      tollType: ASSISTANT_PHONE_TYPE.FRAUD_NUMBER,
      locale: 'en',
    },
    {
      message:
        'should return cdbn orion white label international assistance english phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'cdbn',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message:
        'should return fbngp orion white label international assistance english phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'fbngp',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message:
        'should return gp1859 orion white label international assistance english phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'fbngp',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'en',
    },
    {
      message:
        'should return cdbn orion white label international assistance french phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'cdbn',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message:
        'should return fbngp orion white label international assistance french phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'fbngp',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message:
        'should return gp1859 orion white label international assistance french phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'fbngp',
      tollType: ASSISTANT_PHONE_TYPE.INTERNATIONAL,
      locale: 'fr',
    },
    {
      message:
        'should return cdbn orion white label toll-free assistance english phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'cdbn',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'en',
    },
    {
      message:
        'should return fbngp orion white label toll-free assistance english phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'fbngp',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'en',
    },
    {
      message:
        'should return gp1859 orion white label toll-free assistance english phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'fbngp',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'en',
    },
    {
      message:
        'should return cdbn orion white label toll-free assistance french phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'cdbn',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'fr',
    },
    {
      message:
        'should return fbngp orion white label toll-free assistance french phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'fbngp',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'fr',
    },
    {
      message:
        'should return gp1859 orion white label toll-free assistance french phone number',
      templateName: 'ORION_WHITE_LABEL',
      partnerId: 'fbngp',
      tollType: ASSISTANT_PHONE_TYPE.TOLL_FREE,
      locale: 'fr',
    },
  ];

  testCases.forEach(testCase => {
    it(testCase.message, () => {
      let expectedTemplateName = testCase.templateName;
      let expectedWhiteLabelGroup = 'SBIP2';
      if (['cdbn', 'fbngp', 'gp1859'].includes(testCase.partnerId)) {
        expectedTemplateName = 'WHITE_LABEL';
        expectedWhiteLabelGroup = 'ORION';
      }
      // eslint-disable-next-line no-nested-ternary
      const expectedResult = isEmpty(testCase.partnerId)
        ? get(
            Configs,
            `${expectedTemplateName}.ASSISTANCE.PHONE_NUMBER.${
              testCase.tollType
            }.${toUpper(testCase.locale)}`,
          )
        : testCase.partnerId === 'anyother'
        ? get(
            Configs,
            `${expectedTemplateName}.SBIP2.config.partnership.ASSISTANCE.PHONE_NUMBER.${
              testCase.tollType
            }.${toUpper(testCase.locale)}`,
          )
        : get(
            Configs,
            `${expectedTemplateName}.${expectedWhiteLabelGroup}.config.${
              testCase.partnerId
            }.ASSISTANCE.PHONE_NUMBER.${testCase.tollType}.${toUpper(
              testCase.locale,
            )}`,
          );

      expect(
        getPhoneAssistanceNumber(
          testCase.templateName,
          testCase.partnerId,
          testCase.locale,
          testCase.tollType,
        ),
      ).toEqual(expectedResult);
    });
  });

  it('should return empty string on invalid templateName', () => {
    expect(getPhoneAssistanceNumber(undefined, null, 'fr', false)).toEqual('');
  });

  it('should return empty string on white label template but invalid partnerId', () => {
    expect(
      getPhoneAssistanceNumber('SBIP2_WHITE_LABEL', undefined, 'fr', false),
    ).toEqual('');
  });

  it('should return empty string on invalid locale', () => {
    expect(getPhoneAssistanceNumber('SBIP2', null, undefined, false)).toEqual(
      '',
    );
  });
});

describe('getMessageKey', () => {
  const key = 'test.key';
  const keyWL = 'test.key.wl';
  const keyBNE = 'test.key.bne';

  it('should return same key when null template is provided', () => {
    expect(getMessageKey(null, key)).toBe(key);
  });

  it('should return same key when empty string template is provided', () => {
    expect(getMessageKey('', key)).toBe(key);
  });

  it('should return same key when template not WL or BNE is provided', () => {
    expect(getMessageKey('anyTemplate', key)).toBe(key);
  });

  it('should return WL key when template WL is provided', () => {
    expect(getMessageKey(TEMPLATES.SBIP2_WHITE_LABEL, key)).toBe(keyWL);
  });

  it('should return BNE key when template BNE is provided', () => {
    expect(getMessageKey(TEMPLATES.BNE, key)).toBe(keyBNE);
  });
});

describe('isPartnerVanilla', () => {
  const testCases = [
    { partnerId: 'partnership', result: true },
    { partnerId: 'f55', result: false },
    { partnerId: 'gwl', result: false },
    { partnerId: 'investorsgroup', result: false },
    { partnerId: null, result: false },
    { partnerId: '', result: false },
    { partnerId: undefined, result: false },
  ];

  testCases.forEach(testCase =>
    it(`should return ${testCase.result} with ${testCase.partnerId} as a template schema`, () => {
      expect(isPartnerVanilla(testCase.partnerId)).toBe(testCase.result);
    }),
  );
});
