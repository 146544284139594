import {
  getAllConfigs,
  getConfigsState,
  getExpectedAppReturnTime,
  isLoginAlertConfigEnable,
} from '../selectors';

describe('getConfigsState', () => {
  it('should return configs if they are available', () => {
    const state = {
      configsServiceReducer: { configs: { test: 1 } },
    };
    expect(getConfigsState(state)).toEqual({ configs: { test: 1 } });
  });

  it('should return {} if configs are not available', () => {
    const state = {};
    expect(getConfigsState(state)).toEqual({});
  });

  it('should return module state if they are available', () => {
    const modulePath = 'front_end.sso';

    const state = {
      front_end: {
        sso: {
          configsServiceReducer: { configs: { test: 1 } },
        },
      },
    };
    expect(getConfigsState(state, modulePath)).toEqual({
      configs: { test: 1 },
    });
  });
});

describe('getAllConfigs', () => {
  it('should return configs if they are available', () => {
    const state = {
      configsServiceReducer: { configs: { test: 1 } },
    };
    expect(getAllConfigs(state)).toEqual({ test: 1 });
  });

  it('should return {} if configs are not available', () => {
    const state = {};
    expect(getAllConfigs(state)).toEqual({});
  });
});

describe('getExpectedAppReturnTime', () => {
  it('should return maintenance date if maintenance mode is active', () => {
    const state = {
      configsServiceReducer: {
        configs: {
          status: {
            global: {
              options: {
                unavailable_mode: {
                  active: true,
                  date: '2019-10-09T10:30:00.000Z',
                },
                maintenance_mode: {
                  active: false,
                  date: '2020-11-09T10:30:00.000Z',
                },
              },
            },
          },
        },
      },
    };
    expect(getExpectedAppReturnTime(state)).toEqual('2019-10-09T10:30:00.000Z');
  });

  it('should return unavailability date if unavailability mode is active', () => {
    const state = {
      configsServiceReducer: {
        configs: {
          status: {
            global: {
              options: {
                unavailable_mode: {
                  active: false,
                  date: '2019-10-09T10:30:00.000Z',
                },
                maintenance_mode: {
                  active: true,
                  date: '2020-11-09T10:30:00.000Z',
                },
              },
            },
          },
        },
      },
    };
    expect(getExpectedAppReturnTime(state)).toEqual('2020-11-09T10:30:00.000Z');
  });

  it('should return null if no mode is active', () => {
    const state = {
      configsServiceReducer: {
        configs: {
          status: {
            global: {
              options: {
                unavailable_mode: {
                  active: false,
                  date: '2019-10-09T10:30:00.000Z',
                },
                maintenance_mode: {
                  active: false,
                  date: '2020-11-09T10:30:00.000Z',
                },
              },
            },
          },
        },
      },
    };
    expect(getExpectedAppReturnTime(state)).toEqual(null);
  });
});

describe('isLoginAlertConfigEnable', () => {
  it('should return false if login alert is not enabled', () => {
    const state = {
      configsServiceReducer: {
        configs: {
          status: {
            login: {
              options: {
                alert: {
                  active: false,
                },
              },
            },
          },
        },
      },
    };
    expect(isLoginAlertConfigEnable(state)).toEqual(false);
  });

  it('should return true if login alert is enabled', () => {
    const state = {
      configsServiceReducer: {
        configs: {
          status: {
            login: {
              options: {
                alert: {
                  active: true,
                },
              },
            },
          },
        },
      },
    };
    expect(isLoginAlertConfigEnable(state)).toEqual(true);
  });

  it('should return false if login alert is not available', () => {
    const state = {};
    expect(isLoginAlertConfigEnable(state)).toEqual(false);
  });
});
