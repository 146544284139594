// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import actionTypes from './actionTypes';
import workers from './workers';

export const workersMapping = createWorkersMapping().takeLatest(
  actionTypes.TEMPLATE_SET_CURRENT_TEMPLATE,
  workers.watchSetCurrentTemplate,
);

export default createRootSagaFromWorkersMapping(workersMapping);
