// @flow
import omit from 'lodash/omit';

import * as actionTypes from './actionTypes';

import type { Action, MFAFormState } from './types';

export const initialState = (): MFAFormState => ({
  // export for test
  formId: '',
  isValid: false,
  errors: {},
  formData: {
    remember: false,
    code: '',
  },
  systemError: null,
  submitFailed: false,
  initDone: false,
  hideResend: true,
  factorTypes: [],
  selectedFactor: '',
  contact: '',
  executedAction: {},
});

export default (
  state: MFAFormState = initialState(),
  action: Action,
): MFAFormState => {
  switch (action.type) {
    case actionTypes.MULTI_FACTOR_AUTH_FORM_UPDATE:
      return {
        ...state,
        submitFailed: false,
        formData: { ...state.formData, [action.fieldName]: action.fieldValue },
      };
    case actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE:
      return {
        ...state,
        isValid: false,
        submitFailed: false,
        // $FlowFixMe
        errors: { ...state.errors, [action.fieldName]: action.errors },
        executedAction: { ...action },
      };

    case actionTypes.MULTI_FACTOR_AUTH_FORM_SUBMIT_REQUEST:
      return {
        ...state,
        submitFailed: false,
      };

    case actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_SUCCESS:
      return {
        ...state,
        isValid: action.isValid,
        submitFailed: false,
        errors: omit(state.errors, action.fieldName),
        executedAction: { ...action },
      };

    case actionTypes.MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE:
      return {
        ...state,
        submitFailed: true,
        systemError: action.code,
        errors: action.errors,
        executedAction: { ...action },
      };

    case actionTypes.MULTI_FACTOR_AUTH_FORM_INIT_REQUEST:
      return {
        ...state,
        errors: {},
        formId: action.formId,
      };

    case actionTypes.MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE:
      return {
        ...state,
        errors: {},
        executedAction: { ...action },
        formData: {
          ...state.formData,
          code: '',
        },
      };

    case actionTypes.MULTI_FACTOR_AUTH_FORM_INIT_DONE:
      return {
        ...state,
        ...action.payload,
        initDone: true,
        executedAction: { ...action },
      };

    case actionTypes.MULTI_FACTOR_AUTH_DISPLAY_RESEND:
      return {
        ...state,
        hideResend: false,
      };

    case actionTypes.MULTI_FACTOR_AUTH_HIDE_RESEND:
      return {
        ...state,
        hideResend: true,
      };

    case actionTypes.MULTI_FACTOR_AUTH_UPDATE_FACTORS:
      return {
        ...state,
        factorTypes: action.factorTypes,
        selectedFactor: action.selectedFactor,
        contact: action.contact,
        executedAction: { ...action },
      };

    case actionTypes.MULTI_FACTOR_AUTH_FORM_CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
      };
    default:
      return state;
  }
};
