import { combineReducers } from 'redux';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import { announceAlertMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';

import { formatShortDate } from 'bnc-utilities-js/date';
import service from '..';
import tokenCaptchaService from '../../tokenCaptchaService';
import tokenCaptchaServiceReducer from '../../tokenCaptchaService/reducer';
import templateServiceReducer from '../../templateService/reducer';
import saga from '../saga';
import reducer from '../reducer';
import * as reducers from '../../../globalRedux/reducers/constants';
import { initDoneSelector, isValidSelector } from '../selectors';
import {
  initFormDone,
  initForm,
  triggerFieldValidation,
  fieldValidationSuccess,
  fieldValidationFailure,
  triggerFormValidation,
  formValidationSuccess,
  formValidationFailure,
  resetForm,
  resetRecaptcha,
} from '../actions';
import { FORM_TYPES } from '../../../utils/constants/forms';
import { fieldValidation, formValidation } from '../formSchema';

import {
  reset,
  validateUserRequest,
} from '../../forgotPasswordService/actions';
import { focusOnFirstErrorField } from '../../formHandlerService/actions';
import { isTokenStillValid } from '../../../atoms/BncRecaptcha/utils';
import {
  FORGOT_PASSWORD_FIELDS,
  FORGOT_PASSWORD_PRODUCT_TYPE,
} from '../../../utils/forgotPasswordUtils/constants';
import { buildExpiryDate, buildPhoneNumber } from '../../../utils/formUtils';
import { CONTEXT_IDS } from '../../../utils/constants/forgotPassword';

const birthDate = new Date();
const formData = {
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL]: 'test@gmail.com',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE]: birthDate,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PRODUCT_TYPE]:
    FORGOT_PASSWORD_PRODUCT_TYPE.DEBIT,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.OPEN_CHOICES]: false,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA]: 'someValidToken',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER]: '344353',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE]: '12/24',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER]: '99333399',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.POSTAL_CODE]: 'H3G7C5',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT]: '120.56',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER]: '123 567-1092',
};

const rootReducer = combineReducers({
  [service.namespace]: reducer,
  [reducers.TEMPLATE_SERVICE_REDUCER]: templateServiceReducer,
  [tokenCaptchaService.namespace]: tokenCaptchaServiceReducer,
});

const formId = FORM_TYPES.FORGOT_PASSWORD_FORM;

test('formInit should dispatch initFormDone', async () => {
  const defaultValues = {
    email: '',
    recaptchaResponse: {},
  };

  const result = await expectSaga(saga)
    .withReducer(rootReducer)
    .put(initFormDone(defaultValues))
    .dispatch(initForm(formId, defaultValues))
    .run();

  expect(initDoneSelector(result.storeState)).toEqual(true);
});

describe('triggerFieldValidation', () => {
  test('should dispatch fieldValidationSuccess', async () => {
    const fieldName = 'email';
    const email = 'toto@test.ca';
    const formDataTest = {
      email,
    };
    const validationResult = {
      formValid: true,
      fieldValid: true,
    };

    const result = await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({
        [service.namespace]: { ...reducer, formData: formDataTest },
      })
      .provide([
        [
          matchers.call.fn(
            fieldValidation,
            formDataTest,
            triggerFieldValidation(fieldName),
          ),
          validationResult,
        ],
      ])
      .call(fieldValidation, formDataTest, triggerFieldValidation(fieldName))
      .put(fieldValidationSuccess(fieldName, validationResult.formValid))
      .dispatch(triggerFieldValidation(fieldName))
      .run();

    expect(isValidSelector(result.storeState)).toEqual(true);
  });

  test('should dispatch fieldValidationFailure', async () => {
    const fieldName = 'email';
    const email = 'toto@test';
    const formDataTest = {
      email,
    };
    const validationResult = {
      fieldValid: false,
      errorField: fieldName,
    };

    const result = await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({
        [service.namespace]: { ...reducer, formData: formDataTest },
      })
      .provide([
        [
          matchers.call.fn(
            fieldValidation,
            formDataTest,
            triggerFieldValidation(fieldName),
          ),
          validationResult,
        ],
      ])
      .call(fieldValidation, formDataTest, triggerFieldValidation(fieldName))
      .put(fieldValidationFailure(fieldName, validationResult.errorField))
      .put(announceAlertMessage('text.error.invalidIdentifier'))
      .dispatch(triggerFieldValidation(fieldName))
      .run();

    expect(isValidSelector(result.storeState)).toEqual(false);
  });
});

describe('triggerFormValidation', () => {
  test('should dispatch formValidationSuccess', async () => {
    const validationResult = {
      formValid: true,
    };
    const result = await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({ [service.namespace]: { ...reducer, formData, formId } })
      .provide([
        [matchers.call.fn(isTokenStillValid), true],
        [matchers.call.fn(formValidation, formData), validationResult],
      ])
      .call(formValidation, formData)
      .put(formValidationSuccess())
      .dispatch(triggerFormValidation())
      .run();

    expect(isValidSelector(result.storeState)).toEqual(true);
  });

  test('should dispatch reset Recaptcha if token is invalid', async () => {
    const validationResult = {
      formValid: true,
    };
    const result = await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({ [service.namespace]: { ...reducer, formData, formId } })
      .provide([
        [matchers.call.fn(isTokenStillValid), false],
        [matchers.call.fn(formValidation, formData), validationResult],
      ])
      .call(formValidation, formData)
      .put(resetRecaptcha())
      .dispatch(triggerFormValidation())
      .run();

    expect(isValidSelector(result.storeState)).toEqual(false);
  });

  test('should dispatch formValidationFailure', async () => {
    const email = 'toto@test';
    const formDataTest = {
      email,
    };
    const validationResult = {
      formValid: false,
      formattedErrors: { email: 'something' },
    };
    const result = await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({
        [service.namespace]: { ...reducer, formData: formDataTest, formId },
      })
      .provide([
        [matchers.call.fn(formValidation, formDataTest), validationResult],
      ])
      .call(formValidation, formDataTest)
      .put(
        focusOnFirstErrorField(formId, validationResult.formattedErrors, true),
      )
      .put(formValidationFailure(validationResult.formattedErrors))
      .dispatch(triggerFormValidation())
      .run();

    expect(isValidSelector(result.storeState)).toEqual(false);
  });
});

test('watchResetForm', async () => {
  await expectSaga(saga)
    .withReducer(rootReducer)
    .put(reset())
    .dispatch(resetForm())
    .run();
});

test('watchFormInit', async () => {
  await expectSaga(saga)
    .put(initFormDone())
    .dispatch(initForm())
    .run();
});

test('watchFormValidationSuccess', async () => {
  await expectSaga(saga)
    .withReducer(rootReducer)
    .withState({ [service.namespace]: { ...reducer, formData, formId } })
    .put(
      validateUserRequest({
        productType:
          formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PRODUCT_TYPE],
        contextId: CONTEXT_IDS.SBIP,
        tokenRecaptcha:
          formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA],
        dateOfBirth: formatShortDate(
          formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE],
        ),
        cardNumber:
          formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER],
        email: formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL],
        [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER]:
          formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER],
        [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT]:
          formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT],
        [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM
          .PHONE_NUMBER]: buildPhoneNumber(
          formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER],
        ),
        expiryDate: buildExpiryDate(
          formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE],
        ),
      }),
    )
    .dispatch(formValidationSuccess())
    .run();
});
