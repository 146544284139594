import { toTranslatedMessage } from '../helper';

describe('toTranslatedMessage', () => {
  it('should return json stringify message', () => {
    const text = 'text';
    const aria = { ariaRelevant: 'all' };
    expect(toTranslatedMessage(text, aria)).toEqual(
      '{"text":"text","aria":{"ariaRelevant":"all"}}',
    );
  });
});
