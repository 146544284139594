import { Action as ReduxAction } from '../../types/action';
import * as actionTypes from './actionTypes';
import Biometry from './biometry';

export type BiometryError = {|
  code: string,
  message: string,
  openInModal: boolean,
|};

export type BiometryState = {|
  biometry: Biometry,
  error: BiometryError,
  forceLogin: boolean,
  synchroError: boolean,
|};

export type BiometryInitializationAction = ReduxAction<
  typeof actionTypes.BIOMETRY_INITIALIZATION,
>;

export type BiometryInitializationSuccessAction = ReduxAction<
  typeof actionTypes.BIOMETRY_INITIALIZATION_SUCCESS,
> & {
  biometry: Biometry,
};

export type BiometryValidationEventAction = ReduxAction<
  typeof actionTypes.BIOMETRY_VALIDATION_RESULT,
> & {
  payload: {
    results: string,
    validationResults: string,
    retrievedCredentials: string,
    error?: {
      code: string,
      message: string,
    },
  },
};

export type BiometryValidationSuccessAction = ReduxAction<
  typeof actionTypes.BIOMETRY_VALIDATION_SUCCESS,
> & {
  credentials: string,
};

export type BiometryValidationFailedAction = ReduxAction<
  typeof actionTypes.BIOMETRY_VALIDATION_FAILED,
> & {
  error: BiometryError,
};

export type BiometryForceLogin = ReduxAction<
  typeof actionTypes.BIOMETRY_FORCE_LOGIN,
>;

export type BiometryCredentialsDeletionResult = ReduxAction<
  typeof actionTypes.BIOMETRY_CREDENTIALS_DELETION_RESULT,
>;

export type BiometryCredentialsDeletionSuccessAction = ReduxAction<
  typeof actionTypes.BIOMETRY_CREDENTIALS_DELETION_SUCCESS,
>;

export type BiometryCredentialsSynchronizationError = ReduxAction<
  typeof actionTypes.BIOMETRY_CREDENTIALS_SYNCHRONIZATION_ERROR,
>;

export type BiometryLaunchCredentialsSynchronization = ReduxAction<
  typeof actionTypes.BIOMETRY_LAUNCH_CREDENTIALS_SYNCHRONIZATION,
> & {
  username: string,
  password: string,
};

export type Action =
  | BiometryInitializationAction
  | BiometryValidationEventAction
  | BiometryValidationSuccessAction
  | BiometryForceLogin
  | BiometryCredentialsDeletionResult
  | BiometryCredentialsDeletionSuccessAction
  | BiometryCredentialsSynchronizationError
  | BiometryLaunchCredentialsSynchronization;
