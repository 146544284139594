// @flow
import { put, select } from 'redux-saga/effects';
import requestsManager from 'bnc-react-services/managers/RequestsManager';
import get from 'lodash/get';

import type { Saga } from 'redux-saga';
import type {
  MFATriggerValidationAction,
  MFAInitFormAction,
  MFATriggerSendCodeRequestAction,
  MFATriggerResendCodeRequestAction,
} from './types';

import { focusOnFirstErrorField } from '../formHandlerService/actions';
import {
  loginCodeValidationRequest,
  loginSendCodeRequest,
  loginResendCodeRequest,
  triggerHideResend,
  clearLoginErrorMessages,
  clearOtcResendMessage,
} from '../loginService/actions';

import * as actions from './actions';

import { fieldValidation, formValidation } from './formSchema';

import {
  MFA_FORM_SERVICE_REDUCER,
  LOGIN_FORM_SERVICE_REDUCER,
  LOGIN_SERVICE_REDUCER,
  TEMPLATE_SERVICE_REDUCER,
} from '../../globalRedux/reducers/constants';

import { MAX_IDENTITIES_SAVED } from '../../utils/constants/login';
import TEMPLATES from '../../utils/constants/template';
import { getMFAselected } from '../loginService/selectors';
import { getSelectedFactor } from './selectors';

export function* watchFormValidation(
  action: MFATriggerValidationAction,
): Saga<void> {
  const { formData } = (yield select())[MFA_FORM_SERVICE_REDUCER];
  const selectedFactor = yield select(
    action.isForgotPassword ? getSelectedFactor : getMFAselected,
  );
  const validationResult = yield requestsManager.call(
    fieldValidation,
    formData,
    action,
    selectedFactor,
  );

  if (validationResult.fieldValid) {
    return yield put(
      actions.validationSuccess(action.fieldName, validationResult.formValid),
    );
  }
  return yield put(
    actions.validationFailure(action.fieldName, validationResult.errorField),
  );
}

export function* watchSubmitCodeRequest(): Saga<void> {
  const { formData, formId } = (yield select())[MFA_FORM_SERVICE_REDUCER];
  const selectedFactor = yield select(getMFAselected);
  const validationResult = yield requestsManager.call(
    formValidation,
    formData,
    selectedFactor,
  );
  if (!validationResult.formValid) {
    yield put(
      focusOnFirstErrorField(formId, validationResult.formattedErrors, true),
    );
    yield put(
      actions.submitFailure(formData.code, validationResult.formattedErrors),
    );
    return;
  }
  yield put(actions.submitSuccess());
}

export function* watchFormInit(action: MFAInitFormAction): Saga<void> {
  // init form with more than initial state if needed
  yield put(
    actions.initFormDone({
      formData: { code: '', remember: false, rememberDevice: false },
    }),
  );
}

export function* watchTriggerSendCodeRequest(
  action: MFATriggerSendCodeRequestAction,
): Saga<void> {
  yield put(loginSendCodeRequest(action.factor));
}

export function* watchTriggerResendCodeRequest(
  action: MFATriggerResendCodeRequestAction,
): Saga<void> {
  // Hidding resend buttons
  yield put(triggerHideResend());
  yield put(
    loginResendCodeRequest(get(action, 'factor.factorType', action.factor)),
  );
  // empty and trigger focus on code input
  yield put(actions.resetMfaCode());
}

export function* watchCodeValidation(): Saga<void> {
  const { code, rememberDevice } = (yield select())[
    MFA_FORM_SERVICE_REDUCER
  ].formData;
  const { remember } = (yield select())[LOGIN_FORM_SERVICE_REDUCER].formData;
  const { identities } = (yield select())[LOGIN_SERVICE_REDUCER];
  const rememberMe = identities
    ? remember && identities.length < MAX_IDENTITIES_SAVED
    : remember;
  yield put(loginCodeValidationRequest(code, rememberMe, rememberDevice));
}

export function* watchMultiFactorAuthFormValidationFailure(): Saga<void> {
  const { templateName } = (yield select())[TEMPLATE_SERVICE_REDUCER];
  if (templateName === TEMPLATES.BNE) {
    yield put(clearLoginErrorMessages());
    yield put(clearOtcResendMessage());
  }
}
