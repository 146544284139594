import * as actionTypes from './actionTypes';

export function showModal(modalType) {
  return {
    type: actionTypes.SHOW_MODAL,
    modalType,
  };
}

export function hideModal(modalType) {
  return {
    type: actionTypes.HIDE_MODAL,
    modalType,
  };
}
