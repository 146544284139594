import testReducer from 'bnc-utilities-js/testUtils/reducer';
import forgotPasswordReducer from '../reducer';
import { FORGOT_PASSWORD_STEPS } from '../../../utils/forgotPasswordUtils/constants';
import {
  resendOTC,
  resendOTCFailure,
  reset,
  setPassword,
  setPasswordFail,
  setPasswordSuccess,
  showResendOTCSuccessMessage,
  validateUserFailure,
  validateUserRequest,
  validateUserSuccess,
  verifyOTC,
  verifyOTCFailure,
  verifyOTCSuccess,
} from '../actions';

const mockDate = new Date(1466424490000);
jest.spyOn(global, 'Date').mockImplementation(() => mockDate);

const factorsTestObjArr = [
  { factorType: 'factorType1', value: 'value1' },
  { factorType: 'factorType1', value: 'value1' },
  { factorType: 'factorType1', value: 'value1' },
  { factorType: 'factorType1', value: 'value1' },
];

testReducer(forgotPasswordReducer)
  .initialState()
  .expectDiff({
    isFetching: false,
    failure: false,
    sentAt: '',
    profileType: '',
    recoveryToken: '',
    exceptionType: '',
    identificationError: '',
    factors: [],
    backendError: {},
    currentStep: FORGOT_PASSWORD_STEPS.IDENTIFICATION,
    loginStarted: new Date(),
    loginEnded: null,
    analytics: {},
    showOTCSuccessMessage: false,
    numberOfRetry: 0,
    tokenRecaptcha: {},
    isForgotPasswordFlow: false,
  });

testReducer(forgotPasswordReducer)
  .withAnyState()
  .on(validateUserRequest())
  .expectDiff({
    isFetching: true,
    failure: false,
  });

testReducer(forgotPasswordReducer)
  .withAnyState()
  .on(validateUserSuccess(factorsTestObjArr))
  .expectDiff({
    factors: factorsTestObjArr,
    isFetching: false,
    failure: false,
    backendError: {},
    numberOfRetry: 0,
    currentStep: FORGOT_PASSWORD_STEPS.CODE_CONFIRMATION,
  });

testReducer(forgotPasswordReducer)
  .withAnyState()
  .on(reset())
  .expectDiff({
    isFetching: false,
    failure: false,
    identificationError: '',
    backendError: {},
    numberOfRetry: 0,
  });

testReducer(forgotPasswordReducer)
  .withState({
    isFetching: false,
  })
  .on(verifyOTC())
  .expectDiff({
    isFetching: true,
  });

testReducer(forgotPasswordReducer)
  .withAnyState()
  .on(verifyOTCSuccess())
  .expectDiff({
    isFetching: false,
    currentStep: FORGOT_PASSWORD_STEPS.PASSWORD_RESET,
  });

testReducer(forgotPasswordReducer)
  .withAnyState()
  .on(verifyOTCFailure())
  .expectDiff({
    isFetching: false,
  });

testReducer(forgotPasswordReducer)
  .withAnyState()
  .on(showResendOTCSuccessMessage(true))
  .expectDiff({
    isFetching: false,
    showOTCSuccessMessage: true,
  });

testReducer(forgotPasswordReducer)
  .withState({
    numberOfRetry: 2,
  })
  .on(validateUserFailure({}))
  .expectDiff({
    isFetching: false,
    failure: true,
    backendError: {},
    numberOfRetry: 3,
  });

testReducer(forgotPasswordReducer)
  .withState({
    isFetching: false,
  })
  .on(resendOTC('factorTest'))
  .expectDiff({
    isFetching: true,
  });

testReducer(forgotPasswordReducer)
  .withAnyState()
  .on(resendOTCFailure())
  .expectDiff({
    isFetching: false,
  });

testReducer(forgotPasswordReducer)
  .withState({
    isFetching: false,
  })
  .on(setPassword('somePassword'))
  .expectDiff({
    isFetching: true,
  });

testReducer(forgotPasswordReducer)
  .withState({
    isFetching: true,
  })
  .on(setPasswordFail())
  .expectDiff({
    isFetching: false,
  });

testReducer(forgotPasswordReducer)
  .withState({
    isFetching: true,
  })
  .on(setPasswordSuccess())
  .expectDiff({
    isFetching: false,
    currentStep: FORGOT_PASSWORD_STEPS.FINAL_CONFIRMATION,
  });
