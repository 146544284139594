import React from 'react';
import cx from 'classnames';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import getRewardsWLIcon from '../../../utils/rewardsWLUtils';

import './style.scss';

const propTypes = {
  intl: intlShape.isRequired,
  className: PropTypes.string,
  isVisible: PropTypes.boolean,
  locale: PropTypes.string.isRequired,
  hasTagBrand: PropTypes.boolean,
};

const defaultProps = {
  className: '',
  isVisible: true,
  hasTagBrand: true,
};

function RewardsBrand(props) {
  const { intl, className, isVisible, locale, hasTagBrand } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div className={cx('bnc-brand', locale, className)}>
      <div
        className={cx('bnc-brand__logo', {
          'bnc-brand__logo--big': !hasTagBrand,
        })}
      >
        <h1 className="bnc-logo-wl">
          {getRewardsWLIcon(intl, 'text.title.rewardsIcon')}
        </h1>
      </div>
    </div>
  );
}

RewardsBrand.propTypes = propTypes;
RewardsBrand.defaultProps = defaultProps;

export default RewardsBrand;
