// @flow
import {
  createRootSagaFromWorkersMapping,
  createWorkersMapping,
} from 'bnc-utilities-js/saga';
import * as actionTypes from './actionTypes';
import * as workers from './workers';

export const workersMapping = createWorkersMapping()
  .takeLatest(
    actionTypes.MULTI_FACTOR_AUTH_FORM_INIT_REQUEST,
    workers.watchFormInit,
  )
  .takeLatest(
    actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_REQUEST,
    workers.watchFormValidation,
  )
  .takeLatest(
    actionTypes.MULTI_FACTOR_AUTH_FORM_SUBMIT_REQUEST,
    workers.watchSubmitCodeRequest,
  )
  .takeLatest(
    actionTypes.MULTI_FACTOR_AUTH_FORM_TRIGGER_SEND_CODE_REQUEST,
    workers.watchTriggerSendCodeRequest,
  )
  .takeLatest(
    actionTypes.MULTI_FACTOR_AUTH_FORM_TRIGGER_RESEND_CODE_REQUEST,
    workers.watchTriggerResendCodeRequest,
  )
  .takeLatest(
    actionTypes.MULTI_FACTOR_AUTH_FORM_SUBMIT_SUCCESS,
    workers.watchCodeValidation,
  )
  .takeLatest(
    actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE,
    workers.watchMultiFactorAuthFormValidationFailure,
  );

export default createRootSagaFromWorkersMapping(workersMapping);
