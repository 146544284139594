import type { SDKError } from '../../../types';
import TEMPLATES from '../../constants/template';
import getRsaErrorMessage from '../errorMessageRSAUtils';

jest.mock('../../authErrorMessages', () => ({
  getAuthErrorMessageId: error => error.code,
}));

describe('getRsaErrorMessage', () => {
  const templateName = TEMPLATES.BNE;

  it(`should call intl.formatMessage for french when error code is 3106 and in rsa enroll`, () => {
    const locale = 'fr';
    const sessionManagerStatus = 'MFA_ENROLL';
    const sdkError: SDKError = {
      code: '3106',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      locale,
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getRsaErrorMessage(
      templateName,
      sdkError,
      ownPropsIntl,
      sessionManagerStatus,
    );

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3106.bne.rsa.enroll',
      },
      {
        code: '3106',
      },
    );
  });

  it(`should call intl.formatMessage for french when error code is 3106 without enroll`, () => {
    const locale = 'fr';
    const sessionManagerStatus = '';
    const sdkError: SDKError = {
      code: '3106',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      locale,
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getRsaErrorMessage(
      templateName,
      sdkError,
      ownPropsIntl,
      sessionManagerStatus,
    );

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3106.bne.rsa',
      },
      {
        code: '3106',
      },
    );
  });

  it(`should call intl.formatMessage for english when error code is 3106 and in rsa enroll`, () => {
    const locale = 'en';
    const sdkError: SDKError = {
      code: '3106',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      locale,
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getRsaErrorMessage(
      templateName,
      sdkError,
      ownPropsIntl,
      'MFA_ENROLL',
    );

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3106.bne.rsa.enroll',
      },
      {
        code: '3106',
      },
    );
  });

  it(`should call intl.formatMessage for english when error code is 3106 without enroll`, () => {
    const locale = 'fr';
    const sdkError: SDKError = {
      code: '3106',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      locale,
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getRsaErrorMessage(templateName, sdkError, ownPropsIntl, '');

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3106.bne.rsa',
      },
      {
        code: '3106',
      },
    );
  });

  it(`should call intl.formatMessage for french when error code is 3107 without enroll`, () => {
    const locale = 'fr';
    const sessionManagerStatus = '';
    const sdkError: SDKError = {
      code: '3107',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      locale,
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getRsaErrorMessage(
      templateName,
      sdkError,
      ownPropsIntl,
      sessionManagerStatus,
    );

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3107.bne.rsa',
      },
      {
        code: '3107',
      },
    );
  });

  it(`should call intl.formatMessage for english when error code is 3107 without enroll`, () => {
    const locale = 'en';
    const sdkError: SDKError = {
      code: '3107',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      locale,
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getRsaErrorMessage(templateName, sdkError, ownPropsIntl, '');

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3107.bne.rsa',
      },
      {
        code: '3107',
      },
    );
  });

  it(`should call intl.formatMessage for french when error code is 3102 and in rsa enroll`, () => {
    const locale = 'fr';
    const sessionManagerStatus = 'MFA_ENROLL';
    const sdkError: SDKError = {
      code: '3102',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      locale,
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getRsaErrorMessage(
      templateName,
      sdkError,
      ownPropsIntl,
      sessionManagerStatus,
    );

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3102.bne.rsa.enroll',
      },
      {
        code: '3102',
      },
    );
  });

  it(`should call intl.formatMessage for french when error code is 3102 without enroll`, () => {
    const locale = 'fr';
    const sessionManagerStatus = '';
    const sdkError: SDKError = {
      code: '3102',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      locale,
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getRsaErrorMessage(
      templateName,
      sdkError,
      ownPropsIntl,
      sessionManagerStatus,
    );

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3102.bne.rsa',
      },
      {
        code: '3102',
      },
    );
  });

  it(`should call intl.formatMessage for english when error code is 3102 and in rsa enroll`, () => {
    const locale = 'en';
    const sdkError: SDKError = {
      code: '3102',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      locale,
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getRsaErrorMessage(
      templateName,
      sdkError,
      ownPropsIntl,
      'MFA_ENROLL',
    );

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3102.bne.rsa.enroll',
      },
      {
        code: '3102',
      },
    );
  });

  it(`should call intl.formatMessage for english when error code is 3102 without enroll`, () => {
    const locale = 'en';
    const sdkError: SDKError = {
      code: '3102',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      locale,
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getRsaErrorMessage(templateName, sdkError, ownPropsIntl, '');

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3102.bne.rsa',
      },
      {
        code: '3102',
      },
    );
  });
});
