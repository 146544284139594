import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cleanPath from 'bnc-utilities-js/url/cleanPath';

import Login from '../../pages/Login';
import LoginBiometry from '../../pages/LoginBiometry';
import MultiFactorAuth from '../../pages/MultiFactorAuth';
import MultiFactorAuthRSA from '../../pages/MultiFactorAuthRSA';
import MultiFactorAuthChoice from '../../pages/MultiFactorAuthChoice';
import ResetPassword from '../../pages/ResetPassword';
import ResetPasswordException from '../../pages/ResetPasswordException';
import AccountLocked from '../../pages/AccountLocked';
import NotFound from '../../pages/NotFound';
import Unavailable from '../../pages/Unavailable';
import Unauthorized from '../../pages/Unauthorized';
import UnsupportedBrowser from '../../pages/UnsupportedBrowser';
import ParamsUnavailable from '../../pages/ParamsUnavailable';
import PasswordChangeSuccess from '../../pages/PasswordChangeSuccess';
import ForgotPassword from '../../pages/ForgotPassword';
import ForgotPasswordException from '../../pages/ForgotPasswordException';
import CompleteUnavailability from '../../pages/CompleteUnavailability';
import SessionExpired from '../../pages/SessionExpired';
import Forbidden from '../../pages/Forbidden';
import ForgotPasswordUnavailable from '../../pages/ForgotPasswordUnavailable';

import ROUTES from '../../utils/constants/routes';

import { Configs } from '../../configs';

export const buildPath = (routeEndpoint, selectedTemplate, partnerId) => {
  const whiteLabelKey = get(
    Configs,
    ['TEMPLATES', selectedTemplate, 'WHITE_LABEL_KEY'],
    '',
  );
  const hasWhiteLabels = !isEmpty(whiteLabelKey);
  const baseUrl = get(Configs, ['TEMPLATES', selectedTemplate, 'BASE_URL']);

  const prefixPath = hasWhiteLabels ? `${baseUrl}/${partnerId}` : baseUrl;

  const path = `${prefixPath}${routeEndpoint}`;
  // Clean the last '/' in case of the login route, except if there is only a '/' in the path
  return cleanPath(path);
};

export const availableRoutes = {
  LOGIN: {
    path: ROUTES.LOGIN,
    component: Login,
  },
  LOGIN_BIOMETRY: {
    path: ROUTES.LOGIN_BIOMETRY,
    component: LoginBiometry,
  },
  MFA: {
    path: ROUTES.MFA,
    component: MultiFactorAuth,
  },
  RSA: {
    path: ROUTES.RSA,
    component: MultiFactorAuthRSA,
  },
  MFA_CHOICE: {
    path: ROUTES.MFA_CHOICE,
    component: MultiFactorAuthChoice,
  },
  RESET_PASSWORD: {
    path: ROUTES.RESETPASSWORD,
    component: ResetPassword,
  },
  RESET_PASSWORD_EXCEPTION: {
    path: ROUTES.RESET_PASSWORD_EXCEPTION,
    component: ResetPasswordException,
  },
  ACCOUNT_LOCKED: {
    path: ROUTES.ACCOUNT_LOCKED,
    component: AccountLocked,
  },
  NOT_FOUND: {
    path: ROUTES.NOT_FOUND,
    component: NotFound,
  },
  UNAVAILABLE: {
    path: ROUTES.UNAVAILABLE,
    component: Unavailable,
  },
  UNAUTHORIZED: {
    path: ROUTES.UNAUTHORIZED,
    component: Unauthorized,
  },
  UNSUPPORTED_BROWSER: {
    path: ROUTES.UNSUPPORTED_BROWSER,
    component: UnsupportedBrowser,
  },
  PARAMS_UNAVAILABLE: {
    path: ROUTES.PARAMS_UNAVAILABLE,
    component: ParamsUnavailable,
  },
  PASSWORD_CHANGE_SUCCESS: {
    path: ROUTES.PASSWORD_CHANGE_SUCCESS,
    component: PasswordChangeSuccess,
  },
  FORGOT_PASSWORD: {
    path: ROUTES.FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  FORGOT_PASSWORD_EXCEPTION: {
    path: ROUTES.FORGOT_PASSWORD_EXCEPTION,
    component: ForgotPasswordException,
  },
  COMPLETE_UNAVAILABILITY: {
    path: ROUTES.COMPLETE_UNAVAILABILITY,
    component: CompleteUnavailability,
  },
  SESSION_EXPIRED_PAGE: {
    path: ROUTES.SESSION_EXPIRED,
    component: SessionExpired,
  },
  FORBIDDEN: {
    path: ROUTES.FORBIDDEN,
    component: Forbidden,
  },
  FORGOT_PASSWORD_UNAVAILABLE: {
    path: ROUTES.FORGOT_PASSWORD_UNAVAILABLE,
    component: ForgotPasswordUnavailable,
  },
};
