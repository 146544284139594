import get from 'lodash/get';

import {
  getTemplateState,
  getTemplateName,
  getPartnerName,
  getPartnerIdName,
} from '../selectors';

import TEMPLATES from '../../../utils/constants/template';

import {
  TEMPLATE_SERVICE_REDUCER,
  I18N_SERVICE_REDUCER,
} from '../../../globalRedux/reducers/constants';

import { Configs } from '../../../configs';

describe('getTemplateState', () => {
  it('should return state if they are available', () => {
    const state = {
      templateServiceReducer: { test: 1 },
    };
    expect(getTemplateState(state)).toEqual({ test: 1 });
  });

  it('should return {} if state are not available', () => {
    const state = {};
    expect(getTemplateState(state)).toEqual({});
  });

  it('should return module state if they are available', () => {
    const modulePath = 'front_end.sso';

    const state = {
      front_end: {
        sso: {
          templateServiceReducer: { test: 1 },
        },
      },
    };
    expect(getTemplateState(state, modulePath)).toEqual({ test: 1 });
  });
});

describe('getTemplateName', () => {
  it('should return templateName if it is available', () => {
    const state = {
      templateServiceReducer: { templateName: 'sbip2' },
    };
    expect(getTemplateName(state)).toEqual('sbip2');
  });

  it('should return empty if templateName is not available', () => {
    const state = {
      templateServiceReducer: {},
    };
    expect(getTemplateName(state)).toEqual('');
  });
});

describe('getPartnerIdName', () => {
  it('should return partnerId if it is available', () => {
    const state = {
      templateServiceReducer: { partnerId: 'id1' },
    };
    expect(getPartnerIdName(state)).toEqual('id1');
  });

  it('should return empty if partnerId is not available', () => {
    const state = {
      templateServiceReducer: {},
    };
    expect(getPartnerIdName(state)).toEqual('');
  });
});

describe('getPartnerName', () => {
  const localeData = ['fr', 'en'];
  const partnersWithName = ['f55', 'gwl', 'investorsgroup'];

  for (const templateName in TEMPLATES) {
    if (templateName === 'SBIP2_WHITE_LABEL') {
      localeData.forEach(locale => {
        partnersWithName.forEach(partnerId => {
          it(
            'should return null on '
              .concat(templateName)
              .concat(' context ')
              .concat('on partner ')
              .concat(partnerId)
              .concat(' ')
              .concat(locale.toUpperCase()),
            () => {
              const expectedName = get(
                Configs,
                `WHITE_LABEL.SBIP2.config.${partnerId}.NAME.${locale.toUpperCase()}`,
                null,
              );
              const state = {
                [TEMPLATE_SERVICE_REDUCER]: {
                  templateName,
                  partnerId,
                },
                [I18N_SERVICE_REDUCER]: {
                  locale,
                },
              };
              expect(getPartnerName(state)).toEqual(expectedName);
            },
          );
        });
        it(
          'should return null on '
            .concat(templateName)
            .concat(' context ')
            .concat('on partnership ')
            .concat(' ')
            .concat(locale.toUpperCase()),
          () => {
            const state = {
              [TEMPLATE_SERVICE_REDUCER]: {
                templateName,
                partnerId: 'partnership',
              },
              [I18N_SERVICE_REDUCER]: {
                locale,
              },
            };
            expect(getPartnerName(state)).toEqual(null);
          },
        );
      });
    } else {
      localeData.forEach(locale => {
        it(
          'should return null on '
            .concat(templateName)
            .concat(' context ')
            .concat(locale.toUpperCase()),
          () => {
            const state = {
              [TEMPLATE_SERVICE_REDUCER]: {
                templateName,
              },
              [I18N_SERVICE_REDUCER]: {
                locale,
              },
            };
            expect(getPartnerName(state)).toEqual(null);
          },
        );
      });
    }
  }
});
