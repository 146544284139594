import 'react-app-polyfill/stable';
import isomorphicFetch from 'isomorphic-fetch';
import { configure } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import { expectSaga } from 'redux-saga-test-plan';

require('jest-localstorage-mock');

configure({ adapter: new Adapter() });

jest.mock('@bnc-dsp/bnc-webchat', () => ({
  ChatBot: null,
}));

try {
  const testConfigs = require('../configs/test/front-end-configs.json');
  window.cfg = testConfigs;
} catch (e) {
  console.error('Impossible to find the required file');
}

process.on('unhandledRejection', err => {
  throw err;
});

jest.mock('@bnc-dsp/bnc-webchat', () => ({
  ChatBot: null,
}));

require('jest-localstorage-mock');
require('jest-canvas-mock');
require('react-scripts/config/env');

expectSaga.DEFAULT_TIMEOUT = 10; // set it to 10ms

global.fetch = isomorphicFetch;
