import { trackAction } from '@nbc-studio/analytics';
import get from 'lodash/get';
import {
  buildValidations,
  decodeHash,
  isNotValidated,
  taggingErrors,
  taggingFormErrorProps,
  validate,
} from '../helpers';
import { EVENT_NAMES } from '../../../../utils/taggingUtils/constants';
import {
  NEW_PASSWORD_FIELD_ERRORS,
  REGEX_NO_SPACES_START_END,
} from '../../../../utils/constants/forms';

jest.mock('@nbc-studio/analytics', () => ({
  trackAction: jest.fn(),
}));

const intlMock = {
  formatMessage: jest.fn(),
};

beforeEach(() => {
  jest.clearAllMocks();
});

describe('taggingErrors', () => {
  it('should not tag anything if errors object is empty', () => {
    const errors = {};
    taggingErrors(errors, 'toto');
    expect(trackAction).not.toBeCalled();
  });

  it('should tag error for newPassword field', () => {
    const errors = { newPassword: ['error'] };
    taggingErrors(errors, 'newPassword');
    expect(trackAction).toBeCalledWith(EVENT_NAMES.FORM_ERROR, {
      ...taggingFormErrorProps,
      formError: 'new password:error,',
    });
  });

  it('should tag error for newPasswordConfirmation field', () => {
    const errors = { newPasswordConfirmation: ['error'] };
    taggingErrors(errors, 'newPasswordConfirmation');
    expect(trackAction).toBeCalledWith(EVENT_NAMES.FORM_ERROR, {
      ...taggingFormErrorProps,
      formError: 'password confirmation:no_match',
    });
  });

  it('should tag error for currentPassword field', () => {
    const errors = { currentPassword: ['error'] };
    taggingErrors(errors, 'currentPassword');
    expect(trackAction).toBeCalledWith(EVENT_NAMES.FORM_ERROR, {
      ...taggingFormErrorProps,
      formError: 'current password:invalid',
    });
  });
});

describe('ResetPassword helper - isNotValidated', () => {
  it('should return false if errorType is not found in errors', () => {
    const result = isNotValidated(
      { error1: 'error1', error2: 'error2' },
      'error3',
    );
    expect(result).toEqual(false);
  });

  it('should return true if errorType is found in errors', () => {
    const result = isNotValidated(
      { error1: 'error1', error2: 'error2' },
      'error1',
    );
    expect(result).toEqual(true);
  });
});

const validationPolicyFunctions = {
  notEmpty: {
    validationFunction: values => !values.newPassword,
    validationErrorFunction: errors =>
      errors.push(NEW_PASSWORD_FIELD_ERRORS.notEmpty),
  },
  noSpacesStartEnd: {
    validationFunction: values =>
      !REGEX_NO_SPACES_START_END.test(values.newPassword) && values.newPassword,
    validationErrorFunction: errors =>
      errors.push(NEW_PASSWORD_FIELD_ERRORS.noSpacesStartEnd),
  },
  minLength: {
    validationFunction: (values, complexity) =>
      values.newPassword.length < get(complexity, 'minLength'),
    validationErrorFunction: errors =>
      errors.push(NEW_PASSWORD_FIELD_ERRORS.minLength),
  },
  minNumber: {
    validationFunction: (values, complexity) =>
      !new RegExp(`(?=(?:.*[0-9]){${get(complexity, 'minNumber')}})`).test(
        values.newPassword,
      ),
    validationErrorFunction: errors =>
      errors.push(NEW_PASSWORD_FIELD_ERRORS.minNumber),
  },
  minLowerCase: {
    validationFunction: (values, complexity) =>
      !new RegExp(`(?=(?:.*[a-z]){${get(complexity, 'minLowerCase')}})`).test(
        values.newPassword,
      ),
    validationErrorFunction: errors =>
      errors.push(NEW_PASSWORD_FIELD_ERRORS.minLowerCase),
  },
  minUpperCase: {
    validationFunction: (values, complexity) =>
      !new RegExp(`(?=(?:.*[A-Z]){${get(complexity, 'minUpperCase')}})`).test(
        values.newPassword,
      ),
    validationErrorFunction: errors =>
      errors.push(NEW_PASSWORD_FIELD_ERRORS.minUpperCase),
  },
  minSymbol: {
    validationFunction: (values, complexity) =>
      !new RegExp(
        `(?=(?:.*[^a-zA-Z0-9_ ]){${get(complexity, 'minSymbol')}})`,
      ).test(values.newPassword),
    validationErrorFunction: errors =>
      errors.push(NEW_PASSWORD_FIELD_ERRORS.minSymbol),
  },
};

const buildValidationsCases = [
  {
    complexity: {
      minLength: 8,
      minNumber: 1,
      minLowerCase: 1,
      minUpperCase: 1,
      minSymbol: 1,
    },
    expectedValues: {
      criteriaMap: {
        [NEW_PASSWORD_FIELD_ERRORS.minLength]: 'At least 8 characters',
        [NEW_PASSWORD_FIELD_ERRORS.minNumber]: 'At least one number',
        [NEW_PASSWORD_FIELD_ERRORS.minLowerCase]:
          'At least one lowercase letter',
        [NEW_PASSWORD_FIELD_ERRORS.minUpperCase]:
          'At least one uppercase letter',
        [NEW_PASSWORD_FIELD_ERRORS.minSymbol]: 'At least one symbol',
        [NEW_PASSWORD_FIELD_ERRORS.noSpacesStartEnd]:
          'No spaces at the beginning or end',
      },
      validationArray: [
        validationPolicyFunctions.noSpacesStartEnd,
        validationPolicyFunctions.minLength,
        validationPolicyFunctions.minNumber,
        validationPolicyFunctions.minLowerCase,
        validationPolicyFunctions.minUpperCase,
        validationPolicyFunctions.minSymbol,
      ],
    },
  },
  {
    complexity: {
      minLength: 8,
      minNumber: 1,
      minLowerCase: 1,
      minUpperCase: 0,
      minSymbol: 0,
    },
    expectedValues: {
      criteriaMap: {
        [NEW_PASSWORD_FIELD_ERRORS.minLength]: 'At least 8 characters',
        [NEW_PASSWORD_FIELD_ERRORS.minNumber]: 'At least one number',
        [NEW_PASSWORD_FIELD_ERRORS.minLowerCase]:
          'At least one lowercase letter',
        [NEW_PASSWORD_FIELD_ERRORS.noSpacesStartEnd]:
          'No spaces at the beginning or end',
      },
      validationArray: [
        validationPolicyFunctions.noSpacesStartEnd,
        validationPolicyFunctions.minLength,
        validationPolicyFunctions.minNumber,
        validationPolicyFunctions.minLowerCase,
      ],
    },
  },
  {
    complexity: {
      minLength: 0,
      minNumber: 0,
      minLowerCase: 0,
      minUpperCase: 0,
      minSymbol: 0,
    },
    expectedValues: {
      criteriaMap: {
        [NEW_PASSWORD_FIELD_ERRORS.noSpacesStartEnd]:
          'No spaces at the beginning or end',
      },
      validationArray: [validationPolicyFunctions.noSpacesStartEnd],
    },
  },
];

describe('ResetPassword helper - buildValidations', () => {
  buildValidationsCases.forEach(({ complexity, expectedValues }) => {
    it(`should return ${JSON.stringify(
      expectedValues,
    )} for the input ${JSON.stringify(complexity)}`, () => {
      const receivedValues = buildValidations(intlMock, complexity);
      const numberOfKeys = Object.keys(receivedValues.criteriaMap).length;
      expect(intlMock.formatMessage).toHaveBeenCalledTimes(numberOfKeys);
      expect(numberOfKeys).toEqual(
        Object.keys(expectedValues.criteriaMap).length,
      );
      expect(receivedValues.validationArray).toHaveLength(
        expectedValues.validationArray.length,
      );
    });
  });
});

const mockListOfErrors = {
  newPasswordConfirmation: 'text.error.requiredPasswordConfirmation',
  notContainEmailID: 'text.error.notContainEmailID',
  currentPassword: NEW_PASSWORD_FIELD_ERRORS.notEmpty,
};

const validateTestCases = [
  // Since password meets all criteria, it should return no errors
  {
    parameters: {
      values: {
        newPassword: 'Test1@password',
        newPasswordConfirmation: 'Test1@password',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'test12@gmail.com',
    },
    expectedErrors: {},
  },
  // Since password is empty, it should return a list of errors since all criteria have not been met and including
  // a notEmpty error
  {
    parameters: {
      values: {
        newPassword: '',
        newPasswordConfirmation: '',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'test12@gmail.com',
    },
    expectedErrors: {
      newPassword: [
        NEW_PASSWORD_FIELD_ERRORS.noSpacesStartEnd,
        NEW_PASSWORD_FIELD_ERRORS.minLength,
        NEW_PASSWORD_FIELD_ERRORS.minNumber,
        NEW_PASSWORD_FIELD_ERRORS.minLowerCase,
        NEW_PASSWORD_FIELD_ERRORS.minUpperCase,
        NEW_PASSWORD_FIELD_ERRORS.minSymbol,
      ],
      newPasswordEmpty: NEW_PASSWORD_FIELD_ERRORS.notEmpty,
    },
  },
  // Since password starts and ends with a space, it should return a noSpacesStartEnd error
  {
    parameters: {
      values: {
        newPassword: ' Test1@password ',
        newPasswordConfirmation: ' Test1@password ',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'test12@gmail.com',
    },
    expectedErrors: {
      newPassword: [NEW_PASSWORD_FIELD_ERRORS.noSpacesStartEnd],
    },
  },
  // Since password does not have a min length of 8, it should return a minLength error
  {
    parameters: {
      values: {
        newPassword: 'tE3!',
        newPasswordConfirmation: 'tE3!',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'test12@gmail.com',
    },
    expectedErrors: {
      newPassword: [NEW_PASSWORD_FIELD_ERRORS.minLength],
    },
  },
  // Since password does not have at least a number, it should return a minNumber error
  {
    parameters: {
      values: {
        newPassword: 'Test@password',
        newPasswordConfirmation: 'Test@password',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'test12@gmail.com',
    },
    expectedErrors: {
      newPassword: [NEW_PASSWORD_FIELD_ERRORS.minNumber],
    },
  },
  // Since password does not have at least a lowercase, it should return a minLowerCase error
  {
    parameters: {
      values: {
        newPassword: 'TEST1@PASSWORD',
        newPasswordConfirmation: 'TEST1@PASSWORD',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'test12@gmail.com',
    },
    expectedErrors: {
      newPassword: [NEW_PASSWORD_FIELD_ERRORS.minLowerCase],
    },
  },
  // Since password does not have at least an uppercase, it should return a minUpperCase error
  {
    parameters: {
      values: {
        newPassword: 'test1@password',
        newPasswordConfirmation: 'test1@password',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'test12@gmail.com',
    },
    expectedErrors: {
      newPassword: [NEW_PASSWORD_FIELD_ERRORS.minUpperCase],
    },
  },
  // Since password does not have at least a symbol, it should return a minSymbol error
  {
    parameters: {
      values: {
        newPassword: 'Test1password',
        newPasswordConfirmation: 'Test1password',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'test12@gmail.com',
    },
    expectedErrors: {
      newPassword: [NEW_PASSWORD_FIELD_ERRORS.minSymbol],
    },
  },
  // Since password and password confirmation are not the same, it should return a newPasswordConfirmation error
  {
    parameters: {
      values: {
        newPassword: 'Test1@password',
        newPasswordConfirmation: 'test1@password',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'test12@gmail.com',
    },
    expectedErrors: {
      newPasswordConfirmation: mockListOfErrors.newPasswordConfirmation,
    },
  },
  // Since password contains part of email ID, it should return a notContainEmailID error (not case-sensitive)
  {
    parameters: {
      values: {
        newPassword: 'TEST1@GMAIL.compassword',
        newPasswordConfirmation: 'TEST1@GMAIL.compassword',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'Test1@gmail.com',
    },
    expectedErrors: {
      notContainEmailID: mockListOfErrors.notContainEmailID,
    },
  },
  // Since password does not contain completely everything from the email ID (before @), it should not return any errors
  // (not case-sensitive)
  {
    parameters: {
      values: {
        newPassword: 'Test1@password',
        newPasswordConfirmation: 'Test1@password',
        currentPassword: '',
      },
      hasCurrentPassword: false,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'Test12@gmail.com',
    },
    expectedErrors: {},
  },
  // Since hasCurrentPassword is true, it should return a currentPassword error
  {
    parameters: {
      values: {
        newPassword: 'Test1@password',
        newPasswordConfirmation: 'Test1@password',
        currentPassword: '',
      },
      hasCurrentPassword: true,
      validationArray: buildValidations(
        intlMock,
        buildValidationsCases[0].complexity,
      ).validationArray,
      email: 'test12@gmail.com',
    },
    expectedErrors: {
      currentPassword: mockListOfErrors.currentPassword,
    },
  },
];

describe('ResetPassword helper - validate', () => {
  validateTestCases.forEach(({ parameters, expectedErrors }) => {
    it(`should return ${JSON.stringify(
      expectedErrors,
    )} for the input ${JSON.stringify(parameters)}`, () => {
      const result = validate(
        parameters.values,
        parameters.hasCurrentPassword,
        parameters.validationArray,
        parameters.email,
      );
      expect(result).toEqual(expectedErrors);
    });
  });
});

describe('ResetPassword helper - decodeHash', () => {
  it('should return decoded values for an input hash', () => {
    const result = decodeHash('dGVzdEVtYWls:dGVzdFBhc3N3b3Jk');
    expect(result.email).toEqual('testEmail');
    expect(result.password).toEqual('testPassword');
  });

  it('should return null values for an empty hash value', () => {
    const result = decodeHash('');
    expect(result.email).toEqual(null);
    expect(result.password).toEqual(null);
  });
});
