// @flow
import React from 'react';

import Sbip2ForgotPasswordTemplate from '../../templates/sbip2/ForgotPasswordTemplate';
import NatgoForgotPasswordTemplate from '../../templates/natgo/ForgotPasswordTemplate';
import WhiteLabelForgotPasswordTemplate from '../../templates/whiteLabel/ForgotPasswordTemplate';
import RewardsForgotPasswordTemplate from '../../templates/rewards/ForgotPasswordTemplate';
import BaoForgotPasswordTemplate from '../../templates/bao/ForgotPasswordTemplate';
import OrionWhiteLabelForgotPasswordTemplate from '../../templates/orionWhiteLabel/ForgotPasswordTemplate';
import BneForgotPasswordTemplate from '../../templates/bne/ForgotPasswordTemplate';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';

import './style.scss';

type Props = {
  templateName: string,
  currentStep: string,
};

const ForgotPassword = (props: Props) => {
  const { templateName, currentStep } = props;

  const forgotPasswordTemplates = {
    [TEMPLATES.SBIP2]: (
      <Sbip2ForgotPasswordTemplate currentStep={currentStep} />
    ),
    [TEMPLATES.OBE]: <Sbip2ForgotPasswordTemplate currentStep={currentStep} />,
    [TEMPLATES.SBIP2_WHITE_LABEL]: (
      <WhiteLabelForgotPasswordTemplate currentStep={currentStep} />
    ),
    [TEMPLATES.NATGO]: (
      <NatgoForgotPasswordTemplate currentStep={currentStep} />
    ),
    [TEMPLATES.REWARDS]: (
      <RewardsForgotPasswordTemplate currentStep={currentStep} />
    ),
    [TEMPLATES.BAO]: <BaoForgotPasswordTemplate currentStep={currentStep} />,
    [TEMPLATES.BNE]: <BneForgotPasswordTemplate currentStep={currentStep} />,
    [TEMPLATES.ORION_WHITE_LABEL]: (
      <OrionWhiteLabelForgotPasswordTemplate currentStep={currentStep} />
    ),
  };
  return getTemplateComponent(forgotPasswordTemplates, templateName);
};

export default ForgotPassword;
