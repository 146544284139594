import { createSelector } from 'reselect';
import { getPartnerIdName } from '../../../services/templateService/selectors';
import { isLoginAlertConfigEnable } from '../../../services/configsService/selectors';

const isLoginAlertEnable = createSelector(
  isLoginAlertConfigEnable,
  getPartnerIdName, // True mean not BNC client
  (isConfigEnable, isNotBNCClient) => isConfigEnable && !isNotBNCClient,
);

export default isLoginAlertEnable;
