import {
  injectMemcycoCssSafetyBelt,
  injectMemcycoMainScript,
  injectMemcycoSnippets,
} from '../memcycoUtils';
import { Configs as ConfigsModule } from '../../../configs';

xdescribe('injectMemcycoMainScript', () => {
  const mockConfig = {
    MCO_BNC_MAIN_SCRIPT_URL: 'https://commons.bnc.ca/main',
    MCO_BNC_IMAGE_SAFETY_BELT_URL: 'https://commons.bnc.ca/image',
    MCO_BNC_CSS_SAFETY_BELT_URL: 'https://commons.bnc.ca/css',
  };

  beforeEach(() => {
    ConfigsModule.MCO_BNC_MAIN_SCRIPT_URL =
      mockConfig.MCO_BNC_MAIN_SCRIPT_URL;
    ConfigsModule.MCO_BNC_IMAGE_SAFETY_BELT_URL =
      mockConfig.MCO_BNC_IMAGE_SAFETY_BELT_URL;
    ConfigsModule.MCO_BNC_CSS_SAFETY_BELT_URL =
      mockConfig.MCO_BNC_CSS_SAFETY_BELT_URL;
    document.head.innerHTML = '';
    jest.spyOn(console, 'error').mockImplementation();
  });

  afterEach(() => {
    jest.clearAllMocks();
  });

  xit('should inject the script into the document head when MCO_BNC_MAIN_SCRIPT_URL is defined', () => {
    injectMemcycoMainScript();

    const script = document.querySelector('script');
    expect(script).not.toBeNull();
    expect(script.src).toBe(mockConfig.MCO_BNC_MAIN_SCRIPT_URL);
    expect(script.defer).toBe(true);
    expect(script.referrerPolicy).toBe('strict-origin-when-cross-origin');
  });

  xit('should not inject script when MCO_BNC_MAIN_SCRIPT_URL is not defined', () => {
    ConfigsModule.MCO_BNC_MAIN_SCRIPT_URL = undefined;

    injectMemcycoMainScript();

    const script = document.querySelector('script');
    expect(script).toBeNull();
  });
});

xdescribe('injectMemcycoCssSafetyBelt', () => {
  beforeEach(() => {
    document.head.innerHTML = '';
    jest.spyOn(console, 'error').mockImplementation();
  });

  afterEach(() => {
    jest.clearAllMocks();
  });

  xit('should inject the link into the document head when MCO_BNC_CSS_SAFETY_BELT_URL is defined', () => {
    injectMemcycoCssSafetyBelt();

    const link = document.querySelector('link');
    expect(link).not.toBeNull();
    expect(link.href).toBe(ConfigsModule.MCO_BNC_CSS_SAFETY_BELT_URL);
    expect(link.rel).toBe('stylesheet');
  });

  xit('should not inject link when MCO_BNC_CSS_SAFETY_BELT_URL is not defined', () => {
    ConfigsModule.MCO_BNC_CSS_SAFETY_BELT_URL = undefined;

    injectMemcycoCssSafetyBelt();

    const link = document.querySelector('link');
    expect(link).toBeNull();
  });
});

xdescribe('injectMemcycoSnippets', () => {
  beforeEach(() => {
    document.head.innerHTML = '';
    document.body.innerHTML = '<div><div></div></div>';
    jest.spyOn(console, 'error').mockImplementation();
  });

  afterEach(() => {
    jest.clearAllMocks();
  });

  xit('should inject all elements when environment variables are defined', () => {
    injectMemcycoSnippets();

    const script = document.querySelector('script');
    const link = document.querySelector('link');

    expect(script).not.toBeNull();
    expect(script.src).toBe(ConfigsModule.MCO_BNC_MAIN_SCRIPT_URL);
    expect(script.defer).toBe(true);
    expect(script.referrerPolicy).toBe('strict-origin-when-cross-origin');

    expect(link).not.toBeNull();
    expect(link.href).toBe(ConfigsModule.MCO_BNC_CSS_SAFETY_BELT_URL);
    expect(link.rel).toBe('stylesheet');
  });

  xit('should not inject elements if environment variables are undefined', () => {
    ConfigsModule.MCO_BNC_MAIN_SCRIPT_URL = undefined;
    ConfigsModule.MCO_BNC_IMAGE_SAFETY_BELT_URL = undefined;
    ConfigsModule.MCO_BNC_CSS_SAFETY_BELT_URL = undefined;

    injectMemcycoSnippets();

    const script = document.querySelector('script');
    const link = document.querySelector('link');

    expect(script).toBeNull();
    expect(link).toBeNull();
  });
});
