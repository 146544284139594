import React from 'react';
import NotFoundError from '../../../organisms/common/NotFoundError';
import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';
import Header from '../../../organisms/whiteLabel/Header';

const NotFoundTemplate = () => (
  <FullScreenFooterTemplate
    className="error-background"
    header={<Header hasSwitchLang={false} />}
    main={<NotFoundError hasContactUsLink hasFindClosestBranchLink />}
  />
);

export default NotFoundTemplate;
