import React from 'react';

import { Icon } from '@nbc-design/icons';
import { IconButton } from '@nbc-design/button';
import { Text } from '@bnc-ui/text';
import type { OS } from '../../../types/browser';

import {
  anonymizeUsername,
  getIcon,
} from '../../../services/biometryService/utils';

import './style.scss';
import Biometry from '../../../services/biometryService/biometry';

type Props = {
  biometry: Biometry,
  os: OS,
  onDeletionClick: () => void,
};

const BiometryInput = (props: Props) => {
  const { biometry, os, onDeletionClick } = props;
  return (
    <div className="biometry-input">
      <Icon
        className="biometry-input__icon"
        name={getIcon(biometry, os)}
        size="large"
        title={getIcon(biometry, os)}
      />
      <Text size="large" className="biometry-input__user">
        {anonymizeUsername(biometry.user)}
      </Text>
      <IconButton
        onClick={onDeletionClick}
        icon={<Icon name="close" size="medium" title="close" />}
        theme="entreprise"
        className="biometry-input__revert"
      />
    </div>
  );
};

export default BiometryInput;
