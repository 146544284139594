import { testRootSagaMappings } from 'bnc-utilities-js/testUtils/saga';
import rootSaga, { workersMapping } from '..';

jest.mock('../workers', () => ({
  watchFormInit: jest.fn(),
  watchFormValidation: jest.fn(),
  watchSubmitCodeRequest: jest.fn(),
  watchTriggerCodeRequest: jest.fn(),
  watchCodeValidation: jest.fn(),
  watchLoginMfaSendCodeRequest: jest.fn(),
  watchTriggerSendCodeRequest: jest.fn(),
  watchTriggerResendCodeRequest: jest.fn(),
  watchMultiFactorAuthFormValidationFailure: jest.fn(),
}));

testRootSagaMappings(rootSaga, workersMapping);
