import type { IntlShape } from 'react-intl';
import { getAuthErrorMessageId } from '../authErrorMessages';
import { getMessageKey } from '../templateUtils';
import type { SDKError } from '../../types';
import I18N from '../constants/i18n';
import { ACCOUNT_BLOCKED_DELAY } from '../constants/bneConstants';

const getHourAsStringByLocale = (date: Date, intl: IntlShape) => {
  if (intl.locale.toLowerCase() === I18N.LANG.EN) {
    return intl
      .formatTime(date)
      .replace('AM', 'a.m.')
      .replace('PM', 'p.m.');
  }
  return intl.formatTime(date);
};
const getOtcErrorMessage = (
  templateName: String,
  sdkError: SDKError,
  date: Date,
  intl: IntlShape,
) => {
  const errorCode = getAuthErrorMessageId(sdkError);
  const messageKey = getMessageKey(
    templateName,
    `auth.error.${getAuthErrorMessageId(sdkError)}`,
  );
  // Blocked account error
  if (errorCode === '3107') {
    // Unlock date: current time + 15 minutes
    const unblockDate = new Date(date.getTime() + ACCOUNT_BLOCKED_DELAY);
    return intl.formatMessage(
      { id: messageKey },
      {
        hour: getHourAsStringByLocale(unblockDate, intl),
      },
    );
  }
  return intl.formatMessage({ id: messageKey }, { code: sdkError.code });
};
export default getOtcErrorMessage;
