import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import slice from 'lodash/slice';
import toLower from 'lodash/toLower';
import has from 'lodash/has';

import { FACTOR_TYPES } from 'bnc-react-services/managers/AuthManager/constants';
import { Configs } from '../../configs';
import TEMPLATES from '../constants/template';
import {
  FORGOT_PASSWORD_PRODUCT_TYPE,
  MAX_CHOICES_TO_SHOW,
  MAX_CHOICES_TO_SHOW_REDUCED_MODE,
} from './constants';

export const buildForgotPasswordChoices = (templateName, partnerId) => {
  const prefix =
    templateName === TEMPLATES.SBIP2_WHITE_LABEL
      ? `WHITE_LABEL.SBIP2.config.${partnerId}`
      : `TEMPLATES.${templateName}`;
  const pathChoices = `${prefix}.FORGOT_PASSWORD.CHOICES`;

  let choices = get(Configs, pathChoices, []);
  choices = filter(choices, choice =>
    has(FORGOT_PASSWORD_PRODUCT_TYPE, choice),
  );

  const suffix = partnerId ? `.${toLower(partnerId)}` : '';

  return choices.map(choice => ({
    labelMessageKey: `text.label.forgotPassword.radio.${choice.toLowerCase()}${suffix}`,
    id: choice.toLowerCase(),
    value: get(FORGOT_PASSWORD_PRODUCT_TYPE, choice),
  }));
};

export const getChoicesToShow = (choicesConfig, hasOpenChoices) => {
  if (hasOpenChoices || choicesConfig.length <= MAX_CHOICES_TO_SHOW) {
    return choicesConfig;
  }
  return slice(choicesConfig, 0, MAX_CHOICES_TO_SHOW_REDUCED_MODE);
};

export const buildFactors = (factors, selectedFactor) => {
  let preferredFactor = find(
    factors,
    factor => toLower(factor.factorType) === toLower(selectedFactor),
  );
  preferredFactor = isEmpty(preferredFactor)
    ? find(
        factors,
        factor => toLower(factor.factorType) === toLower(FACTOR_TYPES.EMAIL),
      )
    : preferredFactor;
  if (!isEmpty(preferredFactor)) {
    return {
      factors: factors.map(factor => toLower(factor.factorType)),
      selectedFactor: toLower(preferredFactor.factorType),
      contact: preferredFactor.value,
    };
  }
  return {};
};

export const getAgreementIdNumber = (
  cardNumber,
  accountNumber,
  productType,
) => {
  switch (productType) {
    case FORGOT_PASSWORD_PRODUCT_TYPE.DEBIT:
    case FORGOT_PASSWORD_PRODUCT_TYPE.CREDIT:
      return cardNumber;
    case FORGOT_PASSWORD_PRODUCT_TYPE.PROSPECT:
      return undefined;
    default:
      return accountNumber;
  }
};

export const hasExpiryDateForSheriff = productType =>
  productType === FORGOT_PASSWORD_PRODUCT_TYPE.DEBIT ||
  productType === FORGOT_PASSWORD_PRODUCT_TYPE.CREDIT;

export const hasOriginalAmountForSheriff = productType =>
  productType === FORGOT_PASSWORD_PRODUCT_TYPE.LOAN ||
  productType === FORGOT_PASSWORD_PRODUCT_TYPE.MORTGAGE;

export const hasPhoneForSheriff = productType =>
  productType === FORGOT_PASSWORD_PRODUCT_TYPE.PROSPECT ||
  productType === FORGOT_PASSWORD_PRODUCT_TYPE.INVESTMENT ||
  productType === FORGOT_PASSWORD_PRODUCT_TYPE.INVESTMENT_BROKERAGE;
