import {
  getSecureKeyFAQUrl,
  getSecureKeyAnnounceLogo,
  getWithoutSignInPath,
  BASE_RESOURCES_URL,
  getAppBaseUrl,
} from '../configUtils';
import { Configs } from '../../configs';
import TEMPLATES from '../constants/template';

describe('getExternalUrl', () => {
  test('normal behaviour', () => {
    for (const locale of ['EN', 'FR']) {
      const result = getSecureKeyFAQUrl(locale);
      const expectedResult = Configs.SECURE_KEY.EXTERNAL_URLS.FAQ[locale];
      expect(result).toEqual(expectedResult);
    }
  });

  test('missing key', () => {
    const result = getSecureKeyFAQUrl('ES');
    const expectedResult = null;
    expect(result).toEqual(expectedResult);
  });
});

describe('getSecureKeyAnnounceLogo', () => {
  test('extract secure key announce logo', () => {
    for (const locale of ['fr', 'en']) {
      const result = getSecureKeyAnnounceLogo(locale);
      const expectedResult = `${BASE_RESOURCES_URL}/securekey/${Configs.SECURE_KEY.ANNOUNCE_LOGO[locale]}`;
      expect(result).toEqual(expectedResult);
    }
  });
});

describe('getWithoutSignInPath', () => {
  test('normal behaviour for BAO project', () => {
    let result = getWithoutSignInPath('fr');
    expect(result).toEqual('http://localhost:3000/bao/welcome?lang=fr');

    result = getWithoutSignInPath('en');
    expect(result).toEqual('http://localhost:3000/bao/welcome?lang=en');
  });
});

describe('getAppBaseUrl', () => {
  test.each([
    [TEMPLATES.ORION_WHITE_LABEL, 'cdbn', 'fr', 'http://localhost:4200/cdbn'],
    [TEMPLATES.ORION_WHITE_LABEL, 'cdbn', 'en', 'http://localhost:4200/nbdb'],
    [TEMPLATES.ORION_WHITE_LABEL, 'fbngp', 'fr', 'http://localhost:4200/fbngp'],
    [TEMPLATES.ORION_WHITE_LABEL, 'fbngp', 'en', 'http://localhost:4200/nbfwm'],
    [TEMPLATES.BNE, '', 'fr', ''],
    [
      TEMPLATES.ORION_WHITE_LABEL,
      'gp1859',
      'fr',
      'http://localhost:4200/gp1859',
    ],
    [
      TEMPLATES.ORION_WHITE_LABEL,
      'gp1859',
      'en',
      'http://localhost:4200/pb1859',
    ],
  ])(
    'should build app base url %s, %s, %s',
    (template, partnerId, locale, expectedUrl) => {
      const url = getAppBaseUrl(template, partnerId, locale);
      expect(url).toEqual(expectedUrl);
    },
  );
});
