import { fork, all } from 'redux-saga/effects';
import concat from 'lodash/concat';

import globalErrorHandler from 'bnc-react-services/services/globalErrorService';

import bncAuthSaga from 'bnc-react-services/services/AuthService';
import configsSaga from '../../services/configsService';
import formHandlerSaga from '../../services/formHandlerService';
import i18nSaga from '../../services/i18nService';
import loginSaga from '../../services/loginService';
import loginRouteSaga from '../../services/loginRouteService';
import loginFormSaga from '../../services/loginFormService';
import multiFactorAuthFormSaga from '../../services/multiFactorAuthFormService';
import paramsUnavailableSaga from '../../services/paramsUnavailableService';
import resetPasswordSaga from '../../services/resetPasswordService';
import templateSaga from '../../services/templateService';
import userAgentSaga from '../../services/userAgentService';
import unsupportedBrowserSaga from '../../services/unsupportedBrowserService';
import forgotPasswordFormSaga from '../../services/forgotPasswordFormService/saga';
import forgotPasswordSaga from '../../services/forgotPasswordService/saga';
import tokenCaptchaSaga from '../../services/tokenCaptchaService/saga';
import redirectSaga from '../../services/redirectService/saga';
import biometrySaga from '../../services/biometryService';
import Biometry from '../../services/biometryService/biometry';

export default function createRootSagaFromServices(services) {
  const legacySagas = [
    bncAuthSaga,
    formHandlerSaga,
    i18nSaga,
    loginSaga,
    loginRouteSaga,
    resetPasswordSaga,
    loginRouteSaga,
    loginFormSaga,
    multiFactorAuthFormSaga,
    userAgentSaga,
    unsupportedBrowserSaga,
    paramsUnavailableSaga,
    forgotPasswordFormSaga,
    tokenCaptchaSaga,
    forgotPasswordSaga,
    templateSaga,
    configsSaga,
    redirectSaga,
  ];

  if (Biometry.available()) legacySagas.push(biometrySaga);

  const UMASagas = services.map(service => service.saga).filter(Boolean);
  const sagas = concat(legacySagas, UMASagas);

  return function* root() {
    yield all(sagas.map(saga => fork(globalErrorHandler, saga)));
  };
}
