// @flow
import * as actionTypes from './actionTypes';

import type { FormType } from '../../types/constants';
import type {
  MultiFactorAuthFormErrors,
  MultiFactorAuthFormData,
} from '../../types/forms/multiFactorAuthForm';

import type {
  MFAFormUpdateAction,
  MFATriggerValidationAction,
  MFAValidationSuccessAction,
  MFAValidationFailureAction,
  MFATriggerSubmitAction,
  MAFSubmitSuccessAction,
  MFASubmitFailureAction,
  MFAInitFormAction,
  MFAInitFormDoneAction,
  MFATriggerSendCodeRequestAction,
  MFATriggerResendCodeRequestAction,
  DisplayResendAction,
  HideResendAction,
} from './types';

export function formUpdate(
  fieldName: string,
  fieldValue: string,
): MFAFormUpdateAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_UPDATE,
    fieldName,
    fieldValue,
  };
}

export function triggerValidation(
  fieldName: string,
  isForgotPassword: boolean,
): MFATriggerValidationAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_REQUEST,
    fieldName,
    isForgotPassword,
  };
}

export function validationSuccess(
  fieldName: string,
  isValid: boolean,
): MFAValidationSuccessAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_SUCCESS,
    fieldName,
    isValid,
  };
}

export function validationFailure(
  fieldName: string,
  errors: MultiFactorAuthFormErrors,
): MFAValidationFailureAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE,
    fieldName,
    errors,
  };
}

export function triggerSubmit(): MFATriggerSubmitAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_SUBMIT_REQUEST,
  };
}

export function submitSuccess(): MAFSubmitSuccessAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_SUBMIT_SUCCESS,
  };
}

export function submitFailure(
  code: string,
  errors: MultiFactorAuthFormErrors,
): MFASubmitFailureAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE,
    code,
    errors,
  };
}

export function initForm(
  formId: FormType,
  payload: { formData: MultiFactorAuthFormData },
): MFAInitFormAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_INIT_REQUEST,
    payload,
    formId,
  };
}

export function initFormDone(payload: {
  formData: MultiFactorAuthFormData,
}): MFAInitFormDoneAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_INIT_DONE,
    payload,
  };
}

export function triggerSendCodeRequest(
  factor: string,
): MFATriggerSendCodeRequestAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_TRIGGER_SEND_CODE_REQUEST,
    factor,
  };
}
export function triggerResendCodeRequest(
  factor: string,
): MFATriggerResendCodeRequestAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_TRIGGER_RESEND_CODE_REQUEST,
    factor,
  };
}

export function displayResend(): DisplayResendAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_DISPLAY_RESEND,
  };
}

export function hideResend(): HideResendAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_HIDE_RESEND,
  };
}

export function resetMfaCode(): HideResendAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
  };
}

export function updateFactors(
  factors: Array<string>,
  selectedFactor: string,
  contact: string,
): MFATriggerResendCodeRequestAction {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_UPDATE_FACTORS,
    factorTypes: factors,
    selectedFactor,
    contact,
  };
}

export function clearMfaFormErrors() {
  return {
    type: actionTypes.MULTI_FACTOR_AUTH_FORM_CLEAR_ERRORS,
  };
}
