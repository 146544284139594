import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import merge from 'lodash/merge';

import legacyReducers from './reducers';

const combineReducerFromServices = services => {
  let UMAReducers = {};

  if (services) {
    UMAReducers = services.reduce(
      (reducers, service) =>
        service.reducer
          ? {
              ...reducers,
              [service.namespace]: service.reducer,
            }
          : reducers,
      {},
    );
  }
  const reducers = merge(UMAReducers, legacyReducers);

  return combineReducers({
    ...reducers,
    routing: routerReducer,
  });
};

export default combineReducerFromServices;
