import Biometry from '../biometry';
import {
  BIOMETRIC_VALIDATION,
  BIOMETRIC_CREDENTIALS_DELETION,
  BIOMETRIC_CREDENTIALS_UPDATE,
} from '../constants';
import {
  launchBiometryValidation,
  deleteBiometryCredentials,
  updateBiometricCredentials,
  goToRetail,
} from '../webviewActions';

import { intl } from '../__mocks__/intl';

const biometry = new Biometry('deviceID', 'fingerprint', 'dummy.user', '2.0.0');

window.ReactNativeWebView = {
  postMessage: jest.fn(),
};

beforeEach(() => {
  window.ReactNativeWebView.postMessage.mockClear();
});

describe('launchBiometryValidation', () => {
  it('should call postMessage with a valid message', () => {
    const message = JSON.stringify({
      actionType: BIOMETRIC_VALIDATION,
      payload: {
        userId: biometry.user,
        enterPasswordLabel: 'Enter my password',
        mainBiometricMessage: `Access the account linked to the user code dum***** using your fingerprint login.`,
      },
    });

    launchBiometryValidation(intl, biometry);
    expect(intl.formatMessage).toBeCalled();
    expect(window.ReactNativeWebView.postMessage).toBeCalled();
    expect(window.ReactNativeWebView.postMessage.mock.calls[0][0]).toEqual(
      message,
    );
  });
});

describe('deleteBiometryCredentials', () => {
  it('should call postMessage with a valid message', () => {
    const message = JSON.stringify({
      actionType: BIOMETRIC_CREDENTIALS_DELETION,
      payload: {
        userId: biometry.user,
      },
    });

    deleteBiometryCredentials(biometry);
    expect(window.ReactNativeWebView.postMessage).toBeCalled();
    expect(window.ReactNativeWebView.postMessage.mock.calls[0][0]).toEqual(
      message,
    );
  });
});

describe('updateBiometricCredentials', () => {
  it('should call postMessage with a valid message', () => {
    const password = 'password';
    const message = JSON.stringify({
      actionType: BIOMETRIC_CREDENTIALS_UPDATE,
      payload: {
        userId: biometry.user,
        password,
      },
    });

    updateBiometricCredentials(biometry.user, password);
    expect(window.ReactNativeWebView.postMessage).toBeCalled();
    expect(window.ReactNativeWebView.postMessage.mock.calls[0][0]).toEqual(
      message,
    );
  });
});

describe('goToRetail', () => {
  it('should call postMessage with a valid message', () => {
    const message = JSON.stringify({
      actionType: 'navigateToRetail',
    });

    goToRetail();
    expect(window.ReactNativeWebView.postMessage).toBeCalled();
    expect(window.ReactNativeWebView.postMessage.mock.calls[0][0]).toEqual(
      message,
    );
  });
});
