import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import type { State } from '../../../types/state';
import type { Dispatch } from '../../../types/store';

import { getLocale } from '../../../services/i18nService/selectors';

import { changeLocale } from '../../../services/i18nService/actions';

import SwitchLang from './switchLang';

function mapStateToProps(state: State) {
  return {
    locale: getLocale(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    changeLocale: (locale: string) => dispatch(changeLocale(locale)),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SwitchLang),
);
