// @flow
import React, { useEffect, useState } from 'react';
import type { IntlShape } from 'react-intl';
import has from 'lodash/has';
import get from 'lodash/get';
import cx from 'classnames';

import { Constants, Input, LinkButton } from 'bnc-react-components';
import { Lock } from '@nbc-design/icons/lib/web/Lock';
import { Button } from '@bnc-ui/button';

import { Text } from '@bnc-ui/text';

import LoginFormMessage from '../../../common/LoginFormMessage';

import type { SDKError } from '../../../../types';
import type {
  LoginFormData,
  LoginFormErrors,
} from '../../../../types/forms/loginForm';
import type { User } from '../../../../types/user';
import UserIdentity from '../../../common/UserIdentity';
import Greeting from '../../../common/Greeting';

import { LOGIN_FORM_STEPS } from '../../../../utils/constants/login';
import {
  PASSWORD_FIELD_MAX_LENGTH,
  WELCOME_ANNOUNCE_DELAY,
} from '../../../../utils/constants/forms';
import getGreetingsStringId from '../../../../utils/momentUtils';
import LoginTaggingWrapper from '../LoginTaggingWrapper/index';
import { getWelcomeMsgKey } from '../../../../utils/liveAnnouncer/helper';
import onEyeButtonClick from '../LoginTaggingWrapper/helper';

type Props = {
  className?: string,
  intl: IntlShape,
  formData: LoginFormData,
  onChange: (event: SyntheticInputEvent<any>) => void,
  onBlur: (event: SyntheticInputEvent<any>) => void,
  triggerSubmit: () => void,
  clearLoginErrorMessages: () => void,
  onClickForgetLink: (event: SyntheticInputEvent<any>) => void,
  isFetching: boolean,
  errors: LoginFormErrors,
  changeStepRequest: (nextStep: string, hasToFocus: boolean) => void,
  setFormInputs: (inputs: any) => void,
  handleKeyPress: (event: Event) => void,
  hasForgotPwd: boolean,
  identitySelected: User,
  hasLoginFailure: boolean,
  sdkError: SDKError,
  hasValidationError: boolean,
  isFirstRender: ?boolean,
  announceAlertMessage: (message: string) => void,
  hasToFocus: boolean,
  partnerName: string,
  informationalMessage: string,
  signInButtonTheme?: string,
};

const defaultProps = {
  className: '',
  signInButtonTheme: null,
};

const LoginPasswordPartialContent = (props: Props) => {
  const {
    className,
    onChange,
    onBlur,
    triggerSubmit,
    clearLoginErrorMessages,
    onClickForgetLink,
    formData,
    isFetching,
    errors,
    intl,
    changeStepRequest,
    setFormInputs,
    handleKeyPress,
    hasForgotPwd,
    identitySelected,
    hasLoginFailure,
    sdkError,
    hasValidationError,
    isFirstRender,
    announceAlertMessage,
    hasToFocus,
    partnerName,
    informationalMessage,
    signInButtonTheme,
  } = props;

  const [trackPassword, setTrackPassword] = useState(0);

  useEffect(() => {
    if (hasToFocus) {
      // Focus password input field when component is rendered
      const passwordIdentity = document.querySelector('#password');
      if (passwordIdentity) {
        passwordIdentity.focus();
      }
    }
    if (isFirstRender === null || isFirstRender) {
      setTimeout(
        () => announceAlertMessage(getWelcomeMsgKey(partnerName)),
        WELCOME_ANNOUNCE_DELAY,
      );
    }
  }, [hasToFocus, isFirstRender, partnerName, announceAlertMessage]);

  let loginFormInputs = {};

  const hasPasswordError = has(errors, 'password');
  const passwordErrorClassName = hasPasswordError
    ? 'error loginForm_flexitem'
    : '';

  const backToIdentitySelection = () => {
    changeStepRequest(LOGIN_FORM_STEPS.LOGIN_WITH_ID_STEP, true);
  };

  const onSubmit = () => {
    setFormInputs(loginFormInputs);
    triggerSubmit();
  };

  const renderChooseIdentityAriaMessage = selectedIdentity => {
    const greetingsId = getGreetingsStringId();
    const userName = get(selectedIdentity, 'firstName', '');
    return `${intl.formatMessage({
      id: greetingsId,
    })} ${userName}. ${intl.formatMessage({ id: 'text.aria.changeIdentity' })}`;
  };

  return (
    <div className={cx(className)} role="none" onKeyPress={handleKeyPress}>
      <h2 className="a11y-hide">
        {intl.formatMessage({ id: 'text.aria.greetingsContext' })}
      </h2>
      <Greeting
        tagName="span"
        className="title"
        userName={get(identitySelected, 'firstName', '')}
      />
      <LinkButton
        label={intl.formatMessage({ id: 'text.link.changeIdentity' })}
        onClick={backToIdentitySelection}
        ariaLabel={renderChooseIdentityAriaMessage(identitySelected)}
      />
      {informationalMessage && <Text>{informationalMessage}</Text>}
      <LoginFormMessage
        errors={errors}
        hasValidationError={hasValidationError}
        hasLoginFailure={hasLoginFailure}
        sdkError={sdkError}
        clearLoginErrorMessages={clearLoginErrorMessages}
      />
      <div className="combo combo--withIdentity">
        <div className="combo__entry identity">
          <Button onClick={backToIdentitySelection}>
            <UserIdentity user={identitySelected} isExpandable />
          </Button>
        </div>
        <div className="combo__entry combo__entry--password">
          <label className="a11y-hide" htmlFor="password">
            {intl.formatMessage({
              id: 'text.aria.password',
            })}
          </label>
          <LoginTaggingWrapper helpText="password" counting={trackPassword}>
            <Input
              type="password"
              name="password"
              id="password"
              onChange={onChange}
              onBlur={onBlur}
              className={cx(passwordErrorClassName, className)}
              ariaInvalid={hasPasswordError}
              maxLength={PASSWORD_FIELD_MAX_LENGTH}
              autoComplete={Constants.AUTOCOMPLETE.CURRENT_PASSWORD}
              value={formData.password}
              placeholder={intl.formatMessage({
                id: 'text.field.passwordPlaceholder',
              })}
              showPasswordIconAriaLabel={intl.formatMessage({
                id: 'text.aria.displayPassword',
              })}
              hidePasswordIconAriaLabel={intl.formatMessage({
                id: 'text.aria.hidePassword',
              })}
              innerRef={passwordInputRef => {
                loginFormInputs = {
                  ...loginFormInputs,
                  password: passwordInputRef,
                };
                onEyeButtonClick(passwordInputRef, setTrackPassword);
              }}
            />
          </LoginTaggingWrapper>
        </div>
      </div>

      {hasForgotPwd && (
        <LinkButton
          label={intl.formatMessage({ id: 'text.link.forgotPassword' })}
          onClick={onClickForgetLink}
        />
      )}

      <Button
        type="submit"
        appearance="primary"
        theme={signInButtonTheme}
        size="large"
        fluid
        icon={<Lock size="small" title="lock" />}
        disabled={isFetching}
        className="login__submit"
        onClick={isFetching ? () => {} : onSubmit}
      >
        {isFetching
          ? intl.formatMessage({ id: 'loginForm.button.loading' })
          : intl.formatMessage({ id: 'text.button.login' })}
      </Button>
    </div>
  );
};

LoginPasswordPartialContent.defaultProps = defaultProps;

export default LoginPasswordPartialContent;
