// @flow
import React from 'react';
import cx from 'classnames';
import type { IntlShape } from 'react-intl';

import {
  BASE_RESOURCES_LOGIN_IMAGE_URL,
  DEFAULT_LOGIN_IMAGE_NAME,
} from '../../../utils/constants/login';

import { getPartnerLoginImage } from '../../../utils/partnerUtils';

import './style.scss';

type Props = {
  intl: IntlShape,
  className?: string,
  isVisible?: boolean,
  partnerId: string,
};

const defaultProps = {
  className: '',
  isVisible: true,
};

function WhiteLabelMedia(props: Props) {
  const { intl, className, isVisible, partnerId } = props;

  if (!isVisible) {
    return null;
  }

  const loginPict = partnerId
    ? getPartnerLoginImage(intl.locale, partnerId)
    : `${BASE_RESOURCES_LOGIN_IMAGE_URL}/sbip/${DEFAULT_LOGIN_IMAGE_NAME}`;

  return (
    <div className={cx('white-label-media', className)}>
      <div
        className="image-login-background"
        style={{ backgroundImage: `url(${loginPict})` }}
      />
    </div>
  );
}

WhiteLabelMedia.defaultProps = defaultProps;

export default WhiteLabelMedia;
