// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { LOGIN_SERVICE_REDUCER } from '../../../globalRedux/reducers/constants';
import { getLoginFactorsHint } from '../../../services/loginService/selectors';
import { triggerResendCodeRequest } from '../../../services/multiFactorAuthFormService/actions';
import type { State } from '../../../types/state';
import type { Dispatch } from '../../../types/store';
import MultiFactorAuthFooter from './multiFactorAuthFooter';

function mapStateToProps(state: State) {
  return {
    isHideResend: state[LOGIN_SERVICE_REDUCER].hideResend,
    factors: getLoginFactorsHint(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    triggerCodeRequest: factor => dispatch(triggerResendCodeRequest(factor)),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuthFooter),
);
