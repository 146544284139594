// @flow
import { testRootSagaMappings } from 'bnc-utilities-js/testUtils/saga';
import rootSaga, { workersMapping } from '..';

jest.mock('../workers', () => ({
  callbackAnimateLoginFlowDone: jest.fn(),
  watchChangeRoute: jest.fn(),
  watchResetPasswordRequest: jest.fn(),
  watchBncAuthResetPasswordFailure: jest.fn(),
  watchBncAuthResetPasswordCompleted: jest.fn(),
}));

testRootSagaMappings(rootSaga, workersMapping);
