// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ResetPasswordException from './resetPasswordException';
import { returnToLogin } from '../../../services/loginService/actions';

export default injectIntl(
  connect(null, { returnToLogin: () => returnToLogin() })(
    ResetPasswordException,
  ),
);
