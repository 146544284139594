import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AppHead from './appHead';

import type { State } from '../../types/state';

import { getLocale } from '../../services/i18nService/selectors';
import { getTemplateName } from '../../services/templateService/selectors';

function mapStateToProps(state: State) {
  return {
    locale: getLocale(state),
    templateName: getTemplateName(state),
  };
}

const ConnectedAppHead = connect(mapStateToProps)(injectIntl(AppHead));

export default ConnectedAppHead;
