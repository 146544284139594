import { expectSaga } from 'redux-saga-test-plan';
import { trackAction } from '@nbc-studio/analytics';
import {
  createRootSagaFromWorkersMapping,
  createWorkersMapping,
} from 'bnc-utilities-js/saga';
import { FACTOR_TYPES } from 'bnc-react-services/managers/AuthManager/constants';

import { watchTrackResendCodeSucess } from '../taggingWorkers';
import { trackResendCode } from '../actions';
import { TRACK_RESEND_SUCCESS } from '../actionTypes';
import TEMPLATES from '../../../utils/constants/template';
import { TEMPLATE_SERVICE_REDUCER } from '../../../globalRedux/reducers/constants';

jest.mock('@nbc-studio/analytics', () => ({
  trackAction: jest.fn(),
}));

describe('LoginServive - taggingWorkers track resend code', () => {
  const saga = createRootSagaFromWorkersMapping(
    createWorkersMapping().takeLatest(
      [TRACK_RESEND_SUCCESS],
      watchTrackResendCodeSucess,
    ),
  );

  const stateTest = {
    [TEMPLATE_SERVICE_REDUCER]: {
      templateName: TEMPLATES.SBIP2,
    },
  };

  const testCases = [
    {
      actionType: TRACK_RESEND_SUCCESS,
      dispatchFn: () => trackResendCode(FACTOR_TYPES.CALL),
      comments: 'Resend code',
    },
  ];

  testCases.forEach(({ dispatchFn }) =>
    test(`it should track resend code `, async () => {
      trackAction.mockClear();
      await expectSaga(saga)
        .withState(stateTest)
        .dispatch(dispatchFn())
        .run();
    }),
  );
});
