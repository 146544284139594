// @flow
import React from 'react';
import { Link } from 'react-router';
import type { IntlShape } from 'react-intl';
import { FormattedHTMLMessage } from 'react-intl';
import MediaQuery from 'react-responsive';

import cx from 'classnames';
import { Heading } from '@bnc-ui/heading';
import { Text } from '@bnc-ui/text';
import { ArrowLeft } from '@nbc-design/icons/lib/web/ArrowLeft';

import {
  getSecureKeyFAQUrl,
  getSecureKeyLogo,
  getSecureKeyCancelUrl,
  getSecureKeyAnnounceLogo,
} from '../../../utils/configUtils';

import GLOBAL from '../../../utils/constants/global';

import './style.scss';

type Props = {
  intl: IntlShape,
};

function SecureKeyMedia(props: Props) {
  const { intl } = props;

  return (
    <div className={cx('secure-key-media')}>
      <div className="secure-key-media__header">
        <Link href={getSecureKeyCancelUrl()}>
          <ArrowLeft size="small" title="arrow-left" />
          {intl.formatMessage({ id: 'text.link.backToSecureKey' })}
        </Link>
      </div>

      <div className="secure-key-media__content">
        <div className="secure-key-media__announce">
          <img
            src={getSecureKeyAnnounceLogo(intl.locale)}
            alt="Secure Key to Government Sign-In logo"
          />
          <Heading
            type="h2"
            size={4}
            className="secure-key-media__announce--title"
            tabIndex={0}
          >
            {intl.formatMessage({ id: 'text.message.announce.title' })}
          </Heading>

          <Text tabIndex={0}>
            {intl.formatMessage({ id: 'text.message.announce.message' })}
          </Text>
        </div>

        <div className="secure-key-media__logo">
          <img
            src={getSecureKeyLogo(intl.locale)}
            alt="Government Sign-In logo"
          />
        </div>

        <div className="secure-key-media__text">
          <MediaQuery query={`(min-width: ${GLOBAL.BREAKPOINT.SMALL})`}>
            <Text tabIndex={0}>
              <FormattedHTMLMessage id="text.message.secureKeyInfo" />
            </Text>
            <Text tabIndex={0}>
              <FormattedHTMLMessage id="text.message.secureKeyComplement" />
            </Text>
          </MediaQuery>
        </div>

        <div className="secure-key-media__faq">
          <Link
            href={getSecureKeyFAQUrl(intl.locale)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage({ id: 'text.link.secureKeyFAQ' })}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SecureKeyMedia;
