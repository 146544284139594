// @flow
import * as actionTypes from './actionTypes';
import type { Action as ReduxAction } from '../../types/action';
import type { SDKError } from '../../types';

export type LoginRequestAction = ReduxAction<
  typeof actionTypes.LOGIN_REQUEST,
> & {
  identity: string,
  password: string,
  remember: boolean,
  requestMFA: boolean,
};

export type LoginSuccessAction = ReduxAction<typeof actionTypes.LOGIN_SUCCESS>;

export type LoginFailureAction = ReduxAction<
  typeof actionTypes.LOGIN_FAILURE,
> & {
  // TODO Change type after PR merge
  sdkError: any,
};

export type LogoutAction = ReduxAction<typeof actionTypes.LOGIN_LOGOUT> & {
  forced: boolean,
};

export type LoginMfaAction = ReduxAction<typeof actionTypes.LOGIN_MFA> & {
  contact: string,
  MFAfactors: Array<string>,
  MFAselected: string,
};

export type LoginFlowSuccessAction = ReduxAction<
  typeof actionTypes.LOGIN_FLOW_SUCCESS,
>;

export type LoginReturnToLoginAction = ReduxAction<
  typeof actionTypes.LOGIN_RETURN_TO_LOGIN,
>;

export type LoginSetReturnToSbipLoginFlagAction = ReduxAction<
  typeof actionTypes.LOGIN_SET_RETURN_SBIP_LOGIN_FLAG,
>;

export type LoginTriggerHideResendAction = ReduxAction<
  typeof actionTypes.LOGIN_MFA_TRIGGER_HIDE_RESEND,
>;

export type LoginSendCodeRequestAction = ReduxAction<
  typeof actionTypes.LOGIN_MFA_SEND_CODE_REQUEST,
> & {
  MFAselected: string,
};

export type LoginResendCodeRequestAction = ReduxAction<
  typeof actionTypes.LOGIN_MFA_RESEND_CODE_REQUEST,
> & {
  MFAselected: string,
};

export type LoginCodeValidationRequestAction = ReduxAction<
  typeof actionTypes.LOGIN_MFA_CODE_VALIDATION_REQUEST,
> & {
  code: string,
  remember: boolean,
  rememberDevice: boolean,
};

export type DisplayResendAction = ReduxAction<
  typeof actionTypes.LOGIN_MFA_DISPLAY_RESEND,
>;

export type IdentityWithDeviceTokenAction = ReduxAction<
  typeof actionTypes.PUT_IDENTITY_WITH_DEVICE_TOKEN,
> & {
  login: string,
  deviceToken: string,
};

export type HideResendAction = ReduxAction<
  typeof actionTypes.LOGIN_MFA_HIDE_RESEND,
>;

export type LoginLoadIdentitySuccessAction = ReduxAction<
  typeof actionTypes.LOGIN_LOAD_IDENTITY_SUCCESS,
> & {
  cookieValue: Array<string>,
};

export type LoginClearLoginErrorMessagesAction = ReduxAction<
  typeof actionTypes.LOGIN_CLEAR_ERROR_MESSAGES,
>;

export type LoginRemoveIdentityAction = ReduxAction<
  typeof actionTypes.LOGIN_REMOVE_IDENTITY,
> & {
  identity: string,
};

export type LoginUpdateContactAction = ReduxAction<
  typeof actionTypes.LOGIN_MFA_UPDATE_CONTACT,
> & {
  MFAselected: string,
  contact: string,
};

export type RsaReturnLoginFlag = ReduxAction<
  typeof actionTypes.RSA_RETURN_LOGIN_FLAG,
> & {
  rsaReturnLoginFlag: boolean,
};
export type RememberDeviceBneAction = ReduxAction<
  typeof actionTypes.REMEMBER_DEVICE_BNE,
> & {
  rememberDeviceBne: boolean,
};

export type LoginState = {|
  // TODO: TO BE IMPORTED FROM LOGINFORMSERVICE TYPES
  isFetching: boolean,
  failure: boolean,
  sdkError: SDKError | {},
  identities: Array<string> | {},
  identityRemembered: string | null,
  MFAselected: string,
  MFAfactors: Array<string>,
  transactionStatus: string,
  contact: string,
  hideResend: boolean,
  returnToSbip: boolean,
  rsaReturnLoginFlag: boolean,
  rememberDeviceBne: boolean,
|};

export type Action =
  | LoginFailureAction
  | LoginFlowSuccessAction
  | LoginMfaAction
  | LoginRequestAction
  | LoginReturnToLoginAction
  | LoginSuccessAction
  | LogoutAction
  | LoginTriggerHideResendAction
  | LoginCodeValidationRequestAction
  | LoginSendCodeRequestAction
  | LoginResendCodeRequestAction
  | DisplayResendAction
  | HideResendAction
  | LoginLoadIdentitySuccessAction
  | LoginClearLoginErrorMessagesAction
  | LoginRemoveIdentityAction
  | LoginUpdateContactAction
  | IdentityWithDeviceTokenAction
  | RsaReturnLoginFlag
  | RememberDeviceBneAction;
