// @flow
import React from 'react';
import cx from 'classnames';

import type { Node } from 'react';

import WhiteLabelBrand from '../../../molecules/whiteLabel/WhiteLabelBrand';
import SwitchLang from '../../../molecules/common/SwitchLang';

type Props = {
  className?: string,
  hasLogo?: boolean,
  hasTitle?: boolean,
  title?: Node | string,
  hasSwitchLang?: boolean,
};

const defaultProps = {
  className: '',
  hasLogo: true,
  hasTitle: true,
  title: '',
  hasSwitchLang: true,
};

function Header(props: Props) {
  const { className, hasLogo, hasTitle, title, hasSwitchLang } = props;

  return (
    <div className={cx('header', className)}>
      <SwitchLang isVisible={hasSwitchLang} />
      <WhiteLabelBrand isVisible={hasLogo} />
      {hasTitle && !!title && <div>{title}</div>}
    </div>
  );
}

Header.defaultProps = defaultProps;

export default Header;
