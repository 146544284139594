// @flow
import React, { useEffect } from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import SecureKeyUnavailableTemplate from '../../templates/secureKey/ErrorTemplate';
import RewardsWLUnavailableTemplate from '../../templates/rewardsWhiteLabel/ErrorTemplate';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';
import urlUtils from '../../utils/urlUtils';

const propTypes = {
  intl: intlShape,
  templateName: PropTypes.string,
};

const Unavailable = props => {
  useEffect(() => {
    urlUtils.listenPopEventAndRedirectToBaseUrl();
  });

  const { intl, templateName } = props;
  const title = intl.formatMessage({ id: 'text.message.error.title' });
  const text = intl.formatMessage({ id: 'text.message.unavailableSK' });

  const unavailableTemplates = {
    [TEMPLATES.SECURE_KEY]: (
      <SecureKeyUnavailableTemplate errorTitle={title} errorText={text} />
    ),
    [TEMPLATES.REWARDS_WHITE_LABEL]: (
      <RewardsWLUnavailableTemplate errorTitle={title} errorText={text} />
    ),
  };

  return getTemplateComponent(unavailableTemplates, templateName);
};

Unavailable.propTypes = propTypes;

export default Unavailable;
