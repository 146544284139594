export const intl = {
  formatMessage: jest.fn(({ id }, params) => {
    if (id === 'mobile.biometric.native.password.button') {
      return 'Enter my password';
    }

    return `Access the account linked to the user code ${params.username} using your fingerprint login.`;
  }),
};

export default intl;
