import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import LoginAlert from './LoginAlert';
import isLoginAlertEnable from './selectors';

const mapStateToProps = state => ({
  isLoginAlertEnable: isLoginAlertEnable(state),
});

export default injectIntl(connect(mapStateToProps)(LoginAlert));
