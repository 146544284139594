// @flow
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '@bnc-ui/button';
import { Link } from '@bnc-ui/link';
import { ArrowLeft } from '@nbc-design/icons/lib/web/ArrowLeft';

import './style.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isButton: PropTypes.boolean,
  className: PropTypes.string,
};

type Props = {
  onClick: () => void,
  label: string,
  isButton: boolean,
  className: string,
};

const defaultProps = {
  isButton: false,
  className: '',
};

function BackLinkButton(props: Props) {
  const { onClick, label, isButton, className } = props;

  const linkComponent = (
    <Link
      icon={<ArrowLeft size="small" title="arrow-left" />}
      className={cx('back-link-button', className)}
      onClick={onClick}
      tabIndex="0"
      type="button"
    >
      {label}
    </Link>
  );

  const buttonComponent = (
    <Button appearance="primary" fluid onClick={onClick} size="large">
      {label}
    </Button>
  );

  return isButton ? buttonComponent : linkComponent;
}

BackLinkButton.defaultProps = defaultProps;
BackLinkButton.propTypes = propTypes;

export default BackLinkButton;
