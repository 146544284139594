import React from 'react';
import Right2ColumnsTemplate from '../models/Right2ColumnsTemplate';
import HeaderEnterprise from '../../../organisms/common/HeaderEnterprise';
import LoginFooter from '../../../organisms/bne/LoginFooter';

import CommonLoginBiometry from '../../../organisms/bne/LoginBiometry';

const LoginBiometry = () => (
  <Right2ColumnsTemplate
    className="container--enterprise"
    header={<HeaderEnterprise />}
    main={<CommonLoginBiometry />}
    footer={<LoginFooter />}
  />
);

export default LoginBiometry;
