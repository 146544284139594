// @flow
import React from 'react';

import FormHandler from '../../../molecules/forms/FormHandler';
import LoginForm from '../../../molecules/forms/LoginForm';

import { FORM_TYPES } from '../../../utils/constants/forms';

type Props = {
  className?: string,
  hasForgotPwd?: boolean,
};

const defaultProps = {
  className: '',
  hasForgotPwd: true,
};

function Login(props: Props) {
  const { className, hasForgotPwd } = props;

  return (
    <div id="loginForm" className="connect-form">
      <FormHandler
        // $FlowFixMe
        formName={FORM_TYPES.LOGIN_FORM}
      >
        <LoginForm className={className} hasForgotPwd={hasForgotPwd} />
      </FormHandler>
    </div>
  );
}

Login.defaultProps = defaultProps;

export default Login;
