// @flow
const HTTP = {
  METHODS: {
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE',
    POST: 'POST',
  },

  QUERY_PARAMS: {
    IDENTITY: 'identity=',
  },

  USER_SCREEN_RESOLUTION_HEADER_NAME: 'X-User-Screen-Resolution',
  X_DISABLE_LEGACY: 'X-Disable-Legacy',
};

export default HTTP;
