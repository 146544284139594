import React from 'react';
import { intlShape } from 'react-intl';

import { Text } from '@bnc-ui/text';
import { Combo } from '@bnc-ui/combo';

import {
  getFactorIcon,
  getFactorTarget,
  orderFactors,
} from '../../../utils/factorUtils';

import './style.scss';
import { FACTORS_TYPES } from '../../../utils/taggingUtils/constants';

type Props = {
  intl: intlShape,
  triggerCodeRequest: (factor: *) => void,
  factors: Array<*>,
};

const SendMFACode = (props: Props) => {
  const { intl, triggerCodeRequest, factors } = props;

  let factorItems = [];

  const displayBneNoteIfCallFactor = factor => {
    if (factor.factorType === FACTORS_TYPES.CALL) {
      return (
        <Text size="xSmall" className="factor-info__note">
          {intl.formatMessage({
            id: 'multiFactorAuthChoice.button.method.call.note.bne',
          })}
        </Text>
      );
    }
    return '';
  };

  if (factors != null) {
    factorItems = orderFactors(factors).map(factor => ({
      action: () => {
        triggerCodeRequest(factor);
      },
      children: (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className="factor-info"
          id={`send-mfa-code__choice-${factor.factorType}`}
        >
          <div className="factor-info__icon">
            {getFactorIcon(factor.factorType)}
          </div>
          <div>
            <Text size="paragraph" className="factor-info__label">
              {intl.formatMessage({
                id: `multiFactorAuthChoiceSend.button.method.${factor.factorType}`,
              })}
            </Text>
            <Text size="small" className="factor-info__sublabel">
              {getFactorTarget(factor, intl.locale)}
            </Text>
            {displayBneNoteIfCallFactor(factor)}
          </div>
        </div>
      ),
      disabled: false,
      icon: true,
      key: factor.factorType,
    }));
  }

  return (
    <Combo
      id="send-mfa-code__id"
      type="button"
      items={factorItems}
      className="send-mfa-code"
    />
  );
};

export default SendMFACode;
