// @flow
import type { Action as ReduxAction } from '../../types/action';
import * as actionTypes from './actionTypes';

export type RedirectToPageAction = ReduxAction<
  typeof actionTypes.REDIRECT_TO_PAGE,
> & {
  pageUrl: string,
};

export type Action = RedirectToPageAction;
