import React from 'react';
import { Tagging, TrackingTimer, TrackState } from '@nbc-studio/analytics';
import PropTypes from 'prop-types';
import {
  BNE_EVENT_IDS,
  BNE_EVENT_NAMES,
  BNE_EVENT_SOURCE,
  BNE_EVENT_SOURCE_DETAILS,
  BNE_FORM_IDS,
  BNE_LOB,
  BNE_UCS_IDS,
  BNE_UCS_STATE,
} from '../../../../../../utils/taggingUtils/bneTaggingConstants';

const propTypes = {
  partnerIdName: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
};

const defaultProps = {
  partnerIdName: '',
};

const LoginTaggingWrapper = ({
  children,
  partnerIdName,
  isLoading,
  locale,
}) => {
  const eventSource = partnerIdName || BNE_EVENT_SOURCE.BNE;

  return (
    <Tagging
      ucsId={BNE_UCS_IDS.LOGIN_0001}
      ucsState={BNE_UCS_STATE.NON_PV}
      eventName={BNE_EVENT_NAMES.PAGE_READY}
      eventId={BNE_EVENT_IDS.PL}
      lob={BNE_LOB.BUSINESS}
      pageName={`${eventSource}:business:login`}
      eventSource={eventSource}
      language={locale}
      eventSourceDetail={BNE_EVENT_SOURCE_DETAILS.LOGIN}
      formId={BNE_FORM_IDS.LOGIN}
    >
      {children}
      <TrackingTimer finished={!isLoading} timeout={5000}>
        <TrackState />
      </TrackingTimer>
    </Tagging>
  );
};

LoginTaggingWrapper.propTypes = propTypes;
LoginTaggingWrapper.defaultProps = defaultProps;

export default LoginTaggingWrapper;
