// @flow
import * as cookie from './cookie';
import {
  REMEMBER_DEVISE_BNE_COOKIE_NAME,
  REMEMBER_DEVISE_BNE_COOKIE_SEPARATOR,
} from './constants/bneConstants';

export const createRememberDeviceBneUsersCookie = (usernames: any) => {
  cookie.set(REMEMBER_DEVISE_BNE_COOKIE_NAME, JSON.stringify(usernames));
};

export const getRememberDeviceBneUsersCookie = () => {
  return cookie.get(REMEMBER_DEVISE_BNE_COOKIE_NAME);
};

export const removeRememberDeviceBneUsersCookie = () => {
  cookie.remove(REMEMBER_DEVISE_BNE_COOKIE_NAME);
};

export const addUserRememberDeviceBneUsersCookie = (user: string) => {
  let users = user;
  const usersFromCookie = getRememberDeviceBneUsersCookie();
  if (usersFromCookie) {
    users = usersFromCookie.concat(REMEMBER_DEVISE_BNE_COOKIE_SEPARATOR, user);
  }
  createRememberDeviceBneUsersCookie(users);
};

export const removeUserRememberDeviceBneUsersCookie = (user: string) => {
  const usersFromCookie = getRememberDeviceBneUsersCookie();
  if (usersFromCookie) {
    const usersCookieList = usersFromCookie
      .toString()
      .split(REMEMBER_DEVISE_BNE_COOKIE_SEPARATOR);
    const users = [];
    const usersClean = [...new Set(usersCookieList)];
    for (const userCookie of usersClean) {
      if (userCookie !== user) {
        users.push(userCookie);
      }
    }
    createRememberDeviceBneUsersCookie(
      users.join(REMEMBER_DEVISE_BNE_COOKIE_SEPARATOR),
    );
  }
};

export const uniqueUserRememberDeviceBneUsersCookie = () => {
  const usersFromCookie = getRememberDeviceBneUsersCookie();
  if (usersFromCookie) {
    const usersCookieList = usersFromCookie
      .toString()
      .split(REMEMBER_DEVISE_BNE_COOKIE_SEPARATOR);
    const usersClean = [...new Set(usersCookieList)];
    createRememberDeviceBneUsersCookie(
      usersClean.join(REMEMBER_DEVISE_BNE_COOKIE_SEPARATOR),
    );
  }
};
