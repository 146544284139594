import lowerCase from 'lodash/lowerCase';
import set from 'lodash/set';

// For example: List of selectors to be tested

// const TEST_SELECTORS = [
//     {
//       selector: isValidSelector,
//       selectorField: 'forgotPasswordForm.isValid',
//       selectorType: 'boolean',
//       defaultValue: false,
//     },
// ];

const generateSelectorTests = selectors => () => {
  const randomString = () =>
    Math.random()
      .toString(36)
      .substring(7);
  const randomInt = () => Math.floor(Math.random() * 101);
  const randomBoolean = () => Math.floor(Math.random() * 1001) % 2 === 0;

  const randomSingleValueMap = {
    string: randomString,
    int: randomInt,
    boolean: randomBoolean,
  };

  const randomObject = () => {
    const o = {};
    // generate random object consisting of all random single value
    for (const typeKey in randomSingleValueMap) {
      if (typeKey) {
        o[`field_${typeKey}`] = randomSingleValueMap[typeKey]();
      }
    }
    return o;
  };

  const randomValue = oType => {
    if (oType === 'object') {
      return randomObject();
    }
    if (oType in randomSingleValueMap) {
      return randomSingleValueMap[oType]();
    }
    throw new Error(`Unimplemented output type: ${oType}`);
  };

  /**
   *   outputMapper: (output value) => (another mapped value)
   *
   *   If we do not define outputMapper, we expect that the selector simply
   *   returns value defined in state without any manipulation.
   *
   *   For example:
   *     state = {
   *          count: X
   *    }
   *   and we expect countSelector returns X, then outputMapper does not need to be defined.
   *
   *   In some situations where some calculations are executed on state value, outputMapper is needed.
   *   For example, we expect countSelector return X+1, then we define outputMapper = X => X+1
   *
   */
  const randomInputOutputMapping = (outputType, defaultValue, outputMapper) => {
    const oTypes = outputType.split('.');
    const oFirstType = lowerCase(oTypes[0]);
    const oSecondType = lowerCase(oTypes[1]);

    const outputs = [undefined];

    // let generate 4 random values of outputType
    for (let i = 1; i <= 4; i++) {
      if (oFirstType === 'object' || oFirstType in randomSingleValueMap) {
        outputs.push(randomValue(oFirstType));
      } else if (oFirstType === 'array') {
        for (let arrSize = 0; arrSize <= 3; arrSize++) {
          const out = [];
          for (let k = 1; k <= arrSize * 2; k++) {
            out.push(randomValue(oSecondType));
          }
          outputs.push(out);
        }
      } else throw new Error(`Unimplemented output type: ${outputType}`);
    }

    const outputTransformer = v => (outputMapper ? outputMapper(v) : v);

    return outputs.map(v => ({
      input: v,
      output: outputTransformer(v === undefined ? defaultValue : v),
    }));
  };

  for (const {
    selector,
    selectorField,
    selectorType,
    defaultValue,
    outputMapper,
    testName,
  } of selectors) {
    const mapping = randomInputOutputMapping(
      selectorType,
      defaultValue,
      outputMapper,
    );

    for (const { input, output } of mapping) {
      it(`selector [${testName ||
        selector.name}] should return [${JSON.stringify(
        output,
      )}] when input is [${JSON.stringify(input)}]`, () => {
        const state = {};
        set(state, selectorField, input);
        expect(selector(state)).toEqual(output);
      });
    }
  }
};

export default generateSelectorTests;
