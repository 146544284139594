import getInitials from '../userUtils';

describe('getInitials', () => {
  [
    {
      user: {
        firstName: 'Rebecca',
        lastName: 'Morin',
      },
      result: 'RM',
    },
    {
      user: {
        firstName: 'Toto',
        lastName: 'Tata',
      },
      result: 'TT',
    },
    {
      user: {
        firstName: 'Rebecca',
      },
      result: '',
    },
    {
      user: {
        lastName: 'Morin',
      },
      result: '',
    },
    {
      user: {},
      result: '',
    },
  ].forEach(({ user, result }) => {
    it(`should return '${result}' for browser '${JSON.stringify(
      user,
    )}'`, () => {
      expect(getInitials(user)).toEqual(result);
    });
  });
});
