import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { currentSelector } from '../../../services/forgotPasswordService/selectors';

import {
  getPartnerIdName,
  getTemplateName,
} from '../../../services/templateService/selectors';

import NeedHelp from './needHelp';

const mapStateToProps = state => ({
  partnerId: getPartnerIdName(state),
  templateName: getTemplateName(state),
  currentForgotPasswordStep: currentSelector(state),
});

export default injectIntl(connect(mapStateToProps)(NeedHelp));
