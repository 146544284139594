// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import MultiFactorAuthRSA from './multiFactorAuthRSA';

import type { Dispatch } from '../../../types/store';
import type { State } from '../../../types/state';

import {
  formUpdate,
  triggerSubmit,
  triggerValidation,
  clearMfaFormErrors,
} from '../../../services/multiFactorAuthFormService/actions';
import {
  returnToLogin,
  clearLoginErrorMessages,
} from '../../../services/loginService/actions';
import {
  getMFAfactors,
  getSdkError,
  isFetching,
  getContact,
  getMFAselected,
  showOTCSuccessMessageSelector,
  getLastLoginFailureDate,
  hasLoginFailed,
} from '../../../services/loginService/selectors';
import {
  getMFAFormData,
  getMFAFormErrors,
} from '../../../services/multiFactorAuthFormService/selectors';
import { getAuthErrorMessageId } from '../../../utils/authErrorMessages';
import { getTemplateName } from '../../../services/templateService/selectors';
import {
  hasValidationErrors,
  isLoginFormDone,
} from '../../../services/loginFormService/selectors';

const mapStateToProps = (state: State) => ({
  formData: getMFAFormData(state),
  errors: getMFAFormErrors(state),
  isFetching: isFetching(state),
  sdkError: getSdkError(state),
  factorTypes: getMFAfactors(state),
  selectedFactor: getMFAselected(state),
  contact: getContact(state),
  getAuthErrorMessageId,
  templateName: getTemplateName(state),
  isShowResendOTCSuccessMessage: showOTCSuccessMessageSelector(state),
  lastLoginFailureDate: getLastLoginFailureDate(state),
  isFormDone: isLoginFormDone(state),
  hasValidationError: hasValidationErrors(state),
  hasLoginFailure: hasLoginFailed(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  formUpdate: (fieldName, fieldValue) =>
    dispatch(formUpdate(fieldName, fieldValue)),
  triggerValidation: fieldName => dispatch(triggerValidation(fieldName)),
  triggerSubmit: () => dispatch(triggerSubmit()),
  returnToLogin: () => dispatch(returnToLogin()),
  clearLoginErrorMessages: () => dispatch(clearLoginErrorMessages()),
  clearMfaFormErrors: () => dispatch(clearMfaFormErrors()),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuthRSA),
);
