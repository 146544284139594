import * as actionTypes from './actionTypes';

export const initialState = () => ({
  dtmType: null,
  isFetching: false,
  failure: false,
  backendError: {},
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_SET_DTM_TYPE:
      return {
        ...state,
        dtmType: action.dtmType,
      };
    case actionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        backendError: {},
      };
    case actionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        backendError: action.error,
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
