// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getPartnerIdName } from '../../../services/templateService/selectors';
import LoginFormMessage from './loginFormMessage';

const mapStateToProps = state => ({
  partnerIdName: getPartnerIdName(state),
});

export default injectIntl(connect(mapStateToProps)(LoginFormMessage));
