import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { SSO_URL_PARAMS } from '../../utils/constants/urlParams';
import { STORAGE_EXPIRATION_FIRST_TRY } from '../../utils/constants/login';

export const isSet = (item, path) => has(item, path) && !isEmpty(item[path]);

export const isRedirected = params =>
  isEmpty(params) ||
  !isSet(params, SSO_URL_PARAMS.RELAY_STATE) ||
  !isSet(params, SSO_URL_PARAMS.SAML_REQUEST);

export const getParam = (paramsList, paramName) =>
  isSet(paramsList, paramName) ? paramsList[paramName] : null;

export const shouldRetry = isFirstTryObj => {
  if (!isFirstTryObj || isFirstTryObj.isFirstTry) {
    return true;
  }
  const dateExpiration = new Date(isFirstTryObj.expiration);
  const currentDate = new Date();
  const diffDate = dateExpiration - currentDate;
  return (
    !isFirstTryObj.isFirstTry &&
    (diffDate <= 0 || diffDate >= STORAGE_EXPIRATION_FIRST_TRY)
  );
};
