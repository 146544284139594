// @flow
import * as actionTypes from './actionTypes';

import type { Action } from './types';

export type TemplateState = {|
  +pageUrl: string,
|};

const defaultState: TemplateState = {
  pageUrl: '',
};

export const initialState = () => defaultState;

export default (
  state: TemplateState = initialState(),
  action: Action,
): TemplateState => {
  switch (action.type) {
    case actionTypes.REDIRECT_TO_PAGE:
      return {
        ...state,
        pageUrl: action.pageUrl,
      };
    default:
      return state;
  }
};
