// eslint-disable-next-line import/prefer-default-export
export const unlockedTime = (userLockedCookie, locale) => {
  // eslint-disable-next-line radix
  const date = new Date(parseInt(userLockedCookie));

  return new Intl.DateTimeFormat('en-CA', {
    timeStyle: 'short',
    hourCycle: locale === 'fr' ? 'h23' : 'h12',
  }).format(date);
};
