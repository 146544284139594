import helper from '../helpers';
import {
  BASE_RESOURCES_URL,
  DEFAULT_IMAGE_PATH,
} from '../../../utils/constants/partner';

describe('getFavicons', () => {
  [
    [
      null,
      null,
      {
        faviconHref: '/favicons/favicon.ico',
        pngIconHref: '/favicons/icon.png',
        appleTouchIconHref: '/favicons/ios-icon.png',
        msApplication310Href: '/favicons/icon_largetile.png',
      },
    ],
    [
      'partnerId',
      'locale',
      {
        faviconHref: `${BASE_RESOURCES_URL}/${DEFAULT_IMAGE_PATH.FAVICON}`,
        pngIconHref: `${BASE_RESOURCES_URL}/${DEFAULT_IMAGE_PATH.PNG_ICON}`,
        appleTouchIconHref: `${BASE_RESOURCES_URL}/${DEFAULT_IMAGE_PATH.IOS_ICON}`,
        msApplication310Href: `${BASE_RESOURCES_URL}/${DEFAULT_IMAGE_PATH.LARGE_ICON}`,
      },
    ],
    [
      'anotherPartnerId',
      'anotherLocale',
      {
        faviconHref: `${BASE_RESOURCES_URL}/${DEFAULT_IMAGE_PATH.FAVICON}`,
        pngIconHref: `${BASE_RESOURCES_URL}/${DEFAULT_IMAGE_PATH.PNG_ICON}`,
        appleTouchIconHref: `${BASE_RESOURCES_URL}/${DEFAULT_IMAGE_PATH.IOS_ICON}`,
        msApplication310Href: `${BASE_RESOURCES_URL}/${DEFAULT_IMAGE_PATH.LARGE_ICON}`,
      },
    ],
  ].forEach(([partnerId, locale, expectedResult]) => {
    it(`should return ${JSON.stringify(
      expectedResult,
    )} with partnerId '${partnerId}' and locale '${locale}'`, () => {
      expect(helper.getFavicons(partnerId, locale)).toEqual(expectedResult);
    });
  });
});
