// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { announceMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';
import PasswordChangeSuccess from './passwordChangeSuccess';

import type { Dispatch } from '../../../types/store';

import { loginRouteRequestAppOrigin } from '../../../services/loginRouteService/actions';

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    loginRouteRequestAppOrigin: () => dispatch(loginRouteRequestAppOrigin()),
    announceMessage: msg => dispatch(announceMessage(msg, 'polite')),
  };
}

export default injectIntl(
  connect(null, mapDispatchToProps)(PasswordChangeSuccess),
);
