import UAParser from 'ua-parser-js';
import { testSaga } from 'bnc-utilities-js/testUtils/saga';
import workers from '../workers';

import { OS, Browser, UnsupportedBrowser } from '../mock';
import MockUserAgent from '../__mocks__/mockUserAgent';
import { USER_AGENT_INIT_DONE } from '../actionTypes';
import { UNSUPPORTED_BROWSER_INIT } from '../../unsupportedBrowserService/actionTypes';

import { TEMPLATE_SERVICE_REDUCER } from '../../../globalRedux/reducers/constants';

const mockBrowser = Browser;
const mockOs = OS;
const mockUnsopportedBrowser = UnsupportedBrowser;

jest.mock('ua-parser-js', () => jest.fn());

beforeEach(() => {
  UAParser.mockImplementation(() => MockUserAgent);
});

describe('.watchInitUserAgentRequest', () => {
  test('.watchInitUserAgentRequest - supported browser', async () => {
    MockUserAgent.getBrowser.mockReturnValue(mockBrowser);
    MockUserAgent.getOS.mockReturnValue(mockOs);

    const { sagaDone } = testSaga({
      saga: workers.watchInitUserAgentRequest,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
      },
      args: [],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: USER_AGENT_INIT_DONE,
        browser: mockBrowser,
        os: mockOs,
      },
      { type: UNSUPPORTED_BROWSER_INIT, isSupportedBrowser: true },
    ]);
  });

  test('.watchInitUserAgentRequest - unsupported browser', async () => {
    MockUserAgent.getBrowser.mockReturnValue(mockUnsopportedBrowser);
    MockUserAgent.getOS.mockReturnValue(mockOs);

    const { sagaDone } = testSaga({
      saga: workers.watchInitUserAgentRequest,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
      },
      args: [{}],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: USER_AGENT_INIT_DONE,
        browser: mockUnsopportedBrowser,
        os: mockOs,
      },
      { type: UNSUPPORTED_BROWSER_INIT, isSupportedBrowser: false },
    ]);
  });

  test('.watchInitUserAgentRequest - with the unsupported browser feature not available based on Config', async () => {
    MockUserAgent.getBrowser.mockReturnValue(mockUnsopportedBrowser);
    MockUserAgent.getOS.mockReturnValue(mockOs);

    const { sagaDone } = testSaga({
      saga: workers.watchInitUserAgentRequest,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SECURE_KEY',
        },
      },
      args: [{}],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: USER_AGENT_INIT_DONE,
        browser: mockUnsopportedBrowser,
        os: mockOs,
      },
      { type: UNSUPPORTED_BROWSER_INIT, isSupportedBrowser: true },
    ]);
  });
});
