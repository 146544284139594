// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { announceMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';
import AccountLocked from './accountLocked';

import { returnToLogin } from '../../../services/loginService/actions';
import { getLockedStatus } from '../../../services/loginService/selectors';
import {
  getPartnerIdName,
  getTemplateName,
} from '../../../services/templateService/selectors';

const mapStateToProps = state => ({
  partnerId: getPartnerIdName(state),
  templateName: getTemplateName(state),
  lockedStatus: getLockedStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    announceMessage: msg => dispatch(announceMessage(msg, 'polite')),
    returnToLogin: () => dispatch(returnToLogin()),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AccountLocked),
);
