import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import Footer from '../../common/Footer';

const propTypes = {
  intl: intlShape.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

function LoginFooter(props) {
  const { intl, className } = props;

  const footerContent = (
    <>
      <span>
        {intl.formatMessage({ id: 'rewards.text.title.firstconnection.wl' })}
      </span>
      {intl.formatMessage({ id: 'rewards.text.mess.createaccount.wl' })}
      <span className="number">
        {intl.formatMessage({ id: 'rewards.text.mess.createaccountwl.number' })}
      </span>
      {intl.formatMessage({ id: 'rewards.text.mess.createaccountwl.options' })}
    </>
  );

  return (
    <div className="login-footer">
      <Footer className={className} content={footerContent} />
    </div>
  );
}

LoginFooter.propTypes = propTypes;
LoginFooter.defaultProps = defaultProps;

export default LoginFooter;
