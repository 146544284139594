// @flow
const GLOBAL = {
  BREAKPOINT: {
    LARGE: '1440px',
    MEDIUM: '992px',
    SMALL: '767px',
    EXTRA_SMALL: '425px',
  },

  EVENT: {
    KEY: {
      ESCAPE: 'Escape',
      ENTER: 'Enter',
    },
    CSS: {
      TRANSITION_END: 'transitionend',
    },
  },

  CSS_PROPERTIES: {
    MARGIN_LEFT: 'margin-left',
  },

  COOKIE_EXPIRES_YEAR: 1,
  DOMAIN: '.bnc.ca',
};

export default GLOBAL;
