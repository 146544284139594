export const mockOktaAuth = {
  signOut: jest.fn(),
  signIn: jest.fn(),
  token: {
    getWithoutPrompt: jest.fn(),
  },
  session: {
    get: jest.fn(),
  },
  tx: {
    resume: jest.fn(),
  },
  implicitLogin: jest.fn(),
};

export default mockOktaAuth;
