// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  BNC_AUTH_CHANGE_PASSWORD_FAILURE,
  BNC_AUTH_CHANGE_PASSWORD_COMPLETED,
} from 'bnc-react-services/services/AuthService/actionTypes';

import * as actionTypes from './actionTypes';
import * as workers from './workers';

export const workersMapping = createWorkersMapping()
  .takeLatest(
    actionTypes.RESET_PASSWORD_REQUEST,
    workers.watchResetPasswordRequest,
  )
  .takeLatest(
    BNC_AUTH_CHANGE_PASSWORD_FAILURE,
    workers.watchBncAuthResetPasswordFailure,
  )
  .takeLatest(
    BNC_AUTH_CHANGE_PASSWORD_COMPLETED,
    workers.watchBncAuthResetPasswordCompleted,
  )
  .takeLatest(LOCATION_CHANGE, workers.watchChangeRoute);

export default createRootSagaFromWorkersMapping(workersMapping);
