import filter from 'lodash/filter';
import get from 'lodash/get';
import has from 'lodash/has';
import toLower from 'lodash/toLower';

import { FACTOR_TYPES } from 'bnc-react-services/managers/AuthManager/constants';

import {
  buildFactors,
  buildForgotPasswordChoices,
  getAgreementIdNumber,
  getChoicesToShow,
  hasExpiryDateForSheriff,
  hasOriginalAmountForSheriff,
  hasPhoneForSheriff,
} from '../index';
import TEMPLATES from '../../constants/template';
import { Configs } from '../../../configs';
import { FORGOT_PASSWORD_PRODUCT_TYPE } from '../constants';
import { CONTEXT_IDS } from '../../constants/forgotPassword';

describe('buildForgotPasswordChoices', () => {
  const sbipChoices = get(
    Configs,
    `TEMPLATES.${TEMPLATES.SBIP2}.FORGOT_PASSWORD.CHOICES`,
  );
  const natgoChoices = get(
    Configs,
    `TEMPLATES.${TEMPLATES.NATGO}.FORGOT_PASSWORD.CHOICES`,
  );
  const rewardsChoices = get(
    Configs,
    `TEMPLATES.${TEMPLATES.REWARDS}.FORGOT_PASSWORD.CHOICES`,
  );
  const baoChoices = get(
    Configs,
    `TEMPLATES.${TEMPLATES.BAO}.FORGOT_PASSWORD.CHOICES`,
  );
  const wlF55Choices = get(
    Configs,
    `WHITE_LABEL.SBIP2.config.f55.FORGOT_PASSWORD.CHOICES`,
  );
  const wlGwlChoices = get(
    Configs,
    `WHITE_LABEL.SBIP2.config.gwl.FORGOT_PASSWORD.CHOICES`,
  );
  const wlInvestorsGroupChoices = get(
    Configs,
    `WHITE_LABEL.SBIP2.config.investorsgroup.FORGOT_PASSWORD.CHOICES`,
  );
  const wlPartnershipChoices = get(
    Configs,
    `WHITE_LABEL.SBIP2.config.partnership.FORGOT_PASSWORD.CHOICES`,
  );

  const getResultExpected = (choices, partnerId) => {
    const choicesResult = filter(choices, choice =>
      has(FORGOT_PASSWORD_PRODUCT_TYPE, choice),
    );

    const suffix = partnerId ? `.${toLower(partnerId)}` : '';

    return choicesResult.map(choice => ({
      labelMessageKey: `text.label.forgotPassword.radio.${choice.toLowerCase()}${suffix}`,
      id: choice.toLowerCase(),
      value: get(FORGOT_PASSWORD_PRODUCT_TYPE, choice),
    }));
  };

  const testCases = [
    {
      templateName: TEMPLATES.SBIP2,
      partnerId: '',
      result: getResultExpected(sbipChoices),
    },
    {
      templateName: TEMPLATES.NATGO,
      partnerId: '',
      result: getResultExpected(natgoChoices),
    },
    {
      templateName: TEMPLATES.REWARDS,
      partnerId: '',
      result: getResultExpected(rewardsChoices),
    },
    {
      templateName: TEMPLATES.BAO,
      partnerId: '',
      result: getResultExpected(baoChoices),
    },
    {
      templateName: TEMPLATES.SBIP2_WHITE_LABEL,
      partnerId: 'f55',
      result: getResultExpected(wlF55Choices, CONTEXT_IDS.F55),
    },
    {
      templateName: TEMPLATES.SBIP2_WHITE_LABEL,
      partnerId: 'gwl',
      result: getResultExpected(wlGwlChoices, CONTEXT_IDS.GWL),
    },
    {
      templateName: TEMPLATES.SBIP2_WHITE_LABEL,
      partnerId: 'investorsgroup',
      result: getResultExpected(wlInvestorsGroupChoices, 'INVESTORSGROUP'),
    },
    {
      templateName: TEMPLATES.SBIP2_WHITE_LABEL,
      partnerId: 'partnership',
      result: getResultExpected(wlPartnershipChoices, CONTEXT_IDS.PARTNERSHIP),
    },
  ];

  testCases.forEach(testCase => {
    it(`should return the correct choices for ${testCase.templateName} ${testCase.partnerId}`, () => {
      expect(
        buildForgotPasswordChoices(testCase.templateName, testCase.partnerId),
      ).toEqual(testCase.result);
    });
  });
});

describe('getChoicesToShow', () => {
  const testCases = [
    {
      choicesConfig: ['choice1', 'choice2', 'choice3'],
      hasOpenChoices: false,
      result: ['choice1', 'choice2', 'choice3'],
    },
    {
      choicesConfig: ['choice1', 'choice2', 'choice3', 'choice4'],
      hasOpenChoices: false,
      result: ['choice1', 'choice2'],
    },
    {
      choicesConfig: ['choice1', 'choice2', 'choice3', 'choice4'],
      hasOpenChoices: true,
      result: ['choice1', 'choice2', 'choice3', 'choice4'],
    },
  ];

  testCases.forEach(testCase => {
    it(`should return array with ${testCase.result.length} elements with open choices: ${testCase.hasOpenChoices} and original array of ${testCase.choicesConfig.length} elements`, () => {
      expect(
        getChoicesToShow(testCase.choicesConfig, testCase.hasOpenChoices),
      ).toEqual(testCase.result);
    });
  });
});

describe('getAgreementIdNumber, hasExpiryDateForSheriff, hasOriginalAmountForSheriff, hasPhoneForSheriff selectors', () => {
  const testCases = [
    {
      cardNumber: '123456',
      accountNumber: '987654',
      productType: FORGOT_PASSWORD_PRODUCT_TYPE.DEBIT,
      result: '123456',
      resultHasExpDateForSheriff: true,
      resultHasOriginalAmountForSheriff: false,
      resultHasPhoneForSheriff: false,
    },
    {
      cardNumber: '123456',
      accountNumber: '987654',
      productType: FORGOT_PASSWORD_PRODUCT_TYPE.CREDIT,
      result: '123456',
      resultHasExpDateForSheriff: true,
      resultHasOriginalAmountForSheriff: false,
      resultHasPhoneForSheriff: false,
    },
    {
      cardNumber: '123456',
      accountNumber: '987654',
      productType: FORGOT_PASSWORD_PRODUCT_TYPE.LOAN,
      result: '987654',
      resultHasExpDateForSheriff: false,
      resultHasOriginalAmountForSheriff: true,
      resultHasPhoneForSheriff: false,
    },
    {
      cardNumber: '123456',
      accountNumber: '987654',
      productType: FORGOT_PASSWORD_PRODUCT_TYPE.MORTGAGE,
      result: '987654',
      resultHasExpDateForSheriff: false,
      resultHasOriginalAmountForSheriff: true,
      resultHasPhoneForSheriff: false,
    },
    {
      cardNumber: '123456',
      accountNumber: '987654',
      productType: FORGOT_PASSWORD_PRODUCT_TYPE.PROSPECT,
      resultHasExpDateForSheriff: false,
      resultHasOriginalAmountForSheriff: false,
      resultHasPhoneForSheriff: true,
    },
    {
      accountNumber: '987654',
      productType: FORGOT_PASSWORD_PRODUCT_TYPE.INVESTMENT,
      result: '987654',
      resultHasExpDateForSheriff: false,
      resultHasOriginalAmountForSheriff: false,
      resultHasPhoneForSheriff: true,
    },
    {
      accountNumber: '987654',
      productType: FORGOT_PASSWORD_PRODUCT_TYPE.INVESTMENT_BROKERAGE,
      result: '987654',
      resultHasExpDateForSheriff: false,
      resultHasOriginalAmountForSheriff: false,
      resultHasPhoneForSheriff: true,
    },
    {
      cardNumber: '123456',
      accountNumber: '987654',
      productType: 'some other product',
      result: '987654',
      resultHasExpDateForSheriff: false,
      resultHasOriginalAmountForSheriff: false,
      resultHasPhoneForSheriff: false,
    },
  ];

  testCases.forEach(
    ({
      result,
      resultHasExpDateForSheriff,
      resultHasOriginalAmountForSheriff,
      resultHasPhoneForSheriff,
      productType,
      cardNumber,
      accountNumber,
    }) => {
      it(`should return credit card ${result} for ${productType} and cardNumber= ${cardNumber} accountNumber= ${accountNumber}`, () => {
        expect(
          getAgreementIdNumber(cardNumber, accountNumber, productType),
        ).toEqual(result);
      });

      it(`should return resultHasExpDateForSheriff=${resultHasExpDateForSheriff} for ${productType}`, () => {
        expect(hasExpiryDateForSheriff(productType)).toEqual(
          resultHasExpDateForSheriff,
        );
      });

      it(`should return resultHasOriginalAmountForSheriff=${resultHasOriginalAmountForSheriff} for ${productType}`, () => {
        expect(hasOriginalAmountForSheriff(productType)).toEqual(
          resultHasOriginalAmountForSheriff,
        );
      });

      it(`should return resultHasPhoneForSheriff=${resultHasPhoneForSheriff} for ${productType}`, () => {
        expect(hasPhoneForSheriff(productType)).toEqual(
          resultHasPhoneForSheriff,
        );
      });
    },
  );
});

describe('buildFactors', () => {
  const testCases = [
    {
      factors: [
        { factorType: 'EMAIL', value: 'abc@example.com' },
        { factorType: 'SMS', value: '5146667777' },
      ],
      result: {
        factors: ['email', 'sms'],
        selectedFactor: 'sms',
        contact: '5146667777',
      },
    },

    {
      factors: [{ factorType: 'EMAIL', value: 'abc@example.com' }],
      result: {
        factors: ['email'],
        selectedFactor: 'email',
        contact: 'abc@example.com',
      },
    },

    {
      factors: [],
      result: {},
    },
  ];

  testCases.forEach(testCase => {
    it(`should return ${JSON.stringify(testCase.result)}`, () => {
      expect(buildFactors(testCase.factors, FACTOR_TYPES.SMS)).toEqual(
        testCase.result,
      );
    });
  });
});
