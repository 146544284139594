// @flow
import * as actionTypes from './actionTypes';

import type {
  ChangeLocaleAction,
  ChangeLocaleExtAction,
  ProtectChangeLocaleAction,
} from './types';

export function changeLocale(locale: string): ChangeLocaleAction {
  return {
    type: actionTypes.I18N_SERVICE_CHANGE_LOCALE,
    locale,
  };
}

export function changeLocaleExt(locale: string): ChangeLocaleExtAction {
  return {
    type: actionTypes.I18N_SERVICE_CHANGE_LOCALE_EXT,
    // TODO: if locale not one in configs then switch back to default locale (en)
    locale,
  };
}

export function protectChangeLocale(): ProtectChangeLocaleAction {
  return {
    type: actionTypes.I18N_SERVICE_PROTECT_CHANGE_LOCALE,
  };
}
