// @flow
import reducers from '../globalRedux/reducers';

type Reducer<S, A> = (state: S, action: A) => S;
type Handler = Reducer<any, any>;

export type Handlers = {
  [id: string]: Handler,
};

export type Reducers = typeof reducers;
