import React from 'react';
import ReactDOM from 'react-dom';
import { browserHistory } from 'react-router';
import { applyMiddleware, createStore, compose } from 'redux';
// Redux integration
import { routerReducer, syncHistoryWithStore } from 'react-router-redux';

import combineReducerFromServices from '../reducers';
import { SagaMiddleware, createMiddlewares } from '../middlewares';
import createRootSagaFromServices from '../sagas';
import { Configs } from '../../configs';
// Template
import { retrieveConfigsRequest } from '../../services/configsService/actions';
import { getCurrentTemplate } from '../../utils/templateUtils';
import templateServiceActions from '../../services/templateService/actions';
import paramsUnavailableServiceActions from '../../services/paramsUnavailableService/actions';
import { initAuthClientSdk } from '../../utils/sdk';
import { initUserAgentRequest } from '../../services/userAgentService/actions';

function prepareStore(store) {
  const templatesSchema = Configs.TEMPLATES;

  if (process.env.NODE_ENV === 'development') {
    // This code will not be bundled in production, we override the regex from openshift in order to match with localhost
    const axeConfig = {
      rules: [
        {
          id: 'radiogroup',
          enabled: false,
        },
      ],
    };
    const axe = require('react-axe');
    axe(React, ReactDOM, 1000, axeConfig);
  }
  // 1)
  // Set template based on URL
  const template = getCurrentTemplate(templatesSchema);
  store.dispatch(
    templateServiceActions.setCurrentTemplate(
      template.selectedTemplate,
      template.partnerId,
    ),
  );
  // Get configs
  store.dispatch(retrieveConfigsRequest());
  // Check for required parameters
  store.dispatch(paramsUnavailableServiceActions.initParamsUnavailable());
  // Initialize SDK
  initAuthClientSdk(store);
  // Set browser user agent
  store.dispatch(initUserAgentRequest());
  // Create an enhanced history that syncs navigation events with the store
  const history = syncHistoryWithStore(browserHistory, store);

  return { template, history };
}

export default function configureStore(services) {
  let composeEnhancers = compose;
  if (Configs.DEBUG_MODE) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  // Create store
  const store = createStore(
    combineReducerFromServices(services, routerReducer),
    composeEnhancers(applyMiddleware(...createMiddlewares())),
  );

  if (module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(combineReducerFromServices(services));
    });
  }
  // Run sagas
  SagaMiddleware.getInstance().run(createRootSagaFromServices(services));

  return {
    store,
    ...prepareStore(store),
  };
}
