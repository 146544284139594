import React from 'react';

import FullScreenFooterTemplate from '../FullScreenFooterTemplate';

import Footer from '../../../organisms/common/Footer';
import MultiFactorAuthFooterContent from '../../../organisms/bne/MultiFactorAuthFooter';
import HeaderEnterprise from '../../../organisms/common/HeaderEnterprise';
import MultiFactorAuth from '../../../organisms/bne/MultiFactorAuth';
import './style.scss';

type Props = {
  hideFooter: Boolean,
};

const MultiFactorAuthTemplate = (props: Props) => {
  const { hideFooter } = props;

  return (
    <div className="multifactorauth-bne">
      <HeaderEnterprise hasSwitchLang={false} className="mobile-header" />
      <FullScreenFooterTemplate
        className="container--enterprise"
        header={<HeaderEnterprise hasSwitchLang={false} />}
        main={<MultiFactorAuth className="sbip2-mfa" />}
        footer={(
          <Footer
            content={<MultiFactorAuthFooterContent hideFooter={hideFooter} />}
          />
        )}
        hideFooter={hideFooter}
      />
    </div>
  );
};

export default MultiFactorAuthTemplate;
