import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import * as actionTypes from './actionTypes';
import * as workers from './workers';

export const workersMapping = createWorkersMapping()
  .takeLatest(
    actionTypes.BIOMETRY_INITIALIZATION,
    workers.watchBiometryInitialization,
  )
  .takeLatest(
    actionTypes.BIOMETRY_INITIALIZATION_SUCCESS,
    workers.watchBiometryInitializationSuccess,
  )
  .takeLatest(
    actionTypes.BIOMETRY_VALIDATION_RESULT,
    workers.watchBiometryValidationResult,
  )
  .takeLatest(
    actionTypes.BIOMETRY_VALIDATION_SUCCESS,
    workers.watchBiometryValidationSuccess,
  )
  .takeLatest(actionTypes.BIOMETRY_FORCE_LOGIN, workers.watchForcePasswordLogin)
  .takeLatest(
    actionTypes.BIOMETRY_CREDENTIALS_DELETION_RESULT,
    workers.watchForCredentialsDeletionResult,
  )
  .takeLatest(
    actionTypes.BIOMETRY_CREDENTIALS_DELETION_SUCCESS,
    workers.watchForcePasswordLogin,
  )
  .takeLatest(
    actionTypes.BIOMETRY_LAUNCH_CREDENTIALS_SYNCHRONIZATION,
    workers.watchCredentialsSynchronization,
  );

export default createRootSagaFromWorkersMapping(workersMapping);
