// @flow
import {
  put,
  select,
  fork,
  take,
  cancel,
  takeEvery,
  delay,
} from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import size from 'lodash/size';
import {
  FACTOR_TYPES,
  TRANSACTION_STATUS,
} from 'bnc-react-services/managers/AuthManager/constants';
import * as BncAuthService from 'bnc-react-services/services/AuthService/actions';
import { announceAlertMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';
import {
  BNC_AUTH_VERIFY_SUCCESS,
  BNC_AUTH_RESEND_SUCCESS,
} from 'bnc-react-services/services/AuthService/actionTypes';
import browserHistory from '../../nav/BrowserHistoryManager';
import ROUTES from '../../utils/constants/routes';
import * as cookie from '../../utils/cookie';
import {
  loginSuccess,
  loginFailure,
  loadIdentitySuccess,
  displayResend,
  loginMFA,
  hideResend,
  updateContact,
  setReturnToSbipLoginFlag,
  hideResendOTCSuccessMessage,
  putIdentityWithDeviceToken,
  updateLockedStatus,
  startMfaDisplayResendCounter,
  tickMfaDisplayResendCounter,
  lockUserPassword,
  unlockUserPassword,
  updateLastLoginFailureDate,
  clearLoginErrorMessages,
  trackResendCode,
  setRsaReturnLoginFlag,
  saveHashLogin,
  setRememberDeviceBne,
} from './actions';
import * as loginRouteActions from '../loginRouteService/actions';
import redirectServiceActions from '../redirectService/actions';
import { changeStepRequest, formUpdate } from '../loginFormService/actions';
import { LOGIN_RETURN_TO_LOGIN } from './actionTypes';

import * as reducers from '../../globalRedux/reducers/constants';
import {
  IDENTITY_COOKIE_NAME,
  LOGIN_FORM_STEPS,
  RESEND_TIMEOUT,
  RESEND_SUCCESS_MESSAGE_TIMEOUT,
  IS_UL_SUCCESS_LOGIN_COOKIE_NAME,
} from '../../utils/constants/login';
import {
  getDeviceToken,
  createIdentitiesCookie,
  getPartnerCookieIdentityName,
  deleteIdentityCookie,
  addColorsToIdentities,
  getRememberMe,
  hashLogin,
  getBneDeviceToken,
} from './helper';
import TEMPLATES from '../../utils/constants/template';
import { getAppBaseUrl } from '../../utils/configUtils';
import { hideModal, showModal } from '../modalService/actions';
import { MODAL_TYPES } from '../../utils/constants/modal';
import { getAuthErrorMessageId } from '../../utils/authErrorMessages';
import {
  FRONTEND_ERROR_MESSAGES,
  GENERIC_WITH_CODE,
} from '../../utils/authErrorMessages/constants';
import { hasFeature } from '../../utils/featureUtils';
import { getTemplateName } from '../templateService/selectors';
import { getBaseUrl } from '../../utils/templateUtils';
import { Configs } from '../../configs';
import {
  resetMfaCode,
  clearMfaFormErrors,
  triggerSendCodeRequest,
} from '../multiFactorAuthFormService/actions';
import {
  getIdentityWithDeviceToken,
  getMfaDisplayResendCounter,
  getMFAfactorsWithValues,
} from './selectors';
import {
  createUserLockedCookie,
  getUserLockedCookie,
} from '../../utils/lockUserCookie';
import { MULTI_FACTOR_AUTH_FORM_TRIGGER_SEND_CODE_REQUEST } from '../multiFactorAuthFormService/actionTypes';
import Biometry from '../biometryService/biometry';
import {
  biometryCredentialsSynchronizationError,
  biometryLaunchCredentialsSynchronization,
} from '../biometryService/actions';
import type { BiometryState } from '../biometryService/types';
import { getDomain } from '../i18nService/workers';

export function* watchLoginRequest(action) {
  const { identity: username, password, remember } = action;
  const { identities } = (yield select())[reducers.LOGIN_SERVICE_REDUCER];

  const { templateName } = (yield select())[reducers.TEMPLATE_SERVICE_REDUCER];
  const requestMFA = !(templateName === TEMPLATES.BNE);

  const rememberMe = getRememberMe(remember, identities, username);
  let deviceToken = getDeviceToken(rememberMe, identities, username);
  if (rememberMe && deviceToken) {
    yield put(putIdentityWithDeviceToken(username, deviceToken));
  }

  if (Biometry.available()) {
    const biometryState: BiometryState = (yield select())[
      reducers.BIOMETRY_SERVICE_REDUCER
    ];
    if (biometryState?.synchroError && biometryState?.forceLogin) {
      yield put(
        biometryLaunchCredentialsSynchronization(username.trim(), password),
      );
    }
  }
  if (templateName === TEMPLATES.BNE) {
    console.log('Setting the username session storage variable to ', username);
    sessionStorage.setItem('username', username);
    deviceToken = getBneDeviceToken(identities, username.toLowerCase().trim());
    yield put(
      putIdentityWithDeviceToken(username.toLowerCase().trim(), deviceToken),
    );
    yield put(
      BncAuthService.signInRequest(
        username.toLowerCase().trim(),
        password,
        deviceToken,
        requestMFA,
      ),
    );
  } else {
    yield put(
      BncAuthService.signInRequest(username, password, deviceToken, requestMFA),
    );
  }
  const identification = hashLogin(username, password);
  yield put(saveHashLogin(identification));
}

export function* watchBncSignInCompleted(action) {
  const { transaction } = action;
  const transactionStatus = transaction.status;
  const { templateName } = (yield select())[reducers.TEMPLATE_SERVICE_REDUCER];
  if (transactionStatus === TRANSACTION_STATUS.MFA_REQUIRED) {
    yield put(
      loginMFA(
        transaction.contact,
        transaction.factors,
        null,
        transaction.factorsHint,
        transactionStatus,
        transaction.isHardEnrolling,
      ),
    );
    yield fork(
      createTriggerDisplayExpiredSessionModalMFAChoicePage,
      transaction,
    );
    // If transaction has only one factor we send by this factor auto, else we spot the MFA choices list
    if (size(transaction.factors) === 1) {
      yield put(triggerSendCodeRequest(transaction.factors[0]));
    } else {
      yield put(loginRouteActions.loginRouteRequestMFAChoice());
    }
  } else if (
    transactionStatus === TRANSACTION_STATUS.MFA_CHALLENGE ||
    transactionStatus === TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE ||
    transactionStatus === TRANSACTION_STATUS.MFA_ENROLL
  ) {
    // ACTION MFA
    yield put(
      loginMFA(
        transaction.contact,
        transaction.factors,
        transaction.selectedFactor,
        transaction.factorsHint,
        transactionStatus,
        transaction.isHardEnrolling,
      ),
    );

    // MFA case
    if (containsTokenFactor(action.transaction)) {
      yield put(setRsaReturnLoginFlag(false));
      yield put(loginRouteActions.loginRouteRequestRSA());
      return;
    }
    yield fork(createTaskTriggerDisplayResend);
    yield fork(createTaskTriggerDisplayExpiredSessionModal, transaction);
    yield put(loginRouteActions.loginRouteRequestMFA());
    yield put(resetMfaCode());
  } else if (transactionStatus === TRANSACTION_STATUS.PASSWORD_EXPIRED) {
    if (templateName === TEMPLATES.SECURE_KEY) {
      yield put(setReturnToSbipLoginFlag());
      yield put(loginRouteActions.loginRouteRequestPasswordException());
    } else {
      yield put(loginRouteActions.loginRouteRequestPasswordExpired());
    }
  } else if (
    transactionStatus === TRANSACTION_STATUS.LOCKED_OUT ||
    transactionStatus === TRANSACTION_STATUS.LOCKED_OUT_VSD ||
    transactionStatus === TRANSACTION_STATUS.LOCKED_OUT_FRAUD
  ) {
    if (templateName === TEMPLATES.BNE && !getUserLockedCookie()) {
      createUserLockedCookie();
    }
    yield put(updateLockedStatus(transactionStatus));
    yield put(loginRouteActions.loginRouteRequestLocked());
  }
}

export function* watchBncSignInFailure(action) {
  yield put(loginFailure(action.error));
}

export function* watchBncTokenEnroll(action) {
  const { transaction } = action;
  const transactionStatus = transaction.status;

  // ACTION MFA
  yield put(
    loginMFA(
      transaction.contact,
      transaction.factors,
      FACTOR_TYPES.TOKEN,
      transaction.factorsHint,
      transactionStatus,
      transaction.isHardEnrolling,
    ),
  );
  yield put(setRsaReturnLoginFlag(false));
  return yield put(loginRouteActions.loginRouteRequestRSA());
}

export function* watchLoginFailure(action) {
  const { sdkError } = action;
  const codeError = getAuthErrorMessageId(sdkError);
  const { templateName } = (yield select())[reducers.TEMPLATE_SERVICE_REDUCER];
  const { rsaReturnLoginFlag } = (yield select())[
    reducers.LOGIN_SERVICE_REDUCER
  ];

  const message =
    sdkError && sdkError.code
      ? `text.aria.connectionFailed.${codeError}|code=${sdkError.code}`
      : `text.aria.connectionFailed.${codeError}`;

  if (Biometry.available()) {
    yield put(biometryCredentialsSynchronizationError());
  }

  if (codeError === FRONTEND_ERROR_MESSAGES.AKAMAI_ERRORS.AK000001.code) {
    browserHistory.push(ROUTES.FORBIDDEN);
  } else if (
    codeError === FRONTEND_ERROR_MESSAGES.OKTA_API_ERRORS.E0000069.code &&
    templateName === TEMPLATES.BNE
  ) {
    if (!getUserLockedCookie()) {
      createUserLockedCookie();
    }

    yield put(updateLockedStatus(TRANSACTION_STATUS.LOCKED_OUT));

    yield put(clearLoginErrorMessages());
    yield put(loginRouteActions.loginRouteRequestLocked());
  } else {
    yield put(announceAlertMessage(message));
  }
  if (rsaReturnLoginFlag && templateName === TEMPLATES.BNE) {
    yield put(clearLoginErrorMessages());
    yield watchReturnToLogin();
  }
  if (codeError === GENERIC_WITH_CODE && templateName === TEMPLATES.BNE) {
    yield put(setRsaReturnLoginFlag(true));
  }

  yield put(updateLastLoginFailureDate(new Date(Date.now())));
}

export function* watchRemoveIdentity(action) {
  const { identity } = action;
  const currentIdentities = cookie.get(IDENTITY_COOKIE_NAME) || {};
  const { templateName, partnerId } = (yield select())[
    reducers.TEMPLATE_SERVICE_REDUCER
  ];
  const partnerCookieIdentityName = getPartnerCookieIdentityName(
    templateName,
    partnerId,
  );

  const newIdentities = deleteIdentityCookie(
    currentIdentities,
    identity,
    partnerCookieIdentityName,
  );

  cookie.set(IDENTITY_COOKIE_NAME, newIdentities);

  // $FlowFixMe
  if (isEmpty(newIdentities[partnerCookieIdentityName])) {
    yield put(changeStepRequest(LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP));
  }
  // $FlowFixMe
  const identitiesWithColors = addColorsToIdentities(
    newIdentities[partnerCookieIdentityName],
    partnerCookieIdentityName,
  );
  yield put(loadIdentitySuccess(identitiesWithColors));
}

export function* watchReturnToLogin() {
  yield put(hideModal(MODAL_TYPES.SESSION_EXPIRED_MODAL));
  const { returnToSbip } = (yield select())[reducers.LOGIN_SERVICE_REDUCER];
  const { templateName, partnerId } = (yield select())[
    reducers.TEMPLATE_SERVICE_REDUCER
  ];
  const { locale } = (yield select())[reducers.I18N_SERVICE_REDUCER];
  const returnToOrion = templateName === TEMPLATES.ORION_WHITE_LABEL;

  if (returnToSbip) {
    // When we display the password exception page for secure key,
    // we need to go back to sbip2 because there is no appBaseUrl in secure key
    const templateNameUrl =
      templateName === TEMPLATES.SECURE_KEY ? TEMPLATES.SBIP2 : templateName;

    const appUrl = getAppBaseUrl(templateNameUrl, partnerId, locale);
    if (appUrl) {
      yield put(redirectServiceActions.redirectToPage(appUrl));
    } else {
      browserHistory.push(`${ROUTES.NOT_FOUND}`);
    }
  } else if (returnToOrion) {
    // Only forgotPassword process is used so we must return to Orion for now.
    // The goal is to use the full solution for Orion in the future.
    window.location.href = getAppBaseUrl(templateName, partnerId, locale);
  } else {
    browserHistory.push(`${ROUTES.LOGIN}${window.location.search}`);
  }
}

// ------------------- MFA ------------------- //

function* triggerDisplayResendButtons() {
  // Display resend buttons with delay
  yield delay(RESEND_TIMEOUT);
  yield put(displayResend());
}

export function* createTaskTriggerDisplayResend() {
  const task = yield fork(triggerDisplayResendButtons);
  yield take(BNC_AUTH_VERIFY_SUCCESS);
  yield cancel(task);
}

export function* createTaskTriggerDisplayResendCountdown() {
  yield put(startMfaDisplayResendCounter());
  while ((yield select(getMfaDisplayResendCounter)) > 0) {
    yield delay(1000);
    yield put(tickMfaDisplayResendCounter());
  }
}

export function* triggerDisplayExpiredSessionModalMFAChoicePage() {
  yield delay(Configs.AUTH.MFA_PAGE_CHOICE_EXPIRATION);
  if (hasFeature(getTemplateName(yield select()), 'SESSION_EXPIRED_PAGE')) {
    yield put(
      redirectServiceActions.redirectToPage(
        `${getBaseUrl()}${ROUTES.SESSION_EXPIRED}`,
      ),
    );
  } else {
    yield put(setRsaReturnLoginFlag());
    yield put(formUpdate('identityBne', ''));
    yield put(formUpdate('passwordBne', ''));
    yield put(formUpdate('identity', ''));
    yield put(formUpdate('password', ''));
    yield put(showModal(MODAL_TYPES.SESSION_EXPIRED_MODAL));
  }
}
export function* createTriggerDisplayExpiredSessionModalMFAChoicePage(action) {
  const task = yield fork(
    triggerDisplayExpiredSessionModalMFAChoicePage,
    action,
  );
  yield takeEvery(
    MULTI_FACTOR_AUTH_FORM_TRIGGER_SEND_CODE_REQUEST,
    cancelTask,
    task,
  );
  yield takeEvery(LOGIN_RETURN_TO_LOGIN, cancelTask, task);
}

export function* triggerDisplayExpiredSessionModal(action) {
  // Display Expired Session Modal with delay
  if (action.selectedFactor === 'email') {
    yield delay(Configs.AUTH.MFA_CODE_EXPIRATION);
  } else {
    yield delay(Configs.AUTH.MFA_CODE_EXPIRATION_SMS);
  }
  // Show the session expired popup or the page
  if (hasFeature(getTemplateName(yield select()), 'SESSION_EXPIRED_PAGE')) {
    yield put(
      redirectServiceActions.redirectToPage(
        `${getBaseUrl()}${ROUTES.SESSION_EXPIRED}`,
      ),
    );
  } else {
    yield put(setReturnToSbipLoginFlag());
    yield put(showModal(MODAL_TYPES.MFA_EXPIRED_MODAL));
  }
}

export function* createTaskTriggerDisplayExpiredSessionModal(action) {
  const task = yield fork(triggerDisplayExpiredSessionModal, action);
  yield takeEvery(
    [BNC_AUTH_RESEND_SUCCESS, LOGIN_RETURN_TO_LOGIN],
    cancelTask,
    task,
  );
  yield take(BNC_AUTH_VERIFY_SUCCESS);
  yield cancel(task);
}

export function* createTaskHideResendSuccessMessage() {
  yield delay(RESEND_SUCCESS_MESSAGE_TIMEOUT);
  yield put(hideResendOTCSuccessMessage());
}

export function* cancelTask(task) {
  if (task) {
    yield cancel(task);
  }
}

export function* watchTriggerHideResendButtons() {
  // Hide resend buttons without delay
  yield put(hideResend());
}

export function* watchLoginMfaSendCodeRequest(action) {
  const { templateName } = (yield select())[reducers.TEMPLATE_SERVICE_REDUCER];
  const { MFAselected } = action;
  if (templateName === TEMPLATES.BNE) {
    const { locale } = (yield select())[reducers.I18N_SERVICE_REDUCER];
    // the locale value is going to be used in the headers to identify the language of otc content
    yield put(BncAuthService.sendRequest(MFAselected, locale));
  } else {
    yield put(BncAuthService.sendRequest(MFAselected));
  }
}

export function* watchLoginMfaResendCodeRequest(action) {
  yield put(BncAuthService.resendRequest(action.MFAselected));
}

export function* watchLoginMfaCodeValidationRequest(action) {
  const { code } = action;
  const { rememberDevice } = action;
  const { templateName } = (yield select())[reducers.TEMPLATE_SERVICE_REDUCER];
  if (templateName === TEMPLATES.BNE && rememberDevice) {
    yield put(setRememberDeviceBne(true));
    yield put(BncAuthService.verifyRequest(code, rememberDevice));
  } else {
    yield put(BncAuthService.verifyRequest(code));
  }
}

export function* watchBncSendFailure(action) {
  const { error } = action;
  yield put(loginFailure(error));
}

const containsTokenFactor = transaction => {
  return transaction.selectedFactor === FACTOR_TYPES.TOKEN;
};

export function* watchBncSendSuccess(action) {
  const { transaction } = action;
  const { templateName } = (yield select())[reducers.TEMPLATE_SERVICE_REDUCER];
  const autoDisplayResend = !(templateName === TEMPLATES.BNE);
  yield put(
    loginMFA(
      transaction.contact,
      yield select(getMFAfactorsWithValues),
      transaction.selectedFactor,
    ),
  );

  yield fork(createTaskTriggerDisplayExpiredSessionModal, transaction);
  if (autoDisplayResend) {
    yield fork(createTaskTriggerDisplayResend);
  }
  yield fork(createTaskTriggerDisplayResendCountdown);
  yield put(loginRouteActions.loginRouteRequestMFA());
}

export function* watchBncResendFailure(action) {
  const { error } = action;
  yield put(hideResendOTCSuccessMessage());
  yield put(loginFailure(error));
  yield fork(createTaskTriggerDisplayResend);
}

export function* watchBncResendSuccess(action) {
  const { transaction } = action;
  const { templateName } = (yield select())[reducers.TEMPLATE_SERVICE_REDUCER];
  const autoDisplayResend = !(templateName === TEMPLATES.BNE);

  yield put(updateContact(transaction.selectedFactor, transaction.contact));
  if (autoDisplayResend) {
    yield fork(createTaskTriggerDisplayResend);
  } else {
    yield fork(createTaskTriggerDisplayResendCountdown);
    yield put(clearMfaFormErrors());
  }
  yield fork(createTaskTriggerDisplayExpiredSessionModal, transaction);
  yield fork(createTaskHideResendSuccessMessage);

  yield put(trackResendCode(transaction.selectedFactor));
}

export function* watchBncVerifyFailure(action) {
  const { error } = action;
  yield put(loginFailure(error));
}

export function* watchBncVerifySuccess(action) {
  const { transaction } = action;
  if (transaction.status === TRANSACTION_STATUS.SUCCESS) {
    yield put(loginSuccess());
  } else if (transaction.status === TRANSACTION_STATUS.PASSWORD_EXPIRED) {
    yield put(loginRouteActions.loginRouteRequestPasswordExpired());
  }
}

export function* watchBncAuthSaveIdentity(action) {
  try {
    const profile = get(action.user, 'profile', false);
    if (profile) {
      // Retrieve deviceToken from state & set to profile which will be going to cookie
      const identitiesWithDeviceToken = yield select(
        getIdentityWithDeviceToken,
      );
      const identityWithDeviceToken = find(
        identitiesWithDeviceToken,
        identities => identities.login === profile.login,
      );
      if (identityWithDeviceToken && identityWithDeviceToken.deviceToken) {
        profile.deviceToken = identityWithDeviceToken.deviceToken;
      }
      const currentIdentities = cookie.get(IDENTITY_COOKIE_NAME) || {};
      const { templateName, partnerId } = (yield select())[
        reducers.TEMPLATE_SERVICE_REDUCER
      ];
      const partnerCookieIdentityName = getPartnerCookieIdentityName(
        templateName,
        partnerId,
      );
      if (templateName === TEMPLATES.BNE) {
        cookie.set(IS_UL_SUCCESS_LOGIN_COOKIE_NAME, 'true', {
          SameSite: 'None',
          domain: getDomain(),
        });
      }
      if (partnerCookieIdentityName) {
        let newIdentities = createIdentitiesCookie(
          currentIdentities,
          profile,
          partnerCookieIdentityName,
        );
        const { rememberDeviceBne } = (yield select())[
          reducers.LOGIN_SERVICE_REDUCER
        ];
        if (templateName === TEMPLATES.BNE && !rememberDeviceBne) {
          newIdentities = null;
        }
        // We set new identities if we need to
        if (newIdentities) {
          if (templateName === TEMPLATES.BNE)
            cookie.set(IDENTITY_COOKIE_NAME, newIdentities, {
              SameSite: 'None',
            });
          else cookie.set(IDENTITY_COOKIE_NAME, newIdentities);
          const identitiesWithColors = addColorsToIdentities(
            newIdentities[partnerCookieIdentityName],
            partnerCookieIdentityName,
          );
          yield put(loadIdentitySuccess(identitiesWithColors));
        }
      }
    }
  } catch (e) {
    // FIXME: log to datadog
    yield put(loadIdentitySuccess([]));
  }
}

export function* watchLocationChange(action) {
  if (action.payload.pathname === ROUTES.PARAMS_UNAVAILABLE) {
    yield put(BncAuthService.signOutRequest());
  }
}

export function* watchUserLockedPasswordTimeout(action) {
  const userLockedCookie = getUserLockedCookie();
  if (userLockedCookie) {
    const unlockedTimeout = userLockedCookie - new Date().getTime();
    yield put(lockUserPassword());
    yield delay(unlockedTimeout);
    yield put(unlockUserPassword());
  }
}
