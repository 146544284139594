import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import * as cookie from '../../../utils/cookie';
import HeadTitle from '../../common/HeadTitle';
import FormHandler from '../../../molecules/forms/FormHandler';
import ForgotPasswordForm from '../../../molecules/forms/ForgotPasswordForm';
import { FORM_TYPES } from '../../../utils/constants';
import { FORGOT_PASSWORD_PRODUCT_TYPE } from '../../../utils/forgotPasswordUtils/constants';

const emailKey = 'orionEmail';

type Props = {
  formData: any,
  errors: any,
  formUpdate: (fieldName: string, fieldValue: string) => void,
  triggerFieldValidation: (fieldName: string) => void,
  triggerFormValidation: () => void,
  isFetching: boolean,
  isFailure: boolean,
  returnToLogin: () => void,
  isTokenCaptchaError: boolean,
  backendError: {},
  numberOfRetry: number,
  templateName: string,
  partnerId: string,
  query: { [emailKey]: string },
};

const ForgotPassword = (props: Props) => {
  const {
    formData,
    errors,
    formUpdate,
    triggerFieldValidation,
    triggerFormValidation,
    isFetching,
    isFailure,
    returnToLogin,
    isTokenCaptchaError,
    backendError,
    numberOfRetry,
    templateName,
    partnerId,
    query,
  } = props;

  const email = cookie.get(emailKey) || query[emailKey] || '';

  // Delete after use
  cookie.set(emailKey, '', { domain: '.bnc.ca', maxAge: 0, path: '/' });

  const defaultValues = {
    email,
    recaptchaResponse: {},
  };

  useEffect(() => {
    formUpdate(
      'productType',
      FORGOT_PASSWORD_PRODUCT_TYPE.INVESTMENT_BROKERAGE,
    );
  }, [formUpdate]);

  return (
    <div className="forgot-password">
      <HeadTitle messageKey="text.title.passwordReset" />

      <FormHandler
        formName={FORM_TYPES.FORGOT_PASSWORD_FORM}
        initFormArgs={[defaultValues]}
      >
        <ForgotPasswordForm
          numberOfRetry={numberOfRetry}
          backendError={backendError}
          formData={formData}
          errors={errors}
          formUpdate={formUpdate}
          triggerFieldValidation={triggerFieldValidation}
          triggerFormValidation={triggerFormValidation}
          isFetching={isFetching}
          returnToLogin={returnToLogin}
          displayErrorMessage={
            isTokenCaptchaError || isFailure || !isEmpty(backendError)
          }
          templateName={templateName}
          partnerId={partnerId}
        />
      </FormHandler>
    </div>
  );
};

export default ForgotPassword;
