export const TAGGING_APP_NAME = 'bne';

export const BNE_TRACK_EVENTS = {
  pageLoad: 'trackPageLoad',
  pageView: 'trackPageView',
  trackEvent: 'trackEvent',
};

export const BNE_UCS_IDS = {
  FORM_ERROR: 'ucs-bne-FE',
  LOGIN_0001: 'ucs-bne-0001',
  LOGIN_0002: 'ucs-bne-0002',
  CLICK_ID: 'ucs-bne-CCTA',
  TECHNICAL_ERROR: 'ucs-bne-TE',
  ROADBLOCK: 'ucs-bne-RB',
  EXPIRATION: 'ucs-bne-LB',
};

export const BNE_UCS_STATE = {
  PV: 'pv',
  NON_PV: 'non-pv',
};

export const BNE_EVENT_NAMES = {
  FORM_ERROR: 'formError',
  PAGE_ERROR: 'pageError',
  PAGE_LOAD: 'pageLoad',
  PAGE_READY: 'pageReady',
  PAGE_NAME: 'clickCTA',
  ROADBLOCK: 'roadBlock',
  EXPIRATION: 'lightBox',
};

export const BNE_EVENT_SOURCE = {
  BNE: 'bne',
};

export const BNE_EVENT_SOURCE_DETAILS = {
  LOGIN: 'login',
};

export const BNE_EVENT_IDS = {
  FORM_ERROR: 'FE-bne',
  ROADBLOCK: 'RB-bne',
  EXPIRATION: 'LB-bne',
  PR: 'PR-bne',
  PE: 'PE-bne',
  PL: 'PL-bne',
  CTTA: 'CCTA-bne',
};

export const BNE_LOB = {
  BUSINESS: 'business',
};

export const BNE_STEP_NAMES = {
  SEND_SECURITY_CODE: 'send-security-code',
  ENTER_SECURITY_CODE: 'enter-security-code',
};

export const BNE_FORM_IDS = {
  LOGIN: 'login',
};

export const FUNCTIONALITIES = {
  LOGIN: 'login',
};

export const BNE_FORM_LOGIN_FIELD_ERROR = {
  IDENTITY: 'empty',
  PASSWORD: 'empty',
};

export const trackActionExcludedFields = ['pageName', 'language', 'lob'];

export const BREAKING_POINTS_LABELS = {
  xxl: 'xxl',
  xl: 'xl',
  lg: 'lg',
  md: 'md',
  sm: 'sm',
  xs: 'xs',
};

export const BREAKING_POINTS_VALUES = {
  xxl: 1440,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 577,
  xs: 576,
};

export const FORM_LOGIN_FIELD_ERROR_BNE = {
  IDENTITY: 'invalid_user_code',
  PASSWORD: 'required',
};
