// @flow
import type { Saga } from 'redux-saga';
import { put, select } from 'redux-saga/effects';

import { init } from 'bnc-react-services/services/AuthService/actions';

import type { ChangeLocaleAction, ChangeLocaleExtAction } from './types';
import { changeLocale } from './actions';

import * as cookie from '../../utils/cookie';
import I18N from '../../utils/constants/i18n';
import GLOBAL from '../../utils/constants/global';

import { getAuthConfig } from '../../utils/sdk';

export const IS_CI_DEVELOP_ENV = /\.bngf\.local/i;
export const CI_DEVELOP_DOMAIN = 'npr.ose.cloud.res.bngf.local';

export const getDomain = () => {
  let domain = GLOBAL.DOMAIN;
  if (process.env.NODE_ENV === 'development') {
    domain = 'localhost';
  } else if (IS_CI_DEVELOP_ENV.test(window.location.hostname)) {
    /**
     * All TI, TA, PP and production environments have bnc.ca as domain name but
     * CI-DEVELOP environment has ..bngf.local as domain name.
     */
    domain = CI_DEVELOP_DOMAIN;
  }

  return domain;
};

export function* watchChangeLocale(action: ChangeLocaleAction): void {
  // Save locale in cookie
  const { locale } = action;
  cookie.set(I18N.COOKIE_NAME, locale, {
    domain: getDomain(),
  });
  // Change Accept-Language for SDK (Voice MFA language)
  yield put(init(getAuthConfig(locale)));
}

export function* watchChangeLocaleExt(
  action: ChangeLocaleExtAction,
): Saga<void> {
  const {
    intl: { localeCanBeOverriden },
  } = yield select();
  if (localeCanBeOverriden) {
    yield put(changeLocale(action.locale));
  }
}
