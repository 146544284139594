import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { announceMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';

import ForgotPasswordUnavailable from './forgotPasswordUnvailable';
import {
  getPartnerIdName,
  getTemplateName,
} from '../../services/templateService/selectors';
import { returnToLogin } from '../../services/loginService/actions';
import type { Dispatch } from '../../types/store';

const mapStateToProps = state => ({
  partnerId: getPartnerIdName(state),
  templateName: getTemplateName(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  returnToLogin: () => dispatch(returnToLogin()),
  announceMessage: msg => dispatch(announceMessage(msg, 'polite')),
});
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordUnavailable),
);
