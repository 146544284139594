import testReducer from 'bnc-utilities-js/testUtils/reducer';
import formHandlerReducer from '../reducer';

import { formInitDone, resetForm, setFormInputsState } from '../actions';

import { formName, testForm } from '../mock';

const formId = 'randomFormId';

testReducer(formHandlerReducer)
  .initialState()
  .expectDiff({
    forms: {},
    formsStatus: {},
  });

testReducer(formHandlerReducer)
  .withState({
    forms: {},
    formsStatus: {},
  })
  .on(formInitDone(formId))
  .expectDiff({
    formsStatus: {
      [formId]: {
        initDone: true,
      },
    },
  });

testReducer(formHandlerReducer)
  .withState({
    forms: {
      [formId]: {
        nameInput: '',
      },
    },
    formsStatus: {
      [formId]: {
        initDone: true,
      },
    },
  })
  .on(resetForm(formId, formName))
  .expectDiff({
    forms: {},
    formsStatus: {},
  });

testReducer(formHandlerReducer)
  .withState({
    forms: {},
    formsStatus: {},
  })
  .on(setFormInputsState(formName, testForm))
  .expectDiff({
    forms: {
      [formName]: testForm,
    },
  });
