import get from 'lodash/get';

export const rootSelector = state => get(state, 'resetPasswordReducer', {});

export const failureSelector = state => get(rootSelector(state), 'failure');

export const getBackendError = state =>
  get(rootSelector(state), 'backendError');

export const isFetching = state =>
  get(rootSelector(state), 'isFetching', false);
