// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';

import { Tagging, TrackingTimer, TrackState } from '@nbc-studio/analytics';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import type {
  LoginFormData,
  LoginFormErrors,
} from '../../../../types/forms/loginForm';
import type { SDKError } from '../../../../types';

import LoginWithoutIdPartialContentForm from './LoginWithoutIdPartialContent';
import { LOGIN_FORM_STEPS } from '../../../../utils/constants/login';

import {
  EVENT_IDS,
  EVENT_NAMES,
  EVENT_SOURCE,
  LOB,
  UCS_IDS,
  UCS_STATE,
} from '../../../../utils/taggingUtils/constants';
import './style.scss';

type Props = {
  intl: IntlShape,
  formData: LoginFormData,
  formUpdate: (fieldName: string, value: string | boolean) => void,
  changeStepRequest: (nextStep: string, hasToFocus: ?boolean) => void,
  clearLoginErrorMessages: () => void,
  className: string,
  isFetching: boolean,
  errors: LoginFormErrors,
  currentStep: string,
  triggerSubmit: () => void,
  triggerValidation: (fieldName: string) => void,
  setFormInputs: (inputs: any) => void,
  onKeyPress: (event: Event, func: Function) => void,
  hasLoginFailure: boolean,
  sdkError: SDKError,
  hasValidationError: boolean,
  isFirstRender: ?boolean,
  hasToFocus: boolean,
  announceAlertMessage: (message: string) => void,
  announceStatusMessage: (message: string) => void,
  partnerName: string,
  partnerIdName: string,
  informationalMessage: string,
  signInButtonTheme: string,
  isFormDone: boolean,
};

const LoginForm = (props: Props) => {
  const {
    intl,
    formData,
    formUpdate,
    changeStepRequest,
    clearLoginErrorMessages,
    className,
    isFetching,
    errors,
    currentStep,
    triggerSubmit,
    triggerValidation,
    setFormInputs,
    onKeyPress,
    hasLoginFailure,
    sdkError,
    hasValidationError,
    isFirstRender,
    hasToFocus,
    announceAlertMessage,
    announceStatusMessage,
    partnerName,
    partnerIdName,
    informationalMessage,
    signInButtonTheme,
    isFormDone,
  } = props;

  const loginFormInputs = {};

  const onChange = (event: SyntheticInputEvent<any>) => {
    const fieldName = event.target.name;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    formUpdate(fieldName, value);
  };

  const onBlur = (event: SyntheticInputEvent<any>) => {
    const fieldName = event.target.name;
    triggerValidation(fieldName);
  };

  const onSubmit = () => {
    setFormInputs(loginFormInputs);
    triggerSubmit();
  };

  const handleKeyPress = event => {
    if (!isFetching) {
      onKeyPress(event, onSubmit);
    }
  };

  const localCurrentStepWithoutId =
    currentStep === LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP;
  const isDefault = !localCurrentStepWithoutId;

  const idName = partnerIdName || EVENT_SOURCE.SBIP2;

  return (
    <TransitionGroup>
      <CSSTransition
        key={LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP}
        in={localCurrentStepWithoutId || isDefault}
        timeout={500}
        classNames="login--animation"
        unmountOnExit
      >
        <LoginWithoutIdPartialContentForm
          className={className}
          intl={intl}
          formData={formData}
          onBlur={onBlur}
          isFetching={isFetching}
          onChange={onChange}
          clearLoginErrorMessages={clearLoginErrorMessages}
          errors={errors}
          triggerSubmit={triggerSubmit}
          setFormInputs={setFormInputs}
          handleKeyPress={handleKeyPress}
          hasLoginFailure={hasLoginFailure}
          sdkError={sdkError}
          hasValidationError={hasValidationError}
          isFirstRender={isFirstRender}
          announceAlertMessage={announceAlertMessage}
          hasToFocus={hasToFocus}
          partnerName={partnerName}
          informationalMessage={informationalMessage}
          signInButtonTheme={signInButtonTheme}
          formUpdate={formUpdate}
          changeStepRequest={changeStepRequest}
          announceStatusMessage={announceStatusMessage}
        />
      </CSSTransition>

      <Tagging
        ucsId={UCS_IDS.LOGIN_0001}
        ucsState={UCS_STATE.PV}
        eventName={EVENT_NAMES.PAGE_LOAD}
        eventId={EVENT_IDS.PL}
        eventSource={idName}
        pageName={`${idName}:personal:login`}
        language={intl.locale}
        lob={LOB.PERSONAL}
      >
        <TrackingTimer finished={isFormDone} timeout={5000}>
          <TrackState />
        </TrackingTimer>
      </Tagging>
    </TransitionGroup>
  );
};

export default LoginForm;
