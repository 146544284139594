// @flow
import {
  BNC_AUTH_CHANGE_PASSWORD_FAILURE,
  BNC_AUTH_CHANGE_PASSWORD_COMPLETED,
} from 'bnc-react-services/services/AuthService/actionTypes';
import type { Action as ReduxAction } from '../../types/action';
import * as actionTypes from './actionTypes';
import type { TransactionStatus } from '../../types';

export type ResetPasswordRequestAction = ReduxAction<
  typeof actionTypes.RESET_PASSWORD_REQUEST,
> & {
  passwordIsExpired: boolean,
  newPassword: string,
  currentPassword: string,
};

export type ResetPasswordFailureAction = ReduxAction<
  typeof actionTypes.RESET_PASSWORD_FAILURE,
> & {
  error: any,
};

export type BncAuthChangePasswordFailureAction = ReduxAction<
  typeof BNC_AUTH_CHANGE_PASSWORD_FAILURE,
> & {
  error: any,
};

export type BncAuthChangePasswordCompletedAction = ReduxAction<
  typeof BNC_AUTH_CHANGE_PASSWORD_COMPLETED,
> & {
  // TODO : TransactionType (react-bnc-services)
  transaction: {
    status: TransactionStatus,
    contact: string,
    factors: Array<string>,
    selectedFactor: string,
  },
};

export type Action =
  | ResetPasswordRequestAction
  | ResetPasswordFailureAction
  | BncAuthChangePasswordFailureAction
  | BncAuthChangePasswordCompletedAction;
