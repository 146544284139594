// @flow
import React from 'react';

import ErrorDisplay from '../../../molecules/common/ErrorDisplay';
import Redirect from '../../../molecules/common/Redirect';

type Props = {
  errorTitle: string,
  errorText: string,
  redirectPageUrl: ?string,
};

function ParamUnavailableError(props: Props) {
  const { errorTitle, errorText, redirectPageUrl } = props;

  return (
    <ErrorDisplay
      errorTitle={errorTitle}
      errorText={errorText}
      errorButton={redirectPageUrl && <Redirect pageUrl={redirectPageUrl} />}
    />
  );
}

export default ParamUnavailableError;
