// @flow
import React from 'react';
import { LinkButton } from 'bnc-react-components';
import cx from 'classnames';

import type { IntlShape } from 'react-intl';

import I18N from '../../../utils/constants/i18n';

import './style.scss';

type Props = {
  intl: IntlShape,
  changeLocale: (locale: string) => void,
  isVisible?: boolean,
  locale: string,
  className?: string,
};

const defaultProps = {
  isVisible: true,
  className: '',
};

const SwitchLang = (props: Props) => {
  const { isVisible, locale, intl, changeLocale, className } = props;

  const languages = {};
  // FR
  languages[I18N.LANG.FR] = {
    onChange: () => changeLocale(I18N.LANG.EN),
  };
  // EN
  languages[I18N.LANG.EN] = {
    onChange: () => changeLocale(I18N.LANG.FR),
  };

  const langToReadIn = locale === I18N.LANG.FR ? I18N.LANG.EN : I18N.LANG.FR;

  const renderSwitchLang = (langs, currentLocale) =>
    langs[currentLocale] ? (
      <div className={cx('header__switchLang', className)}>
        <LinkButton
          label={intl.formatMessage({ id: 'text.link.switchLang' })}
          onClick={langs[currentLocale].onChange}
          ariaLabel={intl.formatMessage({ id: 'text.aria.switchLang' })}
          lang={langToReadIn}
        />
      </div>
    ) : null;

  return isVisible && renderSwitchLang(languages, locale);
};

SwitchLang.defaultProps = defaultProps;

export default SwitchLang;
