// @flow
import React from 'react';
import cx from 'classnames';
import type { Node } from 'react';
import LoginAlert from '../../../molecules/forms/LoginAlert';
import './style.scss';

type Props = {
  className?: string,
  modal?: Node,
  header: Node,
  main: Node,
  footer?: Node,
  assets: Node,
  isLoginAlertEnable?: boolean,
};

const defaultProps = {
  className: '',
  footer: null,
  modal: null,
  isLoginAlertEnable: true,
};

function Right2ColumnsTemplate(props: Props) {
  const {
    className,
    header,
    main,
    footer,
    assets,
    modal,
    isLoginAlertEnable,
  } = props;

  return (
    <div className={cx('template-right', className)}>
      {modal}
      {assets && (
        <div className="template-right__content template-right__content--left">
          {assets}
        </div>
      )}

      <section className="template-right__content template-right__content--right">
        {isLoginAlertEnable && <LoginAlert />}
        <div className={cx('container', className)}>
          {header && <header>{header}</header>}
          {main && <main>{main}</main>}
        </div>
        {footer && <footer>{footer}</footer>}
      </section>
    </div>
  );
}

Right2ColumnsTemplate.defaultProps = defaultProps;

export default Right2ColumnsTemplate;
