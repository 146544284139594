import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { Route, browserHistory, Redirect } from 'react-router';
import get from 'lodash/get';

import NotFound from '../pages/NotFound';

import ROUTES from '../utils/constants/routes';
import Router from './Router';
import { buildPath, availableRoutes } from './Router/routerHelper';
import { Configs } from '../configs';

const propTypes = {
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  partnerId: PropTypes.string,
};

const defaultProps = {
  partnerId: '',
};

const Routes = props => {
  const { selectedTemplate, partnerId } = props;
  const availableFeatures = get(
    Configs,
    ['TEMPLATES', selectedTemplate, 'AVAILABLE_FEATURE'],
    [],
  );

  const getRoutes = () => {
    const routes = [];

    availableFeatures.forEach(feature => {
      const routeEndpoint = get(
        availableRoutes,
        [feature, 'path'],
        ROUTES.NOT_FOUND,
      );

      const path = buildPath(routeEndpoint, selectedTemplate, partnerId);
      const component = get(availableRoutes, [feature, 'component'], NotFound);

      routes.push(<Route key={feature} path={path} component={component} />);
    });
    // Default route to redirect to in case of mismatch
    routes.push(
      <Route key={uniqueId()} path={ROUTES.NOT_FOUND} component={NotFound} />,
    );
    routes.push(
      <Redirect key={uniqueId()} from={ROUTES.ALL} to={ROUTES.NOT_FOUND} />,
    );
    return routes;
  };

  return (
    <Router key={uniqueId()} history={browserHistory} {...props}>
      {getRoutes()}
    </Router>
  );
};

Routes.propTypes = propTypes;
Routes.defaultProps = defaultProps;

export default Routes;
