// @flow
import React, { useEffect, useState } from 'react';
import { Input, Constants } from 'bnc-react-components';

import { Lock } from '@nbc-design/icons/lib/web/Lock';
import { Button } from '@bnc-ui/button';
import { Text } from '@bnc-ui/text';
import { Alert } from '@nbc-design/alert';

import cx from 'classnames';
import has from 'lodash/has';

import type { IntlShape } from 'react-intl';

import LoginFormMessage from '../../../../common/LoginFormMessage';
import Greeting from '../../../../common/Greeting';

import type { SDKError } from '../../../../../types';
import type {
  LoginFormData,
  LoginFormErrors,
} from '../../../../../types/forms/loginForm';

import {
  IDENTITY_FIELD_MAX_LENGTH,
  PASSWORD_FIELD_MAX_LENGTH,
  WELCOME_ANNOUNCE_DELAY,
} from '../../../../../utils/constants/forms';

import { getWelcomeMsgKey } from '../../../../../utils/liveAnnouncer/helper';
import onEyeButtonClick from '../../../../forms/LoginForm/LoginTaggingWrapper/helper';
import LoginTaggingWrapper from '../../../../forms/LoginForm/LoginTaggingWrapper/index';

type Props = {
  className?: string,
  intl: IntlShape,
  formData: LoginFormData,
  onBlur: (event: SyntheticInputEvent<any>) => void,
  isFetching: boolean,
  onChange: (event: SyntheticInputEvent<any>) => void,
  clearLoginErrorMessages: () => void,
  errors: LoginFormErrors,
  triggerSubmit: () => void,
  setFormInputs: (inputs: any) => void,
  handleKeyPress: (event: Event) => void,
  hasLoginFailure: boolean,
  sdkError: SDKError,
  hasValidationError: boolean,
  isFirstRender: ?boolean,
  announceAlertMessage: (message: string) => void,
  hasToFocus: boolean,
  partnerName: string,
  informationalMessage: string,
  signInButtonTheme?: string,
};

const defaultProps = {
  className: '',
  signInButtonTheme: null,
};

const LoginWithoutIdPartialContentForm = (props: Props) => {
  const {
    intl,
    onBlur,
    formData,
    isFetching,
    onChange,
    clearLoginErrorMessages,
    errors,
    triggerSubmit,
    setFormInputs,
    handleKeyPress,
    hasLoginFailure,
    sdkError,
    className,
    hasValidationError,
    isFirstRender,
    announceAlertMessage,
    hasToFocus,
    partnerName,
    informationalMessage,
    signInButtonTheme,
  } = props;

  const [trackPassword, setTrackPassword] = useState(0);

  useEffect(() => {
    if (hasToFocus) {
      // Focus identity input field when component is rendered
      const identityInput = document.querySelector('#identity');
      if (identityInput) {
        identityInput.focus();
      }
    }
    if (isFirstRender === null || isFirstRender) {
      setTimeout(
        () => announceAlertMessage(getWelcomeMsgKey(partnerName)),
        WELCOME_ANNOUNCE_DELAY,
      );
    }
  }, [hasToFocus, isFirstRender, partnerName, announceAlertMessage]);

  let loginFormInputs = {};

  const hasIdentityError = has(errors, 'identity');
  const hasPasswordError = has(errors, 'password');
  const identityErrorClassName = hasIdentityError
    ? 'error loginForm_flexitem'
    : '';
  const passwordErrorClassName = hasPasswordError
    ? 'error loginForm_flexitem'
    : '';

  const onSubmit = () => {
    setFormInputs(loginFormInputs);
    triggerSubmit();
  };

  const renderLoginDisclaimerMessage = () => {
    return (
      <Alert
        title={intl.formatMessage({ id: 'text.alert.disclaimerOpenBanking' })}
        appearance="information"
        size="small"
      />
    );
  };

  return (
    <div className={cx(className)} role="none" onKeyPress={handleKeyPress}>
      <h2 className="a11y-hide">
        {intl.formatMessage({ id: 'text.aria.greetingsContext' })}
      </h2>
      <Greeting tagName="span" className="title" />
      {informationalMessage && <Text>{informationalMessage}</Text>}
      <LoginFormMessage
        errors={errors}
        hasValidationError={hasValidationError}
        hasLoginFailure={hasLoginFailure}
        sdkError={sdkError}
        clearLoginErrorMessages={clearLoginErrorMessages}
      />
      <div className="combo">
        <label className="login_label" htmlFor="identity">
          {intl.formatMessage({
            id: 'text.label.identity',
          })}
        </label>

        <div className="combo__entry combo__entry--small">
          <Input
            type="email"
            name="identity"
            placeholder={intl.formatMessage({
              id: 'text.field.identityPlaceholder',
            })}
            id="identity"
            onChange={onChange}
            onBlur={onBlur}
            className={cx(identityErrorClassName, className)}
            ariaInvalid={hasIdentityError}
            maxLength={IDENTITY_FIELD_MAX_LENGTH}
            value={formData.identity}
            innerRef={identityInputRef => {
              loginFormInputs = {
                ...loginFormInputs,
                identity: identityInputRef,
              };
            }}
          />
        </div>

        <label className="login_label" htmlFor="password">
          {intl.formatMessage({
            id: 'text.label.password',
          })}
        </label>
        <div className="combo__entry combo__entry--small">
          <LoginTaggingWrapper helpText="password" counting={trackPassword}>
            <Input
              type="password"
              name="password"
              placeholder={intl.formatMessage({
                id: 'text.field.passwordPlaceholder',
              })}
              id="password"
              onChange={onChange}
              onBlur={onBlur}
              className={cx(passwordErrorClassName, className)}
              ariaInvalid={hasPasswordError}
              maxLength={PASSWORD_FIELD_MAX_LENGTH}
              autoComplete={Constants.AUTOCOMPLETE.CURRENT_PASSWORD}
              value={formData.password}
              showPasswordIconAriaLabel={intl.formatMessage({
                id: 'text.aria.displayPassword',
              })}
              hidePasswordIconAriaLabel={intl.formatMessage({
                id: 'text.aria.hidePassword',
              })}
              innerRef={passwordInputRef => {
                loginFormInputs = {
                  ...loginFormInputs,
                  password: passwordInputRef,
                };
                onEyeButtonClick(passwordInputRef, setTrackPassword);
              }}
            />
          </LoginTaggingWrapper>
        </div>
      </div>
      {renderLoginDisclaimerMessage()}
      <Button
        type="submit"
        appearance="primary"
        theme={signInButtonTheme}
        size="large"
        fluid
        icon={<Lock size="small" title="lock" />}
        disabled={isFetching}
        className="login__submit"
        onClick={isFetching ? () => {} : onSubmit}
      >
        {isFetching
          ? intl.formatMessage({ id: 'loginForm.button.loading' })
          : intl.formatMessage({ id: 'text.button.login' })}
      </Button>
    </div>
  );
};

LoginWithoutIdPartialContentForm.defaultProps = defaultProps;

export default LoginWithoutIdPartialContentForm;
