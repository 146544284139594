import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import type { State } from '../../../types/state';
import SignUpMigration from './signUpMigration';
import { getLocale } from '../../../services/i18nService/selectors';
import type { Dispatch } from '../../../types/store';
import { loginRouteRequestSignUp } from '../../../services/loginRouteService/actions';

function mapStateToProps(state: State) {
  return {
    locale: getLocale(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    loginRouteRequestSignUp: () => dispatch(loginRouteRequestSignUp()),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SignUpMigration),
);
