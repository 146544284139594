import testReducer from 'bnc-utilities-js/testUtils/reducer';
import i18nServiceReducer from '../reducer';
import { changeLocale } from '../actions';

jest.mock('../../../utils/i18nManager', () => ({
  getUserLocale: jest.fn().mockReturnValue('fr'),
  getLocaleMessages: jest.fn().mockReturnValue({}),
}));

testReducer(i18nServiceReducer)
  .initialState()
  .expectDiff({
    locale: 'fr',
    messages: {},
    localeCanBeOverriden: true,
  });

testReducer(i18nServiceReducer)
  .withAnyState()
  .on(changeLocale('en'))
  .expectDiff({
    locale: 'en',
    messages: {},
  });
