import React, { useEffect } from 'react';
import { LinkButton } from 'bnc-react-components';
import { Button } from '@bnc-ui/button';
import { Heading } from '@bnc-ui/heading';
import type { IntlShape } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import type { User } from '../../../../types/user';

import UserIdentity from '../../../common/UserIdentity';
import { LOGIN_FORM_STEPS } from '../../../../utils/constants/login';

import { WELCOME_ANNOUNCE_DELAY } from '../../../../utils/constants/forms';

import { getWelcomeMsgKey } from '../../../../utils/liveAnnouncer/helper';

type Props = {
  className?: string,
  intl: IntlShape,
  identities: Array<User>,
  removeIdentity: (identity: User) => void,
  changeStepRequest: (nextStep: string) => void,
  isFirstRender: ?boolean,
  announceAlertMessage: (message: string) => void,
  hasToFocus: boolean,
  partnerName: string,
};

const defaultProps = {
  className: '',
};

const LoginDeleteIdPartialContent = (props: Props) => {
  const {
    intl,
    identities,
    removeIdentity,
    className,
    changeStepRequest,
    isFirstRender,
    announceAlertMessage,
    hasToFocus,
    partnerName,
  } = props;

  useEffect(() => {
    if (hasToFocus) {
      // Focus first identity button when component is rendered
      const firstIdentity = document.querySelector(
        '.identities__list .identity button',
      );
      if (firstIdentity) {
        firstIdentity.focus();
      }
    }
    if (isFirstRender === null || isFirstRender) {
      setTimeout(
        () => announceAlertMessage(getWelcomeMsgKey(partnerName)),
        WELCOME_ANNOUNCE_DELAY,
      );
    }
  }, [hasToFocus, isFirstRender, partnerName, announceAlertMessage]);

  const renderListIdentities = () =>
    identities.map(identity => (
      <CSSTransition
        key={identity.login}
        timeout={350}
        classNames="combo__entry--fade"
      >
        <div className="combo__entry identity">
          <Button
            onClick={() => removeIdentity(identity)}
            aria-label={intl.formatMessage(
              { id: 'text.aria.fieldDeleteIdentity' },
              { email: identity.login },
            )}
          >
            <UserIdentity user={identity} isDeletable />
          </Button>
        </div>
      </CSSTransition>
    ));

  return (
    <div className={className}>
      <Heading className="title greeting" component="h2">
        {intl.formatMessage({ id: 'text.title.deleteIdentity' })}
      </Heading>

      <TransitionGroup className="combo identities__list">
        {renderListIdentities()}
      </TransitionGroup>

      <LinkButton
        label={intl.formatMessage({ id: 'text.link.backIdentitySelection' })}
        onClick={() =>
          changeStepRequest(LOGIN_FORM_STEPS.LOGIN_WITH_ID_STEP, true)
        }
      />
    </div>
  );
};

LoginDeleteIdPartialContent.defaultProps = defaultProps;

export default LoginDeleteIdPartialContent;
