// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import * as actionTypes from './actionTypes';
import * as workers from './workers';

export const workersMapping = createWorkersMapping()
  .takeLatest(
    actionTypes.UNSUPPORTED_BROWSER_CONTINUE_ANYWAY,
    workers.watchContinueAnyway,
  )
  .takeLatest(
    actionTypes.UNSUPPORTED_BROWSER_CANNOT_CONTINUE,
    workers.watchCannotContinue,
  )
  .takeLatest(
    actionTypes.UNSUPPORTED_BROWSER_INIT,
    workers.watchInitUnsupportedBrowser,
  );

export default createRootSagaFromWorkersMapping(workersMapping);
