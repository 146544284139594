import { connect } from 'react-redux';

import { getTemplateName } from '../../services/templateService/selectors';
import ForgotPasswordException from './forgotPasswordException';

function mapStateToProps(state) {
  return {
    templateName: getTemplateName(state),
  };
}

export default connect(mapStateToProps)(ForgotPasswordException);
