import { SERVICES_ERROR_MESSAGES } from 'bnc-react-services/utils/errorMessages';

// Specific errors for frontend
const ERROR_TYPE = 'FrontEndError';
const ERROR_CODES = {
  NO_EXPLICIT_CONSENT: {
    message: 'NO_EXPLICIT_CONSENT',
    code: '5001',
    type: ERROR_TYPE,
  },
  ON_BEHALF_UNAUTHORIZED: {
    message: 'ON_BEHALF_UNAUTHORIZED',
    code: '5002',
    type: ERROR_TYPE,
  },
};

// We aggregate errors from SDK + services and specific errors for the frontend
export const FRONTEND_ERROR_MESSAGES = {
  ...SERVICES_ERROR_MESSAGES,
  FRONTEND_ERRORS: {
    ...ERROR_CODES,
  },
};

// Error codes having specific translations, the other ones will just use the generic message
// Could be imported from openshift configuration
export const SPECIFIC_MESSAGES_ARRAY = [
  FRONTEND_ERROR_MESSAGES.OKTA_API_ERRORS.E0000004.code,
  FRONTEND_ERROR_MESSAGES.OKTA_API_ERRORS.E0000109.code,
  FRONTEND_ERROR_MESSAGES.OKTA_API_ERRORS.E0000118.code,
  FRONTEND_ERROR_MESSAGES.OKTA_API_ERRORS.E0000068.code,
  FRONTEND_ERROR_MESSAGES.OKTA_API_ERRORS.E0000011.code,
  FRONTEND_ERROR_MESSAGES.OKTA_API_ERRORS.E0000069.code,
  FRONTEND_ERROR_MESSAGES.FRONTEND_ERRORS.NO_EXPLICIT_CONSENT.code,
  FRONTEND_ERROR_MESSAGES.FRONTEND_ERRORS.ON_BEHALF_UNAUTHORIZED.code,
  FRONTEND_ERROR_MESSAGES.AKAMAI_ERRORS.AK000001.code,
];

// Generic messages IDs
export const GENERIC_WITH_CODE = 'GENERIC_WITH_CODE';
export const GENERIC_WITHOUT_CODE = 'GENERIC_WITHOUT_CODE';

export const ERROR_CODE_KEY = 'code';
