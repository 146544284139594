// @flow
import React, { useEffect } from 'react';
import sessionManager from 'bnc-react-services/managers/SessionManager';
import { intlShape, FormattedHTMLMessage } from 'react-intl';
import cx from 'classnames';
import { Heading } from '@bnc-ui/heading';
import { Alert } from '@bnc-ui/alert';
import { Popover } from '@nbc-design/popover';
import { Link } from '@nbc-design/link';
import { FailureFilledXsmallColor } from '@nbc-design/icons/lib/web/FailureFilledXsmallColor';

import RSALogo from '../../../atoms/RSALogo/rsaLogo';
import FormHandler from '../../../molecules/forms/FormHandler';
import MultiFactorAuthRSAForm from '../../../molecules/bne/forms/MultiFactorAuthRSAForm';
import BackLinkButton from '../../../molecules/common/BackLinkButton';

import type { MultiFactorAuthFormData } from '../../../types/forms/multiFactorAuthForm';
import type { SDKError } from '../../../types';
import {
  MULTI_FACTOR_AUTH_FORM_INIT_DONE,
  MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
  MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE,
  MULTI_FACTOR_AUTH_UPDATE_FACTORS,
} from '../../../services/multiFactorAuthFormService/actionTypes';
import { formatFactorValue, getFactorIcon } from '../../../utils/factorUtils';
import { focusOnComponent } from '../../../utils/domUtils';
import { queueAnnouncer } from '../../../utils/liveAnnouncer/helper';
import getRsaErrorMessage from '../../../utils/bne/errorMessageRSAUtils';
import { FORM_TYPES } from '../../../utils/constants/forms';
import { FORGOT_PASSWORD_FIELDS } from '../../../utils/forgotPasswordUtils/constants';

import './style.scss';
import BneBroadcastChannel from '../BneBroadcastChannel';
import PageReadyTaggingWrapper from '../../../molecules/bne/TaggingBNE/PageReadyTaggingWrapper';
import { BNE_STEP_NAMES } from '../../../utils/taggingUtils/bneTaggingConstants';
import ErrorTaggingWrapper from '../../../molecules/bne/TaggingBNE/ErrorTaggingWrapper';

type Props = {
  intl: intlShape,
  className?: string,
  isFetching: boolean,
  formUpdate: (fieldName: string, fieldValue: string) => void,
  triggerSubmit: () => void,
  triggerValidation: (fieldName: string) => void,
  errors: *,
  factorTypes: Array<string>,
  formData: MultiFactorAuthFormData,
  returnToLogin: () => void,
  clearLoginErrorMessages: () => void,
  clearMfaFormErrors: () => void,
  sdkError: SDKError,
  selectedFactor: string,
  contact: string,
  getAuthErrorMessageId: ({}) => string,
  executedAction?: any,
  announceMessage?: string => void,
  templateName: string,
  lastLoginFailureDate: Date,
  isFormDone: boolean,
  hasValidationError: boolean,
  hasLoginFailure: boolean,
};

const defaultProps = {
  className: '',
  executedAction: {},
  announceMessage: () => {},
};

const MultiFactorAuthRSA = (props: Props) => {
  const {
    className,
    intl,
    formUpdate,
    errors,
    triggerSubmit,
    triggerValidation,
    isFetching,
    factorTypes,
    formData,
    contact,
    sdkError,
    selectedFactor,
    returnToLogin,
    clearLoginErrorMessages,
    clearMfaFormErrors,
    getAuthErrorMessageId,
    executedAction,
    announceMessage,
    templateName,
    lastLoginFailureDate,
    isFormDone,
    hasValidationError,
    hasLoginFailure,
  } = props;

  useEffect(() => {
    const { type } = executedAction;
    if (type === MULTI_FACTOR_AUTH_FORM_INIT_DONE) {
      const messages = [
        intl.formatMessage({ id: 'text.title.validationCode' }),
        intl.formatMessage({ id: 'text.message.delayCode' }),
        intl.formatMessage(
          { id: `text.message.validationCodeSent.${selectedFactor}` },
          { contact: formatFactorValue(selectedFactor, contact, intl.locale) },
        ),
      ];
      queueAnnouncer(messages, announceMessage);
    }
  }, [executedAction, intl, selectedFactor, announceMessage, contact]);

  const getFormErrorMessagekey = () => {
    return 'text.message.missingRSAError.bne';
  };

  useEffect(() => {
    if (errors.code) {
      queueAnnouncer(
        [
          intl.formatMessage({
            id: getFormErrorMessagekey(),
          }),
        ],
        announceMessage,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, intl, announceMessage]);

  useEffect(() => {
    if (sdkError.code) {
      queueAnnouncer(
        [
          getRsaErrorMessage(
            templateName,
            sdkError,
            intl,
            sessionManager.getTransactionStatus(),
          ),
        ],
        announceMessage,
      );
    }
  }, [
    sdkError,
    getAuthErrorMessageId,
    intl,
    announceMessage,
    templateName,
    lastLoginFailureDate,
  ]);

  useEffect(() => {
    const { type } = executedAction;
    if (
      !!sdkError.code ||
      type === MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE ||
      type === MULTI_FACTOR_AUTH_UPDATE_FACTORS ||
      type === MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE
    ) {
      focusOnComponent(`#${FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE}`);
    }
  }, [sdkError, executedAction]);

  const useSecurIdTitle = (
    <FormattedHTMLMessage id="text.message.useSecurId.bne" />
  );

  const popoverContent = (
    <div className="popoverContent">
      <RSALogo />
      <Heading type="h2" className="has-size06">
        {intl.formatMessage({ id: 'text.title.RSAKey.popover.first' })}
      </Heading>
      <FormattedHTMLMessage id="text.message.RSAKey.popover.first" />
      <Heading type="h2" className="popover-second has-size06">
        {intl.formatMessage({ id: 'text.title.RSAKey.popover.second' })}
      </Heading>
      <FormattedHTMLMessage id="text.message.RSAKey.popover.second" />
    </div>
  );

  const rsaPopover = (
    <Popover
      className="popover"
      title={intl.formatMessage({ id: 'text.subtitle.about.RSAKey' })}
      content={popoverContent}
      position="bottom"
      closeAriaLabel={intl.formatMessage({
        id: 'text.popover.rsa.close',
      })}
    >
      <Link href="#" className="about-rsa" tabIndex="0">
        {intl.formatMessage({ id: 'text.subtitle.about.RSAKey' })}
      </Link>
    </Popover>
  );

  const goBackToLogin = () => {
    clearLoginErrorMessages();
    clearMfaFormErrors();
    returnToLogin();
  };

  return (
    <>
      <ErrorTaggingWrapper
        errors={errors}
        hasValidationError={hasValidationError}
        hasLoginFailure={hasLoginFailure}
        sdkError={sdkError}
      />
      <PageReadyTaggingWrapper
        locale={intl.locale}
        isLoading={isFormDone}
        stepName={BNE_STEP_NAMES.ENTER_SECURITY_CODE}
      >
        <div id="multiFactorAuth" className={cx('connect-form rsa', className)}>
          <Heading type="h1" size={1}>
            {intl.formatMessage({ id: 'text.title.RSAKey' })}
          </Heading>

          <Heading type="h2" size={4}>
            {intl.formatMessage({ id: 'text.subtitle.RSAKey' })}
          </Heading>
          {!!sdkError.code && (
            <Alert
              className="send-error-message"
              title={getRsaErrorMessage(
                templateName,
                sdkError,
                intl,
                sessionManager.getTransactionStatus(),
              )}
              appearance="error"
              size="small"
              icon={<FailureFilledXsmallColor size="small" title="Error" />}
            />
          )}
          {!!errors.code && (
            <Alert
              className="send-error-message"
              title={intl.formatMessage({
                id: getFormErrorMessagekey(),
              })}
              appearance="error"
              size="small"
              icon={<FailureFilledXsmallColor size="small" title="Error" />}
            />
          )}
          {
            <Alert
              className="use-token"
              type="h1"
              title={useSecurIdTitle}
              size="normal"
              icon={getFactorIcon(selectedFactor, false)}
              appearance="information"
              link={rsaPopover}
            />
          }
          <FormHandler formName={FORM_TYPES.MULTI_FACTOR_AUTH_FORM}>
            <MultiFactorAuthRSAForm
              isFetching={isFetching}
              formData={formData}
              formUpdate={formUpdate}
              triggerSubmit={triggerSubmit}
              triggerValidation={triggerValidation}
              errors={errors}
              sdkErrors={sdkError}
              factorTypes={factorTypes}
            />
          </FormHandler>

          <BackLinkButton
            label={intl.formatMessage({
              id: 'global.button.backToLogin.bne',
            })}
            onClick={goBackToLogin}
          />
          <BneBroadcastChannel channelName="accountLocked_sync" />
        </div>
      </PageReadyTaggingWrapper>
    </>
  );
};

MultiFactorAuthRSA.defaultProps = defaultProps;

export default MultiFactorAuthRSA;
