// @flow
const CARDS = {
  BANKCARD: {
    TYPE: 'BANKCARD',
    LABEL: '******* ******',
  },
  CREDITCARD: {
    TYPE: 'CREDITCARD',
    LABEL: '**** **** **',
  },
  VIRTUALCARD: {
    TYPE: 'VIRTUALCARD',
    LABEL: '******* ******',
  },
};

export default CARDS;
