// @flow
import React from 'react';
import cx from 'classnames';
import type { IntlShape } from 'react-intl';

import { Logo } from 'bnc-react-components';

import { getMessageKey } from '../../../utils/templateUtils';

import './style.scss';

type Props = {
  intl: IntlShape,
  className?: string,
  isVisible?: boolean,
  locale: string,
  templateName: string,
};

const defaultProps = {
  className: '',
  isVisible: true,
};

function BncBrand(props: Props) {
  const { intl, className, isVisible, locale, templateName } = props;

  const customerAreaKey = getMessageKey(
    templateName,
    'text.title.customerArea',
  );

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={cx(
        'bnc-brand-enterprise',
        locale,
        className,
        templateName.toLowerCase(),
      )}
    >
      <div className="bnc-brand-enterprise__logo">
        <Logo
          tagName="div"
          locale={intl.locale}
          preserveAspectRatio="xMinYMid meet"
        />
      </div>
      <span className="bnc-brand-enterprise__separator" />
      <h1 className="bnc-brand-enterprise__tag bnc-brand-enterprise__tag--max-width">
        {intl.formatMessage({ id: customerAreaKey })}
      </h1>
    </div>
  );
}

BncBrand.defaultProps = defaultProps;

export default BncBrand;
