// @flow
import get from 'lodash/get';
import { hasWhiteLabels } from './templateUtils';
import { Configs } from '../configs';
import * as cookie from './cookie';
import TEMPLATES from './constants/template';
import { SBIE_ENVIRONMENTS } from './bne/EnvironmentUtils';

export const BASE_RESOURCES_URL = Configs.DAM_RESOURCES_BASE_URL;
const EXTERNAL_URLS = 'EXTERNAL_URLS';
const FAQ = 'FAQ';
const SECURE_KEY = 'SECURE_KEY';
const BACK_URL = 'BACK_URL';
const SBIP2 = 'SBIP2';
const PASSWORD_CHANGE_REDIRECT = 'PASSWORD_CHANGE_REDIRECT';
const DTM_MAPPING = 'DTM_MAPPING';

const getExternalUrlByConf = (configName: string, key: string, lang: string) =>
  get(Configs, [configName, EXTERNAL_URLS, key, lang.toUpperCase()], null);

export const getSecureKeyFAQUrl = (locale: string) =>
  getExternalUrlByConf(SECURE_KEY, FAQ, locale);

export const getSecureKeyCancelUrl = () =>
  get(Configs, [SECURE_KEY, EXTERNAL_URLS, BACK_URL], null);

export const getSecureKeyLogo = (locale: string) => {
  const fileName = get(Configs, [SECURE_KEY, 'LOGO', locale]);
  return `${BASE_RESOURCES_URL}/securekey/${fileName}`;
};

export const getSecureKeyAnnounceLogo = (locale: string) => {
  const fileName = get(Configs, [SECURE_KEY, 'ANNOUNCE_LOGO', locale]);
  return `${BASE_RESOURCES_URL}/securekey/${fileName}`;
};

export const getBackToLoginLink = () =>
  get(Configs, [SBIP2, PASSWORD_CHANGE_REDIRECT], null);

export const getDtmMappedValue = (tag: string) =>
  get(Configs, [DTM_MAPPING, tag], '');

export const getAppBaseUrl = (
  templateName: string,
  partnerId: string,
  locale: string,
) => {
  const hasWhiteLabel = hasWhiteLabels(templateName);
  const currentSbieEnv = cookie.get('sbieEnv');
  const appBaseUrl =
    templateName === TEMPLATES.BNE &&
    currentSbieEnv !== '' &&
    currentSbieEnv !== undefined
      ? get(
          Configs,
          [
            'TEMPLATES',
            templateName,
            'APP_URL',
            SBIE_ENVIRONMENTS[currentSbieEnv],
            locale.toUpperCase(),
          ],
          '',
        )
      : get(
          Configs,
          ['TEMPLATES', templateName, 'APP_URL', locale.toUpperCase()],
          '',
        );

  return hasWhiteLabel
    ? `${appBaseUrl}/${partnerIdForLocale(partnerId, locale)}`
    : appBaseUrl;
};

export const getSignUpPath = () =>
  get(Configs, 'SBIP2.ENDPOINT.SIGN_UP.URL', '');

export const getWithoutSignInPath = (lang: string) =>
  get(
    Configs,
    ['TEMPLATES', 'BAO', 'WITHOUT_SIGN_IN_URL', lang.toUpperCase()],
    '',
  );

const partnerIdForLocale = (partnerId, locale) => {
  if (locale === 'en') {
    switch (partnerId) {
      case 'cdbn':
        return 'nbdb';
      case 'fbngp':
        return 'nbfwm';
      case 'gp1859':
        return 'pb1859';
      default:
        return partnerId;
    }
  }
  return partnerId;
};
