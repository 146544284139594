import React from 'react';

import Right2ColumnsTemplate from '../../models/Right2ColumnsTemplate';

import LoginBaoFooter from '../../../organisms/bao/LoginFooter';
import Header from '../../../organisms/common/Header';
import LoginBao from '../../../organisms/bao/Login';
import ExpirationModal from '../../../organisms/common/ExpirationModal';

import BncMedia from '../../../molecules/common/BncMedia';

const LoginTemplate = () => (
  <Right2ColumnsTemplate
    className="container-bao-login"
    header={<Header logoTagName="h1" />}
    main={<LoginBao className="bao" />}
    footer={<LoginBaoFooter className="bao" />}
    assets={<BncMedia />}
    modal={<ExpirationModal />}
    isLoginAlertEnable={false}
  />
);

export default LoginTemplate;
