// @flow
import React from 'react';
import type { intlShape } from 'react-intl';

import { CSSTransition } from 'react-transition-group';

import { Heading } from '@nbc-design/heading';
import { Text } from '@nbc-design/text';
import SendMFACode from '../SendMFACode';

import './style.scss';

type Props = {
  intl: intlShape,
  triggerCodeRequest: (factor: *) => void,
  isHideResend: boolean,
  factors: Array<*>,
};

const ResendMFACode = (props: Props) => {
  const { intl, triggerCodeRequest, isHideResend, factors } = props;

  const displayResendButtons = () => (
    <CSSTransition
      in={!isHideResend}
      classNames="resend-group--animated"
      timeout={500}
      unmountOnExit
    >
      <div>
        <Heading type="h5" size={3}>
          {intl.formatMessage({ id: 'text.message.validationCodeResend' })}
        </Heading>
        <Text size="paragraph" type="span">
          {intl.formatMessage({
            id: 'text.message.validationCodeResend.subtext',
          })}
        </Text>
        <SendMFACode
          isResend
          triggerCodeRequest={triggerCodeRequest}
          factors={factors}
        />
      </div>
    </CSSTransition>
  );

  return <div id="resend-mfa-code">{displayResendButtons()}</div>;
};

export default ResendMFACode;
