// @flow
import React from 'react';
import cx from 'classnames';

import ResendMFACode from '../../../molecules/common/ResendMFACode';
import NeedHelp from '../../../molecules/common/NeedHelp';

import './style.scss';

type Props = {
  className?: string,
  triggerCodeRequest: (factor: string) => void,
  isHideResend: boolean,
  isDisableResendFooter?: Boolean,
  factors: Array<*>,
};

const defaultProps = {
  className: '',
  isDisableResendFooter: false,
};

const MultiFactorAuthFooter = (props: Props) => {
  const {
    className,
    triggerCodeRequest,
    isHideResend,
    isDisableResendFooter,
    factors,
  } = props;

  const isHideResendFooter = isDisableResendFooter || isHideResend;

  return (
    <div id="multiFactorAuthFooter" className={cx('mfa-footer', className)}>
      <ResendMFACode
        triggerCodeRequest={triggerCodeRequest}
        isHideResend={isHideResendFooter}
        factors={factors}
      />
      <NeedHelp />
    </div>
  );
};

MultiFactorAuthFooter.defaultProps = defaultProps;

export default MultiFactorAuthFooter;
