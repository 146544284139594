// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';
import cx from 'classnames';
import { Button } from 'bnc-react-components';

type Props = {
  className?: string,
  intl: IntlShape,
  cannotContinueWithUnsupportedBrowser: () => void,
};

const defaultProps = {
  className: '',
};

function Close(props: Props) {
  const { className, intl, cannotContinueWithUnsupportedBrowser } = props;

  return (
    <div className={cx('close', className)}>
      <Button
        className="close__button"
        onClick={cannotContinueWithUnsupportedBrowser}
        tabIndex={0}
      >
        {intl.formatMessage({ id: 'text.button.browserUnsupportedClose' })}
      </Button>
    </div>
  );
}

Close.defaultProps = defaultProps;

export default Close;
