import testReducer from 'bnc-utilities-js/testUtils/reducer';
import redirectReducer from '../reducer';
import actions from '../actions';

const pageUrl = 'http://localhost:3000';

testReducer(redirectReducer)
  .initialState()
  .expectDiff({
    pageUrl: '',
  });

testReducer(redirectReducer)
  .withAnyState()
  .on(actions.redirectToPage(pageUrl))
  .expectDiff({
    pageUrl,
  });
