import React from 'react';
import { intlShape } from 'react-intl';
import lowerCase from 'lodash/lowerCase';

import { Text } from '@bnc-ui/text';
import { Combo } from '@bnc-ui/combo';

import {
  getFactorIcon,
  getFactorTarget,
  orderFactors,
} from '../../../utils/factorUtils';

import './style.scss';

type Props = {
  intl: intlShape,
  triggerCodeRequest: (factor: *) => void,
  factors: Array<*>,
  isResend?: boolean,
};

const defaultProps = {
  isResend: false,
};

const SendMFACode = (props: Props) => {
  const { intl, triggerCodeRequest, factors, isResend } = props;

  let factorItems = [];
  const sendMessageId = isResend ? 'Resend' : 'Send';

  if (factors != null) {
    factorItems = orderFactors(factors).map(factor => ({
      action: () => {
        triggerCodeRequest(factor);
      },
      children: (
        <div
          className="factor-info"
          id={`send-mfa-code__choice-${factor.factorType}`}
        >
          <div className="factor-info__icon">
            {getFactorIcon(factor.factorType)}
          </div>
          <div>
            <Text size="paragraph" className="factor-info__label">
              {intl.formatMessage({
                id: `multiFactorAuthChoice${sendMessageId}.button.method.${lowerCase(
                  factor.factorType,
                )}`,
              })}
            </Text>
            <Text size="small" className="factor-info__sublabel">
              {getFactorTarget(factor, intl.locale)}
            </Text>
          </div>
        </div>
      ),
      disabled: false,
      icon: true,
      key: factor.factorType,
    }));
  }

  return (
    <Combo
      id="send-mfa-code__id"
      type="button"
      items={factorItems}
      className="send-mfa-code"
    />
  );
};

SendMFACode.defaultProps = defaultProps;

export default SendMFACode;
