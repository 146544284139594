// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';

import { toggleFeatureApi } from 'bnc-react-components';
import { Tagging, TrackingTimer, TrackState } from '@nbc-studio/analytics';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import type {
  LoginFormData,
  LoginFormErrors,
} from '../../../types/forms/loginForm';
import type { SDKError } from '../../../types';
import type { User } from '../../../types/user';

import LoginWithoutIdPartialContentForm from './LoginWithoutIdPartialContent';
import LoginWithIdPartialContent from './LoginWithIdPartialContent';
import LoginDeleteIdPartialContent from './LoginDeleteIdPartialContent';
import LoginPasswordPartialContent from './LoginPasswordPartialContent';
import { LOGIN_FORM_STEPS } from '../../../utils/constants/login';

import { CONFIGS_NAMES } from '../../../utils/constants';
import {
  EVENT_IDS,
  EVENT_NAMES,
  EVENT_SOURCE,
  LOB,
  UCS_IDS,
  UCS_STATE,
} from '../../../utils/taggingUtils/constants';
import type { Config } from '../../../types/configs';
import './style.scss';

type Props = {
  intl: IntlShape,
  formData: LoginFormData,
  identities: Array<User>,
  formUpdate: (fieldName: string, value: string | boolean) => void,
  changeStepRequest: (nextStep: string, hasToFocus: ?boolean) => void,
  clearLoginErrorMessages: () => void,
  className: string,
  isFetching: boolean,
  errors: LoginFormErrors,
  currentStep: string,
  removeIdentity: (identity: User) => void,
  triggerSubmit: () => void,
  triggerValidation: (fieldName: string) => void,
  setFormInputs: (inputs: any) => void,
  onKeyPress: (event: Event, func: Function) => void,
  hasForgotPwd: boolean,
  loginRouteForgotPassword: () => void,
  forgotPasswordUnavailable: () => void,
  hasMaxIdentities: boolean,
  hasIdentities: boolean,
  identitySelected: User,
  hasLoginFailure: boolean,
  sdkError: SDKError,
  hasValidationError: boolean,
  isFirstRender: ?boolean,
  hasToFocus: boolean,
  announceAlertMessage: (message: string) => void,
  announceStatusMessage: (message: string) => void,
  partnerName: string,
  partnerIdName: string,
  informationalMessage: string,
  signInButtonTheme: string,
  configs: Config,
  isFormDone: boolean,
};

const LoginForm = (props: Props) => {
  const {
    intl,
    formData,
    identities,
    formUpdate,
    changeStepRequest,
    clearLoginErrorMessages,
    className,
    isFetching,
    errors,
    currentStep,
    removeIdentity,
    triggerSubmit,
    triggerValidation,
    setFormInputs,
    onKeyPress,
    hasForgotPwd,
    loginRouteForgotPassword,
    forgotPasswordUnavailable,
    hasMaxIdentities,
    hasIdentities,
    identitySelected,
    hasLoginFailure,
    sdkError,
    hasValidationError,
    isFirstRender,
    hasToFocus,
    announceAlertMessage,
    announceStatusMessage,
    partnerName,
    partnerIdName,
    informationalMessage,
    signInButtonTheme,
    configs,
    isFormDone,
  } = props;

  const loginFormInputs = {};

  const onChange = (event: SyntheticInputEvent<any>) => {
    const fieldName = event.target.name;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    formUpdate(fieldName, value);
  };

  const onBlur = (event: SyntheticInputEvent<any>) => {
    const fieldName = event.target.name;
    triggerValidation(fieldName);
  };

  const onClickForgetLink = (event: SyntheticInputEvent<any>) => {
    loginRouteForgotPassword();
    event.preventDefault();
  };

  const onClickForgotUnavailableLink = (event: SyntheticInputEvent<any>) => {
    forgotPasswordUnavailable();
    event.preventDefault();
  };

  const isForgotPasswordAvailable = toggleFeatureApi.isAvailable(
    configs,
    CONFIGS_NAMES.FORGOT_PASSWORD.GLOBAL,
    [],
  );

  const onSubmit = () => {
    setFormInputs(loginFormInputs);
    triggerSubmit();
  };

  const handleKeyPress = event => {
    if (!isFetching) {
      onKeyPress(event, onSubmit);
    }
  };

  const localCurrentStepWithoutId =
    currentStep === LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP;
  const localCurrentStepWitId =
    currentStep === LOGIN_FORM_STEPS.LOGIN_WITH_ID_STEP;
  const localCurrentStepDeleteId =
    currentStep === LOGIN_FORM_STEPS.DELETE_IDENTITIES_STEP;
  const localCurrentStepPassword =
    currentStep === LOGIN_FORM_STEPS.PASSWORD_STEP;
  const isDefault =
    !localCurrentStepWithoutId &&
    !localCurrentStepWitId &&
    !localCurrentStepDeleteId &&
    !localCurrentStepPassword;

  const idName = partnerIdName || EVENT_SOURCE.SBIP2;

  return (
    <TransitionGroup>
      {(localCurrentStepWithoutId || isDefault) && (
        <CSSTransition
          key={LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP}
          in={localCurrentStepWithoutId || isDefault}
          timeout={500}
          classNames="login--animation"
          unmountOnExit
        >
          <LoginWithoutIdPartialContentForm
            intl={intl}
            isFormDone={isFormDone}
            formData={formData}
            onChange={onChange}
            formUpdate={formUpdate}
            onClickForgetLink={
              isForgotPasswordAvailable
                ? onClickForgetLink
                : onClickForgotUnavailableLink
            }
            className={className}
            hasForgotPwd={hasForgotPwd}
            isFetching={isFetching}
            identities={identities}
            changeStepRequest={changeStepRequest}
            clearLoginErrorMessages={clearLoginErrorMessages}
            hasIdentitiesSaved={hasIdentities}
            errors={errors}
            onBlur={onBlur}
            triggerSubmit={triggerSubmit}
            setFormInputs={setFormInputs}
            handleKeyPress={handleKeyPress}
            hasLoginFailure={hasLoginFailure}
            sdkError={sdkError}
            hasValidationError={hasValidationError}
            isFirstRender={isFirstRender}
            hasToFocus={hasToFocus}
            announceAlertMessage={announceAlertMessage}
            announceStatusMessage={announceStatusMessage}
            partnerName={partnerName}
            isMaximumReached={hasMaxIdentities}
            informationalMessage={informationalMessage}
            signInButtonTheme={signInButtonTheme}
          />
        </CSSTransition>
      )}
      {localCurrentStepWitId && (
        <CSSTransition
          key={LOGIN_FORM_STEPS.LOGIN_WITH_ID_STEP}
          in={localCurrentStepWitId}
          timeout={500}
          classNames="login--animation"
          unmountOnExit
        >
          <LoginWithIdPartialContent
            identities={identities}
            changeStepRequest={changeStepRequest}
            formUpdate={formUpdate}
            isFirstRender={isFirstRender}
            className={className}
            hasToFocus={hasToFocus}
            announceAlertMessage={announceAlertMessage}
            announceStatusMessage={announceStatusMessage}
            partnerName={partnerName}
            isMaximumReached={hasMaxIdentities}
            informationalMessage={informationalMessage}
            signInButtonTheme={signInButtonTheme}
          />
        </CSSTransition>
      )}
      {localCurrentStepDeleteId && (
        <CSSTransition
          key={LOGIN_FORM_STEPS.DELETE_IDENTITIES_STEP}
          in={localCurrentStepDeleteId}
          timeout={500}
          classNames="login--animation"
          unmountOnExit
        >
          <LoginDeleteIdPartialContent
            identities={identities}
            removeIdentity={removeIdentity}
            changeStepRequest={changeStepRequest}
            isFirstRender={isFirstRender}
            className={className}
            hasToFocus={hasToFocus}
            announceAlertMessage={announceAlertMessage}
            announceStatusMessage={announceStatusMessage}
            partnerName={partnerName}
            informationalMessage={informationalMessage}
          />
        </CSSTransition>
      )}
      {localCurrentStepPassword && (
        <CSSTransition
          key={LOGIN_FORM_STEPS.PASSWORD_STEP}
          in={localCurrentStepPassword}
          timeout={500}
          classNames="login--animation"
          unmountOnExit
        >
          <LoginPasswordPartialContent
            changeStepRequest={changeStepRequest}
            clearLoginErrorMessages={clearLoginErrorMessages}
            className={className}
            formUpdate={formUpdate}
            errors={errors}
            formData={formData}
            onChange={onChange}
            onClickForgetLink={
              isForgotPasswordAvailable
                ? onClickForgetLink
                : onClickForgotUnavailableLink
            }
            hasForgotPwd={hasForgotPwd}
            isFetching={isFetching}
            triggerSubmit={triggerSubmit}
            removeIdentity={removeIdentity}
            onBlur={onBlur}
            setFormInputs={setFormInputs}
            handleKeyPress={handleKeyPress}
            identitySelected={identitySelected}
            hasLoginFailure={hasLoginFailure}
            sdkError={sdkError}
            hasValidationError={hasValidationError}
            isFirstRender={isFirstRender}
            hasToFocus={hasToFocus}
            announceAlertMessage={announceAlertMessage}
            announceStatusMessage={announceStatusMessage}
            partnerName={partnerName}
            informationalMessage={informationalMessage}
            signInButtonTheme={signInButtonTheme}
          />
        </CSSTransition>
      )}

      <Tagging
        ucsId={UCS_IDS.LOGIN_0001}
        ucsState={UCS_STATE.PV}
        eventName={EVENT_NAMES.PAGE_LOAD}
        eventId={EVENT_IDS.PL}
        eventSource={idName}
        pageName={`${idName}:personal:login`}
        language={intl.locale}
        lob={LOB.PERSONAL}
      >
        <TrackingTimer finished={isFormDone} timeout={5000}>
          <TrackState />
        </TrackingTimer>
      </Tagging>
    </TransitionGroup>
  );
};

export default LoginForm;
