import testReducer from 'bnc-utilities-js/testUtils/reducer';
import loginFormServiceReducer, { initialState } from '../reducer';
import {
  formReset,
  formUpdate,
  initForm,
  initFormDone,
  submitFailure,
  triggerSubmit,
  validationFailure,
  validationSuccess,
  changeStepRequest,
  changeStepSuccess,
} from '../actions';

import { LOGIN_FORM_STEPS } from '../../../utils/constants/login';

testReducer(loginFormServiceReducer)
  .initialState()
  .expectDiff({
    formId: '',
    isValid: false,
    errors: {},
    formData: {
      identity: '',
      password: '',
      remember: false,
    },
    submitFailed: false,
    initDone: false,
    currentStep: LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP,
    isFirstRender: null,
    hasToFocus: false,
  });

testReducer(loginFormServiceReducer)
  .withAnyState()
  .on(initForm('FORM_ID'))
  .expectDiff({
    formId: 'FORM_ID',
  });

testReducer(loginFormServiceReducer)
  .withAnyState()
  .on(initFormDone())
  .expectDiff({
    formData: {},
    initDone: true,
  });

testReducer(loginFormServiceReducer)
  .withAnyState()
  .on(formReset())
  .expectDiff(initialState());

testReducer(loginFormServiceReducer)
  .withAnyState()
  .on(formUpdate('identity', 'fieldValue'))
  .expectDiff({
    formData: {
      identity: 'fieldValue',
    },
    submitFailed: false,
  });

testReducer(loginFormServiceReducer)
  .withAnyState()
  .on(validationFailure('fieldName', 'error'))
  .expectDiff({
    isValid: false,
    submitFailed: false,
    errors: {
      fieldName: 'error',
    },
  });

testReducer(loginFormServiceReducer)
  .withAnyState()
  .on(validationSuccess('fieldName', true))
  .expectDiff({
    isValid: true,
    submitFailed: false,
    errors: {},
  });

testReducer(loginFormServiceReducer)
  .withAnyState()
  .on(triggerSubmit())
  .expectDiff({
    submitFailed: false,
  });

testReducer(loginFormServiceReducer)
  .withAnyState()
  .on(submitFailure({}))
  .expectDiff({
    errors: {},
    submitFailed: true,
  });

testReducer(loginFormServiceReducer)
  .withAnyState()
  .on(changeStepSuccess(LOGIN_FORM_STEPS.PASSWORD_STEP))
  .expectDiff({
    currentStep: LOGIN_FORM_STEPS.PASSWORD_STEP,
    errors: {},
    submitFailed: false,
    isFirstRender: false,
  });

testReducer(loginFormServiceReducer)
  .withAnyState()
  .on(changeStepRequest('fieldName', true))
  .expectDiff({
    hasToFocus: true,
  });
