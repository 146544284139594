import React from 'react';

import { AlcWhiteLabelFrColor } from '@nbc-design/icons/lib/web/AlcWhiteLabelFrColor';
import { AlcWhiteLabelEnColor } from '@nbc-design/icons/lib/web/AlcWhiteLabelEnColor';

const getRewardsWLIcon = (intl, label) => {
  const labelParam = { id: label };
  if (intl.locale === 'fr') {
    return <AlcWhiteLabelFrColor ariaLabel={intl.formatMessage(labelParam)} />;
  }
  // English case
  return <AlcWhiteLabelEnColor ariaLabel={intl.formatMessage(labelParam)} />;
};

export { getRewardsWLIcon as default };
