// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedHTMLMessage } from 'react-intl';
import { Button } from 'bnc-react-components';

import type { ExceptionType } from '../../../utils/constants/forgotPassword';
import { EXCEPTION_TYPES } from '../../../utils/constants/forgotPassword';
import { getPhoneAssistanceNumber } from '../../../utils/templateUtils';
import { ASSISTANT_PHONE_TYPE } from '../../../utils/constants/phoneType';
import ErrorDisplay from '../../../molecules/common/ErrorDisplay';

type Props = {
  intl: IntlShape,
  exceptionType: ExceptionType,
  returnToLogin: () => void,
  partnerId: string,
  templateName: string,
};

function ForgotPasswordException(props: Props) {
  const { intl, exceptionType, returnToLogin, partnerId, templateName } = props;

  const errorTitle =
    exceptionType === EXCEPTION_TYPES.CALL_VSD
      ? 'text.title.clientFileError'
      : 'text.title.technicalError';

  let errorText;
  if (exceptionType === EXCEPTION_TYPES.CALL_VSD) {
    const assistanceNumber = getPhoneAssistanceNumber(
      templateName,
      partnerId,
      intl.locale,
      ASSISTANT_PHONE_TYPE.INTERNATIONAL,
    );
    errorText = (
      <FormattedHTMLMessage
        id="text.error.callVSD"
        values={{ assistanceNumber }}
      />
    );
  } else {
    errorText = intl.formatMessage({ id: 'global.text.error.500' });
  }

  const errorButton = (
    <Button primary onClick={returnToLogin}>
      {intl.formatMessage({ id: 'global.button.backToLogin' })}
    </Button>
  );
  return (
    <ErrorDisplay
      errorTitle={intl.formatMessage({ id: errorTitle })}
      errorText={errorText}
      errorButton={errorButton}
    />
  );
}

export default ForgotPasswordException;
