export const UCS_IDS = {
  FORGOT_PASSWORD: 'ucs-sbip2-forgotpassword-0001',
  FORM_ERROR: 'ucs-sbip2-FE',
  LOGIN_HD: 'ucs-sbip2-HD',
  LOGIN_0001: 'ucs-sbip2-0001',
  TECHNICAL_ERROR: 'ucs-sbip2-TE',
  ROADBLOCK: 'ucs-sbip2-RB',
};

export const UCS_STATE = {
  PV: 'pv',
  NON_PV: 'non-pv',
};

export const EVENT_NAMES = {
  STEPS: 'Steps',
  FORM_ERROR: 'formError',
  PAGE_ERROR: 'pageError',
  HELP_DISPLAY: 'helpDisplayed',
  PAGE_LOAD: 'pageLoad',
  ROADBLOCK: 'roadBlock',
  CLICK_CTA: 'clickCTA',
};

export const EVENT_SOURCE = {
  SBIP2: 'sbip2',
};

export const EVENT_SOURCE_DETAILS = {
  FORGOT_PASSWORD: 'forgotpassword',
  LOGIN: 'login',
};

export const EVENT_IDS = {
  ST_FORGOT_PASSWORD: 'ST_forgotpassword',
  FORM_ERROR: 'FE-sbip2',
  ROADBLOCK: 'RB-sbip2',
  HD: 'HD-sbip2',
  PE: 'PE-sbip2',
  PL: 'PL-sbip2',
  CTTA: 'CCTA-sbip2',
  CCTA_FP: 'CCTA-forgotpassword',
  CCTA_MFA: 'CCTA-mfa-setup',
};

export const LOB = {
  PERSONAL: 'personal',
};

export const STEP_NAMES = {
  NEW_PASSWORD: 'New Password',
};

export const FORM_IDS = {
  FORGOT_PASSWORD: 'forgotpassword',
};

export const FUNCTIONALITIES = {
  FORGOT_PASSWORD: 'forgotpassoword',
};

export const FORM_LOGIN_FIELD_ERROR = {
  IDENTITY: 'invalid_email',
  PASSWORD: 'required',
};

export const FLOW_STEPS = {
  START: 'first',
  INTERMEDIATE: 'step',
  END: 'last',
};

export const FLOW_IDS = {
  BANKCARD: 'debit',
  CREDITCARD: 'credit card',
  INVESTMENT: 'investment',
  LOAN: 'personal loan',
  MORTGAGE: 'mortgage',
};

export const MFA_FORM_ID = {
  LOGIN: 'mfa-login',
  FORGOT_PASSWORD: 'forgotpassword',
};

export const MFA_STEPS = {
  SEND_SECURITY_CODE: 'send security code',
  ENTER_SECURITY_CODE: 'enter security code',
};

export const MFA_FLOW_IDS = {
  CALL: 'vocal call',
  SMS: 'text message',
  EMAIL: 'email',
};

export const PAGES = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgotpassword',
};

export const FACTORS_TYPES = {
  SMS: 'sms',
  CALL: 'call',
  EMAIL: 'email',
};

export const EVENT = {
  name: {
    clickCTA: 'clickCTA',
    Steps: 'Steps',
    pageLoad: 'pageLoad',
  },
};
export const UCS = 'ucs';
export const TAGGING_APP_NAME = 'sbip2';

export const MFA_UCS_ID_MFA_LOGIN = `${UCS}-${TAGGING_APP_NAME}-0001`;
export const MFA_UCS_ID_MFA_FP = `${UCS}-${TAGGING_APP_NAME}-${PAGES.FORGOT_PASSWORD}-0001`;

export const FORM_STEPS_DEFINITION = {
  [PAGES.LOGIN]: {
    [MFA_STEPS.SEND_SECURITY_CODE]: {
      eventId: `PL-${TAGGING_APP_NAME.toUpperCase()}`,
      ucsState: UCS_STATE.PV,
      stepName: MFA_STEPS.SEND_SECURITY_CODE,
      ucsId: MFA_UCS_ID_MFA_LOGIN,
      formId: MFA_FORM_ID.LOGIN,
      pageName: `${LOB.PERSONAL}:${PAGES.LOGIN}:${MFA_STEPS.SEND_SECURITY_CODE}`,
      eventName: EVENT.name.pageLoad,
    },

    [MFA_STEPS.ENTER_SECURITY_CODE]: {
      eventId: `PL-${TAGGING_APP_NAME.toUpperCase()}`,
      ucsState: UCS_STATE.PV,
      stepName: MFA_STEPS.ENTER_SECURITY_CODE,
      ucsId: MFA_UCS_ID_MFA_LOGIN,
      formId: MFA_FORM_ID.LOGIN,
      pageName: `${LOB.PERSONAL}:${PAGES.LOGIN}:${MFA_STEPS.ENTER_SECURITY_CODE}`,
      eventName: EVENT.name.pageLoad,
    },
  },

  [PAGES.FORGOT_PASSWORD]: {
    [MFA_STEPS.SEND_SECURITY_CODE]: {
      eventId: `ST-${PAGES.FORGOT_PASSWORD}`,
      eventSourceDetail: PAGES.FORGOT_PASSWORD,
      ucsState: UCS_STATE.PV,
      stepName: MFA_STEPS.SEND_SECURITY_CODE,
      eventName: EVENT.name.Steps,
      ucsId: MFA_UCS_ID_MFA_FP,
      formId: MFA_FORM_ID.FORGOT_PASSWORD,
      pageName: `${LOB.PERSONAL}:${PAGES.FORGOT_PASSWORD}:${MFA_STEPS.SEND_SECURITY_CODE}`,
      stepId: '2',
    },

    [MFA_STEPS.ENTER_SECURITY_CODE]: {
      eventId: `ST-${PAGES.FORGOT_PASSWORD}`,
      eventSourceDetail: PAGES.FORGOT_PASSWORD,
      ucsState: UCS_STATE.PV,
      stepName: MFA_STEPS.ENTER_SECURITY_CODE,
      eventName: EVENT.name.Steps,
      ucsId: MFA_UCS_ID_MFA_FP,
      formId: MFA_FORM_ID.FORGOT_PASSWORD,
      pageName: `${LOB.PERSONAL}:${PAGES.FORGOT_PASSWORD}:${MFA_STEPS.ENTER_SECURITY_CODE}`,
      stepId: '3',
    },
  },
};
