import type { IntlShape } from 'react-intl';
import { anonymizeUsername } from './utils';

import { sendMessage } from '../../utils/browserUtils';
import {
  BIOMETRIC_CREDENTIALS_DELETION,
  BIOMETRIC_CREDENTIALS_UPDATE,
  BIOMETRIC_VALIDATION,
} from './constants';
import Biometry from './biometry';

export const launchBiometryValidation = (intl: IntlShape, biometry: Biometry) =>
  sendMessage({
    actionType: BIOMETRIC_VALIDATION,
    payload: {
      userId: biometry.user,
      enterPasswordLabel: intl.formatMessage({
        id: 'mobile.biometric.native.password.button',
      }),
      mainBiometricMessage: intl.formatMessage(
        { id: 'mobile.biometric.native.message' },
        { username: anonymizeUsername(biometry.user) },
      ),
    },
  });

export const deleteBiometryCredentials = (biometry: Biometry) =>
  sendMessage({
    actionType: BIOMETRIC_CREDENTIALS_DELETION,
    payload: {
      userId: biometry.user,
    },
  });

export const goToRetail = () =>
  sendMessage({
    actionType: 'navigateToRetail',
  });

export const updateBiometricCredentials = (username, password) =>
  sendMessage({
    actionType: BIOMETRIC_CREDENTIALS_UPDATE,
    payload: {
      userId: username,
      password,
    },
  });

export default launchBiometryValidation;
