import React from 'react';
import PropTypes from 'prop-types';
import { toggleFeatureApi } from 'bnc-react-components';
import { intlShape } from 'react-intl';
import get from 'lodash/get';
import { ChatBot as Widget } from '@bnc-dsp/bnc-webchat';

import { Configs } from '../../configs';
import { openExternal } from '../../utils/externalUrlUtils';
import { CONFIGS_NAMES } from '../../utils/constants/configs';
import TEMPLATES from '../../utils/constants/template';

const propTypes = {
  intl: intlShape.isRequired,
  configs: PropTypes.shape({}).isRequired,
  partnerId: PropTypes.string,
  selectedTemplate: PropTypes.string,
};

const defaultProps = {
  partnerId: null,
  selectedTemplate: null,
};

const Chatbot = props => {
  const { intl, configs, partnerId, selectedTemplate } = props;

  const isAvailable = toggleFeatureApi.isAvailable(
    configs,
    CONFIGS_NAMES.CHATBOT.LOGIN,
    [selectedTemplate === TEMPLATES.SBIP2],
    partnerId,
  );

  if (!isAvailable) {
    return null;
  }

  const findClosestBranch = get(
    Configs,
    ['SBIP2', 'EXTERNAL_URLS', 'FIND_CLOSEST_BRANCH', 'URL', intl.locale],
    '',
  );

  const makeAnAppointment = get(
    Configs,
    ['SBIP2', 'EXTERNAL_URLS', 'MAKE_AN_APPOINTMENT', 'URL', intl.locale],
    '',
  );

  const menuOptions = [
    {
      label: intl.formatMessage({ id: 'text.button.appointment' }),
      onClick: () => openExternal(makeAnAppointment),
    },
    {
      label: intl.formatMessage({ id: 'label.button.findUs' }),
      onClick: () => openExternal(findClosestBranch),
    },
  ];

  return (
    <Widget
      locale={intl.locale}
      hasAppLoaded
      agent={Configs.CHATBOT.AGENT}
      authenticated={false}
      socketUrl={Configs.CHATBOT.SOCKET_URL}
      checkGatewayReadyness={false}
      menuOptions={menuOptions}
      keepSession={false}
    />
  );
};

Chatbot.propTypes = propTypes;
Chatbot.defaultProps = defaultProps;

export default Chatbot;
