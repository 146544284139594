import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import getReducerState from '../../utils/stateSelectorHelper';

import { LOGIN_FORM_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';

export const getLoginFormState = (state, modulePath = '') =>
  getReducerState(state, modulePath, LOGIN_FORM_SERVICE_REDUCER);

export const getFormIdentity = createSelector(
  getLoginFormState,
  loginFormState => get(loginFormState, 'formData.identity', ''),
);
export const getFormData = createSelector(getLoginFormState, loginFormState =>
  get(loginFormState, 'formData', {}),
);
export const hasValidationErrors = createSelector(
  getLoginFormState,
  loginFormState => !isEmpty(loginFormState.errors),
);

export const isLoginFormDone = createSelector(
  getLoginFormState,
  loginFormState => get(loginFormState, 'initDone', false),
);

export const isSubmitFormFailed = createSelector(
  getLoginFormState,
  loginFormState => get(loginFormState, 'submitFailed', false),
);
