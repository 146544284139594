import get from 'lodash/get';
import { Configs } from '../../configs';

import {
  BASE_RESOURCES_LOGIN_IMAGE_URL,
  DEFAULT_LOGIN_IMAGE_NAME,
} from '../constants/login';
import { IMAGES_PATH } from '../constants/partner';

export const getConfigForPartner = partnerId =>
  get(Configs, ['WHITE_LABEL', 'config', partnerId], null);

export const getActivePartnerIds = templateName =>
  get(Configs, ['WHITE_LABEL', templateName, 'partnerIds'], []);

export const wlClasses = partnerId => {
  if (partnerId) {
    // eslint-disable-next-line
    import('../../wl-styles.scss');
    return ['wl-app', partnerId];
  }
  return [];
};

export const getPartnerLoginImage = (locale, partnerId) => {
  const filePath = get(
    IMAGES_PATH,
    [partnerId, 'LOGIN_IMAGE', locale],
    DEFAULT_LOGIN_IMAGE_NAME,
  );
  return `${BASE_RESOURCES_LOGIN_IMAGE_URL}/${filePath}`;
};
