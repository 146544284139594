import testReducer from 'bnc-utilities-js/testUtils/reducer';
import Biometry from '../biometry';
import biometryReducer from '../reducer';

import {
  initBiometrySuccess,
  biometryValidationError,
  biometryForceLogin,
  biometryCredentialsSynchronizationError,
} from '../actions';

const deviceId = 'device';
const biometricType = 'fingerprint';
const biometricEnabledUser = 'dummy.user';
const version = '2.0.0';
const biometry = new Biometry(
  deviceId,
  biometricType,
  biometricEnabledUser,
  version,
);

const error = {
  code: 'UnknownError',
  message: 'An unknown error occurred',
};

testReducer(biometryReducer)
  .initialState()
  .expectDiff({
    biometry: null,
    error: null,
    forceLogin: false,
    synchroError: false,
  });

testReducer(biometryReducer)
  .withAnyState()
  .on(initBiometrySuccess(biometry))
  .expectDiff({
    biometry,
  });

testReducer(biometryReducer)
  .withAnyState()
  .on(biometryValidationError(error))
  .expectDiff({
    error: {
      ...error,
      openInModal: true,
    },
  });

testReducer(biometryReducer)
  .withAnyState()
  .on(biometryForceLogin())
  .expectDiff({
    error: null,
    forceLogin: true,
  });

testReducer(biometryReducer)
  .withAnyState()
  .on(biometryCredentialsSynchronizationError())
  .expectDiff({
    synchroError: true,
  });
