// @flow
import { put, select, delay } from 'redux-saga/effects';
import requestsManager from 'bnc-react-services/managers/RequestsManager';
import * as formUtils from 'bnc-utilities-js/formUtils';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import type { Saga } from 'redux-saga';

import { announceAlertMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';

import * as actions from './actions';
import { fieldValidation, formValidation } from './formSchema';
import TEMPLATES from '../../utils/constants/template';

import {
  loginRequest,
  clearLoginErrorMessages,
  loadIdentitySuccess,
  userLockedPassword,
} from '../loginService/actions';
import {
  getPartnerCookieIdentityName,
  addColorsToIdentities,
} from '../loginService/helper';
import { focusOnFirstErrorField } from '../formHandlerService/actions';

import * as cookie from '../../utils/cookie';
import {
  MAX_IDENTITIES_SAVED,
  LOGIN_FORM_STEPS,
  IDENTITY_COOKIE_NAME,
} from '../../utils/constants/login';
import * as reducers from '../../globalRedux/reducers/constants';

import type {
  LoginFormChangeStepRequestAction,
  LoginFormInitFormAction,
  LoginFormTriggerSubmitAction,
  LoginFormTriggerValidationAction,
} from './types';

import { VALIDATION_DELAY } from '../../utils/constants/forms';

/**
 * Field Validation
 * action.type = LOGIN_FORM_VALIDATION_REQUEST
 * @param {*} action
 */
export function* watchFormValidation(
  action: LoginFormTriggerValidationAction,
): Saga<void> {
  const { currentStep: originalStep } = (yield select())[
    reducers.LOGIN_FORM_SERVICE_REDUCER
  ];
  yield delay(VALIDATION_DELAY);
  const { formData, currentStep } = (yield select())[
    reducers.LOGIN_FORM_SERVICE_REDUCER
  ];
  // Get current templateName
  const { templateName } = (yield select())[reducers.TEMPLATE_SERVICE_REDUCER];

  if (originalStep === currentStep) {
    const validationResult = yield requestsManager.call(
      fieldValidation,
      formData,
      action,
      templateName,
    );
    if (validationResult) {
      yield put(
        validationResult.fieldValid
          ? actions.validationSuccess(
              action.fieldName,
              validationResult.formValid,
            )
          : actions.validationFailure(
              action.fieldName,
              validationResult.errorField,
            ),
      );
      if (!validationResult.fieldValid) {
        yield put(announceAlertMessage(`text.aria.invalid${action.fieldName}`));
      }
    }
  }
}

/**
 * Form Submit
 * action.type = LOGIN_FORM_SUBMIT_REQUEST
 * @param {*} action
 */
export function* watchFormSubmit(
  action: LoginFormTriggerSubmitAction,
): Saga<void> {
  const { formData, formId } = (yield select())[
    reducers.LOGIN_FORM_SERVICE_REDUCER
  ];
  const { templateName } = (yield select())[reducers.TEMPLATE_SERVICE_REDUCER];
  const { identities } = (yield select())[reducers.LOGIN_SERVICE_REDUCER];
  const validationResult = yield requestsManager.call(
    formValidation,
    formData,
    templateName,
  );
  // validation failed
  if (!validationResult.formValid) {
    yield put(
      focusOnFirstErrorField(formId, validationResult.formattedErrors, true),
    );
    yield put(actions.submitFailure(validationResult.formattedErrors));
    return;
  }

  const identityNoSpaces = formUtils.stripSpaces(formData.identity);
  const passwordToPass = formData.password;
  const identityToPass = formUtils.containsNumbersOnly(identityNoSpaces)
    ? identityNoSpaces
    : formData.identity;
  const rememberMe = identities
    ? formData.remember && identities.length < MAX_IDENTITIES_SAVED
    : formData.remember;
  yield put(loginRequest(identityToPass, passwordToPass, rememberMe));
}

/**
 * Form Init
 * action.type = LOGIN_FORM_INIT_REQUEST
 * @param {*} action
 */
export function* watchFormInit(action: LoginFormInitFormAction): Saga<void> {
  let identitiesWithColors;
  try {
    const currentIdentities = cookie.get(IDENTITY_COOKIE_NAME) || {};
    // Get current partnerIdentityName
    const { templateName, partnerId } = (yield select())[
      reducers.TEMPLATE_SERVICE_REDUCER
    ];
    const partnerCookieIdentityName = getPartnerCookieIdentityName(
      templateName,
      partnerId,
    );
    const { isFirstRender } = (yield select())[
      reducers.LOGIN_FORM_SERVICE_REDUCER
    ];
    const identities = get(currentIdentities, partnerCookieIdentityName, []);

    if (!isEmpty(identities) && (isFirstRender === null || isFirstRender)) {
      const lastUsedIdentity = get(identities, [0, 'login'], '');
      yield put(actions.formUpdate('identity', lastUsedIdentity));
      yield put(actions.changeStepRequest(LOGIN_FORM_STEPS.PASSWORD_STEP));
    }
    // Augmenting identities with a color
    identitiesWithColors = addColorsToIdentities(
      identities,
      partnerCookieIdentityName,
    );

    if (templateName === TEMPLATES.BNE) {
      yield put(userLockedPassword());
    }
  } catch (e) {
    // FIXME: log error to datadog
    identitiesWithColors = [];
    yield put(
      actions.changeStepRequest(LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP),
    );
  }
  yield put(loadIdentitySuccess(identitiesWithColors));
  const { formData } = (yield select())[reducers.LOGIN_FORM_SERVICE_REDUCER];
  yield put(
    actions.initFormDone({
      identity: formData.identity || '',
    }),
  );
}

export function* watchChangeStepRequest(
  action: LoginFormChangeStepRequestAction,
): Saga<void> {
  // Make sure the password is cleared between every steps
  yield put(actions.formUpdate('password', ''));

  switch (action.nextStep) {
    case LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP:
      yield put(actions.formUpdate('identity', ''));
      yield put(actions.formUpdate('remember', false));
      break;
    case LOGIN_FORM_STEPS.LOGIN_WITH_ID_STEP:
      yield put(actions.formUpdate('identity', ''));
      break;
    case LOGIN_FORM_STEPS.PASSWORD_STEP:
      yield put(actions.formUpdate('remember', true));
      break;
    default:
      break;
  }

  yield put(actions.changeStepSuccess(action.nextStep));
  yield put(clearLoginErrorMessages());
}
