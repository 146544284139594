import selectors from '../selectors';

describe('getUnsupportBrowserState', () => {
  it('should return state if they are available', () => {
    const state = {
      unsupportedBrowserServiceReducer: { test: 1 },
    };
    expect(selectors.getUnsupportBrowserState(state)).toEqual({ test: 1 });
  });

  it('should return {} if state are not available', () => {
    const state = {};
    expect(selectors.getUnsupportBrowserState(state)).toEqual({});
  });

  it('should return module state if they are available', () => {
    const modulePath = 'front_end.sso';

    const state = {
      front_end: {
        sso: {
          unsupportedBrowserServiceReducer: { test: 1 },
        },
      },
    };
    expect(selectors.getUnsupportBrowserState(state, modulePath)).toEqual({
      test: 1,
    });
  });
});
