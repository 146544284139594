// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';

import TitleSection from '../TitleSection';

type Props = {
  intl: IntlShape,
};

function NotReceived(props: Props) {
  const { intl } = props;

  return (
    <TitleSection
      title={intl.formatMessage({
        id: 'text.title.passwordResetIfNotReceived',
      })}
      text={intl.formatMessage({
        id: 'text.message.passwordResetIfNotReceived',
      })}
    />
  );
}

export default NotReceived;
