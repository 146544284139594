// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { Message } from 'bnc-react-components';
import { Tagging, TrackAction, TrackState } from '@nbc-studio/analytics';
import type { LoginFormErrors } from '../../../types/forms/loginForm';
import type { SDKError } from '../../../types';

import { getFirstErrorKey } from '../../../utils/formUtils';
import { getAuthErrorMessageId } from '../../../utils/authErrorMessages';
import { getErrorDescription } from '../../../utils/taggingUtils/helper';
import {
  GENERIC_WITHOUT_CODE,
  GENERIC_WITH_CODE,
} from '../../../utils/authErrorMessages/constants';
import {
  EVENT_IDS,
  EVENT_NAMES,
  EVENT_SOURCE,
  UCS_IDS,
  UCS_STATE,
} from '../../../utils/taggingUtils/constants';

type Props = {
  errors: LoginFormErrors,
  hasValidationError: boolean,
  hasLoginFailure: boolean,
  sdkError: SDKError,
  clearLoginErrorMessages: () => void,
  intl: IntlShape,
  partnerIdName: string,
};

function LoginFormMessage(props: Props) {
  const {
    errors,
    hasValidationError,
    hasLoginFailure,
    sdkError,
    clearLoginErrorMessages,
    intl,
    partnerIdName,
  } = props;

  const popMessage = () => {
    if (hasValidationError) {
      if (hasLoginFailure) {
        clearLoginErrorMessages();
      }
      return {
        message: intl.formatMessage({
          id: `text.error.invalid${getFirstErrorKey(errors)}`,
        }),
        type: 'error',
      };
    }
    if (hasLoginFailure) {
      return {
        message: intl.formatMessage(
          { id: `auth.error.${getAuthErrorMessageId(sdkError)}` },
          { code: sdkError.code },
        ),
        type: 'error',
      };
    }
    return { message: null, type: null };
  };

  const { message, type } = popMessage();

  const isTechnicalError =
    hasLoginFailure &&
    !hasValidationError &&
    includes(
      [GENERIC_WITH_CODE, GENERIC_WITHOUT_CODE],
      getAuthErrorMessageId(sdkError),
    );

  const errorCode = get(sdkError, 'code', '0000');
  const errorMsg = get(sdkError, 'message', 'Unexpected Error');

  return (
    message && (
      <>
        <Message visible type={type}>
          {message}
        </Message>
        {isTechnicalError && (
          <Tagging
            ucsId={UCS_IDS.TECHNICAL_ERROR}
            ucsState={UCS_STATE.PV}
            eventName={EVENT_NAMES.PAGE_ERROR}
            eventId={EVENT_IDS.PE}
            eventSource={partnerIdName || EVENT_SOURCE.SBIP2}
            technicalError={`${errorMsg} (CODE:${errorCode})`}
          >
            <TrackState />
          </Tagging>
        )}
        {(hasValidationError || hasLoginFailure) && (
          <Tagging
            ucsId={UCS_IDS.FORM_ERROR}
            ucsState={UCS_STATE.NON_PV}
            eventName={EVENT_NAMES.FORM_ERROR}
            eventId={EVENT_IDS.FORM_ERROR}
            eventSource={partnerIdName || EVENT_SOURCE.SBIP2}
          >
            <TrackAction
              formError={getErrorDescription(hasLoginFailure, errors)}
              dependencies={[errors, partnerIdName]}
            />
          </Tagging>
        )}
      </>
    )
  );
}

export default LoginFormMessage;
