// @flow
import React from 'react';
import cx from 'classnames';
import type { Node } from 'react';
import { CSSTransition } from 'react-transition-group';
import './style.scss';

type Props = {
  className?: string,
  modal?: Node,
  main: Node,
  header: Node,
  footer: Node,
  hideFooter?: Boolean,
};

const defaultProps = {
  className: '',
  modal: null,
  hideFooter: false,
};

const FullScreenFooterTemplate = (props: Props) => {
  const { className, main, header, footer, modal, hideFooter } = props;

  const displayFooter = () => (
    <div className={cx('template-fullscreen-footer', className)}>
      {modal}
      <div className="template-fullscreen-footer__content">
        <header className="template-fullscreen-footer__header">{header}</header>
        <main className="template-fullscreen-footer__main">{main}</main>
      </div>
      {footer && (
        <CSSTransition
          in={!hideFooter}
          classNames="resend-group--animated"
          timeout={500}
          unmountOnExit
        >
          <footer className="template-fullscreen-footer__footer">
            {footer}
          </footer>
        </CSSTransition>
      )}
    </div>
  );

  return displayFooter();
};

FullScreenFooterTemplate.defaultProps = defaultProps;

export default FullScreenFooterTemplate;
