/**
 * Opens a URL in a new tab/window
 * @param {string} url
 */
export function openExternal(url) {
  const windowToOpen = window.open();
  // in case the new window has been blocked by the browser... thanks Safari ;)
  if (windowToOpen) {
    windowToOpen.opener = null;
    windowToOpen.location = url;
  }
  return windowToOpen;
}

/**
 * Opens a URL in the same tab/window
 * @param {string} url
 */
export function openExternalInSameTab(url) {
  if (!window.location || !url) {
    return;
  }
  window.location = url;
}
