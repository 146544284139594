// @flow
import React, { useEffect } from 'react';
import { IntlShape } from 'react-intl';
import get from 'lodash/get';
import { Error } from '@bnc-layouts/error';
import { Text } from '@bnc-ui/text';
import { Phone } from '@nbc-design/icons/lib/web/Phone';
import { Link } from '@bnc-ui/link';
import { Tagging, TrackState } from '@nbc-studio/analytics';
import { TRANSACTION_STATUS } from 'bnc-react-services/managers/AuthManager/constants';
import WhiteLabelBrand from '../../../molecules/whiteLabel/WhiteLabelBrand';
import { queueAnnouncer } from '../../../utils/liveAnnouncer/helper';
import { focusOnComponent } from '../../../utils/domUtils';
import ROUTES from '../../../utils/constants/routes';
import browserHistory from '../../../nav/BrowserHistoryManager';
import { getPhoneAssistanceNumber } from '../../../utils/templateUtils';
import { LOCKED_PHONE_TYPE } from '../../../utils/constants/phoneType';
import {
  EVENT_IDS,
  EVENT_NAMES,
  EVENT_SOURCE,
  EVENT_SOURCE_DETAILS,
  LOB,
  UCS_IDS,
  UCS_STATE,
} from '../../../utils/taggingUtils/constants';

import { LOCKED_OUT_TOO_MUCH_TRIES } from '../../../utils/constants/forgotPassword';

import './style.scss';

type Props = {
  intl: IntlShape,
  templateName: string,
  partnerId: string,
  returnToLogin: func,
  announceMessage: (msg: string) => void,
  lockedStatus: string,
};

const WARNING_MESSAGE_ID_MAP = {
  [TRANSACTION_STATUS.LOCKED_OUT_FRAUD]:
    'text.message.accountlocked.warning.unusual',
  [TRANSACTION_STATUS.LOCKED_OUT_VSD]:
    'text.message.accountlocked.warning.unusual',
  [LOCKED_OUT_TOO_MUCH_TRIES]:
    'text.message.accountlocked.warning.tooMuchTries',
};

const AccountLocked = (props: Props) => {
  const {
    intl,
    partnerId,
    returnToLogin,
    templateName,
    announceMessage,
    lockedStatus,
  } = props;

  const { locale } = intl;

  const shouldShowCard =
    lockedStatus === TRANSACTION_STATUS.LOCKED_OUT_FRAUD ||
    lockedStatus === TRANSACTION_STATUS.LOCKED_OUT_VSD;

  const warningMsg = intl.formatMessage({
    id: get(
      WARNING_MESSAGE_ID_MAP,
      lockedStatus,
      'text.message.accountlocked.warning',
    ),
  });

  const cardMsg = shouldShowCard
    ? intl.formatMessage({ id: 'text.message.accountlocked.card' })
    : '';

  const assistanceNumber = getPhoneAssistanceNumber(
    templateName,
    partnerId,
    locale,
    get(LOCKED_PHONE_TYPE, lockedStatus, ''),
  );

  useEffect(() => {
    if (!lockedStatus) {
      browserHistory.push(`${ROUTES.LOGIN}${window.location.search}`);
    }

    focusOnComponent('#back-to-login');
    const messages = [
      intl.formatMessage({ id: 'text.message.accountlocked.sorry' }),
      warningMsg,
      cardMsg,
      assistanceNumber,
    ];
    queueAnnouncer(messages, announceMessage);
  });

  const eventLabel = 'account locked';

  return (
    <Tagging
      ucsId={UCS_IDS.ROADBLOCK}
      ucsState={UCS_STATE.NON_PV}
      eventName={EVENT_NAMES.ROADBLOCK}
      eventId={EVENT_IDS.ROADBLOCK}
      eventSource={partnerId || EVENT_SOURCE.SBIP2}
      roadblock={eventLabel}
      pageName={`${EVENT_SOURCE.SBIP2}:${LOB.PERSONAL}:${EVENT_SOURCE_DETAILS.LOGIN}:${eventLabel}`}
    >
      <div className="account-locked">
        <Error
          locale={locale}
          errorIcon="LockedColor"
          title={intl.formatMessage({ id: 'text.message.accountlocked.sorry' })}
          subtitle={
            <Text className="account-locked__subtitle">{warningMsg}</Text>
          }
          cards={
            shouldShowCard && [
              {
                className: 'me-lockedAccount__card',
                content: (
                  <>
                    <Text className="me-lockedAccount__title">
                      {intl.formatMessage({
                        id: 'text.message.accountlocked.card',
                      })}
                    </Text>

                    <Link
                      href={`tel:${assistanceNumber}`}
                      className="me-lockedAccount__phoneLink"
                    >
                      <Phone
                        size="medium"
                        className="me-lockedAccount__phoneIcon"
                      />
                      {assistanceNumber}
                    </Link>
                  </>
                ),
              },
            ]
          }
          button={{
            text: intl.formatMessage({
              id: 'text.message.accountlocked.button',
            }),
            buttonProps: {
              onClick: returnToLogin,
              id: 'back-to-login',
            },
          }}
          customLogo={partnerId ? <WhiteLabelBrand /> : null}
        />
      </div>
      <TrackState />
    </Tagging>
  );
};

export default AccountLocked;
