import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';

import Header from '../../../organisms/rewardsWhiteLabel/Header';
import MultiFactorAuthChoice from '../../../organisms/common/MultiFactorAuthChoice';
import MultiFactorAuthFooterContent from '../../../organisms/common/MultiFactorAuthFooter';
import Footer from '../../../organisms/common/Footer';

const footerComponent = (
  <Footer content={<MultiFactorAuthFooterContent isDisableResendFooter />} />
);

const MultiFactorAuthChoiceTemplate = () => (
  <FullScreenFooterTemplate
    className="rewards-wl-mfa-choice"
    header={<Header hasSwitchLang={false} />}
    main={<MultiFactorAuthChoice />}
    footer={footerComponent}
  />
);

export default MultiFactorAuthChoiceTemplate;
