import { wlClasses } from '../../utils/partnerUtils';
import { addClassBody } from '../../utils/domUtils';

function watchSetCurrentTemplate(action) {
  const { partnerId } = action;
  // Add body class depending on partnerId
  wlClasses(partnerId).forEach(className => addClassBody(className));
}

export default {
  watchSetCurrentTemplate,
};
