import React from 'react';
import PropTypes from 'prop-types';

import TEMPLATES from '../../utils/constants/template';
import { getTemplateComponent } from '../../utils/templateUtils';

import NatgoNotFoundTemplate from '../../templates/natgo/NotFound';
import Sbip2NotFoundTemplate from '../../templates/sbip2/NotFound';
import SecureKeyNotFoundTemplate from '../../templates/secureKey/NotFound';
import WhiteLabelNotFoundTemplate from '../../templates/whiteLabel/NotFound';
import RewardsNotFoundTemplate from '../../templates/rewards/NotFound';
import RewardsWhiteLabelNotFoundTemplate from '../../templates/rewardsWhiteLabel/NotFound';
import BaoNotFoundTemplate from '../../templates/bao/NotFound';

const propTypes = {
  templateName: PropTypes.string,
};

const defaultProps = {
  templateName: '',
};

function NotFound(props) {
  const { templateName } = props;

  const notFoundTemplates = {
    [TEMPLATES.SBIP2]: <Sbip2NotFoundTemplate />,
    [TEMPLATES.OBE]: <Sbip2NotFoundTemplate />,
    [TEMPLATES.NATGO]: <NatgoNotFoundTemplate />,
    [TEMPLATES.SECURE_KEY]: <SecureKeyNotFoundTemplate />,
    [TEMPLATES.SBIP2_WHITE_LABEL]: <WhiteLabelNotFoundTemplate />,
    [TEMPLATES.REWARDS]: <RewardsNotFoundTemplate />,
    [TEMPLATES.REWARDS_WHITE_LABEL]: <RewardsWhiteLabelNotFoundTemplate />,
    [TEMPLATES.BAO]: <BaoNotFoundTemplate />,
  };
  return getTemplateComponent(notFoundTemplates, templateName);
}

NotFound.propTypes = propTypes;
NotFound.defaultProps = defaultProps;

export default NotFound;
