// @flow
import * as actionTypes from './actionTypes';

import type { FormType, LoginFormSteps } from '../../types/constants';
import type { LoginFormErrors } from '../../types/forms/loginForm';

import type {
  LoginFormChangeStepRequestAction,
  LoginFormChangeStepSuccessAction,
  LoginFormInitFormAction,
  LoginFormInitFormDoneAction,
  LoginFormResetAction,
  LoginFormSubmitFailureAction,
  LoginFormTriggerSubmitAction,
  LoginFormTriggerValidationAction,
  LoginFormUpdateAction,
  LoginFormValidationFailureAction,
  LoginFormValidationSuccessAction,
} from './types';

export function formUpdate(
  fieldName: string,
  fieldValue: string | boolean,
): LoginFormUpdateAction {
  return {
    type: actionTypes.LOGIN_FORM_UPDATE,
    fieldName,
    fieldValue,
  };
}

export function triggerValidation(
  fieldName: string,
): LoginFormTriggerValidationAction {
  return {
    type: actionTypes.LOGIN_FORM_VALIDATION_REQUEST,
    fieldName,
  };
}

export function validationSuccess(
  fieldName: string,
  isValid: boolean,
): LoginFormValidationSuccessAction {
  return {
    type: actionTypes.LOGIN_FORM_VALIDATION_SUCCESS,
    fieldName,
    isValid,
  };
}

export function validationFailure(
  fieldName: string,
  errors: LoginFormErrors,
): LoginFormValidationFailureAction {
  return {
    type: actionTypes.LOGIN_FORM_VALIDATION_FAILURE,
    fieldName,
    errors,
  };
}

export function triggerSubmit(): LoginFormTriggerSubmitAction {
  return {
    type: actionTypes.LOGIN_FORM_SUBMIT_REQUEST,
  };
}

export function submitFailure(
  errors: LoginFormErrors,
): LoginFormSubmitFailureAction {
  return {
    type: actionTypes.LOGIN_FORM_SUBMIT_FAILURE,
    errors,
  };
}

export function initForm(formId: FormType): LoginFormInitFormAction {
  return {
    type: actionTypes.LOGIN_FORM_INIT_REQUEST,
    formId,
  };
}

export function initFormDone(payload: any): LoginFormInitFormDoneAction {
  return {
    type: actionTypes.LOGIN_FORM_INIT_DONE,
    payload,
  };
}

export function formReset(): LoginFormResetAction {
  return {
    type: actionTypes.LOGIN_FORM_RESET,
  };
}

export function changeStepRequest(
  nextStep: LoginFormSteps,
  hasToFocus: boolean = false,
): LoginFormChangeStepRequestAction {
  return {
    type: actionTypes.LOGIN_FORM_CHANGE_STEP_REQUEST,
    nextStep,
    hasToFocus,
  };
}

export function changeStepSuccess(
  step: LoginFormSteps,
): LoginFormChangeStepSuccessAction {
  return {
    type: actionTypes.LOGIN_FORM_CHANGE_STEP_SUCCESS,
    step,
  };
}
