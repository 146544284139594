import { put } from 'redux-saga/effects';
import { formUpdate, triggerSubmit } from '../loginFormService/actions';
import Biometry from './biometry';
import {
  BIOMETRY_SUCCESS,
  BIOMETRY_FAILED,
  BIOMETRY_THREE_ATTEMPTS_FAILS,
  BIOMETRY_CANCEL,
} from './constants';

import type {
  BiometryValidationEventAction,
  BiometryValidationSuccessAction,
  BiometryLaunchCredentialsSynchronization,
} from './types';

import {
  biometryValidationError,
  biometryValidationSuccess,
  biometryCredentialsDeletionSuccess,
  biometryCredentialsDeletionFailed,
  initBiometrySuccess,
} from './actions';
import { redirectToLogin } from './utils';
import { updateBiometricCredentials } from './webviewActions';

export function* watchBiometryInitialization() {
  yield put(initBiometrySuccess(Biometry.parse()));
}

export function* watchBiometryInitializationSuccess({ biometry }) {
  yield put(formUpdate('identityBne', biometry.user));
  yield put(formUpdate('identity', biometry.user));
}

export function* watchBiometryValidationResult(
  action: BiometryValidationEventAction,
) {
  const { payload } = action;
  switch (payload.validationResults) {
    case BIOMETRY_SUCCESS:
      yield put(biometryValidationSuccess(payload.retrievedCredentials));
      break;
    case BIOMETRY_FAILED:
    case BIOMETRY_THREE_ATTEMPTS_FAILS:
      yield put(biometryValidationError(payload.error));
      break;
    case BIOMETRY_CANCEL:
    default:
      console.warn(`No action required on ${payload.validationResults} event`);
  }
}

export function* watchBiometryValidationSuccess(
  action: BiometryValidationSuccessAction,
) {
  yield put(formUpdate('passwordBne', action.credentials));
  yield put(formUpdate('password', action.credentials));
  yield put(triggerSubmit());
}

export function* watchForCredentialsDeletionResult(
  action: BiometryValidationEventAction,
) {
  if (action.payload.results === BIOMETRY_SUCCESS) {
    yield put(formUpdate('identityBne', ''));
    yield put(formUpdate('passwordBne', ''));
    yield put(formUpdate('identity', ''));
    yield put(formUpdate('password', ''));
    yield put(biometryCredentialsDeletionSuccess());
  } else {
    yield put(biometryCredentialsDeletionFailed());
  }
}

export function* watchForcePasswordLogin() {
  yield put(formUpdate('identityBne', ''));
  yield put(formUpdate('identity', ''));
  yield put(formUpdate('passwordBne', ''));
  yield put(formUpdate('password', ''));
  redirectToLogin();
}

export function* watchCredentialsSynchronization(
  action: BiometryLaunchCredentialsSynchronization,
) {
  yield updateBiometricCredentials(action.username, action.password);
}
