import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import LoginBiometry from './loginBiometry';

import { getTemplateName } from '../../services/templateService/selectors';

function mapStateToProps(state) {
  return {
    templateName: getTemplateName(state),
  };
}

export default injectIntl(connect(mapStateToProps)(LoginBiometry));
