import toggleFeatureApi from 'bnc-react-components/dist/api/toggleFeatureApi';
import { CONFIGS_NAMES } from '../../utils/constants/configs';
import type { RetrieveConfigsSuccessAction } from './types';

export default function isAppUnavailable(action: RetrieveConfigsSuccessAction) {
  const { configs } = action;
  return (
    toggleFeatureApi.isAvailable(
      configs,
      CONFIGS_NAMES.GLOBAL.UNAVAILABLE_MODE,
      [],
    ) ||
    toggleFeatureApi.isAvailable(
      configs,
      CONFIGS_NAMES.GLOBAL.MAINTENANCE_MODE,
      [],
    )
  );
}
