// @flow
import React, { useEffect } from 'react';
import cx from 'classnames';
import { FACTOR_TYPES } from 'bnc-react-services/managers/AuthManager/constants';

import { Heading } from '@bnc-ui/heading';
import { Text } from '@bnc-ui/text';

import OtcResendCounter from '../../../molecules/bne/OtcResendCounter';
import ResendMFACode from '../../../molecules/bne/ResendMFACode';

import './style.scss';

type Props = {
  intl: intlShape,
  className?: string,
  triggerCodeRequest: (factor: string) => void,
  factors: Array<*>,
  countdownMfa: number,
  selectedFactor: string,
  hideFooter?: Boolean,
};

const defaultProps = {
  className: '',
  hideFooter: true,
};

const MultiFactorAuthFooter = (props: Props) => {
  const {
    intl,
    className,
    triggerCodeRequest,
    factors,
    countdownMfa,
    selectedFactor,
    hideFooter,
  } = props;
  const isHideResend = countdownMfa > 0;
  const bottomRef = React.useRef();

  useEffect(() => {
    if (!hideFooter) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hideFooter]);

  const getMessageToDisplay = () => {
    if (!isHideResend) {
      return intl.formatMessage({
        id: 'text.message.validationCodeResend.subtext.bne',
      });
    }
    if (isHideResend && selectedFactor === FACTOR_TYPES.EMAIL) {
      return intl.formatMessage({
        id: 'text.message.passwordResetIfNotReceived.bne',
      });
    }
    return '';
  };

  return (
    <div id="multiFactorAuthFooter" className={cx('mfa-footer', className)}>
      <Heading type="h1" className="resend-group__title">
        {intl.formatMessage({ id: 'text.message.validationCodeResend.bne' })}
      </Heading>
      <Text>{getMessageToDisplay()}</Text>
      <ResendMFACode
        triggerCodeRequest={triggerCodeRequest}
        isHideResend={isHideResend}
        factors={factors}
      />
      <OtcResendCounter countdownSeconds={countdownMfa} />
      <div ref={bottomRef} />
    </div>
  );
};

MultiFactorAuthFooter.defaultProps = defaultProps;

export default MultiFactorAuthFooter;
