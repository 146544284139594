import isTokenValid from '../tokenUtils';

describe('isTokenValid', () => {
  it('should return false if token is null', () => {
    expect(isTokenValid(null)).toBe(false);
  });

  it("should return false if token doesn't have accessToken", () => {
    const token = {
      expirationTime: 'something',
      scope: 'something',
    };
    expect(isTokenValid(token)).toBe(false);
  });

  it("should return false if token doesn't have expirationTime", () => {
    const token = {
      accessToken: 'something',
      scope: 'something',
    };
    expect(isTokenValid(token)).toBe(false);
  });

  it("should return false if token doesn't have scope", () => {
    const token = {
      accessToken: 'something',
      expirationTime: 'something',
    };
    expect(isTokenValid(token)).toBe(false);
  });

  it('should return false if token expirationTime before new Date', () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const token = {
      accessToken: 'something',
      expirationTime: date,
      scope: 'something',
    };
    expect(isTokenValid(token)).toBe(false);
  });

  it('should return true', () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    const token = {
      accessToken: 'something',
      expirationTime: date,
      scope: 'something',
    };
    expect(isTokenValid(token)).toBe(true);
  });
});
