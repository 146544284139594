// @flow
import mergeDeep from 'bnc-utilities-js/object/mergeDeep';

function loadI18nFiles() {
  // $FlowFixMe Cannot call require.context because property context is missing. Because we overcharged require with require.context module
  const messageFiles = require.context(
    './',
    true,
    /(i18n\/messages.json$|globalMessages.json$|authErrorMessages.json)/,
  );
  const i18nJsonFile = messageFiles.keys().reduce((memo, file) => {
    const jsonContent = require(`${file}`);

    return mergeDeep(memo, jsonContent);
  }, {});

  return i18nJsonFile;
}

export default loadI18nFiles;
