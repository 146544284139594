import React from 'react';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import { MobileCodeColor } from '@nbc-design/icons/lib/web/MobileCodeColor';
import { FACTOR_TYPES } from 'bnc-react-services/managers/AuthManager/constants';
import { Envelope } from '@nbc-design/icons/lib/web/Envelope';
import { PhoneSms } from '@nbc-design/icons/lib/web/PhoneSms';
import { Telephone } from '@nbc-design/icons/lib/web/Telephone';
import { EmailCodeColor } from '@nbc-design/icons/lib/web/EmailCodeColor';
import { VoiceCodeColor } from '@nbc-design/icons/lib/web/VoiceCodeColor';
import { SecurIdTokenHiglightColor } from '@nbc-design/icons/lib/web/SecurIdTokenHiglightColor';

import { LANG } from './forgotPasswordUtils/constants';

export const getFactorIcon = (factor, isResend) => {
  switch (lowerCase(factor)) {
    case FACTOR_TYPES.EMAIL:
      return !isResend ? (
        <Envelope size="large" title="email" />
      ) : (
        <EmailCodeColor size="large" title="email-code-color" />
      );
    case FACTOR_TYPES.SMS:
      return !isResend ? (
        <PhoneSms size="large" title="phone-sms" />
      ) : (
        <MobileCodeColor size="large" title="mobile-code-color" />
      );
    case FACTOR_TYPES.CALL:
      return !isResend ? (
        <Telephone size="large" title="telephone" />
      ) : (
        <VoiceCodeColor size="large" title="voice-code-color" />
      );
    case FACTOR_TYPES.TOKEN:
      return (
        <SecurIdTokenHiglightColor
          size="large"
          title="secur-id-token-higlight-color"
        />
      );
    default:
      return null;
  }
};

const formatPhoneNumber = (unparsedPhoneNumber, locale) => {
  if (unparsedPhoneNumber) {
    const phoneNumber = unparsedPhoneNumber.replace(/[-,\s,+]/g, '');
    const dash = '-';
    const space = ' ';
    const separator = LANG.FR === locale ? space : dash;

    if (phoneNumber.length === 11) {
      return `${phoneNumber.substring(1, 4)}${separator}${phoneNumber.substring(
        4,
        7,
      )}${dash}${phoneNumber.substring(7, 11)}`;
    }
    if (phoneNumber.length === 10) {
      return `${phoneNumber.substring(0, 3)}${separator}${phoneNumber.substring(
        3,
        6,
      )}${dash}${phoneNumber.substring(6, 10)}`;
    }
  }
  return unparsedPhoneNumber;
};

export const formatFactorValue = (factorType, value, locale) => {
  switch (factorType) {
    case FACTOR_TYPES.SMS:
      return formatPhoneNumber(value, locale);
    case FACTOR_TYPES.CALL:
      return getFullPhoneNumber(value, locale);
    default:
      return value;
  }
};

export const getFactorTarget = (factor, locale) => {
  // Login flow
  if (get(factor, 'profile'))
    switch (factor.factorType) {
      case FACTOR_TYPES.SMS:
        return formatPhoneNumber(factor.profile.phoneNumber, locale);
      case FACTOR_TYPES.EMAIL:
        return factor.profile.email;
      case FACTOR_TYPES.CALL:
        return getFullPhoneNumber(factor.profile, locale);
      default:
        return FACTOR_TYPES.UNKNOWN;
    }
  // Forgot password flow
  return factor.value;
};

const getFullPhoneNumber = (phoneNumberTarget, locale) => {
  let phoneNumber;
  if (typeof phoneNumberTarget === 'string') {
    phoneNumber = formatPhoneNumber(phoneNumberTarget, locale);
  } else {
    phoneNumber = formatPhoneNumber(phoneNumberTarget.phoneNumber, locale);
  }
  if (
    phoneNumberTarget.phoneExtension === null ||
    phoneNumberTarget.phoneExtension === undefined ||
    phoneNumberTarget.phoneExtension === ''
  ) {
    return phoneNumber;
  }
  const { phoneExtension } = phoneNumberTarget;
  return `${phoneNumber} #${phoneExtension}`;
};

export const getBneFactorIcon = (factor, isResend) => {
  switch (lowerCase(factor)) {
    case FACTOR_TYPES.EMAIL:
      return !isResend ? (
        <Envelope size="large" title="email" />
      ) : (
        <EmailCodeColor size="large" title="email-code-color" />
      );
    case FACTOR_TYPES.SMS:
      return !isResend ? (
        <PhoneSms size="large" title="phone-sms" />
      ) : (
        <MobileCodeColor size="large" title="mobile-code-color" />
      );
    case FACTOR_TYPES.CALL:
      return !isResend ? (
        <Telephone size="large" title="telephone" />
      ) : (
        <VoiceCodeColor size="large" title="voice-code-color" />
      );
    case FACTOR_TYPES.TOKEN:
      return (
        <SecurIdTokenHiglightColor
          size="large"
          title="secur-id-token-higlight-color"
        />
      );
    default:
      return null;
  }
};

export const orderFactors = factors => {
  return factors.sort((x, y) => {
    if (lowerCase(x.factorType) === FACTOR_TYPES.SMS) {
      return -1;
    }
    if (lowerCase(x.factorType) === FACTOR_TYPES.EMAIL) {
      if (lowerCase(y.factorType) === FACTOR_TYPES.SMS) {
        return 1;
      }
      return -1;
    }
    return 1;
  });
};
