// @flow
import React, { useEffect } from 'react';
import type { intlShape } from 'react-intl';
import cx from 'classnames';

import { Heading } from '@bnc-ui/heading';
import { Alert } from '@bnc-ui/alert';
import { FailureFilledXsmallColor } from '@nbc-design/icons/lib/web/FailureFilledXsmallColor';

import type { SDKError } from '../../../types';
import { queueAnnouncer } from '../../../utils/liveAnnouncer/helper';
import BackLinkButton from '../../../molecules/common/BackLinkButton';
import SendMFACode from '../../../molecules/common/SendMFACode';
import { MFA_STEPS } from '../../../utils/taggingUtils/constants';
import MultiFactorAuthTaggingWrapper from '../MultiFactorAuth/MultiFactorAuthTaggingWrapper/MultiFactorAuthTaggingWrapper';

import './style.scss';

type Props = {
  intl: intlShape,
  returnToLogin: () => void,
  sdkError: SDKError,
  getAuthErrorMessageId: ({}) => string,
  announceMessage?: string => void,
  triggerCodeRequest: (factor: *) => void,
  factors: Array<*>,
  isFormDone?: boolean,
  isForgotPasswordFlow?: boolean,
  forgotPasswordFlowId?: string,
  partnerIdName?: string,
};

const defaultProps = {
  announceMessage: () => {},
  isFormDone: false,
  isForgotPasswordFlow: false,
  forgotPasswordFlowId: '',
  partnerIdName: '',
};

const MultiFactorAuthChoice = (props: Props) => {
  const {
    className,
    intl,
    returnToLogin,
    sdkError,
    getAuthErrorMessageId,
    announceMessage,
    triggerCodeRequest,
    factors,
    isFormDone,
    isForgotPasswordFlow,
    forgotPasswordFlowId,
    partnerIdName,
  } = props;

  useEffect(() => {
    if (sdkError.code) {
      queueAnnouncer(
        [
          intl.formatMessage(
            { id: `auth.error.${getAuthErrorMessageId(sdkError)}` },
            { code: sdkError.code },
          ),
        ],
        announceMessage,
      );
    }
  }, [sdkError, getAuthErrorMessageId, intl, announceMessage]);

  return (
    <MultiFactorAuthTaggingWrapper
      sdkError={sdkError}
      locale={intl.locale}
      isLoading={isFormDone}
      step={MFA_STEPS.SEND_SECURITY_CODE}
      isForgotPasswordFlow={isForgotPasswordFlow}
      forgotPasswordFlowId={forgotPasswordFlowId}
      partnerIdName={partnerIdName}
    >
      <div
        id="multiFactorAuthChoice-common"
        className={cx('connect-form mfa', className)}
      >
        <Heading type="h1" size={1}>
          {intl.formatMessage({ id: 'text.title.validationCode' })}
        </Heading>

        {!!sdkError.code && (
          <Alert
            className="send-error-message"
            title={intl.formatMessage(
              { id: `auth.error.${getAuthErrorMessageId(sdkError)}` },
              { code: sdkError.code },
            )}
            appearance="error"
            size="small"
            icon={<FailureFilledXsmallColor size="small" title="Error" />}
          />
        )}

        <Heading type="h2" size={1}>
          {intl.formatMessage({ id: 'multiFactorAuthChoice.title.choice' })}
        </Heading>

        <SendMFACode
          triggerCodeRequest={triggerCodeRequest}
          factors={factors}
        />

        <BackLinkButton
          label={intl.formatMessage({
            id: 'global.button.backToLogin.bne',
          })}
          className="back-link-button"
          onClick={returnToLogin}
        />
      </div>
    </MultiFactorAuthTaggingWrapper>
  );
};

MultiFactorAuthChoice.defaultProps = defaultProps;

export default MultiFactorAuthChoice;
