// @flow
import React from 'react';
import cx from 'classnames';
import type { IntlShape } from 'react-intl';

import { Logo } from 'bnc-react-components';

import { getMessageKey } from '../../../utils/templateUtils';

import './style.scss';

type Props = {
  intl: IntlShape,
  className?: string,
  isVisible?: boolean,
  logoTagName?: string,
  locale: string,
  templateName: string,
  hasTagBrand?: boolean,
};

const defaultProps = {
  className: '',
  isVisible: true,
  logoTagName: 'div',
  hasTagBrand: true,
};

function BncBrand(props: Props) {
  const {
    intl,
    className,
    isVisible,
    logoTagName,
    locale,
    templateName,
    hasTagBrand,
  } = props;

  const customerAreaKey = getMessageKey(
    templateName,
    'text.title.customerArea',
  );

  if (!isVisible) {
    return null;
  }

  return (
    <div className={cx('bnc-brand', locale, className)}>
      <div
        className={cx('bnc-brand__logo', {
          'bnc-brand__logo--big': !hasTagBrand,
        })}
      >
        <Logo
          tagName={logoTagName}
          title={intl.formatMessage({ id: 'text.title.bncLogo' })}
          locale={intl.locale}
          preserveAspectRatio="xMinYMid meet"
        />
      </div>
      {hasTagBrand && (
        <>
          <span className="bnc-brand__separator" />
          <div className="bnc-brand__tag">
            {intl.formatMessage({ id: customerAreaKey })}
          </div>
        </>
      )}
    </div>
  );
}

BncBrand.defaultProps = defaultProps;

export default BncBrand;
