// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import type { State } from '../../types/state';

import { getTemplateName } from '../../services/templateService/selectors';

import Unavailable from './unavailable';

function mapStateToProps(state: State) {
  return {
    templateName: getTemplateName(state),
  };
}

export default injectIntl(connect(mapStateToProps)(Unavailable));
