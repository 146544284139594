import cx from 'classnames';
import * as React from 'react';
import { Text } from '@bnc-ui/text';
import { NbcColor } from '@nbc-design/icons/lib/web/NbcColor';
import { openWebUrl } from '../../../utils/browserUtils';

import './style.scss';

export interface Props {
  /** An array of object to display links. Optionnal */
  links?: Array<{
    label: string,
    href: string,
    target?: string,
    id: string,
  }>;
}

const defaultProps = {
  links: [],
};

function LoginFooterLinks(props: Props) {
  const { links } = props;

  return (
    <>
      <div className={cx('login-form-footer', links && 'hasLinks')}>
        {links && (
          <nav>
            {links.map(link => (
              <Text
                size="xSmall"
                type="span"
                key={link.id}
                className="footer-link--entreprise"
                onClick={() => openWebUrl(link.href)}
              >
                {link.label}
              </Text>
            ))}
          </nav>
        )}
        <NbcColor
          size="medium"
          title="nbc-color"
          className="login-form-footer__logo"
        />
      </div>
    </>
  );
}

LoginFooterLinks.defaultProps = defaultProps;

export default LoginFooterLinks;
