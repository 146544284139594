import React from 'react';
import get from 'lodash/get';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { Combo } from '@bnc-ui/combo';
import { Radio } from '@bnc-ui/radio';
import { Button } from '@bnc-ui/button';
import { Text } from '@bnc-ui/text';

import {
  buildForgotPasswordChoices,
  getChoicesToShow,
} from '../../../../utils/forgotPasswordUtils';

import {
  FORGOT_PASSWORD_FIELDS,
  MAX_CHOICES_TO_SHOW,
} from '../../../../utils/forgotPasswordUtils/constants';

const propTypes = {
  intl: intlShape.isRequired,
  partnerId: PropTypes.string,
  contextChoice: PropTypes.string.isRequired,
  hasOpenChoices: PropTypes.bool.isRequired,
  templateName: PropTypes.string,
  formUpdate: PropTypes.func.isRequired,
};

const defaultProps = {
  templateName: '',
  partnerId: '',
};

const BankProductChoice = ({
  intl,
  contextChoice,
  hasOpenChoices,
  templateName,
  partnerId,
  formUpdate,
}) => {
  const choicesConfig = buildForgotPasswordChoices(templateName, partnerId);
  const choicesToShow = getChoicesToShow(choicesConfig, hasOpenChoices);
  const shouldHasLinkForOpenChoices =
    !hasOpenChoices && choicesConfig.length > MAX_CHOICES_TO_SHOW;

  const onInputChange = event =>
    formUpdate(event.target.name, event.target.value);
  const openChoices = () =>
    formUpdate(FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.OPEN_CHOICES, true);

  const radioItems = choicesToShow.map(choiceToShow => ({
    children: (
      <Radio
        label={intl.formatMessage({ id: get(choiceToShow, 'labelMessageKey') })}
        id={get(choiceToShow, 'id')}
        name={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PRODUCT_TYPE}
        value={get(choiceToShow, 'value')}
        checked={contextChoice === get(choiceToShow, 'value')}
        onChange={onInputChange}
      />
    ),
  }));

  const bankProductKey = partnerId
    ? `text.title.forgotPassword.${partnerId}.bankProduct`
    : 'text.title.forgotPassword.bankProduct';

  return (
    <div className="forgot-password-form--group__choices">
      <Text size="normal">{intl.formatMessage({ id: bankProductKey })}</Text>
      <Combo id="identification-choices" type="form" items={radioItems} />
      {shouldHasLinkForOpenChoices && (
        <Button
          onClick={openChoices}
          size="small"
          tabIndex={0}
          appearance="link"
        >
          {intl.formatMessage({ id: 'text.label.forgotPassword.others' })}
        </Button>
      )}
    </div>
  );
};

BankProductChoice.defaultProps = defaultProps;
BankProductChoice.propTypes = propTypes;

export default BankProductChoice;
