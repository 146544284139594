// @flow
import { put, select, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { formatShortDate } from 'bnc-utilities-js/date';
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';
import { announceAlertMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';

import {
  fieldValidationSuccess,
  fieldValidationFailure,
  initFormDone,
  formValidationSuccess,
  formValidationFailure,
  resetRecaptcha,
} from './actions';
import * as actionTypes from './actionTypes';
import { formDataSelector, formIdSelector } from './selectors';

import {
  reset,
  setBackendError,
  validateUserRequest,
} from '../forgotPasswordService/actions';
import { fieldValidation, formValidation } from './formSchema';
import { focusOnFirstErrorField } from '../formHandlerService/actions';
import { CONTEXT_IDS } from '../../utils/constants/forgotPassword';

import type {
  ForgotPasswordFormTriggerFieldValidationAction,
  ForgotPasswordFormInitFormAction,
} from './types';

import { isTokenStillValid } from '../../atoms/BncRecaptcha/utils';
import {
  FORGOT_PASSWORD_FIELDS,
  FORGOT_PASSWORD_PRODUCT_TYPE,
} from '../../utils/forgotPasswordUtils/constants';
import { buildExpiryDate, buildPhoneNumber } from '../../utils/formUtils';
import TEMPLATES from '../../utils/constants/template';
import {
  getPartnerIdName,
  getTemplateName,
} from '../templateService/selectors';
import {
  FORGOT_PASSWORD_CODE_FORM_ERROR,
  FORGOT_PASSWORD_PREFIX_VERIFY_USER,
} from '../../utils/authErrorMessages';

/**
 * Field Validation
 * action.type = FORGOT_PASSWORD_FORM_FIELD_VALIDATION_REQUEST
 * @param {*} action
 */
function* watchFieldValidation(
  action: ForgotPasswordFormTriggerFieldValidationAction,
): Saga<void> {
  const formData = yield select(formDataSelector);
  const validationResult = yield call(fieldValidation, formData, action);
  if (validationResult.fieldValid) {
    yield put(
      fieldValidationSuccess(action.fieldName, validationResult.formValid),
    );
  } else {
    yield put(
      fieldValidationFailure(action.fieldName, validationResult.errorField),
    );
    yield put(announceAlertMessage('text.error.invalidIdentifier'));
  }
}

function* watchFormValidation(): Saga<void> {
  const formData = yield select(formDataSelector);
  const formId = yield select(formIdSelector);

  // BY-PASS FORM VALIDATION (HHANH ಠ_ಠ) - ForgotPasswordMockMode
  // yield put(formValidationSuccess());
  // return;

  const validationResult = yield call(formValidation, formData);

  if (!validationResult.formValid) {
    yield put(
      focusOnFirstErrorField(formId, validationResult.formattedErrors, true),
    );
    yield put(formValidationFailure(validationResult.formattedErrors));
    yield put(
      setBackendError({
        errorIdPrefix: FORGOT_PASSWORD_PREFIX_VERIFY_USER,
        code: FORGOT_PASSWORD_CODE_FORM_ERROR,
      }),
    );
  } else if (
    !(yield call(
      isTokenStillValid,
      formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA],
    ))
  ) {
    yield put(resetRecaptcha());
  } else {
    yield put(formValidationSuccess());
  }
}

function* watchFormValidationSuccess(): Saga<void> {
  const formData = yield select(formDataSelector);

  const partnerId = yield select(getPartnerIdName);
  const templateName = yield select(getTemplateName);
  const productType =
    formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PRODUCT_TYPE];

  const contextId = getContextId(templateName, partnerId, productType);
  const tokenRecaptcha =
    formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA];
  const dateOfBirth = formatShortDate(
    formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE],
  );
  const cardNumber =
    formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER];
  const email = formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL];
  const expiryDate = buildExpiryDate(
    formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE],
  );
  const accountNumber =
    formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER];
  const originalAmount =
    formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT];
  const phone = buildPhoneNumber(
    formData[FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER],
  );

  yield put(
    validateUserRequest({
      productType,
      contextId,
      tokenRecaptcha,
      dateOfBirth,
      cardNumber,
      email,
      expiryDate,
      accountNumber,
      originalAmount,
      phone,
    }),
  );
}

function getContextId(templateName, partnerId, productType) {
  if (templateName === TEMPLATES.NATGO) {
    return CONTEXT_IDS.NATGO;
  }
  if (productType === FORGOT_PASSWORD_PRODUCT_TYPE.INVESTMENT_BROKERAGE) {
    return undefined;
  }

  switch (partnerId) {
    case 'gwl':
      return CONTEXT_IDS.GWL;
    case 'f55':
      return CONTEXT_IDS.F55;
    case 'investorsgroup':
      return CONTEXT_IDS.INVESTORS_GROUP;
    case 'partnership':
      return CONTEXT_IDS.PARTNERSHIP;
    default:
      return CONTEXT_IDS.SBIP;
  }
}

/**
 * Form Init
 * action.type = FORGOT_PASSWORD_FORM_INIT_REQUEST
 */
function* watchFormInit(action: ForgotPasswordFormInitFormAction): Saga<void> {
  yield put(initFormDone(action.defaultValues));
}

function* watchResetForm() {
  yield put(reset());
}

const workersMapping = createWorkersMapping()
  .takeLatest(
    actionTypes.FORGOT_PASSWORD_FORM_FIELD_VALIDATION_REQUEST,
    watchFieldValidation,
  )
  .takeLatest(
    actionTypes.FORGOT_PASSWORD_FORM_VALIDATION_REQUEST,
    watchFormValidation,
  )
  .takeLatest(actionTypes.FORGOT_PASSWORD_FORM_INIT_REQUEST, watchFormInit)
  .takeLatest(actionTypes.FORGOT_PASSWORD_FORM_RESET, watchResetForm)
  .takeLatest(
    actionTypes.FORGOT_PASSWORD_FORM_VALIDATION_SUCCESS,
    watchFormValidationSuccess,
  );

export default createRootSagaFromWorkersMapping(workersMapping);
