// @flow
import React, { useEffect } from 'react';
import cx from 'classnames';
import get from 'lodash/get';

import { Heading } from '@bnc-ui/heading';
import { UnsupportedBrowserIe11Color } from '@nbc-design/icons/lib/web/UnsupportedBrowserIe11Color';
import { UnsupportedBrowserColor } from '@nbc-design/icons/lib/web/UnsupportedBrowserColor';
import { Text } from '@bnc-ui/text';

import { Logo } from 'bnc-react-components';

import type { IntlShape } from 'react-intl';

import { Browser, SupportedBrowserList } from '../../../types/browser';

import './style.scss';
import WhiteLabelBrand from '../../../molecules/whiteLabel/WhiteLabelBrand';
import SwitchLang from '../../../molecules/common/SwitchLang';
import { IE11_BROWSER_NAME } from '../../../utils/browserUtils';
import { shouldShowIE11Page } from '../../../services/unsupportedBrowserService/helper';
import { Configs } from '../../../configs';
import { focusOnComponent } from '../../../utils/domUtils';

type Props = {
  className?: string,
  partnerId?: string,
  intl: IntlShape,
  browser: Browser,
  browserVersions: SupportedBrowserList,
};

const defaultProps = {
  className: '',
  partnerId: '',
};

function UnsupportedBrowserHeader(props: Props) {
  const { className, intl, browser, browserVersions, partnerId } = props;

  useEffect(() => {
    focusOnComponent('.unsupported-browser-header__title');
  });

  const renderLogoImage = () => {
    const classLogo =
      partnerId && partnerId !== 'partnership'
        ? 'logo-wl'
        : 'logo-national-bank';
    return (
      <div className={cx(classLogo)}>
        {partnerId ? <WhiteLabelBrand /> : <Logo locale={intl.locale} />}
      </div>
    );
  };

  const renderBrowserMessageText = () => {
    if (
      browser.name === IE11_BROWSER_NAME &&
      shouldShowIE11Page(browser.name, browser.version)
    ) {
      const deadlineDate = get(
        Configs,
        `NO_HOMOLOGATION_IE11.IE11_ENDED_SUPPORT_TEXT.${intl.locale.toUpperCase()}`,
      );
      const suffix =
        partnerId && partnerId !== 'partnership' ? `.${partnerId}` : '';
      return intl.formatMessage(
        { id: `text.message.browserUnsupported.ie11${suffix}` },
        { deadlineDate },
      );
    }
    return browserVersions[browser.name]
      ? intl.formatMessage({ id: 'text.message.browserOutdated' })
      : intl.formatMessage({ id: 'text.message.browserUnsupported' });
  };

  const renderBrowserHeadingText = () => {
    if (
      browser.name === IE11_BROWSER_NAME &&
      shouldShowIE11Page(browser.name, browser.version)
    ) {
      return intl.formatMessage({ id: 'text.title.browserUnsupported.ie11' });
    }
    return browserVersions[browser.name]
      ? intl.formatMessage({ id: 'text.title.browserOutdated' })
      : intl.formatMessage({ id: 'text.title.browserUnsupported' });
  };

  const getLogo = () => {
    if (
      browser.name === IE11_BROWSER_NAME &&
      shouldShowIE11Page(browser.name, browser.version)
    ) {
      return (
        <UnsupportedBrowserIe11Color
          className="ie11-dialog__icon--warning"
          title="unsupported-browser-ie-11-color"
        />
      );
    }
    return (
      <UnsupportedBrowserColor
        className="ie11-dialog__icon--warning"
        title="unsupported-browser-ie-11-color"
      />
    );
  };

  return (
    <div className={cx('unsupported-browser-header', className)}>
      <SwitchLang name="lang-switch" isVisible />
      {renderLogoImage()}
      {getLogo()}
      <div>
        <Heading
          type="h1"
          className="unsupported-browser-header__title"
          tabIndex={0}
        >
          {renderBrowserHeadingText()}
        </Heading>
      </div>
      <div className="unsupported-browser-header__message">
        <Text size="paragraph" tabIndex={0}>
          {renderBrowserMessageText()}
        </Text>
      </div>
    </div>
  );
}

UnsupportedBrowserHeader.defaultProps = defaultProps;

export default UnsupportedBrowserHeader;
