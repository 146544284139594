import { getAuthErrorMessageId } from '..';
import {
  SPECIFIC_MESSAGES_ARRAY,
  GENERIC_WITHOUT_CODE,
  GENERIC_WITH_CODE,
  ERROR_CODE_KEY,
} from '../constants';

describe('getAuthErrorMessageId - SPECIFIC_MESSAGES_ARRAY errors', () => {
  SPECIFIC_MESSAGES_ARRAY.map(value => ({
    error: { [ERROR_CODE_KEY]: value },
    result: value,
  })).forEach(({ error, result }) => {
    it(`Should return '${result}' for error { ${ERROR_CODE_KEY}: ${error[ERROR_CODE_KEY]} }`, () => {
      expect(getAuthErrorMessageId(error)).toEqual(result);
    });
  });
});

describe('getAuthErrorMessageId - no error code found', () => {
  const notErrorObject = 'NOT_AN_ERROR_OBJECT';
  it(`Should return '${GENERIC_WITHOUT_CODE}' for error ${notErrorObject}`, () => {
    expect(getAuthErrorMessageId(notErrorObject)).toEqual(GENERIC_WITHOUT_CODE);
  });
});

describe('getAuthErrorMessageId - error not in SPECIFIC_MESSAGES_ARRAY', () => {
  const errorNotInArray = { [ERROR_CODE_KEY]: '999999' };
  it(`Should return '${GENERIC_WITH_CODE}' for error { ${ERROR_CODE_KEY}: ${errorNotInArray[ERROR_CODE_KEY]} }`, () => {
    expect(getAuthErrorMessageId(errorNotInArray)).toEqual(GENERIC_WITH_CODE);
  });
});
