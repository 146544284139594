import isLoginAlertEnable from '../selectors';

describe('isLoginAlertEnable', () => {
  it('should be true when client is BNC and config enable', () => {
    const state = {
      configsServiceReducer: {
        configs: {
          status: {
            login: {
              options: {
                alert: {
                  active: true,
                },
              },
            },
          },
        },
      },
      templateServiceReducer: {
        partnerId: null,
      },
    };
    expect(isLoginAlertEnable(state)).toEqual(true);
  });

  it('should be false when config is disable', () => {
    const state = {
      configsServiceReducer: {
        configs: {
          status: {
            login: {
              options: {
                alert: {
                  active: false,
                },
              },
            },
          },
        },
      },
      templateServiceReducer: {
        partnerId: null,
      },
    };
    expect(isLoginAlertEnable(state)).toEqual(false);
  });

  it('should be false there is no config setting', () => {
    const state = {
      configsServiceReducer: {
        configs: {
          status: {},
        },
      },
      templateServiceReducer: {
        partnerId: null,
      },
    };
    expect(isLoginAlertEnable(state)).toEqual(false);
  });

  it('should be false when client is not BNC', () => {
    const state = {
      configsServiceReducer: {
        configs: {
          status: {
            login: {
              options: {
                alert: {
                  active: true,
                },
              },
            },
          },
        },
      },
      templateServiceReducer: {
        partnerId: 'ABCD',
      },
    };
    expect(isLoginAlertEnable(state)).toEqual(false);
  });
});
