// @flow
import Cookies from 'universal-cookie';
import addToDate from 'bnc-utilities-js/date/addToDate';

import GLOBAL from './constants/global';

const cookiesInstance = new Cookies();

export function get(name: string) {
  return cookiesInstance.get(name);
}

export function set(name: string, value: *, options: *, expiry?: object) {
  cookiesInstance.set(name, value, {
    path: '/',
    expires:
      expiry ?? addToDate(new Date(), { years: GLOBAL.COOKIE_EXPIRES_YEAR }),
    ...options,
  });
}

export function remove(name: string) {
  cookiesInstance.remove(name);
}
