// @flow
import * as cookie from './cookie';
import {
  ACCOUNT_BLOCKED_DELAY,
  LOCKED_USER_COOKIE_NAME,
} from './constants/bneConstants';

export const createUserLockedCookie = () => {
  const expiry = new Date(new Date().getTime() + ACCOUNT_BLOCKED_DELAY);
  cookie.set(LOCKED_USER_COOKIE_NAME, expiry.getTime(), null, expiry);
};

export const getUserLockedCookie = () => {
  return cookie.get(LOCKED_USER_COOKIE_NAME);
};

export const removeUserLockedCookie = () => {
  cookie.remove(LOCKED_USER_COOKIE_NAME);
};
