import getOtcErrorMessage from '../errorMessageUtils';
import type { SDKError } from '../../../types';
import TEMPLATES from '../../constants/template';

jest.mock('../../authErrorMessages', () => ({
  getAuthErrorMessageId: error => error.code,
}));

describe('getOtcErrorMessage', () => {
  const templateName = TEMPLATES.BNE;

  it(`should call intl.formatMessage with french formatted hour param (01h17) when error code is 3107`, () => {
    const locale = 'fr';
    const TIME = '01:17';
    const sdkError: SDKError = {
      code: '3107',
      type: 'random',
      message: 'values',
    };
    const dateError = new Date(Date.UTC(2020, 1, 1, 1, 2));
    const ownPropsIntl = {
      locale,
      formatTime: jest.fn().mockImplementation(date => TIME),
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };
    const result = getOtcErrorMessage(
      templateName,
      sdkError,
      dateError,
      ownPropsIntl,
    );
    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatTime).toHaveBeenCalledWith(
      new Date(dateError.getTime() + 15 * 60000),
    );
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3107.bne',
      },
      {
        // 01:02 + 15 minutes delay
        hour: '01:17',
      },
    );
  });

  it(`should call intl.formatMessage with french formatted hour param (13h17) when error code is 3107`, () => {
    const locale = 'fr';
    const TIME = '13:17';
    const sdkError: SDKError = {
      code: '3107',
      type: 'random',
      message: 'values',
    };
    const dateError = new Date(Date.UTC(2020, 1, 1, 1, 2));
    const ownPropsIntl = {
      locale,
      formatTime: jest.fn().mockImplementation(date => TIME),
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };
    const result = getOtcErrorMessage(
      templateName,
      sdkError,
      dateError,
      ownPropsIntl,
    );
    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatTime).toHaveBeenCalledWith(
      new Date(dateError.getTime() + 15 * 60000),
    );
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3107.bne',
      },
      {
        // 01:02 + 15 minutes delay
        hour: '13:17',
      },
    );
  });

  it(`should call intl.formatMessage with english formatted hour param (AM) when error code is 3107`, () => {
    const locale = 'en';
    const TIME = '01:17 a.m.';
    const sdkError: SDKError = {
      code: '3107',
      type: 'random',
      message: 'values',
    };
    const dateError = new Date(Date.UTC(2020, 1, 1, 1, 2));
    const ownPropsIntl = {
      locale,
      formatTime: jest.fn().mockImplementation(date => TIME),
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };
    const result = getOtcErrorMessage(
      templateName,
      sdkError,
      dateError,
      ownPropsIntl,
    );
    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatTime).toHaveBeenCalledWith(
      new Date(dateError.getTime() + 15 * 60000),
    );
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3107.bne',
      },
      {
        // 01:02 + 15 minutes delay
        hour: '01:17 a.m.',
      },
    );
  });

  it(`should call intl.formatMessage with english formatted hour (PM) param when error code is 3107`, () => {
    const locale = 'en';
    const TIME = '01:17 p.m.';
    const sdkError: SDKError = {
      code: '3107',
      type: 'random',
      message: 'values',
    };
    const dateError = new Date(Date.UTC(2020, 1, 1, 1, 2));
    const ownPropsIntl = {
      locale,
      formatTime: jest.fn().mockImplementation(date => TIME),
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };
    const result = getOtcErrorMessage(
      templateName,
      sdkError,
      dateError,
      ownPropsIntl,
    );
    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatTime).toHaveBeenCalledWith(
      new Date(dateError.getTime() + 15 * 60000),
    );
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.3107.bne',
      },
      {
        // 01:02 + 15 minutes delay
        hour: '01:17 p.m.',
      },
    );
  });

  it(`should call intl.formatMessage with code param when error code is not 3107`, () => {
    const sdkError: SDKError = {
      code: '1234',
      type: 'random',
      message: 'values',
    };
    const ownPropsIntl = {
      formatMessage: jest
        .fn()
        .mockImplementation((messageKey, params) => 'Random result'),
    };

    const result = getOtcErrorMessage(
      templateName,
      sdkError,
      new Date(),
      ownPropsIntl,
    );

    expect(result).toBe('Random result');
    expect(ownPropsIntl.formatMessage).toHaveBeenCalledWith(
      {
        id: 'auth.error.1234.bne',
      },
      {
        code: '1234',
      },
    );
  });
});
