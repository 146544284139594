// @flow
import React from 'react';

import Sbip2ResetPasswordTemplate from '../../templates/sbip2/ResetPasswordTemplate';
import WhiteLabelResetPasswordTemplate from '../../templates/whiteLabel/ResetPasswordTemplate';
import NatgoResetPasswordTemplate from '../../templates/natgo/ResetPasswordTemplate';
import RewardsResetPasswordTemplate from '../../templates/rewards/ResetPasswordTemplate';
import BaoResetPasswordTemplate from '../../templates/bao/ResetPasswordTemplate';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';

type Props = {
  templateName: string,
};

const ResetPassword = (props: Props) => {
  const { templateName } = props;

  const loginTemplates = {
    [TEMPLATES.SBIP2]: <Sbip2ResetPasswordTemplate />,
    [TEMPLATES.OBE]: <Sbip2ResetPasswordTemplate />,
    [TEMPLATES.SBIP2_WHITE_LABEL]: <WhiteLabelResetPasswordTemplate />,
    [TEMPLATES.NATGO]: <NatgoResetPasswordTemplate />,
    [TEMPLATES.REWARDS]: <RewardsResetPasswordTemplate />,
    [TEMPLATES.BAO]: <BaoResetPasswordTemplate />,
  };
  return getTemplateComponent(loginTemplates, templateName);
};

export default ResetPassword;
