import generateSelectorTests from '../../../utils/selectorTestHelper';
import {
  currentSelector,
  getNumberOfRetry,
  getTokenRecaptcha,
  isFetchingSelector,
  profileTypeSelector,
  sentAtSelector,
  showOTCSuccessMessageSelector,
  exceptionTypeSelector,
  identificationErrorSelector,
  getBackendError,
  recoveryTokenSelector,
  failureSelector,
  getAnalytics,
  factorsSelector,
  factorsHintSelector,
  isForgotPasswordFlowSelector,
} from '../selectors';

const TEST_SELECTORS = [
  {
    selector: profileTypeSelector,
    selectorField: 'forgotPassword.profileType',
    selectorType: 'string',
    defaultValue: '',
  },
  {
    selector: failureSelector,
    selectorField: 'forgotPassword.failure',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    selector: sentAtSelector,
    selectorField: 'forgotPassword.sentAt',
    selectorType: 'string',
    defaultValue: '',
  },
  {
    selector: exceptionTypeSelector,
    selectorField: 'forgotPassword.exceptionType',
    selectorType: 'string',
    defaultValue: '',
  },
  {
    selector: getNumberOfRetry,
    selectorField: 'forgotPassword.numberOfRetry',
    selectorType: 'int',
    defaultValue: 0,
  },
  {
    selector: showOTCSuccessMessageSelector,
    selectorField: 'forgotPassword.showOTCSuccessMessage',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    selector: currentSelector,
    selectorField: 'forgotPassword.currentStep',
    selectorType: 'string',
    defaultValue: undefined,
  },
  {
    testName: 'factorsSelector',
    selector: factorsSelector,
    selectorField: 'forgotPassword.factors',
    selectorType: 'array.object',
    defaultValue: [],
  },
  {
    testName: 'factorsHintSelector',
    selector: factorsHintSelector,
    selectorField: 'forgotPassword.factors',
    selectorType: 'array.object',
    defaultValue: [],
    outputMapper: factors =>
      factors.map(({ factorType, value }) => ({
        factorType: factorType ? factorType.toLowerCase() : factorType,
        value,
      })),
  },
  {
    selector: getTokenRecaptcha,
    selectorField: 'forgotPassword.tokenRecaptcha',
    selectorType: 'object',
    defaultValue: {},
  },
  {
    selector: isFetchingSelector,
    selectorField: 'forgotPassword.isFetching',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    selector: identificationErrorSelector,
    selectorField: 'forgotPassword.identificationError',
    selectorType: 'string',
    defaultValue: '',
  },
  {
    selector: getBackendError,
    selectorField: 'forgotPassword.backendError',
    selectorType: 'object',
    defaultValue: {},
  },
  {
    selector: recoveryTokenSelector,
    selectorField: 'forgotPassword.recoveryToken',
    selectorType: 'string',
    defaultValue: '',
  },
  {
    selector: getAnalytics,
    selectorField: 'forgotPassword.analytics',
    selectorType: 'object',
    defaultValue: {},
  },
  {
    selector: isForgotPasswordFlowSelector,
    selectorField: 'forgotPassword.isForgotPasswordFlow',
    selectorType: 'boolean',
    defaultValue: false,
  },
];

describe('Test Selectors', generateSelectorTests(TEST_SELECTORS));
