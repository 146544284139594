import testReducer from 'bnc-utilities-js/testUtils/reducer';
import resetPasswordReducer from '../reducer';
import {
  resetPasswordRequest,
  resetPasswordFailure,
  resetPasswordSuccess,
  setDtmType,
} from '../actions';

testReducer(resetPasswordReducer)
  .initialState()
  .expectDiff({
    backendError: {},
    dtmType: null,
    failure: false,
    isFetching: false,
  });

testReducer(resetPasswordReducer)
  .withAnyState()
  .on(resetPasswordRequest(false, 'toto', 'toto'))
  .expectDiff({
    backendError: {},
    failure: false,
    isFetching: true,
  });

testReducer(resetPasswordReducer)
  .withState({
    isFetching: true,
  })
  .on(resetPasswordFailure('toto'))
  .expectDiff({
    backendError: 'toto',
    failure: true,
    isFetching: false,
  });

testReducer(resetPasswordReducer)
  .withState({
    isFetching: true,
  })
  .on(resetPasswordSuccess())
  .expectDiff({
    isFetching: false,
  });

testReducer(resetPasswordReducer)
  .withAnyState()
  .on(setDtmType('dtmTypeStr'))
  .expectDiff({
    dtmType: 'dtmTypeStr',
  });
