import React from 'react';
import { intlShape } from 'react-intl';

import { Heading } from '@bnc-ui/heading';
import { Text } from '@bnc-ui/text';

import ForbiddenActionAlert from '../../../molecules/common/ForbiddenActionAlert';
import ForbiddenContactAlert from '../../../molecules/common/ForbiddenContactAlert';

import './style.scss';

const propTypes = {
  intl: intlShape.isRequired,
};

const ForbiddenError = props => {
  const { intl } = props;
  const title = intl.formatMessage({ id: 'text.title.forbidden' });
  const message = intl.formatMessage({ id: 'text.message.forbidden' });

  return (
    <div className="forbidden-error__main">
      <Heading type="h1" size={1} className="forbidden-error__title">
        {title}
      </Heading>
      <Text size="lead" className="forbidden-error__subtitle">
        {message}
      </Text>

      <ForbiddenActionAlert />
      <ForbiddenContactAlert />
    </div>
  );
};

ForbiddenError.propTypes = propTypes;

export default ForbiddenError;
