// @flow
import * as actionTypes from './actionTypes';
import type { Action as ReduxAction } from '../../types/action';
import type {
  ProfileType,
  ExceptionType,
} from '../../utils/constants/forgotPassword';

export type ValidateUserRequestAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_VALIDATE_USER_REQUEST,
> & {};

export type FactorData = {|
  factorType: string,
  value: string,
|};

export type ValidateUserSuccessAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_VALIDATE_USER_SUCCESS,
> & {
  factors: Array<FactorData>,
};

export type ValidateUserFailureAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_VALIDATE_USER_FAILURE,
> & {};

export type ForgotPasswordTriggerExceptionAction = ReduxAction<
  typeof actionTypes.FORGOT_PASSWORD_TRIGGER_EXCEPTION,
> & {
  exceptionType: ExceptionType,
};

export type ForgotPasswordState = {|
  isFetching: boolean,
  failure: boolean,
  sentAt: string,
  profileType: ProfileType,
  recoveryToken: string,
  exceptionType: ExceptionType,
  identificationError: string,
  currentStep: string,
  showOTCSuccessMessage: boolean,
|};

export type Action = ForgotPasswordTriggerExceptionAction;
