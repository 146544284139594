// Auth Service (from bnc-react-services)
import { init } from 'bnc-react-services/services/AuthService/actions';

import { Configs } from '../configs';
import extraHeaders from './extraHeaders';
import I18N, { ACCEPT_LANGUAGE_HEADER } from './constants/i18n';
import * as cookie from './cookie';

// BrowserHistory don't allow to get the origin easily
const windowLocation = window.location;
const origin = { ...windowLocation.origin };

// Auth config params
const clientId = Configs.AUTH.CLIENT_ID;
const redirectUrl = `${origin}/auth`;
const authnId = Configs.AUTH.AUTHORIZATION_ID;
const baseUrl = Configs.AUTH.OKTA_BASE_URL;
const authBaseUrl = Configs.AUTH.AUTH_BASE_URL;
const debugMode = Configs.DEBUG_MODE;

export const getAuthConfig = lang => {
  const languageFromCookie = cookie.get(I18N.COOKIE_NAME);
  const extraHeadersWithInitialLanguage = languageFromCookie
    ? {
        ...extraHeaders,
        [ACCEPT_LANGUAGE_HEADER]: languageFromCookie,
      }
    : extraHeaders;

  return {
    authnId,
    clientId,
    baseUrl,
    redirectUrl,
    authBaseUrl,
    extraHeaders: lang
      ? {
          ...extraHeaders,
          [ACCEPT_LANGUAGE_HEADER]: lang,
        }
      : extraHeadersWithInitialLanguage,
    debugMode,
  };
};

export const initAuthClientSdk = store => store.dispatch(init(getAuthConfig()));
