import React from 'react';

import { AlcWhiteLabelFrColor } from '@nbc-design/icons/lib/web/AlcWhiteLabelFrColor';
import { AlcWhiteLabelEnColor } from '@nbc-design/icons/lib/web/AlcWhiteLabelEnColor';
import getRewardsWLIcon from '../rewardsWLUtils';

describe('getRewardsWLIcon', () => {
  it('should return the AlcWhiteLabelFrColor component', () => {
    const intl = {
      locale: 'fr',
      formatMessage: x => `translated-ariaLabel`,
    };
    expect(getRewardsWLIcon(intl, 'ariaLabel')).toStrictEqual(
      <AlcWhiteLabelFrColor ariaLabel="translated-ariaLabel" />,
    );
  });

  it('should return the AlcWhiteLabelEnColor component', () => {
    const intl = {
      locale: 'en',
      formatMessage: x => `translated-ariaLabel`,
    };
    expect(getRewardsWLIcon(intl, 'ariaLabel')).toStrictEqual(
      <AlcWhiteLabelEnColor ariaLabel="translated-ariaLabel" />,
    );
  });
});
