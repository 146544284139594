import React from 'react';

import { AlcRewardsFrColor } from '@nbc-design/icons/lib/web/AlcRewardsFrColor';
import { AlcRewardsEnColor } from '@nbc-design/icons/lib/web/AlcRewardsEnColor';
import getRewardsIcon from '../rewardsUtils';

describe('getRewardsIcon', () => {
  it('should return the AlcRewardsFrColor component', () => {
    const intl = {
      locale: 'fr',
      formatMessage: x => `translated-aria`,
    };
    expect(getRewardsIcon(intl, 'ariaLabel')).toStrictEqual(
      <AlcRewardsFrColor ariaLabel="translated-aria" />,
    );
  });

  it('should return the AlcRewardsEnColor component', () => {
    const intl = {
      locale: 'en',
      formatMessage: x => `translated-aria`,
    };
    expect(getRewardsIcon(intl, 'ariaLabel')).toStrictEqual(
      <AlcRewardsEnColor ariaLabel="translated-aria" />,
    );
  });
});
