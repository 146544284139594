import React from 'react';

import { AlcRewardsFrColor } from '@nbc-design/icons/lib/web/AlcRewardsFrColor';
import { AlcRewardsEnColor } from '@nbc-design/icons/lib/web/AlcRewardsEnColor';

const getRewardsIcon = (intl, label) => {
  if (intl.locale === 'fr') {
    return <AlcRewardsFrColor ariaLabel={intl.formatMessage({ id: label })} />;
  }
  // English case
  return <AlcRewardsEnColor ariaLabel={intl.formatMessage({ id: label })} />;
};

export { getRewardsIcon as default };
