// @flow
import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';
import Header from '../../../organisms/common/Header';
import Footer from '../../../organisms/common/Footer';
import NeedHelp from '../../../molecules/common/NeedHelp';

import PasswordChangeSuccessBao from '../../../organisms/bao/PasswordChangeSuccess';

function PasswordChangeRequestTemplate() {
  return (
    <FullScreenFooterTemplate
      header={<Header hasSwitchLang={false} />}
      main={<PasswordChangeSuccessBao />}
      footer={<Footer content={<NeedHelp />} />}
    />
  );
}

export default PasswordChangeRequestTemplate;
