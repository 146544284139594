import { connect } from 'react-redux';

import ForgotPassword from './forgotPassword';

import { getTemplateName } from '../../services/templateService/selectors';

import type { State } from '../../types/state';
import { currentSelector } from '../../services/forgotPasswordService/selectors';

const mapStateToProps = (state: State) => ({
  templateName: getTemplateName(state),
  currentStep: currentSelector(state),
});

export default connect(mapStateToProps)(ForgotPassword);
