// @flow

import get from 'lodash/get';
import { Configs } from '../../configs';

/* Constants for Login */
export const MAX_IDENTITIES_SAVED = 4;
export const IDENTITY_COOKIE_NAME = 'bncIdentities';
// Cookie used by the backend
export const IS_UL_SUCCESS_LOGIN_COOKIE_NAME = 'isULSuccessLogin';
// According to analysis we hide resend buttons during MFA REQUIRED for at least 30 seconds before displaying them again (we add 3 seconds to be sure)
// Unit: milliseconds
export const RESEND_TIMEOUT = 33000;

// Hide resend success message after 15 seconds
export const RESEND_SUCCESS_MESSAGE_TIMEOUT = 15000;

export const LOGIN_FORM_STEPS = {
  PASSWORD_STEP: 'PASSWORD_STEP',
  LOGIN_WITH_ID_STEP: 'LOGIN_WITH_ID_STEP',
  LOGIN_WITHOUT_ID_STEP: 'LOGIN_WITHOUT_ID_STEP',
  DELETE_IDENTITIES_STEP: 'DELETE_IDENTITIES_STEP',
};

export const BASE_RESOURCES_LOGIN_IMAGE_URL = get(
  Configs,
  'DAM_RESOURCES_BASE_URL',
);
export const DEFAULT_LOGIN_IMAGE_NAME = 'img-login.jpg';

export const STORAGE_REDIRECT_FIRST_TRY = 'redirectFirstTry';
export const STORAGE_EXPIRATION_FIRST_TRY = 30000;

export const RESEND_DISPLAY_COUTDOWN = 33;
