// @flow
import * as actionTypes from './actionTypes';
import type { Action as ReduxAction } from '../../types/action';
import type {
  MultiFactorAuthFormData,
  MultiFactorAuthFormErrors,
} from '../../types/forms/multiFactorAuthForm';
import type { FormType } from '../../types/constants';

export type MFAFormUpdateAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_UPDATE,
> & {
  fieldName: string,
  fieldValue: string,
};

export type MFATriggerValidationAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_REQUEST,
> & { fieldName: string };

export type MFAValidationSuccessAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_SUCCESS,
> & {
  fieldName: string,
  isValid: boolean,
};

export type MFAValidationFailureAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE,
> & {
  fieldName: string,
  errors: MultiFactorAuthFormErrors,
};

export type MFATriggerSubmitAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_SUBMIT_REQUEST,
>;
export type MAFSubmitSuccessAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_SUBMIT_SUCCESS,
>;

export type MFASubmitFailureAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE,
> & {
  code: string,
  errors: MultiFactorAuthFormErrors,
};

export type MFAInitFormAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_INIT_REQUEST,
> & {
  payload: { formData: MultiFactorAuthFormData },
  formId: FormType,
};

export type MFAInitFormDoneAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_INIT_DONE,
> & {
  payload: { formData: MultiFactorAuthFormData },
};

export type MFATriggerSendCodeRequestAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_TRIGGER_SEND_CODE_REQUEST,
> & {
  factor: string,
};

export type MFATriggerResendCodeRequestAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_FORM_TRIGGER_RESEND_CODE_REQUEST,
> & {
  factor: string,
};

export type MFAUpdateFactorsAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_UPDATE_FACTORS,
> & {
  factorTypes: Array<string>,
  selectedFactor: string,
  contact: string,
};

export type MFAFormState = {|
  formId: FormType,
  isValid: boolean,
  errors: MultiFactorAuthFormErrors,
  formData: MultiFactorAuthFormData,
  systemError: null | {},
  submitFailed: boolean,
  initDone: boolean,
|};

export type DisplayResendAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_DISPLAY_RESEND,
>;

export type HideResendAction = ReduxAction<
  typeof actionTypes.MULTI_FACTOR_AUTH_HIDE_RESEND,
>;

export type Action =
  | MFAFormUpdateAction
  | MFATriggerValidationAction
  | MFAValidationSuccessAction
  | MFAValidationFailureAction
  | MFATriggerSubmitAction
  | MAFSubmitSuccessAction
  | MFASubmitFailureAction
  | MFAInitFormAction
  | MFAInitFormDoneAction
  | MFATriggerResendCodeRequestAction
  | MFAUpdateFactorsAction;
