import sessionManager from 'bnc-react-services/managers/SessionManager';
import * as actionTypesBncAuth from 'bnc-react-services/services/AuthService/actionTypes';
import {
  TRANSACTION_STATUS,
  FACTOR_TYPES,
} from 'bnc-react-services/managers/AuthManager/constants';
import * as actionTypesLiveAnnouncer from 'bnc-react-services/services/LiveAnnouncerService/actionTypes';
import { testSaga } from 'bnc-utilities-js/testUtils/saga';
import has from 'lodash/has';

import * as actionTypesRedirect from '../../redirectService/actionTypes';
import * as cookie from '../../../utils/cookie';
import browserHistory from '../../../nav/BrowserHistoryManager/index';
import TEMPLATES from '../../../utils/constants/template';

import {
  watchReturnToLogin,
  watchRemoveIdentity,
  watchLoginRequest,
  watchBncSignInFailure,
  watchBncSignInCompleted,
  watchBncVerifySuccess,
  watchBncVerifyFailure,
  watchTriggerHideResendButtons,
  watchLocationChange,
  watchLoginFailure,
} from '../workers';
import * as actionTypesLogin from '../actionTypes';
import * as actionTypesRoute from '../../loginRouteService/actionTypes';
import * as actionTypesLoginForm from '../../loginFormService/actionTypes';

import { ROUTES } from '../../../utils/constants';
import { LOGIN_FORM_STEPS } from '../../../utils/constants/login';
import { BNC_PARTNER_ID } from '../../../utils/constants/partner';
import {
  TEMPLATE_SERVICE_REDUCER,
  LOGIN_SERVICE_REDUCER,
  I18N_SERVICE_REDUCER,
} from '../../../globalRedux/reducers/constants';
import {
  getDeviceToken,
  getRememberMe,
  hashLogin,
  getBneDeviceToken,
} from '../helper';
import { GENERIC_WITH_CODE } from '../../../utils/authErrorMessages/constants';
import { getAppBaseUrl } from '../../../utils/configUtils';
import {
  PUT_IDENTITY_WITH_DEVICE_TOKEN,
  SAVE_HASH_LOGIN,
} from '../actionTypes';
import {
  createUserLockedCookie,
  getUserLockedCookie,
} from '../../../utils/lockUserCookie';

import * as actionTypes from '../../modalService/actionTypes';
import { MODAL_TYPES } from '../../../utils/constants/modal';

browserHistory.push = jest.fn();
browserHistory.getCurrentLocation = jest.fn(() => ({
  pathname: '/some/location',
}));

const now = Date.now();
const dateNowMockFn = jest.spyOn(Date, 'now');
dateNowMockFn.mockImplementation(() => now);

jest.mock('bnc-react-services/services/AuthService', () => ({
  init: jest.fn(),
  initDone: jest.fn(),

  signInRequest: jest.fn(),
  signInCompleted: jest.fn(),
  signInFailure: jest.fn(),

  signOutRequest: jest.fn(),
  signOutCompleted: jest.fn(),

  changePasswordRequest: jest.fn(),
  changePasswordCompleted: jest.fn(),
  changePasswordFailure: jest.fn(),

  verifyRecoveryTokenRequest: jest.fn(),
  verifyRecoveryTokenSuccess: jest.fn(),
  verifyRecoveryTokenFailure: jest.fn(),

  verifyRequest: jest.fn(),
  verifySuccess: jest.fn(),
  verifyFailure: jest.fn(),

  resendRequest: jest.fn(),
  resendSuccess: jest.fn(),
  resendFailure: jest.fn(),
}));

jest.mock('../../../utils/lockUserCookie', () => ({
  createUserLockedCookie: jest.fn(),
  getUserLockedCookie: jest.fn(),
}));

jest.mock('bnc-react-services/utils/propagateErrorsHandler', () => jest.fn());

jest.mock('../helper', () => ({
  getDeviceToken: jest.fn(),
  getQuerySearch: jest.fn(),
  getRememberMe: jest.fn(),
  hashLogin: jest.fn(),
  getBneDeviceToken: jest.fn(),
}));

beforeEach(() => {
  jest.useFakeTimers();
});

afterEach(() => {
  jest.clearAllMocks();
  jest.useFakeTimers();
});

jest.mock('bnc-react-services/managers/SessionManager', () => ({
  isAuthenticated: jest.fn(),
  clearSession: jest.fn(),
  setTransactionStatus: jest.fn(),
  getTransactionStatus: jest.fn(),
  getScopes: jest.fn(() => ({})),
  setAccessToken: jest.fn(),
  setSession: jest.fn(),
  setBncId: jest.fn(),
  isAuthenticatedWithSession: jest.fn(),
  getProfile: jest.fn(),
  getRequestHeaders: jest.fn(),
  getPartnerId: jest.fn(),
}));

jest.mock('../../../utils/cookie', () => ({
  get: jest.fn().mockReturnValueOnce({
    BNC_PARTNER_ID: ['email@test.com', 'otheremail@test.com'],
  }),
  set: jest.fn(),
}));

jest.mock('react-router');

window.crypto = {
  getRandomValues: jest.fn(() => [
    141162319,
    272084211,
    1113008567,
    2764149550,
  ]),
};

window.location = {
  reload: jest.fn(),
};

// Mocking RESEND_TIMEOUT constant so we wont have to wait too long to test if the resend buttons are displayed as expected
jest.mock('../../../utils/constants', () => {
  const constants = require.requireActual('../../../utils/constants');
  return {
    ...constants,
    RESEND_TIMEOUT: 2,
  };
});

// Mocking MFA_CODE_EXPIRATION and MFA_CODE_EXPIRATION_SMS values from config in order to make brief the test
jest.mock('../../../configs', () => {
  const Configs = require.requireActual('../../../configs');
  return {
    ...Configs,
    AUTH: {
      ...Configs.AUTH,
      MFA_CODE_EXPIRATION: 2,
      MFA_CODE_EXPIRATION_SMS: 2,
    },
  };
});

// Mocking values and needed parameters
const cookieName = 'bncUserIdentity';
const identity = 'newemail@test.com';
const password = 'testpassword888';
let remember = false;
const identityToDelete = 'email@test.com';
const oktaTransaction = {
  status: '',
  factors: [],
  sessionToken: '',
  prev: jest.fn(),
};
const sdkErrorTestObject = { message: 'ERROR TEST', code: '-1', type: '' };

const deviceToken = 'bhidwbfaufhwi8efbk73i8';

describe('watchLoginRequest', () => {
  test('watchLoginRequest - Login in with success without remember', async () => {
    getRememberMe.mockReturnValueOnce(false);
    getDeviceToken.mockReturnValueOnce(null);
    hashLogin.mockReturnValueOnce(
      'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
    );
    getBneDeviceToken.mockReturnValueOnce(null);

    const { sagaDone } = testSaga({
      saga: watchLoginRequest,
      state: {
        loginServiceReducer: {
          identities: [],
        },
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
          partnerId: '',
        },
      },
      args: [
        {
          identity,
          password,
          remember,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: actionTypesBncAuth.BNC_AUTH_SIGN_IN_REQUEST,
        identity,
        password,
        deviceToken: null,
        requestMFA: true,
      },
      {
        type: SAVE_HASH_LOGIN,
        hashLogin: 'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
      },
    ]);
  });

  test('watchLoginRequest - Login in with max identities and remember true but rememberMe false', async () => {
    // Login WITH remember
    remember = true;
    getRememberMe.mockReturnValueOnce(false);
    getDeviceToken.mockReturnValueOnce(null);
    hashLogin.mockReturnValueOnce(
      'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
    );
    getBneDeviceToken.mockReturnValueOnce(null);

    const { sagaDone } = testSaga({
      saga: watchLoginRequest,
      state: {
        loginServiceReducer: {
          identities: [
            { login: 'email1@bnc.ca' },
            { login: 'email@test.com' },
            { login: 'email3@bnc.ca' },
            { login: 'email4@bnc.ca' },
          ],
        },
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
          partnerId: '',
        },
      },
      args: [
        {
          identity,
          password,
          remember,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: actionTypesBncAuth.BNC_AUTH_SIGN_IN_REQUEST,
        identity,
        password,
        deviceToken: null,
        requestMFA: true,
      },
      {
        type: SAVE_HASH_LOGIN,
        hashLogin: 'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
      },
    ]);
  });

  test('watchLoginRequest - Login in with max identities, remember true and already saved identity, so rememberMe true', async () => {
    // Login WITH remember
    remember = true;
    getRememberMe.mockReturnValueOnce(true);
    getDeviceToken.mockReturnValueOnce(deviceToken);
    hashLogin.mockReturnValueOnce(
      'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
    );
    getBneDeviceToken.mockReturnValueOnce(deviceToken);

    const { sagaDone } = testSaga({
      saga: watchLoginRequest,
      state: {
        loginServiceReducer: {
          identities: [
            { login: 'email1@bnc.ca' },
            { login: 'newemail@test.com' },
            { login: 'email3@bnc.ca' },
            { login: 'email4@bnc.ca' },
          ],
        },
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
          partnerId: '',
        },
      },
      args: [
        {
          identity,
          password,
          remember,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        deviceToken,
        login: identity,
        type: PUT_IDENTITY_WITH_DEVICE_TOKEN,
      },
      {
        type: actionTypesBncAuth.BNC_AUTH_SIGN_IN_REQUEST,
        identity,
        password,
        deviceToken,
        requestMFA: true,
      },
      {
        type: SAVE_HASH_LOGIN,
        hashLogin: 'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
      },
    ]);
  });

  test('watchLoginRequest - Login in without identities', async () => {
    // Login WITH remember
    remember = true;
    getRememberMe.mockReturnValueOnce(true);
    getDeviceToken.mockReturnValueOnce(deviceToken);
    hashLogin.mockReturnValueOnce(
      'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
    );
    getBneDeviceToken.mockReturnValueOnce(deviceToken);

    const { sagaDone } = testSaga({
      saga: watchLoginRequest,
      state: {
        loginServiceReducer: {
          identities: null,
        },
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
          partnerId: '',
        },
      },
      args: [
        {
          identity,
          password,
          remember,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        deviceToken,
        login: identity,
        type: PUT_IDENTITY_WITH_DEVICE_TOKEN,
      },
      {
        type: actionTypesBncAuth.BNC_AUTH_SIGN_IN_REQUEST,
        identity,
        password,
        deviceToken,
        requestMFA: true,
      },
      {
        type: SAVE_HASH_LOGIN,
        hashLogin: 'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
      },
    ]);
  });

  test('watchLoginRequest - Login in without identities and without deviceToken', async () => {
    // Login WITH remember
    remember = true;
    getRememberMe.mockReturnValueOnce(true);
    getDeviceToken.mockReturnValueOnce(null);
    hashLogin.mockReturnValueOnce(
      'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
    );
    getBneDeviceToken.mockReturnValueOnce(null);

    const { sagaDone } = testSaga({
      saga: watchLoginRequest,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
        loginServiceReducer: {
          identities: null,
        },
      },
      args: [
        {
          identity,
          password,
          remember,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: actionTypesBncAuth.BNC_AUTH_SIGN_IN_REQUEST,
        identity,
        password,
        deviceToken: null,
        requestMFA: true,
      },
      {
        type: SAVE_HASH_LOGIN,
        hashLogin: 'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
      },
    ]);
  });

  test('watchLoginRequest - Login in without identities and without deviceToken for BNE', async () => {
    // Login WITH remember
    remember = true;
    getRememberMe.mockReturnValueOnce(true);
    getDeviceToken.mockReturnValueOnce(null);
    hashLogin.mockReturnValueOnce(
      'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
    );
    getBneDeviceToken.mockReturnValueOnce(null);

    const { sagaDone } = testSaga({
      saga: watchLoginRequest,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'BNE',
        },
        loginServiceReducer: {
          identities: null,
        },
      },
      args: [
        {
          identity,
          password,
          remember,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        deviceToken: null,
        login: identity,
        type: PUT_IDENTITY_WITH_DEVICE_TOKEN,
      },
      {
        type: actionTypesBncAuth.BNC_AUTH_SIGN_IN_REQUEST,
        identity,
        password,
        requestMFA: false,
        deviceToken: null,
      },
      {
        type: SAVE_HASH_LOGIN,
        hashLogin: 'bmV3ZW1haWxAdGVzdC5jb20=:dGVzdHBhc3N3b3JkODg4',
      },
    ]);
  });
});

describe('watchReturnToLogin', () => {
  test('watchReturnToLogin - Return to login page', async () => {
    testSaga({
      saga: watchReturnToLogin,
      state: {
        loginServiceReducer: {
          returnToSbip: false,
        },
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
          partnerId: '',
        },
        [I18N_SERVICE_REDUCER]: {
          locale: 'fr',
        },
      },
      args: [],
    });
    expect(browserHistory.push).toBeCalledWith(ROUTES.LOGIN);
  });
  for (const template in TEMPLATES) {
    if (template !== TEMPLATES.ORION_WHITE_LABEL && has(TEMPLATES, template)) {
      const templateName =
        TEMPLATES[template] === TEMPLATES.SECURE_KEY
          ? TEMPLATES.SBIP2
          : TEMPLATES[template];
      const partnerId =
        templateName === TEMPLATES.SBIP2_WHITE_LABEL ? 'test' : '';

      const appBaseUrl = getAppBaseUrl(templateName, partnerId, 'FR');

      test(`watchReturnToLogin - Return to login page from ${template} reset password exception`, async () => {
        const { sagaDone } = testSaga({
          saga: watchReturnToLogin,
          state: {
            loginServiceReducer: {
              returnToSbip: true,
            },
            [TEMPLATE_SERVICE_REDUCER]: {
              templateName: template,
              partnerId,
            },
            [I18N_SERVICE_REDUCER]: {
              locale: 'fr',
            },
          },
          args: [],
        });

        const { actions } = await sagaDone;
        if (TEMPLATES.BNE === templateName) {
          expect(browserHistory.push).toHaveBeenCalledTimes(1);
        } else {
          expect(browserHistory.push).toHaveBeenCalledTimes(0);
          expect(actions).toEqual([
            {
              modalType: MODAL_TYPES.SESSION_EXPIRED_MODAL,
              type: actionTypes.HIDE_MODAL,
            },
            {
              type: actionTypesRedirect.REDIRECT_TO_PAGE,
              pageUrl: appBaseUrl,
            },
          ]);
        }
      });
    }
  }
});

describe.skip('watchRemoveIdentity', () => {
  test('watchRemoveIdentity - Remove user email in cookies, default partner id', async () => {
    sessionManager.getPartnerId.mockReturnValueOnce(null);

    const { sagaDone } = testSaga({
      saga: watchRemoveIdentity,
      args: [{ cookieName, identity: identityToDelete }],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        cookieValue: ['otheremail@test.com'],
        type: actionTypesLogin.LOGIN_LOAD_IDENTITY_SUCCESS,
      },
    ]);
    expect(cookie.set).toHaveBeenCalled();
    expect(cookie.set.mock.calls[0][0]).toEqual(cookieName);
    expect(cookie.set.mock.calls[0][1]).toEqual({
      [BNC_PARTNER_ID]: ['otheremail@test.com'],
    });
  });

  test('watchRemoveIdentity - Remove the last user email in cookies', async () => {
    cookie.get.mockReturnValueOnce({ [BNC_PARTNER_ID]: ['email@test.com'] });
    sessionManager.getPartnerId.mockReturnValueOnce(null);

    const { sagaDone } = testSaga({
      saga: watchRemoveIdentity,
      args: [{ cookieName, identity: identityToDelete }],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: actionTypesLoginForm.LOGIN_FORM_CHANGE_STEP_REQUEST,
        nextStep: LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP,
      },
      {
        cookieValue: [],
        type: actionTypesLogin.LOGIN_LOAD_IDENTITY_SUCCESS,
      },
    ]);
    expect(cookie.set).toHaveBeenCalled();
    expect(cookie.set.mock.calls[0][0]).toEqual(cookieName);
    expect(cookie.set.mock.calls[0][1]).toEqual({
      [BNC_PARTNER_ID]: [],
    });
  });
});

describe('watchBncSignInFailure', () => {
  test('watchBncSignInFailure', async () => {
    const { sagaDone } = testSaga({
      saga: watchBncSignInFailure,
      args: [{ error: sdkErrorTestObject }],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      { type: actionTypesLogin.LOGIN_FAILURE, sdkError: sdkErrorTestObject },
    ]);
  });
});

describe('watchBncSignInCompleted', () => {
  test('watchBncSignInCompleted - PASSWORD_EXPIRED status', async () => {
    oktaTransaction.status = TRANSACTION_STATUS.PASSWORD_EXPIRED;

    const { sagaDone } = testSaga({
      saga: watchBncSignInCompleted,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
        loginServiceReducer: {
          identityRemembered: null,
        },
      },
      args: [{ transaction: oktaTransaction }],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      { type: actionTypesRoute.LOGIN_ROUTE_PASSWORD_EXPIRED },
    ]);
  });

  test('watchBncSignInCompleted - PASSWORD_EXPIRED status Secure Key', async () => {
    oktaTransaction.status = TRANSACTION_STATUS.PASSWORD_EXPIRED;

    const { sagaDone } = testSaga({
      saga: watchBncSignInCompleted,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SECURE_KEY',
        },
        loginServiceReducer: {
          identityRemembered: null,
        },
      },
      args: [{ transaction: oktaTransaction }],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      { type: actionTypesLogin.LOGIN_SET_RETURN_SBIP_LOGIN_FLAG },
      { type: actionTypesRoute.LOGIN_ROUTE_PASSWORD_EXCEPTION },
    ]);
  });

  test('watchBncSignInCompleted - PASSWORD_EXPIRED status', async () => {
    oktaTransaction.status = TRANSACTION_STATUS.LOCKED_OUT;

    const { sagaDone } = testSaga({
      saga: watchBncSignInCompleted,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
        loginServiceReducer: {
          identityRemembered: null,
        },
      },
      args: [{ transaction: oktaTransaction }],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        lockedStatus: oktaTransaction.status,
        type: actionTypesLogin.UPDATE_LOCKED_STATUS,
      },
      { type: actionTypesRoute.LOGIN_ROUTE_LOCKED },
    ]);
  });

  test('watchBncSignInCompleted - Accout locked vsd', async () => {
    oktaTransaction.status = TRANSACTION_STATUS.LOCKED_OUT_VSD;

    const { sagaDone } = testSaga({
      saga: watchBncSignInCompleted,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
        loginServiceReducer: {
          identityRemembered: null,
        },
      },
      args: [{ transaction: oktaTransaction }],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        lockedStatus: oktaTransaction.status,
        type: actionTypesLogin.UPDATE_LOCKED_STATUS,
      },
      { type: actionTypesRoute.LOGIN_ROUTE_LOCKED },
    ]);
  });

  test('watchBncSignInCompleted - Accout locked fraud', async () => {
    oktaTransaction.status = TRANSACTION_STATUS.LOCKED_OUT_FRAUD;

    const { sagaDone } = testSaga({
      saga: watchBncSignInCompleted,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: 'SBIP2',
        },
        loginServiceReducer: {
          identityRemembered: null,
        },
      },
      args: [{ transaction: oktaTransaction }],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        lockedStatus: oktaTransaction.status,
        type: actionTypesLogin.UPDATE_LOCKED_STATUS,
      },
      { type: actionTypesRoute.LOGIN_ROUTE_LOCKED },
    ]);
  });

  test('watchBncSignInCompleted - Accout locked BNE', async () => {
    oktaTransaction.status = TRANSACTION_STATUS.LOCKED_OUT;

    const { sagaDone } = testSaga({
      saga: watchBncSignInCompleted,
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: TEMPLATES.BNE,
        },
        loginServiceReducer: {
          identityRemembered: null,
        },
      },
      args: [{ transaction: oktaTransaction }],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        lockedStatus: oktaTransaction.status,
        type: actionTypesLogin.UPDATE_LOCKED_STATUS,
      },
      { type: actionTypesRoute.LOGIN_ROUTE_LOCKED },
    ]);

    expect(getUserLockedCookie).toHaveBeenCalled();
    expect(createUserLockedCookie).toHaveBeenCalled();
  });
});

describe('watchBncVerifyFailure', () => {
  test('watchBncVerifyFailure', async () => {
    const { sagaDone } = testSaga({
      saga: watchBncVerifyFailure,
      args: [{ error: sdkErrorTestObject }],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      { type: actionTypesLogin.LOGIN_FAILURE, sdkError: sdkErrorTestObject },
    ]);
  });
});

describe('watchBncVerifySuccess', () => {
  test('watchBncVerifySuccess - SUCCESS status', async () => {
    oktaTransaction.status = TRANSACTION_STATUS.SUCCESS;

    const { sagaDone } = testSaga({
      saga: watchBncVerifySuccess,
      state: {
        loginServiceReducer: {
          identityRemembered: 'test@bnc.ca',
        },
      },
      args: [{ transaction: oktaTransaction }],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([{ type: actionTypesLogin.LOGIN_SUCCESS }]);
  });

  test('watchBncVerifySuccess - SUCCESS status without rememberedIdentity', async () => {
    oktaTransaction.status = TRANSACTION_STATUS.SUCCESS;

    const { sagaDone } = testSaga({
      saga: watchBncVerifySuccess,
      state: {
        loginServiceReducer: {},
      },
      args: [{ transaction: oktaTransaction }],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([{ type: actionTypesLogin.LOGIN_SUCCESS }]);
  });

  test('watchBncVerifySuccess - PASSWORD_EXPIRED status', async () => {
    oktaTransaction.status = TRANSACTION_STATUS.PASSWORD_EXPIRED;

    const { sagaDone } = testSaga({
      saga: watchBncVerifySuccess,
      state: {
        loginServiceReducer: {},
      },
      args: [{ transaction: oktaTransaction }],
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      { type: actionTypesRoute.LOGIN_ROUTE_PASSWORD_EXPIRED },
    ]);
  });
});

describe('watchTriggerHideResendButtons', () => {
  test('watchTriggerHideResendButtons - Hide resend buttons', async () => {
    const { sagaDone } = testSaga({
      saga: watchTriggerHideResendButtons,
      state: {},
      args: [],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([{ type: actionTypesLogin.LOGIN_MFA_HIDE_RESEND }]);
  });
});

describe('watchLocationChange', () => {
  test('watchLocationChange - PARAMS_UNAVAILABLE', async () => {
    const payload = { pathname: ROUTES.PARAMS_UNAVAILABLE };
    const { sagaDone } = testSaga({
      saga: watchLocationChange,
      args: [{ payload }],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      { type: actionTypesBncAuth.BNC_AUTH_SIGN_OUT_REQUEST },
    ]);
  });

  test('watchLocationChange - OTHER', async () => {
    const payload = { pathname: '/toto' };
    const { sagaDone } = testSaga({
      saga: watchLocationChange,
      args: [{ payload }],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
  });
});

describe('watchLoginFailure', () => {
  test('watchLoginFailure', async () => {
    const errorMsg = 'text.aria.connectionFailed.'
      .concat(GENERIC_WITH_CODE)
      .concat('|code=')
      .concat(sdkErrorTestObject.code);

    const { sagaDone } = testSaga({
      saga: watchLoginFailure,
      args: [{ sdkError: sdkErrorTestObject }],
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: TEMPLATES.BNE,
        },
        [LOGIN_SERVICE_REDUCER]: {
          MFAselected: FACTOR_TYPES.SMS,
        },
      },
    });
    const { actions } = await sagaDone;
    expect(createUserLockedCookie).not.toHaveBeenCalled();
    expect(actions).toEqual([
      {
        type: actionTypesLiveAnnouncer.ANNOUNCE_ALERT_MESSAGE,
        message: errorMsg,
        politeness: 'assertive',
      },
      {
        type: actionTypesLogin.RSA_RETURN_LOGIN_FLAG,
        flagStatus: true,
      },
      {
        type: actionTypesLogin.UPDATE_LAST_LOGIN_FAILURE_DATE,
        date: new Date(now),
      },
    ]);
  });

  test('watchLoginFailure - account locked error', async () => {
    const accountLockedError = {
      message: 'ACCOUNT_LOCKED',
      code: '3107',
      type: 'NetworkError',
    };

    const { sagaDone } = testSaga({
      saga: watchLoginFailure,
      args: [{ sdkError: accountLockedError }],
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: TEMPLATES.BNE,
        },
        [LOGIN_SERVICE_REDUCER]: {
          MFAselected: FACTOR_TYPES.SMS,
        },
      },
    });
    const { actions } = await sagaDone;
    expect(createUserLockedCookie).toHaveBeenCalled();
    expect(actions).toEqual([
      {
        lockedStatus: 'LOCKED_OUT',
        type: actionTypesLogin.UPDATE_LOCKED_STATUS,
      },
      { type: actionTypesLogin.LOGIN_CLEAR_ERROR_MESSAGES },
      { type: actionTypesRoute.LOGIN_ROUTE_LOCKED },
      {
        type: actionTypesLogin.UPDATE_LAST_LOGIN_FAILURE_DATE,
        date: new Date(now),
      },
    ]);
  });

  test('watchLoginFailure with akamai error code', async () => {
    const akamaiErrorTestObject = {
      message: 'ERROR TEST',
      code: '6001',
      type: '',
    };

    const { sagaDone } = testSaga({
      saga: watchLoginFailure,
      args: [{ sdkError: akamaiErrorTestObject }],
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: TEMPLATES.BNE,
        },
        [LOGIN_SERVICE_REDUCER]: {
          flagStatus: true,
        },
      },
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: actionTypesLogin.UPDATE_LAST_LOGIN_FAILURE_DATE,
        date: new Date(now),
      },
    ]);
    expect(browserHistory.push).toHaveBeenCalledTimes(1);
  });

  test(`watchLoginFailure with account locked error code from ${TEMPLATES.BNE}`, async () => {
    oktaTransaction.status = TRANSACTION_STATUS.LOCKED_OUT;

    const accountLockedErrorTestObject = {
      message: 'ACCOUNT_LOCKED',
      code: '3107',
      type: 'NetworkError',
    };

    const { sagaDone } = testSaga({
      saga: watchLoginFailure,
      args: [{ sdkError: accountLockedErrorTestObject }],
      state: {
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: TEMPLATES.BNE,
        },
        [LOGIN_SERVICE_REDUCER]: {
          MFAselected: FACTOR_TYPES.TOKEN,
        },
      },
    });
    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        lockedStatus: oktaTransaction.status,
        type: actionTypesLogin.UPDATE_LOCKED_STATUS,
      },
      { type: actionTypesLogin.LOGIN_CLEAR_ERROR_MESSAGES },
      { type: actionTypesRoute.LOGIN_ROUTE_LOCKED },
      {
        type: actionTypesLogin.UPDATE_LAST_LOGIN_FAILURE_DATE,
        date: new Date(now),
      },
    ]);
  });
});
