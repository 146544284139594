// @flow
import get from 'lodash/get';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import replace from 'lodash/replace';
import has from 'lodash/has';
import indexOf from 'lodash/indexOf';
import cleanPath from 'bnc-utilities-js/url/cleanPath';

import { Configs } from '../configs';

import type { Template } from '../types/templates';

import BrowserHistoryManager from '../nav/BrowserHistoryManager';
import { getActivePartnerIds } from './partnerUtils/index';

import ROUTES from './constants/routes';
import TEMPLATES from './constants/template';

export type getCurrentTemplateType = (
  templates: Array<Template>,
) => { partnerId: string, selectedTemplate: Template } | null;
export type getTemplateUrlType = (path: string) => string;
export type generateWhiteLabelTemplateRegexType = (
  template: Template,
  regex: string,
) => string;

const generateWhiteLabelTemplateRegex: generateWhiteLabelTemplateRegexType = (
  template,
  regex,
) => {
  const whiteLabelKey = get(template, ['WHITE_LABEL_KEY']);
  const availablePartnerId = getActivePartnerIds(whiteLabelKey);
  const partnerIdJoined = join(availablePartnerId, '|');
  return replace(regex, '$whitelabels', partnerIdJoined);
};

export const hasWhiteLabels = (selectedTemplate: string) => {
  const whiteLabelKey = get(
    Configs,
    ['TEMPLATES', selectedTemplate, 'WHITE_LABEL_KEY'],
    '',
  );
  return !isEmpty(whiteLabelKey);
};

export const getCurrentTemplate: getCurrentTemplateType = templates => {
  if (isEmpty(templates)) {
    return null;
  }

  const location = window.location.href;

  let selectedTemplate: Template = {};
  let partnerId: string = '';

  some(templates, template => {
    let stringRegex = get(template, 'REGEX');
    const whiteLabelKey = get(template, ['WHITE_LABEL_KEY'], '');
    const hasWhiteLabelsKey = !isEmpty(whiteLabelKey);

    if (hasWhiteLabelsKey) {
      stringRegex = generateWhiteLabelTemplateRegex(template, stringRegex);
    }
    const templateRegex = new RegExp(stringRegex);
    const matchLocation = location.match(templateRegex);

    if (matchLocation) {
      selectedTemplate = get(template, 'TEMPLATE_NAME', {});
      // We extract the 1st group in order to get the partnerId
      partnerId = hasWhiteLabelsKey ? get(matchLocation, 1, '') : '';
      return true;
    }
    return false;
  });

  return { selectedTemplate, partnerId };
};

export const getTemplatedUrl: getTemplateUrlType = path => {
  const baseUrl = getBaseUrl();
  const newPath = `${baseUrl}${path}`;
  return cleanPath(newPath);
};

export const getBaseUrl = () => {
  const templates = get(Configs, 'TEMPLATES');

  const currentTemplate = getCurrentTemplate(templates);
  const selectedTemplate = get(currentTemplate, 'selectedTemplate', {});
  const partnerId = get(currentTemplate, 'partnerId', '');

  const whiteLabelKey = get(
    templates,
    [selectedTemplate, 'WHITE_LABEL_KEY'],
    '',
  );
  const hasWhiteLabelsKey = !isEmpty(whiteLabelKey);

  let baseUrl = get(templates, [selectedTemplate, 'BASE_URL'], '');
  baseUrl = hasWhiteLabelsKey ? `${baseUrl}/${partnerId}` : `${baseUrl}`;

  return baseUrl;
};

export const getTemplateComponent = (templates: any, templateName: string) => {
  // For safety reason if the configuration allows this page by mistake, redirect to not found page
  if (!has(templates, templateName)) {
    BrowserHistoryManager.push(ROUTES.NOT_FOUND);
    return null;
  }

  return templates[templateName];
};

export const getPhoneAssistanceNumber = (
  templateName: string,
  partnerId: any,
  locale: string,
  phoneType: string,
) => {
  const localeAux = locale ? locale.toUpperCase() : '';
  const whiteLabel = 'WHITE_LABEL';

  let pathPrefix = templateName;
  const pathSuffix = `ASSISTANCE.PHONE_NUMBER.${phoneType}.${localeAux}`;
  let defaultValue = '';
  if (templateName?.endsWith(whiteLabel)) {
    const templateGroup = replace(templateName, `${whiteLabel}`, '') || 'SBIP2';
    const realTemplateGroup = replace(templateGroup, new RegExp('_$'), '');
    pathPrefix = `${whiteLabel}.${realTemplateGroup}.config.${partnerId}`;

    const defaultPathPrefix = `${whiteLabel}.SBIP2.config.partnership`;
    defaultValue = get(Configs, `${defaultPathPrefix}.${pathSuffix}`, '');
  }
  const pathTo = `${pathPrefix}.${pathSuffix}`;
  const assistancePhone = get(Configs, pathTo, defaultValue);
  return assistancePhone;
};

export const getMessageKey = (templateName: string, messageKey: string) => {
  switch (templateName) {
    case TEMPLATES.SBIP2_WHITE_LABEL:
      return `${messageKey}.wl`;
    case TEMPLATES.BNE:
      return `${messageKey}.bne`;
    default:
      return messageKey;
  }
};

export const isPartnerVanilla = partnerId => {
  return (
    indexOf(get(Configs, 'WHITE_LABEL.SBIP2.PARTNERS_VANILLA'), partnerId) !==
    -1
  );
};
