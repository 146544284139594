// @flow
import type { Action as ReduxAction } from '../../types/action';
import type { Config } from '../../types/configs';

import * as actionTypes from './actionTypes';

export type ConfigsState = {|
  configs: Config,
  hasConfig: boolean,
  error: any,
|};

export type RetrieveConfigsRequestAction = ReduxAction<
  typeof actionTypes.CONFIGS_SERVICE_RETRIEVE_CONFIGS_REQUEST,
>;

export type RetrieveConfigsSuccessAction = ReduxAction<
  typeof actionTypes.CONFIGS_SERVICE_RETRIEVE_CONFIGS_SUCCESS,
> & {
  configs: Config,
};

export type RetrieveConfigsFailureAction = ReduxAction<
  typeof actionTypes.CONFIGS_SERVICE_RETRIEVE_CONFIGS_FAILURE,
> & {
  err: any,
};

export type Action =
  | RetrieveConfigsSuccessAction
  | RetrieveConfigsFailureAction
  | RetrieveConfigsRequestAction;
