import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import toLower from 'lodash/toLower';

import { forgotPasswordFormDataShape } from '../../../../utils/shapes';
import DebitInfo from './debitInfo';
import LoanInfo from './loanInfo';
import InvestmentInfo from './investmentInfo';
import CreditInfo from './creditInfo';
import MortgageInfo from './mortgageInfo';
import ProspectInfo from './prospectInfo';
import { FORGOT_PASSWORD_PRODUCT_TYPE } from '../../../../utils/forgotPasswordUtils/constants';
import InvestmentBrokerageInfo from './investmentBrokerageInfo';

const propTypes = {
  intl: intlShape.isRequired,
  formData: forgotPasswordFormDataShape.isRequired,
  formUpdate: PropTypes.func.isRequired,
  triggerValidation: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  partnerId: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

const defaultProps = {
  partnerId: '',
};

const BankProductInfo = ({
  intl,
  formData,
  formUpdate,
  triggerValidation,
  errors,
  partnerId,
  locale,
}) => {
  const onChange = event => {
    formUpdate(event.target.name, event.target.value);
  };
  const onBlur = event => {
    triggerValidation(event.target.name);
  };

  const suffix = partnerId ? `.${toLower(partnerId)}` : '';

  const renderProductInfo = () => {
    const context = get(formData, 'productType');
    switch (context) {
      case FORGOT_PASSWORD_PRODUCT_TYPE.DEBIT:
        return (
          <DebitInfo
            intl={intl}
            errors={errors}
            formData={formData}
            onChange={onChange}
            onBlur={onBlur}
            suffix={suffix}
          />
        );

      case FORGOT_PASSWORD_PRODUCT_TYPE.LOAN:
        return (
          <LoanInfo
            intl={intl}
            errors={errors}
            formData={formData}
            onChange={onChange}
            onBlur={onBlur}
          />
        );
      case FORGOT_PASSWORD_PRODUCT_TYPE.INVESTMENT:
        return (
          <InvestmentInfo
            intl={intl}
            errors={errors}
            formData={formData}
            onChange={onChange}
            onBlur={onBlur}
            locale={locale}
          />
        );
      case FORGOT_PASSWORD_PRODUCT_TYPE.CREDIT:
        return (
          <CreditInfo
            intl={intl}
            errors={errors}
            formData={formData}
            onChange={onChange}
            onBlur={onBlur}
          />
        );
      case FORGOT_PASSWORD_PRODUCT_TYPE.MORTGAGE:
        return (
          <MortgageInfo
            intl={intl}
            errors={errors}
            formData={formData}
            onChange={onChange}
            onBlur={onBlur}
          />
        );
      case FORGOT_PASSWORD_PRODUCT_TYPE.PROSPECT:
        return (
          <ProspectInfo
            intl={intl}
            errors={errors}
            formData={formData}
            onChange={onChange}
            onBlur={onBlur}
            locale={locale}
          />
        );
      case FORGOT_PASSWORD_PRODUCT_TYPE.INVESTMENT_BROKERAGE:
        return (
          <InvestmentBrokerageInfo
            intl={intl}
            errors={errors}
            formData={formData}
            onChange={onChange}
            onBlur={onBlur}
            locale={locale}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="forgot-password-form--group forgot-password-form--group-composed">
      {renderProductInfo()}
    </div>
  );
};

BankProductInfo.propTypes = propTypes;
BankProductInfo.defaultProps = defaultProps;

export default BankProductInfo;
