import React, { useEffect } from 'react';

import browserHistory from '../../../nav/BrowserHistoryManager';
import FormHandler from '../../../molecules/forms/FormHandler';
import LoginForm from '../../../molecules/bne/forms/LoginFormWithoutRememberMe';

import { FORM_TYPES, ROUTES } from '../../../utils/constants';
import Biometry from '../../../services/biometryService/biometry';
import { isWebView } from '../../../utils/browserUtils';
import * as cookie from '../../../utils/cookie';
import { getDomain } from '../../../services/i18nService/workers';

type Props = {
  className?: string,
  hasForgotPwd?: boolean,
  isForceLogin: boolean,
};

const defaultProps = {
  className: '',
  hasForgotPwd: true,
};

function Login(props: Props) {
  const { className, hasForgotPwd, isForceLogin } = props;

  useEffect(() => {
    if (isWebView()) {
      // In case of mobile application (using webview), set STYLEINDEX cookie to redirect to mobile webapp
      cookie.set('STYLEINDEX', 'sbic_sbicommobile', { domain: getDomain() });
    }

    if (Biometry.available() && !isForceLogin) {
      browserHistory.push(`${ROUTES.LOGIN_BIOMETRY}${window.location.search}`);
    }
  }, [isForceLogin]);

  return (
    <div id="loginForm" className="connect-form">
      <FormHandler
        // $FlowFixMe
        formName={FORM_TYPES.LOGIN_FORM}
      >
        <LoginForm className={className} hasForgotPwd={hasForgotPwd} />
      </FormHandler>
    </div>
  );
}

Login.defaultProps = defaultProps;

export default Login;
