import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';
import ForgotPasswordException from '../../../organisms/common/ForgotPasswordException';
import Header from '../../../organisms/rewards/Header';

const ForgotPasswordExceptionTemplate = () => (
  <FullScreenFooterTemplate
    header={<Header hasSwitchLang={false} />}
    main={<ForgotPasswordException />}
    className="error-background"
  />
);

export default ForgotPasswordExceptionTemplate;
