import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import RewardsWLBrand from '../../../molecules/rewards/RewardsWLBrand';
import SwitchLang from '../../../molecules/common/SwitchLang';

const propTypes = {
  className: PropTypes.string,
  hasLogo: PropTypes.boolean,
  hasTitle: PropTypes.boolean,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hasSwitchLang: PropTypes.boolean,
  hasTagBrand: PropTypes.boolean,
};

const defaultProps = {
  className: '',
  hasLogo: true,
  hasTitle: true,
  title: '',
  hasSwitchLang: true,
  hasTagBrand: true,
};

function Header(props) {
  const {
    className,
    hasLogo,
    hasTitle,
    title,
    hasSwitchLang,
    hasTagBrand,
  } = props;

  return (
    <div className={cx('rewards-wl-header', className)}>
      <SwitchLang isVisible={hasSwitchLang} />
      <RewardsWLBrand isVisible={hasLogo} hasTagBrand={hasTagBrand} />
      {hasTitle && !!title && <div>{title}</div>}
    </div>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
