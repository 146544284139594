// @flow
import React from 'react';
import cx from 'classnames';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import { Icon, Constants } from 'bnc-react-components';

import type { User } from '../../../types/user';

import './style.scss';

type Item = {
  user: User,
  isHoverable?: boolean,
  iconName?: string,
  isDeletable?: boolean,
};

type Props = {
  id: string,
  items: Array<Item>,
  onClick?: (item: Item) => void,
  onDelete?: (item: Item) => void,
  textDelete?: string,
};

const defaultProps = {
  textDelete: '',
  onClick: () => {},
  onDelete: () => {},
};

const SelectableList = (props: Props) => {
  const { id, items, onClick, onDelete, textDelete } = props;

  const renderIcon = iconName => (
    <div className="identity__icon">
      <Icon size={30} name={iconName} />
    </div>
  );

  const renderDelete = item => (
    <button
      type="button"
      // $FlowFixMe
      onClick={() => onDelete(item)}
      className="selectableitem__delete"
    >
      {textDelete && (
        <span className="selectableitem__deletelabel">{textDelete}</span>
      )}
      <Icon size={12} name={Constants.ICONS.CLOSE} />
    </button>
  );

  return (
    <div id={id} className="selectablelist">
      {items.map(item => (
        <div
          key={uniqueId('selectableContainer_')}
          className="selectablecontainer"
        >
          <div
            className={cx('selectableitem', { ishoverable: item.isHoverable })}
          >
            {item.isDeletable && renderDelete(item)}
            <button
              type="button"
              // $FlowFixMe
              onClick={() => onClick(item)}
              className="selectableitem__identity"
            >
              {item.iconName && renderIcon(item.iconName)}
              <div className="identity__label">
                {get(item, 'user.login', '')}
              </div>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

SelectableList.defaultProps = defaultProps;

export default SelectableList;
