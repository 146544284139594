import * as actionTypes from './actionTypes';
import type {
  BiometryInitializationAction,
  BiometryInitializationSuccessAction,
  BiometryValidationSuccessAction,
  BiometryValidationFailedAction,
  BiometryForceLogin,
  BiometryCredentialsDeletionSuccessAction,
  BiometryCredentialsSynchronizationError,
  BiometryLaunchCredentialsSynchronization,
} from './types';
import Biometry from './biometry';

export function biometryEvent(type, payload) {
  return {
    type: actionTypes.eventActionTypeMapper[type],
    payload,
  };
}

export function initBiometry(): BiometryInitializationAction {
  return {
    type: actionTypes.BIOMETRY_INITIALIZATION,
  };
}

export function initBiometrySuccess(
  biometry: Biometry,
): BiometryInitializationSuccessAction {
  return {
    type: actionTypes.BIOMETRY_INITIALIZATION_SUCCESS,
    biometry,
  };
}

export function biometryValidationSuccess(
  credentials,
): BiometryValidationSuccessAction {
  return {
    type: actionTypes.BIOMETRY_VALIDATION_SUCCESS,
    credentials,
  };
}

export function biometryValidationError(error): BiometryValidationFailedAction {
  return {
    type: actionTypes.BIOMETRY_VALIDATION_FAILED,
    error,
  };
}
export function biometryForceLogin(): BiometryForceLogin {
  return {
    type: actionTypes.BIOMETRY_FORCE_LOGIN,
  };
}

export function biometryCredentialsDeletionSuccess(): BiometryCredentialsDeletionSuccessAction {
  return {
    type: actionTypes.BIOMETRY_CREDENTIALS_DELETION_SUCCESS,
  };
}

export function biometryCredentialsDeletionFailed(): BiometryCredentialsDeletionSuccessAction {
  return {
    type: actionTypes.BIOMETRY_CREDENTIALS_DELETION_FAILED,
  };
}

export function biometryCredentialsSynchronizationError(): BiometryCredentialsSynchronizationError {
  return {
    type: actionTypes.BIOMETRY_CREDENTIALS_SYNCHRONIZATION_ERROR,
  };
}

export function biometryLaunchCredentialsSynchronization(
  username: string,
  password: string,
): BiometryLaunchCredentialsSynchronization {
  return {
    type: actionTypes.BIOMETRY_LAUNCH_CREDENTIALS_SYNCHRONIZATION,
    username,
    password,
  };
}
