// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';

import FormHandler from '../../../molecules/forms/FormHandler';
import LoginForm from '../../../molecules/forms/LoginForm';

import { FORM_TYPES } from '../../../utils/constants/forms';

type Props = {
  intl: IntlShape,
  className?: string,
  hasForgotPwd?: boolean,
};

const defaultProps = {
  className: '',
  hasForgotPwd: true,
};

function Login(props: Props) {
  const { intl, className, hasForgotPwd } = props;

  const informationalMessage = (
    <>
      <span>{intl.formatMessage({ id: 'rewards.text.new' })}</span>
      {intl.formatMessage({ id: 'rewards.text.whatsnew' })}
    </>
  );

  return (
    <div id="loginForm" className="connect-form">
      <FormHandler
        // $FlowFixMe
        formName={FORM_TYPES.LOGIN_FORM}
      >
        <LoginForm
          className={className}
          hasForgotPwd={hasForgotPwd}
          informationalMessage={informationalMessage}
          signInButtonTheme="transactional"
        />
      </FormHandler>
    </div>
  );
}

Login.defaultProps = defaultProps;

export default Login;
