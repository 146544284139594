import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import split from 'lodash/split';

import { trackAction } from '@nbc-studio/analytics';

import {
  NEW_PASSWORD_FIELD_ERRORS,
  REGEX_NO_SPACES_START_END,
} from '../../../utils/constants/forms';
import {
  UCS_IDS,
  EVENT_NAMES,
  EVENT_IDS,
} from '../../../utils/taggingUtils/constants';

export function voidCopyPasteEvents(e) {
  e.preventDefault();
}

export function isNotValidated(errors, errorType) {
  return includes(errors, errorType);
}

export function validate(
  values,
  hasCurrentPassword,
  validationsArray,
  email = '',
) {
  let errors = {};
  const newPasswordErrors = [];
  const username = email ? email.toLowerCase() : '';

  forEach(validationsArray, validation => {
    if (validation.isNotValidPassword(values)) {
      validation.validationErrorFunction(newPasswordErrors);
    }
  });
  if (!isEmpty(newPasswordErrors)) {
    errors = { ...errors, newPassword: newPasswordErrors };
  } else if (values.newPassword !== values.newPasswordConfirmation) {
    errors = {
      ...errors,
      newPasswordConfirmation: 'text.error.requiredPasswordConfirmation',
    };
  }

  if (username && includes(values.newPassword.toLowerCase(), username)) {
    errors = { ...errors, notContainEmailID: 'text.error.notContainEmailID' };
  }

  if (hasCurrentPassword && !values.currentPassword) {
    errors = { ...errors, currentPassword: NEW_PASSWORD_FIELD_ERRORS.notEmpty };
  }

  if (!values.newPassword) {
    errors = {
      ...errors,
      newPasswordEmpty: NEW_PASSWORD_FIELD_ERRORS.notEmpty,
    };
  }

  return errors;
}

export const taggingFormErrorProps = {
  ucsId: UCS_IDS.FORM_ERROR,
  eventName: EVENT_NAMES.FORM_ERROR,
  eventId: EVENT_IDS.FORM_ERROR,
};

export const taggingErrors = (errors, fieldName) => {
  if (!isEmpty(errors)) {
    switch (fieldName) {
      case 'newPassword': {
        let formError = 'new password:';
        if (errors[fieldName]) {
          errors[fieldName].forEach(error => {
            formError = formError.concat(`${error},`);
          });
          trackAction(EVENT_NAMES.FORM_ERROR, {
            ...taggingFormErrorProps,
            formError,
          });
        }
        break;
      }
      case 'newPasswordConfirmation': {
        if (errors[fieldName]) {
          trackAction(EVENT_NAMES.FORM_ERROR, {
            ...taggingFormErrorProps,
            formError: 'password confirmation:no_match',
          });
        }
        break;
      }
      case 'currentPassword':
      default: {
        if (errors[fieldName]) {
          trackAction(EVENT_NAMES.FORM_ERROR, {
            ...taggingFormErrorProps,
            formError: 'current password:invalid',
          });
        }
        break;
      }
    }
  }
};

const POLICY_TYPES = [
  { policy: 'minLength' },
  { policy: 'minNumber' },
  { policy: 'minLowerCase' },
  { policy: 'minUpperCase' },
  { policy: 'minSymbol' },
  { policy: 'excludeUsername' },
];

const buildCriteriaMap = (intl, complexity) => {
  const validations = {
    [NEW_PASSWORD_FIELD_ERRORS.noSpacesStartEnd]: intl.formatMessage({
      id: 'text.message.passwordNoSpacesStartEnd',
    }),
  };
  forEach(POLICY_TYPES, validationType => {
    const minValue = get(complexity, validationType.policy);
    if (minValue > 0) {
      const validation = get(NEW_PASSWORD_FIELD_ERRORS, validationType.policy);
      if (validation) {
        const plurality = minValue > 1 ? 'plural' : 'singular';
        validations[validation] = intl.formatMessage(
          {
            id: `text.message.password.${plurality}.${validationType.policy}`,
          },
          {
            min: minValue,
          },
        );
      }
    }
  });

  return validations;
};

const buildValidationList = complexity => {
  const validationArray = [
    {
      isNotValidPassword: values =>
        !REGEX_NO_SPACES_START_END.test(values.newPassword),
      validationErrorFunction: errors =>
        errors.push(NEW_PASSWORD_FIELD_ERRORS.noSpacesStartEnd),
    },
  ];

  forEach(POLICY_TYPES, validationType => {
    if (get(complexity, validationType.policy) > 0) {
      switch (validationType.policy) {
        case 'minLength':
          validationArray.push({
            isNotValidPassword: values =>
              values.newPassword.length <
              get(complexity, validationType.policy),
            validationErrorFunction: errors =>
              errors.push(NEW_PASSWORD_FIELD_ERRORS.minLength),
          });
          break;
        case 'minNumber':
          validationArray.push({
            isNotValidPassword: values =>
              !new RegExp(
                `(?=(?:.*[0-9]){${get(complexity, validationType.policy)}})`,
              ).test(values.newPassword),
            validationErrorFunction: errors =>
              errors.push(NEW_PASSWORD_FIELD_ERRORS.minNumber),
          });
          break;
        case 'minLowerCase':
          validationArray.push({
            isNotValidPassword: values =>
              !new RegExp(
                `(?=(?:.*[a-z]){${get(complexity, validationType.policy)}})`,
              ).test(values.newPassword),
            validationErrorFunction: errors =>
              errors.push(NEW_PASSWORD_FIELD_ERRORS.minLowerCase),
          });
          break;
        case 'minUpperCase':
          validationArray.push({
            isNotValidPassword: values =>
              !new RegExp(
                `(?=(?:.*[A-Z]){${get(complexity, validationType.policy)}})`,
              ).test(values.newPassword),
            validationErrorFunction: errors =>
              errors.push(NEW_PASSWORD_FIELD_ERRORS.minUpperCase),
          });
          break;
        case 'minSymbol':
          validationArray.push({
            isNotValidPassword: values =>
              !new RegExp(
                `(?=(?:.*[^a-zA-Z0-9_ ]){${get(
                  complexity,
                  validationType.policy,
                )}})`,
              ).test(values.newPassword),
            validationErrorFunction: errors =>
              errors.push(NEW_PASSWORD_FIELD_ERRORS.minSymbol),
          });
          break;
        default:
          break;
      }
    }
  });

  return validationArray;
};

export const buildValidations = (intl, complexity) => ({
  criteriaMap: buildCriteriaMap(intl, complexity),
  validationArray: buildValidationList(complexity),
});

export const decodeHash = hashLogin => {
  if (!hashLogin) {
    return {
      email: null,
      password: null,
    };
  }
  const identityInfo = split(hashLogin, ':');
  return {
    email: Buffer.from(identityInfo[0], 'base64').toString('utf8'),
    password: Buffer.from(identityInfo[1], 'base64').toString('utf8'),
  };
};
