import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Button } from '@bnc-ui/button';

import BackLinkButton from '../../../molecules/common/BackLinkButton/backLinkButton';

import './style.scss';

const propTypes = {
  intl: intlShape.isRequired,
  onClickFunction: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  resetForm: PropTypes.func,
};

const defaultProps = {
  resetForm: () => null,
};

const ResetPasswordButtonSection = props => {
  const { onClickFunction, resetForm, isProcessing, intl } = props;

  return (
    <>
      <div id="resetPasswordFormSubmit" className="reset-password-form__submit">
        <Button
          fluid
          appearance="primary"
          type="submit"
          size="large"
          id="reset-password-form_submit"
          disabled={isProcessing}
          onClick={onClickFunction}
        >
          {isProcessing
            ? intl.formatMessage({
                id: 'global.button.label.pending',
              })
            : intl.formatMessage({
                id: 'global.button.confirm.label',
              })}
        </Button>
      </div>
      <BackLinkButton
        label={intl.formatMessage({ id: 'global.button.backToLogin' })}
        onClick={resetForm}
      />
    </>
  );
};

ResetPasswordButtonSection.propTypes = propTypes;
ResetPasswordButtonSection.defaultProps = defaultProps;

export default ResetPasswordButtonSection;
