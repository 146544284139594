import { combineReducers } from 'redux';
import { expectSaga } from 'redux-saga-test-plan';

import { trackState } from '@nbc-studio/analytics';
import {
  createRootSagaFromWorkersMapping,
  createWorkersMapping,
} from 'bnc-utilities-js/saga';

import { FUNCTIONALITIES } from '../../../utils/taggingUtils/constants';

import {
  getContext,
  watchTaggingForgotPasswordEvents,
} from '../taggingWorkers';
import forgotPasswordFormReducer from '../../forgotPasswordFormService/reducer';
import {
  FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE,
  FORGOT_PASSWORD_FORM_INIT_DONE,
} from '../../forgotPasswordFormService/actionTypes';

import { sendAnalytics } from '../actions';
import forgotPasswordService from '../index';
import {
  FORGOT_PASSWORD_SEND_ANALYTICS,
  FORGOT_PASSWORD_SET_PASSWORD_SUCCESS,
} from '../actionTypes';

jest.mock('@nbc-studio/analytics', () => ({
  trackState: jest.fn(),
}));

global.Date = jest.fn();

describe('forgotPassword - taggingWorkers page load event', () => {
  const rootReducer = combineReducers({
    [forgotPasswordService.namespace]: forgotPasswordFormReducer,
  });

  const saga = createRootSagaFromWorkersMapping(
    createWorkersMapping().takeLatest(
      [
        FORGOT_PASSWORD_FORM_INIT_DONE,
        FORGOT_PASSWORD_SEND_ANALYTICS,
        FORGOT_PASSWORD_SET_PASSWORD_SUCCESS,
      ],
      watchTaggingForgotPasswordEvents,
    ),
  );

  const testCases = [
    {
      actionType: FORGOT_PASSWORD_FORM_INIT_DONE,
      dispatchFn: () => sendAnalytics(FORGOT_PASSWORD_FORM_INIT_DONE),
      comments: 'Forgot password choose option',
    },
    {
      actionType: FORGOT_PASSWORD_SET_PASSWORD_SUCCESS,
      dispatchFn: () => sendAnalytics(FORGOT_PASSWORD_SET_PASSWORD_SUCCESS),
      comments: 'Forgot password - confirmation',
    },
  ];

  testCases.forEach(({ actionType, stepName, dispatchFn, comments }) =>
    test(`it should call trackState on ${comments}`, async () => {
      trackState.mockClear();
      await expectSaga(saga)
        .withReducer(rootReducer)
        .call(
          trackState,
          FUNCTIONALITIES.FORGOT_PASSWORD,
          getContext({ type: actionType, stepName }, 'fr'),
        )
        .dispatch(dispatchFn())
        .run();
    }),
  );
});

describe('ForgotPassword - taggingWorkers preference choice', () => {
  it('Context does not exist ', async () => {
    const context = getContext({
      type: 'XXXXXX',
    });

    expect(context).toEqual({});
  });

  describe('ForgotPassword - taggingWorkers error', () => {
    it('Error field email', async () => {
      const context = getContext({
        type: FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE,
        fieldName: 'email',
      });

      expect(context.ucsState).toEqual('non-pv');
      expect(context.formError).toEqual('email:empty');
    });

    it('Error field birthDate', async () => {
      const context = getContext({
        type: FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE,
        fieldName: 'birthDate',
      });

      expect(context.ucsState).toEqual('non-pv');
      expect(context.formError).toEqual('birthDate:invalid');
    });

    it('Error field expiryDate', async () => {
      const context = getContext({
        type: FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE,
        fieldName: 'expiryDate',
      });

      expect(context.ucsState).toEqual('non-pv');
      expect(context.formError).toEqual('expiryDate:invalid');
    });

    it('Error field cardNumber', async () => {
      const context = getContext({
        type: FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE,
        fieldName: 'cardNumber',
      });

      expect(context.ucsState).toEqual('non-pv');
      expect(context.formError).toEqual('cardNumber:invalid');
    });
  });
});
