import includes from 'lodash/includes';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';

import {
  INADEQUATE_PASSWORD_CODE,
  PASSWORD_POLICIES_ERROR_CODE,
} from '../constants/forgotPassword';

export const getTransformedTempErrorCode = backendError => {
  const errorCode = get(backendError, 'message', '');
  if (
    includes(errorCode, 'HISTORY_COUNT') ||
    includes(errorCode, 'POLICIES_UNMET')
  ) {
    return PASSWORD_POLICIES_ERROR_CODE;
  }
  if (includes(errorCode, 'INADEQUATE_PASSWORD')) {
    return INADEQUATE_PASSWORD_CODE;
  }
  return '';
};

export const getBackendErrorMessage = (backendError, isTempPassword) => {
  let errorCode = '';
  if (isTempPassword) {
    errorCode = getTransformedTempErrorCode(backendError);
  } else {
    errorCode = get(backendError, 'code', '');
  }
  const policiesOrDictionnaryError =
    errorCode === INADEQUATE_PASSWORD_CODE ||
    errorCode === PASSWORD_POLICIES_ERROR_CODE;

  return policiesOrDictionnaryError
    ? `text.error.iamx.${camelCase(errorCode)}`
    : 'text.message.passwordChangeFailed';
};
