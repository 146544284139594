import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { intlShape, FormattedHTMLMessage } from 'react-intl';

import { Phone } from '@nbc-design/icons/lib/web/Phone';
import { Alert } from '@bnc-ui/alert';

import { getPhoneAssistanceNumber } from '../../../utils/templateUtils';
import { ASSISTANT_PHONE_TYPE } from '../../../utils/constants/phoneType';

import './style.scss';

const propTypes = {
  intl: intlShape.isRequired,
  className: PropTypes.string,
  templateName: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
};

const defaultProps = {
  className: '',
};

const ForbiddenContactAlert = props => {
  const { intl, className, templateName, partnerId } = props;

  const phoneNumber = getPhoneAssistanceNumber(
    templateName,
    partnerId,
    intl.locale,
    ASSISTANT_PHONE_TYPE.INTERNATIONAL,
  );

  return (
    <Alert
      className={cx('forbidden-contact__alert', className)}
      appearance="minimal"
    >
      <Phone size="large" />
      <FormattedHTMLMessage
        className="contact-info__message"
        id="text.message.forbiddenContact"
        values={{ assitancePhone: phoneNumber }}
      />
    </Alert>
  );
};

ForbiddenContactAlert.propTypes = propTypes;
ForbiddenContactAlert.defaultProps = defaultProps;

export default ForbiddenContactAlert;
