// @flow
import React from 'react';
import cx from 'classnames';

import ContinueAnyway from '../../../molecules/common/ContinueAnyway';
import Close from '../../../molecules/common/Close';

import './style.scss';
import { Browser } from '../../../types/browser';

type Props = {
  browser: Browser,
  className?: string,
};

const defaultProps = {
  className: '',
};

function UnsupportedBrowserFooter(props: Props) {
  const { className, browser } = props;
  const browserVersion = parseInt(browser.version, 10);
  if (browser.name === 'IE' && browserVersion <= 10) {
    return (
      <div className={cx('unsupported-browser-footer', className)}>
        <Close />
      </div>
    );
  }
  return (
    <div className={cx('unsupported-browser-footer', className)}>
      <ContinueAnyway />
    </div>
  );
}

UnsupportedBrowserFooter.defaultProps = defaultProps;

export default UnsupportedBrowserFooter;
