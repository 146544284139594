import * as yup from 'yup';
import get from 'lodash/get';
import { isValidIdentity } from 'bnc-utilities-js/formUtils/email';

import { isFieldValid, isFormValid } from '../../utils/formUtils';
import {
  FORGOT_PASSWORD_PRODUCT_TYPE,
  FORGOT_PASSWORD_FIELDS,
  FORGOT_PASSWORD_VALIDATION_RULES,
} from '../../utils/forgotPasswordUtils/constants';

// Custom schema methods
yup.addMethod(yup.string, 'validateEmail', function testEmail(message) {
  return this.test('testEmail', message, value => isValidIdentity(value));
});

yup.addMethod(yup.string, 'validateBirthdate', function testBirthDate(message) {
  return this.test('testBirthdate', message, value => value !== '');
});

// Basic schema
const forgotPasswordFormSchema = {
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL]: yup
    .string()
    .default('')
    .validateEmail('text.error.field.email'),
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE]: yup
    .string()
    .typeError('text.error.field.birthdate')
    .required('text.error.field.birthdate')
    .validateBirthdate('text.error.field.birthdate'),
};

const forgotPasswordSecurityFormSchema = {
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA]: yup
    .object()
    .required('text.error.field.recaptcha'),
};

const forgotPasswordExpiryDateFormSchema = {
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE]: yup
    .string()
    .required('text.error.field.expirationDateIncorrectFormat')
    .matches(FORGOT_PASSWORD_VALIDATION_RULES.REGEX.CARD_EXPIRY_DATE, {
      message: 'text.error.field.expirationDateIncorrectFormat',
    }),
};

const forgotPasswordOriginalAmountFormSchema = {
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM
    .ORIGINAL_AMOUNT]: yup
    .string()
    .required('text.error.field.originalAmountIncorrectFormat'),
};

const forgotPasswordPhoneFormSchema = {
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER]: yup
    .string()
    .required('text.error.field.phoneNumberIncorrectFormat')
    .matches(FORGOT_PASSWORD_VALIDATION_RULES.REGEX.PHONE_NUMBER, {
      message: 'text.error.field.phoneNumberIncorrectFormat',
    }),
};

// Debit schema
const forgotPasswordFormDebitSchema = yup.object({
  ...forgotPasswordFormSchema,
  ...forgotPasswordSecurityFormSchema,
  ...forgotPasswordExpiryDateFormSchema,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER]: yup
    .string()
    .required('text.error.field.debitCardNumberIncorrectFormat')
    .max(
      FORGOT_PASSWORD_VALIDATION_RULES.DEBIT.CARD_LENGTH,
      'text.error.field.debitCardNumberIncorrectFormat',
    )
    .matches(FORGOT_PASSWORD_VALIDATION_RULES.REGEX.DEBIT_CARD_NUMBER, {
      message: 'text.error.field.debitCardNumberIncorrectFormat',
    }),
});

// Personal Loan schema
const forgotPasswordFormLoanSchema = yup.object({
  ...forgotPasswordFormSchema,
  ...forgotPasswordSecurityFormSchema,
  ...forgotPasswordOriginalAmountFormSchema,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER]: yup
    .string()
    .required('text.error.field.loanNumberIncorrectFormat')
    .max(
      FORGOT_PASSWORD_VALIDATION_RULES.LOAN.ACCOUNT_MAX_LENGTH,
      'text.error.field.loanNumberNumberIncorrectFormat',
    )
    .min(
      FORGOT_PASSWORD_VALIDATION_RULES.LOAN.ACCOUNT_MIN_LENGTH,
      'text.error.field.loanNumberNumberIncorrectFormat',
    ),
});

// Credit card schema
const forgotPasswordFormCreditSchema = yup.object({
  ...forgotPasswordFormSchema,
  ...forgotPasswordSecurityFormSchema,
  ...forgotPasswordExpiryDateFormSchema,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER]: yup
    .string()
    .required('text.error.field.creditCardNumberIncorrectFormat')
    .max(
      FORGOT_PASSWORD_VALIDATION_RULES.CREDIT.CARD_LENGTH,
      'text.error.field.creditCardNumberIncorrectFormat',
    )
    .matches(FORGOT_PASSWORD_VALIDATION_RULES.REGEX.CREDIT_CARD_NUMBER, {
      message: 'text.error.field.creditCardNumberIncorrectFormat',
    }),
});

// Investment schema
const forgotPasswordFormInvestmentSchema = yup.object({
  ...forgotPasswordFormSchema,
  ...forgotPasswordSecurityFormSchema,
  ...forgotPasswordPhoneFormSchema,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER]: yup
    .string()
    .required('text.error.field.investmentAccountNumberIncorrectFormat')
    .max(
      FORGOT_PASSWORD_VALIDATION_RULES.INVESTMENT.ACCOUNT_LENGTH,
      'text.error.field.investmentAccountNumberIncorrectFormat',
    ),
});

// Mortgage schema
const forgotPasswordFormMortgageSchema = yup.object({
  ...forgotPasswordFormSchema,
  ...forgotPasswordSecurityFormSchema,
  ...forgotPasswordOriginalAmountFormSchema,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER]: yup
    .string()
    .required('text.error.field.mortgageAccountNumberIncorrectFormat')
    .min(
      FORGOT_PASSWORD_VALIDATION_RULES.MORTGAGE.ACCOUNT_MIN_LENGTH,
      'text.error.field.mortgageAccountNumberIncorrectFormat',
    )
    .max(
      FORGOT_PASSWORD_VALIDATION_RULES.MORTGAGE.ACCOUNT_MAX_LENGTH,
      'text.error.field.mortgageAccountNumberIncorrectFormat',
    )
    .matches(FORGOT_PASSWORD_VALIDATION_RULES.REGEX.MORTGAGE_ACCOUNT_NUMBER, {
      message: 'text.error.field.mortgageAccountNumberIncorrectFormat',
    }),
});

// Prospect
const forgotPasswordFormProspectSchema = yup.object({
  ...forgotPasswordFormSchema,
  ...forgotPasswordSecurityFormSchema,
  ...forgotPasswordPhoneFormSchema,
});

// Brokerage schema
const forgotPasswordFormBrokerageSchema = yup.object({
  ...forgotPasswordFormSchema,
  ...forgotPasswordSecurityFormSchema,
  ...forgotPasswordPhoneFormSchema,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER]: yup
    .string()
    .required('text.error.field.brokerageAccountNumberIncorrectFormat')
    .max(
      FORGOT_PASSWORD_VALIDATION_RULES.INVESTMENT.ACCOUNT_LENGTH,
      'text.error.field.brokerageAccountNumberIncorrectFormat',
    ),
});

// getter for validation schema
const getForgotPasswordFormSchema = productType => {
  switch (productType) {
    case FORGOT_PASSWORD_PRODUCT_TYPE.DEBIT:
      return forgotPasswordFormDebitSchema;
    case FORGOT_PASSWORD_PRODUCT_TYPE.LOAN:
      return forgotPasswordFormLoanSchema;
    case FORGOT_PASSWORD_PRODUCT_TYPE.CREDIT:
      return forgotPasswordFormCreditSchema;
    case FORGOT_PASSWORD_PRODUCT_TYPE.INVESTMENT:
      return forgotPasswordFormInvestmentSchema;
    case FORGOT_PASSWORD_PRODUCT_TYPE.MORTGAGE:
      return forgotPasswordFormMortgageSchema;
    case FORGOT_PASSWORD_PRODUCT_TYPE.PROSPECT:
      return forgotPasswordFormProspectSchema;
    case FORGOT_PASSWORD_PRODUCT_TYPE.INVESTMENT_BROKERAGE:
      return forgotPasswordFormBrokerageSchema;
    default:
      return yup.object({
        ...forgotPasswordFormSchema,
      });
  }
};

export function fieldValidation(formData, action) {
  return isFieldValid(
    formData,
    action,
    getForgotPasswordFormSchema(
      get(formData, FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PRODUCT_TYPE),
    ),
  );
}

export function formValidation(formData) {
  return isFormValid(
    formData,
    getForgotPasswordFormSchema(
      get(formData, FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PRODUCT_TYPE),
    ),
  );
}
