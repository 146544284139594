// @flow
import React from 'react';
import sessionManager from 'bnc-react-services/managers/SessionManager';

import ResetPasswordForm from '../../../molecules/forms/ResetPasswordForm';

type Props = {
  isFetching: boolean,
  isSubmitFailed: boolean,
  returnToLogin: () => void,
  announceAlertMessage: (message: string) => void,
  resetPasswordRequest: (
    newPassword: string,
    passwordIsExpired: boolean,
    currentPassword?: string,
  ) => void,
};

function ResetPassword(props: Props) {
  const {
    isFetching,
    isSubmitFailed,
    returnToLogin,
    announceAlertMessage,
    resetPasswordRequest,
  } = props;
  const transactionStatus = sessionManager.getTransactionStatus();

  return (
    <ResetPasswordForm
      type={transactionStatus}
      isProcessing={isFetching}
      submitFailed={isSubmitFailed}
      returnToLogin={returnToLogin}
      announceAlertMessage={announceAlertMessage}
      resetPasswordRequest={resetPasswordRequest}
    />
  );
}

export default ResetPassword;
