// @flow
import { namespace as liveAnnouncerServiceNamespace } from 'bnc-react-services/services/LiveAnnouncerService';
import liveAnnouncerServiceReducer from 'bnc-react-services/services/LiveAnnouncerService/reducer';
import type { Handlers } from '../../types/reducers';

import formHandlerReducer from '../../services/formHandlerService/reducer';
import i18nReducer from '../../services/i18nService/reducer';
import loginReducer from '../../services/loginService/reducer';
import templateReducer from '../../services/templateService/reducer';
import loginFormReducer from '../../services/loginFormService/reducer';
import multiFactorAuthFormReducer from '../../services/multiFactorAuthFormService/reducer';
import userAgentServiceReducer from '../../services/userAgentService/reducer';
import unsupportedBrowserServiceReducer from '../../services/unsupportedBrowserService/reducer';
import forgotPasswordFormServiceReducer from '../../services/forgotPasswordFormService/reducer';
import forgotPasswordFormService from '../../services/forgotPasswordFormService';
import forgotPasswordServiceReducer from '../../services/forgotPasswordService/reducer';
import forgotPasswordService from '../../services/forgotPasswordService';
import tokenCaptchaServiceReducer from '../../services/tokenCaptchaService/reducer';
import tokenCaptchaService from '../../services/tokenCaptchaService';
import configsServiceReducer from '../../services/configsService/reducer';
import modalServiceReducer from '../../services/modalService/reducer';
import redirectService from '../../services/redirectService';
import redirectServiceReducer from '../../services/redirectService/reducer';
import resetPasswordReducer from '../../services/resetPasswordService/reducer';
import biometryServiceReducer from '../../services/biometryService/reducer';

import { isWebView } from '../../utils/browserUtils';

import {
  I18N_SERVICE_REDUCER,
  LOGIN_SERVICE_REDUCER,
  FORM_HANDLER_SERVICE_REDUCER,
  TEMPLATE_SERVICE_REDUCER,
  LOGIN_FORM_SERVICE_REDUCER,
  MFA_FORM_SERVICE_REDUCER,
  UNSUPPORTED_BROWSER_SERVICE_REDUCER,
  USER_AGENT_SERVICE_REDUCER,
  CONFIGS_SERVICE_REDUCER,
  MODAL_SERVICE_REDUCER,
  RESET_PASSWORD_REDUCER,
  BIOMETRY_SERVICE_REDUCER,
} from './constants';

const reducers: Handlers = {
  [FORM_HANDLER_SERVICE_REDUCER]: formHandlerReducer,
  [I18N_SERVICE_REDUCER]: i18nReducer,
  [LOGIN_SERVICE_REDUCER]: loginReducer,
  [TEMPLATE_SERVICE_REDUCER]: templateReducer,
  [LOGIN_FORM_SERVICE_REDUCER]: loginFormReducer,
  [MFA_FORM_SERVICE_REDUCER]: multiFactorAuthFormReducer,
  [USER_AGENT_SERVICE_REDUCER]: userAgentServiceReducer,
  [UNSUPPORTED_BROWSER_SERVICE_REDUCER]: unsupportedBrowserServiceReducer,
  [forgotPasswordFormService.namespace]: forgotPasswordFormServiceReducer,
  [forgotPasswordService.namespace]: forgotPasswordServiceReducer,
  [tokenCaptchaService.namespace]: tokenCaptchaServiceReducer,
  [CONFIGS_SERVICE_REDUCER]: configsServiceReducer,
  [MODAL_SERVICE_REDUCER]: modalServiceReducer,
  [redirectService.namespace]: redirectServiceReducer,
  [liveAnnouncerServiceNamespace]: liveAnnouncerServiceReducer,
  [RESET_PASSWORD_REDUCER]: resetPasswordReducer,
};

if (isWebView()) {
  reducers[[BIOMETRY_SERVICE_REDUCER]] = biometryServiceReducer;
}

export default reducers;
