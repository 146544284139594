export const testForm = {
  field1: { name: 'field1' },
  field2: { name: 'field2' },
};

export const formName = 'formName';

export const errors = {
  field1: 'field1-error-msg',
  field2: ['field2-error-msg', 'field2-pattern-error'],
};
