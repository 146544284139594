import { getDtmMappedValue } from '../configUtils';

/**
 * @typedef {Object} SendFunctionalityOptions
 * @property {string|string[]} name supplier name or array of suppliers name if long pay.
 * @property {number|number[]} amount transfer or payment amount or array of amount if long pay.
 * @property {string|string[]} frequency recurrent transfer or payment frequency or array of frequency if long pay.
 * @property {number|number[]} repeat recurrent transfer or payment number of repeat or array of repeat if long pay.
 */
/**
 * Function to build options object for sendFunctionnality function.
 * @param {?string|string[]} name supplier name or array of suppliers name if long pay.
 * @param {?number|number[]} amount transfer or payment amount or array of amount if long pay.
 * @param {?string|string[]} frequency recurrent transfer or payment frequency or payment frequency or array of frequency if long pay.
 * @param {?number|number[]} iteration recurrent transfer or payment number of repeat or payment number of repeat or array of repeat if long pay.
 * @return {SendFunctionalityOptions} options object for sendFunctionnality function.
 */
export function buildOptionsObjectFromData(
  name = null,
  amount = null,
  frequency = null,
  iteration = null,
) {
  return {
    name,
    amount,
    frequency,
    repeat: iteration,
  };
}

export const getDtmPageObject = templateName => {
  const dtmMappedTemplateName = getDtmMappedValue(templateName);
  return {
    LOGIN: {
      uri: `${dtmMappedTemplateName}:login`,
      type: `${dtmMappedTemplateName}:Login`,
      context: dtmMappedTemplateName,
    },
    LOGIN_VERIFY: {
      uri: `${dtmMappedTemplateName}:login:verify`,
      type: `${dtmMappedTemplateName}:Login`,
      context: dtmMappedTemplateName,
    },
    NOT_FOUND: {
      uri: `${dtmMappedTemplateName}:404`,
      type: `${dtmMappedTemplateName}:404`,
      context: dtmMappedTemplateName,
    },
  };
};
