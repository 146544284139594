import React from 'react';
import cx from 'classnames';

import type { Node } from 'react';

import BncBrandEnterprise from '../../../molecules/common/BncBrandEnterprise';
import SwitchLang from '../../../molecules/common/SwitchLang';

import './style.scss';

type Props = {
  className?: string,
  hasLogo?: boolean,
  hasTitle?: boolean,
  title?: Node | string,
  hasSwitchLang?: boolean,
};

const defaultProps = {
  className: '',
  hasLogo: true,
  hasTitle: true,
  title: '',
  hasSwitchLang: true,
};

function Header(props: Props) {
  const { className, hasLogo, hasTitle, title, hasSwitchLang } = props;

  return (
    <div className={cx('header', className)} id="header-entreprise">
      <SwitchLang
        isVisible={hasSwitchLang}
        className="switch-lang--entreprise"
      />
      <BncBrandEnterprise isVisible={hasLogo} />
      {hasTitle && !!title && <h1>{title}</h1>}
    </div>
  );
}

Header.defaultProps = defaultProps;

export default Header;
