// @flow
import { select, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import redirectServiceActions from '../redirectService/actions';

import browserHistory from '../../nav/BrowserHistoryManager/index';
import { ROUTES } from '../../utils/constants';
import {
  TEMPLATE_SERVICE_REDUCER,
  I18N_SERVICE_REDUCER,
} from '../../globalRedux/reducers/constants';
import {
  getAppBaseUrl,
  getSignUpPath,
  getWithoutSignInPath,
} from '../../utils/configUtils';
import TEMPLATES from '../../utils/constants/template';
import {
  forgotPasswordUnavailableCheck,
  resetForgotPasswordFlow,
} from '../forgotPasswordService/actions';
import { getForgotPasswordUrl } from '../../utils/bne/EnvironmentUtils';

export function watchLoginMFA(): void {
  browserHistory.push(`${ROUTES.MFA}${window.location.search}`);
}

export function watchLoginRSA(): void {
  browserHistory.push(`${ROUTES.RSA}${window.location.search}`);
}

export function watchLoginMFAChoice(): void {
  browserHistory.push(`${ROUTES.MFA_CHOICE}${window.location.search}`);
}

export function* watchLoginPasswordExpired(): Saga<void> {
  const { templateName } = (yield select())[TEMPLATE_SERVICE_REDUCER];

  if (templateName === TEMPLATES.BNE) {
    window.location.href = `${getForgotPasswordUrl()}`;
  } else {
    browserHistory.push(`${ROUTES.RESETPASSWORD}${window.location.search}`);
    yield put(forgotPasswordUnavailableCheck(ROUTES.RESETPASSWORD));
  }
}

export function watchLoginLocked(): void {
  browserHistory.push(`${ROUTES.ACCOUNT_LOCKED}${window.location.search}`);
}

export function watchLoginPasswordException(): void {
  browserHistory.push(ROUTES.RESET_PASSWORD_EXCEPTION);
}

export function* watchLoginSignUp(): Saga<void> {
  const { templateName, partnerId } = (yield select())[
    TEMPLATE_SERVICE_REDUCER
  ];
  const { locale } = (yield select())[I18N_SERVICE_REDUCER];
  const template =
    templateName === TEMPLATES.REWARDS ? TEMPLATES.SBIP2 : templateName;
  const baseSignUpUrl = getAppBaseUrl(template, partnerId, locale);

  const signUpPath = getSignUpPath();
  yield put(
    redirectServiceActions.redirectToPage(`${baseSignUpUrl}/${signUpPath}`),
  );
}
export function* watchLoginAppOrigin(): Saga<void> {
  const { templateName, partnerId } = (yield select())[
    TEMPLATE_SERVICE_REDUCER
  ];
  const { locale } = (yield select())[I18N_SERVICE_REDUCER];
  const baseSignUpUrl = getAppBaseUrl(templateName, partnerId, locale);
  yield put(redirectServiceActions.redirectToPage(baseSignUpUrl));
}

export function* watchLoginForgotPassword(): Saga<void> {
  yield put(resetForgotPasswordFlow());
  browserHistory.push(`${ROUTES.FORGOT_PASSWORD}${window.location.search}`);
  yield put(forgotPasswordUnavailableCheck(ROUTES.FORGOT_PASSWORD));
}

export function watchLoginForgotPasswordUnavailable(): void {
  browserHistory.push(
    `${ROUTES.FORGOT_PASSWORD_UNAVAILABLE}${window.location.search}`,
  );
}

export function watchLoginForgotPasswordException(): void {
  browserHistory.push(
    `${ROUTES.FORGOT_PASSWORD_EXCEPTION}${window.location.search}`,
  );
}

export function watchLoginResetPassword() {
  browserHistory.push(ROUTES.RESETPASSWORD);
}

export function* watchLoginRouteWithoutSignIn(): Saga<void> {
  const { locale } = (yield select())[I18N_SERVICE_REDUCER];
  const withoutSignInPath = getWithoutSignInPath(locale);

  yield put(redirectServiceActions.redirectToPage(withoutSignInPath));
}
