import {
  getBackendErrorMessage,
  getTransformedTempErrorCode,
} from '../helpers';
import {
  INADEQUATE_PASSWORD_CODE,
  PASSWORD_POLICIES_ERROR_CODE,
} from '../../constants/forgotPassword';

const transformedErrorCodeCases = [
  {
    backendError: {
      message: 'HISTORY_COUNT',
    },
    expectedValue: PASSWORD_POLICIES_ERROR_CODE,
  },
  {
    backendError: {
      message: 'POLICIES_UNMET',
    },
    expectedValue: PASSWORD_POLICIES_ERROR_CODE,
  },
  {
    backendError: {
      message: 'INADEQUATE_PASSWORD',
    },
    expectedValue: INADEQUATE_PASSWORD_CODE,
  },
  {
    backendError: {
      message: 'OTHER_MESSAGE',
    },
    expectedValue: '',
  },
];
describe('ResetPassword helper - getTransformedTempErrorCode', () => {
  transformedErrorCodeCases.forEach(({ backendError, expectedValue }) => {
    it(`should return ${expectedValue} for the message ${backendError.message}`, () => {
      const receivedValues = getTransformedTempErrorCode(backendError);
      expect(receivedValues).toEqual(expectedValue);
    });
  });
});

const backendDetails = [
  {
    backendError: {
      message: 'HISTORY_COUNT',
    },
    isTempPassword: true,
    expectedValue: 'text.error.iamx.passwordPoliciesError',
  },
  {
    backendError: {
      code: INADEQUATE_PASSWORD_CODE,
    },
    isTempPassword: false,
    expectedValue: 'text.error.iamx.inadequatePassword',
  },
  {
    backendError: {
      code: '',
    },
    isTempPassword: false,
    expectedValue: 'text.message.passwordChangeFailed',
  },
];
describe('ResetPassword helper - getBackendErrorMessage', () => {
  backendDetails.forEach(({ backendError, isTempPassword, expectedValue }) => {
    it(`should return ${expectedValue} for the message ${backendError.message}`, () => {
      const receivedValues = getBackendErrorMessage(
        backendError,
        isTempPassword,
      );
      expect(receivedValues).toEqual(expectedValue);
    });
  });
});
