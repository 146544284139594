import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import BankProductChoice from './bankProductChoice';
import {
  getPartnerIdName,
  getTemplateName,
} from '../../../../services/templateService/selectors';

const mapStateToProps = state => ({
  templateName: getTemplateName(state),
  partnerId: getPartnerIdName(state),
});

export default injectIntl(connect(mapStateToProps)(BankProductChoice));
