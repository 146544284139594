// @flow
import React from 'react';

import Sbip2PasswordChangeSuccessTemplate from '../../templates/sbip2/PasswordChangeSuccessTemplate';
import WhiteLabelPasswordChangeSuccessTemplate from '../../templates/whiteLabel/PasswordChangeSuccessTemplate';
import NatgoPasswordChangeSuccessTemplate from '../../templates/natgo/PasswordChangeSuccessTemplate';
import RewardsPasswordChangeSuccessTemplate from '../../templates/rewards/PasswordChangeSuccessTemplate';
import BaoPasswordChangeSuccessTemplate from '../../templates/bao/PasswordChangeSuccessTemplate';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';

type Props = {
  templateName: string,
};

const PasswordChangeSuccess = (props: Props) => {
  const { templateName } = props;

  const loginTemplates = {
    [TEMPLATES.SBIP2]: <Sbip2PasswordChangeSuccessTemplate />,
    [TEMPLATES.OBE]: <Sbip2PasswordChangeSuccessTemplate />,
    [TEMPLATES.SBIP2_WHITE_LABEL]: <WhiteLabelPasswordChangeSuccessTemplate />,
    [TEMPLATES.NATGO]: <NatgoPasswordChangeSuccessTemplate />,
    [TEMPLATES.REWARDS]: <RewardsPasswordChangeSuccessTemplate />,
    [TEMPLATES.BAO]: <BaoPasswordChangeSuccessTemplate />,
  };
  return getTemplateComponent(loginTemplates, templateName);
};

export default PasswordChangeSuccess;
