import { connect } from 'react-redux';

import ForgotPassword from './forgotPassword';

import {
  formUpdate,
  triggerFieldValidation,
  triggerFormValidation,
} from '../../../services/forgotPasswordFormService/actions';
import { returnToLogin } from '../../../services/loginService/actions';
import {
  formDataSelector,
  isTokenCaptchaError,
  errorsSelector,
} from '../../../services/forgotPasswordFormService/selectors';
import {
  isFetchingSelector,
  failureSelector,
  getBackendError,
  getNumberOfRetry,
} from '../../../services/forgotPasswordService/selectors';
import {
  getPartnerIdName,
  getTemplateName,
} from '../../../services/templateService/selectors';

const mapStateToProps = state => ({
  formData: formDataSelector(state),
  isFetching: isFetchingSelector(state),
  isFailure: failureSelector(state),
  backendError: getBackendError(state),
  numberOfRetry: getNumberOfRetry(state),
  isTokenCaptchaError: isTokenCaptchaError(state),
  templateName: getTemplateName(state),
  partnerId: getPartnerIdName(state),
  errors: errorsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  formUpdate: (fieldName, fieldValue) =>
    dispatch(formUpdate(fieldName, fieldValue)),
  triggerFieldValidation: fieldName =>
    dispatch(triggerFieldValidation(fieldName)),
  triggerFormValidation: () => dispatch(triggerFormValidation()),
  returnToLogin: () => dispatch(returnToLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
