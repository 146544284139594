// @flow
import * as actionTypes from './actionTypes';

import type { ConfigsState, Action } from './types';
import { CONFIG_KEYS, OPTION_PATH } from '../../utils/constants/configs';

export const defaultState: ConfigsState = {
  // export for test
  configs: {
    status: {
      [CONFIG_KEYS.GLOBAL]: {
        active: true,
        date: '',
      },
      [CONFIG_KEYS.SIGN_UP.GLOBAL]: {
        active: true,
      },
      [CONFIG_KEYS.FORGOT_PASSWORD.GLOBAL]: {
        active: true,
        [OPTION_PATH]: {
          [CONFIG_KEYS.FORGOT_PASSWORD.CREDIT_CARD]: {
            active: true,
          },
        },
      },
      [CONFIG_KEYS.LOGIN.GLOBAL]: {
        [OPTION_PATH]: {
          [CONFIG_KEYS.LOGIN.ALERT]: {
            active: false,
          },
        },
      },
      [CONFIG_KEYS.CHATBOT.GLOBAL]: {
        active: false,
      },
    },
  },
  hasConfig: false,
  error: null,
};

export const initialState = () => defaultState;

export default (
  state: ConfigsState = initialState(),
  action: Action,
): ConfigsState => {
  switch (action.type) {
    case actionTypes.CONFIGS_SERVICE_RETRIEVE_CONFIGS_SUCCESS:
      return {
        ...state,
        // $FlowFixMe
        configs: action.configs,
        hasConfig: true,
      };
    case actionTypes.CONFIGS_SERVICE_RETRIEVE_CONFIGS_FAILURE:
      return {
        ...state,
        // $FlowFixMe
        error: action.err,
        hasConfig: true,
      };
    default:
      return state;
  }
};
