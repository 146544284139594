import React from 'react';

import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';
import CompleteUnavailability from '../../../organisms/common/CompleteUnavailability';
import Header from '../../../organisms/whiteLabel/Header';

const CompleteUnavailabilityTemplate = () => (
  <FullScreenFooterTemplate
    className="error-background"
    header={<Header />}
    main={<CompleteUnavailability />}
  />
);

export default CompleteUnavailabilityTemplate;
