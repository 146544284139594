import get from 'lodash/get';
import service from '.';

export const rootSelector = state => get(state, service.namespace, {});

export const isFetchingSelector = state =>
  get(rootSelector(state), 'isFetching', false);

export const failureSelector = state =>
  get(rootSelector(state), 'failure', false);

export const sentAtSelector = state => get(rootSelector(state), 'sentAt', '');

export const profileTypeSelector = state =>
  get(rootSelector(state), 'profileType', '');

export const recoveryTokenSelector = state =>
  get(rootSelector(state), 'recoveryToken', '');

export const exceptionTypeSelector = state =>
  get(rootSelector(state), 'exceptionType', '');

export const identificationErrorSelector = state =>
  get(rootSelector(state), 'identificationError', '');

export const getBackendError = state =>
  get(rootSelector(state), 'backendError', {});

export const currentSelector = state => get(rootSelector(state), 'currentStep');

export const factorsSelector = state => get(rootSelector(state), 'factors', []);

export const factorsHintSelector = state =>
  get(rootSelector(state), 'factors', []).map(({ factorType, value }) => ({
    factorType: factorType ? factorType.toLowerCase() : factorType,
    value,
  }));

export const showOTCSuccessMessageSelector = state =>
  get(rootSelector(state), 'showOTCSuccessMessage', false);

export const getNumberOfRetry = state =>
  get(rootSelector(state), 'numberOfRetry', 0);

export const getTokenRecaptcha = state =>
  get(rootSelector(state), 'tokenRecaptcha', {});

export const getAnalytics = state => get(rootSelector(state), 'analytics', {});

export const isForgotPasswordFlowSelector = state =>
  get(rootSelector(state), 'isForgotPasswordFlow', false);
