import React from 'react';
import { Tagging, TrackState } from '@nbc-studio/analytics';
import PropTypes from 'prop-types';
import {
  BNE_EVENT_IDS,
  BNE_EVENT_NAMES,
  BNE_EVENT_SOURCE,
  BNE_EVENT_SOURCE_DETAILS,
  BNE_LOB,
  BNE_UCS_IDS,
  BNE_UCS_STATE,
} from '../../../../utils/taggingUtils/bneTaggingConstants';

const propTypes = {
  eventLabel: PropTypes.string,
};

const defaultProps = {
  eventLabel: '',
};

const RoadBlockTaggingWrapper = ({ children, eventLabel }) => {
  const pageN = `${BNE_EVENT_SOURCE.BNE}:${BNE_LOB.BUSINESS}:${BNE_EVENT_SOURCE_DETAILS.LOGIN}:${eventLabel}`;

  return (
    <Tagging
      ucsId={BNE_UCS_IDS.ROADBLOCK}
      ucsState={BNE_UCS_STATE.NON_PV}
      eventName={BNE_EVENT_NAMES.ROADBLOCK}
      eventId={BNE_EVENT_IDS.ROADBLOCK}
      eventSource={BNE_EVENT_SOURCE.BNE}
      roadblock={eventLabel}
      pageName={pageN}
    >
      {children}
      <TrackState />
    </Tagging>
  );
};

RoadBlockTaggingWrapper.propTypes = propTypes;
RoadBlockTaggingWrapper.defaultProps = defaultProps;

export default RoadBlockTaggingWrapper;
