import get from 'lodash/get';
import compact from 'lodash/compact';
import join from 'lodash/join';

const getReducerState = (state, modulePath, reducerPath) => {
  const reducerPathArray = compact([modulePath, reducerPath]);
  const stateReducerPath = join(reducerPathArray, '.');
  return get(state, stateReducerPath, {});
};
export default getReducerState;
