// @flow
export const FORGOT_PASSWORD_FORM_FIELD_VALIDATION_REQUEST =
  'FORGOT_PASSWORD_FORM_FIELD_VALIDATION_REQUEST';
export const FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE =
  'FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE';
export const FORGOT_PASSWORD_FORM_FIELD_VALIDATION_SUCCESS =
  'FORGOT_PASSWORD_FORM_FIELD_VALIDATION_SUCCESS';
export const FORGOT_PASSWORD_FORM_UPDATE = 'FORGOT_PASSWORD_FORM_UPDATE';
export const FORGOT_PASSWORD_FORM_SUBMIT_REQUEST =
  'FORGOT_PASSWORD_FORM_SUBMIT_REQUEST';
export const FORGOT_PASSWORD_FORM_INIT_REQUEST =
  'FORGOT_PASSWORD_FORM_INIT_REQUEST';
export const FORGOT_PASSWORD_FORM_INIT_DONE = 'FORGOT_PASSWORD_FORM_INIT_DONE';
export const FORGOT_PASSWORD_FORM_VALIDATION_REQUEST =
  'FORGOT_PASSWORD_FORM_VALIDATION_REQUEST';
export const FORGOT_PASSWORD_FORM_VALIDATION_FAILURE =
  'FORGOT_PASSWORD_FORM_VALIDATION_FAILURE';
export const FORGOT_PASSWORD_FORM_VALIDATION_SUCCESS =
  'FORGOT_PASSWORD_FORM_VALIDATION_SUCCESS';
export const FORGOT_PASSWORD_FORM_RESET = 'FORGOT_PASSWORD_FORM_RESET';
export const FORGOT_PASSWORD_RESET_RECAPTCHA_DONE =
  'SIGN_UP_RESET_RECAPTCHA_DONE';
export const FORGOT_PASSWORD_RESET_RECAPTCHA =
  'FORGOT_PASSWORD_RESET_RECAPTCHA';
