import { testSaga } from 'bnc-utilities-js/testUtils/saga';

import { watchRedirectToPage } from '../saga';

jest.mock('bnc-react-services/managers/SessionManager', () => ({
  clearSession: jest.fn(),
}));

Object.defineProperty(window.location, 'replace', {
  configurable: true,
});
window.location.replace = jest.fn();

describe('watchRedirectToPage', () => {
  test('watchRedirectToPage - Nothing happens with no page url', async () => {
    testSaga({
      saga: watchRedirectToPage,
      args: [{ pageUrl: null }],
    });
    expect(window.location.replace).toHaveBeenCalledTimes(0);
  });

  test('watchRedirectToPage - Redirect happens with page url value', async () => {
    const pageUrl = 'http://localhost:3000';
    testSaga({
      saga: watchRedirectToPage,
      args: [{ pageUrl }],
    });
    expect(window.location.replace).toBeCalledWith(pageUrl);
  });
});
