import uniqueId from 'lodash/uniqueId';
import { testSaga } from 'bnc-utilities-js/testUtils/saga';

import {
  watchResetFormRequest,
  watchFocusOnFirstErrorField,
  watchFormInitRequest,
} from '../workers';

import {
  FORM_HANDLER_FORM_INIT_DONE,
  FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
  FORM_HANDLER_NO_FIELD_TO_FOCUS_ON,
} from '../actionTypes';

import helper from '../helper';
import { FORM_TYPES } from '../../../utils/constants/forms';
import { FORGOT_PASSWORD_FORM_RESET } from '../../forgotPasswordFormService/actionTypes';

[[FORM_TYPES.FORGOT_PASSWORD_FORM, FORGOT_PASSWORD_FORM_RESET, false]].forEach(
  ([formName, resetAction, hasFormId]) => {
    test(`.watchResetFormRequest - ${formName}`, async () => {
      const formId = uniqueId('form_id');

      const { sagaDone } = testSaga({
        saga: watchResetFormRequest,
        args: [{ formName, formId }],
      });

      const { actions } = await sagaDone;

      if (hasFormId) {
        expect(actions).toEqual([{ type: resetAction, formId }]);
      } else if (formName && resetAction) {
        expect(actions).toEqual([{ type: resetAction }]);
      }
    });
  },
);

describe('.watchFocusOnFirstErrorField', () => {
  test('.watchFocusOnFirstErrorField', async () => {
    const forms = {
      toto: {
        tata: [
          {
            focus: () => {},
            scrollIntoView: () => {},
          },
        ],
      },
    };
    const errors = {
      tata: 'error',
    };
    const formName = 'toto';

    const { sagaDone } = testSaga({
      saga: watchFocusOnFirstErrorField,
      state: {
        formHandlerServiceReducer: {
          forms,
        },
      },
      args: [{ formName, errors }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
      },
    ]);
  });
  test('.watchFocusOnFirstErrorField - no input', async () => {
    const forms = {
      toto: {
        tata: [],
      },
    };
    const errors = {
      tata: 'error',
    };
    const formName = 'toto';

    const { sagaDone } = testSaga({
      saga: watchFocusOnFirstErrorField,
      state: {
        formHandlerServiceReducer: {
          forms,
        },
      },
      args: [{ formName, errors }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
      },
    ]);
  });
  test('.watchFocusOnFirstErrorField - not datepicker', async () => {
    const forms = {
      toto: {
        tata: [
          {
            focus: () => {},
            scrollIntoView: () => {},
            parentNode: {
              id: 'id',
            },
          },
        ],
      },
    };
    const errors = {
      tata: 'error',
    };
    const formName = 'toto';

    const { sagaDone } = testSaga({
      saga: watchFocusOnFirstErrorField,
      state: {
        formHandlerServiceReducer: {
          forms,
        },
      },
      args: [{ formName, errors }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
      },
    ]);
  });
  test('.watchFocusOnFirstErrorField - datepicker', async () => {
    const forms = {
      toto: {
        tata: [
          {
            focus: () => {},
            scrollIntoView: () => {},
            parentNode: {
              id: 'datepicker_',
            },
          },
        ],
      },
    };
    const errors = {
      tata: 'error',
    };
    const formName = 'toto';

    const { sagaDone } = testSaga({
      saga: watchFocusOnFirstErrorField,
      state: {
        formHandlerServiceReducer: {
          forms,
        },
      },
      args: [{ formName, errors }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
      },
    ]);
  });
  test('.watchFocusOnFirstErrorField - errors array - no input', async () => {
    const forms = {
      toto: {},
    };
    const errors = {
      tata: ['error'],
    };
    const formName = 'toto';

    const { sagaDone } = testSaga({
      saga: watchFocusOnFirstErrorField,
      state: {
        formHandlerServiceReducer: {
          forms,
        },
      },
      args: [{ formName, errors }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
      },
    ]);
  });
  test('.watchFocusOnFirstErrorField - errors array', async () => {
    const forms = {
      toto: {
        tata: {
          focus: () => {},
          scrollIntoView: () => {},
        },
      },
    };
    const errors = {
      tata: ['error'],
    };
    const formName = 'toto';

    const { sagaDone } = testSaga({
      saga: watchFocusOnFirstErrorField,
      state: {
        formHandlerServiceReducer: {
          forms,
        },
      },
      args: [{ formName, errors }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
      },
    ]);
  });
  test('.watchFocusOnFirstErrorField - errors array - not datepicker', async () => {
    const forms = {
      toto: {
        tata: {
          focus: () => {},
          scrollIntoView: () => {},
          parentNode: {
            id: 'id',
          },
        },
      },
    };
    const errors = {
      tata: ['error'],
    };
    const formName = 'toto';

    const { sagaDone } = testSaga({
      saga: watchFocusOnFirstErrorField,
      state: {
        formHandlerServiceReducer: {
          forms,
        },
      },
      args: [{ formName, errors }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
      },
    ]);
  });
  test('.watchFocusOnFirstErrorField - errors array - datepicker', async () => {
    const forms = {
      toto: {
        tata: {
          focus: () => {},
          scrollIntoView: () => {},
          parentNode: {
            id: 'datepicker_',
          },
        },
      },
    };
    const errors = {
      tata: ['error'],
    };
    const formName = 'toto';

    const { sagaDone } = testSaga({
      saga: watchFocusOnFirstErrorField,
      state: {
        formHandlerServiceReducer: {
          forms,
        },
      },
      args: [{ formName, errors }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
      },
    ]);
  });
  test('.watchFocusOnFirstErrorField - no errors', async () => {
    const forms = {};
    const errors = {};

    const { sagaDone } = testSaga({
      saga: watchFocusOnFirstErrorField,
      state: {
        formHandlerServiceReducer: {
          forms,
        },
      },
      args: [{ errors }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: FORM_HANDLER_NO_FIELD_TO_FOCUS_ON,
      },
    ]);
  });
});

Object.keys(helper.formInfos).forEach(formName => {
  test(`.watchFormInitRequest - ${formName}`, async () => {
    const formId = uniqueId('form_id');

    const { dispatch, sagaDone } = testSaga({
      saga: watchFormInitRequest,
      args: [{ formName, formId, initArgs: [] }],
    });

    dispatch(helper.formInfos[formName].initFormDone());

    const { actions } = await sagaDone;

    expect(actions).toContainEqual({
      type: helper.formInfos[formName].initRequestActionType,
      formId,
    });
    expect(actions).toContainEqual({
      formId,
      type: FORM_HANDLER_FORM_INIT_DONE,
    });
  });
});
