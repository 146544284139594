// @flow
import { browserHistory } from 'react-router';
import { getTemplatedUrl } from '../../utils/templateUtils';

const push = path => {
  const templatePath = getTemplatedUrl(path);
  browserHistory.push(templatePath);
};

const browserHistoryManager = {
  ...browserHistory,
  push,
};

export default browserHistoryManager;
