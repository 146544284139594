import { testSaga } from 'bnc-utilities-js/testUtils/saga';
import { FACTOR_TYPES } from 'bnc-react-services/managers/AuthManager/constants';
import { isFieldValid, isFormValid } from '../../../utils/formUtils';
import { FORM_HANDLER_FOCUS_ERROR_INPUT_REQUEST } from '../../formHandlerService/actionTypes';
import {
  LOGIN_MFA_RESEND_CODE_REQUEST,
  LOGIN_MFA_CODE_VALIDATION_REQUEST,
  LOGIN_MFA_TRIGGER_HIDE_RESEND,
} from '../../loginService/actionTypes';
import {
  MULTI_FACTOR_AUTH_FORM_INIT_DONE,
  MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
  MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE,
  MULTI_FACTOR_AUTH_FORM_SUBMIT_SUCCESS,
  MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE,
  MULTI_FACTOR_AUTH_FORM_VALIDATION_SUCCESS,
} from '../actionTypes';
import {
  watchCodeValidation,
  watchFormInit,
  watchFormValidation,
  watchSubmitCodeRequest,
  watchTriggerResendCodeRequest,
} from '../workers';
import { TEMPLATE_SERVICE_REDUCER } from '../../../globalRedux/reducers/constants';
import TEMPLATES from '../../../utils/constants/template';

jest.mock('../../../utils/formUtils', () => ({
  isFormValid: jest.fn(),
  isFieldValid: jest.fn(),
}));

test('.watchFormInit', async () => {
  const { sagaDone } = testSaga({
    saga: watchFormInit,
    args: [
      {
        formId: 'FORM_ID',
      },
    ],
  });

  const { actions } = await sagaDone;

  expect(actions).toEqual([
    {
      type: MULTI_FACTOR_AUTH_FORM_INIT_DONE,
      payload: {
        formData: { code: '', remember: false, rememberDevice: false },
      },
    },
  ]);
});

test('.watchTriggerResendCodeRequest', async () => {
  const { sagaDone } = testSaga({
    saga: watchTriggerResendCodeRequest,
    args: [{ factor: 'sms' }],
  });

  const { actions } = await sagaDone;

  expect(actions).toEqual([
    {
      type: LOGIN_MFA_TRIGGER_HIDE_RESEND,
    },
    {
      MFAselected: 'sms',
      type: LOGIN_MFA_RESEND_CODE_REQUEST,
    },
    {
      type: MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
    },
  ]);
});

describe('.watchCodeValidation', () => {
  test('.watchCodeValidation - rememberMe', async () => {
    const { sagaDone } = testSaga({
      saga: watchCodeValidation,
      state: {
        multiFactorAuthFormServiceReducer: {
          formData: {
            code: 'code',
          },
        },
        loginFormServiceReducer: {
          formData: {
            remember: true,
          },
        },
        loginServiceReducer: {
          identities: [],
        },
      },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: LOGIN_MFA_CODE_VALIDATION_REQUEST,
        code: 'code',
        remember: true,
      },
    ]);
  });
  test('.watchCodeValidation - do not rememberMe if', async () => {
    const { sagaDone } = testSaga({
      saga: watchCodeValidation,
      state: {
        multiFactorAuthFormServiceReducer: {
          formData: {
            code: 'code',
          },
        },
        loginFormServiceReducer: {
          formData: {
            remember: false,
          },
        },
        loginServiceReducer: {
          identities: [],
        },
      },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: LOGIN_MFA_CODE_VALIDATION_REQUEST,
        code: 'code',
        remember: false,
      },
    ]);
  });
  test('.watchCodeValidation - do not rememberMe - no identities', async () => {
    const { sagaDone } = testSaga({
      saga: watchCodeValidation,
      state: {
        multiFactorAuthFormServiceReducer: {
          formData: {
            code: 'code',
            rememberDevice: false,
          },
        },
        loginFormServiceReducer: {
          formData: {
            remember: false,
          },
        },
        loginServiceReducer: {},
      },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: LOGIN_MFA_CODE_VALIDATION_REQUEST,
        code: 'code',
        remember: false,
        rememberDevice: false,
      },
    ]);
  });
});

describe('.watchFormValidation', () => {
  test('.watchFormValidation - Success', async () => {
    isFormValid.mockReturnValueOnce({ formValid: true });
    isFieldValid.mockReturnValueOnce({ fieldValid: true, formValid: true });

    const { sagaDone } = testSaga({
      saga: watchFormValidation,
      state: {
        multiFactorAuthFormServiceReducer: {
          formData: {
            code: 'code',
          },
        },
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: TEMPLATES.SBIP2,
        },
      },
      args: [{ fieldName: 'code' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        fieldName: 'code',
        isValid: true,
        type: MULTI_FACTOR_AUTH_FORM_VALIDATION_SUCCESS,
      },
    ]);
  });
  test('.watchFormValidation - Failure', async () => {
    isFormValid.mockReturnValueOnce({ formValid: false });
    isFieldValid.mockReturnValueOnce({
      fieldValid: false,
      errorField: ['error message'],
    });

    const { sagaDone } = testSaga({
      saga: watchFormValidation,
      state: {
        multiFactorAuthFormServiceReducer: {
          formData: {
            code: 'code',
          },
        },
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: TEMPLATES.SBIP2,
        },
      },
      args: [{ fieldName: 'code' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        fieldName: 'code',
        errors: ['error message'],
        type: MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE,
      },
    ]);
  });
  test('.watchFormValidation Call - Success', async () => {
    isFormValid.mockReturnValueOnce({ formValid: true });
    isFieldValid.mockReturnValueOnce({ fieldValid: true, formValid: true });

    const { sagaDone } = testSaga({
      saga: watchFormValidation,
      state: {
        multiFactorAuthFormServiceReducer: {
          formData: {
            code: '12345',
          },
        },
        loginServiceReducer: {
          MFAfactors: FACTOR_TYPES.CALL,
        },
      },
      args: [{ fieldName: 'code' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        fieldName: 'code',
        isValid: true,
        type: MULTI_FACTOR_AUTH_FORM_VALIDATION_SUCCESS,
      },
    ]);
  });
  test('.watchFormValidation Call - Failure', async () => {
    isFormValid.mockReturnValueOnce({ formValid: false });
    isFieldValid.mockReturnValueOnce({
      fieldValid: false,
      errorField: ['error message'],
    });

    const { sagaDone } = testSaga({
      saga: watchFormValidation,
      state: {
        multiFactorAuthFormServiceReducer: {
          formData: {
            code: '1234',
          },
        },
        loginServiceReducer: {
          MFAfactors: FACTOR_TYPES.CALL,
        },
      },
      args: [{ fieldName: 'code' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        fieldName: 'code',
        errors: ['error message'],
        type: MULTI_FACTOR_AUTH_FORM_VALIDATION_FAILURE,
      },
    ]);
  });
});

describe('.watchSubmitCodeRequest', () => {
  isFormValid
    .mockReturnValueOnce({ formValid: true })
    .mockReturnValueOnce({ formattedErrors: ['error message'] });
  test('.watchSubmitCodeRequest - Success', async () => {
    const { sagaDone } = testSaga({
      saga: watchSubmitCodeRequest,
      state: {
        multiFactorAuthFormServiceReducer: {
          formData: {
            code: 'code',
          },
          formId: 'FORM_ID',
        },
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: TEMPLATES.SBIP2,
        },
      },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: MULTI_FACTOR_AUTH_FORM_SUBMIT_SUCCESS,
      },
    ]);
  });
  test('.watchFormSubmit - Failure', async () => {
    const { sagaDone } = testSaga({
      saga: watchSubmitCodeRequest,
      state: {
        multiFactorAuthFormServiceReducer: {
          formData: {
            code: 'code',
          },
          formId: 'FORM_ID',
        },
        [TEMPLATE_SERVICE_REDUCER]: {
          templateName: TEMPLATES.SBIP2,
        },
      },
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        errors: ['error message'],
        formName: 'FORM_ID',
        scrollTop: true,
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_REQUEST,
      },
      {
        code: 'code',
        errors: ['error message'],
        type: MULTI_FACTOR_AUTH_FORM_SUBMIT_FAILURE,
      },
    ]);
  });
});
