// @flow
import React from 'react';
import { IntlShape } from 'react-intl';

import cx from 'classnames';

import { Heading } from '@bnc-ui/heading';

import { Browser, SupportedBrowserList } from '../../../types/browser';
import BrowserCard from '../../../molecules/common/BrowserCard';
import './style.scss';

type Props = {
  className?: string,
  browserList: Array<Browser>,
  browserVersions: SupportedBrowserList,
  intl: IntlShape,
};

const defaultProps = {
  className: '',
};

function BrowserList(props: Props) {
  const { className, browserList, browserVersions, intl } = props;

  return (
    <div className="browser-list-container">
      <Heading type="h3" tabIndex={0}>
        {intl.formatMessage({ id: 'text.title.recommendedBrowser' })}
      </Heading>
      <div className={cx('browser-list-container--cards', className)}>
        {browserList.map(browser => (
          <BrowserCard
            className="browser-list__card"
            key={browser.name}
            name={browserVersions[browser.name].name}
          />
        ))}
      </div>
    </div>
  );
}

BrowserList.defaultProps = defaultProps;

export default BrowserList;
