// @flow
import React from 'react';
import { intlShape } from 'react-intl';

import FullScreenFooterTemplate from '../../templates/models/FullScreenFooterTemplate';
import ParamUnavailableError from '../../organisms/common/ParamUnavailableError';
import Header from '../../organisms/common/Header';

const propTypes = {
  intl: intlShape.isRequired,
};

const SessionExpired = props => {
  const { intl } = props;
  const title = intl.formatMessage({ id: 'text.title.expiredSession' });
  const message = intl.formatMessage({ id: 'text.error.timeoutSK' });

  return (
    <FullScreenFooterTemplate
      header={<Header hasSwitchLang={false} />}
      main={<ParamUnavailableError errorTitle={title} errorText={message} />}
      className="error-background"
    />
  );
};
SessionExpired.propTypes = propTypes;

export default SessionExpired;
