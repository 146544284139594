// @flow
export const FORGOT_PASSWORD_VALIDATE_USER_REQUEST =
  'FORGOT_PASSWORD_VALIDATE_USER_REQUEST';
export const FORGOT_PASSWORD_VALIDATE_USER_SUCCESS =
  'FORGOT_PASSWORD_VALIDATE_USER_SUCCESS';
export const FORGOT_PASSWORD_VALIDATE_USER_FAILURE =
  'FORGOT_PASSWORD_VALIDATE_USER_FAILURE';

export const FORGOT_PASSWORD_SET_CONTEXT = 'FORGOT_PASSWORD_SET_CONTEXT';
export const FORGOT_PASSWORD_TRIGGER_EXCEPTION =
  'FORGOT_PASSWORD_TRIGGER_EXCEPTION';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';
export const FORGOT_PASSWORD_SET_ANALYTICS = 'FORGOT_PASSWORD_SET_ANALYTICS';
export const FORGOT_PASSWORD_SEND_ANALYTICS = 'FORGOT_PASSWORD_SEND_ANALYTICS';

export const FORGOT_PASSWORD_SET_BACKEND_ERROR =
  'FORGOT_PASSWORD_SET_BACKEND_ERROR';

export const FORGOT_PASSWORD_SHOW_RESEND_OTC_SUCCESS_MESSAGE =
  'FORGOT_PASSWORD_SHOW_RESEND_OTC_SUCCESS_MESSAGE';

export const FORGOT_PASSWORD_RESEND_OTC = 'FORGOT_PASSWORD_RESEND_OTC';

export const FORGOT_PASSWORD_RESEND_OTC_FAILURE =
  'FORGOT_PASSWORD_RESEND_OTC_FAILURE';

export const FORGOT_PASSWORD_SET_PASSWORD = 'FORGOT_PASSWORD_SET_PASSWORD';
export const FORGOT_PASSWORD_SET_PASSWORD_SUCCESS =
  'FORGOT_PASSWORD_SET_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_SET_PASSWORD_FAIL =
  'FORGOT_PASSWORD_SET_PASSWORD_FAIL';

export const FORGOT_PASSWORD_VERIFY_OTC = 'FORGOT_PASSWORD_VERIFY_OTC';

export const FORGOT_PASSWORD_VERIFY_OTC_SUCCESS =
  'FORGOT_PASSWORD_VERIFY_OTC_SUCCESS';

export const FORGOT_PASSWORD_VERIFY_OTC_FAILURE =
  'FORGOT_PASSWORD_VERIFY_OTC_FAILURE';

export const FORGOT_PASSWORD_FLOW_RESET = 'FORGOT_PASSWORD_FLOW_RESET';
export const SAVE_RECAPTCHA_TOKEN = 'SAVE_RECAPTCHA_TOKEN';
export const FORGOT_PASSWORD_UNAVAILABLE_CHECK =
  'FORGOT_PASSWORD_UNAVAILABLE_CHECK';

export const FORGOT_PASSWORD_VALIDATE_USER_SUCCESS_MFA_CHOICE =
  'FORGOT_PASSWORD_VALIDATE_USER_SUCCESS_MFA_CHOICE';
export const TRIGGER_VERIFICATION_CODE_STEP = 'TRIGGER_VERIFICATION_CODE_STEP';
export const FORGOT_PASSWORD_TRACK_RESEND_SUCCESS =
  'FORGOT_PASSWORD_TRACK_RESEND_SUCCESS';
