import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import CompleteUnavailability from './completeUnavailability';
import {
  getExpectedAppReturnTime,
  isMaintenanceModeEnable,
  isUnavailableModeEnable,
} from '../../../services/configsService/selectors';

function mapStateToProps(state) {
  return {
    expectedAppReturnDate: getExpectedAppReturnTime(state),
    isMaintenanceModeEnable: isMaintenanceModeEnable(state),
    isUnavailableModeEnable: isUnavailableModeEnable(state),
  };
}

export default injectIntl(connect(mapStateToProps)(CompleteUnavailability));
