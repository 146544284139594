// @flow
import React from 'react';
import cx from 'classnames';
import type { IntlShape } from 'react-intl';
import getRewardsIcon from '../../../utils/rewardsUtils';

import './style.scss';

type Props = {
  intl: IntlShape,
  className?: string,
  isVisible?: boolean,
  locale: string,
  hasTagBrand?: boolean,
};

const defaultProps = {
  className: '',
  isVisible: true,
  hasTagBrand: true,
};

function RewardsBrand(props: Props) {
  const { intl, className, isVisible, locale, hasTagBrand } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div className={cx('bnc-brand', locale, className)}>
      <div
        className={cx('bnc-brand__logo', {
          'bnc-brand__logo--big': !hasTagBrand,
        })}
      >
        <div className="bnc-logo">
          {getRewardsIcon(intl, 'text.title.rewardsLogo')}
        </div>
      </div>
    </div>
  );
}

RewardsBrand.defaultProps = defaultProps;

export default RewardsBrand;
