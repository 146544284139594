const DEFAULT_COUNTRY_CODE = 1;

const clickToCallNumberFormatter = (phoneNumber, withCommand = true) => {
  const normalizedPhoneNumber = phoneNumber.replace(/[-+()\s]/g, '');
  const internationalNumber =
    normalizedPhoneNumber.length === 10
      ? `${DEFAULT_COUNTRY_CODE}${normalizedPhoneNumber}`
      : normalizedPhoneNumber;
  const command = withCommand ? 'tel:+' : '';
  return `${command}${internationalNumber.substring(
    0,
    1,
  )}-${internationalNumber.substring(1, 4)}-${internationalNumber.substring(
    4,
    7,
  )}-${internationalNumber.substring(7, 11)}`;
};

export default clickToCallNumberFormatter;
