export const BNC_RECAPTCHA_ID = 'recap';

export const isTokenStillValid = token => {
  if (
    !token ||
    !token.accessToken ||
    !token.tokenType ||
    !token.expiresIn ||
    !token.scope ||
    !token.expirationTime
  ) {
    return false;
  }
  return new Date() <= token.expirationTime;
};
