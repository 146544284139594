// @flow
import React from 'react';

import { Tagging, TrackState } from '@nbc-studio/analytics';
import includes from 'lodash/includes';
import get from 'lodash/get';
import type { SDKError } from '../../../types';

import {
  GENERIC_WITH_CODE,
  GENERIC_WITHOUT_CODE,
} from '../../../../utils/authErrorMessages/constants';
import { getAuthErrorMessageId } from '../../../../utils/authErrorMessages';
import {
  BNE_EVENT_IDS,
  BNE_EVENT_NAMES,
  BNE_EVENT_SOURCE,
  BNE_UCS_IDS,
  BNE_UCS_STATE,
} from '../../../../utils/taggingUtils/bneTaggingConstants';

type Props = {
  hasValidationError: boolean,
  hasLoginFailure: boolean,
  sdkError: SDKError,
};

function ErrorTaggingWrapper(props: Props) {
  const { hasValidationError, hasLoginFailure, sdkError } = props;

  const isTechnicalError =
    hasLoginFailure &&
    !hasValidationError &&
    includes(
      [GENERIC_WITH_CODE, GENERIC_WITHOUT_CODE],
      getAuthErrorMessageId(sdkError),
    );

  const errorCode = get(sdkError, 'code', '0000');
  const errorMsg = get(sdkError, 'message', 'Unexpected Error');

  return (
    <>
      {isTechnicalError && (
        <Tagging
          ucsId={BNE_UCS_IDS.TECHNICAL_ERROR}
          ucsState={BNE_UCS_STATE.NON_PV}
          eventName={BNE_EVENT_NAMES.PAGE_ERROR}
          eventId={BNE_EVENT_IDS.PE}
          eventSource={BNE_EVENT_SOURCE.BNE}
          technicalError={`${errorMsg} (CODE:${errorCode})`}
        >
          <TrackState />
        </Tagging>
      )}
    </>
  );
}

export default ErrorTaggingWrapper;
