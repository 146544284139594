// @flow
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  getMFAfactorsWithValues,
  getMfaDisplayResendCounter,
  getMFAselected,
} from '../../../services/loginService/selectors';
import { triggerResendCodeRequest } from '../../../services/multiFactorAuthFormService/actions';
import type { State } from '../../../types/state';
import type { Dispatch } from '../../../types/store';
import MultiFactorAuthFooter from './multiFactorAuthFooter';

function mapStateToProps(state: State) {
  return {
    factors: getMFAfactorsWithValues(state),
    countdownMfa: getMfaDisplayResendCounter(state),
    selectedFactor: getMFAselected(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    triggerCodeRequest: factor => dispatch(triggerResendCodeRequest(factor)),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuthFooter),
);
