// @flow
import { FORM_TYPES } from '../utils/constants/forms';
import TEMPLATES from '../utils/constants/template';
import SESSION_STORAGE from '../utils/constants/session';
import ROUTES from '../utils/constants/routes';
import { LOGIN_FORM_STEPS } from '../utils/constants/login';
import I18N from '../utils/constants/i18n';
import HTTP from '../utils/constants/http';

export type FormType = $Values<typeof FORM_TYPES>;
export type TemplateType = $Values<typeof TEMPLATES>;
export type SessionStorage = $Keys<typeof SESSION_STORAGE>;
export type Route = $Values<typeof ROUTES>;
export type LoginFormSteps = $Keys<typeof LOGIN_FORM_STEPS>;
export type HttpMethods = $Keys<typeof HTTP.METHODS>;

export type Lang = $Keys<typeof I18N.LANG>;
export type LangAvailable = Array<Lang>;
export type I18n = Lang | LangAvailable | 'LANG_DEFAULT' | 'COOKIE_NAME';
