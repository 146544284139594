import { getI18nState, getLocale } from '../selectors';

import I18N from '../../../utils/constants/i18n';

describe('getFormHandlerState', () => {
  it('should return state if they are available', () => {
    const state = {
      intl: { test: 1 },
    };
    expect(getI18nState(state)).toEqual({ test: 1 });
  });

  it('should return {} if state are not available', () => {
    const state = {};
    expect(getI18nState(state)).toEqual({});
  });

  it('should return module state if they are available', () => {
    const modulePath = 'front_end.sso';

    const state = {
      front_end: {
        sso: {
          intl: { test: 1 },
        },
      },
    };
    expect(getI18nState(state, modulePath)).toEqual({ test: 1 });
  });
});

describe('getLocale', () => {
  it('should return local if it is available', () => {
    const state = {
      intl: { locale: 'fr' },
    };
    expect(getLocale(state)).toEqual('fr');
  });

  it('should return empty if locale is not available', () => {
    const state = {
      intl: {},
    };
    expect(getLocale(state)).toEqual(I18N.LANG_DEFAULT);
  });
});
