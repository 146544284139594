import { connect } from 'react-redux';

import * as reducers from '../../globalRedux/reducers/constants';
import UnsupportedBrowser from './unsupportedBrowser';

import { getTemplateName } from '../../services/templateService/selectors';

import TEMPLATES from '../../utils/constants/template';

import type { State } from '../../types/state';
import { getLocale } from '../../services/i18nService/selectors';

function mapStateToProps(state: State) {
  return {
    locale: getLocale(state),
    browser: state[reducers.USER_AGENT_SERVICE_REDUCER].browser,
    os: state[reducers.USER_AGENT_SERVICE_REDUCER].os,
    continueWithUnsupportedBrowser:
      state[reducers.UNSUPPORTED_BROWSER_SERVICE_REDUCER]
        .continueWithUnsupportedBrowser,
    cannotContinueWithUnsupportedBrowser:
      state[reducers.UNSUPPORTED_BROWSER_SERVICE_REDUCER]
        .cannotContinueWithUnsupportedBrowser,
    isBypassing: getTemplateName(state) === TEMPLATES.OBE,
  };
}

export default connect(mapStateToProps)(UnsupportedBrowser);
