const { default: customTimeFormat } = require('../helper');

describe('customTimeFormat', () => {
  it('format date in english 1', () => {
    const TIME = '4:30 PM';
    const intl = {
      locale: 'en',
      formatTime: x => TIME,
    };
    expect(customTimeFormat(intl, TIME)).toEqual('4:30 p.m.');
  });

  it('format date in english 2', () => {
    const TIME = '4:30 AM';
    const intl = {
      locale: 'en',
      formatTime: x => TIME,
    };
    expect(customTimeFormat(intl, TIME)).toEqual('4:30 a.m.');
  });

  it('format date in english 3', () => {
    const TIME = '09:05 AM';
    const intl = {
      locale: 'en',
      formatTime: x => TIME,
    };
    expect(customTimeFormat(intl, TIME)).toEqual('9:05 a.m.');
  });

  it('format date in english 4', () => {
    const TIME = '12:00 AM';
    const intl = {
      locale: 'en',
      formatTime: x => TIME,
    };
    expect(customTimeFormat(intl, TIME)).toEqual('12:00 a.m.');
  });

  it('format date in french 1', () => {
    const TIME = '10:40';
    const intl = {
      locale: 'fr',
      formatTime: x => TIME,
    };
    expect(customTimeFormat(intl, TIME)).toEqual('10 h 40');
  });

  it('format date in french 2', () => {
    const TIME = '09:05';
    const intl = {
      locale: 'fr',
      formatTime: x => TIME,
    };
    expect(customTimeFormat(intl, TIME)).toEqual('9 h 05');
  });

  it('format date in french 3', () => {
    const TIME = '19:32';
    const intl = {
      locale: 'fr',
      formatTime: x => TIME,
    };
    expect(customTimeFormat(intl, TIME)).toEqual('19 h 32');
  });

  it('format date in french 4', () => {
    const TIME = '00:00';
    const intl = {
      locale: 'fr',
      formatTime: x => TIME,
    };
    expect(customTimeFormat(intl, TIME)).toEqual('00 h 00');
  });
});
