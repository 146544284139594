import React from 'react';
import { intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Alert } from '@bnc-ui/alert';
import { Configs } from '../../../configs';

import './style.scss';

const propTypes = {
  intl: intlShape.isRequired,
  isLoginAlertEnable: PropTypes.bool.isRequired,
};

const LoginAlert = props => {
  const { intl, isLoginAlertEnable } = props;

  const descriptionComponent = (
    <FormattedMessage
      id="text.alerts.login.description"
      values={{
        noticepage: (
          <a
            id="login-alert_btn"
            className="useful-link"
            target="_blank"
            rel="noopener noreferrer"
            href={Configs.SBIP2.EXTERNAL_URLS.BANK_NOTICE.URL[intl.locale]}
          >
            {intl.formatMessage({ id: 'text.notice.noticepage.label' })}
          </a>
        ),
      }}
    />
  );

  return (
    isLoginAlertEnable && (
      <Alert
        title={descriptionComponent}
        appearance="warning"
        ariaLabelIcon="Login Alert"
        isClosable="true"
        className="login-alert"
        tabIndex="0"
        ariaLabelClose="Close"
      />
    )
  );
};

LoginAlert.propTypes = propTypes;

export default LoginAlert;
