import {
  clearMfaFormErrors,
  triggerResendCodeRequest,
  triggerValidation,
} from '../actions';
import {
  MULTI_FACTOR_AUTH_FORM_CLEAR_ERRORS,
  MULTI_FACTOR_AUTH_FORM_TRIGGER_RESEND_CODE_REQUEST,
  MULTI_FACTOR_AUTH_FORM_VALIDATION_REQUEST,
} from '../actionTypes';

test('triggerCodeRequest', () => {
  const factor = 'factor';
  expect(triggerResendCodeRequest(factor)).toEqual({
    type: MULTI_FACTOR_AUTH_FORM_TRIGGER_RESEND_CODE_REQUEST,
    factor,
  });
});

test('triggerValidation', () => {
  const fieldName = 'fieldName';
  expect(triggerValidation(fieldName)).toEqual({
    type: MULTI_FACTOR_AUTH_FORM_VALIDATION_REQUEST,
    fieldName,
  });
});

test('clearMfaFormErrors', () => {
  expect(clearMfaFormErrors()).toEqual({
    type: MULTI_FACTOR_AUTH_FORM_CLEAR_ERRORS,
  });
});
