import { testSaga } from 'bnc-utilities-js/testUtils/saga';
import {
  stripSpaces,
  containsNumbersOnly,
} from 'bnc-utilities-js/formUtils/string';
import sessionManager from 'bnc-react-services/managers/SessionManager';

import { ANNOUNCE_ALERT_MESSAGE } from 'bnc-react-services/services/LiveAnnouncerService/actionTypes';

import * as cookie from '../../../utils/cookie';
import {
  LOGIN_FORM_INIT_DONE,
  LOGIN_FORM_SUBMIT_FAILURE,
  LOGIN_FORM_VALIDATION_FAILURE,
  LOGIN_FORM_VALIDATION_SUCCESS,
  LOGIN_FORM_CHANGE_STEP_REQUEST,
  LOGIN_FORM_UPDATE,
  LOGIN_FORM_CHANGE_STEP_SUCCESS,
} from '../actionTypes';
import { FORM_HANDLER_FOCUS_ERROR_INPUT_REQUEST } from '../../formHandlerService/actionTypes';
import {
  watchFormInit,
  watchFormSubmit,
  watchFormValidation,
  watchChangeStepRequest,
} from '../workers';

import { isFormValid, isFieldValid } from '../../../utils/formUtils';
import {
  LOGIN_LOAD_IDENTITY_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_CLEAR_ERROR_MESSAGES,
} from '../../loginService/actionTypes';
import { LOGIN_FORM_STEPS } from '../../../utils/constants/login';
import { BNC_PARTNER_ID } from '../../../utils/constants/partner';

afterEach(() => {
  isFieldValid.mockReset();
  isFormValid.mockReset();
  jest.clearAllMocks();
});

jest.mock('bnc-react-services/managers/SessionManager', () => ({
  getPartnerId: jest.fn(),
}));

jest.mock('../../../utils/formUtils', () => ({
  isFormValid: jest.fn(),
  isFieldValid: jest.fn(),
  stripSpaces: jest.fn(),
  containsNumbersOnly: jest.fn(),
}));

jest.mock('bnc-utilities-js/formUtils/string', () => ({
  stripSpaces: jest.fn(),
  containsNumbersOnly: jest.fn(),
}));

jest.mock('../../../utils/cookie', () => ({
  get: jest.fn(),
  set: jest.fn(),
}));

describe('.watchFormInit for BNE', () => {
  test('.watchFormInit for BNE', async () => {
    cookie.get.mockReturnValueOnce({
      BNC_PARTNER_ID: ['email@test.com', 'otheremail@test.com'],
    });

    const { sagaDone } = testSaga({
      saga: watchFormInit,
      state: {
        loginFormServiceReducer: {
          formData: {},
        },
        templateServiceReducer: {
          templateName: 'BNE',
        },
      },
      args: [
        {
          formId: 'FORM_ID',
          cookieName: 'bncUserIdentity',
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: 'USER_LOCKED_PASSWORD',
      },
      {
        cookieValue: [],
        type: 'LOGIN_LOAD_IDENTITY_SUCCESS',
      },
      {
        payload: {
          identity: '',
        },
        type: 'LOGIN_FORM_INIT_DONE',
      },
    ]);
  });
});

describe.skip('.watchFormInit', () => {
  test('.watchFormInit', async () => {
    cookie.get.mockReturnValueOnce({
      BNC_PARTNER_ID: ['email@test.com', 'otheremail@test.com'],
    });

    const { sagaDone } = testSaga({
      saga: watchFormInit,
      state: {
        loginFormServiceReducer: {
          formData: {},
        },
      },
      args: [
        {
          formId: 'FORM_ID',
          cookieName: 'bncUserIdentity',
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: LOGIN_LOAD_IDENTITY_SUCCESS,
        cookieValue: ['email@test.com', 'otheremail@test.com'],
      },
      {
        type: LOGIN_FORM_CHANGE_STEP_REQUEST,
        nextStep: LOGIN_FORM_STEPS.LOGIN_WITH_ID_STEP,
      },
      {
        type: LOGIN_FORM_INIT_DONE,
        payload: {
          formData: {
            identity: '',
            remember: true,
            password: '',
          },
        },
      },
    ]);
  });

  test('.watchFormInit - empty cookie', async () => {
    cookie.get.mockReturnValueOnce();

    const { sagaDone } = testSaga({
      saga: watchFormInit,
      state: {
        loginFormServiceReducer: {
          formData: {},
        },
        configsServiceReducer: {
          partnerId: null,
        },
      },
      args: [
        {
          formId: 'FORM_ID',
          cookieName: 'bncUserIdentity',
        },
      ],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: LOGIN_LOAD_IDENTITY_SUCCESS,
        cookieValue: [],
      },
      {
        type: LOGIN_FORM_INIT_DONE,
        payload: {
          formData: {
            identity: '',
            remember: true,
            password: '',
          },
        },
      },
    ]);
    expect(cookie.set).toHaveBeenCalled();
    expect(cookie.set.mock.calls[0][0]).toEqual('bncUserIdentity');
    expect(cookie.set.mock.calls[0][1]).toEqual({
      [BNC_PARTNER_ID]: [],
    });
  });

  test('.watchFormInit - empty object cookie', async () => {
    cookie.get.mockReturnValueOnce({});
    sessionManager.getPartnerId.mockReturnValueOnce(null);

    const { sagaDone } = testSaga({
      saga: watchFormInit,
      state: {
        loginFormServiceReducer: {
          formData: {},
        },
      },
      args: [
        {
          formId: 'FORM_ID',
          cookieName: 'bncUserIdentity',
        },
      ],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: LOGIN_LOAD_IDENTITY_SUCCESS,
        cookieValue: [],
      },
      {
        type: LOGIN_FORM_INIT_DONE,
        payload: {
          formData: {
            identity: '',
            remember: true,
            password: '',
          },
        },
      },
    ]);
  });

  // This test can be deleted when we dont need to support cookie from array
  // https://www.timeanddate.com/countdown/generic?iso=20191026T00&p0=165&font=cursive
  // When this time is done, it should be 1 year, therefore it should be ok
  test('.watchFormInit - cookie is an array', async () => {
    cookie.get.mockReturnValueOnce(['email@test.com', 'otheremail@test.com']);
    sessionManager.getPartnerId.mockReturnValueOnce(null);

    const { sagaDone } = testSaga({
      saga: watchFormInit,
      state: {
        loginFormServiceReducer: {
          formData: {},
        },
      },
      args: [
        {
          cookieName: 'bncUserIdentity',
        },
      ],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: LOGIN_LOAD_IDENTITY_SUCCESS,
        cookieValue: ['email@test.com', 'otheremail@test.com'],
      },
      {
        type: LOGIN_FORM_CHANGE_STEP_REQUEST,
        nextStep: LOGIN_FORM_STEPS.LOGIN_WITH_ID_STEP,
      },
      {
        type: LOGIN_FORM_INIT_DONE,
        payload: {
          formData: {
            identity: '',
            remember: true,
            password: '',
          },
        },
      },
    ]);
    expect(cookie.set).toHaveBeenCalled();
    expect(cookie.set.mock.calls[0][0]).toEqual('bncUserIdentity');
    expect(cookie.set.mock.calls[0][1]).toEqual({
      [BNC_PARTNER_ID]: ['email@test.com', 'otheremail@test.com'],
    });
  });

  test('.watchFormInit - url param identity', async () => {
    cookie.get.mockReturnValueOnce({
      BNC_PARTNER_ID: ['email@test.com', 'otheremail@test.com'],
    });
    sessionManager.getPartnerId.mockReturnValueOnce(null);

    const { sagaDone } = testSaga({
      saga: watchFormInit,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'test@test.ca',
          },
        },
      },
      args: [
        {
          cookieName: 'bncUserIdentity',
          formId: 'FORM_ID',
        },
      ],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        type: LOGIN_LOAD_IDENTITY_SUCCESS,
        cookieValue: ['email@test.com', 'otheremail@test.com'],
      },
      {
        type: LOGIN_FORM_INIT_DONE,
        payload: {
          formData: {
            identity: 'test@test.ca',
            remember: true,
            password: '',
          },
        },
      },
    ]);
  });
});

describe('.watchFormValidation', () => {
  test('.watchFormValidation - Success', async () => {
    isFieldValid.mockReturnValueOnce({
      fieldValid: true,
      formValid: true,
    });

    const { sagaDone } = testSaga({
      saga: watchFormValidation,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'email@test.com',
            remember: true,
            password: 'password',
            requestMFA: true,
          },
        },
        templateServiceReducer: {
          templateName: 'sbip',
        },
      },
      args: [{ fieldName: 'identity' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        fieldName: 'identity',
        isValid: true,
        type: LOGIN_FORM_VALIDATION_SUCCESS,
      },
    ]);
  });
  test('.watchFormValidation - enterprise Success', async () => {
    isFieldValid.mockReturnValueOnce({
      fieldValid: true,
      formValid: true,
    });

    const { sagaDone } = testSaga({
      saga: watchFormValidation,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'abc123',
            remember: true,
            password: 'password',
          },
        },
        templateServiceReducer: {
          templateName: 'BNE',
        },
      },
      args: [{ fieldName: 'identity' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        fieldName: 'identity',
        isValid: true,
        type: LOGIN_FORM_VALIDATION_SUCCESS,
      },
    ]);
  });
  test('.watchFormValidation - Failure', async () => {
    isFieldValid.mockReturnValueOnce({
      fieldValid: false,
      errorField: ['error message'],
    });

    const { sagaDone } = testSaga({
      saga: watchFormValidation,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'email@test.com',
            remember: true,
            password: 'password',
            templateName: '',
          },
        },
        templateServiceReducer: {
          templateName: 'sbip',
        },
      },
      args: [{ fieldName: 'identity' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        fieldName: 'identity',
        errors: ['error message'],
        type: LOGIN_FORM_VALIDATION_FAILURE,
      },
      {
        message: 'text.aria.invalididentity',
        politeness: 'assertive',
        type: ANNOUNCE_ALERT_MESSAGE,
      },
    ]);
  });
  test('.watchFormValidation - enterprise Failure', async () => {
    isFieldValid.mockReturnValueOnce({
      fieldValid: false,
      errorField: ['error message'],
    });

    const { sagaDone } = testSaga({
      saga: watchFormValidation,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'abc123',
            remember: true,
            password: 'password',
            templateName: '',
          },
        },
        templateServiceReducer: {
          templateName: 'BNE',
        },
      },
      args: [{ fieldName: 'identity' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        fieldName: 'identity',
        errors: ['error message'],
        type: LOGIN_FORM_VALIDATION_FAILURE,
      },
      {
        message: 'text.aria.invalididentity',
        politeness: 'assertive',
        type: ANNOUNCE_ALERT_MESSAGE,
      },
    ]);
  });
  test('.watchFormValidation - no validationResult', async () => {
    isFieldValid.mockReturnValueOnce();

    const { sagaDone } = testSaga({
      saga: watchFormValidation,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'email@test.com',
            remember: true,
            password: 'password',
          },
        },
        templateServiceReducer: {
          templateName: 'sbip',
        },
      },
      args: [{ fieldName: 'identity' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([]);
  });

  test('.watchFormValidation - enterprise no validationResult', async () => {
    isFieldValid.mockReturnValueOnce();

    const { sagaDone } = testSaga({
      saga: watchFormValidation,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'abc123',
            remember: true,
            password: 'password',
          },
        },
        templateServiceReducer: {
          templateName: 'BNE',
        },
      },
      args: [{ fieldName: 'identity' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([]);
  });
});

describe('.watchFormSubmit', () => {
  test('.watchFormSubmit - Success', async () => {
    isFormValid.mockReturnValueOnce({ formValid: true });
    stripSpaces
      .mockReturnValueOnce('email@test.com')
      .mockReturnValueOnce('password');
    containsNumbersOnly.mockReturnValueOnce(false);
    const { sagaDone } = testSaga({
      saga: watchFormSubmit,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'email@test.com',
            remember: true,
            password: 'password',
            requestMFA: true,
          },
          formId: 'FORM_ID',
        },
        loginServiceReducer: {
          identities: ['email@test.com', 'otheremail@test.com'],
        },
        templateServiceReducer: {
          templateName: 'sbip',
        },
      },
      args: [{ cookieName: 'toto' }],
    });
    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        identity: 'email@test.com',
        password: 'password',
        remember: true,
        type: LOGIN_REQUEST,
        requestMFA: true,
      },
    ]);
  });

  test('.watchFormSubmit - Success Entreprise', async () => {
    isFormValid.mockReturnValueOnce({ formValid: true });
    stripSpaces
      .mockReturnValueOnce('email@test.com')
      .mockReturnValueOnce('password');
    containsNumbersOnly.mockReturnValueOnce(false);
    const { sagaDone } = testSaga({
      saga: watchFormSubmit,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'test123',
            remember: true,
            password: 'password',
            requestMFA: true,
          },
          formId: 'FORM_ID',
        },
        loginServiceReducer: {
          identities: ['email@test.com', 'otheremail@test.com'],
        },
        templateServiceReducer: {
          templateName: 'BNE',
        },
      },
      args: [{ cookieName: 'toto' }],
    });
    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        identity: 'test123',
        password: 'password',
        remember: true,
        type: LOGIN_REQUEST,
        requestMFA: true,
      },
    ]);
  });

  test('.watchFormSubmit - Failure', async () => {
    isFormValid.mockReturnValueOnce({
      formValid: false,
      formattedErrors: ['error message'],
    });
    const { sagaDone } = testSaga({
      saga: watchFormSubmit,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'email@test.com',
            remember: true,
            password: 'password',
          },
          formId: 'FORM_ID',
        },
        loginServiceReducer: {
          identities: ['email@test.com', 'otheremail@test.com'],
        },
        templateServiceReducer: {
          templateName: 'sbip',
        },
      },
      args: [{ cookieName: 'toto' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        errors: ['error message'],
        formName: 'FORM_ID',
        scrollTop: true,
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_REQUEST,
      },
      {
        errors: ['error message'],
        type: LOGIN_FORM_SUBMIT_FAILURE,
      },
    ]);
  });

  test('.watchFormSubmit - Failure Entreprise', async () => {
    isFormValid.mockReturnValueOnce({
      formValid: false,
      formattedErrors: ['error message'],
    });
    const { sagaDone } = testSaga({
      saga: watchFormSubmit,
      state: {
        loginFormServiceReducer: {
          formData: {
            identity: 'test123',
            remember: true,
            password: 'password',
          },
          formId: 'FORM_ID',
        },
        loginServiceReducer: {
          identities: ['email@test.com', 'otheremail@test.com'],
        },
        templateServiceReducer: {
          templateName: 'BNE',
        },
      },
      args: [{ cookieName: 'toto' }],
    });

    const { actions } = await sagaDone;

    expect(actions).toEqual([
      {
        errors: ['error message'],
        formName: 'FORM_ID',
        scrollTop: true,
        type: FORM_HANDLER_FOCUS_ERROR_INPUT_REQUEST,
      },
      {
        errors: ['error message'],
        type: LOGIN_FORM_SUBMIT_FAILURE,
      },
    ]);
  });
});

describe('.watchChangeStepRequest', () => {
  test('.watchChangeStepRequest - LOGIN_WITH_ID_STEP', async () => {
    const nextStep = LOGIN_FORM_STEPS.LOGIN_WITH_ID_STEP;
    const { sagaDone } = testSaga({
      saga: watchChangeStepRequest,
      args: [
        {
          nextStep,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: LOGIN_FORM_UPDATE,
        fieldName: 'password',
        fieldValue: '',
      },
      {
        type: LOGIN_FORM_UPDATE,
        fieldName: 'identity',
        fieldValue: '',
      },
      {
        type: LOGIN_FORM_CHANGE_STEP_SUCCESS,
        step: nextStep,
      },
      {
        type: LOGIN_CLEAR_ERROR_MESSAGES,
      },
    ]);
  });

  test('.watchChangeStepRequest - LOGIN_WITHOUT_ID_STEP', async () => {
    const nextStep = LOGIN_FORM_STEPS.LOGIN_WITHOUT_ID_STEP;
    const { sagaDone } = testSaga({
      saga: watchChangeStepRequest,
      args: [
        {
          nextStep,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: LOGIN_FORM_UPDATE,
        fieldName: 'password',
        fieldValue: '',
      },
      {
        type: LOGIN_FORM_UPDATE,
        fieldName: 'identity',
        fieldValue: '',
      },
      {
        type: LOGIN_FORM_UPDATE,
        fieldName: 'remember',
        fieldValue: false,
      },
      {
        type: LOGIN_FORM_CHANGE_STEP_SUCCESS,
        step: nextStep,
      },
      {
        type: LOGIN_CLEAR_ERROR_MESSAGES,
      },
    ]);
  });

  test('.watchChangeStepRequest - PASSWORD_STEP', async () => {
    const { sagaDone } = testSaga({
      saga: watchChangeStepRequest,
      args: [
        {
          nextStep: LOGIN_FORM_STEPS.PASSWORD_STEP,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: LOGIN_FORM_UPDATE,
        fieldName: 'password',
        fieldValue: '',
      },
      {
        type: LOGIN_FORM_UPDATE,
        fieldName: 'remember',
        fieldValue: true,
      },
      {
        type: LOGIN_FORM_CHANGE_STEP_SUCCESS,
        step: LOGIN_FORM_STEPS.PASSWORD_STEP,
      },
      {
        type: LOGIN_CLEAR_ERROR_MESSAGES,
      },
    ]);
  });
});
