import * as BncAuthService from 'bnc-react-services/services/AuthService/actions';
import testReducer from 'bnc-utilities-js/testUtils/reducer';
import { TRANSACTION_STATUS } from 'bnc-react-services/managers/AuthManager/constants';
import loginReducer from '../reducer';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  loadIdentitySuccess,
  removeIdentity,
  loginMFA,
  displayResend,
  hideResend,
  updateContact,
  loginCodeValidationRequest,
  setReturnToSbipLoginFlag,
  setRememberDeviceBne,
  clearLoginErrorMessages,
  putIdentityWithDeviceToken,
  updateLockedStatus,
  startMfaDisplayResendCounter,
  tickMfaDisplayResendCounter,
  clearOtcResendMessage,
  lockUserPassword,
  unlockUserPassword,
  saveHashLogin,
} from '../actions';

const sdkErrorTestObject = { message: 'ERROR TEST', code: '-1', type: '' };
const loginMFAData = {
  contact: 'theverybest@gmail.com',
  MFAfactors: ['sms', 'email'],
  MFAfactorsHint: [{ sms: '+1 XXX-XXX-0713' }, { email: 'a....d@gmail.com' }],
  MFAselected: 'sms',
  transactionStatus: TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE,
  isHardEnrolling: true,
};

testReducer(loginReducer)
  .initialState()
  .expectDiff({
    isFetching: false,
    failure: false,
    sdkError: {},
    identities: [],
    identityRemembered: null,
    identityWithDeviceToken: [],
    MFAfactors: [],
    MFAfactorsHint: [],
    MFAselected: '',
    transactionStatus: '',
    isEnrollAfterBeenChallenged: false,
    contact: '',
    hideResend: true,
    returnToSbip: false,
    rsaReturnLoginFlag: false,
    rememberDeviceBne: false,
    mfaDisplayResendCounter: 33,
    showOTCSuccessMessage: false,
    userPasswordLocked: false,
    lastLoginFailureDate: {},
    lockedStatus: '',
    hashLogin: '',
  });

testReducer(loginReducer)
  .withAnyState()
  .on(removeIdentity('id'))
  .expectDiff({});

testReducer(loginReducer)
  .withAnyState()
  .on(loginRequest())
  .expectDiff({
    isFetching: true,
    failure: false,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(loginSuccess())
  .expectDiff({
    failure: false,
    isFetching: false,
    identityRemembered: null,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(loadIdentitySuccess(['toto@gmail.com']))
  .expectDiff({
    identities: ['toto@gmail.com'],
    isFetching: false,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(loginFailure(sdkErrorTestObject))
  .expectDiff({
    isFetching: false,
    failure: true,
    sdkError: sdkErrorTestObject,
    identityRemembered: null,
    showOTCSuccessMessage: false,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(BncAuthService.signInRequest('test@bnc.ca', 'pwd1234', null))
  .expectDiff({
    identityRemembered: null,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(BncAuthService.signInRequest('test@bnc.ca', 'pwd1234', 'deviceToken'))
  .expectDiff({
    identityRemembered: 'test@bnc.ca',
  });

testReducer(loginReducer)
  .withAnyState()
  .on(
    loginMFA(
      loginMFAData.contact,
      loginMFAData.MFAfactors,
      loginMFAData.MFAselected,
      loginMFAData.MFAfactorsHint,
      loginMFAData.transactionStatus,
      loginMFAData.isHardEnrolling,
    ),
  )
  .expectDiff({
    contact: loginMFAData.contact,
    sdkError: {},
    failure: false,
    MFAfactors: loginMFAData.MFAfactors,
    MFAfactorsHint: loginMFAData.MFAfactorsHint,
    MFAselected: loginMFAData.MFAselected,
    transactionStatus: loginMFAData.transactionStatus,
    isEnrollAfterBeenChallenged: loginMFAData.isHardEnrolling,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(displayResend())
  .expectDiff({
    hideResend: false,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(hideResend())
  .expectDiff({
    hideResend: true,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(updateContact({}, {}))
  .expectDiff({
    MFAselected: {},
    contact: {},
  });

testReducer(loginReducer)
  .withAnyState()
  .on(loginCodeValidationRequest())
  .expectDiff({
    failure: false,
    isFetching: true,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(clearLoginErrorMessages())
  .expectDiff({
    isFetching: false,
    failure: false,
    sdkError: {},
  });

testReducer(loginReducer)
  .withAnyState()
  .on(BncAuthService.signInCompleted())
  .expectDiff({
    isFetching: false,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(BncAuthService.resendRequest())
  .expectDiff({
    isFetching: true,
    failure: false,
    showOTCSuccessMessage: false,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(BncAuthService.resendSuccess())
  .expectDiff({
    isFetching: false,
    failure: false,
    sdkError: {},
    showOTCSuccessMessage: true,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(setReturnToSbipLoginFlag())
  .expectDiff({
    returnToSbip: true,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(updateLockedStatus(TRANSACTION_STATUS.LOCKED_OUT))
  .expectDiff({
    lockedStatus: TRANSACTION_STATUS.LOCKED_OUT,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(
    putIdentityWithDeviceToken(
      'test@bnc.ca',
      '12345678901234567890123456789012',
    ),
  )
  .expectDiff({
    identityWithDeviceToken: [
      { deviceToken: '12345678901234567890123456789012', login: 'test@bnc.ca' },
    ],
  });

testReducer(loginReducer)
  .withAnyState()
  .on(updateLockedStatus(TRANSACTION_STATUS.LOCKED_OUT))
  .expectDiff({
    lockedStatus: TRANSACTION_STATUS.LOCKED_OUT,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(startMfaDisplayResendCounter())
  .expectDiff({
    mfaDisplayResendCounter: 33,
    hideResend: true,
  });

testReducer(loginReducer)
  .withState({
    mfaDisplayResendCounter: 33,
  })
  .on(tickMfaDisplayResendCounter())
  .expectDiff({
    mfaDisplayResendCounter: 32,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(clearOtcResendMessage())
  .expectDiff({
    showOTCSuccessMessage: false,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(lockUserPassword())
  .expectDiff({
    userPasswordLocked: true,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(unlockUserPassword())
  .expectDiff({
    userPasswordLocked: false,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(saveHashLogin('dGVzdEVtYWls:dGVzdFBhc3N3b3Jk'))
  .expectDiff({
    hashLogin: 'dGVzdEVtYWls:dGVzdFBhc3N3b3Jk',
  });

testReducer(loginReducer)
  .withAnyState()
  .on(setRememberDeviceBne(true))
  .expectDiff({
    rememberDeviceBne: true,
  });

testReducer(loginReducer)
  .withAnyState()
  .on(setRememberDeviceBne())
  .expectDiff({});
