// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import ForgotPasswordException from './forgotPasswordException';

import { exceptionTypeSelector } from '../../../services/forgotPasswordService/selectors';
import { returnToLogin } from '../../../services/loginService/actions';
import {
  getPartnerIdName,
  getTemplateName,
} from '../../../services/templateService/selectors';

import type { Dispatch } from '../../../types/store';

function mapStateToProps(state) {
  return {
    exceptionType: exceptionTypeSelector(state),
    partnerId: getPartnerIdName(state),
    templateName: getTemplateName(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    returnToLogin: () => dispatch(returnToLogin()),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordException),
);
