import getReducerState from '../../utils/stateSelectorHelper';

import { UNSUPPORTED_BROWSER_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';

const getUnsupportBrowserState = (state, modulePath = '') =>
  getReducerState(state, modulePath, UNSUPPORTED_BROWSER_SERVICE_REDUCER);

export default {
  getUnsupportBrowserState,
};
