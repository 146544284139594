// @flow
export const LOGIN_FORM_VALIDATION_REQUEST = 'LOGIN_FORM_VALIDATION_REQUEST';
export const LOGIN_FORM_VALIDATION_FAILURE = 'LOGIN_FORM_VALIDATION_FAILURE';
export const LOGIN_FORM_VALIDATION_SUCCESS = 'LOGIN_FORM_VALIDATION_SUCCESS';
export const LOGIN_FORM_UPDATE = 'LOGIN_FORM_UPDATE';
export const LOGIN_FORM_RESET = 'LOGIN_FORM_RESET';
export const LOGIN_FORM_INIT_REQUEST = 'LOGIN_FORM_INIT_REQUEST';
export const LOGIN_FORM_INIT_DONE = 'LOGIN_FORM_INIT_DONE';
export const LOGIN_FORM_SUBMIT_REQUEST = 'LOGIN_FORM_SUBMIT_REQUEST';
export const LOGIN_FORM_CHANGE_STEP_REQUEST = 'LOGIN_FORM_CHANGE_STEP_REQUEST';
export const LOGIN_FORM_CHANGE_STEP_SUCCESS = 'LOGIN_FORM_CHANGE_STEP_SUCCESS';
export const LOGIN_FORM_SUBMIT_FAILURE = 'LOGIN_FORM_SUBMIT_FAILURE';
