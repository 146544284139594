import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import { Configs } from '../../configs';
import DTMMiddleware from './dtmMiddleware/index';

export const SagaMiddleware = (() => {
  let instance = null;
  function getInstance() {
    if (instance === null) {
      instance = createSagaMiddleware();
    }
    return instance;
  }
  return {
    getInstance,
  };
})();

export const createMiddlewares = () => {
  // Get saga middleware (or create it)
  const sagaMiddleware = SagaMiddleware.getInstance();
  // TO DO disabling it if not debug
  const loggerMiddleware = createLogger();

  let middlewares = [sagaMiddleware, DTMMiddleware];

  if (Configs.DEBUG_MODE) {
    middlewares = [...middlewares, loggerMiddleware];
  }

  return middlewares;
};
