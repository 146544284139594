import { connect } from 'react-redux';

import ForgotPassword from './forgotPassword';

import {
  formUpdate,
  triggerFieldValidation,
  triggerFormValidation,
} from '../../../services/forgotPasswordFormService/actions';
import {
  errorsSelector,
  formDataSelector,
  isTokenCaptchaError,
} from '../../../services/forgotPasswordFormService/selectors';
import {
  isFetchingSelector,
  failureSelector,
  getBackendError,
  getNumberOfRetry,
} from '../../../services/forgotPasswordService/selectors';
import {
  getPartnerIdName,
  getTemplateName,
} from '../../../services/templateService/selectors';
import { getLocale } from '../../../services/i18nService/selectors';
import { returnToLogin } from '../../../services/loginService/actions';

const mapStateToProps = state => {
  return {
    formData: formDataSelector(state),
    errors: errorsSelector(state),
    isFetching: isFetchingSelector(state),
    isFailure: failureSelector(state),
    backendError: getBackendError(state),
    numberOfRetry: getNumberOfRetry(state),
    isTokenCaptchaError: isTokenCaptchaError(state),
    templateName: getTemplateName(state),
    partnerId: getPartnerIdName(state),
    getLocale: getLocale(state),
    query: state.routing?.locationBeforeTransitions?.query,
  };
};

const mapDispatchToProps = dispatch => ({
  formUpdate: (fieldName, fieldValue) =>
    dispatch(formUpdate(fieldName, fieldValue)),
  triggerFieldValidation: fieldName =>
    dispatch(triggerFieldValidation(fieldName)),
  triggerFormValidation: () => dispatch(triggerFormValidation()),
  returnToLogin: () => dispatch(returnToLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
