import { createSelector } from 'reselect';
import get from 'lodash/get';

import getReducerState from '../../utils/stateSelectorHelper';

import { CONFIGS_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';

export const getConfigsState = (state, modulePath = '') =>
  getReducerState(state, modulePath, CONFIGS_SERVICE_REDUCER);

export const getAllConfigs = createSelector(getConfigsState, configState =>
  get(configState, 'configs', {}),
);

const getExpectedMaintenanceModeReturnTime = createSelector(
  getConfigsState,
  configState =>
    get(configState, 'configs.status.global.options.maintenance_mode.date'),
);

const getExpectedUnavailableModeReturnTime = createSelector(
  getConfigsState,
  configState =>
    get(configState, 'configs.status.global.options.unavailable_mode.date'),
);

export const isMaintenanceModeEnable = createSelector(
  getConfigsState,
  configState =>
    get(
      configState,
      'configs.status.global.options.maintenance_mode.active',
      false,
    ),
);

export const isUnavailableModeEnable = createSelector(
  getConfigsState,
  configState =>
    get(
      configState,
      'configs.status.global.options.unavailable_mode.active',
      false,
    ),
);

export const getExpectedAppReturnTime = createSelector(
  isMaintenanceModeEnable,
  isUnavailableModeEnable,
  getExpectedMaintenanceModeReturnTime,
  getExpectedUnavailableModeReturnTime,
  (
    isMaintenanceMode,
    isUnavailableMode,
    maintenanceModeReturnTime,
    unavailableModeReturnTime,
  ) => {
    if (isUnavailableMode) return unavailableModeReturnTime;
    if (isMaintenanceMode) return maintenanceModeReturnTime;
    return null;
  },
);

export const isLoginAlertConfigEnable = createSelector(
  getConfigsState,
  configState =>
    get(configState, 'configs.status.login.options.alert.active', false),
);
