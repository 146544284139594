import React from 'react';
import { IntlShape } from 'react-intl';
import { Helmet } from 'react-helmet';

type Props = {
  intl: IntlShape,
  messageKey: string,
  partnerId: string,
};

function HeadTitle(props: Props) {
  const { intl, messageKey, partnerId } = props;

  const pageTitle = intl.formatMessage({ id: messageKey });
  const titleKey =
    partnerId && partnerId !== 'partnership'
      ? `text.title.${partnerId}.page`
      : 'text.title.page';

  return (
    <Helmet>
      <title>{intl.formatMessage({ id: titleKey }, { pageTitle })}</title>
    </Helmet>
  );
}

export default HeadTitle;
