// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import MultiFactorAuthChoice from './multiFactorAuthChoice';

import type { Dispatch } from '../../../types/store';
import type { State } from '../../../types/state';

import { returnToLogin } from '../../../services/loginService/actions';

import { getSdkError } from '../../../services/loginService/selectors';
import { getAuthErrorMessageId } from '../../../utils/authErrorMessages';
import { triggerSendCodeRequest } from '../../../services/multiFactorAuthFormService/actions';
import { isLoginFormDone } from '../../../services/loginFormService/selectors';

const mapStateToProps = (state: State) => ({
  sdkError: getSdkError(state),
  getAuthErrorMessageId,
  isFormDone: isLoginFormDone(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  returnToLogin: () => dispatch(returnToLogin()),
  triggerCodeRequest: factor => dispatch(triggerSendCodeRequest(factor)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuthChoice),
);
