import testReducer from 'bnc-utilities-js/testUtils/reducer';
import tokenCaptchaReducer from '../reducer';
import {
  tokenCaptchaRequest,
  tokenCaptchaRequestFailure,
  tokenCaptchaRequestSuccess,
  tokenCaptchaReset,
} from '../actions';

const tokenCaptchaTestObj = {
  accessToken:
    'eyJraWQiOiJnb3d3MnlLUE51b1JBNFJ2TkNSSDVxWGIxTlRSQ2c5Nzd5bjZSclB3TExJIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULmRnaDdaZ3UwRlVNUFJTNlBySExxamJyNVJ4SHFmTktpTFhnczV1cXRacnciLCJpc3MiOiJodHRwczovL25iYy5va3RhcHJldmlldy5jb20vb2F1dGgyL2F1c2FnaHh3ejBsYlhtc3pxMGg3IiwiYXVkIjoiMTl4enFCbTZkb1V0NzlvUVpPTFkiLCJpYXQiOjE2NDI3OTkwOTUsImV4cCI6MTY0Mjc5OTk5NSwiY2lkIjoiMG9haHBjbXpuOE5KSkVRUTcwaDciLCJ1aWQiOiIwMHUxMmgybGRkYU5ZRURDMTBoOCIsInNjcCI6WyJvcGVuaWQiLCJlbnRlcnByaXNlOmJhbmtpbmciXSwic3ViIjoiMjQyODM0MSIsImdyb3VwcyI6WyJFbnRlcnByaXNlLkJhbmtpbmciLCJMREFQIiwiRW50ZXJwcmlzZSJdfQ.KZiIsMtt5PGg0yqJ1Wf6pLNUkfTQIzPq2_9vEAUX8Vu08o_aQhUX0MCRhoAaeZtFj8hLO64QgEWYeAp3mUeXZrWsyhZ2BEBvjy3lwbXKYYsOkqsJfdyYQmmD7g5H9W2qCvNnUeNeIVmJMqcPtmMmgqSNLfHnWAJlxQF1zR6LzMjYDY8l8OTbrDvdH7o0iV3s6EkRSdrEse-zM60iHGbN5Fb_ROLbpXQBzh2TSeK2_74xRSkp2tLk3jSNKQeVonF4M8oCkItPQ86QNtrpud0DXIHvHtKZOQJVWDP7nEAEnUkxDNigG3ZfE5pR2cBK19k0cpR3EoyRXycnI3SzAY-pnA',
  scope: 'openid enterprise:banking',
  expiresIn: 3600,
  expirationTime: new Date().getTime(),
};

testReducer(tokenCaptchaReducer)
  .initialState()
  .expectDiff({
    token: null,
    failure: false,
  });

testReducer(tokenCaptchaReducer)
  .withAnyState()
  .on(tokenCaptchaReset())
  .expectDiff({
    token: null,
    failure: false,
  });

testReducer(tokenCaptchaReducer)
  .withAnyState()
  .on(tokenCaptchaRequestFailure())
  .expectDiff({
    token: null,
    failure: true,
  });

testReducer(tokenCaptchaReducer)
  .withAnyState()
  .on(tokenCaptchaRequestSuccess(tokenCaptchaTestObj))
  .expectDiff({
    token: tokenCaptchaTestObj,
  });

testReducer(tokenCaptchaReducer)
  .withAnyState()
  .on(tokenCaptchaRequest('sdjshkdfjejdkejdsdksdjsjd', 'nbfgid'))
  .expectDiff({
    failure: false,
  });
