import React from 'react';

import Right2ColumnsTemplate from '../../models/Right2ColumnsTemplate';

import LoginFooter from '../../../organisms/secureKey/LoginFooter';
import Header from '../../../organisms/common/Header';
import Login from '../../../organisms/secureKey/Login';
import SecureKeyMedia from '../../../molecules/common/SecureKeyMedia';

import './style.scss';

const LoginTemplate = () => (
  <Right2ColumnsTemplate
    header={<Header logoTagName="h1" />}
    main={<Login hasForgotPwd={false} />}
    footer={<LoginFooter hasSignUpMigration />}
    assets={<SecureKeyMedia />}
    className="secureKey"
  />
);

export default LoginTemplate;
