// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import type { Dispatch } from '../../../types/store';
import type { State } from '../../../types/state';

import { loginRouteRequestSignUp } from '../../../services/loginRouteService/actions';
import SignUp from './signUp';

import { getTemplateName } from '../../../services/templateService/selectors';

function mapStateToProps(state: State) {
  return {
    templateName: getTemplateName(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    loginRouteRequestSignUp: () => dispatch(loginRouteRequestSignUp()),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SignUp));
