import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import FormHandler from '../../../molecules/forms/FormHandler';
import LoginForm from '../../../molecules/forms/LoginForm';

import { FORM_TYPES } from '../../../utils/constants/forms';

const propTypes = {
  intl: intlShape.isRequired,
  className: PropTypes.string,
  hasForgotPwd: PropTypes.boolean,
};

const defaultProps = {
  className: '',
  hasForgotPwd: true,
};

function Login(props) {
  const { intl, className, hasForgotPwd } = props;

  const informationalMessage = (
    <>
      <span>{intl.formatMessage({ id: 'rewards.text.new' })}</span>
      {intl.formatMessage({ id: 'rewards.text.whatsnew1' })}
    </>
  );

  return (
    <div id="loginForm" className="connect-form">
      <FormHandler formName={FORM_TYPES.LOGIN_FORM}>
        <LoginForm
          className={className}
          hasForgotPwd={hasForgotPwd}
          informationalMessage={informationalMessage}
          signInButtonTheme="transactional"
        />
      </FormHandler>
    </div>
  );
}

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

export default Login;
