import type { IntlShape } from 'react-intl';
import { getAuthErrorMessageId } from '../authErrorMessages';
import { getMessageKey } from '../templateUtils';
import type { SDKError } from '../../types';

const getRsaErrorMessage = (
  templateName: String,
  sdkError: SDKError,
  intl: IntlShape,
  sessionManagerStatus: String,
) => {
  const messageKey = getMessageKey(
    templateName,
    `auth.error.${getAuthErrorMessageId(sdkError)}`,
  );
  let msgRsaError;
  if (sessionManagerStatus === 'MFA_ENROLL') {
    msgRsaError = '.rsa.enroll';
  } else {
    msgRsaError = '.rsa';
  }
  return intl.formatMessage(
    { id: `${messageKey}${msgRsaError}` },
    { code: sdkError.code },
  );
};

export default getRsaErrorMessage;
