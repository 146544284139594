// @flow
import type { Saga } from 'redux-saga';

import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import SessionManager from 'bnc-react-services/managers/SessionManager';
import requestsManager from 'bnc-react-services/managers/RequestsManager';

import * as actionTypes from './actionTypes';
import type { RedirectToPageAction } from './types';

export function* watchRedirectToPage(action: RedirectToPageAction): Saga<void> {
  const { pageUrl } = action;
  if (pageUrl) {
    // Clearing local session before redirecting to login page
    yield requestsManager.call(SessionManager.clearSession);
    window.location.replace(pageUrl);
  }
}

export const workersMapping = createWorkersMapping().takeLatest(
  actionTypes.REDIRECT_TO_PAGE,
  watchRedirectToPage,
);

export default createRootSagaFromWorkersMapping(workersMapping);
