// @flow
import React, { useEffect, useState } from 'react';
import { IntlShape, FormattedMessage } from 'react-intl';
import { Text } from '@bnc-ui/text';
import { Alert } from '@bnc-ui/alert';
import { Link } from '@bnc-ui/link';
import { Button } from '@nbc-design/button';
import { Heading } from '@nbc-design/heading';
import { LockedColor } from '@nbc-design/icons/lib/web/LockedColor';
import { focusOnComponent } from '../../../utils/domUtils';
import { unlockedTime } from '../../../utils/bne/lockedAccountUtils';
import ROUTES from '../../../utils/constants/routes';
import browserHistory from '../../../nav/BrowserHistoryManager';
import { getMessageKey } from '../../../utils/templateUtils';
import { getUserLockedCookie } from '../../../utils/lockUserCookie';

import './style.scss';
import '@bnc-layouts/error/dist/error.css';
import RoadBlockTaggingWrapper from '../../../molecules/bne/TaggingBNE/RoadBlockTaggingWrapper';
import ClickTaggingWrapper from '../../../molecules/bne/TaggingBNE/ClickTaggingWrapper';
import { getForgotPasswordUrl } from '../../../utils/bne/EnvironmentUtils';

type Props = {
  intl: IntlShape,
  templateName: string,
  returnToLogin: func,
  lockedStatus: string,
  isFetching: boolean,
};

const AccountLocked = (props: Props) => {
  const { intl, returnToLogin, templateName, lockedStatus, isFetching } = props;

  const { locale } = intl;
  const errorIconSize = { width: 168, height: 168 };

  const [buttonClicked, setButtonClicked] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!lockedStatus) {
      browserHistory.push(`${ROUTES.LOGIN}${window.location.search}`);
    }

    focusOnComponent('#back-to-login');
  }, [lockedStatus]);

  const userLockedCookie = getUserLockedCookie();

  const accountLockedWarningMessage = (
    <FormattedMessage
      id={getMessageKey(
        templateName,
        'text.message.accountlocked.warning.message',
      )}
      values={{
        bold: (
          <Link
            href={getForgotPasswordUrl()}
            id="link-forgot-password"
            underlined="true"
            tabIndex="0"
            onClick={isFetching ? () => {} : () => setButtonClicked(true)}
          >
            {intl.formatMessage({
              id: getMessageKey(
                templateName,
                'text.message.accountlocked.warning.message.bold',
              ),
            })}
          </Link>
        ),
      }}
    />
  );

  const eventLabel = 'account locked';
  const interaction = 'forgot-password';

  return (
    <RoadBlockTaggingWrapper eventLabel={eventLabel}>
      <div className="account-locked-bne">
        <div className="account-locked-bne__container account-locked-bne__container-title">
          <div className="account-locked-bne__iconContainer">
            <LockedColor size={errorIconSize} />
          </div>
          <Heading type="h1" size={2} className="account-locked-bne__title">
            {intl.formatMessage({
              id: getMessageKey(
                templateName,
                'text.message.accountlocked.sorry',
              ),
            })}
          </Heading>
        </div>

        <div className="account-locked-bne__container account-locked-bne__container-subtitle">
          <Text className="account-locked-bne__subtitle">
            <FormattedMessage
              id={getMessageKey(
                templateName,
                'text.message.accountlocked.warning',
              )}
              values={{
                lockedTime: unlockedTime(userLockedCookie, locale),
              }}
            />
          </Text>
        </div>

        <div className="account-locked-bne__container account-locked-bne__container-alert">
          <Alert
            className="account-locked-bne__alert"
            description={accountLockedWarningMessage}
            size="normal"
            ariaLabelIcon="Information Icon"
          />
          {buttonClicked === true && (
            <ClickTaggingWrapper interaction={interaction} />
          )}
        </div>

        <div className="account-locked-bne__container account-locked-bne__container-btn">
          <Button
            appearance="primary"
            theme="entreprise"
            className="account-locked-bne__btn"
            id="back-to-login"
            onClick={returnToLogin}
          >
            {intl.formatMessage({
              id: getMessageKey(
                templateName,
                'text.message.accountlocked.button',
              ),
            })}
          </Button>
        </div>
      </div>
    </RoadBlockTaggingWrapper>
  );
};

export default AccountLocked;
