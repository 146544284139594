import get from 'lodash/get';

import { TRANSACTION_STATUS } from 'bnc-react-services/managers/AuthManager/constants';

import { MAX_IDENTITIES_SAVED } from '../../../utils/constants/login';
import generateSelectorTests from '../../../utils/selectorTestHelper';

import {
  getContact,
  getIdentities,
  getIdentityWithDeviceToken,
  getLockedStatus,
  getLoginState,
  getMFAfactors,
  getLoginFactorsHint,
  getMFAselected,
  getSdkError,
  hasIdentities,
  hasLoginFailed,
  hasMaxIdentities,
  isFetching,
  isHideResend,
  getMfaDisplayResendCounter,
  getMFAfactorsWithValues,
  getLockedUserPassword,
  showOTCSuccessMessageSelector,
  getLastLoginFailureDate,
  isEnrollAfterBeenChallenged,
  getHashLogin,
} from '../selectors';

describe('getLoginState', () => {
  it('should return state if they are available', () => {
    const state = {
      loginServiceReducer: { test: 1 },
    };
    expect(getLoginState(state)).toEqual({ test: 1 });
  });

  it('should return {} if state are not available', () => {
    const state = {};
    expect(getLoginState(state)).toEqual({});
  });

  it('should return module state if they are available', () => {
    const modulePath = 'front_end.sso';

    const state = {
      front_end: {
        sso: {
          loginServiceReducer: { test: 1 },
        },
      },
    };
    expect(getLoginState(state, modulePath)).toEqual({ test: 1 });
  });
});

const TEST_SELECTORS = [
  {
    testName: 'getIdentities',
    selector: getIdentities,
    selectorField: 'loginServiceReducer.identities',
    selectorType: 'array.string',
    defaultValue: [],
  },
  {
    selector: hasLoginFailed,
    selectorField: 'loginServiceReducer.failure',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    selector: isFetching,
    selectorField: 'loginServiceReducer.isFetching',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    selector: isHideResend,
    selectorField: 'loginServiceReducer.hideResend',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    selector: getContact,
    selectorField: 'loginServiceReducer.contact',
    selectorType: 'string',
    defaultValue: '',
  },
  {
    selector: getMFAselected,
    selectorField: 'loginServiceReducer.MFAselected',
    selectorType: 'string',
    defaultValue: '',
  },
  {
    selector: getSdkError,
    selectorField: 'loginServiceReducer.sdkError',
    selectorType: 'object',
    defaultValue: {},
  },
  {
    testName: 'hasMaxIdentities',
    selector: hasMaxIdentities,
    selectorField: 'loginServiceReducer.identities',
    selectorType: 'array.string',
    defaultValue: false,
    outputMapper: x => x.length >= MAX_IDENTITIES_SAVED,
  },
  {
    testName: 'hasIdentities',
    selector: hasIdentities,
    selectorField: 'loginServiceReducer.identities',
    selectorType: 'array.string',
    defaultValue: false,
    outputMapper: x => x.length > 0,
  },
  {
    testName: 'getMFAfactors',
    selector: getMFAfactors,
    selectorField: 'loginServiceReducer.MFAfactors',
    selectorType: 'array.string',
    defaultValue: [],
    outputMapper: x =>
      x.map(factorType => ({
        factorType,
        value: '',
      })),
  },
  {
    testName: 'getLoginFactorsHint',
    selector: getLoginFactorsHint,
    selectorField: 'loginServiceReducer.MFAfactorsHint',
    selectorType: 'array.object',
    defaultValue: [],
    outputMapper: factors =>
      factors.map(cursor => {
        const factorTypeKey = Object.keys(cursor)[0];
        return {
          factorType: factorTypeKey.toLowerCase(),
          value: cursor[factorTypeKey],
        };
      }),
  },
  {
    selector: showOTCSuccessMessageSelector,
    selectorField: 'loginServiceReducer.showOTCSuccessMessage',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    testName: 'getIdentityWithDeviceToken',
    selector: getIdentityWithDeviceToken,
    selectorField: 'loginServiceReducer.identityWithDeviceToken',
    selectorType: 'array.object',
    defaultValue: [],
  },
  {
    selector: getLockedStatus,
    selectorField: 'loginServiceReducer.lockedStatus',
    selectorType: 'string',
    defaultValue: '',
  },
  {
    selector: getMfaDisplayResendCounter,
    selectorField: 'loginServiceReducer.mfaDisplayResendCounter',
    selectorType: 'int',
    defaultValue: 33,
  },
  {
    testName: 'getMFAfactorsWithValues',
    selector: getMFAfactorsWithValues,
    selectorField: 'loginServiceReducer.MFAfactors',
    selectorType: 'array.object',
    defaultValue: [],
  },
  {
    selector: getLockedUserPassword,
    selectorField: 'loginServiceReducer.userPasswordLocked',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    selector: showOTCSuccessMessageSelector,
    selectorField: 'loginServiceReducer.showOTCSuccessMessage',
    selectorType: 'boolean',
    defaultValue: false,
  },
  {
    testName: 'getLastLoginFailureDate',
    selector: getLastLoginFailureDate,
    selectorField: 'loginServiceReducer.lastLoginFailureDate',
    selectorType: 'object',
    defaultValue: {},
  },
  {
    testName: 'getHashLogin',
    selector: getHashLogin,
    selectorField: 'loginServiceReducer.hashLogin',
    selectorType: 'string',
    defaultValue: '',
  },
];

describe('Test Selectors', generateSelectorTests(TEST_SELECTORS));

describe('isEnrollAfterBeenChallenged', () => {
  [
    {
      state: {
        loginServiceReducer: {
          isEnrollAfterBeenChallenged: false,
          transactionStatus: TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE,
        },
      },
      expectedValue: false,
    },
    {
      state: {
        loginServiceReducer: {
          isEnrollAfterBeenChallenged: true,
          transactionStatus: TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE,
        },
      },
      expectedValue: true,
    },
    {
      state: {
        loginServiceReducer: {
          isEnrollAfterBeenChallenged: false,
          transactionStatus: TRANSACTION_STATUS.MFA_CHALLENGE,
        },
      },
      expectedValue: false,
    },
    {
      state: {
        loginServiceReducer: {
          isEnrollAfterBeenChallenged: true,
          transactionStatus: TRANSACTION_STATUS.MFA_CHALLENGE,
        },
      },
      expectedValue: false,
    },
    {
      state: {},
      expectedValue: false,
    },
  ].forEach(({ state, expectedValue }) =>
    it(`should return ${expectedValue} when isEnrollAfterBeenChallenged=${get(
      state,
      'loginServiceReducer.isEnrollAfterBeenChallenged',
    )} and transactionStatus=${get(
      state,
      'loginServiceReducer.transactionStatus',
    )}`, () => {
      expect(isEnrollAfterBeenChallenged(state)).toEqual(expectedValue);
    }),
  );
});
