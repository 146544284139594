import React, { useEffect, useRef } from 'react';
import FullScreenFooterTemplate from '../../models/FullScreenFooterTemplate';
import AccountLocked from '../../../organisms/bne/AccountLocked';
import BncBrandEnterprise from '../../../molecules/common/BncBrandEnterprise';

import './styles.scss';

type Props = {
  lockedStatus: string,
};
const AccountLockedTemplate = (props: Props) => {
  const { lockedStatus } = props;
  const channelRef = useRef(new BroadcastChannel('accountLocked_sync'));
  const channel = channelRef.current;

  useEffect(() => {
    channel.postMessage({
      lockedStatus,
      message: 'Account locked',
    });
  });

  return (
    <FullScreenFooterTemplate
      className="account-locked-template"
      header={<BncBrandEnterprise />}
      main={<AccountLocked lockedStatus={lockedStatus} />}
    />
  );
};

export default AccountLockedTemplate;