// @flow
import React, { useEffect } from 'react';
import type { IntlShape } from 'react-intl';
import { Button } from '@bnc-ui/button';
import { Text } from '@bnc-ui/text';
import { Confirmation } from '@bnc-ui/confirmation';
import { focusOnComponent } from '../../../utils/domUtils';
import './style.scss';

type Props = {
  intl: IntlShape,
  loginRouteRequestAppOrigin: () => void,
  announceMessage: (msg: string) => void,
};

const PasswordChangeSuccess = (props: Props) => {
  const { intl, loginRouteRequestAppOrigin, announceMessage } = props;

  useEffect(() => {
    setTimeout(
      () => announceMessage('text.message.passwordChangedInstructions'),
      1000,
    );
  }, [announceMessage]);

  useEffect(() => {
    focusOnComponent('#back-to-login');
  });

  return (
    <div className="sbip2-password-change-success">
      <Confirmation
        type="h1"
        appearance="success"
        title={intl.formatMessage({ id: 'text.message.passwordChanged' })}
      />

      <Text>
        {intl.formatMessage({ id: 'text.message.passwordChangedInstructions' })}
      </Text>

      <Button
        id="back-to-login"
        appearance="primary"
        onClick={() => loginRouteRequestAppOrigin()}
      >
        {intl.formatMessage({ id: 'global.link.backToLogin' })}
      </Button>
    </div>
  );
};

export default PasswordChangeSuccess;
