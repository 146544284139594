import get from 'lodash/get';

import { Configs } from '../../configs';
import { IE11_BROWSER_NAME } from '../../utils/browserUtils';

// eslint-disable-next-line import/prefer-default-export
export function shouldShowIE11Page(
  browserName,
  browserVersion,
  referenceDate = new Date(),
) {
  if (browserName === IE11_BROWSER_NAME && browserVersion === 11) {
    const deadLineStringDate = get(
      Configs,
      'NO_HOMOLOGATION_IE11.IE11_ENDED_SUPPORT',
      '2999-12-08T05:00:00.000Z',
    );
    const effectiveStringDate = get(
      Configs,
      'NO_HOMOLOGATION_IE11.IE11_EFFECTIVE_DATE',
      '2999-11-10T05:00:00.000Z',
    );
    const deadlineDate = new Date(deadLineStringDate);
    const effectiveDate = new Date(effectiveStringDate);
    return referenceDate >= effectiveDate && deadlineDate <= referenceDate;
  }
  return false;
}
