import React from 'react';
import PropTypes from 'prop-types';

import ErrorDisplay from '../../../molecules/common/ErrorDisplay';

const propTypes = {
  errorTitle: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
};

function Error(props) {
  const { errorTitle, errorText } = props;

  return <ErrorDisplay errorTitle={errorTitle} errorText={errorText} />;
}

Error.propTypes = propTypes;

export default Error;
