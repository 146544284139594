import isAppUnavailable from '../helper';

describe('isAppUnavailable()', () => {
  [
    {
      configs: {
        status: {
          global: {
            options: {
              maintenance_mode: {
                active: true,
              },
              unavailable_mode: {
                active: true,
              },
            },
          },
        },
      },
      expectedResult: true,
      mode: 'maintenance_mode',
    },
    {
      configs: {
        status: {
          global: {
            options: {
              unavailable_mode: {
                active: false,
              },
            },
          },
        },
      },
      expectedResult: true,
      mode: 'unavailable_mode',
    },
    {
      configs: {
        status: {
          global: {
            options: {
              unavailable_mode: {
                active: true,
              },
              maintenance_mode: {
                active: false,
              },
            },
          },
        },
      },
      expectedResult: true,
      mode: 'unavailable_mode',
    },
    {
      configs: {
        status: {
          global: {
            options: {
              unavailable_mode: {
                active: false,
              },
              maintenance_mode: {
                active: true,
              },
            },
          },
        },
      },
      expectedResult: true,
      mode: 'maintenance_mode',
    },
    {
      configs: {
        status: {},
      },
      expectedResult: true,
      mode: 'unavailable_mode',
    },
  ].forEach(({ configs, expectedResult, mode }) => {
    it(`config '${mode}' is active' it should return '${expectedResult}'`, () => {
      expect(isAppUnavailable({ configs })).toEqual(expectedResult);
    });
  });
});
