import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { announceMessage } from 'bnc-react-services/services/LiveAnnouncerService/actions';
import Intro from './intro';

const mapDispatchToProps = dispatch => ({
  announceMessage: msg => dispatch(announceMessage(msg, 'polite')),
});

export default injectIntl(connect(null, mapDispatchToProps)(Intro));
