import React, { useEffect } from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { Heading } from '@bnc-ui/heading';
import { Alert } from '@bnc-ui/alert';
import { Text } from '@bnc-ui/text';
import { formatMessageWithFallback } from '../../../../utils/formUtils';
import { queueAnnouncer } from '../../../../utils/liveAnnouncer/helper';

const propTypes = {
  intl: intlShape.isRequired,
  displayErrorMessage: PropTypes.bool,
  errorMessageId: PropTypes.string,
  vsdPhoneNumber: PropTypes.string.isRequired,
  announceMessage: PropTypes.func.isRequired,
};

const defaultProps = {
  displayErrorMessage: false,
  errorMessageId: '',
};

const Intro = ({
  intl,
  displayErrorMessage,
  errorMessageId,
  vsdPhoneNumber,
  announceMessage,
}) => {
  const title = formatMessageWithFallback(
    intl,
    `auth.error.${errorMessageId}.title`,
    null,
    { vsdPhoneNumber },
  );

  const description = formatMessageWithFallback(
    intl,
    `auth.error.${errorMessageId}`,
    null,
    { vsdPhoneNumber },
  );

  useEffect(
    () =>
      queueAnnouncer(
        [
          intl.formatMessage({ id: 'text.title.passwordReset' }),
          intl.formatMessage({ id: 'text.message.passwordResetInfo' }),
        ],
        announceMessage,
      ),
    [announceMessage, intl],
  );

  useEffect(() => {
    if (displayErrorMessage) {
      const messages = [];
      if (title) messages.push(title);
      if (description) messages.push(description);
      queueAnnouncer(messages, announceMessage);
    }
  }, [displayErrorMessage, title, description, announceMessage]);

  return (
    <div className="forgot-password-form--group__header">
      <Heading type="h1" size={1}>
        {intl.formatMessage({ id: 'text.title.passwordReset' })}
      </Heading>
      <Text size="paragraph" className="forgot-password-form--group--reset">
        {intl.formatMessage({ id: 'text.message.passwordResetInfo' })}
      </Text>
      {displayErrorMessage && (
        <Alert
          className="forgot-password-form--group--alert"
          appearance="error"
          title={title}
          description={description}
        />
      )}
    </div>
  );
};

Intro.propTypes = propTypes;
Intro.defaultProps = defaultProps;

export default Intro;
