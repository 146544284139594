// @flow

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_LOGOUT = 'LOGIN_LOGOUT';
export const LOGIN_MFA = 'LOGIN_MFA';
export const LOGIN_FLOW_SUCCESS = 'LOGIN_FLOW_SUCCESS';
export const LOGIN_LOAD_IDENTITY_SUCCESS = 'LOGIN_LOAD_IDENTITY_SUCCESS';
export const LOGIN_CLEAR_ERROR_MESSAGES = 'LOGIN_CLEAR_ERROR_MESSAGES';
export const LOGIN_REMOVE_IDENTITY = 'LOGIN_REMOVE_IDENTITY';
export const LOGIN_RETURN_TO_LOGIN = 'LOGIN_RETURN_TO_LOGIN';
export const LOGIN_SET_RETURN_SBIP_LOGIN_FLAG =
  'LOGIN_SET_RETURN_SBIP_LOGIN_FLAG';

export const LOGIN_MFA_TRIGGER_HIDE_RESEND = 'LOGIN_MFA_TRIGGER_HIDE_RESEND';
export const LOGIN_MFA_SEND_CODE_REQUEST = 'LOGIN_MFA_SEND_CODE_REQUEST';
export const LOGIN_MFA_RESEND_CODE_REQUEST = 'LOGIN_MFA_RESEND_CODE_REQUEST';
export const LOGIN_MFA_CODE_VALIDATION_REQUEST =
  'LOGIN_MFA_CODE_VALIDATION_REQUEST';
export const LOGIN_MFA_DISPLAY_RESEND = 'LOGIN_MFA_DISPLAY_RESEND';
export const LOGIN_MFA_HIDE_RESEND = 'LOGIN_MFA_HIDE_RESEND';
export const LOGIN_MFA_UPDATE_CONTACT = 'LOGIN_MFA_UPDATE_CONTACT';
export const LOGIN_MFA_HIDE_RESEND_SUCCESS_MESSAGE =
  'LOGIN_MFA_HIDE_RESEND_SUCCESS_MESSAGE';
export const UPDATE_LOCKED_STATUS = 'UPDATE_LOCKED_STATUS';
export const START_MFA_DISPLAY_RESEND_COUNTER =
  'START_MFA_DISPLAY_RESEND_COUNTER';
export const TICK_MFA_DISPLAY_RESEND_COUNTER =
  'TICK_MFA_DISPLAY_RESEND_COUNTER';
export const CLEAR_OTC_RESEND_MESSAGE = 'CLEAR_OTC_RESEND_MESSAGE';

export const USER_LOCKED_PASSWORD = 'USER_LOCKED_PASSWORD';
export const USER_LOCK_PASSWORD = 'USER_LOCK_PASSWORD';
export const USER_UNLOCK_PASSWORD = 'USER_UNLOCK_PASSWORD';
export const UPDATE_LAST_LOGIN_FAILURE_DATE = 'UPDATE_LAST_LOGIN_FAILURE_DATE';

export const PUT_IDENTITY_WITH_DEVICE_TOKEN = 'PUT_IDENTITY_WITH_DEVICE_TOKEN';
export const TRACK_RESEND_SUCCESS = 'TRACK_RESEND_SUCCESS';

export const SAVE_HASH_LOGIN = 'SAVE_HASH_LOGIN';

export const RSA_RETURN_LOGIN_FLAG = 'RSA_RETURN_LOGIN_FLAG';
export const REMEMBER_DEVICE_BNE = 'REMEMBER_DEVICE_BNE';
