// @flow
import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import * as actionTypes from './actionTypes';

import {
  watchLoginPasswordException,
  watchLoginPasswordExpired,
  watchLoginLocked,
  watchLoginMFA,
  watchLoginMFAChoice,
  watchLoginSignUp,
  watchLoginAppOrigin,
  watchLoginForgotPassword,
  watchLoginForgotPasswordException,
  watchLoginResetPassword,
  watchLoginRouteWithoutSignIn,
  watchLoginForgotPasswordUnavailable,
  watchLoginRSA,
} from './workers';

export const workersMapping = createWorkersMapping()
  .takeLatest(
    actionTypes.LOGIN_ROUTE_PASSWORD_EXPIRED,
    watchLoginPasswordExpired,
  )
  .takeLatest(
    actionTypes.LOGIN_ROUTE_PASSWORD_EXCEPTION,
    watchLoginPasswordException,
  )
  .takeLatest(actionTypes.LOGIN_ROUTE_LOCKED, watchLoginLocked)
  .takeLatest(actionTypes.LOGIN_ROUTE_MFA_CHOICE, watchLoginMFAChoice)
  .takeLatest(actionTypes.LOGIN_ROUTE_RSA, watchLoginRSA)
  .takeLatest(actionTypes.LOGIN_ROUTE_MFA, watchLoginMFA)
  .takeLatest(actionTypes.LOGIN_ROUTE_SIGN_UP, watchLoginSignUp)
  .takeLatest(actionTypes.LOGIN_ROUTE_APP_ORIGIN, watchLoginAppOrigin)
  .takeLatest(actionTypes.LOGIN_ROUTE_FORGOT_PASSWORD, watchLoginForgotPassword)
  .takeLatest(
    actionTypes.FORGOT_PASSWORD_UNAVAILABLE,
    watchLoginForgotPasswordUnavailable,
  )
  .takeLatest(
    actionTypes.LOGIN_ROUTE_FORGOT_PASSWORD_EXCEPTION,
    watchLoginForgotPasswordException,
  )
  .takeLatest(actionTypes.LOGIN_ROUTE_RESET_PASSWORD, watchLoginResetPassword)
  .takeLatest(
    actionTypes.LOGIN_ROUTE_WITHOUT_SIGN_IN,
    watchLoginRouteWithoutSignIn,
  );
export default createRootSagaFromWorkersMapping(workersMapping);
