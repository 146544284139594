import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Header from './header';
import { getTemplateName } from '../../../services/templateService/selectors';
import { getLocale } from '../../../services/i18nService/selectors';

function mapStateToProps(state) {
  return {
    templateName: getTemplateName(state),
    locale: getLocale(state),
  };
}
export default injectIntl(connect(mapStateToProps)(Header));
