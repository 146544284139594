// @flow
import React, { Component } from 'react';
import get from 'lodash/get';
import type { Node } from 'react';
import type { FormType } from '../../../types/constants';
import GLOBAL from '../../../utils/constants/global';

type Props = {
  children: Node,
  getChildrenRef?: Function | null,
  formName: FormType,
  initFormArgs?: Array<any>,
  formsStatus: { [formName: string]: any },
  setFormInputsState: (formName: string, inputs: any) => void,
  resetForm: (formId: string, formName: string) => void,
  formInitRequest: (formId: string, formName: string, initArgs: any) => void,
  formUniqueSuffix?: string | null,
};

/* eslint-disable react/no-unused-prop-types */
class FormHandler extends Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    initFormArgs: [],
    formUniqueSuffix: null,
    getChildrenRef: () => {},
  };

  constructor(props: Props) {
    super(props);
    const { formUniqueSuffix, formName } = props;
    this.state = {};
    this.formId = formUniqueSuffix
      ? `${formName}_${formUniqueSuffix}`
      : formName;
  }

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  componentDidMount() {
    const { formInitRequest, formName, initFormArgs } = this.props;
    formInitRequest(this.formId, formName, initFormArgs);
  }

  componentWillUnmount() {
    const { resetForm, formName } = this.props;
    resetForm(this.formId, formName);
  }

  onKeyPress = (event: SyntheticKeyboardEvent<>, action: Function): void => {
    if (event.key === GLOBAL.EVENT.KEY.ENTER) {
      action();
    }
  };

  setFormInputsState = (inputs: any) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.setFormInputsState(this.formId, inputs);
  };

  getChildrenWithProps() {
    const { children, getChildrenRef } = this.props;

    if (!children) {
      return <div />;
    }
    // $FlowFixMe
    const element = React.cloneElement(children, {
      setFormInputs: this.setFormInputsState,
      onKeyPress: this.onKeyPress,
      formId: this.formId,
    });
    if (getChildrenRef) {
      getChildrenRef(element);
    }

    return element;
  }

  formId: string;

  // Set global types used in constructor
  // eslint-disable-next-line react/static-property-placement
  props: Props;

  render() {
    return (
      // eslint-disable-next-line react/destructuring-assignment
      get(this.props.formsStatus, [this.formId, 'initDone'], false) &&
      this.getChildrenWithProps()
    );
  }
}

export default FormHandler;
