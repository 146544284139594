import { failureSelector, tokenSelector } from '../selectors';
import generateSelectorTests from '../../../utils/selectorTestHelper';

const TEST_SELECTORS = [
  {
    testName: 'tokenSelector',
    selector: tokenSelector,
    selectorField: 'tokenCaptcha.token',
    selectorType: 'object',
    defaultValue: null,
  },
  {
    testName: 'failureSelector',
    selector: failureSelector,
    selectorField: 'tokenCaptcha.failure',
    selectorType: 'boolean',
    defaultValue: false,
  },
];

describe('Test Selectors', generateSelectorTests(TEST_SELECTORS));
