import React from 'react';
import { intlShape, FormattedHTMLMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import { Pending } from '@nbc-design/icons/lib/web/Pending';

type Props = {
  intl: intlShape,
  countdownSeconds: number,
};

const OtcResendCounter = (props: Props) => {
  const { intl, countdownSeconds } = props;
  const isHidden = countdownSeconds <= 0;
  return (
    <CSSTransition
      in={!isHidden}
      classNames="resend-group--animated"
      timeout={500}
      unmountOnExit
    >
      <div id="resend-countdown">
        <div className="dsc-alert is-noChildren is-information">
          <Pending
            size="large"
            title={intl.formatMessage({ id: 'text.title.otc.pending' })}
          />
          <FormattedHTMLMessage
            id="text.message.otc.renew.countdown.bne"
            values={{ countdownSeconds }}
          />
        </div>
      </div>
    </CSSTransition>
  );
};

export default OtcResendCounter;
