// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';
import cx from 'classnames';
import get from 'lodash/get';

import { Button } from '@bnc-ui/button';
import { Text } from '@bnc-ui/text';

import openExternal from 'bnc-utilities-js/url/openExternal';

import { Configs } from '../../../configs';

import IconEdge from './icons/IconEdge';
import IconChrome from './icons/IconChrome';
import IconFirefox from './icons/IconFirefox';
import IconSafari from './icons/IconSafari';

import './style.scss';

type Props = {
  className?: string,
  intl: IntlShape,
  name: string,
};

const defaultProps = {
  className: '',
};

const BrowserCard = (props: Props) => {
  const { className, intl, name } = props;

  const downloadBrowser = () => {
    const linkToOpen = get(Configs.SUPPORTED_BROWSER_VERSIONS, [
      name,
      'updateLink',
      intl.locale,
    ]);
    openExternal(linkToOpen);
  };

  const ariaLabel = `${intl.formatMessage({
    id: 'text.button.download',
  })} ${intl.formatMessage({ id: `text.title.browser${name}` })}`;

  const recommendedBrowsers = {
    [get(Configs, 'SUPPORTED_BROWSER_VERSIONS.Edge.name', 'Edge')]: IconEdge,
    [get(
      Configs,
      'SUPPORTED_BROWSER_VERSIONS.Chrome.name',
      'Chrome',
    )]: IconChrome,
    [get(
      Configs,
      'SUPPORTED_BROWSER_VERSIONS.Firefox.name',
      'Firefox',
    )]: IconFirefox,
    [get(
      Configs,
      'SUPPORTED_BROWSER_VERSIONS.Safari.name',
      'Safari',
    )]: IconSafari,
  };

  const IconBrowser = get(recommendedBrowsers, name, null);

  return (
    IconBrowser && (
      <div className={cx(className, `browser-card-${name}`, 'browser-card')}>
        <IconBrowser />
        <Text size="small" tabIndex={0} className="browser-card__title">
          {intl.formatMessage({ id: `text.title.browser${name}` })}
        </Text>
        <Button
          className="browser-card__button-download"
          onClick={downloadBrowser}
          size="small"
          aria-label={ariaLabel}
          tabIndex={0}
          appearance="link"
        >
          {intl.formatMessage({ id: 'text.button.download' })}
        </Button>
      </div>
    )
  );
};

BrowserCard.defaultProps = defaultProps;

export default BrowserCard;
