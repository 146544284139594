import React from 'react';

import Dialog from '@bnc-ui/dialog';
import { Confirmation } from '@bnc-ui/confirmation';
import { Button } from '@bnc-ui/button';

import type { IntlShape } from 'react-intl';
import type { OS } from '../../../types/browser';
import type { BiometryError } from '../../../services/biometryService/types';
import { getIcon } from '../../../services/biometryService/utils';
import Biometry from '../../../services/biometryService/biometry';

type Props = {
  intl: IntlShape,
  biometry: Biometry,
  os: OS,
  error: BiometryError,
  isLoginFailure: boolean,
  isShow: boolean,
  forceLogin: () => any,
};

function getLoginFailureMessage(biometry: Biometry, os: OS): string {
  return `mobile.biometric.login.invalidBiometric.${getIcon(
    biometry,
    os,
  )}.message`;
}

function getMessage(code: string, biometry: Biometry, os: OS): string {
  switch (code) {
    case 'threeAttemptsFailed':
      return biometry.type === 'facialRecognition'
        ? 'mobile.biometric.three.tries.facialRecognition.message'
        : 'mobile.biometric.three.tries.message';
    case 'unknownError':
      if (os.name.toUpperCase() === 'IOS') {
        return 'mobile.biometric.three.tries.facialRecognition.message';
      }
      return biometry.type === 'facialRecognition'
        ? 'mobile.biometric.android.error.facialRecognition.message'
        : 'mobile.biometric.android.error.message';
    case 'biometricPermissionNotAllowed':
    default:
      return 'mobile.biometric.offline';
  }
}

function BiometryErrorModal(props: Props) {
  const {
    intl,
    error,
    isShow,
    forceLogin,
    isLoginFailure,
    biometry,
    os,
  } = props;

  const goToLogin = () => forceLogin();

  return (
    isShow && (
      <Dialog className="biometry-error-modal" titleId="biometryErrorModal">
        <Dialog.Body>
          <Confirmation
            size={1}
            type="h3"
            title={intl.formatMessage({
              id: isLoginFailure
                ? getLoginFailureMessage(biometry, os)
                : getMessage(error.code, biometry, os),
            })}
            appearance="error"
          />
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            appearance="primary"
            className="mfa-expired__button"
            onClick={goToLogin}
          >
            {intl.formatMessage({
              id: 'ok',
            })}
          </Button>
        </Dialog.Footer>
      </Dialog>
    )
  );
}

export default BiometryErrorModal;
