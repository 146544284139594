import React, { useEffect, useRef } from 'react';
import {
  createUserLockedCookie,
  getUserLockedCookie,
} from '../../../utils/lockUserCookie';

type Props = {
  channelName: string,
  updateLockedStatus: string => void,
  loginRouteRequestLocked: () => void,
  onmessage: (ev: MessageEvent) => void,
};
const BneBroadcastChannel = (props: Props) => {
  const {
    channelName,
    updateLockedStatus,
    loginRouteRequestLocked,
    onmessage,
  } = props;

  let broadCastChannel;
  let channelRef;
  let channel;

  try {
    broadCastChannel = new BroadcastChannel(channelName);
  } catch (e) {
    console.error(`Unable to create broadcastChannel ${e}`);
  }

  if (broadCastChannel) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    channelRef = useRef(broadCastChannel);
    channel = channelRef.current;
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (channel) {
      if (onmessage) {
        channel.onmessage = onmessage;
      } else {
        channel.onmessage = ev => {
          const username = sessionStorage.getItem('username');
          if (
            sessionStorage.getItem('username') === username &&
            ev.data.message === 'Account locked'
          ) {
            if (!getUserLockedCookie()) {
              createUserLockedCookie();
            }
            updateLockedStatus(ev.data.lockedStatus);
            loginRouteRequestLocked();
          }
        };
      }
      return () => {
        channel.close();
      };
    }
  }, [channel, onmessage, loginRouteRequestLocked, updateLockedStatus]);

  return <></>;
};
export default BneBroadcastChannel;
