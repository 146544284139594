import merge from 'lodash/merge';
import { Configs } from '../../configs';
import HTTP from '../../utils/constants/http';
import { bncFetch } from '../../utils/fetchUtils';

export default {
  getConfigs: () =>
    bncFetch(Configs.API.ENDPOINT.CONFIG_SERVER.ENDPOINT, {
      headers: merge({
        method: HTTP.METHODS.GET,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    }).then(res => res.json()),
};
