import * as actionTypes from './actionTypes';
import type { BiometryState } from './types';
import { BIOMETRY_CREDENTIALS_DELETION_FAILED } from './constants';

export const defaultState: BiometryState = {
  biometry: null,
  error: null,
  forceLogin: false,
  synchroError: false,
};

export const initialState = () => defaultState;

export default (
  state: BiometryState = initialState(),
  action: Action,
): BiometryState => {
  switch (action.type) {
    case actionTypes.BIOMETRY_INITIALIZATION_SUCCESS:
      return {
        ...state,
        biometry: action.biometry,
      };
    case actionTypes.BIOMETRY_VALIDATION_FAILED:
      return {
        ...state,
        error: { ...action.error, openInModal: true },
      };
    case actionTypes.BIOMETRY_FORCE_LOGIN: {
      return {
        ...state,
        error: null,
        forceLogin: true,
      };
    }
    case actionTypes.BIOMETRY_CREDENTIALS_DELETION_SUCCESS: {
      return {
        ...state,
        biometry: null,
        forceLogin: true,
      };
    }
    case actionTypes.BIOMETRY_CREDENTIALS_DELETION_FAILED: {
      return {
        ...state,
        error: {
          code: BIOMETRY_CREDENTIALS_DELETION_FAILED,
          openInModal: false,
        },
      };
    }
    case actionTypes.BIOMETRY_CREDENTIALS_SYNCHRONIZATION_ERROR: {
      return {
        ...state,
        synchroError: true,
      };
    }
    default:
      return state;
  }
};
