import React from 'react';
import PropTypes from 'prop-types';

import SbipForgotPasswordExceptionTemplate from '../../templates/sbip2/ForgotPasswordExceptionTemplate';
import WhiteLabelForgotPasswordExceptionTemplate from '../../templates/whiteLabel/ForgotPasswordExceptionTemplate';
import NatgoForgotPasswordExceptionTemplate from '../../templates/natgo/ForgotPasswordExceptionTemplate';
import RewardsForgotPasswordExceptionTemplate from '../../templates/rewards/ForgotPasswordExceptionTemplate';
import BaoForgotPasswordExceptionTemplate from '../../templates/bao/ForgotPasswordExceptionTemplate';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';

const propTypes = {
  templateName: PropTypes.string.isRequired,
};

const ForgotPasswordException = props => {
  const { templateName } = props;

  const loginTemplates = {
    [TEMPLATES.SBIP2]: <SbipForgotPasswordExceptionTemplate />,
    [TEMPLATES.OBE]: <SbipForgotPasswordExceptionTemplate />,
    [TEMPLATES.SBIP2_WHITE_LABEL]: (
      <WhiteLabelForgotPasswordExceptionTemplate />
    ),
    [TEMPLATES.NATGO]: <NatgoForgotPasswordExceptionTemplate />,
    [TEMPLATES.REWARDS]: <RewardsForgotPasswordExceptionTemplate />,
    [TEMPLATES.BAO]: <BaoForgotPasswordExceptionTemplate />,
    [TEMPLATES.BNE]: <SbipForgotPasswordExceptionTemplate />,
  };
  return getTemplateComponent(loginTemplates, templateName);
};

ForgotPasswordException.propTypes = propTypes;

export default ForgotPasswordException;
