// @flow
import * as actionTypes from './actionTypes';
import type {
  ForgotPasswordFormUpdateAction,
  ForgotPasswordFormTriggerFieldValidationAction,
  ForgotPasswordFormFieldValidationSuccessAction,
  ForgotPasswordFormFieldValidationFailureAction,
  ForgotPasswordFormInitFormAction,
  ForgotPasswordFormInitFormDoneAction,
} from './types';
import type {
  ForgotPasswordFormData,
  ForgotPasswordFormErrors,
} from '../../types/forms/forgotPasswordForm';
import type { FormType } from '../../types/constants';

export function formUpdate(
  fieldName: string,
  fieldValue: string,
): ForgotPasswordFormUpdateAction {
  return {
    type: actionTypes.FORGOT_PASSWORD_FORM_UPDATE,
    fieldName,
    fieldValue,
  };
}

export function triggerFieldValidation(
  fieldName: string,
): ForgotPasswordFormTriggerFieldValidationAction {
  return {
    type: actionTypes.FORGOT_PASSWORD_FORM_FIELD_VALIDATION_REQUEST,
    fieldName,
  };
}

export function fieldValidationSuccess(
  fieldName: string,
  isValid: boolean,
): ForgotPasswordFormFieldValidationSuccessAction {
  return {
    type: actionTypes.FORGOT_PASSWORD_FORM_FIELD_VALIDATION_SUCCESS,
    fieldName,
    isValid,
  };
}

export function fieldValidationFailure(
  fieldName: string,
  errors: ForgotPasswordFormErrors,
): ForgotPasswordFormFieldValidationFailureAction {
  return {
    type: actionTypes.FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE,
    fieldName,
    errors,
  };
}

export function triggerFormValidation() {
  return {
    type: actionTypes.FORGOT_PASSWORD_FORM_VALIDATION_REQUEST,
  };
}

export function formValidationSuccess() {
  return {
    type: actionTypes.FORGOT_PASSWORD_FORM_VALIDATION_SUCCESS,
  };
}

export function formValidationFailure(errors: ForgotPasswordFormErrors) {
  return {
    type: actionTypes.FORGOT_PASSWORD_FORM_VALIDATION_FAILURE,
    errors,
  };
}

export function initForm(
  formId: FormType,
  defaultValues: ForgotPasswordFormData,
): ForgotPasswordFormInitFormAction {
  return {
    type: actionTypes.FORGOT_PASSWORD_FORM_INIT_REQUEST,
    formId,
    defaultValues,
  };
}

export function initFormDone(
  payload: ForgotPasswordFormData,
): ForgotPasswordFormInitFormDoneAction {
  return {
    type: actionTypes.FORGOT_PASSWORD_FORM_INIT_DONE,
    payload,
  };
}

export function resetForm() {
  return {
    type: actionTypes.FORGOT_PASSWORD_FORM_RESET,
  };
}

export function resetRecaptcha() {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESET_RECAPTCHA,
  };
}

export function resetRecaptchaDone() {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESET_RECAPTCHA_DONE,
  };
}
