import { Configs } from '../../configs';

export const BASE_RESOURCES_URL = Configs.DAM_RESOURCES_BASE_URL;
export const BNC_PARTNER_ID = 'BNC_PARTNER_ID';
export const PARTNER_ID_BASE_URL = '/p';

export const IMAGES_PATH = {
  f55: {
    LOGO: {
      en: 'partners/f55/logo-f55.svg',
      fr: 'partners/f55/logo-l55.svg',
    },
    LOGO_SHORT: {
      en: 'partners/f55/logo-short-f55.svg',
      fr: 'partners/f55/logo-short-l55.svg',
    },
    LOGIN_IMAGE: {
      en: 'partners/f55/img-login.jpg',
      fr: 'partners/f55/img-login.jpg',
    },
    FAVICON: {
      en: 'partners/f55/favicon-f55.ico',
      fr: 'partners/f55/favicon-l55.ico',
    },
    PNG_ICON: {
      en: 'partners/f55/icon-f55.png',
      fr: 'partners/f55/icon-f55.png',
    },
    IOS_ICON: {
      en: 'partners/f55/icon-ios-f55.png',
      fr: 'partners/f55/icon-ios-f55.png',
    },
    LARGE_ICON: {
      en: 'partners/f55/icon-largetile-f55.png',
      fr: 'partners/f55/icon-largetile-f55.png',
    },
  },
  gwl: {
    LOGO: {
      en: 'partners/gwl/logo-gwl.svg',
      fr: 'partners/gwl/logo-lagwl.svg',
    },
    LOGO_SHORT: {
      en: 'partners/gwl/logo-short-gwl.svg',
      fr: 'partners/gwl/logo-short-lagwl.svg',
    },
    LOGIN_IMAGE: {
      en: 'partners/gwl/img-login.jpg',
      fr: 'partners/gwl/img-login.jpg',
    },
    FAVICON: {
      en: 'partners/gwl/favicon-gwl.ico',
      fr: 'partners/gwl/favicon-lagwl.ico',
    },
    PNG_ICON: {
      en: 'partners/gwl/icon-gwl.png',
      fr: 'partners/gwl/icon-gwl.png',
    },
    IOS_ICON: {
      en: 'partners/gwl/icon-ios-gwl.png',
      fr: 'partners/gwl/icon-ios-gwl.png',
    },
    LARGE_ICON: {
      en: 'partners/gwl/icon-largetile.png',
      fr: 'partners/gwl/icon-largetile.png',
    },
  },
  investorsgroup: {
    LOGO: {
      en: 'partners/ig/logo-igwealth.svg',
      fr: 'partners/ig/logo-iggestion.svg',
    },
    LOGO_SHORT: {
      en: 'partners/ig/logo-short-igwealth.svg',
      fr: 'partners/ig/logo-short-iggestion.svg',
    },
    LOGIN_IMAGE: {
      en: 'partners/ig/img-login.jpg',
      fr: 'partners/ig/img-login.jpg',
    },
    FAVICON: {
      en: 'partners/ig/favicon-ig.ico',
      fr: 'partners/ig/favicon-ig.ico',
    },
    PNG_ICON: {
      en: 'partners/ig/icon-ig.png',
      fr: 'partners/ig/icon-ig.png',
    },
    IOS_ICON: {
      en: 'partners/ig/icon-ios-ig.png',
      fr: 'partners/ig/icon-ios-ig.png',
    },
    LARGE_ICON: {
      en: 'partners/ig/icon-largetile-ig.png',
      fr: 'partners/ig/icon-largetile-ig.png',
    },
  },
  partnership: {
    LOGO: {
      en: 'partners/partnership/logo-nbc.svg',
      fr: 'partners/partnership/logo-bnc.svg',
    },
    LOGO_SHORT: {
      en: 'partners/partnership/logo-nbc.svg',
      fr: 'partners/partnership/logo-bnc.svg',
    },
    LOGIN_IMAGE: {
      en: 'partners/partnership/img-login.jpg',
      fr: 'partners/partnership/img-login.jpg',
    },
    FAVICON: {
      en: 'partners/partnership/favicon.ico',
      fr: 'partners/partnership/favicon.ico',
    },
    PNG_ICON: {
      en: 'partners/partnership/icon.png',
      fr: 'partners/partnership/icon.png',
    },
    IOS_ICON: {
      en: 'partners/partnership/icon-ios.png',
      fr: 'partners/partnership/icon-ios.png',
    },
    LARGE_ICON: {
      en: 'partners/partnership/icon-largetile.png',
      fr: 'partners/partnership/icon-largetile.png',
    },
  },
};

export const DEFAULT_IMAGE_PATH = {
  LOGO: 'partners/partnership/logo-bnc.svg',
  LOGO_SHORT: 'partners/partnership/logo-bnc.svg',
  LOGIN_IMAGE: 'partners/partnership/img-login.jpg',
  FAVICON: 'partners/partnership/favicon.ico',
  PNG_ICON: 'partners/partnership/icon.png',
  IOS_ICON: 'partners/partnership/icon-ios.png',
  LARGE_ICON: 'partners/partnership/icon-largetile.png',
};
