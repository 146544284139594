// @flow
import * as actionTypes from './actionTypes';

import type {
  FormInitRequestAction,
  SetFormInputsStateAction,
  FocusOnFirstErrorFieldAction,
  FocusOnFirstErrorFieldSuccessAction,
  NoFieldToFocusOnAction,
  FormInitDoneAction,
  ResetFormAction,
} from './types';

export function formInitRequest(
  formId: string,
  formName: string,
  initArgs: any,
): FormInitRequestAction {
  return {
    type: actionTypes.FORM_HANDLER_FORM_INIT_REQUEST,
    formId,
    formName,
    initArgs,
  };
}

export function setFormInputsState(
  formName: string,
  inputs: any,
): SetFormInputsStateAction {
  return {
    type: actionTypes.FORM_HANDLER_POPULATE_FORM_INPUTS,
    formName,
    inputs,
  };
}

export function focusOnFirstErrorField(
  formName: string,
  errors: any,
  scrollTop: boolean,
): FocusOnFirstErrorFieldAction {
  return {
    type: actionTypes.FORM_HANDLER_FOCUS_ERROR_INPUT_REQUEST,
    formName,
    errors,
    scrollTop,
  };
}

export function focusOnFirstErrorFieldSuccess(): FocusOnFirstErrorFieldSuccessAction {
  return {
    type: actionTypes.FORM_HANDLER_FOCUS_ERROR_INPUT_SUCCESS,
  };
}

export function noFieldToFocusOn(): NoFieldToFocusOnAction {
  return {
    type: actionTypes.FORM_HANDLER_NO_FIELD_TO_FOCUS_ON,
  };
}

export function formInitDone(formId: string): FormInitDoneAction {
  return {
    type: actionTypes.FORM_HANDLER_FORM_INIT_DONE,
    formId,
  };
}

export function resetForm(formId: string, formName: string): ResetFormAction {
  return {
    type: actionTypes.FORM_HANDLER_RESET_FORM_REQUEST,
    formId,
    formName,
  };
}
