import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { InputGroup } from '@bnc-ui/inputGroup';
import { FormGroup } from '@bnc-ui/formGroup';
import { CurrencyInput } from '@bnc-ui/currencyInput';

import { forgotPasswordFormDataShape } from '../../../../utils/shapes';
import onAmountBlur from './common';
import {
  FORGOT_PASSWORD_FIELDS,
  FORGOT_PASSWORD_VALIDATION_RULES,
} from '../../../../utils/forgotPasswordUtils/constants';

const propTypes = {
  intl: intlShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  formData: forgotPasswordFormDataShape.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

const LoanInfo = ({ intl, errors, formData, onChange, onBlur }) => {
  return (
    <div className="forgot-password-form--group--loan">
      <FormGroup
        label={{
          text: intl.formatMessage({
            id: 'text.label.forgotPassword.loanAccountNumber',
          }),
          htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER,
        }}
        validate={{
          hasError:
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER in errors,
          errorMsg: intl.formatMessage({
            id: 'text.error.field.loanAccountNumberIncorrectFormat',
          }),
        }}
        tooltip={{
          content: intl.formatMessage({
            id: 'text.tooltip.forgotPassword.loanAccountNumber',
          }),
          position: 'top',
        }}
      >
        <InputGroup
          inputId={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER}
          inputName={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER}
          className="forgot-password-form--group--loan__input"
          onChange={onChange}
          onBlur={onBlur}
          placeholder={intl.formatMessage({
            id: 'text.field.placeholder.forgotPassword.loanAccountNumber',
          })}
          value={get(
            formData,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER,
          )}
          minLength={FORGOT_PASSWORD_VALIDATION_RULES.LOAN.ACCOUNT_MIN_LENGTH}
          maxLength={FORGOT_PASSWORD_VALIDATION_RULES.LOAN.ACCOUNT_MAX_LENGTH}
        />
      </FormGroup>
      <FormGroup
        label={{
          text: intl.formatMessage({
            id: 'text.label.forgotPassword.originalAmount',
          }),
          htmlFor: FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT,
        }}
        validate={{
          hasError:
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT in
            errors,
          errorMsg: intl.formatMessage({
            id: 'text.error.forgotPassword.originalAmount',
          }),
        }}
      >
        <CurrencyInput
          id={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT}
          name={FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT}
          locale={intl.locale}
          onBlur={onAmountBlur(
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT,
            intl.locale,
            onChange,
            onBlur,
          )}
          placeholder={intl.formatMessage({
            id: 'text.field.placeholder.forgotPassword.originalAmount',
          })}
          value={get(
            formData,
            FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT,
          )}
        />
      </FormGroup>
    </div>
  );
};

LoanInfo.propTypes = propTypes;

export default LoanInfo;
