import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import BankProductInfo from './bankProductInfo';
import { getPartnerIdName } from '../../../../services/templateService/selectors';
import { getLocale } from '../../../../services/i18nService/selectors';

const mapStateToProps = state => ({
  partnerId: getPartnerIdName(state),
  locale: getLocale(state),
});

export default injectIntl(connect(mapStateToProps)(BankProductInfo));
