// @flow
import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import { Icon, Constants } from 'bnc-react-components';

import Avatar from '../../../atoms/Avatar';
import getInitials from '../../../utils/userUtils';

import type { User } from '../../../types/user';

import './style.scss';

type Props = {
  className?: string,
  user: User,
  isExpandable?: boolean,
  isDeletable?: boolean,
};

const defaultProps = {
  className: '',
  isExpandable: false,
  isDeletable: false,
};

function UserIdentity(props: Props) {
  const { className, user, isExpandable, isDeletable } = props;
  const { firstName, lastName, login } = user;
  return (
    <div className={cx('userIdentity', className)}>
      <Avatar
        initials={getInitials(user)}
        color={get(user, 'color', '')}
        className="userIdentity__avatar"
      />
      <div className="userIdentity__credentials">
        {(firstName || lastName) && (
          <div className="userIdentity__name" aria-hidden>
            {`${firstName} ${lastName}`}
          </div>
        )}
        {login && <div className="userIdentity__email">{login}</div>}
      </div>
      {isExpandable && (
        <div className="userIdentity__expandable">
          <Icon name={Constants.ICONS.DOUBLE_ARROW} />
        </div>
      )}
      {isDeletable && (
        <div className="userIdentity__deletable">
          <Icon name={Constants.ICONS.DELETE} />
        </div>
      )}
    </div>
  );
}

UserIdentity.defaultProps = defaultProps;

export default UserIdentity;
