import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import RecaptchaSdk from './RecaptchaSdk';
import { BNC_RECAPTCHA_ID } from './utils';

const propTypes = {
  siteKey: PropTypes.string.isRequired,
  exhangeTokenEndpoint: PropTypes.string.isRequired,
  googleRecaptchaEndpoint: PropTypes.string.isRequired,
  success: PropTypes.func.isRequired,
  failure: PropTypes.func.isRequired,
  timeoutRecaptcha: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  hasToReset: PropTypes.bool,
  resetDone: PropTypes.func,
  nbfgId: PropTypes.string,
};

const defaultProps = {
  hasToReset: false,
  resetDone: () => {},
  nbfgId: null,
};

let bncCaptchaSdk;

class BncRecaptcha extends Component {
  componentDidMount() {
    const {
      siteKey,
      exhangeTokenEndpoint,
      googleRecaptchaEndpoint,
      success,
      failure,
      timeoutRecaptcha,
      intl,
      nbfgId,
    } = this.props;

    bncCaptchaSdk = new RecaptchaSdk(
      siteKey,
      exhangeTokenEndpoint,
      googleRecaptchaEndpoint,
      BNC_RECAPTCHA_ID,
      intl.locale,
      nbfgId,
    );
    bncCaptchaSdk.initCallBacks(success, failure, timeoutRecaptcha);
    bncCaptchaSdk.show();
  }

  componentDidUpdate() {
    const { hasToReset, resetDone } = this.props;

    if (hasToReset) {
      this.reset();
      resetDone();
    }
  }

  reset = () => {
    bncCaptchaSdk.reset();
  };

  render() {
    return <div id={BNC_RECAPTCHA_ID} />;
  }
}

BncRecaptcha.propTypes = propTypes;
BncRecaptcha.defaultProps = defaultProps;

export default BncRecaptcha;
