import {
  getErrorMessage,
  clearLoginErrorMessageIfNecessary,
} from '../loginFormMessageUtils';

jest.mock('../../formUtils', () => ({
  getFirstErrorKey: obj => Object.keys(obj)[0],
}));
jest.mock('../../authErrorMessages', () => ({
  getAuthErrorMessageId: error => error.code,
}));
jest.mock('../../templateUtils', () => ({
  getMessageKey: (tplName, key) => `${key}.${tplName.toLowerCase()}`,
}));

describe('clearLoginErrorMessageIfNecessary', () => {
  it(`should do nothing when has validation error`, () => {
    const hasValidationError: boolean = true;
    const hasLoginFailure: boolean = false;
    const clearLoginErrorMessages: unknown = jest.fn();
    clearLoginErrorMessageIfNecessary(
      hasValidationError,
      hasLoginFailure,
      clearLoginErrorMessages,
    );
    expect(clearLoginErrorMessages).not.toHaveBeenCalled();
  });
  it(`should do nothing when has login error`, () => {
    const hasValidationError: boolean = false;
    const hasLoginFailure: boolean = true;
    const clearLoginErrorMessages: unknown = jest.fn();
    clearLoginErrorMessageIfNecessary(
      hasValidationError,
      hasLoginFailure,
      clearLoginErrorMessages,
    );
    expect(clearLoginErrorMessages).not.toHaveBeenCalled();
  });
  it(`should do nothing when has no error`, () => {
    const hasValidationError: boolean = false;
    const hasLoginFailure: boolean = false;
    const clearLoginErrorMessages: unknown = jest.fn();
    clearLoginErrorMessageIfNecessary(
      hasValidationError,
      hasLoginFailure,
      clearLoginErrorMessages,
    );
    expect(clearLoginErrorMessages).not.toHaveBeenCalled();
  });
  it(`should do nothing when has validation & login errors`, () => {
    const hasValidationError: boolean = true;
    const hasLoginFailure: boolean = true;
    const clearLoginErrorMessages: unknown = jest.fn();
    clearLoginErrorMessageIfNecessary(
      hasValidationError,
      hasLoginFailure,
      clearLoginErrorMessages,
    );
    expect(clearLoginErrorMessages).toHaveBeenCalled();
  });
});

describe('getErrorMessage', () => {
  const testCases = [
    {
      hasValidationError: true,
      hasLoginFailure: false,
      errors: {
        identityBne: ['test'],
        passwordBne: ['test'],
      },
      result: {
        message: 'text.error.invalidcreditentialsBne',
        type: 'error',
      },
    },
    {
      hasValidationError: true,
      hasLoginFailure: false,
      errors: {
        passwordBne: ['test'],
      },
      result: {
        message: 'text.error.invalidpasswordBne',
        type: 'error',
      },
    },
    {
      hasValidationError: true,
      hasLoginFailure: false,
      errors: {
        identityBne: ['test'],
      },
      result: {
        message: 'text.error.invalididentityBne',
        type: 'error',
      },
    },
    {
      hasValidationError: true,
      hasLoginFailure: false,
      errors: {
        unknowError: ['test'],
      },
      result: {
        message: 'text.error.invalidunknowError',
        type: 'error',
      },
    },
    {
      hasValidationError: true,
      hasLoginFailure: true,
      errors: {
        unknowError: ['test'],
      },
      result: {
        message: 'text.error.invalidunknowError',
        type: 'error',
      },
    },
    {
      hasValidationError: true,
      hasLoginFailure: true,
      errors: {
        identityBne: ['test'],
        passwordBne: ['test'],
      },
      result: {
        message: 'text.error.invalidcreditentialsBne',
        type: 'error',
      },
    },
    {
      hasValidationError: false,
      hasLoginFailure: true,
      errors: {},
      sdkError: {
        code: 'code',
      },
      result: {
        message: 'auth.error.code.bne',
        type: 'error',
      },
    },
    {
      hasValidationError: false,
      hasLoginFailure: false,
      errors: {},
      result: {
        message: null,
        type: null,
      },
    },
  ];

  testGetErrorMessageFor(true, true);
  testGetErrorMessageFor(true, false);
  testGetErrorMessageFor(false, true);
  testGetErrorMessageFor(false, false);

  function testGetErrorMessageFor(
    hasValidationError: boolean,
    hasLoginFailure: boolean,
  ) {
    const filteredTestCases = testCases.filter(
      obj =>
        obj.hasValidationError === hasValidationError &&
        obj.hasLoginFailure === hasLoginFailure,
    );

    describe(`When hasValidationError is ${hasValidationError} and hasLoginFailure is ${hasLoginFailure},`, () => {
      filteredTestCases.forEach(({ errors, sdkError, result }) => {
        it(`should return ${
          result.message
        } message when errors is ${JSON.stringify(
          errors,
        )}, and sdkError is ${JSON.stringify(sdkError)}`, () => {
          const templateName: string = 'BNE';

          const ownPropsIntl = {
            formatMessage: message => message.id,
          };

          const actualResult = getErrorMessage(
            hasValidationError,
            hasLoginFailure,
            errors,
            sdkError,
            templateName,
            ownPropsIntl,
          );
          expect(actualResult).toEqual(result);
        });
      });
    });
  }

  describe('when has login error', () => {});
});
