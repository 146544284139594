// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import MultiFactorAuthForm from './multiFactorAuthForm';
import { getPartnerIdName } from '../../../services/templateService/selectors';

const mapStateToProps = state => ({
  partnerIdName: getPartnerIdName(state),
});

export default injectIntl(connect(mapStateToProps)(MultiFactorAuthForm));
