// @flow
import shuffle from 'lodash/shuffle';
import get from 'lodash/get';
import pull from 'lodash/pull';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import replace from 'lodash/replace';

import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';

import { Buffer } from 'buffer';
import * as cookie from '../../utils/cookie';
import TEMPLATES from '../../utils/constants/template';
import {
  MAX_IDENTITIES_SAVED,
  IDENTITY_COOKIE_NAME,
} from '../../utils/constants/login';
import COLOR_PALETTE from '../../utils/constants/avatar';

export const createIdentitiesCookie = (
  currentIdentities: Object,
  profile: Object,
  partnerCookieIdentityName: string,
) => {
  if (
    currentIdentities[partnerCookieIdentityName] &&
    !find(
      currentIdentities[partnerCookieIdentityName],
      p => p.login === profile.login,
    ) &&
    currentIdentities[partnerCookieIdentityName].length >= MAX_IDENTITIES_SAVED
  ) {
    return currentIdentities;
  }
  return {
    ...currentIdentities,
    [partnerCookieIdentityName]: uniqBy(
      [profile, ...get(currentIdentities, partnerCookieIdentityName, [])],
      'login',
    ),
  };
};

export const getPartnerCookieIdentityName = (
  templateName: string,
  partnerId: string,
): string => {
  // If we have a partner ID we return it to use it as key for its identities
  if (partnerId) {
    return partnerId;
  }
  if (templateName === TEMPLATES.NATGO) {
    // Natgo is using the same identities as SBIP2
    return TEMPLATES.SBIP2;
  }
  return templateName;
};

export const deleteIdentityCookie = (
  currentIdentities: Object,
  identity: any,
  partnerCookieIdentityName: string,
): Object => ({
  ...currentIdentities,
  [partnerCookieIdentityName]: filter(
    get(currentIdentities, partnerCookieIdentityName, []),
    user => user.login !== identity.login,
  ),
});

const getUUID = () => {
  let uuid;
  try {
    uuid = uuidv4() || uuidv1() || replace(Math.random(), '.', '');
  } catch (e) {
    // FIXME: log error to datadog
    uuid = replace(Math.random(), '.', '');
  }
  return replace(uuid, new RegExp('-', 'g'), '');
};

export const addColorsToIdentities = (
  identities: Array<any>,
  partnerCookieIdentityName: string,
): Array<any> => {
  if (identities) {
    const shuffledColors = shuffle(COLOR_PALETTE);
    identities.map((identity, index) => {
      const currentIdentity = identity;
      let shouldUpdateIdentity = false;
      // Color treatment
      if (
        !currentIdentity.color ||
        (currentIdentity.color &&
          shuffledColors.indexOf(currentIdentity.color) === -1)
      ) {
        // The user doesn't have a color or it's not in the color palette
        currentIdentity.color = shuffledColors[0];
        shouldUpdateIdentity = true;
      }
      // deviceToken treatment
      if (!currentIdentity.deviceToken) {
        currentIdentity.deviceToken = getUUID();
        shouldUpdateIdentity = true;
      }
      if (shouldUpdateIdentity) {
        updateIdentityCookie(currentIdentity, partnerCookieIdentityName);
      }
      // Remove color from array
      pull(shuffledColors, currentIdentity.color);
      return currentIdentity;
    });
  }
  return identities;
};

const updateIdentityCookie = (currentIdentity, partnerCookieIdentityName) => {
  const identitiesFromCookie = cookie.get(IDENTITY_COOKIE_NAME) || {};
  // Set cookie to add color so we won't need to do it again next time
  const newIdentities = createIdentitiesCookie(
    identitiesFromCookie,
    currentIdentity,
    partnerCookieIdentityName,
  );
  cookie.set(IDENTITY_COOKIE_NAME, newIdentities);
};

export const getRememberMe = (remember, identities, username) => {
  if (identities && identities.length >= MAX_IDENTITIES_SAVED) {
    return !!find(
      identities,
      savedIdentity => savedIdentity.login === username,
    );
  }
  return remember;
};

export const getDeviceToken = (rememberMe, identities, username) => {
  // Determine if we need to generate a new uuid and set to the state OR using uuid existing one in the identities saved
  if (rememberMe && identities) {
    const identity = find(
      identities,
      savedIdentity => savedIdentity.login === username,
    );
    if (identity && identity.deviceToken) {
      return identity.deviceToken;
    }
  }
  if (rememberMe) {
    return getUUID();
  }
  return null;
};

export const getBneDeviceToken = (identities, username) => {
  // Determine if we need to generate a new uuid and set to the state OR using uuid existing one in the identities saved
  if (identities) {
    const identity = find(
      identities,
      savedIdentity =>
        savedIdentity.login.toLowerCase() === username.toLowerCase(),
    );
    if (identity && identity.deviceToken) {
      return identity.deviceToken;
    }
  }
  return getUUID();
};

export const removeIdentitiesCookie = () => {
  cookie.remove(IDENTITY_COOKIE_NAME);
};

export const hashLogin = (username, password) => {
  if (!username || typeof password !== 'string') {
    return null;
  }
  const emailID = Buffer.from(username, 'utf8').toString('base64');
  const pwd = Buffer.from(password, 'utf8').toString('base64');
  return `${emailID}:${pwd}`;
};
