// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import LoginFormMessage from './loginFormMessage';

import type { State } from '../../../types/state';
import { getTemplateName } from '../../../services/templateService/selectors';

const mapStateToProps = (state: State) => ({
  templateName: getTemplateName(state),
});

export default injectIntl(connect(mapStateToProps)(LoginFormMessage));
