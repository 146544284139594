import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, FormattedMessage } from 'react-intl';
import { MaintenanceColor } from '@nbc-design/icons/lib/web/MaintenanceColor';
import { Heading } from '@bnc-ui/heading';
import dateFormat from 'bnc-utilities-js/date/dateFormat';
import customTimeFormat from './helper';
import './style.scss';

const propTypes = {
  intl: intlShape.isRequired,
  expectedAppReturnDate: PropTypes.string,
  isMaintenanceModeEnable: PropTypes.bool.isRequired,
  isUnavailableModeEnable: PropTypes.bool.isRequired,
};

const defaultProps = {
  expectedAppReturnDate: null,
};

const CompleteUnavailability = props => {
  const {
    intl,
    expectedAppReturnDate,
    isMaintenanceModeEnable,
    isUnavailableModeEnable,
  } = props;

  const backDate = dateFormat(intl.locale, expectedAppReturnDate, false);
  const backTime = customTimeFormat(intl, expectedAppReturnDate);
  const backTimeZone = intl.formatMessage({ id: 'text.mode.timezone' });
  const backSeparator = intl.formatMessage({
    id: 'text.mode.datetime.separator',
  });
  const estimatedBackMoment = `${backDate}${backSeparator} ${backTime} ${backTimeZone}`;

  return (
    <div className="complete-unavailability">
      <MaintenanceColor
        size={{ width: 96, height: 96 }}
        title="maintenance-color"
      />

      <Heading className="complete-unavailability__title" type="h1">
        {isUnavailableModeEnable &&
          intl.formatMessage({ id: 'text.mode.unavailable.title' })}
        {isMaintenanceModeEnable &&
          intl.formatMessage({ id: 'text.mode.maintenance.title' })}
      </Heading>

      {expectedAppReturnDate && (
        <div className="complete-unavailability__message">
          <FormattedMessage
            id="text.mode.message"
            values={{
              date: (
                <span className="complete-unavailability__message--date">
                  {estimatedBackMoment}
                </span>
              ),
            }}
          />
        </div>
      )}

      <div className="complete-unavailability__thank">
        {intl.formatMessage({ id: 'text.mode.thank' })}
      </div>
    </div>
  );
};

CompleteUnavailability.propTypes = propTypes;
CompleteUnavailability.defaultProps = defaultProps;

export default CompleteUnavailability;
