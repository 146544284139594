import split from 'lodash/split';
import includes from 'lodash/includes';

/*  Helper for translated messages management.
    It can operate with encapsulated messages who can be combined together in one main message.
    This logic can be used for other purposes but it has been firstly used for the Aria Live announcer who receives a complex string.
    This string is pipe delimited.
    At the first pipe-delimited position resides the main message and the other pipe-delimited segments are the secondary messages.
    The secondary messages can be conformed by values which can be translated iteratively OR
    by combination of key and value that will be taken literally without traduction because direct assignation is expected.
*/
export function getTranslatedMessage(intl, message) {
  let translatedMessage = '';
  if (includes(message, '|')) {
    const splittedMsg = split(message, '|');
    // Main messsage on first place, keys after
    translatedMessage = intl.formatMessage(
      { id: splittedMsg[0] },
      getMessageKeys(intl, splittedMsg),
    );
  } else if (isTranslatedMessage(message)) {
    /**
     * When the incoming message is surrounded by { }, we do not consider the incoming message as i18-message-id,
     * but as a stringify of object { text, aria } where text is message that is already translated externally,
     * and aria is the property of
     * We need this in the situation where the i18n message has some place holders.
     */
    return JSON.parse(message);
  } else if (message) {
    translatedMessage = intl.formatMessage({ id: message });
  }

  return {
    text: message ? translatedMessage : '',
    aria: { ariaRelevant: 'all' },
  };
}

export function getMessageKeys(intl, arrMessage) {
  const prefixMsg = 'm';
  const keys = {};
  for (let i = 1; i < arrMessage.length; i++) {
    if (includes(arrMessage[i], '=')) {
      const splitKey = split(arrMessage[i], '=');
      // Property key and value gave it on key
      keys[splitKey[0]] = splitKey[1];
    } else {
      // Only value gave it on key, sequences m1,m2,m3... as necessary will be assigned iteratively
      // and keys based on the combination of key and value are considered
      keys[prefixMsg.concat(i)] = intl.formatMessage({ id: arrMessage[i] });
    }
  }
  return keys;
}

export function getWelcomeMsgKey(partnerName) {
  let keyWelcomeMsg = 'text.arialive.bienvenue';
  if (partnerName) {
    keyWelcomeMsg = `${keyWelcomeMsg}.wl|partner=${partnerName}`;
  }
  return keyWelcomeMsg;
}

const isTranslatedMessage = message =>
  message && message.startsWith('{') && message.endsWith('}');

export const toTranslatedMessage = (text, aria) =>
  JSON.stringify({ text, aria });

let announceMethod;
let queueMessages = [];
let lastAnnounceMoment = 0;

// delay time between each announcement
const DELAY_TIME = 1000;

const announceEvent = () => {
  if (queueMessages.length > 0) {
    if (Date.now() - lastAnnounceMoment > DELAY_TIME) {
      const theMessage = queueMessages
        .map(v => v.replace(/\.$/, ''))
        .join('. ');
      announceMethod &&
        announceMethod(
          toTranslatedMessage(theMessage, { ariaRelevant: 'additions text' }),
        );
      queueMessages = [];
      lastAnnounceMoment = Date.now();
    } else {
      setTimeout(announceEvent, 500); // see me in 500 milliseconds
    }
  }
};

export const queueAnnouncer = (messages, announcer) => {
  if (announcer) {
    announceMethod = announcer;
  }

  for (const message of messages) {
    if (queueMessages.indexOf(message) < 0) {
      queueMessages.push(message);
    }
  }

  setTimeout(announceEvent, 500);
};
