// @flow
import * as actionTypes from './actionTypes';

import type {
  TokenCaptcha,
  TokenCaptchaRequestAction,
  TokenCaptchaRequestSuccessAction,
  TokenCaptchaRequestFailureAction,
  TokenCaptchaResetAction,
} from './types';

export function tokenCaptchaRequest(
  recaptcha: any,
  nbfgId: string,
): TokenCaptchaRequestAction {
  return {
    type: actionTypes.TOKEN_CAPTCHA_REQUEST,
    recaptcha,
    nbfgId,
  };
}

export function tokenCaptchaRequestSuccess(
  token: TokenCaptcha,
): TokenCaptchaRequestSuccessAction {
  return {
    type: actionTypes.TOKEN_CAPTCHA_REQUEST_SUCCESS,
    token,
  };
}

export function tokenCaptchaRequestFailure(): TokenCaptchaRequestFailureAction {
  return {
    type: actionTypes.TOKEN_CAPTCHA_REQUEST_FAILURE,
  };
}

export function tokenCaptchaReset(): TokenCaptchaResetAction {
  return {
    type: actionTypes.TOKEN_CAPTCHA_RESET,
  };
}
