import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { Text } from '@bnc-ui/text';
import { Button } from '@bnc-ui/button';

import './style.scss';

const propTypes = {
  intl: intlShape.isRequired,
  returnToLogin: PropTypes.func.isRequired,
};

function SessionExpired(props) {
  const { intl, returnToLogin } = props;

  useEffect(() => {
    document.querySelector('.session-expired__paragraph').focus();
  }, []);

  return (
    <div className="session-expired">
      <Text
        type="p"
        size="paragraph"
        className="session-expired__paragraph"
        tabIndex={0}
      >
        {intl.formatMessage({ id: 'text.message.expiredSession' })}
      </Text>
      <Button
        appearance="primary"
        className="session-expired__button"
        onClick={returnToLogin}
      >
        {intl.formatMessage({ id: 'text.button.reConnect' })}
      </Button>
    </div>
  );
}

SessionExpired.propTypes = propTypes;

export default SessionExpired;
