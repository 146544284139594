import React from 'react';
import AccountLocked from '../../../organisms/common/AccountLocked';

import FullScreenCenterTemplate from '../../models/FullScreenCenterTemplate';

const AccountLockedTemplate = props => {
  return <FullScreenCenterTemplate main={<AccountLocked />} />;
};

export default AccountLockedTemplate;
