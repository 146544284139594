// @flow
export const I18N_SERVICE_REDUCER = 'intl';
export const LOGIN_SERVICE_REDUCER = 'loginServiceReducer';
export const FORM_HANDLER_SERVICE_REDUCER = 'formHandlerServiceReducer';
export const TEMPLATE_SERVICE_REDUCER = 'templateServiceReducer';
export const LOGIN_FORM_SERVICE_REDUCER = 'loginFormServiceReducer';
export const MFA_FORM_SERVICE_REDUCER = 'multiFactorAuthFormServiceReducer';
export const USER_AGENT_SERVICE_REDUCER = 'userAgentServiceReducer';
export const UNSUPPORTED_BROWSER_SERVICE_REDUCER =
  'unsupportedBrowserServiceReducer';
export const CONFIGS_SERVICE_REDUCER = 'configsServiceReducer';
export const MODAL_SERVICE_REDUCER = 'modalServiceReducer';
export const RESET_PASSWORD_REDUCER = 'resetPasswordReducer';
export const BIOMETRY_SERVICE_REDUCER = 'biometryServiceReducer';
