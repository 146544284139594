import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from '@bnc-ui/button';

import Paragraph from '../../../atoms/Paragraph';

import './style.scss';

const propTypes = {
  intl: intlShape.isRequired,
  returnToLogin: PropTypes.func.isRequired,
};

function ResetPasswordException(props) {
  const { intl, returnToLogin } = props;
  const expiredPasswordMessage = intl.formatMessage({
    id: 'text.message.expiredPassword',
  });

  return (
    <div>
      <Paragraph
        className="returnToSbipLogin_reset-exception"
        message={expiredPasswordMessage}
      />

      <Button
        appearance="primary"
        className="returnToSbipLogin__button"
        onClick={returnToLogin}
      >
        {intl.formatMessage({ id: 'text.link.sbip2Login' })}
      </Button>
    </div>
  );
}

ResetPasswordException.propTypes = propTypes;

export default ResetPasswordException;
