import {
  biometrySelector,
  errorSelector,
  forceLoginSelector,
  getBiometryState,
} from '../selectors';
import Biometry from '../biometry';

describe('getBiometryState', () => {
  it('should return biometry if it available', () => {
    const biometry = new Biometry(
      'deviceID',
      'fingerprint',
      'dummy.user',
      '2.0.0',
    );
    const state = {
      biometryServiceReducer: {
        biometry,
      },
    };

    expect(getBiometryState(state)).toEqual({ biometry });
  });

  it('should return null if biometry is not available', () => {
    const state = {};
    expect(getBiometryState(state)).toEqual({});
  });
});

describe('biometrySelector', () => {
  it('should return biometry config if it available', () => {
    const biometry = new Biometry(
      'deviceID',
      'fingerprint',
      'dummy.user',
      '2.0.0',
    );
    const state = {
      biometryServiceReducer: {
        biometry,
      },
    };

    expect(biometrySelector(state)).toEqual(biometry);
  });

  it('should return null if biometry config is unavailable', () => {
    expect(biometrySelector({})).toEqual(null);
  });
});

describe('errorSelector', () => {
  it('should return error if it available', () => {
    const error = {
      code: 'UnknownError',
      message: 'An unknown error occurred',
    };

    const state = {
      biometryServiceReducer: {
        error,
      },
    };

    expect(errorSelector(state)).toEqual(error);
  });

  it('should return null if error is unavailable', () => {
    expect(errorSelector({})).toEqual(null);
  });
});

describe('forceLoginSelector', () => {
  it('should return forceLogin if it set', () => {
    const state = {
      biometryServiceReducer: {
        forceLogin: true,
      },
    };

    expect(forceLoginSelector(state)).toEqual(true);
  });

  it('should return false if forceLogin is not set', () => {
    expect(forceLoginSelector({})).toEqual(false);
  });
});
