// @flow
import React from 'react';
import { Head } from 'bnc-react-components';
import type { IntlShape } from 'react-intl';
import { Helmet } from 'react-helmet';
import helper from './helpers';

import TEMPLATES from '../../utils/constants/template';

type Props = {
  intl: IntlShape,
  partnerId?: string,
  locale: string,
  templateName: String,
};
const defaultProps = {
  partnerId: '',
};

const AppHead = (props: Props) => {
  const { partnerId, locale, intl, templateName } = props;
  const titleKey =
    partnerId && partnerId !== 'partnership'
      ? `text.title.${partnerId}.loginPage`
      : 'text.title.loginPage';
  const isBncTemplate = templateName === TEMPLATES.SBIP2;

  return (
    <>
      <Head
        title={intl.formatMessage({ id: titleKey })}
        favicons={helper.getFavicons(partnerId, locale)}
        lang={locale}
      />

      {isBncTemplate && (
        <Helmet>
          <meta name="apple-itunes-app" content="app-id=476651878" />
        </Helmet>
      )}
    </>
  );
};

AppHead.defaultProps = defaultProps;

export default AppHead;
