import { TRANSACTION_STATUS } from 'bnc-react-services/managers/AuthManager/constants';
import * as BncAuthService from 'bnc-react-services/services/AuthService/actions';
import {
  BNC_AUTH_RESEND_FAILURE,
  BNC_AUTH_SAVE_IDENTITY,
  BNC_AUTH_SIGN_IN_COMPLETED,
  BNC_AUTH_SEND_SUCCESS,
} from 'bnc-react-services/services/AuthService/actionTypes';
import has from 'lodash/has';
import { expectSaga } from 'redux-saga-test-plan';
import saga from '..';
import {
  LOGIN_SERVICE_REDUCER,
  TEMPLATE_SERVICE_REDUCER,
} from '../../../globalRedux/reducers/constants';
import TEMPLATES from '../../../utils/constants/template';
import * as loginRouteActions from '../../loginRouteService/actions';
import * as actionTypesRoute from '../../loginRouteService/actionTypes';
import {
  loadIdentitySuccess,
  loginFailure,
  lockUserPassword,
  unlockUserPassword,
} from '../actions';
import * as actionTypes from '../actionTypes';
import {
  createTaskTriggerDisplayExpiredSessionModal,
  createTaskTriggerDisplayResend,
  triggerDisplayExpiredSessionModal,
  createTaskTriggerDisplayResendCountdown,
  createTriggerDisplayExpiredSessionModalMFAChoicePage,
} from '../workers';
import {
  MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
  MULTI_FACTOR_AUTH_FORM_TRIGGER_SEND_CODE_REQUEST,
} from '../../multiFactorAuthFormService/actionTypes';
import { LOGIN_ROUTE_MFA_CHOICE } from '../../loginRouteService/actionTypes';

const oktaTransaction = {
  status: '',
  contact: '',
  factors: [],
  sessionToken: '',
  prev: jest.fn(),
};

const oktaTransactionMfaRequired = {
  status: '',
  contact: '',
  factors: [],
  sessionToken: '',
  prev: jest.fn(),
};

const oktaTransactionMfaRequiredMultiFactor = {
  status: '',
  contact: '',
  factors: [],
  sessionToken: '',
  prev: jest.fn(),
};

// Mocking MFA_CODE_EXPIRATION and MFA_CODE_EXPIRATION_SMS values from config in order to make brief the test
jest.mock('../../../configs', () => {
  const Configs = require.requireActual('../../../configs');
  return {
    ...Configs,
    AUTH: {
      ...Configs.AUTH,
      MFA_CODE_EXPIRATION: 2,
      MFA_CODE_EXPIRATION_SMS: 2,
    },
  };
});

jest.mock('../helper', () => {
  const Helper = require.requireActual('../helper');
  return {
    ...Helper,
    createIdentitiesCookie: (currentIdentities, profile) => {
      return profile === 'ME'
        ? { user: 'Mr.X', email: 'toto@gmail.com' }
        : null;
    },
    addColorsToIdentities: () => {
      return { color: 'colorOfMrX' };
    },
  };
});

jest.mock('../../../utils/lockUserCookie', () => {
  const lockUserCookie = require.requireActual('../../../utils/lockUserCookie');
  return {
    ...lockUserCookie,
    getUserLockedCookie: () => {
      return new Date();
    },
  };
});

for (const template in TEMPLATES) {
  if (has(TEMPLATES, template)) {
    const stateTest = {
      [LOGIN_SERVICE_REDUCER]: {
        identityWithDeviceToken: [
          {
            login: 'test@bnc.ca',
            deviceToken: '01234567890123456789012345678912',
          },
        ],
      },
      [TEMPLATE_SERVICE_REDUCER]: {
        templateName: template,
      },
      [LOGIN_SERVICE_REDUCER]: {
        rememberDeviceBne: false,
      },
    };

    const stateTestWithoutIdentities = {
      [TEMPLATE_SERVICE_REDUCER]: {
        templateName: template,
      },
      [LOGIN_SERVICE_REDUCER]: {
        rememberDeviceBne: false,
      },
    };

    const stateTestWithoutDeviceToken = {
      [LOGIN_SERVICE_REDUCER]: {
        identityWithDeviceToken: [
          {
            login: 'test@bnc.ca',
          },
        ],
      },
      [TEMPLATE_SERVICE_REDUCER]: {
        templateName: template,
      },
      [LOGIN_SERVICE_REDUCER]: {
        rememberDeviceBne: false,
      },
    };

    oktaTransaction.contact = 'Somebody';

    if (template !== TEMPLATES.BNE) {
      test(`watchBncSignInCompleted by TRANSACTION_STATUS => MFA_CHALLENGE on ${template} one factor when login is reached`, async () => {
        oktaTransaction.factors = ['email'];
        oktaTransaction.factorsHint = [{ email: 'test@gmail.com' }];
        oktaTransaction.selectedFactor = 'email';
        oktaTransaction.MFAselected = 'email';
        oktaTransaction.status = TRANSACTION_STATUS.MFA_CHALLENGE;
        oktaTransaction.isHardEnrolling = false;

        await expectSaga(saga)
          .withState(stateTestWithoutDeviceToken)
          .fork(createTaskTriggerDisplayResend)
          .fork(createTaskTriggerDisplayExpiredSessionModal, oktaTransaction)
          .fork(triggerDisplayExpiredSessionModal, oktaTransaction)
          .put(loginRouteActions.loginRouteRequestMFA())
          .put({
            type: actionTypes.LOGIN_MFA,
            contact: 'Somebody',
            MFAfactors: ['email'],
            MFAfactorsHint: [{ email: 'test@gmail.com' }],
            MFAselected: 'email',
            transactionStatus: oktaTransaction.status,
            isHardEnrolling: oktaTransaction.isHardEnrolling,
          })
          .put({
            type: MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
          })
          .dispatch({
            type: BNC_AUTH_SIGN_IN_COMPLETED,
            transaction: oktaTransaction,
          })
          .run();
      });

      test(`watchBncSignInCompleted by TRANSACTION_STATUS => MFA_CHALLENGE on ${template} many factors when login reached`, async () => {
        oktaTransaction.factors = ['email', 'sms'];
        oktaTransaction.selectedFactor = 'sms';
        oktaTransaction.MFAselected = 'sms';
        oktaTransaction.factorsHint = [
          {
            email: 'test@gmail.com',
            sms: '0488393993',
          },
        ];
        oktaTransaction.status = TRANSACTION_STATUS.MFA_CHALLENGE;
        oktaTransaction.isHardEnrolling = false;

        await expectSaga(saga)
          .withState(stateTestWithoutDeviceToken)
          .fork(createTaskTriggerDisplayResend)
          .fork(createTaskTriggerDisplayExpiredSessionModal, oktaTransaction)
          .fork(triggerDisplayExpiredSessionModal, oktaTransaction)
          .put(loginRouteActions.loginRouteRequestMFA())
          .put({
            type: actionTypes.LOGIN_MFA,
            contact: 'Somebody',
            MFAfactors: ['email', 'sms'],
            MFAfactorsHint: [
              {
                email: 'test@gmail.com',
                sms: '0488393993',
              },
            ],
            MFAselected: 'sms',
            transactionStatus: oktaTransaction.status,
            isHardEnrolling: oktaTransaction.isHardEnrolling,
          })
          .put({
            type: MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
          })
          .dispatch({
            type: BNC_AUTH_SIGN_IN_COMPLETED,
            transaction: oktaTransaction,
          })
          .run();
      });

      test(`watchBncSignInCompleted by TRANSACTION_STATUS => MFA_CHALLENGE on ${template} on validation success`, async () => {
        oktaTransaction.factors = ['email'];
        oktaTransaction.selectedFactor = 'email';
        oktaTransaction.MFAselected = 'email';
        oktaTransaction.factorsHint = [{ email: 'test@gmail.com' }];
        oktaTransaction.status = TRANSACTION_STATUS.MFA_CHALLENGE;
        oktaTransaction.isHardEnrolling = false;

        await expectSaga(saga)
          .withState(stateTestWithoutDeviceToken)
          .put({
            type: actionTypes.LOGIN_MFA,
            contact: 'Somebody',
            MFAfactors: ['email'],
            MFAfactorsHint: [{ email: 'test@gmail.com' }],
            MFAselected: 'email',
            transactionStatus: oktaTransaction.status,
            isHardEnrolling: oktaTransaction.isHardEnrolling,
          })
          .put({
            type: actionTypesRoute.LOGIN_ROUTE_MFA,
          })
          .put({
            type: actionTypes.LOGIN_MFA_UPDATE_CONTACT,
            MFAselected: 'email',
            contact: 'Somebody',
          })
          .put({
            type: MULTI_FACTOR_AUTH_FORM_RESET_MFA_CODE,
          })
          .fork(createTaskTriggerDisplayResend)
          .fork(createTaskTriggerDisplayExpiredSessionModal, oktaTransaction)
          .fork(triggerDisplayExpiredSessionModal, oktaTransaction)
          .dispatch({
            type: BNC_AUTH_SIGN_IN_COMPLETED,
            transaction: oktaTransaction,
          })
          .dispatch(BncAuthService.resendSuccess(oktaTransaction))
          .run();
      });
    } else {
      test(`watchBncSignInCompleted by TRANSACTION_STATUS => MFA_CHALLENGE on ${template} one factor when login is reached`, async () => {
        oktaTransaction.factors = ['email'];
        oktaTransaction.factorsHint = [{ email: 'test@gmail.com' }];
        oktaTransaction.selectedFactor = 'email';
        oktaTransaction.MFAselected = 'email';

        await expectSaga(saga)
          .withState(stateTestWithoutDeviceToken)
          .fork(createTaskTriggerDisplayExpiredSessionModal, oktaTransaction)
          .fork(triggerDisplayExpiredSessionModal, oktaTransaction)
          .put(loginRouteActions.loginRouteRequestMFA())
          .put({
            type: actionTypes.LOGIN_MFA,
            contact: 'Somebody',
            MFAfactors: ['email'],
            MFAfactorsHint: [{ email: 'test@gmail.com' }],
            MFAselected: 'email',
            transactionStatus: TRANSACTION_STATUS.MFA_CHALLENGE,
            isHardEnrolling: false,
          })
          .dispatch({
            type: BNC_AUTH_SIGN_IN_COMPLETED,
            transaction: oktaTransaction,
          })
          .run();
      });

      test(`watchBncSignInCompleted by TRANSACTION_STATUS => MFA_CHALLENGE on ${template} many factors when login reached`, async () => {
        oktaTransaction.factors = ['email', 'sms'];
        oktaTransaction.selectedFactor = 'sms';
        oktaTransaction.MFAselected = 'sms';
        oktaTransaction.factorsHint = [
          {
            email: 'test@gmail.com',
            sms: '0488393993',
          },
        ];

        await expectSaga(saga)
          .withState(stateTestWithoutDeviceToken)
          .fork(createTaskTriggerDisplayExpiredSessionModal, oktaTransaction)
          .fork(triggerDisplayExpiredSessionModal, oktaTransaction)
          .put(loginRouteActions.loginRouteRequestMFA())
          .put({
            type: actionTypes.LOGIN_MFA,
            contact: 'Somebody',
            MFAfactors: ['email', 'sms'],
            MFAfactorsHint: [
              {
                email: 'test@gmail.com',
                sms: '0488393993',
              },
            ],
            MFAselected: 'sms',
            transactionStatus: 'MFA_CHALLENGE',
            isHardEnrolling: false,
          })
          .dispatch({
            type: BNC_AUTH_SIGN_IN_COMPLETED,
            transaction: oktaTransaction,
          })
          .run();
      });

      test(`watchBncSignInCompleted by TRANSACTION_STATUS => MFA_CHALLENGE on ${template} token factor when login reached with `, async () => {
        oktaTransaction.factors = ['token'];
        oktaTransaction.selectedFactor = 'token';
        oktaTransaction.MFAselected = 'token';
        oktaTransaction.factorsHint = [
          {
            email: 'test@gmail.com',
            sms: '0488393993',
          },
        ];

        await expectSaga(saga)
          .withState(stateTestWithoutDeviceToken)
          .put(loginRouteActions.loginRouteRequestRSA())
          .put({
            type: actionTypes.LOGIN_MFA,
            contact: 'Somebody',
            MFAfactors: ['token'],
            MFAfactorsHint: [
              {
                email: 'test@gmail.com',
                sms: '0488393993',
              },
            ],
            MFAselected: 'token',
            transactionStatus: 'MFA_CHALLENGE',
            isHardEnrolling: false,
          })
          .dispatch({
            type: BNC_AUTH_SIGN_IN_COMPLETED,
            transaction: oktaTransaction,
          })
          .run();
      });

      test(`watchBncSignInCompleted by TRANSACTION_STATUS => MFA_CHALLENGE on ${template} on validation success`, async () => {
        oktaTransaction.factors = ['email'];
        oktaTransaction.selectedFactor = 'email';
        oktaTransaction.MFAselected = 'email';
        oktaTransaction.factorsHint = [{ email: 'test@gmail.com' }];

        await expectSaga(saga)
          .withState(stateTestWithoutDeviceToken)
          .put({
            type: actionTypes.LOGIN_MFA,
            contact: 'Somebody',
            MFAfactors: ['email'],
            MFAfactorsHint: [{ email: 'test@gmail.com' }],
            MFAselected: 'email',
            transactionStatus: 'MFA_CHALLENGE',
            isHardEnrolling: false,
          })
          .put({
            type: actionTypesRoute.LOGIN_ROUTE_MFA,
          })
          .put({
            type: actionTypes.LOGIN_MFA_UPDATE_CONTACT,
            MFAselected: 'email',
            contact: 'Somebody',
          })
          .fork(createTaskTriggerDisplayExpiredSessionModal, oktaTransaction)
          .fork(triggerDisplayExpiredSessionModal, oktaTransaction)
          .dispatch({
            type: BNC_AUTH_SIGN_IN_COMPLETED,
            transaction: oktaTransaction,
          })
          .dispatch(BncAuthService.resendSuccess(oktaTransaction))
          .run();
      });

      test(`watchBncSignInCompleted by TRANSACTION_STATUS => MFA_REQUIRED on ${template} many factors when login reached`, async () => {
        oktaTransaction.factors = ['email', 'sms'];
        oktaTransaction.selectedFactor = 'sms';
        oktaTransaction.MFAselected = 'email';
        oktaTransaction.status = 'MFA_REQUIRED';
        oktaTransaction.contact = 'Somebody';
        oktaTransaction.factorsHint = [
          { email: 'test@gmail.com', sms: '0488393993' },
        ];

        await expectSaga(saga)
          .withState(stateTestWithoutDeviceToken)
          .put({
            type: actionTypes.LOGIN_MFA,
            contact: 'Somebody',
            MFAfactors: ['email', 'sms'],
            MFAfactorsHint: [
              {
                email: 'test@gmail.com',
                sms: '0488393993',
              },
            ],
            MFAselected: null,
            transactionStatus: oktaTransaction.status,
            isHardEnrolling: oktaTransaction.isHardEnrolling,
          })
          .put({
            type: actionTypesRoute.LOGIN_ROUTE_MFA_CHOICE,
          })
          .fork(
            createTriggerDisplayExpiredSessionModalMFAChoicePage,
            oktaTransaction,
          )
          .dispatch({
            type: BNC_AUTH_SIGN_IN_COMPLETED,
            transaction: oktaTransaction,
          })
          .run();
      });
    }

    test(`watchBncSignInCompleted by TRANSACTION_STATUS => MFA_REQUIRED on ${template} on validation success - only one factor: auto send`, async () => {
      oktaTransactionMfaRequired.factors = ['email'];
      oktaTransactionMfaRequired.MFAselected = 'email';
      oktaTransactionMfaRequired.factorsHint = [{ email: 'test@gmail.com' }];
      oktaTransactionMfaRequired.status = TRANSACTION_STATUS.MFA_REQUIRED;

      await expectSaga(saga)
        .withState(stateTestWithoutDeviceToken)
        .put({
          type: actionTypes.LOGIN_MFA,
          contact: '',
          MFAfactors: ['email'],
          MFAfactorsHint: [{ email: 'test@gmail.com' }],
          MFAselected: null,
          transactionStatus: oktaTransactionMfaRequired.status,
          isHardEnrolling: undefined,
        })
        .put({
          type: MULTI_FACTOR_AUTH_FORM_TRIGGER_SEND_CODE_REQUEST,
          factor: 'email',
        })
        .dispatch({
          type: BNC_AUTH_SIGN_IN_COMPLETED,
          transaction: oktaTransactionMfaRequired,
        })
        .run();
    });

    test(`watchBncSignInCompleted by TRANSACTION_STATUS => MFA_REQUIRED on ${template} on validation success - multi factor: choices list`, async () => {
      oktaTransactionMfaRequiredMultiFactor.factors = ['email', 'sms', 'call'];
      oktaTransactionMfaRequiredMultiFactor.MFAselected = 'email';
      oktaTransactionMfaRequiredMultiFactor.factorsHint = [
        { email: 'test@gmail.com', sms: '438 811 1111', call: '438 811 2222' },
      ];
      oktaTransactionMfaRequiredMultiFactor.status =
        TRANSACTION_STATUS.MFA_REQUIRED;

      await expectSaga(saga)
        .withState(stateTestWithoutDeviceToken)
        .put({
          type: actionTypes.LOGIN_MFA,
          contact: '',
          MFAfactors: ['email', 'sms', 'call'],
          MFAfactorsHint: [
            {
              email: 'test@gmail.com',
              sms: '438 811 1111',
              call: '438 811 2222',
            },
          ],
          MFAselected: null,
          transactionStatus: oktaTransactionMfaRequiredMultiFactor.status,
          isHardEnrolling: undefined,
        })
        .put({
          type: LOGIN_ROUTE_MFA_CHOICE,
        })
        .dispatch({
          type: BNC_AUTH_SIGN_IN_COMPLETED,
          transaction: oktaTransactionMfaRequiredMultiFactor,
        })
        .run();
    });

    test(`watchLoginMfaCodeRequest`, async () => {
      await expectSaga(saga)
        .withState(stateTestWithoutDeviceToken)
        .put(BncAuthService.resendRequest('abc'))
        .dispatch({
          type: actionTypes.LOGIN_MFA_RESEND_CODE_REQUEST,
          MFAselected: 'abc',
        })
        .run();
    });

    test(`watchLoginMfaCodeValidationRequest`, async () => {
      await expectSaga(saga)
        .withState(stateTestWithoutDeviceToken)
        .put(BncAuthService.verifyRequest('xyz'))
        .dispatch({
          type: actionTypes.LOGIN_MFA_CODE_VALIDATION_REQUEST,
          code: 'xyz',
        })
        .run();
    });

    test(`watchLoginMfaCodeValidationRequest`, async () => {
      await expectSaga(saga)
        .withState(stateTestWithoutDeviceToken)
        .put(loginFailure('resend this error'))
        .fork(createTaskTriggerDisplayResend)
        .dispatch({
          type: BNC_AUTH_RESEND_FAILURE,
          error: 'resend this error',
        })
        .run();
    });

    const transaction = {
      factors: [],
      contact: 'Somebody',
      selectedFactor: 'email',
      MFAselected: 'email',
      prev: jest.fn(),
    };
    if (template !== TEMPLATES.BNE) {
      test(`watchBncSendSuccess on ${template}`, async () => {
        await expectSaga(saga)
          .withState(stateTest)
          .fork(createTaskTriggerDisplayResend)
          .fork(createTaskTriggerDisplayExpiredSessionModal, transaction)
          .fork(createTaskTriggerDisplayResendCountdown)
          .put({
            type: actionTypes.LOGIN_MFA,
            contact: 'Somebody',
            MFAfactors: [],
            MFAfactorsHint: undefined,
            MFAselected: 'email',
            transactionStatus: undefined,
            isHardEnrolling: undefined,
          })
          .put(loginRouteActions.loginRouteRequestMFA())
          .dispatch({
            type: BNC_AUTH_SEND_SUCCESS,
            transaction,
          })
          .run();
      });

      test(`watchBncAuthSaveIdentity`, async () => {
        await expectSaga(saga)
          .withState(stateTest)
          .put(loadIdentitySuccess({ color: 'colorOfMrX' }))
          .dispatch({
            type: BNC_AUTH_SAVE_IDENTITY,
            user: {
              profile: 'ME',
            },
          })
          .run();
      });

      test(`watchBncAuthSaveIdentity without deviceToken to save`, async () => {
        await expectSaga(saga)
          .withState(stateTestWithoutIdentities)
          .put(loadIdentitySuccess({ color: 'colorOfMrX' }))
          .dispatch({
            type: BNC_AUTH_SAVE_IDENTITY,
            user: {
              profile: 'ME',
            },
          })
          .run();
      });

      test(`watchBncAuthSaveIdentity without deviceToken to save`, async () => {
        await expectSaga(saga)
          .withState(stateTestWithoutDeviceToken)
          .put(loadIdentitySuccess({ color: 'colorOfMrX' }))
          .dispatch({
            type: BNC_AUTH_SAVE_IDENTITY,
            user: {
              profile: 'ME',
            },
          })
          .run();
      });
    } else {
      test(`watchBncSendSuccess on ${template}`, async () => {
        await expectSaga(saga)
          .withState(stateTest)
          .fork(createTaskTriggerDisplayExpiredSessionModal, transaction)
          .fork(createTaskTriggerDisplayResendCountdown)
          .put({
            type: actionTypes.LOGIN_MFA,
            contact: 'Somebody',
            MFAfactors: [],
            MFAfactorsHint: undefined,
            MFAselected: 'email',
            transactionStatus: undefined,
            isHardEnrolling: undefined,
          })
          .put(loginRouteActions.loginRouteRequestMFA())
          .dispatch({
            type: BNC_AUTH_SEND_SUCCESS,
            transaction,
          })
          .run();
      });

      test(`watchBncAuthSaveIdentity on ${template}`, async () => {
        await expectSaga(saga)
          .withState(stateTest)
          .dispatch({})
          .run();
      });

      test(`watchBncAuthSaveIdentity without identities to save on ${template}`, async () => {
        await expectSaga(saga)
          .withState(stateTestWithoutIdentities)
          .dispatch({})
          .run();
      });

      test(`watchBncAuthSaveIdentity without deviceToken to save on ${template}`, async () => {
        await expectSaga(saga)
          .withState(stateTestWithoutDeviceToken)
          .dispatch({})
          .run();
      });
    }

    test(`watchUserLockedPasswordTimeout`, async () => {
      await expectSaga(saga)
        .withState(stateTestWithoutDeviceToken)
        .put(lockUserPassword())
        .put(unlockUserPassword())
        .dispatch({
          type: actionTypes.USER_LOCKED_PASSWORD,
        })
        .run();
    });
  }
}
