// @flow
import React, { useEffect } from 'react';
import sessionManager from 'bnc-react-services/managers/SessionManager';

import BneMultiFactorAuthChoiceTemplate from '../../templates/bne/MultiFactorAuthChoiceTemplate';
import BaoMultiFactorAuthChoiceTemplate from '../../templates/bao/MultiFactorAuthChoiceTemplate';
import MultiFactorAuthChoiceTemplate from '../../templates/common/MultiFactorAuthChoiceTemplate';
import WhiteLabelMultiFactorAuthChoiceTemplate from '../../templates/whiteLabel/MultiFactorAuthChoiceTemplate';
import RewardsMultiFactorAuthChoiceTemplate from '../../templates/rewards/MultiFactorAuthChoiceTemplate';
import RewardsWhitelabelMultiFactorAuthChoiceTemplate from '../../templates/rewardsWhiteLabel/MultiFactorAuthChoiceTemplate';

import browserHistory from '../../nav/BrowserHistoryManager';

import { getTemplateComponent } from '../../utils/templateUtils';
import TEMPLATES from '../../utils/constants/template';
import ROUTES from '../../utils/constants/routes';

const MultiFactorAuthChoice = props => {
  const { templateName } = props;

  useEffect(() => {
    const transactionStatus = sessionManager.getTransactionStatus();

    // Check if an Okta transaction has been started already
    if (!transactionStatus) {
      browserHistory.push(`${ROUTES.LOGIN}${window.location.search}`);
    }
  });

  const loginTemplates = {
    [TEMPLATES.SBIP2]: <MultiFactorAuthChoiceTemplate />,
    [TEMPLATES.OBE]: <MultiFactorAuthChoiceTemplate />,
    [TEMPLATES.SECURE_KEY]: <MultiFactorAuthChoiceTemplate />,
    [TEMPLATES.NATGO]: <MultiFactorAuthChoiceTemplate />,
    [TEMPLATES.SBIP2_WHITE_LABEL]: <WhiteLabelMultiFactorAuthChoiceTemplate />,
    [TEMPLATES.REWARDS]: <RewardsMultiFactorAuthChoiceTemplate />,
    [TEMPLATES.REWARDS_WHITE_LABEL]: (
      <RewardsWhitelabelMultiFactorAuthChoiceTemplate />
    ),
    [TEMPLATES.BAO]: <BaoMultiFactorAuthChoiceTemplate />,
    [TEMPLATES.BNE]: <BneMultiFactorAuthChoiceTemplate />,
  };

  return getTemplateComponent(loginTemplates, templateName);
};

export default MultiFactorAuthChoice;
