import React from 'react';
import PropTypes from 'prop-types';

import FullScreenCenterTemplate from '../../models/FullScreenCenterTemplate';
import ErrorMessage from '../../../organisms/rewardsWhiteLabel/Error';

const propTypes = {
  errorTitle: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
};

const ErrorTemplate = props => {
  const { errorTitle, errorText } = props;

  return (
    <FullScreenCenterTemplate
      main={<ErrorMessage errorTitle={errorTitle} errorText={errorText} />}
    />
  );
};

ErrorTemplate.propTypes = propTypes;

export default ErrorTemplate;
