import { testRootSagaMappings } from 'bnc-utilities-js/testUtils/saga';
import rootSaga, { workersMapping } from '..';

jest.mock('../workers', () => ({
  watchFocusOnFirstErrorField: jest.fn(),
  watchResetFormRequest: jest.fn(),
  watchFormInitRequest: jest.fn(),
}));

testRootSagaMappings(rootSaga, workersMapping);
