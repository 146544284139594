import {
  forgotPasswordUnavailable,
  loginRouteForgotPassword,
  loginRouteRequestAppOrigin,
  loginRouteRequestLocked,
  loginRouteRequestMFA,
  loginRouteRequestMFAChoice,
  loginRouteRequestPasswordException,
  loginRouteRequestPasswordExpired,
  loginRouteRequestRSA,
  loginRouteRequestSignUp,
  loginRouteWithoutSignIn,
} from '../actions';
import {
  FORGOT_PASSWORD_UNAVAILABLE,
  LOGIN_ROUTE_APP_ORIGIN,
  LOGIN_ROUTE_FORGOT_PASSWORD,
  LOGIN_ROUTE_LOCKED,
  LOGIN_ROUTE_MFA,
  LOGIN_ROUTE_MFA_CHOICE,
  LOGIN_ROUTE_PASSWORD_EXCEPTION,
  LOGIN_ROUTE_PASSWORD_EXPIRED,
  LOGIN_ROUTE_RSA,
  LOGIN_ROUTE_SIGN_UP,
  LOGIN_ROUTE_WITHOUT_SIGN_IN,
} from '../actionTypes';

test('loginRouteRequestMFAChoice', () => {
  expect(loginRouteRequestMFAChoice()).toEqual({
    type: LOGIN_ROUTE_MFA_CHOICE,
  });
});

test('loginRouteRequestMFA', () => {
  expect(loginRouteRequestMFA()).toEqual({
    type: LOGIN_ROUTE_MFA,
  });
});

test('loginRouteRequestRSA', () => {
  expect(loginRouteRequestRSA()).toEqual({
    type: LOGIN_ROUTE_RSA,
  });
});

test('loginRouteRequestLocked', () => {
  expect(loginRouteRequestLocked()).toEqual({
    type: LOGIN_ROUTE_LOCKED,
  });
});

test('loginRouteRequestPasswordExpired', () => {
  expect(loginRouteRequestPasswordExpired()).toEqual({
    type: LOGIN_ROUTE_PASSWORD_EXPIRED,
  });
});

test('loginRouteRequestSignUp', () => {
  expect(loginRouteRequestSignUp()).toEqual({
    type: LOGIN_ROUTE_SIGN_UP,
  });
});

test('loginRouteRequestAppOrigin', () => {
  expect(loginRouteRequestAppOrigin()).toEqual({
    type: LOGIN_ROUTE_APP_ORIGIN,
  });
});

test('loginRouteForgotPassword', () => {
  expect(loginRouteForgotPassword()).toEqual({
    type: LOGIN_ROUTE_FORGOT_PASSWORD,
  });
});

test('forgotPasswordUnavailable', () => {
  expect(forgotPasswordUnavailable()).toEqual({
    type: FORGOT_PASSWORD_UNAVAILABLE,
  });
});

test('loginRouteRequestPasswordException', () => {
  expect(loginRouteRequestPasswordException()).toEqual({
    type: LOGIN_ROUTE_PASSWORD_EXCEPTION,
  });
});

test('loginRouteRequestPasswordException', () => {
  expect(loginRouteWithoutSignIn()).toEqual({
    type: LOGIN_ROUTE_WITHOUT_SIGN_IN,
  });
});

test('forgotPasswordUnavailable', () => {
  expect(forgotPasswordUnavailable()).toEqual({
    type: FORGOT_PASSWORD_UNAVAILABLE,
  });
});
