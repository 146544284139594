import { testSaga } from 'bnc-utilities-js/testUtils/saga';
import { BNC_AUTH_INIT } from 'bnc-react-services/services/AuthService/actionTypes';

import * as cookie from '../../../utils/cookie';
import {
  watchChangeLocale,
  watchChangeLocaleExt,
  IS_CI_DEVELOP_ENV,
} from '../workers';
import { I18N_SERVICE_CHANGE_LOCALE } from '../actionTypes';

jest.mock('../../../utils/cookie', () => ({
  set: jest.fn(),
}));

jest.mock('../../../utils/sdk', () => ({
  getAuthConfig: jest.fn(),
}));

describe('.watchChangeLocale', () => {
  test('.watchChangeLocale', async () => {
    const locale = 'fr';
    const { sagaDone } = testSaga({
      saga: watchChangeLocale,
      args: [
        {
          locale,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: BNC_AUTH_INIT,
      },
    ]);

    watchChangeLocale({ locale: 'fr' });
    expect(cookie.set).toBeCalled();
  });
});

describe('testing ci-develop environment determination', () => {
  test('test ci-domain regex', () => {
    expect(
      IS_CI_DEVELOP_ENV.test(
        'http://iamx-login-ci-develop.npr.ose.cloud.res.bngf.local/?SAMLRequest=xyz',
      ),
    ).toEqual(true);
    expect(
      IS_CI_DEVELOP_ENV.test(
        'http://iamx-login-ci-develop.npr.ose.cloud.res.bngf.local/',
      ),
    ).toEqual(true);
    expect(
      IS_CI_DEVELOP_ENV.test(
        'http://iamx-login-ci-develop.npr.ose.cloud.res.bngf.local',
      ),
    ).toEqual(true);
    expect(IS_CI_DEVELOP_ENV.test('https://connexion-ti.bnc.ca/')).toEqual(
      false,
    );
    expect(
      IS_CI_DEVELOP_ENV.test('https://connexion-ti.bnc.ca/?SAMLRequest=abc'),
    ).toEqual(false);
  });
});

describe('.watchChangeLocaleExt', () => {
  test('.watchChangeLocaleExt - localeCanBeOverriden', async () => {
    const locale = 'fr';
    const { sagaDone } = testSaga({
      saga: watchChangeLocaleExt,
      state: {
        intl: {
          localeCanBeOverriden: true,
        },
      },
      args: [
        {
          locale,
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      {
        type: I18N_SERVICE_CHANGE_LOCALE,
        locale,
      },
    ]);
  });
});
