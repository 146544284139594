import testReducer from 'bnc-utilities-js/testUtils/reducer';
import userAgentReducer from '../reducer';
import { initUserAgentDone } from '../actions';

const browser = {
  name: 'Chrome',
  version: '62.01',
  major: '62',
};

const os = {
  name: 'MacOS',
  version: '12',
};

testReducer(userAgentReducer)
  .initialState()
  .expectDiff({
    browser: {
      name: '',
      version: '',
      major: '',
    },
    os: {
      name: '',
      version: '',
    },
  });

testReducer(userAgentReducer)
  .withAnyState()
  .on(initUserAgentDone(browser, os))
  .expectDiff({
    browser,
    os,
  });
