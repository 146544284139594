import testReducer from 'bnc-utilities-js/testUtils/reducer';
import configsReducer from '../reducer';

import { retrieveConfigsSuccess, retrieveConfigsFailure } from '../actions';

testReducer(configsReducer)
  .initialState()
  .expectDiff({
    configs: {
      status: {
        global: {
          active: true,
          date: '',
        },
        sign_up: {
          active: true,
        },
        forgot_password: {
          active: true,
          options: {
            credit_card: {
              active: true,
            },
          },
        },
        login: {
          options: {
            alert: {
              active: false,
            },
          },
        },
        chatbot: {
          active: false,
        },
      },
    },
    hasConfig: false,
    error: null,
  });

testReducer(configsReducer)
  .withAnyState()
  .on(retrieveConfigsSuccess({ status: { active: false } }))
  .expectDiff({ configs: { status: { active: false } }, hasConfig: true });

testReducer(configsReducer)
  .withAnyState()
  .on(retrieveConfigsFailure('error message'))
  .expectDiff({
    error: 'error message',
    hasConfig: true,
  });
