// @flow
import * as actionTypes from './actionTypes';

import type {
  InitSupportedBrowserAction,
  ContinueWithUnsupportedBrowserAction,
  CannotContinueWithUnsupportedBrowserAction,
} from './types';

export function initSupportedBrowser(
  isSupportedBrowser: boolean,
): InitSupportedBrowserAction {
  return {
    type: actionTypes.UNSUPPORTED_BROWSER_INIT,
    isSupportedBrowser,
  };
}

export function continueWithUnsupportedBrowser(): ContinueWithUnsupportedBrowserAction {
  return {
    type: actionTypes.UNSUPPORTED_BROWSER_CONTINUE_ANYWAY,
  };
}

export function cannotContinueWithUnsupportedBrowser(): CannotContinueWithUnsupportedBrowserAction {
  return {
    type: actionTypes.UNSUPPORTED_BROWSER_CANNOT_CONTINUE,
  };
}
