import React from 'react';

import FullScreenFooterTemplate from '../FullScreenFooterTemplate';

import HeaderEnterprise from '../../../organisms/common/HeaderEnterprise';
import MultiFactorAuthRSA from '../../../organisms/bne/MultiFactorAuthRSA';
import './style.scss';

const MultiFactorAuthRSATemplate = () => {
  return (
    <div className="multifactorauth-bne">
      <HeaderEnterprise hasSwitchLang={false} className="mobile-header" />
      <FullScreenFooterTemplate
        className="container--enterprise"
        header={<HeaderEnterprise hasSwitchLang={false} />}
        main={<MultiFactorAuthRSA className="sbip2-mfa" />}
      />
    </div>
  );
};

export default MultiFactorAuthRSATemplate;
