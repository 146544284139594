import React from 'react';
import { Tagging, TrackAction } from '@nbc-studio/analytics';
import PropTypes from 'prop-types';
import {
  BNE_EVENT_IDS,
  BNE_EVENT_NAMES,
  BNE_EVENT_SOURCE,
  BNE_EVENT_SOURCE_DETAILS,
  BNE_UCS_IDS,
  BNE_UCS_STATE,
} from '../../../../utils/taggingUtils/bneTaggingConstants';

const propTypes = {
  partnerIdName: PropTypes.string,
  interaction: PropTypes.string.isRequired,
};

const defaultProps = {
  partnerIdName: '',
};

const ClickTaggingWrapper = ({ children, partnerIdName, interaction }) => (
  <Tagging
    ucsId={BNE_UCS_IDS.CLICK_ID}
    ucsState={BNE_UCS_STATE.NON_PV}
    eventName={BNE_EVENT_NAMES.PAGE_NAME}
    eventId={BNE_EVENT_IDS.CTTA}
    eventSourceDetail={BNE_EVENT_SOURCE_DETAILS.LOGIN}
    eventSource={partnerIdName || BNE_EVENT_SOURCE.BNE}
  >
    {children}
    {interaction && (
      <TrackAction interaction={interaction} dependencies={[interaction]} />
    )}
  </Tagging>
);

ClickTaggingWrapper.propTypes = propTypes;
ClickTaggingWrapper.defaultProps = defaultProps;

export default ClickTaggingWrapper;
