import get from 'lodash/get';
import service from '.';
import { FORGOT_PASSWORD_FIELDS } from '../../utils/forgotPasswordUtils/constants';

const rootSelector = state => get(state, service.namespace, {});

export const isValidSelector = state =>
  get(rootSelector(state), 'isValid', false);

export const errorsSelector = state => get(rootSelector(state), 'errors', {});

export const formDataSelector = state =>
  get(rootSelector(state), 'formData', {});

export const failureSelector = state =>
  get(rootSelector(state), 'failure', false);

export const formIdSelector = state => get(rootSelector(state), 'formId', '');

export const initDoneSelector = state =>
  get(rootSelector(state), 'initDone', false);

export const isEmailError = state => !!errorsSelector(state).email;

export const isTokenCaptchaError = state =>
  !!errorsSelector(state).tokenCaptcha;

export const tokenRecaptchaSelector = state =>
  get(
    formDataSelector(state),
    FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA,
    '',
  );

export const executedActionSelector = state =>
  get(rootSelector(state), 'executedAction', {});

export const getProductType = state =>
  get(formDataSelector(state), 'productType', '');
