import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getTemplateName } from '../../services/templateService/selectors';

import ParamsUnavailable from './paramsUnavailable';

function mapStateToProps(state) {
  return {
    templateName: getTemplateName(state),
  };
}

export default injectIntl(connect(mapStateToProps)(ParamsUnavailable));
