// @flow
import * as actionTypes from './actionTypes';
import type { Action as ReduxAction } from '../../types/action';
import type {
  LoginFormData,
  LoginFormErrors,
} from '../../types/forms/loginForm';
import type { FormType, LoginFormSteps } from '../../types/constants';

export type LoginFormUpdateAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_UPDATE,
> & {
  fieldName: string,
  fieldValue: string | boolean,
};

export type LoginFormTriggerValidationAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_VALIDATION_REQUEST,
> & {
  fieldName: string,
};

export type LoginFormValidationSuccessAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_VALIDATION_SUCCESS,
> & {
  fieldName: string,
  isValid: boolean,
};

export type LoginFormValidationFailureAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_VALIDATION_FAILURE,
> & {
  fieldName: string,
  errors: LoginFormErrors,
};

export type LoginFormTriggerSubmitAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_SUBMIT_REQUEST,
>;

export type LoginFormSubmitFailureAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_SUBMIT_FAILURE,
> & {
  errors: LoginFormErrors,
};

export type LoginFormInitFormAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_INIT_REQUEST,
> & {
  formId: FormType,
};

export type LoginFormInitFormDoneAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_INIT_DONE,
> & {
  payload: any,
};

export type LoginFormResetAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_RESET,
>;

export type LoginFormChangeStepRequestAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_CHANGE_STEP_REQUEST,
> & {
  nextStep: LoginFormSteps,
  hasToFocus: boolean,
};

export type LoginFormChangeStepSuccessAction = ReduxAction<
  typeof actionTypes.LOGIN_FORM_CHANGE_STEP_SUCCESS,
> & {
  step: LoginFormSteps,
};

export type LoginFormState = {|
  formId: FormType,
  isValid: boolean,
  errors: LoginFormErrors,
  formData: LoginFormData,
  submitFailed: boolean,
  initDone: boolean,
  currentStep: LoginFormSteps,
  isFirstRender: ?boolean,
  hasToFocus: boolean,
|};

export type Action =
  | LoginFormChangeStepRequestAction
  | LoginFormChangeStepSuccessAction
  | LoginFormInitFormAction
  | LoginFormInitFormDoneAction
  | LoginFormResetAction
  | LoginFormSubmitFailureAction
  | LoginFormTriggerSubmitAction
  | LoginFormTriggerValidationAction
  | LoginFormUpdateAction
  | LoginFormValidationFailureAction
  | LoginFormValidationSuccessAction;
