import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import NotFoundError from './notFoundError';
import redirectServiceSelectors from '../../../services/redirectService/selectors';

function mapStateToProps(state) {
  return {
    redirectPageUrl: redirectServiceSelectors.getRedirectPage(state),
  };
}

export default injectIntl(connect(mapStateToProps)(NotFoundError));
