import testReducer from 'bnc-utilities-js/testUtils/reducer';
import unsupportedBrowserReducer from '../reducer';
import {
  continueWithUnsupportedBrowser,
  cannotContinueWithUnsupportedBrowser,
  initSupportedBrowser,
} from '../actions';

testReducer(unsupportedBrowserReducer)
  .initialState()
  .expectDiff({
    isSupportedBrowser: false,
    continueWithUnsupportedBrowser: false,
    cannotContinueWithUnsupportedBrowser: false,
  });

testReducer(unsupportedBrowserReducer)
  .withAnyState()
  .on(continueWithUnsupportedBrowser())
  .expectDiff({
    continueWithUnsupportedBrowser: true,
  });

testReducer(unsupportedBrowserReducer)
  .withAnyState()
  .on(cannotContinueWithUnsupportedBrowser())
  .expectDiff({
    cannotContinueWithUnsupportedBrowser: true,
  });

testReducer(unsupportedBrowserReducer)
  .withAnyState()
  .on(initSupportedBrowser(true))
  .expectDiff({
    isSupportedBrowser: true,
  });

testReducer(unsupportedBrowserReducer)
  .withAnyState()
  .on(initSupportedBrowser(false))
  .expectDiff({
    isSupportedBrowser: false,
  });
