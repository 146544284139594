import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import ParamsUnavailableTemplate from '../../templates/common/ParamsUnavailableTemplate';
import WhiteLabelParamsUnavailableTemplate from '../../templates/whiteLabel/ParamsUnavailableTemplate';

import TEMPLATES from '../../utils/constants/template';

const propTypes = {
  intl: intlShape.isRequired,
  templateName: PropTypes.string.isRequired,
};

const ParamsUnavailable = props => {
  const { intl, templateName } = props;
  const title = intl.formatMessage({ id: 'text.message.badLink' });
  const text = intl.formatMessage({ id: 'text.message.pageError' });

  switch (templateName) {
    case TEMPLATES.SBIP2_WHITE_LABEL:
      return (
        <WhiteLabelParamsUnavailableTemplate
          errorTitle={title}
          errorText={text}
        />
      );
    default:
      return <ParamsUnavailableTemplate errorTitle={title} errorText={text} />;
  }
};

ParamsUnavailable.propTypes = propTypes;

export default ParamsUnavailable;
