// @flow
import * as actionTypes from './actionTypes';
import type { Action as ReduxAction } from '../../types/action';
import type { OS, Browser } from '../../types/browser';

export type UserAgentState = {|
  +browser: Browser,
  +os: OS,
|};

export type InitUserAgentRequestAction = ReduxAction<
  typeof actionTypes.USER_AGENT_INIT_REQUEST,
>;

export type InitUserAgentDoneAction = ReduxAction<
  typeof actionTypes.USER_AGENT_INIT_DONE,
> & { browser: Browser, os: OS };

export type Action = InitUserAgentDoneAction | InitUserAgentRequestAction;
