import { LOCATION_CHANGE } from 'react-router-redux';
import { combineReducers } from 'redux';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import { throwError } from 'redux-saga-test-plan/providers';

import testReducer from 'bnc-utilities-js/testUtils/reducer';
import { toggleFeatureApi } from 'bnc-react-components';
import globalErrorServiceActionTypes from 'bnc-react-services/services/globalErrorService/actionTypes';

import { FACTOR_TYPES } from 'bnc-react-services/managers/AuthManager/constants';

import service from '..';
import {
  MFA_FORM_SERVICE_REDUCER,
  TEMPLATE_SERVICE_REDUCER,
} from '../../../globalRedux/reducers/constants';
import { ROUTES } from '../../../utils/constants';
import { buildFactors } from '../../../utils/forgotPasswordUtils';
import {
  FORGOT_PASSWORD_FIELDS,
  FORGOT_PASSWORD_PRODUCT_TYPE,
  FORGOT_PASSWORD_STEPS,
} from '../../../utils/forgotPasswordUtils/constants';
import forgotPasswordFormService from '../../forgotPasswordFormService';
import forgotPasswordFormReducer from '../../forgotPasswordFormService/reducer';
import {
  loginRouteForgotPasswordException,
  loginRouteRequestLocked,
} from '../../loginRouteService/actions';
import multiFactorAuthFormReducer from '../../multiFactorAuthFormService/reducer';
import {
  hideResend,
  resetMfaCode,
  submitFailure,
  updateFactors,
} from '../../multiFactorAuthFormService/actions';
import templateServiceReducer from '../../templateService/reducer';
import {
  forgotPasswordUnavailableCheck,
  resendOTC,
  saveRecaptchaToken,
  setBackendError,
  setPassword,
  setPasswordFail,
  setPasswordSuccess,
  showResendOTCSuccessMessage,
  triggerException,
  triggerMFAChoiceStep,
  triggerVerificationCodeStep,
  validateUserFailure,
  validateUserRequest,
  validateUserSuccess,
  verifyOTC,
  verifyOTCSuccess,
} from '../actions';
import {
  forgotPasswordValidateUserFetch,
  sendOTC,
  setPasswordFetch,
  verifyOTC as apiVerifyOTC,
} from '../api';
import reducer from '../reducer';
import saga, {
  createTaskHideResendSuccessMessage,
  createTaskTriggerDisplayResend,
} from '../saga';
import { MULTI_FACTOR_AUTH_UPDATE_FACTORS } from '../../multiFactorAuthFormService/actionTypes';
import { resetRecaptcha } from '../../forgotPasswordFormService/actions';
import {
  EXCEPTION_TYPES,
  LOCKED_OUT_TOO_MUCH_TRIES,
} from '../../../utils/constants/forgotPassword';
import { updateLockedStatus } from '../../loginService/actions';

toggleFeatureApi.isAvailable = jest.fn();

const rootReducer = combineReducers({
  [service.namespace]: reducer,
  [forgotPasswordFormService.namespace]: forgotPasswordFormReducer,
  [TEMPLATE_SERVICE_REDUCER]: templateServiceReducer,
  [MFA_FORM_SERVICE_REDUCER]: multiFactorAuthFormReducer,
});

const factorsState = [
  {
    factorType: 'SMS',
    value: '1 xxx-xxx-1012',
  },
  {
    factorType: 'EMAIL',
    value: 'u***t@gmail.com',
  },
];

const formData = {
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EMAIL]: 'test@gmail.com',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.BIRTHDATE]: new Date(),
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PRODUCT_TYPE]:
    FORGOT_PASSWORD_PRODUCT_TYPE.DEBIT,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.OPEN_CHOICES]: false,
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.RECAPTCHA]: 'someValidToken',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.CARD_NUMBER]: '123456',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.EXPIRY_DATE]: new Date(),
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ACCOUNT_NUMBER]: '99333399',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.POSTAL_CODE]: 'H3G7C5',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.ORIGINAL_AMOUNT]: '120.56',
  [FORGOT_PASSWORD_FIELDS.IDENTIFICATION_FORM.PHONE_NUMBER]: '123 567-1092',
  tokenRecaptcha: { accessToken: 'someToken' },
};

const factorResult = [
  {
    factorType: 'SMS',
    value: '1 xxx-xxx-1012',
  },
  {
    factorType: 'EMAIL',
    value: 'u***t@gmail.com',
  },
];

test('location change should not dispatch anything', async () => {
  const payload = {
    pathname: ROUTES.NOT_FOUND,
  };
  const locationChangeAction = {
    type: LOCATION_CHANGE,
    payload,
  };

  await expectSaga(saga)
    .withReducer(rootReducer)
    .dispatch(locationChangeAction)
    .run();
});

describe('watchValidateUserRequest saga', () => {
  test('validate user request on success should put success', async () => {
    await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({
        [service.namespace]: {
          ...reducer,
          isFetching: true,
          failure: false,
          currentStep: FORGOT_PASSWORD_STEPS.IDENTIFICATION,
          factors: [],
        },
        [forgotPasswordFormService.namespace]: {
          ...forgotPasswordFormReducer,
          formData,
        },
        templateServiceReducer: { templateName: '', partnerId: '' },
        [MFA_FORM_SERVICE_REDUCER]: {
          ...multiFactorAuthFormReducer,
        },
      })
      .provide([
        [
          matchers.call.fn(forgotPasswordValidateUserFetch, {}),
          { factors: factorResult },
        ],
      ])
      .put(saveRecaptchaToken(formData.tokenRecaptcha))
      .put(triggerMFAChoiceStep(factorResult))
      .dispatch(validateUserRequest(formData))
      .hasFinalState({
        [service.namespace]: {
          isFetching: false,
          failure: false,
          currentStep: FORGOT_PASSWORD_STEPS.MFA_CHOICE,
          factors: factorsState,
          backendError: {},
          tokenRecaptcha: { accessToken: 'someToken' },
          numberOfRetry: 0,
        },
        [forgotPasswordFormService.namespace]: {
          ...forgotPasswordFormReducer,
          formData,
        },
        templateServiceReducer: { templateName: '', partnerId: '' },
        [MFA_FORM_SERVICE_REDUCER]: {},
      })
      .run();
  });

  test('validate user request on error should put fail', async () => {
    const error = new Error('some error');

    await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({
        [service.namespace]: {
          ...reducer,
          isFetching: true,
          failure: false,
          numberOfRetry: 10,
        },
        [forgotPasswordFormService.namespace]: {
          ...forgotPasswordFormReducer,
          formData,
        },
        templateServiceReducer: { templateName: '', partnerId: '' },
        [MFA_FORM_SERVICE_REDUCER]: {
          ...multiFactorAuthFormReducer,
        },
      })
      .provide([
        [
          matchers.call.fn(forgotPasswordValidateUserFetch, {}),
          Promise.reject(error),
        ],
      ])
      .put({
        error,
        type: globalErrorServiceActionTypes.BNC_GLOBAL_ERROR_CAUGHT,
      })
      .put(validateUserFailure(error))
      .put(resetRecaptcha())
      .dispatch(validateUserRequest(formData))
      .hasFinalState({
        [service.namespace]: {
          isFetching: false,
          failure: true,
          backendError: error,
          numberOfRetry: 11,
        },
        [forgotPasswordFormService.namespace]: {
          ...forgotPasswordFormReducer,
          formData,
          hasToResetRecaptcha: true,
        },
        templateServiceReducer: { templateName: '', partnerId: '' },
        [MFA_FORM_SERVICE_REDUCER]: {
          ...multiFactorAuthFormReducer,
        },
      })
      .run();
  });
});

test('watchTriggerVerificationStep', async () => {
  await expectSaga(saga)
    .withReducer(rootReducer)
    .withState({
      [service.namespace]: {
        ...reducer,
        isFetching: true,
        failure: false,
        currentStep: FORGOT_PASSWORD_STEPS.IDENTIFICATION,
        factors: factorsState,
      },
      [forgotPasswordFormService.namespace]: {
        ...forgotPasswordFormReducer,
        formData,
      },
      templateServiceReducer: { templateName: '', partnerId: '' },
      [MFA_FORM_SERVICE_REDUCER]: {
        ...multiFactorAuthFormReducer,
      },
    })
    .put(validateUserSuccess(factorsState, FACTOR_TYPES.EMAIL))
    .dispatch(triggerVerificationCodeStep(FACTOR_TYPES.EMAIL));
});
test('watchValidateUserSuccess success', async () => {
  const { factors, selectedFactor, contact } = buildFactors(
    factorResult,
    FACTOR_TYPES.SMS,
  );
  await expectSaga(saga)
    .withReducer(rootReducer)
    .withState({
      [service.namespace]: {
        ...reducer,
        isFetching: true,
        failure: false,
        currentStep: FORGOT_PASSWORD_STEPS.CODE_CONFIRMATION,
        factors: [],
      },
      [forgotPasswordFormService.namespace]: {
        ...forgotPasswordFormReducer,
        formData,
      },
      templateServiceReducer: { templateName: '', partnerId: '' },
      [MFA_FORM_SERVICE_REDUCER]: {
        ...multiFactorAuthFormReducer,
      },
    })
    .provide([
      [
        matchers.call.fn(sendOTC, selectedFactor),
        Promise.resolve({
          code: 1241,
          message: 'IAMX_OTC_SENT',
        }),
      ],
    ])
    .put(updateFactors(factors, selectedFactor, contact))
    .fork(createTaskTriggerDisplayResend)
    .dispatch(validateUserSuccess(factorResult, selectedFactor))
    .hasFinalState({
      [service.namespace]: {
        isFetching: false,
        failure: false,
        currentStep: FORGOT_PASSWORD_STEPS.CODE_CONFIRMATION,
        factors: factorsState,
        backendError: {},
        numberOfRetry: 0,
      },
      [forgotPasswordFormService.namespace]: {
        ...forgotPasswordFormReducer,
        formData,
      },
      templateServiceReducer: { templateName: '', partnerId: '' },
      [MFA_FORM_SERVICE_REDUCER]: {
        ...multiFactorAuthFormReducer,
        factorTypes: factorResult.map(f => f.factorType.toLowerCase()),
        selectedFactor: 'sms',
        contact: factorResult.filter(f => f.factorType === 'SMS')[0].value,
        executedAction: {
          type: MULTI_FACTOR_AUTH_UPDATE_FACTORS,
          factorTypes: ['sms', 'email'],
          selectedFactor: 'sms',
          contact: '1 xxx-xxx-1012',
        },
      },
    })
    .run();
});

test('triggerException should dispatch a loginRouteForgotPasswordException action', async () => {
  await expectSaga(saga)
    .put(loginRouteForgotPasswordException())
    .dispatch(triggerException())
    .run();
});

test('triggerException when account is locked should route to locked page', async () => {
  await expectSaga(saga)
    .put(updateLockedStatus(LOCKED_OUT_TOO_MUCH_TRIES))
    .put(loginRouteRequestLocked())
    .dispatch(triggerException(EXCEPTION_TYPES.ACCOUNT_LOCKED))
    .run();
});

test('triggerException by default calling loginRouteForgotPasswordException', async () => {
  await expectSaga(saga)
    .put(loginRouteForgotPasswordException())
    .dispatch(triggerException('anything'))
    .run();
});

testReducer(reducer)
  .withAnyState()
  .on(showResendOTCSuccessMessage(true))
  .expectDiff({
    isFetching: false,
    showOTCSuccessMessage: true,
  });

testReducer(reducer)
  .withAnyState()
  .on(showResendOTCSuccessMessage(false))
  .expectDiff({
    isFetching: false,
    showOTCSuccessMessage: false,
  });

test('watchValidateUserSuccess fail', async () => {
  const { selectedFactor } = buildFactors(factorResult);
  const err = {
    code: 800,
    message: 'BUSINESS_GENERIC',
  };
  await expectSaga(saga)
    .withReducer(rootReducer)
    .provide([[matchers.call.fn(sendOTC, selectedFactor), throwError(err)]])
    .put(setBackendError(err))
    .dispatch(validateUserSuccess(factorResult))
    .run();
});

describe('watchResendOTC saga', () => {
  test('resendOTC success', async () => {
    await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({
        [service.namespace]: {
          ...reducer,
          isFetching: false,
          failure: false,
          currentStep: FORGOT_PASSWORD_STEPS.CODE_CONFIRMATION,
          factors: [
            { factorType: 'SMS', value: 'smsContact' },
            { factorType: 'EMAIL', value: 'emailContact' },
          ],
        },
        [forgotPasswordFormService.namespace]: {
          ...forgotPasswordFormReducer,
          formData,
        },
        [MFA_FORM_SERVICE_REDUCER]: {
          ...multiFactorAuthFormReducer,
          factorTypes: ['sms', 'email'],
        },
        templateServiceReducer: { templateName: '', partnerId: '' },
      })
      .provide([
        [
          matchers.call.fn(sendOTC, 'sms'),
          Promise.resolve({
            code: 1241,
            message: 'IAMX_OTC_SENT',
          }),
        ],
      ])
      .put(hideResend())
      .fork(createTaskTriggerDisplayResend)
      .put(setBackendError({}))
      .put(resetMfaCode())
      .put(showResendOTCSuccessMessage(true))
      .fork(createTaskHideResendSuccessMessage)
      .put(updateFactors(['sms', 'email'], 'sms', 'smsContact'))
      .dispatch(resendOTC('sms'))
      .run();
  });

  test('resendOTC failure', async () => {
    const err = {
      code: 800,
      message: 'BUSINESS_GENERIC',
    };

    await expectSaga(saga)
      .withReducer(rootReducer)
      .provide([[matchers.call.fn(sendOTC, 'sms'), throwError(err)]])
      .put(hideResend())
      .fork(createTaskTriggerDisplayResend)
      .put(setBackendError({}))
      .put(setBackendError(err))
      .dispatch(resendOTC('sms'))
      .run();
  });
});

describe('watchVerifyOTC saga', () => {
  test('verifyOTC success', async () => {
    await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({
        [MFA_FORM_SERVICE_REDUCER]: {
          ...multiFactorAuthFormReducer,
          formData: {
            code: '123456',
          },
          selectedFactor: 'sms',
        },
      })
      .provide([
        [
          matchers.call.fn(apiVerifyOTC, 'sms', '123456'),
          Promise.resolve({ factorResult: 'SUCCESS' }),
        ],
      ])
      .put(verifyOTCSuccess())
      .dispatch(verifyOTC('sms'))
      .run();
  });

  test('verifyOTC form invalid', async () => {
    await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({
        [MFA_FORM_SERVICE_REDUCER]: {
          ...multiFactorAuthFormReducer,
          formData: {
            code: '12345',
          },
          selectedFactor: 'sms',
        },
      })
      .put(
        submitFailure('12345', {
          code: ['text.message.validationCodeError'],
        }),
      )
      .dispatch(verifyOTC('sms'))
      .run();
  });

  test('verifyOTC failure', async () => {
    const err = {
      code: 800,
      message: 'BUSINESS_GENERIC',
    };

    await expectSaga(saga)
      .withReducer(rootReducer)
      .withState({
        [MFA_FORM_SERVICE_REDUCER]: {
          ...multiFactorAuthFormReducer,
          formData: {
            code: '123456',
          },
          selectedFactor: 'sms',
        },
      })
      .provide([
        [matchers.call.fn(apiVerifyOTC, 'sms', '123456'), throwError(err)],
      ])
      .put(setBackendError(err))
      .dispatch(verifyOTC('sms'))
      .run();
  });
});

describe('watchSetPasswordRequest saga', () => {
  test('watchSetPasswordRequest success', async () => {
    await expectSaga(saga)
      .withReducer(rootReducer)
      .provide([
        [
          matchers.call.fn(setPasswordFetch, 'somePassword'),
          Promise.resolve(null),
        ],
      ])
      .put(setPasswordSuccess())
      .dispatch(setPassword('somePassword'))
      .run();
  });

  test('watchSetPasswordRequest failure', async () => {
    const err = {
      code: 400,
      message: 'BUSINESS_GENERIC',
    };

    await expectSaga(saga)
      .withReducer(rootReducer)
      .provide([
        [matchers.call.fn(setPasswordFetch, 'somePassword'), throwError(err)],
      ])
      .put(setPasswordFail())
      .dispatch(setPassword('somePassword'))
      .run();
  });
});

describe('watchForgotPasswordUnavailableCheck ', () => {
  toggleFeatureApi.isAvailable.mockImplementationOnce(() => false);

  test('watchForgotPasswordUnavailableCheck when toggle FALSE and AVAILABLE feature', async () => {
    await expectSaga(saga)
      .dispatch(forgotPasswordUnavailableCheck(ROUTES.FORGOT_PASSWORD))
      .run();
  });

  test('watchForgotPasswordUnavailableCheck when toggle FALSE and AVAILABLE feature', async () => {
    await expectSaga(saga)
      .dispatch(forgotPasswordUnavailableCheck(ROUTES.RESETPASSWORD))
      .run();
  });

  toggleFeatureApi.isAvailable.mockImplementationOnce(() => true);

  test('watchForgotPasswordUnavailableCheck when toggle TRUE and UNAVAILABLE feature', async () => {
    await expectSaga(saga)
      .dispatch(
        forgotPasswordUnavailableCheck(ROUTES.FORGOT_PASSWORD_UNAVAILABLE),
      )
      .run();
  });
});
