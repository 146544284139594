import {
  getModalStack,
  getModalState,
  isShowSessionExpiredModal,
  isShowMFACodeExpiredModal,
} from '../selectors';

import { MODAL_TYPES } from '../../../utils/constants/modal';

describe('getModalState', () => {
  it('should return state if they are available', () => {
    const state = {
      modalServiceReducer: { test: 1 },
    };
    expect(getModalState(state)).toEqual({ test: 1 });
  });

  it('should return {} if state are not available', () => {
    const state = {};
    expect(getModalState(state)).toEqual({});
  });

  it('should return module state if they are available', () => {
    const modulePath = 'front_end.sso';

    const state = {
      front_end: {
        sso: {
          modalServiceReducer: { test: 1 },
        },
      },
    };
    expect(getModalState(state, modulePath)).toEqual({ test: 1 });
  });
});

describe('getModalStack', () => {
  it('should return local if it is available', () => {
    const state = {
      modalServiceReducer: { modalStack: ['MODAL_1'] },
    };
    expect(getModalStack(state)).toEqual(['MODAL_1']);
  });

  it('should return empty if locale is not available', () => {
    const state = {
      modalServiceReducer: {},
    };
    expect(getModalStack(state)).toEqual([]);
  });
});

describe('isShowSessionExpiredModal', () => {
  it('should return true if the session expired modal is in the stack', () => {
    const state = {
      modalServiceReducer: { modalStack: [MODAL_TYPES.SESSION_EXPIRED_MODAL] },
    };
    expect(isShowSessionExpiredModal(state)).toEqual(true);
  });

  it('should return true if the session expired modal is not in the stack', () => {
    const state = {
      modalServiceReducer: {},
    };
    expect(isShowSessionExpiredModal(state)).toEqual(false);
  });
});

describe('isShowMFACodeExpiredModal', () => {
  it('should return true if the MFA code expired modal is in the stack', () => {
    const state = {
      modalServiceReducer: {
        modalStack: [MODAL_TYPES.MFA_EXPIRED_MODAL],
      },
    };
    expect(isShowMFACodeExpiredModal(state)).toEqual(true);
  });

  it('should return true if the MFA code expired modal is not in the stack', () => {
    const state = {
      modalServiceReducer: {},
    };
    expect(isShowMFACodeExpiredModal(state)).toEqual(false);
  });
});
