// @flow
import React from 'react';
import cx from 'classnames';
import type { Node } from 'react';

import './style.scss';

type Props = {
  className?: string,
  modal?: Node,
  main: Node,
  header: Node,
  footer: Node,
};

const defaultProps = {
  className: '',
  modal: null,
};

function FullScreenFooterTemplate(props: Props) {
  const { className, main, header, footer, modal } = props;

  return (
    <div className={cx('template-fullscreen-footer', className)}>
      {modal}
      <div className="template-fullscreen-footer__content">
        <div className="template-fullscreen-footer__header">
          {header && <header>{header}</header>}
        </div>
        <main className="template-fullscreen-footer__main">{main}</main>
      </div>
      {footer && (
        <footer className="template-fullscreen-footer__footer">{footer}</footer>
      )}
    </div>
  );
}

FullScreenFooterTemplate.defaultProps = defaultProps;

export default FullScreenFooterTemplate;
