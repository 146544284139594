// @flow
import React from 'react';
import cx from 'classnames';
import { Constants, Icon } from 'bnc-react-components';

// Loading Styles and style Helper
import './style.scss';

type Props = {
  className?: string,
  label: string,
  subLabel: string,
  iconName: string,
};

const defaultProps = {
  className: '',
};

const FactorInfo = (props: Props) => {
  const { className, label, subLabel, iconName } = props;

  return (
    <div className={cx('factor-info', className)}>
      <div className="factor-info__icon">
        <Icon size={24} name={iconName} />
      </div>
      <div className="factor-info__labels">
        <span className="factor-info__label">{label}</span>
        {subLabel}
      </div>
      <div className="factor-info__arrow">
        <Icon size={16} name={Constants.ICONS.CHEVRON} />
      </div>
    </div>
  );
};

FactorInfo.defaultProps = defaultProps;

export default FactorInfo;
