import * as actionTypes from './actionTypes';
import type { RedirectToPageAction } from './types';

function redirectToPage(pageUrl: string): RedirectToPageAction {
  return {
    type: actionTypes.REDIRECT_TO_PAGE,
    pageUrl,
  };
}

export default {
  redirectToPage,
};
