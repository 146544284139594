import isEmpty from 'lodash/isEmpty';

import browserHistoryManager from '../nav/BrowserHistoryManager';
import { getBaseUrl } from './templateUtils';

const parseParams = url => {
  const params = {};
  if (!isEmpty(url)) {
    url
      .replace(/^.+[?]/, '')
      .split('&')
      .map(param => {
        const arr = param.split('=');
        params[arr[0]] = arr[1];
        return params[arr[0]];
      });
  }
  return params;
};

const listenPopEventAndRedirectToBaseUrl = () => {
  browserHistoryManager.listen(location => {
    if (location.action === 'POP') {
      window.location = getBaseUrl();
    }
  });
};

export default {
  parseParams,
  listenPopEventAndRedirectToBaseUrl,
};
