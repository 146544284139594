// @flow
import React from 'react';

import FullScreenCenterTemplate from '../../models/FullScreenCenterTemplate';
import ErrorMessage from '../../../organisms/secureKey/Error';

type Props = {
  errorTitle: string,
  errorText: string,
};

const ErrorTemplate = (props: Props) => {
  const { errorTitle, errorText } = props;

  return (
    <FullScreenCenterTemplate
      main={<ErrorMessage errorTitle={errorTitle} errorText={errorText} />}
    />
  );
};

export default ErrorTemplate;
