// @flow
import { select } from 'redux-saga/effects';

import type { Saga } from 'redux-saga';
import { openExternalInSameTab } from '../../utils/externalUrlUtils';
import type { InitSupportedBrowserAction } from './types';
import browserHistory from '../../nav/BrowserHistoryManager/index';
import { ROUTES } from '../../utils/constants';
import userAgentSelectors from '../userAgentService/selectors';
import { shouldShowIE11Page } from './helper';
import { getLocale } from '../i18nService/selectors';
import { Configs } from '../../configs';

import { TEMPLATE_SERVICE_REDUCER } from '../../globalRedux/reducers/constants';

import { hasUnsupportedBrowserFeature } from '../../utils/browserUtils';

export function* watchContinueAnyway(): Saga<void> {
  yield browserHistory.push(`${ROUTES.LOGIN}${window.location.search}`);
}

export function* watchCannotContinue(): Saga<void> {
  const locale = yield getLocale();
  yield openExternalInSameTab(
    Configs.BNE.EXTERNAL_URLS.ENTERPRISE_HOME_URL.URL[locale],
  );
}

export function* watchInitUnsupportedBrowser(
  action: InitSupportedBrowserAction,
): Saga<void> {
  const {
    continueWithUnsupportedBrowser,
  } = (yield select()).unsupportedBrowserServiceReducer;
  const browserName = yield select(userAgentSelectors.getBrowserName);
  const { templateName } = (yield select())[TEMPLATE_SERVICE_REDUCER];
  const browserVersion = yield select(userAgentSelectors.getBrowserVersion);

  if (
    hasUnsupportedBrowserFeature(templateName) &&
    ((!action.isSupportedBrowser && !continueWithUnsupportedBrowser) ||
      shouldShowIE11Page(browserName, browserVersion))
  ) {
    yield browserHistory.push(
      `${ROUTES.UNSUPPORTED_BROWSER}${window.location.search}`,
    );
  }
}
