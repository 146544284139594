// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { IntlShape } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import { Link } from '@bnc-ui/link';

import './style.scss';

import {
  getPhoneAssistanceNumber,
  isPartnerVanilla,
} from '../../../utils/templateUtils';
import TitleSection from '../TitleSection';
import { Configs } from '../../../configs';
import { FORGOT_PASSWORD_STEPS } from '../../../utils/forgotPasswordUtils/constants';
import { ASSISTANT_PHONE_TYPE } from '../../../utils/constants/phoneType';

type Props = {
  intl: IntlShape,
  partnerId?: string,
  templateName: string,
  currentForgotPasswordStep?: string,
};

const defaultProps = {
  partnerId: '',
  currentForgotPasswordStep: '',
};

const NeedHelp = (props: Props) => {
  const { intl, partnerId, templateName, currentForgotPasswordStep } = props;

  const renderFaqInfo = () => {
    if (isEmpty(partnerId) || isPartnerVanilla(partnerId)) {
      return (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={Configs.SBIP2.EXTERNAL_URLS.HELP_PAGE.URL[intl.locale]}
        >
          {intl.formatMessage({ id: 'text.link.helpInfo' })}
        </Link>
      );
    }

    const assistancePhone = getPhoneAssistanceNumber(
      templateName,
      partnerId,
      intl.locale,
      ASSISTANT_PHONE_TYPE.INTERNATIONAL,
    );
    const tollFreeAssistancePhone = getPhoneAssistanceNumber(
      templateName,
      partnerId,
      intl.locale,
      ASSISTANT_PHONE_TYPE.TOLL_FREE,
    );

    const ariaLabelHelpInfoInternational = intl.formatMessage(
      { id: `text.aria.international.${partnerId}.helpInfo` },
      { assistancePhone },
    );
    const ariaLabelHelpInfoTollFree = intl.formatMessage(
      { id: `text.aria.tollFree.${partnerId}.helpInfo` },
      { assistanceTollFreePhone: tollFreeAssistancePhone },
    );

    return (
      <FormattedMessage
        id={`text.message.${partnerId}.helpInfo`}
        values={{
          assistancePhone: (
            <Link
              target="_blank"
              href={`tel:${assistancePhone}`}
              rel="noopener noreferrer"
              aria-label={ariaLabelHelpInfoInternational}
            >
              {assistancePhone}
            </Link>
          ),
          assistanceTollFreePhone: (
            <Link
              target="_blank"
              href={`tel:${tollFreeAssistancePhone}`}
              rel="noopener noreferrer"
              aria-label={ariaLabelHelpInfoTollFree}
            >
              {tollFreeAssistancePhone}
            </Link>
          ),
        }}
      />
    );
  };

  const demoInteractive = (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={Configs.SBIP2.EXTERNAL_URLS.DEMO.URL[intl.locale]}
    >
      {intl.formatMessage({ id: 'text.link.demoInteractive' })}
    </Link>
  );

  const helpSection = (
    <span className="title-section--info">
      {renderFaqInfo()}
      {currentForgotPasswordStep !== FORGOT_PASSWORD_STEPS.FINAL_CONFIRMATION &&
        !partnerId &&
        demoInteractive}
    </span>
  );

  return (
    <TitleSection
      title={intl.formatMessage({ id: 'global.link.help' })}
      text={helpSection}
    />
  );
};

NeedHelp.defaultProps = defaultProps;

export default NeedHelp;
